import { Col, DatePicker, Input, Row } from 'antd';
import { useEffect } from 'react';
import AssignOwners from '../FormElements/AssignOwners';
import { ActivityFormItem } from '../style';
import { TaskRemainderProps } from '../types';

export default function TaskRemainder(props: TaskRemainderProps) {
  const { form, assigneeOptionType, text, remainder, dateFormat } = props;
  const {
    taskReminderDateLabel,
    taskReminderDatePlaceholder,
    taskReminderLabel,
    taskReminderPlaceholder,
    taskReminderAssignToLabel,
    taskReminderAssignToPlaceholder,
  } = text;

  useEffect(() => {
    if (remainder) {
      form.scrollToField('taskRemainderDueDate');
    } else {
      form.resetFields(['taskRemainderTitle', 'taskRemainderDueDate', 'taskRemainderOwner']);
    }
  }, [form, remainder]);

  const TaskTitleField = (
    <ActivityFormItem
      name="taskRemainderTitle"
      label={taskReminderLabel}
      required
      rules={[
        {
          required: remainder,
          message: `${taskReminderLabel} is required`,
        },
      ]}
    >
      <Input placeholder={taskReminderPlaceholder} />
    </ActivityFormItem>
  );

  const DueDateField = (
    <ActivityFormItem
      name="taskRemainderDueDate"
      label={taskReminderDateLabel}
      required
      rules={[
        {
          required: remainder,
          message: `${taskReminderDateLabel} is required`,
        },
      ]}
    >
      <DatePicker
        placeholder={taskReminderDatePlaceholder}
        format={dateFormat}
        style={{ width: '100%', height: '32px' }}
      />
    </ActivityFormItem>
  );

  const AssignToTaskField = (
    <ActivityFormItem name="taskRemainderOwner" label={taskReminderAssignToLabel}>
      <AssignOwners
        placeholder={taskReminderAssignToPlaceholder}
        assigneeOptionType={assigneeOptionType}
      />
    </ActivityFormItem>
  );

  return (
    <>
      {TaskTitleField}
      <Row gutter={16}>
        <Col span={12}>{DueDateField}</Col>
        <Col span={12}>{AssignToTaskField}</Col>
      </Row>
    </>
  );
}
