import { PayloadAction } from '@reduxjs/toolkit';
import { CollectionPeriodsHistory, collectorPerformanceProps } from './type';
import { Page } from 'components/BaseComponents/Table/Pagination/TablePagination';
import { initialState } from '.';

export function initializeCollectionPeriods(
  state: collectorPerformanceProps,
  action: PayloadAction<CollectionPeriodsHistory[]>
) {
  state.collectionPeriods = action.payload;
  state.currentFilter =
    action.payload.find((period) => Boolean(period.current)) ?? action.payload[0];
}

export function updateCurrentFilter(
  state: collectorPerformanceProps,
  action: PayloadAction<{ id: number; period: CollectionPeriodsHistory }>
) {
  state.page = initialState.page;
  if (!action.payload.period) {
    state.currentFilter = state.collectionPeriods.find((period) => period.id === action.payload.id);
  }
  if (action.payload.period) {
    const isExist = state.collectionPeriods.find((period) => period.id === action.payload.id);

    if (!isExist) {
      state.currentFilter = { ...action.payload.period };
      state.collectionPeriods = [{ ...action.payload.period }, ...state.collectionPeriods];
    }
  }
}

export function resetCollectionPeriods(state: collectorPerformanceProps) {
  state.collectionPeriods = [];
  state.currentFilter = undefined;
}

export function updateCurrentPage(state: collectorPerformanceProps, action: PayloadAction<Page>) {
  state.page = action.payload;
}

export function resetCurrentPage(state: collectorPerformanceProps) {
  state.page = initialState.page;
}
