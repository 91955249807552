import { PromiseToPayStatus, Slippage } from 'types/activities/promise-to-pay';
import ky from './ky';

const baseUrl = 'promise-to-pay';
interface UpdatePromiseToPayStatusProps {
  status: PromiseToPayStatus;
}

export async function updatePromiseToPayStatus(
  params: UpdatePromiseToPayStatusProps,
  id: number
): Promise<unknown> {
  return await ky.put(`${baseUrl}/${id}/status`, { json: params });
}

interface SlippageProps {
  amount: string;
  comments: string;
  to_date: string;
}

export async function createSlippage(id: number, params: SlippageProps) {
  return (await ky
    .post(`${baseUrl}/${id}/slippage`, {
      json: params,
    })
    .json()) as Slippage;
}
