import { CustomFieldDataType, NumberFields } from 'types/entities/custom-field';
import { InvoiceSegmentFilter, InvoiceSegmentFilterType } from 'types/entities/invoice-segments';
import { validNumberBasedFields } from './../../types/entities/custom-field/index';
import {
  defaultBalanceAmountData,
  defaultInvoiceAmountData,
  defaultInvoiceCustomFieldData,
  defaultInvoiceCustomFieldDataNumberBased,
  defaultInvoiceDueDaysData,
  defaultInvoiceStatusData,
  defaultInvoiceSubsidiaryData,
} from './initial-state';
import { InvoiceSegmentFilterIdentifier } from './reducers';

export function isEqualSegmentFilters(
  filterA: InvoiceSegmentFilter,
  filterB: InvoiceSegmentFilter
) {
  if (
    filterA.type === InvoiceSegmentFilterType.CUSTOM_FIELD &&
    filterB.type === InvoiceSegmentFilterType.CUSTOM_FIELD
  ) {
    return filterA.id === filterB.id;
  }

  return filterA.type === filterB.type;
}

export function identifySegmentFilter(
  filter: InvoiceSegmentFilter,
  matcher: InvoiceSegmentFilterIdentifier
) {
  if (filter.type !== InvoiceSegmentFilterType.CUSTOM_FIELD) {
    return filter.type === matcher.type;
  }

  return filter.id === matcher.id;
}

export function getDefaultFilterDataFromType(
  type: InvoiceSegmentFilterType,
  customFieldDataType?: CustomFieldDataType
): InvoiceSegmentFilter {
  switch (type) {
    case InvoiceSegmentFilterType.INVOICE_AMOUNT:
      return defaultInvoiceAmountData;
    case InvoiceSegmentFilterType.INVOICE_DUE_DAYS:
      return defaultInvoiceDueDaysData;
    case InvoiceSegmentFilterType.INVOICE_STATUS:
      return defaultInvoiceStatusData;
    case InvoiceSegmentFilterType.INVOICE_BALANCE_AMOUNT:
      return defaultBalanceAmountData;
    case InvoiceSegmentFilterType.INVOICE_SUBSIDIARY:
      return defaultInvoiceSubsidiaryData;
    case InvoiceSegmentFilterType.CUSTOM_FIELD:
      return validNumberBasedFields.includes(customFieldDataType as unknown as NumberFields)
        ? defaultInvoiceCustomFieldDataNumberBased
        : defaultInvoiceCustomFieldData;
  }
}
