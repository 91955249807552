import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import React from 'react';

interface PopConfirmContentProps {
  title?: string;
  desc?: string;
  titleStyles?: React.CSSProperties;
  descStyles?: React.CSSProperties;
  iconColor?: React.CSSProperties['color'];
}

function PopConfirmContent(props: PopConfirmContentProps) {
  return (
    <Flex direction="column" gap="var(--space-4)">
      <Flex align="center" gap="var(--space-4)">
        <FontAwesomeIcon
          icon={['far', 'exclamation-circle']}
          color={props.iconColor ?? ' var(--gold-6)'}
        />
        <div style={props.titleStyles}>{props?.title}</div>
      </Flex>
      <Flex.Child style={props.descStyles}>{props?.desc}</Flex.Child>
    </Flex>
  );
}
export { PopConfirmContent };
