import { useQuery } from '@tanstack/react-query';
import { getCustomReportToken, getCustomReports } from 'services/reports';

export function useCustomReportToken() {
  const {
    data: customReportToken,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['INTEGRATION_TOKEN'],
    queryFn: async () => {
      return await getCustomReportToken();
    },
  });

  return { isLoading, customReportToken, refetch };
}

export function useIntegrationDashboards() {
  const {
    data: dashboardList,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['INTEGRATION_DASHBOARD'],
    queryFn: async () => {
      return await getCustomReports();
    },
  });

  return { isLoading, dashboardList, refetch };
}
