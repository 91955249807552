import { Flex } from 'components/BaseComponents/Layout/Flex';
import { BubbleMenu } from 'lib/RichText';
import { Editor } from 'lib/RichText/type';
import styled from 'styled-components';
import EditLink from '../Toolbar/Options/Insert/EditLink';
import UnsetLink from '../Toolbar/Options/Insert/UnsetLink';

const StyledMenu = styled(BubbleMenu)<{ menuWidth: React.CSSProperties['width'] }>`
  padding: var(--space-4);
  background-color: var(--gray-1);
  box-shadow: var(--shadow-3);
  border-radius: var(--br-1);

  &.open {
    display: block !important ;
  }
  &.close {
    display: none !important ;
  }
`;

interface BubbleMenusProps {
  editor: Editor;
  onOpenVisible?: (visible: boolean) => void;
  open: boolean;
  menuWidth?: React.CSSProperties['width'];
}

function LinkBubbleMenu(props: Readonly<BubbleMenusProps>) {
  const isOpen = !props.open && props.editor.isActive('link');

  const UnsetLinkMenu = (
    <StyledMenu
      editor={props.editor}
      updateDelay={500}
      pluginKey="unset-link"
      tippyOptions={{ appendTo: 'parent', placement: 'bottom-end' }}
      className={isOpen ? 'open' : 'close'}
      shouldShow={undefined}
      menuWidth={props.menuWidth ? props.menuWidth : '90px'}
    >
      <Flex align="center" gap="--space-4">
        <UnsetLink editor={props.editor} />
        <EditLink onVisibleChange={props.onOpenVisible} editor={props.editor} />
      </Flex>
    </StyledMenu>
  );

  return <div id="parent">{UnsetLinkMenu}</div>;
}

export default LinkBubbleMenu;
