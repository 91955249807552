import { TableInitialState } from '@sinecycle/growcomponents';
import { EActions, Eactions } from 'components/HigherOrderComponent/KeyActionsContainer/types';
import {
  useDraftCustomerDetailsInvoiceListQuery,
  useDraftInvoiceListQuery,
} from 'queries/invoices';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ELastContactActivity } from 'store/activity-feed/type';
import { InvoiceStatusType } from 'store/aging/types';
import { accountConfigSelector } from 'store/authentication/authentication';
import { IInvoices, IInvoicesQueryParams, StatementsResponse } from 'store/customer/types';
import { setInvoiceFlagList } from 'store/invoice-flag/invoice-flag';
import { IInvoiceFlagList, IInvoiceIdAndFlag } from 'store/invoice-flag/types';
import {
  DraftInvoicesListFilters,
  InvoiceDropdownInterfaceProps,
  OpenInvoicesListFilters,
} from 'store/invoice/type';
import { ELocalStorageVariable } from 'store/type';
import { DraftInvoiceListSummary } from 'types/api/invoice';
import { ParentChildConfigProps } from 'types/entities/custom-detail';
import { InvoiceStatus } from 'types/entities/invoice';
import { DraftInvoiceFilterParams } from 'types/filter-view/draft-invoice';
import { InvoiceStatementTable } from '../InvoiceStatements/InvoiceStatementTable';
import useDownloadPdf from '../InvoiceStatements/useDownloadPdf';
import { InvoiceStatementsActions } from '../QuickOperations/QuickOperations';
import { useColumn } from './Columns/useColumn';
import { DraftBulkAction } from './DraftBulkActions';
import useActions from './useActions';

export const draftInvoiceStatus = [InvoiceStatus.DRAFT];
const sortChildren = ['BALANCE_DUE_TOTAL_AMOUNT', 'DOCUMENT_CURRENCY', 'BALANCE_DUE_CURRENCY'];
const defaultSortColumn = 'DUE_DATE';
interface InvoicesStatementsProps {
  isFromInvoiceList?: boolean;
  customerId?: number;
  invoiceStatusList: InvoiceStatusType[];
  setQuickAction: (key: ELastContactActivity, invoiceList: InvoiceDropdownInterfaceProps[]) => void;
  currentFilter?: DraftInvoicesListFilters;
  saveFilterViews?: (config: DraftInvoicesListFilters) => void;
  isLoading?: boolean;
  hasActionAccess?: boolean;
  selectAll?: boolean;
  setSelectAll?: React.Dispatch<React.SetStateAction<boolean>>;
  setAction: (key: EActions, invoiceList: InvoiceDropdownInterfaceProps[]) => void;
  setTotalRecords?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCustomerTotalRecords?: (totalRecords: number | undefined) => void;
  scroll?: {
    x?: number | true | string;
    y?: string;
  };
  currentPage: number;
  handlePageChange: (page: number) => void;
  hasFilterViewLoaded?: boolean;
  action?: EActions;
}

const DraftInvoicesStatements = (props: InvoicesStatementsProps) => {
  const { handleDownload } = useDownloadPdf();
  const configList = useSelector(accountConfigSelector);
  const parentConfig = configList?.parent_child_config as ParentChildConfigProps;
  const bulkActionFilterParam = props.isFromInvoiceList
    ? props.currentFilter
    : ({
        ...props.currentFilter,
        view_child: parentConfig.view_child,
      } as OpenInvoicesListFilters);

  const [tableState, setTableState] = useState<TableInitialState<IInvoices>>({
    currentPage: 1,
    selectedRows: [],
    selectAll: { select_all: Boolean(props.selectAll), selected: false },
    bulk_records: 0,
  });
  const [pageSize] = useState<number>(20);
  const [invoiceListSummary, setInvoiceListSummary] = useState<DraftInvoiceListSummary>();

  const { customerQuickActions, invoiceQuickActions } = useActions({
    selectAll: tableState.selectAll.select_all,
    setAction: props.setAction,
    setQuickAction: props.setQuickAction,
    isFromInvoiceList: Boolean(props.isFromInvoiceList),
  });

  const dispatch = useDispatch();

  function getParams() {
    return {
      ...(props.currentFilter ? props.currentFilter : ({} as DraftInvoicesListFilters)),
      filter_type: InvoiceStatus.DRAFT,
      status: draftInvoiceStatus,
      sort_by: props.currentFilter?.sort_by ?? 'ASC',
      sort_col:
        props.currentFilter?.sort_col === 'DUE_AMOUNT' //defensive coding to ensure we don't use due_amount column which might be saved in cache
          ? defaultSortColumn
          : props.currentFilter?.sort_col ?? defaultSortColumn,
      sort_by_custom_field: props.currentFilter?.sort_by_custom_field,
      size: pageSize,
      page: props.currentPage ?? 1,
    } as IInvoicesQueryParams;
  }

  const param = useMemo(getParams, [props.currentFilter, props.currentPage, pageSize]);
  function handleSuccessCallBack(data: StatementsResponse<IInvoices, DraftInvoiceListSummary>) {
    if (!props.isFromInvoiceList) {
      localStorage.setItem(
        ELocalStorageVariable.DRAFT_INVOICE_CUSTOMER_STATEMENTS,
        JSON.stringify(param)
      );
      if (!props.currentFilter) {
        props.saveFilterViews?.(param as unknown as DraftInvoicesListFilters);
      }
    }

    setInvoiceListSummary(data.summary);
    setTableState({
      ...tableState,
      bulk_records: data.total_records,
      currentPage: data.current_page,
    });
    props.setTotalRecords && props.setTotalRecords(data.total_records);
    props.setCustomerTotalRecords && props.setCustomerTotalRecords(data.total_customer_records);
    const flagList = {} as IInvoiceFlagList;
    flagList.list = [] as IInvoiceIdAndFlag[];
    data.list.forEach((invoice) => {
      flagList.list.push({
        id: invoice.id,
        flag: invoice.invoice_flag ? true : false,
      } as IInvoiceIdAndFlag);
    });
    dispatch(setInvoiceFlagList(flagList));
  }

  const { data: draftInvoice, isLoading } = useDraftInvoiceListQuery({
    onSuccessCallBack: handleSuccessCallBack,
    param: param?.status?.length ? param : { ...param, status: draftInvoiceStatus },
    enable: props.isFromInvoiceList && props.hasFilterViewLoaded,
  });
  const draftReqParam = { ...param, view_child: parentConfig.view_child };
  const { data: draftInvoiceCustomer, isLoading: customerLoading } =
    useDraftCustomerDetailsInvoiceListQuery({
      onSuccessCallBack: handleSuccessCallBack,
      param:
        draftReqParam?.status?.length === 0
          ? { ...draftReqParam, status: draftInvoiceStatus }
          : draftReqParam,
      customerId: String(props.customerId),
      enable: !props.isFromInvoiceList,
    });

  const updateFilterView = (value: DraftInvoicesListFilters) => {
    if (props.saveFilterViews) {
      props.saveFilterViews({
        ...(props.currentFilter ? props.currentFilter : ({} as DraftInvoicesListFilters)),
        ...value,
      });
    }
  };

  const loading = props.isFromInvoiceList ? isLoading : customerLoading;

  const { column } = useColumn(
    invoiceListSummary,
    props.isFromInvoiceList,
    tableState.bulk_records,
    tableState.currentPage,
    props.currentFilter as unknown as DraftInvoiceFilterParams
  );

  return (
    <InvoiceStatementTable
      loading={loading}
      data={props.isFromInvoiceList ? draftInvoice?.list : draftInvoiceCustomer?.list}
      onSortChange={(values) => {
        updateFilterView({
          sort_by: values.sortOrder as string,
          sort_col: sortChildren.includes(values.sortCol)
            ? 'BALANCE_DUE_AMOUNT'
            : values.sortCol.startsWith('CUSTOM_FIELD')
            ? 'CUSTOM_FIELD'
            : values.sortCol,
          sort_by_custom_field: values.customCol,
        } as DraftInvoicesListFilters);
      }}
      getUpdatedTableValue={(value) => {
        setTableState(value);
        props.setSelectAll?.(value.selectAll.select_all);
      }}
      onPageChange={(page) => {
        props.handlePageChange(page);
      }}
      columns={column}
      tableState={tableState}
      rowClassRules={{
        'flagged-row': (params) => {
          return Boolean(params.data?.invoice_flag);
        },
      }}
      bulkActions={
        <DraftBulkAction
          setAction={props.setAction}
          setQuickAction={props.setQuickAction}
          selectAll={tableState.selectAll.select_all}
          isFromInvoiceList={props.isFromInvoiceList}
          currentFilterParam={bulkActionFilterParam}
          bulkRecordsLength={tableState.bulk_records}
          customerId={props?.customerId}
          action={props?.action}
        />
      }
      quickOperations={{
        items: props.isFromInvoiceList ? invoiceQuickActions : customerQuickActions,
        onClick(key, selectedRows) {
          const selectedRow = selectedRows?.map(
            (f) => f.data
          ) as unknown as InvoiceDropdownInterfaceProps[];

          if (key === EActions.DOWNLOAD_PDF) {
            handleDownload(selectedRows);
            return;
          }
          if (EActions.EMAIL !== key && Eactions.includes(key as EActions)) {
            props.setAction(key as EActions, selectedRow);
          } else if (EActions.EMAIL === key) {
            props.setQuickAction(ELastContactActivity.EMAIL, selectedRow);
          }
        },
        component: <InvoiceStatementsActions />,
      }}
      isFromInvoiceList={props.isFromInvoiceList}
      columnPreserveKey={props.isFromInvoiceList ? 'COLUMNS_DRAFT_IN' : 'COLUMNS_DRAFT_IN_C'}
      currentFilter={bulkActionFilterParam}
    />
  );
};

export default React.memo(DraftInvoicesStatements);
