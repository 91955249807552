import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsMutating } from '@tanstack/react-query';
import { Flex } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { GrowText } from 'components/BaseComponents/Typography';
import { useState } from 'react';
import useOpen from 'util/hooks/useOpen';
import FormModal from '../FormModal/FormModal';
import { activityText } from '../text';
import { DocumentFormElementProps, DocumentFormModalProps } from '../types';
import { onFormSave } from '../Utils';
import DocumentForm from './DocumentForm';

export default function DocumentFormModal<DocumentElements extends DocumentFormElementProps>(
  props: Omit<DocumentFormModalProps<DocumentElements>, 'form' | 'onFormClose' | 'handleDisable'>
) {
  const { openModal, id, onClose } = props;
  const [disabled, setDisabled] = useState(false);
  const [form] = useForm();
  const isFormSaving = useIsMutating({
    mutationKey: ['activity-forms'],
  });

  const { open, onClose: modalClose } = useOpen({
    open: openModal,
  });

  function formSubmit() {
    onFormSave(form);
  }

  function onCancel() {
    modalClose();
    onClose?.();
  }

  const Header = (
    <Flex gap={'var(--space-8)'} align="center">
      <FontAwesomeIcon icon={['far', 'file-arrow-up']} color="var(--cyan-6)" fontSize={18} />
      <GrowText size="var(--fs-20)" strong>
        {activityText.create_document}
      </GrowText>
    </Flex>
  );

  return (
    <FormModal
      open={open}
      onCancel={onCancel}
      formSubmit={formSubmit}
      isLoading={Boolean(isFormSaving)}
      headerTitle={Header}
      id={Number(id)}
      enableExtraAction={false}
      disabled={disabled}
    >
      <DocumentForm<DocumentElements>
        form={form}
        onFormClose={onCancel}
        handleDisable={(value) => {
          setDisabled(value);
        }}
        {...props}
      />
    </FormModal>
  );
}
