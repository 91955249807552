import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input } from 'antd';
import styled from 'styled-components';
interface placeholderSearchProps {
  onSearchCallBack: (value: string) => void;
  onClear: () => void;
  searchQuery: string | undefined;
}
const t = {
  placeholder: 'Search Template',
};

const StyledPlaceHolderSearch = styled(Input)`
  margin: var(--space-16) var(--space-16) var(--space-12) var(--space-16);
  padding: var(--space-0) var(--space-0) var(--space-0) var(--space-12);
  width: 218px;
  border: 1px solid var(--gray-5);

  &.ant-input-affix-wrapper-focused {
    border: 1px solid var(--purple-5) !important;
    box-shadow: none !important;
    .prefix-search-icon {
      color: var(--purple-7);
    }
  }
`;

const CancelSearchStyle: React.CSSProperties = {
  border: 0,
  boxShadow: 'none',
  fontSize: 'var(--fs-14) !important',
};

function PlaceholderSearch(props: placeholderSearchProps) {
  const CancelSearch = (
    <Button
      style={CancelSearchStyle}
      icon={<FontAwesomeIcon icon={['far', 'times']} color="var(--gray-7)" />}
      onClick={props.onClear}
    />
  );

  const PrefixIcon = (
    <FontAwesomeIcon
      className="prefix-search-icon"
      style={{ marginRight: 'var(--space-4)' }}
      icon={['far', 'search']}
      color="var(--purple-9)"
    />
  );

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    props.onSearchCallBack(e?.target?.value.toLowerCase());
  }

  return (
    <StyledPlaceHolderSearch
      value={props.searchQuery}
      size="small"
      suffix={CancelSearch}
      prefix={PrefixIcon}
      placeholder={t.placeholder}
      onChange={handleSearchChange}
    />
  );
}

export { PlaceholderSearch };
