import { EmailSignature } from 'services/signature';
import { CustomDetailConfig, ParentChildConfigProps, PasswordDetailsConfig } from './custom-detail';
import { UserBasic } from './user';

export enum ArEmailStatus {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  EXPIRED = 'EXPIRED',
}

export enum AccountConfigKey {
  STRATEGY_V2 = 'strategy_v2_enabled',
  PAYMENT_LINK = 'payment_link_enabled',
  CUSTOMER_STATEMENT = 'customer_statement_enabled',
  CUSTOMER_REPORT = 'custom_reports_enabled',
  ADVANCED_REPORTS = 'advanced_report_enabled',
  HIDE_DASHBOARD_TOTAL_COLLECTED_TILE = 'hide_dashboard_total_collected_tile',
  ALLOW_NON_ADMINS_CREATE_DISPUTE_TYPE = 'allow_non_admins_create_dispute_type',
  CUSTOM_CUSTOMER_DETAILS_CONFIG = 'custom_customer_details_config',
  SHOULD_EVALUATE_BALANCES = 'evaluate_balance',
  HEALTH_SCORE_ENABLED = 'health_score_enabled',
  MULTI_CURRENCY_ENABLED = 'multi_currency_enabled',
  CUSTOMER_PORTAL_ENABLED = 'customer_portal_enabled',
  PARENT_CHILD_ENABLED = 'parent_child_enabled',
  ADVANCED_CONTACTS = 'advanced_contacts',
  SUBSIDIARY_ENABLED = 'subsidiary_enabled',
  DRAFT_INVOICE_ENABLED = 'draft_invoice_enabled',
  DRAFT_METRIC_ENABLED = 'draft_metrics_enabled',
  PASSWORD_CONFIG = 'password_config',
  WORKFLOW_ENABLED = 'workflow_enabled',
  SHOW_BAD_DEBT_TILE = 'show_dashboard_bad_debt_tile',
  NET_SALES_ENABLED = 'net_sales_enabled',
  PARENT_CHILD_CONFIG = 'parent_child_config',
  GMS_ENABLED = 'gms_enabled',
}

export type AccountConfig = Record<
  AccountConfigKey,
  boolean | CustomDetailConfig[] | ParentChildConfigProps
>;

export type PasswordConfig = AccountConfig & PasswordDetailsConfig;

export interface AccountBase {
  id: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  email: string;
  currency: string;
  logo_url: string;
  phone_no: string;
  ar_email: string;
  ar_email_status?: ArEmailStatus;
  time_zone: string;
  configs: AccountConfig;
  locale: string;
  date_format: string;
}

export interface Account extends AccountBase {
  ar_email_user_ids: number[];
  email_signature: EmailSignature | null;
  subsidiary_logo_url?: string;
  fav_icon_url?: string;
}

export interface AccountDetails extends AccountBase {
  ar_email_users?: UserBasic[];
}

export interface CurrentAccount {
  id: number;
}

export enum LOGO_TYPE {
  LOGO = 'LOGO',
  FAV_ICON = 'FAV_ICON',
}
export interface AccountLogoDetails {
  [LOGO_TYPE.FAV_ICON]?: File;
  [LOGO_TYPE.LOGO]?: File;
}
