import { CashAppProvider } from '@growfin/asgard';
import { ThemeProvider } from '@sinecycle/growcomponents';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import initIcons from 'icons';
import { HTTPError } from 'ky';
import { notifyError } from 'lib/error-handler';
import { errorMonitor } from 'lib/error-monitor';
import { logRocket } from 'lib/logrocket';
import { tracker } from 'lib/tracker';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store/store';
import './styles/index.css';

initIcons();
logRocket.init();
logRocket.logSessionURL();
tracker.init();
errorMonitor.init();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: (error) => {
        notifyError(error as HTTPError);
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CashAppProvider>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ThemeProvider>
      </CashAppProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
