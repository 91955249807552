import { assign, castArray, get, head, isArray, isObject, omit, uniq } from 'lodash';
import { ActivityKey } from 'queries/activites';
import {
  getInvoiceLineItems,
  getInvoicesSuggestions,
  getRecentInvoicesOfCustomer,
} from 'services/invoice';
import { ActivityFeedCollection } from 'store/activity-feed/type';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import { ActivityType } from 'types/activities/activity-types';
import { PerformedActionType, SubscribedEntityType } from 'types/api/inbox/activity';
import transformData from 'util/transformData';
import { getActivitySchemaMapping } from './ActivitiesList/Predicate';
import { ActionableEntity } from './Email';
import { LatestActivityData, customerDetailsProps } from './type';

export function tranformActivitySchema(
  activity?: ActivityFeedCollection
): SubscribedEntityType | undefined {
  const data =
    activity?.parent && activity?.parent_type !== 'EMAIL'
      ? transformData<ActivityFeedCollection, SubscribedEntityType>({
          originalData: activity,
          schemaMapping: getActivitySchemaMapping(activity.parent_type),
        })
      : null;

  const getLatestActivity = (activity: ActivityFeedCollection | undefined): LatestActivityData => {
    if (get(activity, 'child_type') === 'COMMENT') {
      return {
        type: PerformedActionType.COMMENT_ADDED,
        actor: get(activity, 'child.created_by')!,
        createdDate: get(activity, 'child.created_date') ?? '',
        showAvatar: true,
      };
    } else {
      return {
        type: PerformedActionType.CREATED,
        actor: get(activity, 'parent.created_by')!,
        createdDate: get(activity, 'parent.created_date') ?? '',
        showAvatar: true,
      };
    }
  };

  if (isObject(data)) {
    return assign({}, data, {
      latest_activity: getLatestActivity(activity),
    });
  } else {
    return undefined;
  }
}

export function activityFormDataFormat<T>(
  data: T,
  type: ActivityKey,
  invoice?: InvoiceDropdownInterfaceProps,
  customerId?: number
) {
  if (type === ActivityType.CALL_LOG || type === ActivityType.TASK) {
    return assign({}, omit(data as unknown as object, 'taskDetails'), {
      customer_ids: castArray(customerId),
    });
  }
  if (type === ActivityType.ESCALATION) {
    return assign({}, omit(data as unknown as object, 'taskDetails'), {
      customer_id: customerId,
    });
  }
  if (type === ActivityType.NOTE) {
    return assign({}, omit(data as unknown as object, 'taskDetails'), {
      ref_id_list: invoice ? invoice : castArray(customerId),
      type: invoice ? ActionableEntity.INVOICE : ActionableEntity.CUSTOMER,
    });
  }
  return omit(data as unknown as object, 'taskDetails');
}

export function lineItemsFn(customerId?: number | number[]) {
  return {
    lineItemsQueryFn: (invoiceIdsList?: number[]) => getInvoiceLineItems(invoiceIdsList),
    lineItemsQueryKey: ['invoiceLineItems', customerId],
  };
}

export function invoiceItemsFn<T>(customerId?: number | number[], viewChild?: boolean) {
  const customerDetails = isArray(customerId) ? customerId : castArray(customerId);
  const customerIdData = head(uniq(customerDetails as unknown as number[]));

  return {
    invoiceQueryFn: () => getRecentInvoicesOfCustomer<T>(customerIdData, viewChild),
    invoiceSearchFn: (searchTerm: string) =>
      getInvoicesSuggestions<T>(searchTerm, customerIdData, viewChild),
    invoiceQueryKey: ['recent-customer-invoices', customerIdData, viewChild],
  };
}

export function destructureWithCustomerInfo(
  activity: ActivityFeedCollection,
  customerDetails?: customerDetailsProps
): ActivityFeedCollection | undefined {
  if (activity.customer_info) {
    return assign({}, activity, {
      parent: assign({}, activity.parent, {
        customer_info: activity.customer_info,
      }),
    });
  }

  return assign({}, activity, {
    parent: assign({}, activity.parent, {
      customer_info: {
        customer_id: customerDetails?.id,
        customer_name: customerDetails?.name,
      },
    }),
  });
}
