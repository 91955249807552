import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { StyledIconButton, StyledPreviewItem } from 'components/Common/Styles/Styles';
import { getDocTypeIcon } from 'components/HigherOrderComponent/DocViewer';
import { FileType, ValidFileType } from 'types/common/attachments';

interface CustomerStatementPreviewProps {
  hide?: boolean;
  fileType: FileType;
  onRemove: () => void;
}

const t = {
  title: 'Customer Statement',
};
function CustomerStatementPreview(props: Readonly<CustomerStatementPreviewProps>) {
  if (props.hide) return null;
  const FileIcon = getDocTypeIcon(props.fileType as ValidFileType);
  const Title = <Texto color="var(--purple-7)">{t.title}</Texto>;

  const CloseIcon = <FontAwesomeIcon icon={['far', 'times']} color="var(--gray-7)" />;
  const CloseButton = (
    <Tooltip placement="top" title="Remove" mouseEnterDelay={0.5}>
      <StyledIconButton
        icon={CloseIcon}
        size="small"
        style={{ width: '12px', height: '12px', display: 'flex' }}
        onClick={props.onRemove}
      />
    </Tooltip>
  );
  const FileInfo = (
    <Flex align="center" gap="--space-8">
      {FileIcon}
      {Title}
    </Flex>
  );
  return (
    <StyledPreviewItem align="center" justify="space-between" gap="--space-8">
      {FileInfo}
      {CloseButton}
    </StyledPreviewItem>
  );
}

export default CustomerStatementPreview;
