import { Card } from 'antd';
import { styled } from 'lib/css-in-js';

export const StyledCardWrapper = styled(Card)`
  width: 640px;
  background: var(--gray-1);
  border-radius: var(--br-3);
  box-shadow: var(--shadow-2);
  .ant-card-body {
    padding: var(--space-32);
  }
`;
