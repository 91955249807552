import { DetailsLayout } from 'components/CollectionActivities/Common/Details/DetailsLayout';

import ActivitiesModalLoader from 'components/CollectionActivities/Common/ActivitiesModalLoader';
import useEmitActivityEvents from 'components/CollectionActivities/hooks/useEmitActivityEvents';
import { ActivityLoader } from 'components/CollectionActivities/style';
import { useCollectionActivityQuery } from 'queries/activites';
import { ActivityDetailsBaseProps } from 'types/activities/activity-types';
import { Escalation } from 'types/activities/escalation';
import { EscalationDetailsBody } from './EscalationDetailsBody';
import { EscalationDetailsInfo } from './EscalationDetailsInfo';

interface EscalationDetailsProps extends ActivityDetailsBaseProps {}
function EscalationDetails(props: EscalationDetailsProps) {
  const {
    id,
    type,
    hideActions,
    customer,
    mode,
    subscriptionType,
    isListFetched,
    onDelete,
    editLoading,
    deleteLoading,
    activitiesMode,
  } = props;
  const { data, isLoading, error } = useCollectionActivityQuery<Escalation>({
    id,
    type,
    isListFetched,
  });
  const isUnauthorized = error ? error?.status === 403 : false;
  useEmitActivityEvents(isLoading);
  if (activitiesMode && isLoading) {
    return (
      <ActivityLoader>
        <ActivitiesModalLoader />
      </ActivityLoader>
    );
  }

  return (
    <DetailsLayout
      mode={mode}
      isUnauthorized={isUnauthorized}
      content={
        <EscalationDetailsBody
          data={data}
          isLoading={isLoading}
          hideActions={hideActions}
          customer={customer}
          subscriptionType={subscriptionType}
          onDelete={onDelete}
          editLoading={editLoading}
          deleteLoading={deleteLoading}
          activitiesMode={activitiesMode}
        />
      }
      info={
        <EscalationDetailsInfo
          loading={isLoading}
          data={data}
          customer={customer}
          activitiesMode={activitiesMode}
        />
      }
    />
  );
}

export { EscalationDetails };
