import { forEach, get, isArray, isBoolean, isObject, merge } from 'lodash';

interface TransformDataProps<T, K> {
  originalData: T;
  schemaMapping?: Record<string, any>;
  customTransformers?: (data: T, customProps?: K) => Record<string, any>;
  customTransformerProps?: K;
}

/**
 * Transforms the original data based on the provided schema mapping.
 *
 * @template T - The type of the original data.
 * @template U - The type of the transformed data.
 * @param {T} originalData - The original data to be transformed.
 * @param {K} customProps - Custom Props for the custom tranformer.
 * @param {Record<string, any>} [schemaMapping] - The mapping of keys to values in the original data.
 * @param {Function} [customTransformers] - Custom transformation function for transforming the entire data.
 * @returns {U} - The transformed data.
 */
export default function transformData<T, U, K = unknown>(props: TransformDataProps<T, K>): U {
  const { originalData, schemaMapping, customTransformers, customTransformerProps } = props;
  /**
   * Cache map to store the transformed values.
   */
  const cache = new Map<string, unknown>();

  /**
   * Transforms the given value based on its type.
   *
   * @param {string} key - The key of the value.
   * @param {string | any} value - The value to be transformed.
   * @returns {unknown} - The transformed value.
   */
  const customTransformersResult =
    customTransformers && customTransformers(originalData, customTransformerProps);

  function transformValue(key: string, value: string): unknown {
    if (customTransformersResult && key in customTransformersResult) {
      // If a custom transformer exists for the key, use it to transform the value
      return customTransformersResult[key];
    } else if (isArray(value)) {
      return (
        value
          // If the value is an array, map each path to its corresponding value in the original data
          .map((path: string) => {
            const pathSegments = path.split('.');
            return get(originalData, pathSegments);
          })
          .filter(Boolean)
      );
    } else if (isObject(value)) {
      // If the value is an object, recursively transform the original data using the value as the schema mapping
      return transformData<T, U, K>({
        originalData,
        schemaMapping: value,
        customTransformers,
        customTransformerProps,
      });
    } else if (isBoolean(value)) {
      // If the value is a boolean, returns it
      return value;
    } else {
      // If the value is a string and no custom transformer exists for the key, get the corresponding value in the original data
      const result = get(originalData, value.split('.'));
      return result !== undefined ? result : undefined;
    }
  }

  /**
   * Gets the transformed value for the given key and value.
   *
   * @param {string} key - The key of the value.
   * @param {string} value - The value to be transformed.
   * @returns {unknown} - The transformed value.
   */
  function getTransformedValue(key: string, value: string): unknown {
    const cacheKey = `${key}:${value}`;
    if (!cache.has(cacheKey)) {
      cache.set(cacheKey, transformValue(key, value));
    }
    return cache.get(cacheKey);
  }

  const transformedObject = {} as U;

  forEach(schemaMapping, (value, key) => {
    const transformedValue = getTransformedValue(key, value);
    merge(transformedObject, { [key]: transformedValue });
  });

  return transformedObject;
}
