import { TagsSearchResponseInterface } from '../store/aging/types';
import ky from './ky';

const baseUrl = 'tags';

export async function getTagsForIds(tags: number[]) {
  return (await ky.get(`${baseUrl}`, { searchParams: { ids: tags.toString() } }).json()) as {
    id: number;
    name: string;
  }[];
}

export async function searchTags(searchWord: string) {
  return (await ky
    .get(`${baseUrl}/suggestions?keyword=${searchWord}`)
    .json()) as TagsSearchResponseInterface[];
}

export async function getLatestTags() {
  return (await ky.get(`${baseUrl}/latest`).json()) as TagsSearchResponseInterface[];
}

export async function getAllTags() {
  return (await ky.get(`${baseUrl}`).json()) as TagsSearchResponseInterface[];
}
