import { useCallback, useEffect, useState } from 'react';

function useDisableStatus() {
  const EventType = 'UPLOADING';

  const [disabled, setDisabled] = useState(false);

  function handleDispatchEvent(status: boolean) {
    const event = new CustomEvent(EventType, {
      detail: status,
    });
    document.dispatchEvent(event);
  }
  function handleDisableCallBack(event: CustomEvent) {
    if (event.type === EventType) {
      setDisabled(event.detail);
    }
  }

  const disableCallback = useCallback(handleDisableCallBack, []);

  function handleEventListener() {
    document.addEventListener(EventType, disableCallback as EventListener);
    return () => {
      document.addEventListener(EventType, disableCallback as EventListener);
    };
  }

  useEffect(handleEventListener, [disableCallback]);

  return {
    disabled,
    handleDispatchEvent,
  };
}

export default useDisableStatus;
