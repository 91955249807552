import { Divider } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Expansion } from 'components/Common/Expansion/Expansion';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { drop, take } from 'lodash';
import { useState } from 'react';
import { Commento } from 'types/activities/comment';
import { Komento } from '.';

export function CommentsList({ comments }: { comments?: Commento[] }) {
  const [open, setOpen] = useState(false);
  if (!comments?.length) return null;

  const first = take(comments, 3);
  const rest = drop(comments, 3);

  const Rest = (
    <HideWrapper hide={!rest.length}>
      <>
        {open ? (
          rest.map((comment, key) => {
            return (
              <CommentSection
                comment={comment}
                hideDivider={Boolean(key === rest.length - 1)}
                key={comment.id}
              />
            );
          })
        ) : (
          <Divider style={{ margin: 'var(--space-16) 0' }}>
            <Expansion
              onClick={() => {
                setOpen(true);
              }}
              text={`${rest.length} more`}
            />
          </Divider>
        )}
      </>
    </HideWrapper>
  );
  return (
    <Flex direction="column" gap="var(--space-6)">
      {first.map((comment, key) => {
        return (
          <CommentSection
            comment={comment}
            hideDivider={open ? Boolean(!rest.length && open) : Boolean(key === first.length - 1)}
            key={comment.id}
          />
        );
      })}
      {Rest}
    </Flex>
  );
}

export function CommentSection({
  comment,
  hideDivider,
}: {
  comment: Commento;
  hideDivider: boolean;
}) {
  return (
    <>
      <Komento {...comment} />
      <HideWrapper hide={hideDivider}>
        <Divider style={{ margin: 'var(--space-16) 0' }} />
      </HideWrapper>
    </>
  );
}
