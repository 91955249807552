import { Button } from 'antd';
import { GrowCard } from 'components/BaseComponents/Card';
import { GrowRow } from 'components/BaseComponents/GrowGrid';
import { Style } from 'lib/style/style';

export const ActivitiesWrapper = Style(GrowRow, {
  background: 'var(--background-1)',
  overflow: 'hidden',
  height: 'calc(100vh - calc(64px + 56px + 56px))',
  scrollbarWidth: 'none',
  scrollBehavior: 'smooth',

  variants: {
    mode: {
      activites: {
        borderRadius: 'var(--br-1)',
        width: '100%',
      },
    },
  },
});

export const InboxActivityCard = Style(GrowCard, {
  borderRadius: 'var(--br-1)',
  width: '300px',
  border: '1px solid var(--gray-4)',
  lineHeight: '1.2',

  '.activity-actions-update': {
    display: 'none',
  },

  '&:hover': {
    border: '1px solid var(--primary-4)',
  },
  '.ant-card-body': {
    padding: 'var(--space-16)',
    maxHeight: '160px',
  },
  '.ant-card-actions': {
    padding: 'var(--space-8) var(--space-16) var(--space-16) var(--space-16)',
    borderRadius: '0 0 var(--br-1)  var(--br-1)',
    border: 0,

    li: {
      margin: 0,

      '&:first-child': {
        borderTop: '1px solid var(--gray-4)',

        '&>:first-child': {
          marginTop: 'var(--space-8)',
        },
      },
    },
  },

  variants: {
    selected: {
      true: {
        border: '2px solid var(--primary-5)',
        boxShadow: 'var(--shadow-2)',
        '&:hover': {
          border: '2px solid var(--primary-5)',
          boxShadow: 'var(--shadow-2)',
        },
      },
    },
    loader: {
      true: {
        minHeight: '120px',
      },
    },
    hasFooter: {
      true: {
        '.ant-card-body': {
          paddingBottom: 'var(--space-8)',
        },
      },
    },
    mode: {
      dashboard: {
        width: '100%',
        boxShadow: 'none',

        '&:hover': {
          border: '1px solid var(--primary-4)',
        },
      },
      activitiesMode: {
        width: '640px',
        boxShadow: 'none',

        '&:hover': {
          border: '1px solid var(--primary-4)',
          '.activity-actions-update': {
            display: 'inline-flex',
            height: '24px',
          },

          '.activity-status': {
            display: 'none',
          },
        },
      },
    },
  },
});

export const ActivityIconButton = Style(Button, {
  '&.ant-btn': {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.hide-icon': {
    display: 'none',
    fontSize: 'var(--fs-12) !important',
  },
  '.default-icon': {
    display: 'block',
  },

  '&:hover': {
    '&.ant-btn': {
      backgroundColor: 'var(--primary-1)',
    },
    '.hide-icon': {
      display: 'block',
    },
    '.default-icon': {
      display: 'none',
    },
  },

  variants: {
    viewMode: {
      true: {
        pointerEvents: 'none',

        '&.ant-btn': {
          backgroundColor: 'var(--activityBg)',
          boxShadow: 'none !important',
          border: '0 !important',
        },
      },
    },
    hideBackground: {
      true: {
        '&:hover': {
          '&.ant-btn': {
            backgroundColor: 'inherit',
          },
        },
      },
    },
  },
});
