import {
  FollowupStatus,
  LastActivityType,
  LastFollowupActivityType,
} from 'types/activities/activity-types';
import { InvoicePaymentStatus } from 'types/activities/payment';
import { CollectionPeriod } from './collection-period';
import { BaseCustomer } from './customer';
import { User } from './user';

export const invoiceStatus = [
  'PENDING',
  'REOPEN',
  'DISPUTE',
  'PARTIAL_PAYMENT',
  'FULL_PAYMENT',
  'WRITE_OFF',
  'CLOSED',
  'DRAFT',
  'VOID',
] as const;

export type InvoiceStatuses = (typeof invoiceStatus)[number];
enum ClosedInvoiceType {
  CREDIT_MEMO = 'CREDIT_MEMO',
  WRITE_OFF = 'WRITE_OFF',
  PAYMENT = 'PAYMENT',
}
export enum StatementViews {
  INVOICES = 'INVOICES',
  OPEN_INVOICES = 'OPEN_INVOICES',
  DRAFT_INVOICES = 'DRAFT_INVOICES',
  PAYMENTS = 'PAYMENTS',
  CREDIT_ADJUSTMENTS = 'CREDIT_ADJUSTMENTS',
  CLOSED_INVOICES = 'CLOSED_INVOICES',
  ACTIVITIES = 'ACTIVITIES',
  PROFILE = 'PROFILE',
  DOCUMENTS = 'DOCUMENTS',
  OTHER_DETAILS = 'OTHER_DETAILS',
  LINE_ITEMS = 'LINE_ITEMS',
  ALL = 'ALL',
  EMAIL = 'EMAIL',
  AUTOMATIONS = 'AUTOMATIONS',
}

export enum InvoiceStatus {
  PENDING = 'PENDING',
  REOPEN = 'REOPEN',
  DISPUTE = 'DISPUTE',
  PARTIAL_PAYMENT = 'PARTIAL_PAYMENT',
  FULL_PAYMENT = 'FULL_PAYMENT',
  WRITE_OFF = 'WRITE_OFF',
  CLOSED = 'CLOSED',
  VOID = 'VOID',
  DRAFT = 'DRAFT',
}
export enum InvoiceEmailDeliveryStatus {
  NOT_DELIVERED = 'NOT_DELIVERED',
  IN_PROGRESS = 'IN_PROGRESS',
  ATTEMPTED = 'ATTEMPTED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  DELIVERED = 'DELIVERED',
  SCHEDULED = 'SCHEDULED',
}
export type InvoiceEmaiLDeliveryStatusOptions = {
  label: string;
  value: InvoiceEmailDeliveryStatus;
}[];

interface ClosedInvoiceDetails {
  id: number;
  type: ClosedInvoiceType;
  date: Date;
  amount: number;
}

export interface Invoice extends InvoiceBasicDetails {
  balance_due_amount: number;
  amount: number;
  payment_status: InvoicePaymentStatus;
  followup_status: FollowupStatus;
  followup_date: string;
  followup_title: string;
  closed_invoice_details: ClosedInvoiceDetails;
  closed_date: string;
  closed_by: User;
  last_activity_type: LastActivityType;
  last_activity_date: string;
  last_followup_activity_type: LastFollowupActivityType;
  last_followup_activity_date: string;
  collection_period?: CollectionPeriod;
  deleted?: boolean;
}

export interface InvoiceBasicDetails {
  id: number;
  currency: string;
  status: InvoiceStatus;
  invoice_no: string;
  invoice_title: string;
  invoice_url: string;
  invoice_status: InvoiceStatus;
  balance_due_amount_base_currency: number;
  amount_base_currency: number;
  amount_applied?: number;
  amount_applied_base_currency?: number;
  due_date: string;
  status_updated_date: string;
  invoice_date: string;
  customer_id: number;
  customer_name?: string;
  created_by: User;
  amount: number;
  created_date: string;
  customer?: BaseCustomer;
}

export interface InvoiceLineItem {
  id: number;
  source_id: number | null;
  productName: string;
  quantity: number;
  amount: number;
  amount_base_currency: number;
  currency: string;
  unitPrice: number;
}

export const invoiceEmailDeliveryStatusOptions: InvoiceEmaiLDeliveryStatusOptions = [
  {
    value: InvoiceEmailDeliveryStatus.ATTEMPTED,
    label: 'Attempted',
  },
  {
    value: InvoiceEmailDeliveryStatus.IN_PROGRESS,
    label: 'In Progress',
  },
  {
    value: InvoiceEmailDeliveryStatus.NOT_APPLICABLE,
    label: 'Not Applicable',
  },
  {
    value: InvoiceEmailDeliveryStatus.NOT_DELIVERED,
    label: 'Not Delivered',
  },
  {
    value: InvoiceEmailDeliveryStatus.DELIVERED,
    label: 'Delivered',
  },
  {
    value: InvoiceEmailDeliveryStatus.SCHEDULED,
    label: 'Scheduled',
  },
];

export type InvoiceDerivedStatus = 'ALL' | 'OPEN' | 'CLOSED' | 'DRAFT';

export type InvoiceStatusesConfig = Record<InvoiceDerivedStatus, InvoiceStatuses[]>;

export const invoiceStatusesConfig: InvoiceStatusesConfig = {
  CLOSED: ['WRITE_OFF', 'CLOSED', 'VOID'],
  ALL: [
    'PENDING',
    'REOPEN',
    'DISPUTE',
    'PARTIAL_PAYMENT',
    'FULL_PAYMENT',
    'WRITE_OFF',
    'CLOSED',
    'VOID',
  ],
  OPEN: ['PENDING', 'DISPUTE', 'PARTIAL_PAYMENT', 'FULL_PAYMENT'],
  DRAFT: ['DRAFT'],
};
