import { Radio, RadioChangeEvent } from 'antd';
import type { RadioGroupButtonStyle } from 'antd/lib/radio';
import { OptionsProps } from '../../types';

export interface SwitcherAdditionalProps {
  switcherStyle?: RadioGroupButtonStyle;
}

interface SwitcherProps<T> {
  label?: string;
  name: string;
  value?: T;
  options?: OptionsProps[];
  onChange?: (value: T) => void;
  additionalProps?: SwitcherAdditionalProps;
}

export function SwitcherFilter<T extends string | number>(props: SwitcherProps<T>) {
  const { onChange, name, value, options = [], additionalProps = {} } = props;

  function handleChange(e: RadioChangeEvent) {
    onChange && onChange(e.target.value as T);
  }

  return (
    <Radio.Group
      name={name}
      options={options}
      onChange={handleChange}
      value={value}
      optionType="button"
      buttonStyle={additionalProps.switcherStyle}
    />
  );
}
