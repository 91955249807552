import { useQuery } from '@tanstack/react-query';
import { chain, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppPage } from 'router/constants/page-info';
import { getSearchResults } from 'services/search';
import { userPrivilegesSelector } from 'store/authentication/authentication';

export function useSearch(query: string) {
  return useQuery({
    queryKey: ['spotlight-search', query],
    enabled: query.length > 0,
    queryFn: () => {
      return getSearchResults(query, 'all');
    },
    keepPreviousData: true,
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  });
}

export function useRouteSearch(query: string) {
  // Get all Valid ( Priviledged ) Pages
  const priviledgesList = useSelector(userPrivilegesSelector);
  const validPages = useMemo(
    () =>
      chain(AppPage)
        .filter(
          (page) =>
            !page.routePath.includes(':') && // Exclude Dynamic Routes
            page.privileges.every((priv) => priviledgesList?.includes(priv))
        )
        .value(),
    [priviledgesList]
  );

  // Filter Pages based on query
  query = query.toLowerCase();
  const results = validPages; //.filter((page) => page.title.toLowerCase().includes(query));
  return uniqBy(results, 'path');
}
