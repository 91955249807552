export enum FilterKeys {
  /** INVOICE START */
  INVOICE_STATUS = 'invoice_status',
  DUE_DATE = 'due_date',
  TOTAL_DUE_AMOUNT = 'total_due_amount',
  ISSUED_DATE = 'issued_date',
  CLOSED_DATE = 'closed_date',
  COLLECTION_OWNER = 'collection_owner',
  ACCOUNT_MANAGER_IDS = 'account_manager_ids',
  CS_MANAGER_IDS = 'cs_manager_ids',
  COLLECTION_MANAGER_IDS = 'collection_manager_ids',
  SALES_MANAGER_IDS = 'sales_manager_ids',
  BILLING_CONTACT = 'billing_contact',
  SUBSIDIARY = 'SUBSIDIARY',
  CUSTOMER_DROPDOWN = 'CUSTOMER_DROPDOWN',
  DISPUTE_TYPE = 'DISPUTE_TYPE',
  CUSTOM_FIELD = 'custom_field',
  /** INVOICE END */
}
