import { SortOrder } from '@sinecycle/growcomponents';
import { DisputeFilters } from 'components/CollectionActivites/Disputes/types';
import { AllCustomersFilter } from 'store/AllCustomers/type';
import { PaymentListFilter } from 'store/payments/type';
import { ClosedInvoiceFilterParams } from './closed-invoice';
import { OpenInvoiceFilterParams } from './open-invoice';

export interface BasicParams {
  sort_col: string;
  sort_by: SortOrder;
  page: number;
  size: number;
  sort_by_custom_field?: number;
}

interface BaseFilterViewProps {
  id?: string;
  name: string;
  type?: string;
  system_defined?: boolean;
}

export interface InvoiceFilterView<T extends OpenInvoiceFilterParams | ClosedInvoiceFilterParams>
  extends BaseFilterViewProps {
  invoice_filter: T;
}

export enum PersistedFilterKeys {
  AGING = 'AGING',
  OPEN_INVOICES = 'OPEN_INVOICES',
  DRAFT_INVOICES = 'DRAFT_INVOICES',
  CLOSED_INVOICES = 'CLOSED_INVOICES',
}

export interface PersistedFilterProps {
  [PersistedFilterKeys.AGING]?: string;
  [PersistedFilterKeys.OPEN_INVOICES]?: string;
  [PersistedFilterKeys.DRAFT_INVOICES]?: string;
  [PersistedFilterKeys.CLOSED_INVOICES]?: string;
}

export interface PersistedFilterViews<
  T extends
    | OpenInvoiceFilterParams
    | ClosedInvoiceFilterParams
    | AllCustomersFilter
    | PaymentListFilter
    | DisputeFilters
> {
  [x: string]: T;
}
