import Pusher, { Channel } from 'pusher-js';
import { pusherAuthorizer } from './api/realtime-service';
import { RealtimeChannel, RealtimeInterface } from './types';

export type CallbackFn = Function; // aliasing it as CallbackFn for more specificity
type PusherConnectionStateChange = { previous: string; current: string };

class Realtime implements RealtimeInterface {
  private pusher: Pusher;
  private channels: Record<RealtimeChannel, string>;

  private _logConnectionStateChanges() {
    this.pusher.connection.bind('state_change', (states: PusherConnectionStateChange) => {
      console.log(`Pusher Connection State: Changed from ${states.previous} to ${states.current}`);
    });
  }

  constructor() {
    const appKey = import.meta.env.VITE_PUSHER_KEY,
      cluster = import.meta.env.VITE_PUSHER_CLUSTER;

    if (!appKey || !cluster) {
      throw new Error('appkey or cluster missing in envs');
    }

    this.pusher = new Pusher(appKey, { cluster, authorizer: pusherAuthorizer });
    this.channels = {
      [RealtimeChannel.ACCOUNT]: '',
      [RealtimeChannel.USER]: '',
      [RealtimeChannel.GLOBAL]: '',
    };

    this._logConnectionStateChanges();
  }

  _getChannel(channelName: string) {
    return this.pusher.channel(channelName) as Channel | undefined;
  }

  // use this if you want to pass custom channelName (not mapped with channel type)
  _bindHandlerToEvent(channelName: string, eventName: string, callback: CallbackFn) {
    const channel = this._getChannel(channelName);

    if (channel) {
      channel.bind(eventName, callback);
      console.log(`listening for event: ${eventName} on channel ${channel.name}`);
    }
  }

  // use this if you want to pass custom channelName (not mapped with channel type)
  _unbindHandlerFromEvent(channelName: string, eventName: string, callback: CallbackFn) {
    const channel = this._getChannel(channelName);

    if (channel) {
      channel.unbind(eventName, callback);
    }
  }

  getChannelName(channelType: RealtimeChannel) {
    return this.channels[channelType];
  }

  subscribeToChannel(channelName: string) {
    this.pusher.subscribe(channelName);
    console.log('subscribed to', channelName);
  }

  unsubscribeFromChannel(channelName: string) {
    this.pusher.unsubscribe(channelName);
    console.log('unsubscribed to', channelName);
  }

  subscribeToAllChannels(userId: number, accountId: number) {
    const channels: Record<RealtimeChannel, string> = {
      [RealtimeChannel.ACCOUNT]: `private-account-${accountId}`,
      [RealtimeChannel.USER]: `private-user-${accountId}-${userId}`,
      [RealtimeChannel.GLOBAL]: 'growfin-global',
    };

    this.channels = channels;

    const channelNames = Object.values(channels);

    channelNames.forEach((channelName) => {
      this.subscribeToChannel(channelName);
    });
  }

  // when you need more granular options, you can create custom hooks using this
  getRealtimeInstance() {
    return this.pusher;
  }

  // if channel is not present, nothing happens, it silently returns
  bindHandlerToEvent(channelType: RealtimeChannel, eventName: string, callback: CallbackFn) {
    const channelName = this.getChannelName(channelType);

    this._bindHandlerToEvent(channelName, eventName, callback);
  }

  // if channel is not present, nothing happens, it silently returns
  unbindHandlerFromEvent(channelType: RealtimeChannel, eventName: string, callback: CallbackFn) {
    const channelName = this.getChannelName(channelType);

    this._unbindHandlerFromEvent(channelName, eventName, callback);
  }
}

const realtime = new Realtime();

export { realtime };
