import ky from 'services/ky';
import {
  BaseCollectionStrategy,
  CollectionStrategy,
  CollectionStrategyStats,
} from 'types/entities/collection-strategy';
import { filterStringIds } from './utils';

const baseUrl = 'v2/collection-strategies';

export async function getCollectionStrategies() {
  return await ky.get(`${baseUrl}/`).json<BaseCollectionStrategy[]>();
}

export async function toggleStrategyEnable(id: number, enabled: boolean) {
  return (await ky
    .patch(`${baseUrl}/${id}`, { json: { enabled } })
    .json()) as BaseCollectionStrategy;
}

export async function getCollectionStrategy(id: number) {
  return (await ky.get(`${baseUrl}/${id}`).json()) as CollectionStrategy;
}

export async function updateCollectionStrategy(updatedStratgy: CollectionStrategy) {
  const filteredStrategy = filterStringIds(updatedStratgy);
  const strategyId = filteredStrategy.id;

  return (await ky
    .put(`${baseUrl}/${strategyId}`, { json: filteredStrategy })
    .json()) as CollectionStrategy;
}

export async function createCollectionStrategy(newStrategy: CollectionStrategy) {
  const filteredStrategy = filterStringIds(newStrategy);

  return (await ky.post(`${baseUrl}`, { json: filteredStrategy }).json()) as CollectionStrategy;
}

export async function getCollectionStrategyStats(id: number) {
  return (await ky.get(`${baseUrl}/${id}/stats`).json()) as CollectionStrategyStats;
}

export async function removeCollectionStrategy(id: number) {
  return await ky.delete(`${baseUrl}/${id}`);
}
