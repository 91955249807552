import { omit, pick } from 'lodash';
import { useMemo } from 'react';
import {
  ActivityStatus,
  ActivityStatusConfig,
  InboxActivitiesFilters,
  InboxActivityMode,
  InboxActivitySearchParam,
  InboxActivityStatusParams,
  InboxActivityType,
} from 'types/api/inbox/activity';
import { getActivityStatusTransformer, getEntityTypesTransformer } from '../FilterStrip/util';
import { useActivitySearchParams } from './useActivitySearchParams';
import { useGetActivityFilterOption } from './useGetActivityFilterOption';

export const activityStatusConfig: Partial<ActivityStatusConfig> = {
  DISPUTE: InboxActivityStatusParams.disputeStatus,
  TASK: InboxActivityStatusParams.taskStatus,
  PROMISE_TO_PAY: InboxActivityStatusParams.ptpStatus,
  ESCALATION: InboxActivityStatusParams.escalationStatus,
};

export function useActivityFilterParams() {
  const { activitySearchParams } = useActivitySearchParams();
  const { defaultFilterValue } = useGetActivityFilterOption();
  const filterParams = omit(activitySearchParams, [
    InboxActivitySearchParam.activityId,
    InboxActivitySearchParam.activityType,
    InboxActivitySearchParam.activityMode,
    InboxActivitySearchParam.selectedEntities,
    InboxActivitySearchParam.status,
    InboxActivitySearchParam.page,
    InboxActivitySearchParam.pageSize,
  ]) as InboxActivitiesFilters;

  function getActivityFilters(): InboxActivitiesFilters {
    const modifiedParams: InboxActivitiesFilters = {
      ...filterParams,
      read: activitySearchParams.status === ActivityStatus.UNREAD ? false : undefined,
      activity_mode: activitySearchParams.activity_mode
        ? activitySearchParams.activity_mode
        : InboxActivityMode.ASSIGNED,
    };

    const value = activitySearchParams.selected_entities
      ? activitySearchParams.selected_entities === 'ALL'
        ? []
        : activitySearchParams.selected_entities.split(',')
      : activitySearchParams.activity_mode === InboxActivityMode.ASSIGNED
      ? defaultFilterValue.ASSIGNED
      : activitySearchParams.activity_mode === InboxActivityMode.SUBSCRIBED
      ? defaultFilterValue.SUBSCRIBED
      : [];
    const entityTypes = getEntityTypesTransformer(value).parent as InboxActivityType[];
    const status = getActivityStatusTransformer(activityStatusConfig, value);

    return {
      ...modifiedParams,
      entity_types: entityTypes,
      ...status,
    };
  }

  const filters = useMemo(getActivityFilters, [
    activitySearchParams.activity_mode,
    activitySearchParams.selected_entities,
    activitySearchParams.status,
    defaultFilterValue.ASSIGNED,
    defaultFilterValue.SUBSCRIBED,
    filterParams,
  ]);
  const searchParams = pick(activitySearchParams, [
    InboxActivitySearchParam.page,
    InboxActivitySearchParam.pageSize,
  ]);

  return { filters, searchParams };
}
