import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notify } from 'components/BaseComponents/Notifications';
import {
  createUserCategory,
  deleteUserCategory,
  getUserCategory,
  updateUserCategory,
} from 'services/user-category';

const t = {
  deleteSuccess: 'User deleted successfully',
};

export interface UpdateUserCategoryProps {
  id?: number;
  title: string;
  roles: number[];
}
export type createUserCategoryProps = Omit<UpdateUserCategoryProps, 'id'>;

export function useUserCategoryGetQuery() {
  return useQuery({
    queryKey: ['user-category'],
    retry: false,
    queryFn: () => getUserCategory(),
  });
}

export function useCreateUserCategoryQuery() {
  return useMutation({
    mutationFn: (data: createUserCategoryProps) => createUserCategory(data),
    retry: false,

    onSuccess() {},
    onError() {},
  });
}

export function useUserCategoryUpdateQuery() {
  return useMutation({
    mutationFn: (data: UpdateUserCategoryProps) => updateUserCategory(data),
    retry: false,
    onSuccess() {},
    onError() {},
  });
}

export function useUserCategoryDeleteQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => deleteUserCategory(id),
    retry: false,

    onSuccess() {
      queryClient.invalidateQueries(['user-category']);
      notify.success(t.deleteSuccess);
    },
    onError() {},
  });
}
