import { amountFormats, CellRenderProps, GrowTypography } from '@sinecycle/growcomponents';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';
import { AmountMaybeWithMultiCurrencySplit } from 'types/common/multi-currency';
type MultiCurrencyCellValue = {
  amount: AmountMaybeWithMultiCurrencySplit;
  invoice?: number;
};
function MultiCurrencyCell<T>(props: CellRenderProps<T>) {
  const { value, style } = props;
  const multiValues = value as MultiCurrencyCellValue;
  const locale = useSelector(localeSelector);

  if (!value) return null;

  const Extra = multiValues?.invoice ? (
    <GrowTypography.Text style={{ color: 'var(--gray-8)' }}>{`${amountFormats.number(
      multiValues?.invoice,
      { locale }
    )} invoices`}</GrowTypography.Text>
  ) : null;
  return (
    <AmountWithCurrencySplit
      type="multi"
      amountWithCurrencySplit={multiValues.amount}
      context="Cell Level"
      hideSplitTotal
      showTooltip={false}
      style={{ ...style }}
      currencyArea="DETAILS"
      amountExtra={{ value: Extra }}
    />
  );
}

export default MultiCurrencyCell;

function MultiCurrencyAmountCell<T>(props: CellRenderProps<T>) {
  const { value, style } = props;

  if (!value) return null;
  return (
    <GrowTypography.Text>
      <AmountWithCurrencySplit
        type="multi"
        amountWithCurrencySplit={value as AmountMaybeWithMultiCurrencySplit}
        showTooltip
        context="Cell Level"
        hideSplitTotal
        style={{ ...style }}
        currencyArea="DETAILS"
      />
    </GrowTypography.Text>
  );
}

export { MultiCurrencyAmountCell };
