import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { MenuClickEvent } from 'components/BaseComponents/Menu/Antd/type';
import { useCurrentUser } from 'hooks/use-current-user';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { accountDetailsSelector } from 'store/authentication/authentication';
import { commonButtonProps } from '../../Toolbar';
import { DropdownButton } from '../../common/DropdownWrapper';
import { ToolbarOptionProps } from '../../type';

const t = {
  label: 'Signature',
  signature: 'Insert Signature',
  noSignature: 'No signature provided for the selected FROM email',
  mySignature: 'My Signature',
  arEmailSignature: 'AR Email Signature',
};

const Icon = <FontAwesomeIcon icon={['far', 'signature']} />;

interface SignatureProps extends ToolbarOptionProps {
  signature?: string;
}

type SignatureAlign = 'mySignature' | 'arEmailSignature';

// type SignaturePosition = { start: number; end: number };
export function Signature({ editor, signature }: SignatureProps) {
  const accountAREmail = useSelector(accountDetailsSelector);
  const currentLoggedInUser = useCurrentUser();
  const disableSignature = !accountAREmail?.email_signature && !currentLoggedInUser.email_signature;
  const isAutoSigned = useRef(false);
  // const [signaturePosition, setSignaturePosition] = useState<SignaturePosition>();
  function insertSignatureCallback(signature: string) {
    if (!signature) return;

    /**
     * The below logic takes care of replacing signature just like Gmail exp
     * Disabling this for now since it doesn't work for nodes with images
     *
     *
     */

    /*      const empty_lines = join(fill(Array(2), TIPTAP_EMPTYLINE), '');
        const dashed = `<div>--</div>`;
        const node = editor.view.dom.getElementsByClassName('signature');
        if (editor.state.doc.lastChild && Boolean(node.length)) {
          const startPos = editor.state.doc.content.size - editor.state.doc.lastChild.nodeSize;
          const endPos = editor.state.doc.content.size;

          editor
            .chain()
            .focus()
            .insertContentAt({ from: startPos, to: endPos }, signature, {
              parseOptions: { preserveWhitespace: true },
            })
            .run();
          return;
        } */

    const ts = `<div></div>${signature}<div></div>`;

    editor.chain().focus().insertContentAt(editor.state.doc.content.size, ts).run();
  }

  const insertSignature = useCallback(insertSignatureCallback, [editor]);

  useEffect(() => {
    // Inserting singature only on first time
    if (!editor || isAutoSigned.current) return;
    if (signature) insertSignature(signature);
    isAutoSigned.current = true;
  }, [editor, insertSignature, signature]);

  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    icon: Icon,
    disabled: disableSignature,
  };

  const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
    title: t.label,
  };

  const CurrentSignatureMode = <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />;

  function onInsertCallback(align: SignatureAlign) {
    // formattingOptions['align'].onClick(editor, align);
    if (align === 'mySignature') {
      if (currentLoggedInUser.email_signature?.content)
        insertSignature(currentLoggedInUser.email_signature.content);
    } else if (accountAREmail?.email_signature?.content)
      insertSignature(accountAREmail.email_signature.content);
  }

  const alignItems = [
    {
      key: 'mySignature',
      label: t.mySignature,
      disabled: !currentLoggedInUser?.email_signature,
    },
    {
      key: 'arEmailSignature',
      label: t.arEmailSignature,
      disabled: !accountAREmail?.email_signature,
    },
  ];

  function handleMenuClick(event: MenuClickEvent) {
    onInsertCallback(event.key as SignatureAlign);
  }

  return (
    <DropdownButton
      menu={{ items: alignItems, onClick: handleMenuClick }}
      placement="top"
      disabled={disableSignature}
      arrow={false}
    >
      <div>{CurrentSignatureMode}</div>
    </DropdownButton>
  );
}
