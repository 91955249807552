import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import { DateText } from 'components/BaseComponents/Date/DateText';
import { ExtraInfo } from 'components/BaseComponents/GrowTable/ExtraInfo';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowParagraph, GrowText } from 'components/BaseComponents/Typography';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';
import TaskButton from 'components/Common/ActivitiesForm/Task/TaskButton';
import { TaskFormText } from 'components/Common/ActivitiesForm/text';
import {
  CustomActivityProps,
  FormItemNames,
  MutationFunctionProps,
} from 'components/Common/ActivitiesForm/types';
import { TaskFormActivityProps, TaskResponseSchema } from 'components/Common/hooks/type';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { PageLink } from 'lib/router';
import { head } from 'lodash';
import { useEffect, useState } from 'react';
import { pagePath } from 'router/constants/page-info';
import { ActivityType } from 'types/activities/activity-types';
import { Task } from 'types/activities/task';
import { isPastDate } from 'util/datetime-formatter';
import { InvoiceDropdownInterfaceProps } from '../../../../store/invoice/type';
import { ActivitySchema, invoiceTransformSchema } from '../ActivitySchema';
import useFormCustomTransformer from '../hooks/useFormCustomTransformer';
import useFormSubmitMutate from '../hooks/useFormSubmitMutate';
import { ActivityMutationProps, MutationFunctionResponseType } from '../type';
import { invoiceItemsFn } from '../utils';
import { taskCountStyles } from './style';

interface FollowupActionProps {
  invoiceList?: InvoiceDropdownInterfaceProps[];
  customerId?: number;
  nextFollowup?: Task;
  context?: string;
  openTaskCount?: number;
  mode?: 'CELL' | 'DETAILS';
}

const FollowupAction = (props: FollowupActionProps) => {
  const [nextFollowup, setNextFollowup] = useState<Task | undefined>();
  const { cardLabel, Icon } = getActivityUnitItems(ActivityType.TASK);
  const { onActivityFormTransform } = useFormCustomTransformer();
  const onFormMutateFn = useFormSubmitMutate();
  const { mode = 'CELL' } = props;
  const invoiceItemQueries = invoiceItemsFn<InvoiceDropdownInterfaceProps>(props?.customerId);

  useEffect(() => {
    setNextFollowup(props.nextFollowup);
  }, [props.nextFollowup]);

  function customTransformer(data: Record<FormItemNames, any>, customProps?: CustomActivityProps) {
    if (!customProps) return {};

    return onActivityFormTransform({ data, customerId: props?.customerId });
  }

  function onMutateFn(data: MutationFunctionProps<ActivityMutationProps>) {
    return onFormMutateFn(data).then((data) => {
      const taskData = head(data as unknown as TaskResponseSchema[]);

      if (taskData?.due_date && !isPastDate(taskData?.due_date)) {
        setNextFollowup({
          title: taskData.title,
          description: taskData?.description,
          due_date: taskData?.due_date,
        } as Task);
      }
      return data;
    });
  }

  const FollowupPopover = (
    <Popover
      placement="right"
      arrow={false}
      overlayInnerStyle={{ maxWidth: '250px' }}
      content={
        <Flex direction="column" justify="center" gap="var(--space-4)">
          <Flex align="center" gap="var(--space-4)">
            {Icon}
            <GrowText style={{ color: 'var(--gray-7)' }}>{cardLabel}</GrowText>
          </Flex>
          <div>
            <GrowText weight="semibold">{nextFollowup?.title}</GrowText>
          </div>
          <div>
            <GrowParagraph ellipsis={{ rows: 2 }}>{nextFollowup?.description}</GrowParagraph>
          </div>
        </Flex>
      }
    >
      {mode === 'DETAILS' ? (
        <span>
          <FontAwesomeIcon icon={['far', 'info-circle']} color="var(--geekblue-6)" fontSize={14} />
        </span>
      ) : (
        <div>
          <ExtraInfo value={<DateText value={nextFollowup?.due_date ?? ''} />} />
        </div>
      )}
    </Popover>
  );

  const AddTask = (
    <TaskButton<TaskFormActivityProps<InvoiceDropdownInterfaceProps>>
      invoices={props?.invoiceList}
      text={TaskFormText}
      invoiceTransformSchema={invoiceTransformSchema}
      customTransformer={customTransformer}
      responseSchema={ActivitySchema.TASK}
      onMutateFn={onMutateFn as MutationFunctionResponseType<TaskResponseSchema>}
      buttonConfig={{
        size: 'small',
        color: 'var(--primary-7)',
        type: 'link',
        hideIcon: true,
        text: 'Add Task',
      }}
      {...invoiceItemQueries}
    />
  );

  const TaskCount = (
    <PageLink
      appPage="INVOICE_DETAILS"
      TO_LINK={{
        pathname:
          props.invoiceList &&
          props.invoiceList
            .map((invoice) => {
              return pagePath('INVOICE_DETAILS', { id: invoice.id });
            })
            .toString(),

        search: `current_activity_tab=TASK`,
      }}
      style={taskCountStyles}
    >{`+ ${props.openTaskCount && props.openTaskCount - 1} more`}</PageLink>
  );

  const OpenTasks = (
    <Flex gap="var(--space-8)" align="center">
      {mode === 'DETAILS' ? (
        <Flex gap="var(--space-4)" align="center">
          <DateText
            value={nextFollowup?.due_date ?? ''}
            textProps={{ strong: mode === 'DETAILS' }}
          />
        </Flex>
      ) : (
        FollowupPopover
      )}

      {props.openTaskCount && props.openTaskCount > 1 && TaskCount}
      <HideWrapper hide={mode !== 'DETAILS'}>{FollowupPopover}</HideWrapper>
    </Flex>
  );

  return <div className="open-tasks">{nextFollowup?.due_date ? OpenTasks : AddTask}</div>;
};

export default FollowupAction;
