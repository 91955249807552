import DatePicker from 'components/BaseComponents/AntComponents/DatePicker';
import { FileType } from 'types/common/attachments';
import { NonUndefined } from 'types/utils/utils';

export const statementsTexts = {
  as_of: 'as of',
  month_range: 'Month Range',
};

export enum StatementTypes {
  ALL_TRANSACTIONS = 'ALL_TRANSACTION_STATEMENT',
  OUTSTANDING_TRANSACTIONS = 'OPEN_TRANSACTION_STATEMENT',
}

export enum StatementPeriods {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  LAST_7_DAYS = 'LAST_7_DAYS',
  MORE_THAN_30_DAYS = 'MORE_THAN_30_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  CUSTOM_RANGE = 'CUSTOM_RANGE',
  YESTERDAY = 'YESTERDAY',
  CUSTOM_DATE = 'CUSTOM_DATE',
  START_OF_THE_MONTH = 'START_OF_THE_MONTH',
  LAST_TWO_MONTHS = 'LAST_TWO_MONTHS',
}

export const periodsSate: Partial<Record<StatementPeriods, string>> = {
  [StatementPeriods.TODAY]: 'Today',
  [StatementPeriods.TOMORROW]: 'Yesterday',
  [StatementPeriods.START_OF_THE_MONTH]: 'Start of the Month',
  [StatementPeriods.THIS_MONTH]: 'This Month',
  [StatementPeriods.THIS_WEEK]: 'This Week',
  [StatementPeriods.LAST_WEEK]: 'Last Week',
  [StatementPeriods.LAST_MONTH]: 'Last Month',
  [StatementPeriods.CUSTOM_RANGE]: 'Custom Range',
  [StatementPeriods.YESTERDAY]: 'Yesterday',
  [StatementPeriods.CUSTOM_DATE]: 'Custom Date',
  [StatementPeriods.LAST_TWO_MONTHS]: 'Last Two Months',
};

export type StatementPeriodType = 'RELATIVE' | 'ABSOLUTE';

export type CustomerStatement = {
  period_type: StatementPeriodType;
  attachment_type: StatementTypes;
  file_type: FileType;
  period: StatementPeriods | undefined;
  custom_range: [Date, Date] | null | [string, string];
  custom_date: string | Date | null;
};

export const DateFormat = 'DD MMM YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export type RangePickerProps = React.ComponentProps<typeof DatePicker.RangePicker>;
export type CurrentDate = Parameters<
  NonUndefined<NonNullable<RangePickerProps>['disabledDate']>
>['0'];
export type DateChange = Parameters<NonUndefined<NonNullable<RangePickerProps>['onChange']>>['0'];
export type DateRange = NonNullable<DateChange>['0'];
export type DatePickerProps = React.ComponentProps<typeof DatePicker>;
export type ChangeEvent = Parameters<NonUndefined<NonNullable<DatePickerProps>['onChange']>>['0'];
