import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { formattingOptions } from 'lib/RichText';
import { startCase } from 'lodash';
import { RTEActionButton } from '../../../type';
import { commonButtonProps } from '../../Toolbar';
import { ToolbarOptionProps } from '../../type';

export const BulletsAndNumbering: RTEActionButton[] = [
  {
    label: <FontAwesomeIcon icon={['far', 'list']} />,
    key: 'bulletlist',
    onClick: formattingOptions['bulletlist'].onClick,
    isActive: formattingOptions['bulletlist'].isActive,
  },
  {
    label: <FontAwesomeIcon icon={['far', 'list-ol']} />,
    key: 'orderlist',
    onClick: formattingOptions['orderlist'].onClick,
    isActive: formattingOptions['orderlist'].isActive,
  },
];

interface BulletsNumberingProps extends ToolbarOptionProps {}

export function BulletsNumbering({ editor }: BulletsNumberingProps) {
  const buttonRenderer = (action: RTEActionButton) => {
    function onClickCallback(event: React.MouseEvent<HTMLElement>) {
      action.onClick(editor);
    }

    const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
      ...commonButtonProps,
      icon: action.label,
      onClick: onClickCallback,
      type: action.isActive(editor) ? 'default' : 'text',
    };

    const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
      title: startCase(action.key),
    };

    return <Button key={action.key} buttonProps={buttonProps} toolTipProps={toolTipProps} />;
  };

  const LineActionsArea = <>{BulletsAndNumbering.map(buttonRenderer)}</>;

  return <Flex>{LineActionsArea}</Flex>;
}
