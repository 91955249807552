import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers/rootReducer';

type LoadingState = {
  fetching: AsyncFetches[];
  saving: AsyncSaves[];
};

export enum AsyncFetches {
  // customer segments
  MATCHING_CUSTOMERS = 'MATCHING_CUSTOMERS',
  GET_CUSTOMER_SEGMENTS = 'GET_CUSTOMER_SEGMENTS',
  GET_CUSTOMER_SEGMENT = 'GET_CUSTOMER_SEGMENT',
  //customer territories
  GET_CUSTOMER_TERRITORIES = 'GET_CUSTOMER_TERRITORIES',
  // email
  GET_EMAIL_TEMPLATES = 'EMAIL_TEMPLATES',
  GET_EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  EMAIL_PREVIEW = 'EMAIL_PREVIEW',
  // collection followup
  CLONE_FOLLOWUP = 'CLONE_FOLLOWUP',
  DELETE_FOLLOWUP = 'DELETE_FOLLOWUP',
  FOLLOWUP_STAT_TOOLTIP = 'FOLLOWUP_STAT_TOOLTIP',
  GET_COLLECTION_FOLLOWUP = 'GET_COLLECTION_FOLLOWUP',
  // search
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
}

export enum AsyncSaves {
  UPLOAD_FILE = 'UPLOAD_FILE',
}

const initialState: LoadingState = {
  fetching: [],
  saving: [],
};

const asyncRequestsSlice = createSlice({
  name: 'asyncRequests',
  initialState,
  reducers: {
    addToFetchingHandler(state, action: PayloadAction<AsyncFetches>) {
      state.fetching.push(action.payload);
    },
    clearFetchingHandler(state, action: PayloadAction<AsyncFetches>) {
      state.fetching = state.fetching.filter((fetchType) => fetchType !== action.payload);
    },
    addToSavingHandler(state, action: PayloadAction<AsyncSaves>) {
      state.saving.push(action.payload);
    },
    clearSavingHandler(state, action: PayloadAction<AsyncSaves>) {
      state.saving = state.saving.filter((fetchType) => fetchType !== action.payload);
    },
  },
});

const getAsyncSaves = (state: RootState) => state.asyncRequests.saving;

export function generateIsSavingSelector(saveType: AsyncSaves) {
  return createSelector(getAsyncSaves, (asyncSaves) => {
    const save = asyncSaves.find((request) => request === saveType);
    return save ? true : false;
  });
}

export const {
  addToSavingHandler,
  clearSavingHandler,
  addToFetchingHandler,
  clearFetchingHandler,
} = asyncRequestsSlice.actions;

export default asyncRequestsSlice.reducer;
