import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reducers/rootReducer';

const draftListStateSelector = (state: RootState) => state.invoiceReducerV2;

const draftInvoiceCurrentFilterViewSelector = createSelector(
  draftListStateSelector,
  (currentState) => currentState.draftInvoiceCurrentFilterView
);

const draftInvoiceSavedFilterViewsSelector = createSelector(
  draftListStateSelector,
  (currentState) => currentState.draftInvoiceSavedFilterviews
);

const draftInvoiceCurrentFilterSelector = createSelector(
  draftListStateSelector,
  (currentState) => currentState.draftInvoiceCurrentFilterView?.invoice_filter
);

const DraftCurrentPage = (state: RootState) => state.invoiceReducerV2.draftCurrentPage;

const draftInvoiceQuerParams = createSelector(
  draftListStateSelector,
  (currentState) => currentState.draftInvoiceFilterQueryParams
);

export {
  DraftCurrentPage,
  draftInvoiceCurrentFilterSelector,
  draftInvoiceCurrentFilterViewSelector,
  draftInvoiceQuerParams,
  draftInvoiceSavedFilterViewsSelector,
};
