import { PayloadAction } from '@reduxjs/toolkit';

import { Sorter, TableInitialState } from '@sinecycle/growcomponents';
import { CMData } from 'components/CollectorsManagement/CMBody/CollectorsManagementTable';
import {
  ModifiedAmountAction,
  collectionResponses,
} from 'types/api/collectors/collectors-management';
import { CollectorsManagementProps } from './type';

export function updateCmSelectAll(
  state: CollectorsManagementProps,
  action: PayloadAction<{ selected: boolean; select_all: boolean }>
) {
  state.selectAll = action.payload;
}

export function updateCmSelectedRows(
  state: CollectorsManagementProps,
  action: PayloadAction<number[]>
) {
  state.selectedRowIds = [...action.payload];
}

export function addCmExcludedRowId(
  state: CollectorsManagementProps,
  action: PayloadAction<number>
) {
  state.excludedRowIds = [...state.excludedRowIds, action.payload];
}

export function removeCmExcludedRowId(
  state: CollectorsManagementProps,
  action: PayloadAction<number>
) {
  state.excludedRowIds = state.excludedRowIds.filter((id) => id !== action.payload);
}

export function resetCmExcludedRowId(state: CollectorsManagementProps) {
  state.excludedRowIds = [];
}

export function updateSortingCmCurrentFilter(
  state: CollectorsManagementProps,
  action: PayloadAction<Sorter>
) {
  state.collectorsManagement.currentFilter = {
    sort_by: action.payload.sortOrder,
    sort_col: action.payload.sortCol,
  };
}

export function updateCollectorsInfo(
  state: CollectorsManagementProps,
  action: PayloadAction<collectionResponses['list']>
) {
  state.collectorsManagement.collectorsInfo = action.payload.map((collections) => {
    return {
      modified: collections.collection_period_metric.modified_date ? true : false,
      modifiedTarget: collections.collection_period_metric.modified_collection_target.amount.value,
      collectionOwnerId: collections.collection_owner.id,
      name: `${collections.collection_owner.first_name} ${collections.collection_owner.last_name}`,
    };
  });
}

export function updateBulkRecords(state: CollectorsManagementProps, action: PayloadAction<number>) {
  state.collectorsManagement.bulkRecords = action.payload;
}
export function updateTableState(
  state: CollectorsManagementProps,
  action: PayloadAction<TableInitialState<CMData>>
) {
  state.selectedRows = action.payload.selectedRows;
  state.selectAll = action.payload.selectAll;
}

//  TableInitialState<CMData>;
export function updateModifiedCollectionTargetAmount(
  state: CollectorsManagementProps,
  action: PayloadAction<ModifiedAmountAction>
) {
  state.collectorsManagement.collectorsInfo = state.collectorsManagement.collectorsInfo.map(
    (collectors) => {
      const isOwnerId = action.payload.ownerIds.find(
        (ownerId) => ownerId === collectors.collectionOwnerId
      );
      return isOwnerId
        ? {
            ...collectors,
            modified: true,
            modifiedTarget: action.payload.amount,
          }
        : collectors;
    }
  );
}
