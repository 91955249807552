import { CollectionPeriod } from 'store/collection-period/types';
import { CollectionPeriodsHistory } from 'store/collectors-performace/type';
import ky from './ky';
const baseUrl = 'collection-periods/current';

export async function fetchCollectionPeriod() {
  return (await ky.get(baseUrl).json()) as CollectionPeriod;
}

export async function fetchCurrentCollectionPeriod() {
  return (await ky.get(baseUrl).json()) as CollectionPeriod;
}

export async function searchCollectionPeriods(text: string) {
  return (await ky
    .get(`collection-periods/search`, { searchParams: { text } })
    .json()) as CollectionPeriod[];
}

export async function getCollectionPeriods() {
  return (await ky.get(`collection-periods`).json()) as CollectionPeriodsHistory[];
}

export async function getSavedCollectionPeriods(periodIds?: number[]) {
  return (await ky
    .get(`collection-periods/details`, { searchParams: { period_ids: String(periodIds) } })
    .json()) as CollectionPeriodsHistory[];
}
