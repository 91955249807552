import { CellRenderProps } from '@sinecycle/growcomponents';

import { StatusCell } from 'components/Common/StatusCell/StatusCell';
import { IInvoices } from 'store/customer/types';
import { InvoiceEmailDeliveryStatus } from 'types/entities/invoice';
import { getInvoiceEmailDeliveryConfig } from '../../common/util';

function EmailDeliveryStatus(props: CellRenderProps<IInvoices, InvoiceEmailDeliveryStatus>) {
  const { value } = props;
  if (!value) return '-';
  return (
    <StatusCell
      status={{
        mode: 'Icon',
        iconColor: getInvoiceEmailDeliveryConfig(value).iconColor,
        text: getInvoiceEmailDeliveryConfig(value).label,
      }}
    />
  );
}

export default EmailDeliveryStatus;
