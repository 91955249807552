import { Flex } from 'components/BaseComponents/Layout/Flex';
import styled from 'styled-components';
import { PlaceholderBody } from './Body';
import PlaceholderHeader from './header';
import { placeholdersProps } from './type';

const StyledPlaceholdersContent = styled(Flex)`
  width: 600px;
  height: 400px;
  background: var(--gray-1);
`;

const t = {
  AddPlaceholders: 'Insert Placeholder',
  emptyMessage: 'No results found for',
  notAvailable: 'No Placeholders Available',
};
function Placeholders(props: placeholdersProps) {
  const handlePlaceholderInsertCallback = (placeholder: string) => {
    props.onInsert && props.onInsert(placeholder);
  };

  return (
    <StyledPlaceholdersContent direction="column" style={{ ...props.style }}>
      <PlaceholderHeader
        title={props.headerTitle ? props.headerTitle : t.AddPlaceholders}
        onClose={props.cancel}
        viewOnly={props.viewOnly}
      />
      <PlaceholderBody
        folderType={props.folderType}
        validFolders={props.validFolders}
        onPlaceholderInsertCallback={handlePlaceholderInsertCallback}
        viewOnly={props.viewOnly}
        optionsOverrideStyles={props.optionsOverrideStyles}
        sideBarOverrideStyles={props.sideBarOverrideStyles}
      />
    </StyledPlaceholdersContent>
  );
}

export { Placeholders };
