import { Filter, FilterGroup, StandardFilterGroup } from 'components/Common/FilterComponents/types';
import { useDashboardCustomFieldsQuery } from 'queries/admin-dashboard';
import { useEffect, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { reportingTagsSelector } from 'store/reporting-tags/reporting-tags';
import { IReportingTags } from 'store/reporting-tags/type';
import { CustomField, CustomFieldEntityType, CustomFieldPage } from 'types/entities/custom-field';
import { filterReducer, initialFilterList } from '../reducers/filterListReducer';
import { filterValuesReducer, initialFilterValues } from '../reducers/filterValuesReducer';

export enum CustomDashboardFitlers {
  REPORTING_TAGS = 'reporting_tags',
  CUSTOMER_CUSTOM_FIELDS = 'custom_fields',
  INVOICE_CUSTOM_FIELDS = 'invoice_custom_fields',
}
const entity = `CUSTOMER,INVOICE`;

export function useDashboardFilter() {
  const [filterGroups, updateFilterGroups] = useReducer(filterReducer, initialFilterList);
  const [filterValues, updateFilterValues] = useReducer(filterValuesReducer, initialFilterValues);

  const reportingTags = useSelector(reportingTagsSelector);
  const { data: customFields } = useDashboardCustomFieldsQuery(
    entity,
    CustomFieldPage.ADMIN_DASHBOARD
  );
  const customerCustomFields = useMemo(
    () =>
      customFields?.filter(
        (customField) => customField.entity_type === CustomFieldEntityType.CUSTOMER
      ),
    [customFields]
  );
  const invoiceCustomFields = useMemo(
    () =>
      customFields?.filter(
        (customField) => customField.entity_type === CustomFieldEntityType.INVOICE
      ),
    [customFields]
  );

  useEffect(() => {
    if (reportingTags.length) {
      const filterGroup: FilterGroup<IReportingTags> = {
        id: CustomDashboardFitlers.REPORTING_TAGS,
        label: 'Reporting Tags',
        filterList: reportingTags.map(
          (reportingTag) =>
            ({
              id: `reporting-tag-${reportingTag.id}`,
              label: reportingTag.name,
              active: false,
              removable: true,
              filterObject: reportingTag,
            } as Filter<IReportingTags>)
        ),
      };

      updateFilterGroups({ type: 'ADD_GROUP', payload: { filterGroup } });
    }
  }, [reportingTags]);

  useEffect(() => {
    if (invoiceCustomFields?.length) {
      const filterGroup: FilterGroup<CustomField> = {
        id: StandardFilterGroup.INVOICE,
        label: 'Invoice',
        filterList: invoiceCustomFields?.map(
          (customField) =>
            ({
              id: `${StandardFilterGroup.INVOICE}-custom-field-${customField.id}`,
              label: customField.display_name,
              active: false,
              removable: true,
              filterObject: customField,
            } as Filter<CustomField>)
        ),
      };

      updateFilterGroups({ type: 'ADD_GROUP', payload: { filterGroup } });
    }
  }, [invoiceCustomFields]);

  useEffect(() => {
    if (customerCustomFields?.length) {
      const filterGroup: FilterGroup<CustomField> = {
        id: StandardFilterGroup.CUSTOMER,
        label: 'Customer',
        filterList: customerCustomFields?.map(
          (customField) =>
            ({
              id: `${StandardFilterGroup.CUSTOMER}-custom-field-${customField.id}`,
              label: customField.display_name,
              active: false,
              removable: true,
              filterObject: customField,
            } as Filter<CustomField>)
        ),
      };

      updateFilterGroups({ type: 'ADD_GROUP', payload: { filterGroup } });
    }
  }, [customerCustomFields]);

  return {
    filterGroups,
    updateFilterGroups,
    filterValues,
    updateFilterValues,
  };
}
