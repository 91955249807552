import { Editor } from '@tiptap/react';
import { AddButtonProps } from 'components/BaseComponents/RTE/Toolbar/common/nodes/CustomLinkButton/AddButtonCanvas';
import { AlignTypes, HeadingLevel } from './type';

export const formattingOptions = {
  heading: {
    onClick: (editor: Editor, headingNo: number) =>
      editor
        .chain()
        .focus()
        .toggleHeading({ level: headingNo as HeadingLevel })
        .run(),
    isActive: (editor: Editor, headingNo: number) =>
      editor.isActive('heading', { level: headingNo }),
  },
  paragraph: {
    onClick: (editor: Editor) => editor.chain().focus().setParagraph().run(),
    isActive: (editor: Editor) => editor.isActive('paragraph'),
  },
  bulletlist: {
    onClick: (editor: Editor) => editor.chain().focus().toggleBulletList().run(),
    isActive: (editor: Editor) => editor.isActive('bulletList'),
  },
  orderlist: {
    onClick: (editor: Editor) => editor.chain().focus().toggleOrderedList().run(),
    isActive: (editor: Editor) => editor.isActive('orderedList'),
  },
  bold: {
    onClick: (editor: Editor) => editor.chain().focus().toggleBold().run(),
    isActive: (editor: Editor) => editor.isActive('bold'),
  },
  italic: {
    onClick: (editor: Editor) => editor.chain().focus().toggleItalic().run(),
    isActive: (editor: Editor) => editor.isActive('italic'),
  },
  underline: {
    onClick: (editor: Editor) => editor.chain().focus().toggleUnderline().run(),
    isActive: (editor: Editor) => editor.isActive('underline'),
  },
  strikethrough: {
    onClick: (editor: Editor) => editor.chain().focus().toggleStrike().run(),
    isActive: (editor: Editor) => editor.isActive('strike'),
  },
  quote: {
    onClick: (editor: Editor) => editor.chain().focus().toggleCode().run(),
    isActive: (editor: Editor) => editor.isActive('code'),
  },
  undo: {
    onClick: (editor: Editor) => editor.chain().focus().undo().run(),
  },
  redo: {
    onClick: (editor: Editor) => editor.chain().focus().redo().run(),
  },
  align: {
    onClick: (editor: Editor, alignment: AlignTypes) =>
      editor.chain().focus().setTextAlign(alignment).run(),
  },
  table: {
    addTable: {
      onClick: (editor: Editor) => {
        editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
      },
    },
    deleteTable: {
      onClick: (editor: Editor) => editor.chain().focus().deleteTable().run(),
    },
    addColBefore: {
      onClick: (editor: Editor) => editor.chain().focus().addColumnBefore().run(),
    },
    addColAfter: {
      onClick: (editor: Editor) => editor.chain().focus().addColumnAfter().run(),
    },
    deleteCol: {
      onClick: (editor: Editor) => editor.chain().focus().deleteColumn().run(),
    },
    addRowBefore: {
      onClick: (editor: Editor) => editor.chain().focus().addRowBefore().run(),
    },
    addRowAfter: {
      onClick: (editor: Editor) => editor.chain().focus().addRowAfter().run(),
    },
    deleteRow: {
      onClick: (editor: Editor) => editor.chain().focus().deleteRow().run(),
    },
    mergeCell: {
      onClick: (editor: Editor) => editor.chain().focus().mergeOrSplit().run(),
    },
    splitCell: {
      onClick: (editor: Editor) => editor.chain().focus().mergeOrSplit().run(),
    },
  },
  fontFamily: {
    onClick: (editor: Editor, fontFamily: string) =>
      editor.chain().focus().setFontFamily(fontFamily).run(),
  },
  fontColor: {
    onClick: (editor: Editor, fontColor: string) =>
      editor.chain().focus().setColor(fontColor).run(),
    getCurrentColor: (editor: Editor) => editor.getAttributes('textStyle').color,
  },
  fontSize: {
    onClick: (editor: Editor, fontSize: string) =>
      editor.chain().focus().setFontSize(fontSize).run(),
  },
  fontHighlight: {
    onClick: (editor: Editor, fontColor: string) =>
      editor.chain().focus().setHighlight({ color: fontColor }).run(),
    getCurrentHighlight: (editor: Editor) =>
      editor.isActive('highlight') ? editor.getAttributes('highlight').color : undefined,
  },
  link: {
    onClick: (editor: Editor, fontColor: string) =>
      editor.chain().focus().setColor(fontColor).run(),
  },
  customButton: {
    onClick: (editor: Editor, buttonStyle: AddButtonProps) => {
      const { schema, view } = editor;
      const node = schema.nodes.customButton.create({
        buttonStyle,
      });
      const transaction = view.state.tr.replaceSelectionWith(node);

      view.dispatch(transaction);
    },
  },
  insertImage: {
    onClick: (editor: Editor, image: File) => {
      const { schema, view } = editor;

      const node = schema.nodes.customImageCanvas.create({
        image: image,
      });

      const transaction = view.state.tr.replaceSelectionWith(node);
      view.dispatch(transaction);
    },
  },
};
