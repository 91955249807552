import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { NumberFormat } from 'components/Common/NumberFormat/NumberFormat';
import { FollowupActionLabels } from 'constants/collection-activities/followup-statuses';
import { isNumber, keyBy } from 'lodash';
import styled from 'styled-components';
import { FollowupActivitiesData } from '../type';

const t = {
  not_viewed: 'Not Viewed',
  failed_to_follow_up: 'Failed to Follow up',
  unassigned: 'Unassigned Customers',
};

const StyledFlex = styled(Flex)`
  height: 60px;
  padding: var(--space-12) var(--space-16);
  background: var(--gray-2);
`;

function ExtraFollowUpActivities(props: {
  data: FollowupActivitiesData[];
  dataRankedActivity: FollowupActivitiesData[];
}) {
  const { data, dataRankedActivity } = props;

  const Icon = <FontAwesomeIcon icon={['far', 'exclamation-triangle']} color="var(--red-4)" />;

  const dataMap = keyBy(data, 'type');

  const notViewedAmount = dataMap[FollowupActionLabels.NOT_VIEWED].valueObject.outstanding.amount;
  const failedToFollowupAmount =
    dataMap[FollowupActionLabels.NOT_FOLLOWED_UP].valueObject.outstanding.amount;
  const assignedCustomerCount =
    dataMap[FollowupActionLabels.ASSIGNED].valueObject.outstanding.customer_count;
  const notAssignedCustomerCount =
    dataMap[FollowupActionLabels.NOT_ASSIGNED].valueObject.outstanding.customer_count;
  const debtorsCustomerCount = dataRankedActivity[0].valueObject.outstanding.customer_count;

  const NotViewed = (
    <StyledFlex direction="column" align="flex-start" style={{ borderRadius: 'var(--space-4)' }}>
      <GrowText color="var(--gray-8)" size={'var(--fs-12)'}>
        {t.not_viewed}
      </GrowText>
      <GrowText weight="semibold" color="var(--purple-10)" size={'var(--fs-16)'}>
        <AmountWithCurrencySplit
          type="multi"
          amountWithCurrencySplit={notViewedAmount}
          className="text-16 semi-bold"
          showTooltip
        />
      </GrowText>
    </StyledFlex>
  );
  const FailedToFollowUp = (
    <StyledFlex
      direction="column"
      align="flex-start"
      gap="var(--space-4)"
      style={{ borderRadius: 'var(--space-4)' }}
    >
      <GrowText color="var(--gray-8)" size={'var(--fs-12)'}>
        {t.failed_to_follow_up}
      </GrowText>
      <GrowText weight="semibold" color="var(--purple-10)" size={'var(--fs-16)'}>
        <AmountWithCurrencySplit
          type="multi"
          amountWithCurrencySplit={failedToFollowupAmount}
          className="text-16 semi-bold"
          showTooltip
        />
      </GrowText>
    </StyledFlex>
  );

  const Unassigned = (
    <StyledFlex
      direction="column"
      align="flex-start"
      gap="var(--space-4)"
      style={{ borderRadius: 'var(--space-4)' }}
    >
      <Flex align="center" gap="var(--space-4)">
        {Icon}
        <GrowText color="var(--gray-8)" size={'var(--fs-12)'}>
          {t.unassigned}
        </GrowText>
      </Flex>
      {isNumber(assignedCustomerCount) && isNumber(notAssignedCustomerCount) && (
        <Row align="middle" gutter={[4, 4]}>
          <Col>
            <NumberFormat value={notAssignedCustomerCount} textProps={{ fs: '16', strong: true }} />
          </Col>
          <Col>
            <GrowText color="var(--purple-10)" size={'var(--fs-16)'}>
              of
            </GrowText>
          </Col>
          <Col>
            <NumberFormat value={debtorsCustomerCount ?? 0} />
          </Col>
        </Row>
      )}
    </StyledFlex>
  );

  return (
    <>
      <Row gutter={[8, 8]} style={{ width: '100%', marginBottom: 'var(--space-8)' }}>
        <Col flex={'1 1 33%'}>{Unassigned}</Col>
        <Col flex={'1 1 33%'}>{FailedToFollowUp}</Col>
      </Row>
      <Row>
        <Col style={{ width: '49%' }}>{NotViewed}</Col>
      </Row>
    </>
  );
}

export default ExtraFollowUpActivities;
