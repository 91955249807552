import { QueryKey, useMutation, useQuery } from '@tanstack/react-query';
import { IParamsForPostComments } from 'components/HigherOrderComponent/KeyActivitesContainer/Comment/comment';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { useLocation, useParams } from 'react-router-dom';
import { associateTagsToCustomers } from 'services/aging';
import {
  BulkActionPayload,
  addFlagToCustomerDetailsBulkInvoices,
  addUnFlagBulkInvoices,
  pauseResumeCustomerFollowupDetailsBulkInvoices,
  postAgingKeyActivitiesBulk,
} from 'services/bulkaction';
import {
  getCustomersClosedInvoices,
  getCustomersDraftInvoices,
  getCustomersOpenInvoices,
} from 'services/customers';
import { FlagInvoiceParams, addFlagToInvoices, unflagInvoices } from 'services/flag-invoices';
import {
  InvoiceType,
  getAllInvoicesList,
  getInvoiceDetail,
  postKeyActivites,
  setCollectionStrategyStatusForInvoices,
} from 'services/invoice';
import { searchUsers } from 'services/users';
import { TFilterViews, TagsForCustomersInterface } from 'store/aging/types';
import { IInvoices, IInvoicesQueryParams, StatementsResponse } from 'store/customer/types';
import { OpenInvoiceListSummary } from 'types/api/invoice';
import { InvoiceStrategyStatus } from 'types/entities/collection-strategy';
import { User } from 'types/entities/user';

interface useInvoiceDetailsQueryProps {
  onErrorCallBack: (err: any) => void;
  onSuccessCallBack?: (data: IInvoices) => void;
}

interface useOpenInvoiceListQueryProps {
  onErrorCallBack?: (err: any) => void;
  onSuccessCallBack?: (data: StatementsResponse<IInvoices, OpenInvoiceListSummary>) => void;
  param: IInvoicesQueryParams;
  enable?: boolean;
  customerId?: string;
}

interface useDraftInvoiceListQueryProps {
  onErrorCallBack?: (err: any) => void;
  onSuccessCallBack?: (data: StatementsResponse<IInvoices, OpenInvoiceListSummary>) => void;
  param: IInvoicesQueryParams;
  enable?: boolean;
  customerId?: string;
}

interface useClosedInvoiceListQueryProps {
  onErrorCallBack?: (err: any) => void;
  onSuccessCallBack?: (data: StatementsResponse<IInvoices>) => void;
  param: IInvoicesQueryParams;
  enable?: boolean;
  customerId?: string;
}

interface useAllInvoicesQueryProps {
  onSuccessCallBack: (data: StatementsResponse<IInvoices>) => void;
}

interface useGetDocumentQueryProps<T> {
  queryFn: (entityLevel: ActionableEntity) => Promise<T[]>;
  queryKey: QueryKey;
  entityLevel: ActionableEntity;
}

export function useInvoiceDetailsQuery(props: useInvoiceDetailsQueryProps) {
  const { id: filterId } = useParams<{ id: string }>();
  return useQuery({
    queryKey: ['invoice-details', filterId],
    queryFn: () => getInvoiceDetail(filterId),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(filterId),
    onSuccess(data) {
      props.onSuccessCallBack?.(data);
    },
    onError(err) {
      props.onErrorCallBack(err);
    },
  });
}
export function useGetAllInvoicesQuery(props: useAllInvoicesQueryProps) {
  const location = useLocation();
  const state = location.state as {
    currentPage?: number;
    invoiceType?: InvoiceType;
    currentInvoiceId?: number;
    invoiceFilter?: TFilterViews;
    showToggle?: boolean;
  };
  return useQuery({
    queryKey: ['all-invoices'],
    queryFn: () =>
      getAllInvoicesList(state?.invoiceType as InvoiceType, {
        ...state?.invoiceFilter,
        pagination: true,
        size: 1,
        page: state.currentPage ? state.currentPage : 1,
      }),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(state) && Boolean(state.invoiceType) && Boolean(state.currentPage),

    onSuccess(data) {
      props.onSuccessCallBack(data);
    },
  });
}

export function useGetAllInvoiceListMutation() {
  const location = useLocation();
  const state = location.state as {
    currentPage?: number;
    invoiceType?: InvoiceType;
    currentInvoiceId?: number;
    invoiceFilter?: TFilterViews;
    showToggle?: boolean;
  };

  return useMutation({
    mutationFn: (page: number) => {
      return getAllInvoicesList(state?.invoiceType as InvoiceType, {
        ...state?.invoiceFilter,
        pagination: true,
        page,
        size: 1,
      });
    },
  });
}

export function useOpenInvoiceListQuery(props: useOpenInvoiceListQueryProps) {
  return useQuery({
    queryKey: ['open-invoice-list', props.param],
    queryFn: () => getAllInvoicesList('open', props.param),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(props.enable),
    onSuccess(data) {
      props.onSuccessCallBack?.(data);
    },
    onError(err) {
      props.onErrorCallBack?.(err);
    },
  });
}

export function useDraftInvoiceListQuery(props: useDraftInvoiceListQueryProps) {
  return useQuery({
    queryKey: ['draft-invoice-list', props.param],
    queryFn: () => getAllInvoicesList('draft', props.param),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(props.enable),
    onSuccess(data) {
      props.onSuccessCallBack?.(data);
    },
    onError(err) {
      props.onErrorCallBack?.(err);
    },
  });
}

export function useOpenCustomerDetailsInvoiceListQuery(props: useOpenInvoiceListQueryProps) {
  return useQuery({
    queryKey: ['open-invoice-list-customer-details', props.param],
    queryFn: () => getCustomersOpenInvoices(props.customerId as unknown as number, props.param),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(props.enable),
    onSuccess(data) {
      props.onSuccessCallBack?.(data);
    },
    onError(err) {
      props.onErrorCallBack?.(err);
    },
  });
}

export function useDraftCustomerDetailsInvoiceListQuery(props: useDraftInvoiceListQueryProps) {
  return useQuery({
    queryKey: ['draft-invoice-list-customer-details', props.param],
    queryFn: () => getCustomersDraftInvoices(props.customerId as unknown as number, props.param),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(props.enable),
    onSuccess(data) {
      props.onSuccessCallBack?.(data);
    },
    onError(err) {
      props.onErrorCallBack?.(err);
    },
  });
}

export function useOpenClosedListQuery(props: useClosedInvoiceListQueryProps) {
  return useQuery({
    queryKey: ['closed-invoice-list', props.param],
    queryFn: () => getAllInvoicesList('closed', props.param),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(props.enable),
    onSuccess(data) {
      props.onSuccessCallBack?.(data);
    },
    onError(err) {
      props.onErrorCallBack?.(err);
    },
  });
}

export function useClosedCustomerDetailsInvoiceListQuery(props: useClosedInvoiceListQueryProps) {
  return useQuery({
    queryKey: ['closed-invoice-list-customer-details', props.param],
    queryFn: () => getCustomersClosedInvoices(props.customerId as unknown as number, props.param),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(props.enable),
    onSuccess(data) {
      props.onSuccessCallBack?.(data);
    },
    onError(err) {
      props.onErrorCallBack?.(err);
    },
  });
}

export function useSearchUsersMutation() {
  return useMutation<User[], unknown, string>({
    mutationFn: (searchTerm: string) => searchUsers(searchTerm),
    retry: false,
  });
}

export function useGetDocumentQuery<T>(props: useGetDocumentQueryProps<T>) {
  const { queryFn, queryKey, entityLevel } = props;

  return useQuery<T[]>({
    queryKey,
    queryFn: async () => {
      const data = await queryFn(entityLevel);
      return data || [];
    },
  });
}

export function useUnFlagInvoice() {
  return useMutation({
    mutationFn: ({ params }: { params: FlagInvoiceParams }) => unflagInvoices(params),
    retry: false,
    mutationKey: ['unflagInvoice'],
  });
}

export function usePostAgingActivitiesBulk() {
  return useMutation({
    mutationFn: ({ params, baseUrl }: { params: any; baseUrl: string }) =>
      postAgingKeyActivitiesBulk(params, baseUrl),
    retry: false,
  });
}

export function usePublishTags() {
  return useMutation({
    mutationFn: ({ data }: { data: TagsForCustomersInterface }) => associateTagsToCustomers(data),
    retry: false,
  });
}

export function useFlagInvoices() {
  return useMutation({
    mutationFn: ({ invoiceList, comment }: { invoiceList?: number[]; comment?: string }) =>
      addFlagToInvoices({ invoice_list: invoiceList, comment }),
    retry: false,
  });
}

export function useFlagInvoiceBulkAction() {
  return useMutation({
    mutationFn: ({ params, baseUrl }: { params: any; baseUrl: string }) =>
      addFlagToCustomerDetailsBulkInvoices(params, baseUrl),
    retry: false,
  });
}

export function usePauseResumeStrategy() {
  return useMutation({
    mutationFn: ({ invoiceId, status }: { invoiceId: number[]; status: InvoiceStrategyStatus }) =>
      setCollectionStrategyStatusForInvoices(invoiceId, status),
    retry: false,
    mutationKey: ['pause-resume-strategy'],
  });
}

export function usePauseResumeStrategyBulk() {
  return useMutation({
    mutationFn: ({ param, baseUrl }: { param: BulkActionPayload; baseUrl: string }) =>
      pauseResumeCustomerFollowupDetailsBulkInvoices(param, baseUrl),
    retry: false,
    mutationKey: ['pause-resume-strategy', 'bulk-action'],
  });
}

export function useUnFlagInvoiceBulk() {
  return useMutation({
    mutationFn: ({ param, baseUrl }: { param: FlagInvoiceParams; baseUrl: string }) =>
      addUnFlagBulkInvoices(param, baseUrl),
    retry: false,
    mutationKey: ['unflagInvoice', 'bulk-action'],
  });
}

export function usePostCommentsMutation() {
  return useMutation({
    mutationFn: ({ params, key }: { params: IParamsForPostComments; key: string }) =>
      postKeyActivites(params, key),
    retry: false,
  });
}
