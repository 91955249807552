import { FilterViews } from 'components/FilterViews';
import FilterViewsLoader from 'components/FilterViews/Loader/FilterViewsLoader';
import { FilterView } from 'components/FilterViews/type';
import produce from 'immer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  closedInvoiceCurrentFilterViewSelector,
  closedInvoiceSavedFilterViewsSelector,
  updateClosedInvoiceCurrentFilterview,
  updateClosedInvoiceSavedFilterview,
} from 'store/invoice-v2/invoice';
import { ClosedInvoiceFilterParams } from 'types/filter-view/closed-invoice';
import { InvoiceFilterView } from 'types/filter-view/common';

export function ClosedInvoiceFilterViews() {
  const dispatch = useDispatch();

  /** selectors */
  const currentFilterView = useSelector(closedInvoiceCurrentFilterViewSelector, shallowEqual);
  const savedFilterViews = useSelector(closedInvoiceSavedFilterViewsSelector);

  function updateCurrentView(filterViews: InvoiceFilterView<ClosedInvoiceFilterParams>) {
    dispatch(updateClosedInvoiceCurrentFilterview(filterViews.id));
  }

  function saveFilterViewCallback(filterViews: InvoiceFilterView<ClosedInvoiceFilterParams>) {
    const updatedViews = produce(savedFilterViews, (draft) => {
      const isExists = draft.findIndex((view) => view.id === filterViews.id);
      draft[isExists] = filterViews;
    });
    dispatch(updateClosedInvoiceSavedFilterview(updatedViews));
    dispatch(updateClosedInvoiceCurrentFilterview(filterViews.id));
  }

  function deleteFilterViewCallback(filterId: string) {
    const filterViews = savedFilterViews.filter((filterView) => filterView.id !== filterId);
    dispatch(updateClosedInvoiceSavedFilterview(filterViews));
    dispatch(updateClosedInvoiceCurrentFilterview());
  }
  function saveAsFilterViewCallBack(filterViews: InvoiceFilterView<ClosedInvoiceFilterParams>) {
    const updatedViews = produce(savedFilterViews, (draft) => {
      draft.push(filterViews);
    });
    dispatch(updateClosedInvoiceSavedFilterview(updatedViews));
    dispatch(updateClosedInvoiceCurrentFilterview(filterViews.id));
  }

  const FilterViewList = (
    <FilterViewsLoader loading={currentFilterView && currentFilterView.id ? false : true}>
      <FilterViews<InvoiceFilterView<ClosedInvoiceFilterParams>, ClosedInvoiceFilterParams>
        totalSavedViews={savedFilterViews as unknown as FilterView<ClosedInvoiceFilterParams>[]}
        currentView={currentFilterView as unknown as FilterView<ClosedInvoiceFilterParams>}
        filterType="invoice_filter"
        entity="closed-invoices"
        updatedFilterValue={currentFilterView?.invoice_filter}
        deleteFilterViewCallBack={deleteFilterViewCallback}
        saveAsFilterViewCallBack={saveAsFilterViewCallBack}
        saveFilterViewCallBack={saveFilterViewCallback}
        updateFilterViewCallBack={updateCurrentView}
      />
    </FilterViewsLoader>
  );

  return FilterViewList;
}
