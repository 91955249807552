import { GrowText } from 'components/BaseComponents/Typography';
import { StatusCell } from 'components/Common/StatusCell/StatusCell';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { cond, stubTrue } from 'lodash';
import { TaskStatus as TaskStatusList, taskStatusConfig } from 'types/activities/task';
import { getRelativeDaysFromNow } from 'util/datetime-formatter';

function getTaskStatusAndColor(status: TaskStatusList, dueDays: number) {
  const dueStatus = cond([
    [() => status === TaskStatusList.CLOSED, () => TaskStatusList.CLOSED],
    [() => dueDays > 0, () => TaskStatusList.OVERDUE],
    [() => dueDays === 0, () => TaskStatusList.DUE_TODAY],
    [() => dueDays < 0, () => TaskStatusList.DUE],
    [stubTrue, () => TaskStatusList.CLOSED],
  ])();

  return {
    label: taskStatusConfig[dueStatus].label,
    style: taskStatusConfig[dueStatus].style,
  };
}

interface TaskStatusProps {
  status: TaskStatusList;
  shouldReverse?: boolean;
  dueDays: number;
}

export function TaskStatusComp(props: TaskStatusProps) {
  const { status, dueDays } = props;
  const { label, style } = getTaskStatusAndColor(status, dueDays);
  return (
    <StatusCell
      status={{
        mode: 'Tags',
        text: label,
        style,
      }}
    />
  );
}

interface TaskDueDateProps {
  dueDate: string;
  status: TaskStatusList;
  isCard?: boolean;
}

const cardStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
};
export function TaskDueDate(props: TaskDueDateProps) {
  const { dueDate, status, isCard } = props;
  const date = getRelativeDaysFromNow(dueDate);

  const color = date === 'Today' ? 'var(--orange-8)' : 'var(--red-7)';
  return (
    <HideWrapper hide={status === TaskStatusList.CLOSED}>
      <GrowText
        color={color}
        style={isCard ? cardStyles : { width: '200px' }}
        ellipsis={{ tooltip: date }}
      >
        {date}
      </GrowText>
    </HideWrapper>
  );
}
