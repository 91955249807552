import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { BubbleMenu, formattingOptions } from 'lib/RichText';
import { useState } from 'react';
import { commonButtonProps } from '../../Toolbar';
import { PopoverButton } from '../../common/PopoverWrapper';
import { AddButtonCanvas } from '../../common/nodes/CustomLinkButton';
import { AddButtonProps } from '../../common/nodes/CustomLinkButton/AddButtonCanvas';
import { ToolbarOptionProps } from '../../type';

const t = {
  label: 'Button',
  title: 'Text Color',
};

const Icon = <FontAwesomeIcon icon={['far', 'rectangle']} />;

const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
  title: t.label,
};

interface CustomButtonProps extends ToolbarOptionProps {}

export function CustomButton({ editor }: CustomButtonProps) {
  const [open, setOpen] = useState<boolean>(false);
  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    icon: Icon,
    onClick: () => {
      setOpen((prev) => !prev);
    },
  };

  const ButtonIcon = <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />;

  function onSuccess(buttonStyle: AddButtonProps) {
    formattingOptions.customButton.onClick(editor, buttonStyle);
    onCancel();
  }

  function onCancel() {
    setOpen(false);
  }

  const Pallete = <AddButtonCanvas onSuccess={onSuccess} onCancel={onCancel} />;
  const EditIcon = (
    <Button
      buttonProps={{
        icon: <FontAwesomeIcon icon={['far', 'pen-alt']} />,
      }}
      toolTipProps={{
        title: 'Edit',
      }}
    />
  );

  const DeleteIcon = (
    <Button
      buttonProps={{
        icon: <FontAwesomeIcon icon={['far', 'trash-alt']} />,
      }}
      toolTipProps={{
        title: 'Delete',
      }}
    />
  );

  const ActionMenu = (
    <BubbleMenu
      pluginKey={'custom-button-bubble-menu'}
      editor={editor}
      shouldShow={(props) => {
        return false;
      }}
    >
      <Space>
        {EditIcon}
        {DeleteIcon}
      </Space>
    </BubbleMenu>
  );

  return (
    <PopoverButton
      align={{
        points: ['tr', 'bl'],
      }}
      arrow
      open={open}
      content={Pallete}
      overlayInnerStyle={{ width: '320px' }}
      destroyTooltipOnHide
    >
      {ActionMenu}
      {ButtonIcon}
    </PopoverButton>
  );
}
