import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'antd';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { commonButtonProps } from '../Toolbar';

interface DropdownButtonProps extends React.ComponentProps<typeof Dropdown> {
  children?: ReactNode;
}

const StyledDropDownButton = styled(Flex)`
  .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    background: none;
  }

  &:hover {
    background: var(--gray-3);
    border-radius: var(--br-4);
  }
`;

export function DropdownButton(props: DropdownButtonProps) {
  const { children } = props;

  const Icon = <FontAwesomeIcon icon={['far', 'caret-down']} />;

  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    style: {
      ...commonButtonProps?.style,
      width: '16px',
    },
    icon: Icon,
    disabled: props.disabled,
  };

  const DropdownChildren = (
    <StyledDropDownButton className="cursor-pointer" align="center">
      {children}
      <Button buttonProps={buttonProps} />
    </StyledDropDownButton>
  );

  return React.createElement(
    Dropdown,
    { ...props, placement: 'bottom', trigger: ['click'] },
    DropdownChildren
  );
}
