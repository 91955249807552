import { Divider, Skeleton } from 'antd';
import { GrowCol } from 'components/BaseComponents/GrowGrid';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ActivityCardDetails, ActivityCardInfo, DetailsLayoutContainer } from '../style';

export default function ActivitiesModalLoader() {
  const loaderSmall = <Skeleton.Input active size="small" style={{ width: '20%' }} />;
  const loaderMedium = <Skeleton.Input active size="small" style={{ width: '40%' }} />;

  const SkeletonLoader1 = (
    <Flex direction="column" gap="var(--space-12)" style={{ marginBottom: 'var(--space-32)' }}>
      <Skeleton.Input active size="small" />
      <Skeleton.Input active size="small" style={{ width: '60%' }} />
      <Skeleton.Input active size="small" style={{ width: '80%' }} />
    </Flex>
  );

  const SkeletonLoader2 = (
    <Flex direction="column" gap="var(--space-12)" style={{ marginBottom: 'var(--space-32)' }}>
      {loaderSmall}
      {loaderMedium}
    </Flex>
  );

  const SkeletonLoader3 = (
    <Flex direction="column" gap="var(--space-12)" style={{ marginBottom: 'var(--space-16)' }}>
      <Skeleton.Input active size="small" style={{ width: '50%' }} />
      <Skeleton.Input active style={{ width: '100%' }} />
    </Flex>
  );

  const SkeletonLoader4 = (
    <Flex direction="column" gap="var(--space-12)">
      {loaderSmall}
      <Skeleton.Button active />
      <Skeleton.Input active size="small" style={{ width: '70%' }} />
    </Flex>
  );

  const SkeletonLoader5 = (
    <Flex direction="column" gap="var(--space-12)" style={{ marginBottom: 'var(--space-16)' }}>
      {loaderSmall}
      <Skeleton.Button active style={{ width: '30%' }} />
    </Flex>
  );

  const SkeletonLoader6 = (
    <Flex direction="column" gap="var(--space-8)" style={{ marginBottom: 'var(--space-16)' }}>
      {loaderMedium}
      <Skeleton.Input active size="small" style={{ width: '50%' }} />
    </Flex>
  );

  const SkeletonLoaderRepeated = (
    <>
      {Array.from({ length: 4 }).map((_, index) => (
        <Flex
          key={index}
          direction="column"
          gap="var(--space-8)"
          style={{ marginBottom: 'var(--space-16)' }}
        >
          {loaderMedium}
          <Skeleton.Input active size="small" style={{ width: '50%' }} />
        </Flex>
      ))}
    </>
  );

  return (
    <DetailsLayoutContainer style={{ width: '100%' }}>
      <GrowCol flex="auto">
        <ActivityCardDetails mode={'inbox'}>
          {SkeletonLoader1}
          <Skeleton.Button active style={{ marginBottom: 'var(--space-16)' }} />
          {SkeletonLoader2}
          {SkeletonLoader3}
          <Divider style={{ margin: 'var(--space-16) 0 var(--space-16) 0' }} />
          {SkeletonLoader4}
        </ActivityCardDetails>
      </GrowCol>
      <GrowCol style={{ width: '320px' }}>
        <ActivityCardInfo mode={'inbox'}>
          <Skeleton.Input active style={{ marginBottom: 'var(--space-32)' }} />
          {SkeletonLoader5}
          {SkeletonLoader6}
          {SkeletonLoaderRepeated}
        </ActivityCardInfo>
      </GrowCol>
    </DetailsLayoutContainer>
  );
}
