import { Form as AntForm } from 'antd';
import { FormItemProps, FormProps } from './type';

const { Item } = AntForm;
function AntFormComponent(props: FormProps) {
  return <AntForm {...props} />;
}
function AntFormItemComponent(props: FormItemProps) {
  return <Item {...props} />;
}

export const useFormWatch = AntForm.useWatch;

export const useForm = AntForm.useForm;

export const FormProvider = AntForm.Provider;

export { AntFormComponent, AntFormItemComponent };
