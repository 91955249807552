import { Checkbox as AntCheckBox, CheckboxOptionType, Divider } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { every } from 'lodash';
import { ReactNode, ReactText } from 'react';
import { ICheckBoxAnt } from '../types';

export type CheckboxChangeEventType = CheckboxChangeEvent;
export type CheckboxValueType = string | number | boolean;

export const AntCheckBoxComponent = (props: ICheckBoxAnt) => {
  const onChangeTrigger = (event: CheckboxChangeEvent) => {
    props.onChange && props.onChange(event);
  };

  return (
    <AntCheckBox
      style={props.style}
      disabled={props.disabled}
      checked={props.checked}
      className={props.className}
      onChange={onChangeTrigger}
      indeterminate={props.indeterminate}
    >
      {props.children}
    </AntCheckBox>
  );
};

const CheckboxGroup = AntCheckBox.Group;

interface AntCheckBoxGroupComponentProps {
  checkedList: Array<ReactText>;
  checkAllLabel?: ReactNode;
  className?: string;
  optionsList: Array<CheckboxOptionType>;
  onChangeCallBack?: (list: CheckboxValueType[]) => void;
}

export const AntCheckBoxGroupComponent = (props: AntCheckBoxGroupComponentProps) => {
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    let filteredList: CheckboxValueType[] = [];

    if (e.target.checked) {
      filteredList = props.optionsList?.map((list) => list?.value);
    } else {
      filteredList = props.optionsList
        ?.filter((list) => list?.disabled)
        ?.map((list) => list?.value);
    }

    props.onChangeCallBack && props.onChangeCallBack([...filteredList]);
  };

  return (
    <div className={`${props.className}`}>
      <AntCheckBox
        disabled={Boolean(
          props?.checkedList?.length === props.optionsList?.length &&
            every(props.optionsList, 'disabled')
        )}
        indeterminate={Boolean(
          props?.checkedList?.length && props?.checkedList?.length !== props.optionsList?.length
        )}
        onChange={onCheckAllChange}
        checked={props?.checkedList?.length === props.optionsList?.length}
      >
        <span className={``}>{props.checkAllLabel ?? 'Check all'}</span>
      </AntCheckBox>
      <Divider />
      <CheckboxGroup
        options={props.optionsList ?? []}
        value={props?.checkedList?.length ? props.checkedList : []}
        onChange={props.onChangeCallBack}
      />
    </div>
  );
};
