import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'reducers/rootReducer';
import { SearchResultsResponseState } from './types';

export const initialState: SearchResultsResponseState = {
  status: 0,
  message: '',
  results: undefined,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    getSearchResultsHandler(state, action: PayloadAction<SearchResultsResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.results = action.payload.results;
    },
    clearStatusAndMessageHandler(state) {
      state.status = 0;
      state.message = '';
      state.results = undefined;
    },
  },
});

export const searchSelector = (state: RootState) => state.search;

export const { getSearchResultsHandler, clearStatusAndMessageHandler } = searchSlice.actions;

export default searchSlice.reducer;
