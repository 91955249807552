import { DetailsLayout } from 'components/CollectionActivities/Common/Details/DetailsLayout';
import { useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { useOnDeleteActivity } from 'components/Common/hooks/useOnDeleteActivity';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { useCollectionActivityQuery } from 'queries/activites';
import { ActivityDetailsBaseProps } from 'types/activities/activity-types';
import { Task } from 'types/activities/task';
import { getActivityDetails } from '../activityCardUtils';
import ActivitiesModalLoader from '../Common/ActivitiesModalLoader';
import useEmitActivityEvents from '../hooks/useEmitActivityEvents';
import { ActivityLoader } from '../style';
import { TaskDetailsBody } from './TaskDetailsBody';
import { TaskDetailsInfo } from './TaskDetailsInfo';
interface TaskDetailsProps extends ActivityDetailsBaseProps {}
function TaskDetails(props: TaskDetailsProps) {
  const {
    id,
    customer,
    type,
    hideActions,
    queryKey,
    mode,
    subscriptionType,
    isListFetched,
    onDelete,
    editLoading,
    deleteLoading,
    activitiesMode,
  } = props;
  const { data, isLoading, error } = useCollectionActivityQuery<Task>({ id, type, isListFetched });
  const isUnauthorized = error ? error?.status === 403 : false;
  const { entitySearchParams } = useEntitySearchParams();
  const { deleteActivityListData, isLoading: deleteAssociatedActivityLoading } =
    useOnDeleteActivity();
  useEmitActivityEvents(isLoading);
  if (activitiesMode && isLoading) {
    return (
      <ActivityLoader>
        <ActivitiesModalLoader />
      </ActivityLoader>
    );
  }

  if (entitySearchParams[EActivityQueryParams.ASSOCIATED_ACTIVITY]) {
    return getActivityDetails({
      type: data?.associated_activity_type!,
      id: data?.associated_activity_id,
      hideActions,
      queryKey,
      customer,
      isListFetched: true,
      onDelete: deleteActivityListData,
      deleteLoading: deleteAssociatedActivityLoading,
      activitiesMode: true,
    });
  }

  return (
    <DetailsLayout
      mode={mode}
      isUnauthorized={isUnauthorized}
      content={
        <TaskDetailsBody
          data={data}
          isLoading={isLoading}
          customer={customer}
          hideActions={hideActions}
          subscriptionType={subscriptionType}
          onDelete={onDelete}
          editLoading={editLoading}
          deleteLoading={deleteLoading}
          activitiesMode={activitiesMode}
        />
      }
      info={
        <TaskDetailsInfo
          loading={isLoading}
          data={data}
          queryKey={queryKey}
          customer={customer}
          activitiesMode={activitiesMode}
        />
      }
    />
  );
}

export { TaskDetails };
