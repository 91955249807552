import { documentMessages } from '../text';

export function getNotifySuccessMessage() {
  return {
    title: documentMessages.create.success.title,
  };
}

export function getNotifyErrorMessage() {
  return {
    title: documentMessages.create.error.title,
  };
}
