import { InputNumber, Space } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { Form } from 'components/BaseComponents/Form';
import { FormRules } from 'components/BaseComponents/Form/Antd/type';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Radio, RadioGroup } from 'components/BaseComponents/Radio';
import { GenericRadioChangeEvent } from 'components/BaseComponents/Radio/Antd/type';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { useRef } from 'react';
import {
  CustomFieldDataType,
  CustomFieldOperatorType,
  CustomFiledOperands,
  DecimalFields,
  getValidationRegex,
  getValidationText,
} from 'types/entities/custom-field';
import CustomRange, { dispatchErrorValidation } from './CustomRange';

const t = {
  greaterThan: 'Greater than or equals',
  lessThan: 'Less than or equals',
  from: 'From',
  to: 'To',
  unassigned: 'Is unassigned',
  equalTo: 'Equals',
  Between: 'Between',
};

const fieldIds = {
  single_values: 'single_values',
  range_values: {
    from: 'from',
    to: 'to',
  },
};

export interface CustomFiledRangeFilterAdditionalProps {
  showCustomRangeSelect?: boolean;
  dataType: CustomFieldDataType;
  currencySymbol?: string;
}

interface CustomFieldRangeFilterProps<T> {
  label?: string;
  name: string;
  value?: T;
  onChange?: (value: T) => void;
  additionalProps?: CustomFiledRangeFilterAdditionalProps;
}

export type CustomFieldRangeValueType<T extends CustomFieldOperatorType> = {
  value: CustomFiledOperands<T>['value'];
  type: T;
  should_include_unassigned: boolean;
};

function CustomFieldRange<T extends CustomFieldRangeValueType<CustomFieldOperatorType>>(
  props: CustomFieldRangeFilterProps<T>
) {
  const [form] = useForm();

  const { onChange, value, additionalProps } = props;

  const ref = useRef(value);
  const regEx = getValidationRegex(additionalProps?.dataType as DecimalFields);
  const errorText = getValidationText(additionalProps?.dataType as DecimalFields);
  function customRangeChangeEvent(event: GenericRadioChangeEvent<CustomFieldOperatorType>) {
    form.resetFields();
    dispatchErrorValidation(false);
    switch (event.target.value) {
      case 'BTWN':
        onChange?.({
          value: ref.current?.type === 'BTWN' ? ref.current.value : { from: '1', to: '2' },
          type: 'BTWN',
          should_include_unassigned: false,
        } as T);
        break;
      case 'EQ':
        onChange?.({
          value: ref.current?.type === 'EQ' ? ref.current.value : '1',
          type: 'EQ',
          should_include_unassigned: false,
        } as T);
        break;
      case 'GOE':
        onChange?.({
          value: ref.current?.type === 'GOE' ? ref.current.value : '1',
          type: 'GOE',
          should_include_unassigned: false,
        } as T);
        break;
      case 'LOE':
        onChange?.({
          value: ref.current?.type === 'LOE' ? ref.current.value : '1',
          type: 'LOE',
          should_include_unassigned: false,
        } as T);
        break;
      case 'IN':
        onChange?.({
          value: ref.current?.type === 'IN' ? ref.current.value : ['1'],
          type: 'IN',
          should_include_unassigned: false,
        } as unknown as T);
        break;
      case 'UNS':
        onChange?.({
          value: [],
          type: 'UNS',
          should_include_unassigned: true,
        } as unknown as T);
        break;

      default:
        break;
    }
  }

  function singleValueValidator(_: RuleObject, inputValue: number) {
    if (!inputValue && inputValue !== 0) {
      dispatchErrorValidation(true);
      return Promise.reject('Value should not be empty');
    }
    if (!regEx.test(inputValue as unknown as string)) {
      dispatchErrorValidation(true);
      return Promise.reject(errorText);
    }
    dispatchErrorValidation(false);
    return Promise.resolve();
  }

  const singleValueRules: FormRules = [
    {
      validator: singleValueValidator,
    },
  ];

  function handleSingleValueChange(singleValue: number | null) {
    onChange?.({
      value: value?.type === 'IN' ? [String(singleValue)] : String(singleValue),
      type: value?.type,
      should_include_unassigned: value?.should_include_unassigned,
    } as unknown as T);
  }

  const SingleValues = (
    <Form form={form}>
      <StyledFormItem
        fieldId={fieldIds.single_values}
        shouldUpdate
        name="radio-group"
        rules={singleValueRules}
        className="single-value"
        preserve={false}
        initialValue={Number(value?.value as CustomFiledOperands<'LOE' | 'GOE' | 'EQ'>['value'])}
      >
        <InputNumber
          onChange={handleSingleValueChange}
          controls={false}
          prefix={
            additionalProps?.dataType === 'CURRENCY' ? additionalProps.currencySymbol : undefined
          }
        />
      </StyledFormItem>
    </Form>
  );

  const GreaterThan = (
    <Radio<CustomFieldOperatorType> value="GOE" checked={value?.type === 'GOE'}>
      <TypographyText>{t.greaterThan}</TypographyText>
      {value?.type === 'GOE' && SingleValues}
    </Radio>
  );

  const LessThan = (
    <Radio<CustomFieldOperatorType> value="LOE" checked={value?.type === 'LOE'}>
      <TypographyText>{t.lessThan}</TypographyText>
      {value?.type === 'LOE' && SingleValues}
    </Radio>
  );

  const EqualTo = (
    <Radio<CustomFieldOperatorType> value="IN" checked={value?.type === 'IN'}>
      <TypographyText>{t.equalTo}</TypographyText>
      {value?.type === 'IN' && SingleValues}
    </Radio>
  );
  const CustomRanges = (
    <Radio<CustomFieldOperatorType> value="BTWN" checked={value?.type === 'BTWN'}>
      <TypographyText>{t.Between}</TypographyText>
      {value?.type === 'BTWN' && (
        <CustomRange
          value={value as CustomFieldRangeValueType<'BTWN'>}
          onChange={(value) => {
            onChange?.(value as T);
          }}
          currencySymbol={
            additionalProps?.dataType === 'CURRENCY' ? additionalProps.currencySymbol : undefined
          }
          dataType={props.additionalProps?.dataType}
        />
      )}
    </Radio>
  );

  const Unassigned = (
    <Radio<CustomFieldOperatorType> value="UNS" checked={value?.type === 'UNS'}>
      <TypographyText>{t.unassigned}</TypographyText>
    </Radio>
  );
  return (
    <Flex direction="column" gap="--space-8">
      <RadioGroup onChange={customRangeChangeEvent} value={value?.type}>
        <Space direction="vertical">
          {Unassigned}
          {GreaterThan}
          {LessThan}
          {EqualTo}
          {CustomRanges}
        </Space>
      </RadioGroup>
    </Flex>
  );
}

export default CustomFieldRange;
