import { Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import ActivityCardActions from 'components/CollectionActivities/Common/Card/ActivityCardActions';
import ActivityCardHeader from 'components/CollectionActivities/Common/Card/ActivityCardHeader';
import { LatestActivityFooter } from 'components/CollectionActivities/Common/LatestActivity';
import { InboxActivityCardProps } from 'components/CollectionActivities/types';
import { getKeyActivityConfig } from 'components/HigherOrderComponent/KeyActivitesContainer/key-acivities-config';
import { CustomerAndInvoice } from 'components/Inbox/CollectionActivitiesV2/Activities/CustomerAndInvoice';
import { ToggleActivityReadButton } from 'components/Inbox/CollectionActivitiesV2/Activities/ToggleActivityReadButton';
import { ELastContactActivity } from 'store/activity-feed/type';
import { ActivityType } from 'types/activities/activity-types';

const activityConfig = getKeyActivityConfig(ELastContactActivity.CREDIT_MEMO);

interface CreditMemoCardProps extends InboxActivityCardProps<ActivityType.CREDIT_MEMO> {}

function CardHeader(props: CreditMemoCardProps) {
  const { credit_memo, viewMode, activitiesMode, activityBg } = props;
  const MarkAsRead = (
    <ToggleActivityReadButton
      activity={credit_memo}
      viewMode={viewMode}
      hideDot={activitiesMode}
      css={activityBg}
    />
  );

  const Title = (
    <GrowText color="var(--gray-8)" strong={!credit_memo.read}>
      {activityConfig?.cardLabel}
    </GrowText>
  );
  const Heading = (
    <Flex gap="var(--space-4)" align="center">
      {MarkAsRead}
      {Title}
    </Flex>
  );
  return (
    <ActivityCardHeader activitiesMode={activitiesMode}>
      {Heading}
      <ActivityCardActions activity={credit_memo} />
    </ActivityCardHeader>
  );
}

function CardBody(props: CreditMemoCardProps) {
  const { credit_memo, activitiesMode } = props;

  const Body = (
    <GrowText ellipsis strong={!credit_memo.read} size="var(--fs-16)">
      {credit_memo.title}
    </GrowText>
  );

  const Details = (
    <CustomerAndInvoice
      customer={credit_memo.customer}
      invoices={credit_memo.invoices}
      activitiesMode={activitiesMode}
    />
  );

  return (
    <Row gutter={[0, 6]}>
      <Col span={24}> {Body}</Col>
      <Col span={24}> {Details}</Col>
    </Row>
  );
}

function CardFooter({ credit_memo }: CreditMemoCardProps) {
  const { latest_activity } = credit_memo;

  return <LatestActivityFooter latestActivity={latest_activity} />;
}

const CreditMemoCard = () => <></>;

CreditMemoCard.Header = CardHeader;
CreditMemoCard.Body = CardBody;
CreditMemoCard.Footer = CardFooter;

export { CreditMemoCard };
