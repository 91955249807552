import { Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ToolTipPlacement } from 'components/BaseComponents/Tooltip/type';
import { GrowParagraph } from 'components/BaseComponents/Typography';
import { Contact } from 'types/entities/contact';
import { CustomDetail, CustomDetailsType } from 'types/entities/custom-detail';
import { cleanArrayPredicate } from 'util/predicates';
import CustomDetailRecord from './CustomDetailRecord';

interface DetailProps {
  customerName: string;
  customDetails: CustomDetail<string | Contact[]>[];
  parent: JSX.Element;
  placement: ToolTipPlacement;
}

function CustomerNameWithDetail(props: DetailProps) {
  const customerName = props.customerName;
  const customDetails = props.customDetails;
  const MAX_CONTACTS_TO_LIST = 3;
  const tooltipHeader = (
    <Flex align="center" gap="var(--space-4)">
      <GrowParagraph
        strong
        ellipsis={{ rows: 2, tooltip: customerName, onEllipsis(ellipsis) {} }}
        style={{ fontSize: 'var(--fs-16)', margin: 0 }}
      >
        {customerName}
      </GrowParagraph>
    </Flex>
  );

  function sanitizeContacts(contacts: Contact[]) {
    return contacts
      .filter((contact) => cleanArrayPredicate(contact.first_name || contact.last_name))
      .slice(0, MAX_CONTACTS_TO_LIST);
  }

  function getValues(customDetail: CustomDetail<string | Contact[]>) {
    switch (customDetail.type) {
      case CustomDetailsType.CUSTOM_FIELD:
        if (customDetail.value) {
          return {
            displayName: customDetail.display_name,
            customValue: customDetail.value as string,
          };
        }
        break;
      case CustomDetailsType.PRIMARY_CONTACT:
      case CustomDetailsType.SECONDARY_CONTACT:
        const contacts = sanitizeContacts(customDetail.value as Contact[]);
        if (contacts && Boolean(contacts.length)) {
          return {
            displayName:
              contacts.length > 1 ? customDetail.display_name + '(s)' : customDetail.display_name,
            contacts: contacts,
          };
        }
        break;
    }
  }

  const content = (
    <Flex
      direction="column"
      align="flex-start"
      gap="var(--space-12)"
      style={{ maxWidth: '300px', wordBreak: 'break-word' }}
    >
      {tooltipHeader}
      {customDetails?.map((detail: CustomDetail<string | Contact[]>, index) => {
        const values = getValues(detail);
        return (
          values && (
            <Flex key={index}>
              <CustomDetailRecord detail={values} />
            </Flex>
          )
        );
      })}
    </Flex>
  );

  return (
    <Popover placement={props.placement} content={content} arrow={false}>
      {props.parent}
    </Popover>
  );
}

export default CustomerNameWithDetail;
