import { Descriptions } from 'antd';
import { ActivityContent } from 'components/CollectionActivities/style';

interface ContentProps {
  label?: React.ReactNode;
  content: React.ReactNode;
}
function InfoContent(props: ContentProps) {
  const { label, content } = props;
  return (
    <ActivityContent layout="vertical" colon={false} info>
      <Descriptions.Item label={label}>{content ? content : '-'}</Descriptions.Item>
    </ActivityContent>
  );
}

export { InfoContent };
