import styled from 'styled-components';

type length = '4' | '8' | '12' | '16' | '20' | '24' | '32' | '36' | '48';

type Axis = 'horizontal' | 'vertical';
type DividerProps = {
  axis?: Axis;
  size: length;
};

const sizeToSpaceMapper: Record<length, string> = {
  4: '--space-4',
  8: '--space-8',
  12: '--space-12',
  16: '--space-16',
  20: '--space-20',
  24: '--space-24',
  32: '--space-32',
  36: '--space-36',
  48: '--space-48',
};

function getHeight({ axis, size }: DividerProps) {
  return axis === 'horizontal' ? '1px' : `var(${sizeToSpaceMapper[size]})`;
}

function getWidth({ axis, size }: DividerProps) {
  return axis === 'vertical' ? '1px' : `var(${sizeToSpaceMapper[size]})`;
}

/**
 * Usage:
 * Produces a 16px × 16px gap
 * <Spacer size={16} />
 *
 * Produces a 32px × 1px gap
 * <Spacer axis="horizontal" size={32} />
 *
 * Produces a 1px x 32px gap
 * <Spacer axis="vertical" size={32} />
 *
 * Use "rem" or "%"
 * <Spacer size="20%" />
 */
export const Divider = styled.div<DividerProps>`
  width: ${getWidth};
  min-width: ${getWidth};
  height: ${getHeight};
  min-height: ${getHeight};
  background-color: var(--gray-5);
  border-radius: var(--br-1);
  ::before {
    content: '';
  }
`;

Divider.defaultProps = {
  axis: 'vertical',
};
