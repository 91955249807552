import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'reducers/rootReducer';
import { CustomField, CustomFieldPage } from 'types/entities/custom-field';

const initialState: Record<CustomFieldPage, CustomField[]> = {
  [CustomFieldPage.AR_AGING]: [],
  [CustomFieldPage.CUSTOMER_SEGMENT]: [],
  [CustomFieldPage.EMAIL_PLACEHOLDER]: [],
  [CustomFieldPage.ADMIN_DASHBOARD]: [],
  [CustomFieldPage.INVOICE_LIST]: [],
  [CustomFieldPage.INVOICE_SEGMENT]: [],
  [CustomFieldPage.ALL_CUSTOMERS]: [],
  [CustomFieldPage.COLLECTION_PLANNING]: [],
  [CustomFieldPage.INVOICE_LIST_UI]: [],
  [CustomFieldPage.WORKFLOW]: [],
  [CustomFieldPage.ALL_CUSTOMERS_TABLE]: [],
};

/* Reducers */
type SetCustomFieldPayload = { page: CustomFieldPage; customFields: CustomField[] };

const customFieldsSlice = createSlice({
  name: 'customFields',
  initialState,
  reducers: {
    setCustomFields(state, action: PayloadAction<SetCustomFieldPayload>) {
      const { page, customFields } = action.payload;

      const customFieldIds = customFields.map((customField) => customField.id);

      const prunedCustomFieldsInState = state[page].filter(
        (customField) => !customFieldIds.includes(customField.id)
      );

      state[page] = [...prunedCustomFieldsInState, ...customFields];
    },
  },
});

/* Selectors */
export const customFieldsSelector = (state: RootState) => state.customFields;

export const invoiceSegmentCustomFieldSelector = createSelector(
  customFieldsSelector,
  (customFields) => customFields.INVOICE_SEGMENT
);

export const customFieldSelector = (page: CustomFieldPage) => (state: RootState) =>
  state.customFields[page];

/* Actions (handlers) */
export const { setCustomFields } = customFieldsSlice.actions;

export default customFieldsSlice.reducer;
