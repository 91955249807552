import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { GrowPicker } from 'components/BaseComponents/ColorPicker/GrowPicker';
import { formattingOptions } from 'lib/RichText';
import { styled } from 'lib/css-in-js';
import { commonButtonProps } from '../../Toolbar';
import { ColorPalleteContainer } from '../../common/ColorPallete';
import { ToolbarOptionProps } from '../../type';

const t = {
  label: 'Text Highlight',
  title: 'Highlight Color',
};

const StyledIcon = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: -2px;
    height: 2px;
    width: 100%;
    border-radius: var(--br-1);
    background: ${(props) => props.color ?? 'intial'};
  }
`;

const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
  title: t.label,
};

interface TextHighLightProps extends ToolbarOptionProps {}

export function TextHighLight({ editor }: TextHighLightProps) {
  const Icon = (
    <StyledIcon
      className="color-container"
      color={`${formattingOptions.fontHighlight.getCurrentHighlight(editor)}`}
    >
      <FontAwesomeIcon icon={['far', 'paintbrush']} />
    </StyledIcon>
  );

  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    icon: Icon,
  };

  const CurrentColorPalette = <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />;

  function onSelectCallback(
    color: Parameters<
      NonNullable<React.ComponentProps<typeof ColorPalleteContainer>['onSelectCallback']>
    >[0]
  ) {
    formattingOptions.fontHighlight.onClick(editor, color);
  }

  return <GrowPicker onChange={onSelectCallback} overlay={CurrentColorPalette} showPicker />;
}
