import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentListData } from 'store/payments/type';

import { Sorter } from '@sinecycle/growcomponents';
import { Page } from 'components/BaseComponents/Table/Pagination/TablePagination';
import { PaymentListFilter, PaymentListFilterView, PaymentListSwitcher } from './type';

export interface PaymentListProps {
  currentView: PaymentListFilterView;
  totalSavedViews: PaymentListFilterView[];

  page: Page;
  bulk_records: number;
  summary?: PaymentListData['summary'];
}
export const initialState: PaymentListProps = {
  currentView: {} as PaymentListFilterView,
  totalSavedViews: [],
  bulk_records: 0,
  page: {
    page: 1,
    size: 20,
  },
};

const PaymentSlice = createSlice({
  name: 'paymentList',
  initialState,
  reducers: {
    updateCurrentView(state, action: PayloadAction<PaymentListFilterView>) {
      state.currentView = action.payload;
      state.page = { ...state.page, page: 1 };
    },
    getFilterViews(
      state,
      action: PayloadAction<{
        filterViews: PaymentListFilterView[];
        id?: string;
        sessionFilters?: PaymentListFilter;
        reset?: boolean;
      }>
    ) {
      state.totalSavedViews = action.payload.filterViews.filter((filterViews) => filterViews);

      const selectedFilterView = action.payload.filterViews.find(
        (filterView) => filterView.id === action.payload.id
      ) as PaymentListFilterView;

      const resetFilter: PaymentListFilter = {
        view: PaymentListSwitcher.ALL,
        sort_col: 'PAYMENT_DATE',
        sort_by: 'DESC',
        participant_scope: 'DEFAULT',
        view_scope: 'DEFAULT',
      };

      if (selectedFilterView) {
        const paymentFilter = action.payload.sessionFilters
          ? {
              ...action.payload.sessionFilters,
              view: action.payload.sessionFilters.view ?? null,
            }
          : {
              ...selectedFilterView.payments_report_filter,
              view: selectedFilterView.payments_report_filter.view ?? null,
            };

        const updatedSelectedView = {
          ...selectedFilterView,
          payments_report_filter: action.payload.reset ? resetFilter : paymentFilter,
        };

        state.currentView = updatedSelectedView ?? ({} as PaymentListFilterView);
      } else {
        const paymentFilter = {
          ...action.payload.filterViews[0].payments_report_filter,
          view: action.payload.filterViews[0].payments_report_filter.view ?? null,
        };

        const updatedFilterView = {
          ...action.payload.filterViews[0],
          payments_report_filter: action.payload.reset ? resetFilter : paymentFilter,
        };
        state.currentView = updatedFilterView ?? ({} as PaymentListFilterView);
      }
    },

    updateFilterViewSaveAs(state, action: PayloadAction<PaymentListFilterView>) {
      state.totalSavedViews = [...state.totalSavedViews, action.payload];
      state.currentView = action.payload;
    },

    updateFilterViewSave(state, action: PayloadAction<PaymentListFilterView>) {
      state.totalSavedViews = state.totalSavedViews.map((filterViews) => {
        if (filterViews.id === action.payload.id) {
          return { ...action.payload };
        }
        return filterViews;
      });
    },

    deleteFilterViews(state, action: PayloadAction<string>) {
      state.totalSavedViews = state.totalSavedViews.filter(
        (filterViews) => filterViews.id !== action.payload
      );
    },

    updateSwitcherView(state, action: PayloadAction<PaymentListSwitcher>) {
      state.page = { ...state.page, page: 1 };
      state.currentView.payments_report_filter = {
        ...state.currentView.payments_report_filter,
        view: action.payload,
      };
    },

    updateCurrentPage(state, action: PayloadAction<Page>) {
      state.page = action.payload;
    },

    updateSortingCurrentFilter(state, action: PayloadAction<Sorter>) {
      state.currentView.payments_report_filter = {
        ...state.currentView.payments_report_filter,
        sort_by: action.payload.sortOrder,
        sort_col: action.payload.sortCol,
      };
      state.page = { ...state.page, page: 1 };
    },

    initializeTagsAndTotalPages(state, action: PayloadAction<PaymentListData>) {
      state.bulk_records = action.payload.total_records;
      state.summary = action.payload.summary;
    },

    updateCurrentFilter(state, action: PayloadAction<PaymentListFilter>) {
      state.currentView.payments_report_filter = action.payload;
      state.page = { ...state.page, page: 1 };
    },
  },
});

export const {
  getFilterViews,
  updateFilterViewSaveAs,
  updateFilterViewSave,
  deleteFilterViews,
  updateCurrentView,
  updateSwitcherView,
  initializeTagsAndTotalPages,
  updateCurrentPage,
  updateSortingCurrentFilter,
  updateCurrentFilter,
} = PaymentSlice.actions;
export default PaymentSlice.reducer;
