import { IS_LOCAL } from 'util/http-utils';
import { Sentry } from './sentry';

export const errorMonitor = {
  init() {
    if (IS_LOCAL) return;

    Sentry.init();
  },

  identify({ email }: { email: string }) {
    if (IS_LOCAL) return;

    Sentry.identify({ email });
  },

  logError(error: Error, errorInfo: any = null) {
    if (IS_LOCAL) return;

    Sentry.logError(error, errorInfo);
  },
};
