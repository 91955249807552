import { Spin } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledCard } from 'pages/Dashboard/AdminDashboard/SnapShot/Snapshot';

interface ChartSpinnerProps {
  title: React.ReactNode;
  height: string;
}

export default function ChartSpinner(props: ChartSpinnerProps) {
  const { title, height } = props;

  return (
    <StyledCard style={{ height: '300px' }}>
      <Spin delay={0} style={{ height: height }}>
        <Flex direction="column" align="flex-start" gap="var(--space-8)">
          {title}
        </Flex>
      </Spin>
    </StyledCard>
  );
}
