import { EscalationType } from 'types/activities/escalation';
import ky from './ky';

export interface UpdateEscalationStatusProps {
  status: EscalationType;
}

export async function updateEscalationStatus(
  params: UpdateEscalationStatusProps,
  id: number
): Promise<unknown> {
  return await ky.put(`escalations/${id}/status`, { json: params });
}
