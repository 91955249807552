import { UserNotifications } from 'store/user-notifications';
import { kyWithSerializer } from './ky';

const baseUrl = 'user-notifications';

export async function updateUserNotificationStatus(userNotifications: UserNotifications) {
  return kyWithSerializer.put(`${baseUrl}/status`, { json: userNotifications });
}

export async function getUserNotificationStatus() {
  return (await kyWithSerializer.get(`${baseUrl}/status`).json()) as UserNotifications;
}
