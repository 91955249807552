import {
  deleteBusinessUnits,
  getBusinessUnits,
  postBusinessUnits,
  updateBusinessUnits,
} from 'services/business-units';
import {
  getAllBusinessUnitsHandler,
  updateBusinessUnitHandler,
} from 'store/business-units/business-units';
import { AllBusinessUnitsResponseState, CRUD_ACTIONS } from 'store/business-units/types';
import { AppThunk } from 'store/store';
import { BusinessUnit } from 'types/entities/business-unit';

export const getBusinessUnitsThunk = (): AppThunk<
  Promise<{ payload: AllBusinessUnitsResponseState; type: string }>
> => {
  return async (dispatch) => {
    try {
      const businessUnits = await getBusinessUnits();
      return dispatch(getAllBusinessUnitsHandler({ status: 200, businessUnits }));
    } catch (err: any) {
      return dispatch(getAllBusinessUnitsHandler({ status: 500, message: err.message }));
    }
  };
};

export const updateBusinessUnitThunk = (
  type: CRUD_ACTIONS,
  BusinessUnit: BusinessUnit
): AppThunk<
  Promise<{
    payload: unknown;
    type: string;
  }>
> => {
  return async (dispatch) => {
    let data = {} as BusinessUnit;
    try {
      switch (type) {
        case CRUD_ACTIONS.CREATE:
          data = await postBusinessUnits(BusinessUnit);
          break;
        case CRUD_ACTIONS.UPDATE:
          data = await updateBusinessUnits(BusinessUnit);
          break;
        case CRUD_ACTIONS.DELETE:
          await deleteBusinessUnits(BusinessUnit.id);
          data = BusinessUnit;
          break;
      }
    } catch (err) {
      console.log('Error updaing business unit', err);
    }

    return dispatch(
      updateBusinessUnitHandler({
        type,
        BusinessUnit: data,
      })
    );
  };
};
