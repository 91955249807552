import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/BaseComponents/Buttons/Button';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import useOpen from 'util/hooks/useOpen';
import { DocumentTypesModal } from './Modal/DocumentTypesModal';

const t = {
  title: 'Document Type',
};
const Icon = <FontAwesomeIcon icon={['far', 'plus']} fontSize={16} color="var(--primary-7)" />;
function AddDocumentTypes() {
  const { open, toggleOpen, onClose } = useOpen({ open: false });
  const isDocumentManage = usePrivilegeStatus([ERolesAndPrivileges.MANAGE_DOCUMENT_TYPE]);
  return (
    <>
      <HideWrapper hide={!isDocumentManage}>
        <Button
          buttonProps={{ children: t.title, icon: Icon, onClick: toggleOpen, size: 'large' }}
        />
      </HideWrapper>
      {open && <DocumentTypesModal open={open} onClose={onClose} />}
    </>
  );
}

export { AddDocumentTypes };
