import { Flex } from 'components/BaseComponents/Layout/Flex';
import FileItems from 'components/Common/AssociatedFiles/FileItems';
import { AssociatedFile } from 'components/Common/AssociatedFiles/type';
import { StatusCell } from 'components/Common/StatusCell/StatusCell';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import produce from 'immer';
import { useGetCustomerDocumentLists } from 'queries/documents/documents';
import { useCallback, useEffect, useState } from 'react';
import { RequestConfig } from 'types/common/common';
import { getDocumentConfig } from '../predicate';
import { DocumentFilter } from '../types';

interface CustomerSingleLevelDocumentProps {
  customerId: string;
}

function CustomerSingleLevelDocumentComp(props: CustomerSingleLevelDocumentProps) {
  const filters: RequestConfig<DocumentFilter> = {
    page: 1,
    size: 10,
    filters: { entity_types: ['CUSTOMER'] },
  };
  const { data } = useGetCustomerDocumentLists(filters, props.customerId);
  const [documents, setDocuments] = useState<AssociatedFile[]>();

  function handleEventCallBack(e: CustomEvent) {
    if (e.type === 'update-document') {
      setDocuments(e.detail);
    }
  }

  const eventCallBack = useCallback(handleEventCallBack, []);

  useEffect(() => {
    document.addEventListener('update-document', eventCallBack as any);

    return () => {
      document.removeEventListener('update-document', eventCallBack as any);
    };
  }, [eventCallBack]);

  useEffect(() => {
    const docs: AssociatedFile[] =
      data?.list.map((f) => {
        return {
          ...f.attachment,
          enabled: false,
          id: String(f.id),
          mode: 'SINGLE',
          level: ActionableEntity.CUSTOMER,
          file_name: f.name,
          typeId: f.type.id,
          docType: f.type,
          file_size: f.attachment.file_size,
          previewId: f.attachment.id,
        };
      }) ?? [];
    setDocuments(docs);

    const event = new CustomEvent('add-document', { detail: docs });
    document.dispatchEvent(event);
  }, [data?.list]);

  return (
    <Flex direction="column" gap="var(--space-8)">
      <>
        {documents?.map((f) => {
          return (
            <FileItems
              key={f.id}
              file={f}
              onChange={(id, status) => {
                if (!id) return;
                const updatedFiles = produce(documents, (draft) => {
                  const isExist = draft.findIndex((file) => file.id === id);

                  draft[isExist].checked = status;
                  return draft;
                });
                setDocuments(updatedFiles);

                const event = new CustomEvent('add-document', { detail: updatedFiles });
                document.dispatchEvent(event);
              }}
              extra={
                f.docType ? (
                  <StatusCell
                    status={{
                      mode: 'Tags',
                      text: getDocumentConfig(f.docType.entity_type).label,
                      style: getDocumentConfig(f.docType.entity_type).style,
                    }}
                  />
                ) : undefined
              }
            />
          );
        })}
      </>
    </Flex>
  );
}

function CustomerSingleLevelDocument(props: CustomerSingleLevelDocumentProps) {
  const isDocumentView = usePrivilegeStatus([ERolesAndPrivileges.VIEW_DOCUMENT]);
  return (
    <HideWrapper hide={!isDocumentView}>
      <CustomerSingleLevelDocumentComp {...props} />
    </HideWrapper>
  );
}

export { CustomerSingleLevelDocument };
