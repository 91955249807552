import FilterStrip from 'components/FilterViews/Strips/FilterStrips';
import { DownloadDraftReports } from './DownloadReports/DownloadDraftReport';
import { DraftInvoiceFilterViews } from './DraftInvoiceFilterViews';

export function DraftExtraTabContent() {
  return (
    <FilterStrip style={{ borderBottom: '1px solid var(--gray-5)', height: '56px' }}>
      <DraftInvoiceFilterViews />
      <DownloadDraftReports />
    </FilterStrip>
  );
}
