import { forOwn } from 'lodash';
import { getFormattedDateForRelativeOptions, RelativeDateRange } from 'util/relative-date-range';

export function filterNormalizer<T>(currentFilter: T) {
  const normalizedFilter = { ...currentFilter } as T;
  const relativeDateRangeKeys = Object.keys(RelativeDateRange);

  forOwn(currentFilter, function (value, key) {
    if (relativeDateRangeKeys.includes(value as unknown as string)) {
      const [newFrom, newTo] = getFormattedDateForRelativeOptions(
        value as unknown as RelativeDateRange
      ) as unknown as [T[keyof T], T[keyof T]];
      const fromkey = key.replace(/range/i, 'from') as keyof T;
      const tokey = key.replace(/range/i, 'to') as keyof T;

      normalizedFilter[fromkey] = newFrom;
      normalizedFilter[tokey] = newTo;
    }
  });

  return normalizedFilter;
}
