import { Col, Row } from 'antd';
import { Flex, FlexBoxChild } from 'components/BaseComponents/Layout/Flex';
import { Overlay } from 'components/BaseComponents/Overlay';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { AppliedPaymentsCredits } from 'components/Common/MultiCurrency/UnappliedPaymentsAndCredits';
import ArrowSelect from 'components/Common/SelectComponents/ArrowSelect';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { RelativeDateRangeLabels } from 'constants/date-time';
import { PageLink } from 'lib/router';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useTotalCollectionsTileQuery } from 'queries/admin-dashboard';
import React, { useState } from 'react';
import { AccountConfigKey } from 'types/entities/account';
import { RelativeDateRange } from 'util/relative-date-range';
import useShowBadDebtAndIsDemo from '../hooks/useShowBadDebtAndIsDemo';
import { StyledTileCard } from '../styles';
import { dashboardTexts } from '../texts';
import TotalCustomersInvoices from '../TotalCustomersInvoices';
import { MainCardItem, TotalCollected } from './style';

const t = dashboardTexts.totalCollected;

const PERIODS = [
  RelativeDateRange.TODAY,
  RelativeDateRange.YESTERDAY,
  RelativeDateRange.LAST_7_DAYS,
  RelativeDateRange.THIS_WEEK,
  RelativeDateRange.THIS_MONTH,
];

const PERIOD_OPTIONS = PERIODS.map((PERIOD) => ({
  label: RelativeDateRangeLabels[PERIOD],
  value: PERIOD,
}));

function TotalCollectedTile({ filterParams }: DashboardComponentProps) {
  const [timePeriod, setTimePeriod] = useState<RelativeDateRange>(RelativeDateRange.THIS_MONTH);
  const hideTotalCollected = useConfig(AccountConfigKey.HIDE_DASHBOARD_TOTAL_COLLECTED_TILE);
  const showBadDebtandIsDemo = useShowBadDebtAndIsDemo();

  const { data: totalCollection, isLoading: loading } = useTotalCollectionsTileQuery({
    ...filterParams,
    period: timePeriod,
  });

  const totalCollected = totalCollection?.total_payment_amount.amount;
  const customersCount = totalCollection?.total_payment_amount.customer_count;
  const invoicesCount = totalCollection?.total_payment_amount.invoice_count;

  const InputLoaderJSX = <InputLoader active={loading} />;

  const TotalCollectedJSX = totalCollected && totalCollection && (
    <Flex align="center" gap={'var(--space-8)'}>
      <PageLink appPage="PAYMENT_LIST" state={{ addPrimaryPathAsChildPath: true }}>
        <AmountWithCurrencySplit
          type="multi"
          amountWithCurrencySplit={totalCollected}
          className="text-30 semi-bold"
          showTooltip
          context={t.title}
          formatOptions={{ maximumFractionDigits: 0 }}
        />
      </PageLink>
      <div style={{ cursor: 'pointer' }}>
        <AppliedPaymentsCredits appliedPayments={totalCollection} />
      </div>
    </Flex>
  );

  if (hideTotalCollected) {
    return (
      <StyledTileCard>
        <Overlay shouldCover={hideTotalCollected} style={{ opacity: '.5' }}>
          <Row className="no-wrap">
            <TotalCollected flex={1}>
              <MainCardItem>
                <GrowText>{t.title}</GrowText>
                <GrowText size="var(--fs-30)">-</GrowText>
              </MainCardItem>
            </TotalCollected>
          </Row>
        </Overlay>
      </StyledTileCard>
    );
  }

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices
      loading={loading}
      customersCount={customersCount}
      invoicesCount={invoicesCount}
    />
  );

  const PeriodFilter = (
    <ArrowSelect
      options={PERIOD_OPTIONS}
      value={timePeriod}
      onChange={setTimePeriod}
      arrowStyle={'Bold'}
    />
  );

  const LabelJSX = (
    <div className="title">
      <GrowText size={'var(--fs-16)'}>{t.title}</GrowText>
    </div>
  );

  return (
    <StyledTileCard padding={'var(--space-16) var(--space-16) var(--space-12) var(--space-16) '}>
      <Row className="no-wrap">
        <Col flex="max-content 1 0">{LabelJSX}</Col>
      </Row>
      <Row className="no-wrap">
        <TotalCollected flex={1}>
          <MainCardItem>
            <Flex
              gap={showBadDebtandIsDemo ? 'var(--space-12)' : 'var(--space-6)'}
              direction="column"
            >
              <Flex align="flex-start" direction="column">
                <FlexBoxChild className="value amount">
                  {loading ? InputLoaderJSX : TotalCollectedJSX}
                </FlexBoxChild>
              </Flex>
              <Flex
                align={showBadDebtandIsDemo ? 'flex-start' : 'center'}
                justify="space-between"
                direction={showBadDebtandIsDemo ? 'column' : 'row'}
                gap={showBadDebtandIsDemo ? 'var(--space-8)' : '0'}
              >
                <FlexBoxChild>{TotalCustInvoiceInfo}</FlexBoxChild>
                <FlexBoxChild>{PeriodFilter}</FlexBoxChild>
              </Flex>
            </Flex>
          </MainCardItem>
        </TotalCollected>
      </Row>
    </StyledTileCard>
  );
}

export default React.memo(TotalCollectedTile);
