import { CellRenderProps, defaultCellComponents } from '@sinecycle/growcomponents';
import { useSelector } from 'react-redux';
import { baseCurrencySelector, dateFormatSelector } from 'store/authentication/authentication';
import { CustomFieldDataResponse } from 'types/entities/custom-field';
import { EditOnHover } from '../EditOnHover';
import { cellComponents } from './cellComponents';

export interface CustomColumnProps<T> extends CellRenderProps<T> {
  fieldData: CustomFieldDataResponse;
}

function CustomColumn<T>(props: CustomColumnProps<T>) {
  const baseCurrency = useSelector(baseCurrencySelector);
  const format = useSelector(dateFormatSelector);
  const renderNumberCell = (is_decimal: boolean) => {
    const value = Number(props.value);
    return isNaN(value)
      ? '-'
      : cellComponents.numberText<T>({
          ...props,
          value,
          numberCellProps: {
            options: {
              mode: is_decimal ? 'DECIMAL' : 'DEFAULT',
            },
          },
        });
  };

  switch (props.fieldData.data_type) {
    case 'NUMBER':
      return renderNumberCell(false);
    case 'DECIMAL':
      return renderNumberCell(true);
    case 'STRING':
    case 'TEXT':
      return (
        <EditOnHover hoverMode="CELL" hideEdit={!props.fieldData.editable}>
          {defaultCellComponents.textCell({
            ...props,
            value: props.value,
            textCellProps: { ellipsis: true },
          })}
        </EditOnHover>
      );
    case 'BOOLEAN':
      return (
        <EditOnHover hoverMode="CELL" hideEdit={!props.fieldData.editable}>
          {defaultCellComponents.textCell({
            ...props,
            value:
              props.value === '1' || props.value === 'TRUE'
                ? 'True'
                : props.value === '0' || props.value === 'FALSE'
                ? 'False'
                : props.value,
            textCellProps: { ellipsis: true },
          })}
        </EditOnHover>
      );
    case 'DATE':
    case 'DATETIME':
      return (
        <EditOnHover hoverMode="CELL" hideEdit={!props.fieldData.editable}>
          {props?.value === '-' ? (
            <>{props.value}</>
          ) : (
            defaultCellComponents.dateCell({
              ...props,
              value: props.value,
              dateCellProps: {
                format,
              },
            })
          )}
        </EditOnHover>
      );

    case 'CURRENCY':
      return (
        <EditOnHover hoverMode="CELL" hideEdit={!props.fieldData.editable}>
          {cellComponents.amountCell<T>({
            ...props,
            value: {
              currency: baseCurrency ?? 'USD',
              amount: Number(props.value),
              format: 'FULL',
            },
          })}
        </EditOnHover>
      );
    default:
      return (
        <EditOnHover hoverMode="CELL" hideEdit={!props.fieldData.editable}>
          {defaultCellComponents.textCell({ ...props, value: props.value })}
        </EditOnHover>
      );
  }
}

export { CustomColumn };
