import { HideWrapper } from '@sinecycle/growcomponents';
import { Form } from 'antd';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { size } from 'lodash';
import { useEffect } from 'react';
import { LineItemsCheckboxProps, LineItemsSelectProps } from '../types';
import LineItemsCheckbox from './LineItemsCheckbox';
import LineItemsSelect from './LineItemSelect';

export default function ShowLineItems<T>(
  props: LineItemsCheckboxProps &
    LineItemsSelectProps<T> & { invoiceLineItems?: number[]; lineItemFieldKey: string }
) {
  const {
    checked,
    disabled,
    onChange,
    queryFn,
    onChangeLineItems,
    invoiceLineItems,
    invoiceIdsList,
    lineItemFieldKey,
    queryKey,
    transformSchema,
    lineItemsLabel,
    placeholder,
    form,
    bulkAction,
  } = props;

  useEffect(() => {
    if (invoiceLineItems?.length) {
      form.setFieldValue('referLineItem', true);
    } else {
      form.setFieldValue('referLineItem', false);
    }

    if (size(invoiceIdsList) !== 1) {
      form.setFieldValue('referLineItem', false);
    }
  }, [form, invoiceIdsList, invoiceLineItems]);

  return (
    <HideWrapper hide={Boolean(bulkAction)}>
      <StyledFormItem name="referLineItem" style={{ marginBottom: 'var(--space-8)' }}>
        <LineItemsCheckbox
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          invoiceIdsList={invoiceIdsList}
        />
      </StyledFormItem>
      {size(invoiceIdsList) === 1 ? (
        <div className={`${checked ? 'active-transition' : 'hide-transition'}`}>
          <Form.Item name={lineItemFieldKey} label={lineItemsLabel} initialValue={invoiceLineItems}>
            <LineItemsSelect<T>
              placeholder={placeholder}
              onChangeLineItems={onChangeLineItems}
              queryFn={queryFn}
              queryKey={queryKey}
              transformSchema={transformSchema}
            />
          </Form.Item>
        </div>
      ) : null}
    </HideWrapper>
  );
}
