import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { notifyError } from 'lib/error-handler';
import { getSubsidiaries, getSubsidiariesForSettings, saveSubsidiary } from 'services/subsidiary';

const HALF_DAY = 12 * 60 * 60 * 1000;

const t = {
  error: 'Subsidiary update failed! Please provide a unique subisidary name',
};

export function useSubsidiariesQuery(isCurrentUserSuccess?: boolean) {
  return useQuery({
    queryKey: ['subsidiaries'],
    queryFn: () => getSubsidiaries(),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
    enabled: Boolean(isCurrentUserSuccess),
  });
}

export function useSubsidiariesSettingsQuery() {
  return useQuery({
    queryKey: ['subsidiaries', { page: 'settings' }],
    queryFn: () => getSubsidiariesForSettings(),
  });
}

export function useSubsidiaryPutQuery() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: saveSubsidiary,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['subsidiaries'],
      });
    },
    onError: (err: HTTPError) => {
      if (err?.response?.status === 409) {
        notifyError(undefined, t.error);
      }
    },
  });
}
