import { GrowTypography } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';

interface widgetProps {
  name: string;
  first_name: string;
  last_name: string;
}

const Widget = (props: widgetProps) => {
  return (
    <Flex direction="column" gap="var(--space-4)">
      <GrowTypography.Text>{props.name}</GrowTypography.Text>
      <GrowTypography.Text style={{ color: 'var(--gray-7)' }}>
        {`${props.first_name}${props.last_name ?? ''}`}
      </GrowTypography.Text>
    </Flex>
  );
};

export { Widget };
