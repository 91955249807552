import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { LegendDataItem } from './Legend';

const colorSquareIcons = {
  width: '16px',
  height: '16px',
  display: 'inline-block',
  marginRight: 'var(--space-8)',
  borderRadius: 'var(--space-2)',
};

interface LegendItemProps {
  item: LegendDataItem;
  tooltipText: string;
  onClick: () => void;
  textWidth?: string;
}
const infoIcon = (
  <FontAwesomeIcon
    icon={['far', 'circle-info']}
    color={'var(--geekblue-6)'}
    size="sm"
    style={{
      cursor: 'pointer',
    }}
  />
);
export default function LegendItem(props: LegendItemProps) {
  const { item, tooltipText, onClick, textWidth } = props;

  function handleClick() {
    onClick();
  }

  const Title = (
    <Flex direction="column" justify="center" align="flex-start">
      <TypographyText style={{ color: 'var(--geekblue-3)', fontSize: 'var(--fs-12)' }}>
        {item.label}
      </TypographyText>
      <TypographyText style={{ color: 'var(--gray-1)' }}>{tooltipText}</TypographyText>
    </Flex>
  );

  return (
    <Flex
      style={{
        marginTop: 'var(--space-6)',
        cursor: item.disable ? 'not-allowed' : 'pointer',
        minHeight: '24px',
      }}
      align="center"
      onClick={item.disable ? undefined : handleClick}
    >
      <div
        style={{
          ...colorSquareIcons,
          backgroundColor: item.toggle ? 'var(--gray-6)' : item.color,
        }}
      />
      <Flex align="center" style={{ width: textWidth ? textWidth : '120px' }} gap="var(--space-4)">
        <GrowText
          color={item.disable || item.toggle ? 'var(--gray-6)' : 'var(--gray-9)'}
          size={'var(--space-12)'}
          ellipsis={{
            tooltip: item.disable ? false : item.label,
          }}
        >
          {item.label}
        </GrowText>
        <Tooltip title={Title}>{Boolean(item.disable) && <span>{infoIcon} </span>}</Tooltip>
      </Flex>
    </Flex>
  );
}
