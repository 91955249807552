import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from 'antd';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { Editor } from 'lib/RichText/type';

interface UnsetLinkProps {
  hide?: boolean;
  editor: Editor;
}

const t = {
  tooltipText: 'Remove hyperlink',
};

function UnsetLink(props: Readonly<UnsetLinkProps>) {
  const UnsetLinkIcon = <FontAwesomeIcon icon={['far', 'link-slash']} color="--gray-9" />;

  function handleUnsetLink() {
    props.editor.chain().focus().unsetLink().run();
  }
  const Title = (
    <Texto breakWord={{ maxWidth: '65px', wordBreak: 'break-word' }} color="var(--gray-1)" as="div">
      {t.tooltipText}
    </Texto>
  );
  return (
    <Tooltip
      title={Title}
      overlayStyle={{ padding: 0, width: '80px' }}
      destroyTooltipOnHide={true}
      trigger={['hover']}
      mouseLeaveDelay={0}
      placement="bottomRight"
    >
      <Button icon={UnsetLinkIcon} onClick={handleUnsetLink} type="text" size="middle" />
    </Tooltip>
  );
}

export default UnsetLink;
