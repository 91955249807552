import { Radio } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { OptionsProps } from '../../types';

interface SingleSelectProps<T> {
  label?: string;
  name: string;
  value?: T;
  options: OptionsProps[];
  onChange?: (value: T) => void;
}

type RadioValueType = string | number;

export function RadioFilter<T extends RadioValueType>(props: SingleSelectProps<T>) {
  const style: React.CSSProperties = {
    maxHeight: 100,
    overflow: 'auto',
  };

  const { onChange, name, value, options } = props;

  return (
    <Radio.Group
      value={value}
      onChange={(e) => onChange && onChange(e.target.value as T)}
      name={name}
      style={style}
    >
      <Flex direction="column" gap="--space-4">
        {options.map((option) => (
          <Radio style={{ marginLeft: 0 }} key={option.value} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Flex>
    </Radio.Group>
  );
}
