import { createSlice } from '@reduxjs/toolkit';
import {
  addCmExcludedRowId,
  removeCmExcludedRowId,
  resetCmExcludedRowId,
  updateBulkRecords,
  updateCmSelectAll,
  updateCmSelectedRows,
  updateCollectorsInfo,
  updateModifiedCollectionTargetAmount,
  updateSortingCmCurrentFilter,
  updateTableState,
} from './reducers';
import { CollectorsManagementProps } from './type';

export const initialState: CollectorsManagementProps = {
  collectorsManagement: {
    collectionOwners: [],

    currentFilter: {
      sort_by: 'DESC',
      sort_col: 'TOTAL_CUSTOMERS',
    },
    bulkRecords: 0,
    collectorsInfo: [],
  },
  selectedRowIds: [],
  excludedRowIds: [],
  selectAll: {
    selected: false,
    select_all: false,
  },
  selectedRows: [],
};

const CollectorsManagementSlice = createSlice({
  name: 'collectorsManagement',
  initialState,
  reducers: {
    updateCmSelectAll,
    updateCmSelectedRows,
    addCmExcludedRowId,
    removeCmExcludedRowId,
    resetCmExcludedRowId,
    updateSortingCmCurrentFilter,
    updateCollectorsInfo,
    updateModifiedCollectionTargetAmount,
    updateBulkRecords,
    updateTableState,
  },
});

export const collectorsManagementActions = CollectorsManagementSlice.actions;

export default CollectorsManagementSlice.reducer;
