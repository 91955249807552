import { Col, Row } from 'antd';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import React from 'react';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';
import { StyledChildCard } from '../styles';
import { dashboardTexts } from '../texts';
import TotalCustomersInvoices from '../TotalCustomersInvoices';

const componentTexts = dashboardTexts.overdueTile;

interface OverdueTileProps {
  loading: boolean;
  totalOutstanding?: TotalOutstandingResponse;
  style?: React.CSSProperties;
}
function OverdueTile({ loading, totalOutstanding, style }: OverdueTileProps) {
  const overDue = totalOutstanding?.over_due;
  const invoiceCount = totalOutstanding?.over_due.invoice_count;
  const InputLoaderJSX = <InputLoader active={loading} />;
  const OverDueAmountJSX = overDue && (
    <AmountWithCurrencySplit
      type="multi"
      amountWithCurrencySplit={overDue.amount}
      className="text-24 semi-bold"
      showTooltip
      context={componentTexts.title}
      formatOptions={{ maximumFractionDigits: 0 }}
    />
  );

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices loading={loading} invoicesCount={invoiceCount} showCustomer={false} />
  );

  const LabelJSX = (
    <div className="title">
      <GrowText size={'var(--gray-9)'}>{componentTexts.title}</GrowText>
    </div>
  );

  return (
    <StyledChildCard
      className="current-due-container"
      color="var(--volcano-2)"
      style={{ height: 'fit-content' }}
    >
      <div style={{ marginBottom: 'var(--space-12)' }}>
        <Row className="no-wrap" gutter={[16, 16]} wrap>
          <Col span={24}>{LabelJSX}</Col>
        </Row>
        <Row>
          <Col span={24}>
            <GrowText size={'var(--fs-20)'} className="amount">
              {loading ? InputLoaderJSX : OverDueAmountJSX}
            </GrowText>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>{TotalCustInvoiceInfo}</Col>
      </Row>
    </StyledChildCard>
  );
}

export default React.memo(OverdueTile);
