import { Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import ActivityCardActions from 'components/CollectionActivities/Common/Card/ActivityCardActions';
import ActivityCardHeader from 'components/CollectionActivities/Common/Card/ActivityCardHeader';
import { LatestActivityFooter } from 'components/CollectionActivities/Common/LatestActivity';
import { InboxActivityCardProps } from 'components/CollectionActivities/types';
import { SourceAppComp } from 'components/Common/SourceApp/SourceApp';
import { StatusCell } from 'components/Common/StatusCell/StatusCell';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { getKeyActivityConfig } from 'components/HigherOrderComponent/KeyActivitesContainer/key-acivities-config';
import { CustomerAndInvoice } from 'components/Inbox/CollectionActivitiesV2/Activities/CustomerAndInvoice';
import { ToggleActivityReadButton } from 'components/Inbox/CollectionActivitiesV2/Activities/ToggleActivityReadButton';
import { ELastContactActivity } from 'store/activity-feed/type';
import { ActivityType } from 'types/activities/activity-types';
import { disputeStatusConfig } from 'types/activities/dispute';

const activityConfig = getKeyActivityConfig(ELastContactActivity.DISPUTE);

interface DisputeCardProps extends InboxActivityCardProps<ActivityType.DISPUTE> {}

function DisputeCardHeader(props: DisputeCardProps) {
  const { dispute, viewMode, activitiesMode, activityBg, onStatusUpdate } = props;

  const Source = (
    <HideWrapper hide={dispute.entity_detail.source_app !== 'CUSTOMER_PORTAL'}>
      <Flex align="center" gap="var(--space-4)">
        <GrowText color="var(--gray-8)">via</GrowText>
        <SourceAppComp customer_details={dispute.customer} source_app="CUSTOMER_PORTAL" iconOnly />
      </Flex>
    </HideWrapper>
  );
  const MarkAsRead = (
    <ToggleActivityReadButton
      activity={dispute}
      viewMode={viewMode}
      hideDot={activitiesMode}
      css={activityBg}
    />
  );
  const Status = (
    <StatusCell
      status={{
        mode: 'Tags',
        text: disputeStatusConfig[dispute.entity_detail.status].label,
        style: disputeStatusConfig[dispute.entity_detail.status].style,
      }}
    />
  );
  const Title = (
    <Flex align="center" gap="var(--space-4)">
      <GrowText color="var(--gray-8)" strong={!dispute.read}>
        {activityConfig?.cardLabel}
      </GrowText>
      {Source}
    </Flex>
  );
  const Heading = (
    <Flex gap="var(--space-4)" align="center">
      {MarkAsRead}
      {Title}
    </Flex>
  );
  return (
    <ActivityCardHeader activitiesMode={activitiesMode}>
      {Heading}
      <ActivityCardActions
        activity={dispute}
        onStatusUpdate={onStatusUpdate}
        extraActions={Status}
      />
    </ActivityCardHeader>
  );
}

function DisputeCardBody(props: DisputeCardProps) {
  const { dispute, activitiesMode } = props;
  const Body = (
    <GrowText ellipsis strong={!dispute.read} size="var(--fs-16)">
      {dispute.title}
    </GrowText>
  );

  const Details = (
    <CustomerAndInvoice
      customer={dispute.customer}
      invoices={dispute.invoices}
      activitiesMode={activitiesMode}
    />
  );

  return (
    <Row gutter={[0, 6]}>
      <Col span={24}> {Body}</Col>
      <Col span={24}> {Details}</Col>
    </Row>
  );
}

function DisputeCardFooter(props: DisputeCardProps) {
  const { dispute } = props;

  return <LatestActivityFooter latestActivity={dispute.latest_activity} />;
}

export const DisputeCard = () => <></>;

DisputeCard.Header = DisputeCardHeader;
DisputeCard.Body = DisputeCardBody;
DisputeCard.Footer = DisputeCardFooter;
