import { Divider, Skeleton } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { FilterStrip } from 'components/Common/FilterComponents/style';
import SnapShotSkeleton from './SnapShot';

function DashboardSkeleton() {
  const FilterStrips = (
    <Flex direction="column">
      <FilterStrip style={{ height: '56px' }}>
        <Skeleton.Input active />
      </FilterStrip>
      <Divider style={{ margin: 0 }} />
      <FilterStrip style={{ height: '56px' }}>
        <Skeleton.Input active />
      </FilterStrip>
    </Flex>
  );
  return (
    <Flex className="scrollable-tab-content" direction="column">
      {FilterStrips}
      <Flex.Child style={{ padding: '24px' }}>
        <SnapShotSkeleton />
      </Flex.Child>
    </Flex>
  );
}

export default DashboardSkeleton;
