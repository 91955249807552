export enum FileType {
  PDF = 'PDF',
  PNG = 'PNG',
  JPG = 'JPG',
  DOC = 'DOC',
  XLS = 'XLS',
  CSV = 'CSV',
  XLSX = 'XLSX',
  EML = 'EML',
}

export const PreviewFile = [FileType.PDF, FileType.JPG, FileType.PNG];

export const MimeType: Record<string, FileType> = {
  'text/csv': FileType.CSV,
  'application/msword': FileType.DOC,
  'image/jpeg': FileType.JPG,
  'image/png': FileType.PNG,
  'application/vnd.ms-excel': FileType.XLS,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': FileType.XLSX,
  'application/pdf': FileType.PDF,
  'message/rfc822': FileType.EML,
};

export enum FileExtension {
  DOC = '.doc',
  DOCX = '.docx',
  PDF = '.pdf',
  JPG = '.jpg',
  JPEG = '.jpeg',
  PNG = '.png',
  XLS = '.xls',
  XLSX = '.xlsx',
  EML = '.eml',
}

export type ValidFileType =
  | FileType.PDF
  | FileType.PNG
  | FileType.JPG
  | FileType.DOC
  | FileType.XLS
  | FileType.CSV
  | FileType.XLSX
  | FileType.EML;

export type AcceptedFileType =
  | FileExtension.DOC
  | FileExtension.DOCX
  | FileExtension.PDF
  | FileExtension.JPG
  | FileExtension.JPEG
  | FileExtension.PNG
  | FileExtension.XLS
  | FileExtension.XLSX
  | FileExtension.EML;

export interface Attachment {
  id: number;
  file_name: string;
  file_type: string;
  file_size: number;
}

export interface UploadedFiles extends File {
  uid?: string;
}
