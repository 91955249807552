const buttonProps = {
  small: {
    height: '24px',
    fontSize: '14px',
    padding: '0 7px',
  },
  default: {
    height: '32px',
    fontSize: '14px',
    padding: '4px 15px',
  },
  large: {
    height: '40px',
    fontSize: '16px',
    padding: '8px 24px',
  },
};

export type ButtonSize = keyof typeof buttonProps;

export function buttonCSSProps(buttonSizeValue: ButtonSize, buttonColorValue?: string) {
  return {
    fontSize: buttonProps[buttonSizeValue]?.fontSize ?? buttonProps['default'].fontSize,
    padding: buttonProps[buttonSizeValue]?.padding,
    borderRadius: '4px',
    outline: 'none',
    border: 'none',
    color: '#FFFFFF',
    background: buttonColorValue ?? '#C41D7F',
    margin: '0',
    textDecoration: 'none',
    lineHeight: '1.5',
    display: 'inline-block',
  };
}
