import { useMutation, useQuery } from '@tanstack/react-query';
import { SearchParamsOption } from 'ky';
import { postAccountDetails } from 'services/accounts';
import { getActivityFeedUsers } from 'services/activity-feed';
import { searchInvoiceContacts } from 'services/invoice';
import {
  allUsers,
  getActiveAccountManagers,
  getActiveCollectionOwners,
  getActiveCustomerSuccessManager,
  getCollectionManagers,
  getCustomerContacts,
  getSalesManagers,
  getSyncGms,
  searchUsers,
} from 'services/users';

const HALF_DAY = 12 * 60 * 60 * 1000;

export function useCollectionOwnersQuery(searchParams: SearchParamsOption) {
  return useQuery({
    queryKey: ['collection-owners', searchParams ? searchParams.toString() : 'empty'],
    queryFn: () => getActiveCollectionOwners(searchParams),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
    retry: false,
  });
}

export function useAccountManagersQuery(searchParams: SearchParamsOption) {
  return useQuery({
    queryKey: ['account-managers', searchParams ? searchParams.toString() : 'empty'],
    queryFn: () => getActiveAccountManagers(searchParams),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
    retry: false,
  });
}

export function useCSManagersQuery(searchParams: SearchParamsOption) {
  return useQuery({
    queryKey: ['account-executives', searchParams ? searchParams.toString() : 'empty'],
    queryFn: () => getActiveCustomerSuccessManager(searchParams),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
    retry: false,
  });
}

export function useCollectionManagersQuery(searchParams: SearchParamsOption) {
  return useQuery({
    queryKey: ['collection-managers', searchParams ? searchParams.toString() : 'empty'],
    queryFn: () => getCollectionManagers(searchParams),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
    retry: false,
  });
}

export function useSalesManagersQuery(searchParams: SearchParamsOption) {
  return useQuery({
    queryKey: ['sales-managers', searchParams ? searchParams.toString() : 'empty'],
    queryFn: () => getSalesManagers(searchParams),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
    retry: false,
  });
}

export function useBillingContactsQuery(searchTerm?: string) {
  return useQuery({
    queryKey: ['billing-contacts', searchTerm ? searchTerm.toString() : 'empty'],
    queryFn: () => searchInvoiceContacts(searchTerm ? searchTerm : ''),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
    retry: false,
  });
}

export function useCustomerContactsQuery(
  customerContactQueryKey: string,
  categoryId?: number,
  searchParams?: SearchParamsOption
) {
  return useQuery({
    queryKey: [customerContactQueryKey, searchParams ? searchParams.toString() : 'empty'],
    queryFn: () => getCustomerContacts(searchParams ? searchParams : '', categoryId),
    retry: false,
    cacheTime: 0,
    staleTime: 0,
    enabled: !!categoryId,
  });
}
export function useActivityFeedUserGetQuery() {
  return useQuery({
    queryKey: ['activity-feed-users'],
    retry: false,
    queryFn: () => getActivityFeedUsers(),
  });
}

export function useGetAllUserQuery() {
  return useQuery({
    queryKey: ['all-users'],
    retry: false,
    queryFn: () => allUsers(),
  });
}

export function useSearchUsersMutation() {
  return useMutation({
    mutationFn: (searchWord: string, includeRelativePath?: boolean) =>
      searchUsers(searchWord, includeRelativePath),
  });
}

export function useUpdateAccountDetails() {
  return useMutation({
    mutationFn: postAccountDetails,
  });
}

export function useUpdateUserGmsAuth() {
  return useMutation({
    mutationFn: () => getSyncGms({ isArEmail: false }),
  });
}
