import { RelativeDateRangeLabels } from 'constants/date-time';
import { getCustomFormattedDate } from 'util/datetime-formatter';
import { getFormattedDateForRelativeOptions, RelativeDateRange } from 'util/relative-date-range';
import { DateRange, DateRangeDropdownOption, DateRangeString } from './types';

const defaultRanges = [
  [RelativeDateRange.THIS_WEEK, 'This Week'],
  [RelativeDateRange.LAST_7_DAYS, 'Last 7 Days'],
  [RelativeDateRange.LAST_30_DAYS, 'Last 30 Days'],
  [RelativeDateRange.MORE_THAN_30_DAYS, 'More than 30 Days'],
] as [RelativeDateRange, string][];

export function allDateRangeOptions(): DateRangeDropdownOption[] {
  const allRanges = Object.keys(RelativeDateRangeLabels) as RelativeDateRange[];

  return allRanges.map((key, label) => ({
    id: key,
    label: RelativeDateRangeLabels[key],
  }));
}

export function getDefaultOptions(): DateRangeDropdownOption[] {
  const defaultOptions: DateRangeDropdownOption[] = [];

  defaultRanges.forEach(([defaultRangeOption, defaultRangeLabel]) => {
    defaultOptions.push({
      id: defaultRangeOption,
      label: defaultRangeLabel,
    });
  });

  return defaultOptions;
}

function getFromToDateStringFromSelectedRange(
  dateRange: RelativeDateRange,
  dateRangeOptions: DateRangeDropdownOption[],
  dateFormat: string
): [string, string] {
  const selectedOption = dateRangeOptions.find((option) => option.id === dateRange);
  if (selectedOption) {
    return getFormattedDateForRelativeOptions(selectedOption.id, { format: dateFormat });
  } else {
    return ['', ''];
  }
}

export function getDateRange(
  dateRange: Partial<DateRange> = {},
  dateRangeOptions: DateRangeDropdownOption[],
  dateFormat: string,
  defaultRangeOption: RelativeDateRange = RelativeDateRange.THIS_WEEK
): DateRangeString {
  if (dateRange.type) {
    const [from, to] = getFromToDateStringFromSelectedRange(
      dateRange.type,
      dateRangeOptions,
      dateFormat
    );

    return { from, to, type: dateRange.type };
  } else if (dateRange.from && dateRange.to) {
    const { from, to } = dateRange;
    const fromDateString = getCustomFormattedDate(from, dateFormat);
    const toDateString = getCustomFormattedDate(to, dateFormat);

    return { from: fromDateString, to: toDateString, type: 'CUSTOM_RANGE' };
  } else {
    const [from, to] = getFromToDateStringFromSelectedRange(
      defaultRangeOption,
      dateRangeOptions,
      dateFormat
    );

    return { from, to, type: defaultRangeOption };
  }
}
