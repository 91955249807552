import { InputNumber } from 'antd';
import { useState } from 'react';
interface TextEditorCellProps {
  currencySymbol?: string;
  onChange?: (value?: number) => void;
  value?: number;
  precision: number;
}
const t = {
  placeHolder: 'Enter number',
};
export default function NumericEditorCell(props: TextEditorCellProps) {
  const [inputValue, setInputValue] = useState(props.value);

  const NumberInput = (
    <InputNumber
      prefix={props.currencySymbol}
      onPressEnter={(e) => {
        if (e.key === 'Enter') props?.onChange?.(inputValue);
      }}
      onChange={(value) => {
        const reg = /^-?\d*(\.\d*)?$/;
        if (!value) {
          setInputValue(value as number);
        }
        if (value) {
          const vlaidationString = value as unknown as string;
          if (reg.test(vlaidationString) || vlaidationString === '' || vlaidationString === '-') {
            setInputValue(value as number);
          }
        }
      }}
      autoFocus
      type="number"
      placeholder={t.placeHolder}
      controls={true}
      style={{ width: '100%', borderStyle: 'none', boxShadow: 'none' }}
      defaultValue={(props.value as unknown) === '-' ? undefined : props.value}
      precision={props.precision}
      status={inputValue ? '' : 'error'}
    />
  );
  return <>{NumberInput}</>;
}
