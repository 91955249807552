import { isObject, mapValues } from 'lodash';

/**
 * Retrieves values from a nested object structure based on specified keys.
 *
 * @template T - The type of the keys parameter.
 * @template U - The type of the returned object.
 * @template K - The type of the input data object.
 *
 * @param {K} data - The input data object from which values are to be extracted.
 * @param {T} keys - An object specifying the keys for which values need to be extracted. The keys can represent a path in the nested structure.
 * @returns {U | undefined} - An object containing the extracted values corresponding to the specified keys, or undefined if the input data is falsy.
 *
 * @example
 * // Example input data object
 * const data = {
 *   user: {
 *     name: 'John Doe',
 *     address: {
 *       city: 'New York',
 *       zip: '10001'
 *     }
 *   },
 *   orders: [
 *     { id: 1, product: 'Laptop' },
 *     { id: 2, product: 'Phone' }
 *   ]
 * };
 *
 * // Example keys object
 * const keys = {
 *   userName: 'user.name',
 *   userCity: 'user.address.city',
 *   orderProducts: 'orders.product'
 * };
 *
 * // Example usage
 * const result = getObjectValues(data, keys);
 * // result: { userName: 'John Doe', userCity: 'New York', orderProducts: ['Laptop', 'Phone'] }
 */
export function getObjectValues<T, U, K extends Record<string, any>>(
  data: K,
  keys: T
): U | undefined {
  if (!data) return;

  /**
   * Searches for a value in the nested object structure based on a dot-separated key.
   *
   * @param {K} node - The current node in the nested object structure.
   * @param {string} key - The dot-separated key representing the path to the value.
   * @returns {unknown[]} - An array of found values.
   */
  function extractValuesByPath(node: K, key: string) {
    const keys = key.split('.');

    /**
     * Recursively searches for values in the nested object structure.
     *
     * @param {K} node - The current node in the nested object structure.
     * @param {number} depth - The current depth of recursion.
     * @param {unknown[]} acc - The accumulator array that collects found values.
     * @returns {unknown[]} - The accumulator array with found values.
     */
    function traversePath(node: K, depth: number, acc: unknown[]): unknown[] {
      if (depth >= keys.length) {
        if (Array.isArray(node)) {
          node.forEach((val) => acc.push(val));
        } else {
          acc.push(node);
        }
        return acc;
      }

      const k = keys[depth];
      if (Array.isArray(node)) {
        node.forEach((val) => traversePath(val, depth, acc));
      } else if (isObject(node)) {
        return traversePath(node[k], depth + 1, acc);
      }
      return acc;
    }

    return traversePath(node, 0, []);
  }

  return mapValues(keys as unknown as Record<string, string>, (path) =>
    extractValuesByPath(data, path)
  ) as unknown as U;
}
