import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { DownloadButtonWithText } from 'components/BaseComponents/Buttons';
import React from 'react';

interface DownloadReportsButtonProps {
  componentToDisplay: React.ReactNode;
  style?: React.CSSProperties;
  onVisibleChange?: (visible: Boolean) => void;
  popoverVisible?: boolean;
  onClick?: () => void;
  placement?: TooltipPlacement;
  overlayStyle?: React.CSSProperties;
  text?: string;
  loading?: boolean;
}

export function DownloadReportsButton(props: DownloadReportsButtonProps) {
  return (
    <Popover
      content={props.componentToDisplay}
      trigger={['click']}
      overlayStyle={{ maxWidth: 600, ...props.overlayStyle }}
      onOpenChange={props.onVisibleChange}
      open={props.popoverVisible}
      placement="bottomLeft"
    >
      <DownloadButtonWithText
        style={props.style}
        onClick={props.onClick}
        text={props.text}
        loading={props.loading}
      />
    </Popover>
  );
}
