import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'components/BaseComponents/Buttons/Button';
import LinkBubbleMenu from 'components/BaseComponents/RTE/ContextMenu/LinkActionBubbleMenu';
import { BubbleMenu, getActiveLinkAttribute, getSelectedText } from 'lib/RichText';
import { useRef, useState } from 'react';
import { commonButtonProps } from '../../Toolbar';
import { ToolbarOptionProps } from '../../type';
import InsertLink, { LinkSelection } from './InsertLink';

const t = {
  tooltipText: 'Add hyperlink',
  choose_heading: 'Choose Heading',
  label: 'Link',
};

const Icon = <FontAwesomeIcon icon={['far', 'link']} />;

const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
  title: t.label,
};

interface LinkProps extends ToolbarOptionProps {}

export function Link({ editor }: LinkProps) {
  const [open, setOpen] = useState<boolean>(false);
  const selectedTextRef = useRef<LinkSelection>();
  // selectedTextRef.current = getSelectedText(editor);

  /* 
    Hacky way suggessted in one of the forums

    link - https://github.com/thefinnomenon/creatorkitchen/blob/main/components/TextFloatingToolbar/TextFloatingToolbar.tsx
  */
  const openRef = useRef<boolean>(false);

  function onCancelCallback() {
    onClickCallback();
  }

  function onClickCallback() {
    setOpen((prev) => {
      openRef.current = !prev;
      const { from, to } = editor.state.selection;

      selectedTextRef.current = {
        from,
        to,
        text: getSelectedText(editor),
        href: getActiveLinkAttribute(editor),
      };

      if (openRef.current) {
        selectedTextRef.current ? editor.chain().focus(from).run() : editor.chain().focus().run();
      } else {
        editor.chain().focus().blur();
      }

      return !prev;
    });
  }

  const InsertLinkJSX = (
    <InsertLink
      editor={editor}
      onCancel={onCancelCallback}
      open={open}
      selection={selectedTextRef.current}
    />
  );

  const LinkBubbleMenuJSX = (
    <LinkBubbleMenu
      editor={editor}
      open={open}
      onOpenVisible={(open: boolean) => onClickCallback()}
    />
  );

  const menu = (
    <BubbleMenu
      editor={editor}
      tippyOptions={{ duration: 200 }}
      shouldShow={(props) => {
        return Boolean(openRef.current);
      }}
    >
      {InsertLinkJSX}
    </BubbleMenu>
  );

  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    icon: Icon,
    onClick: (event) => onClickCallback(),
  };

  const LinkButton = <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />;

  return (
    <div>
      {menu}
      {LinkButton}
      {LinkBubbleMenuJSX}
    </div>
  );
}
