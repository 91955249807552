import { Card, Tag, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const KeyboardText = styled(Text)`
  kbd {
    color: var(--gray-5);
  }
`;

export const SecondaryText = styled(Text)`
  color: var(--gray-7);
`;

export const PlaceholderText = styled(Text)`
  color: var(--orange-5);
`;

export const AutocompleteMenu = styled.div`
  background-color: var(--gray-3);
  max-height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .meta-information {
    height: 50px;
  }

  .search-cards-list {
    height: calc(100% - 50px);
    overflow: auto;
  }
`;

export const StyledTag = styled(Tag)`
  &.active {
    background-color: var(--blue-6);
    color: var(--gray-1);
  }
  background-color: var(--gray-1);
`;

export const AutocompleteCard = styled(Card)`
  .ant-card-body {
    padding: 10px;
  }
`;
