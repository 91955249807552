import { createSlice } from '@reduxjs/toolkit';
import { invoiceInitialState } from './initial-state';
import {
  removeInvoiceSegmentScope,
  saveInvoiceSegmentScope,
  setDunningRule,
  setRuleErrors,
  setShowErrors,
  toggleDrawer,
  updateActionData,
  updateActionType,
  updateEventData,
  updateTitle,
} from './reducers';

const dunningRuleSlice = createSlice({
  name: 'followup-strategy-dunning-rule',
  initialState: invoiceInitialState,
  reducers: {
    toggleDrawer,
    setDunningRule,
    updateTitle,
    updateEventData,
    updateActionType,
    updateActionData,
    saveInvoiceSegmentScope,
    removeInvoiceSegmentScope,
    setRuleErrors,
    setShowErrors,
  },
});

export const dunningRuleActions = dunningRuleSlice.actions;
export default dunningRuleSlice.reducer;
