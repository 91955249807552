import { MenuOptions } from 'components/BaseComponents/Menu/Antd/type';
import { PageNavLink, matchPath, useLocation } from 'lib/router';
import { useMemo } from 'react';
import { AppPageName } from 'router/constants/page-info';
import styled from 'styled-components';
import { cleanArrayPredicate } from 'util/predicates';
import { AppSubRoute } from '../sidebar.components';
import useAutomations from './useAutomations';
import useCashAppsRoute from './useCashAppsRoute';
import useCollectionsRoute from './useCollections';
import useCustomersRoute from './useCustomers';
import useDashBoardRoute from './useDashBoardRoute';
import useInboxRoute from './useInboxRoute';
import useReportsRoute from './useReports';
import useSettingsRoute from './useSettingsRoute';
import { useTransactionsRoute } from './useTransactions';

const StyledNavLink = styled(PageNavLink)``;
export type PageListItems = {
  key: AppPageName;
  subLinks: AppSubRoute[] | 'primitive';
  access: boolean;
  label: string;
  icon: React.ReactNode;
  url?: string;
};

const filterListPredicate = (List: PageListItems) => Boolean(List.access);
const mapLinkPredicate = (link: AppSubRoute) => {
  return {
    key: `${link.url}`,
    label: (
      <StyledNavLink
        to={link.url}
        state={{ fromSidebar: true }}
        style={{ color: 'inherit', maxWidth: '130px' }}
      >
        {link.label}
      </StyledNavLink>
    ),
  };
};
function usePageList() {
  const location = useLocation();
  const { dashboardPageList } = useDashBoardRoute();
  const { inboxPageList } = useInboxRoute();
  const { customerPageList } = useCustomersRoute();
  const { transactionPageList } = useTransactionsRoute();
  const { collectionsPageList } = useCollectionsRoute();
  const { reportPageList } = useReportsRoute();
  const { cashAppPageList } = useCashAppsRoute();
  const { settingsPageList } = useSettingsRoute();
  const { automationPage } = useAutomations();
  const pageList: Array<PageListItems> = [
    ...dashboardPageList,
    ...inboxPageList,
    ...customerPageList,
    ...transactionPageList,
    ...collectionsPageList,
    ...reportPageList,
    ...cashAppPageList,
    ...automationPage,
    ...settingsPageList,
  ];

  const validPageList = pageList.filter(filterListPredicate);
  function getSideBarRoutes(): MenuOptions {
    return validPageList.map((list) => {
      return {
        key: list.key,
        label: (
          <StyledNavLink
            to={list.url ? list.url : '#'}
            state={{ fromSidebar: true }}
            style={{ color: 'inherit', maxWidth: '130px' }}
          >
            {list.label}
          </StyledNavLink>
        ),
        icon: list.icon,
        children: list.subLinks === 'primitive' ? undefined : list.subLinks.map(mapLinkPredicate),
        className: list.key,
      };
    });
  }

  const sideBarRoutes = getSideBarRoutes();
  function getActiveKey() {
    return validPageList
      .map((list) => {
        if (list.subLinks === 'primitive') {
          if (list.key === 'INBOX') {
            return location.pathname.split('/').includes('inbox')
              ? { active: list.key, defaultOpen: list.key }
              : undefined;
          }

          const path = location.pathname.split('/');
          const url = list.url?.split('/');

          return path[1] === url?.[1] ? { active: list.key, defaultOpen: list.key } : undefined;
        }

        const selected = list.subLinks.find((f) => matchPath(f.url, location.pathname));

        return selected ? { active: `${selected?.url}`, defaultOpen: list.key } : undefined;
      })
      .filter(cleanArrayPredicate);
  }

  const activeKey = useMemo(getActiveKey, [location.pathname, validPageList]);

  return { validPageList, activeKey, sideBarRoutes };
}

export { usePageList };
