import styled from 'styled-components';

export const EmailPreviewContent = styled.div`
  overflow: auto;
  overflow-x: hidden;

  .ant-alert-info {
    border: none;
    background-color: var(--gray-1);
  }
`;
