import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import useOpen from 'util/hooks/useOpen';
import { TaskFormModalProps, TaskModalFormElementProps } from '../types';
import TaskFormModal from './TaskFormModal';

export default function TaskButton<TaskElements extends TaskModalFormElementProps>(
  props: Omit<TaskFormModalProps<TaskElements>, 'openModal' | 'form' | 'onClose' | 'onFormClose'>
) {
  const { buttonConfig, disabled } = props;
  const { open, toggleOpen, onClose } = useOpen({ open: false });
  const iconColor = disabled
    ? undefined
    : buttonConfig?.color
    ? buttonConfig?.color
    : 'var(--primary-7)';
  return (
    <>
      <Button
        icon={
          !buttonConfig?.hideIcon ? (
            <FontAwesomeIcon icon={['far', 'check']} color={iconColor} />
          ) : null
        }
        onClick={toggleOpen}
        disabled={disabled}
        {...buttonConfig}
      >
        {!buttonConfig?.hidetext && (buttonConfig?.text || 'Create Task')}
      </Button>

      <TaskFormModal<TaskElements> openModal={open} onClose={onClose} {...props} />
    </>
  );
}
