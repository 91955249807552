import LogRocket from 'logrocket';
import { APP_ENV_VALUES, IS_LOCAL, IS_PS0, IS_STAGING, getEnv } from 'util/http-utils';

type Identify = typeof LogRocket.identify;

const projects: Record<APP_ENV_VALUES, string> = {
  staging: 'growfin-staging',
  production: 'growfin-prod',
  ps0: '',
  development: '',
};
const redacted = ['POST', 'PUT', 'PATCH'];

const BLACKLISTED_URLS = ['demo.growfin.ai', 'cash-king.growfin.ai'];
const IS_DISABLED =
  IS_LOCAL || IS_STAGING || IS_PS0 || BLACKLISTED_URLS.includes(window.location.hostname);

export const logRocket = {
  init() {
    if (IS_DISABLED) return;

    const env = getEnv();
    const logRocketSlug = import.meta.env.VITE_LOGROCKET_SLUG;
    const logRocketProject = env && projects[env];

    if (logRocketProject && logRocketSlug) {
      LogRocket.init(`${logRocketSlug}/${logRocketProject}`, {
        mergeIframes: true,
        network: {
          requestSanitizer: (request) => {
            if (redacted.includes(request.method)) {
              request.body = 'REDACTED';
            }
            return request;
          },
        },
      });
    }
  },

  logSessionURL() {
    if (IS_DISABLED) return;

    LogRocket.getSessionURL((sessionURL) => {
      console.log('LogRocket Session URL', sessionURL);
    });
  },

  getSessionURL(): Promise<string> {
    if (IS_DISABLED) return Promise.resolve('');

    return new Promise((resolve, reject) => {
      LogRocket.getSessionURL((sessionURL) => resolve(sessionURL));
    });
  },

  track(trackingItem: string) {
    if (IS_DISABLED) return;

    LogRocket.track(trackingItem);
  },

  identify(uid: string, traits: Parameters<Identify>[0]) {
    if (IS_DISABLED) return;

    LogRocket.identify(uid, traits);
  },

  startNewSession() {
    if (IS_DISABLED) return;

    LogRocket.startNewSession();
  },
};
