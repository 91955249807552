export type currencyType = {
  name?: string;
  symbol: string;
  code: string;
};
export const currencyList: currencyType[] = [
  {
    name: 'US Dollar',
    symbol: '$',
    code: 'USD',
  },
  {
    name: 'Canadian Dollar',
    symbol: '$',
    code: 'CAD',
  },
  {
    name: 'Euro',
    symbol: '€',
    code: 'EUR',
  },
  {
    name: 'United Arab Emirates Dirham',
    symbol: 'د.إ.‏',
    code: 'AED',
  },
  {
    name: 'Afghan Afghani',
    symbol: '؋',
    code: 'AFN',
  },
  {
    name: 'Albanian Lek',
    symbol: 'Lek',
    code: 'ALL',
  },
  {
    name: 'Armenian Dram',
    symbol: 'դր.',
    code: 'AMD',
  },
  {
    name: 'Argentine Peso',
    symbol: '$',
    code: 'ARS',
  },
  {
    name: 'Australian Dollar',
    symbol: '$',
    code: 'AUD',
  },
  {
    name: 'Azerbaijani Manat',
    symbol: 'ман.',
    code: 'AZN',
  },
  {
    name: 'Bosnia-Herzegovina Convertible Mark',
    symbol: 'KM',
    code: 'BAM',
  },
  {
    name: 'Bangladeshi Taka',
    symbol: '৳',
    code: 'BDT',
  },
  {
    name: 'Bulgarian Lev',
    symbol: 'лв.',
    code: 'BGN',
  },
  {
    name: 'Bahraini Dinar',
    symbol: 'د.ب.‏',
    code: 'BHD',
  },
  {
    name: 'Burundian Franc',
    symbol: 'FBu',
    code: 'BIF',
  },
  {
    name: 'Brunei Dollar',
    symbol: '$',
    code: 'BND',
  },
  {
    name: 'Bolivian Boliviano',
    symbol: 'Bs',
    code: 'BOB',
  },
  {
    name: 'Brazilian Real',
    symbol: 'R$',
    code: 'BRL',
  },
  {
    name: 'Botswanan Pula',
    symbol: 'P',
    code: 'BWP',
  },
  {
    name: 'Belarusian Ruble',
    symbol: 'BYR',
    code: 'BYR',
  },
  {
    name: 'Belize Dollar',
    symbol: '$',
    code: 'BZD',
  },
  {
    name: 'Congolese Franc',
    symbol: 'FrCD',
    code: 'CDF',
  },
  {
    name: 'Swiss Franc',
    symbol: 'CHF',
    code: 'CHF',
  },
  {
    name: 'Chilean Peso',
    symbol: '$',
    code: 'CLP',
  },
  {
    name: 'Chinese Yuan',
    symbol: 'CN¥',
    code: 'CNY',
  },
  {
    name: 'Colombian Peso',
    symbol: '$',
    code: 'COP',
  },
  {
    name: 'Costa Rican Colón',
    symbol: '₡',
    code: 'CRC',
  },
  {
    name: 'Cape Verdean Escudo',
    symbol: 'CV$',
    code: 'CVE',
  },
  {
    name: 'Czech Republic Koruna',
    symbol: 'Kč',
    code: 'CZK',
  },
  {
    name: 'Djiboutian Franc',
    symbol: 'Fdj',
    code: 'DJF',
  },
  {
    name: 'Danish Krone',
    symbol: 'kr',
    code: 'DKK',
  },
  {
    name: 'Dominican Peso',
    symbol: 'RD$',
    code: 'DOP',
  },
  {
    name: 'Algerian Dinar',
    symbol: 'د.ج.‏',
    code: 'DZD',
  },
  {
    name: 'Estonian Kroon',
    symbol: 'kr',
    code: 'EEK',
  },
  {
    name: 'Egyptian Pound',
    symbol: 'ج.م.‏',
    code: 'EGP',
  },
  {
    name: 'Eritrean Nakfa',
    symbol: 'Nfk',
    code: 'ERN',
  },
  {
    name: 'Ethiopian Birr',
    symbol: 'Br',
    code: 'ETB',
  },
  {
    name: 'British Pound Sterling',
    symbol: '£',
    code: 'GBP',
  },
  {
    name: 'Georgian Lari',
    symbol: 'GEL',
    code: 'GEL',
  },
  {
    name: 'Ghanaian Cedi',
    symbol: 'GH₵',
    code: 'GHS',
  },
  {
    name: 'Guinean Franc',
    symbol: 'FG',
    code: 'GNF',
  },
  {
    name: 'Guatemalan Quetzal',
    symbol: 'Q',
    code: 'GTQ',
  },
  {
    name: 'Hong Kong Dollar',
    symbol: '$',
    code: 'HKD',
  },
  {
    name: 'Honduran Lempira',
    symbol: 'L',
    code: 'HNL',
  },
  {
    name: 'Croatian Kuna',
    symbol: 'kn',
    code: 'HRK',
  },
  {
    name: 'Hungarian Forint',
    symbol: 'Ft',
    code: 'HUF',
  },
  {
    name: 'Indonesian Rupiah',
    symbol: 'Rp',
    code: 'IDR',
  },
  {
    name: 'Israeli New Sheqel',
    symbol: '₪',
    code: 'ILS',
  },
  {
    name: 'Indian Rupee',
    symbol: 'টকা',
    code: 'INR',
  },
  {
    name: 'Iraqi Dinar',
    symbol: 'د.ع.‏',
    code: 'IQD',
  },
  {
    name: 'Iranian Rial',
    symbol: '﷼',
    code: 'IRR',
  },
  {
    name: 'Icelandic Króna',
    symbol: 'kr',
    code: 'ISK',
  },
  {
    name: 'Jamaican Dollar',
    symbol: '$',
    code: 'JMD',
  },
  {
    name: 'Jordanian Dinar',
    symbol: 'د.أ.‏',
    code: 'JOD',
  },
  {
    name: 'Japanese Yen',
    symbol: '￥',
    code: 'JPY',
  },
  {
    name: 'Kenyan Shilling',
    symbol: 'Ksh',
    code: 'KES',
  },
  {
    name: 'Cambodian Riel',
    symbol: '៛',
    code: 'KHR',
  },
  {
    name: 'Comorian Franc',
    symbol: 'FC',
    code: 'KMF',
  },
  {
    name: 'South Korean Won',
    symbol: '₩',
    code: 'KRW',
  },
  {
    name: 'Kuwaiti Dinar',
    symbol: 'د.ك.‏',
    code: 'KWD',
  },
  {
    name: 'Kazakhstani Tenge',
    symbol: 'тңг.',
    code: 'KZT',
  },
  {
    name: 'Lebanese Pound',
    symbol: 'ل.ل.‏',
    code: 'LBP',
  },
  {
    name: 'Sri Lankan Rupee',
    symbol: 'SL Re',
    code: 'LKR',
  },
  {
    name: 'Lithuanian Litas',
    symbol: 'Lt',
    code: 'LTL',
  },
  {
    name: 'Latvian Lats',
    symbol: 'Ls',
    code: 'LVL',
  },
  {
    name: 'Libyan Dinar',
    symbol: 'د.ل.‏',
    code: 'LYD',
  },
  {
    name: 'Moroccan Dirham',
    symbol: 'د.م.‏',
    code: 'MAD',
  },
  {
    name: 'Moldovan Leu',
    symbol: 'MDL',
    code: 'MDL',
  },
  {
    name: 'Malagasy Ariary',
    symbol: 'MGA',
    code: 'MGA',
  },
  {
    name: 'Macedonian Denar',
    symbol: 'MKD',
    code: 'MKD',
  },
  {
    name: 'Myanma Kyat',
    symbol: 'K',
    code: 'MMK',
  },
  {
    name: 'Macanese Pataca',
    symbol: 'MOP$',
    code: 'MOP',
  },
  {
    name: 'Mauritian Rupee',
    symbol: 'MURs',
    code: 'MUR',
  },
  {
    name: 'Mexican Peso',
    symbol: '$',
    code: 'MXN',
  },
  {
    name: 'Malaysian Ringgit',
    symbol: 'RM',
    code: 'MYR',
  },
  {
    name: 'Mozambican Metical',
    symbol: 'MTn',
    code: 'MZN',
  },
  {
    name: 'Namibian Dollar',
    symbol: 'N$',
    code: 'NAD',
  },
  {
    name: 'Nigerian Naira',
    symbol: '₦',
    code: 'NGN',
  },
  {
    name: 'Nicaraguan Córdoba',
    symbol: 'C$',
    code: 'NIO',
  },
  {
    name: 'Norwegian Krone',
    symbol: 'kr',
    code: 'NOK',
  },
  {
    name: 'Nepalese Rupee',
    symbol: 'नेरू',
    code: 'NPR',
  },
  {
    name: 'New Zealand Dollar',
    symbol: '$',
    code: 'NZD',
  },
  {
    name: 'Omani Rial',
    symbol: 'ر.ع.‏',
    code: 'OMR',
  },
  {
    name: 'Panamanian Balboa',
    symbol: 'B/.',
    code: 'PAB',
  },
  {
    name: 'Peruvian Nuevo Sol',
    symbol: 'S/.',
    code: 'PEN',
  },
  {
    name: 'Philippine Peso',
    symbol: '₱',
    code: 'PHP',
  },
  {
    name: 'Pakistani Rupee',
    symbol: '₨',
    code: 'PKR',
  },
  {
    name: 'Polish Zloty',
    symbol: 'zł',
    code: 'PLN',
  },
  {
    name: 'Paraguayan Guarani',
    symbol: '₲',
    code: 'PYG',
  },
  {
    name: 'Qatari Rial',
    symbol: 'ر.ق.‏',
    code: 'QAR',
  },
  {
    name: 'Romanian Leu',
    symbol: 'RON',
    code: 'RON',
  },
  {
    name: 'Serbian Dinar',
    symbol: 'дин.',
    code: 'RSD',
  },
  {
    name: 'Russian Ruble',
    symbol: 'руб.',
    code: 'RUB',
  },
  {
    name: 'Rwandan Franc',
    symbol: 'FR',
    code: 'RWF',
  },
  {
    name: 'Saudi Riyal',
    symbol: 'ر.س.‏',
    code: 'SAR',
  },
  {
    name: 'Sudanese Pound',
    symbol: 'SDG',
    code: 'SDG',
  },
  {
    name: 'Swedish Krona',
    symbol: 'kr',
    code: 'SEK',
  },
  {
    name: 'Singapore Dollar',
    symbol: '$',
    code: 'SGD',
  },
  {
    name: 'Somali Shilling',
    symbol: 'Ssh',
    code: 'SOS',
  },
  {
    name: 'Syrian Pound',
    symbol: 'ل.س.‏',
    code: 'SYP',
  },
  {
    name: 'Thai Baht',
    symbol: '฿',
    code: 'THB',
  },
  {
    name: 'Tunisian Dinar',
    symbol: 'د.ت.‏',
    code: 'TND',
  },
  {
    name: 'Tongan Paʻanga',
    symbol: 'T$',
    code: 'TOP',
  },
  {
    name: 'Turkish Lira',
    symbol: 'TL',
    code: 'TRY',
  },
  {
    name: 'Trinidad and Tobago Dollar',
    symbol: '$',
    code: 'TTD',
  },
  {
    name: 'New Taiwan Dollar',
    symbol: 'NT$',
    code: 'TWD',
  },
  {
    name: 'Tanzanian Shilling',
    symbol: 'TSh',
    code: 'TZS',
  },
  {
    name: 'Ukrainian Hryvnia',
    symbol: '₴',
    code: 'UAH',
  },
  {
    name: 'Ugandan Shilling',
    symbol: 'USh',
    code: 'UGX',
  },
  {
    name: 'Uruguayan Peso',
    symbol: '$',
    code: 'UYU',
  },
  {
    name: 'Uzbekistan Som',
    symbol: 'UZS',
    code: 'UZS',
  },
  {
    name: 'Venezuelan Bolívar',
    symbol: 'Bs.F.',
    code: 'VEF',
  },
  {
    name: 'Vietnamese Dong',
    symbol: '₫',
    code: 'VND',
  },
  {
    name: 'CFA Franc BEAC',
    symbol: 'FCFA',
    code: 'XAF',
  },
  {
    name: 'CFA Franc BCEAO',
    symbol: 'CFA',
    code: 'XOF',
  },
  {
    name: 'Yemeni Rial',
    symbol: 'ر.ي.‏',
    code: 'YER',
  },
  {
    name: 'South African Rand',
    symbol: 'R',
    code: 'ZAR',
  },
  {
    name: 'Zambian Kwacha (1968–2012)',
    symbol: 'ZMK',
    code: 'ZMK',
  },
  {
    name: 'Fijian Dollar',
    symbol: 'FJD',
    code: 'FJD',
  },
  {
    name: 'São Tomé & Príncipe Dobra',
    symbol: 'STD',
    code: 'STD',
  },
  {
    name: 'Seychellois Rupee',
    symbol: 'SCR',
    code: 'SCR',
  },
  {
    name: 'Barbadian Dollar',
    symbol: 'BBD',
    code: 'BBD',
  },
  {
    name: 'Mexican Investment Unit',
    symbol: 'MXV',
    code: 'MXV',
  },
  {
    name: 'STN',
    symbol: 'STN',
    code: 'STN',
  },
  {
    name: 'Cuban Convertible Peso',
    symbol: 'CUC',
    code: 'CUC',
  },
  {
    name: 'Bahamian Dollar',
    symbol: 'BSD',
    code: 'BSD',
  },
  {
    name: 'Sudanese Dinar (1992–2007)',
    symbol: 'SDD',
    code: 'SDD',
  },
  {
    name: 'Gambian Dalasi',
    symbol: 'GMD',
    code: 'GMD',
  },
  {
    name: 'Cuban Peso',
    symbol: 'CUP',
    code: 'CUP',
  },
  {
    name: 'Uruguayan Peso (Indexed Units)',
    symbol: 'UYI',
    code: 'UYI',
  },
  {
    name: 'Falkland Islands Pound',
    symbol: 'FKP',
    code: 'FKP',
  },
  {
    name: 'Bhutanese Ngultrum',
    symbol: 'BTN',
    code: 'BTN',
  },
  {
    name: 'Salvadoran Colón',
    symbol: 'SVC',
    code: 'SVC',
  },
  {
    name: 'Mozambican Metical (1980–2006)',
    symbol: 'MZM',
    code: 'MZM',
  },
  {
    name: 'Dutch Guilder',
    symbol: 'NLG',
    code: 'NLG',
  },
  {
    name: 'Palladium',
    symbol: 'XPD',
    code: 'XPD',
  },
  {
    name: 'CFP Franc',
    symbol: 'CFPF',
    code: 'XPF',
  },
  {
    name: 'Platinum',
    symbol: 'XPT',
    code: 'XPT',
  },
  {
    name: 'Belgian Franc',
    symbol: 'BEF',
    code: 'BEF',
  },
  {
    name: 'Russian Ruble (1991–1998)',
    symbol: 'RUR',
    code: 'RUR',
  },
  {
    name: 'Kyrgystani Som',
    symbol: 'KGS',
    code: 'KGS',
  },
  {
    name: 'Silver',
    symbol: 'XAG',
    code: 'XAG',
  },
  {
    name: 'Italian Lira',
    symbol: 'ITL',
    code: 'ITL',
  },
  {
    name: 'WIR Euro',
    symbol: 'CHE',
    code: 'CHE',
  },
  {
    name: 'Andorran Peseta',
    symbol: 'ADP',
    code: 'ADP',
  },
  {
    name: 'Gold',
    symbol: 'XAU',
    code: 'XAU',
  },
  {
    name: 'WIR Franc',
    symbol: 'CHW',
    code: 'CHW',
  },
  {
    name: 'Samoan Tala',
    symbol: 'WST',
    code: 'WST',
  },
  {
    name: 'Turkmenistani Manat (1993–2009)',
    symbol: 'TMM',
    code: 'TMM',
  },
  {
    name: 'Slovak Koruna',
    symbol: 'SKK',
    code: 'SKK',
  },
  {
    name: 'Special Drawing Rights',
    symbol: 'XDR',
    code: 'XDR',
  },
  {
    name: 'ADB Unit of Account',
    symbol: 'XUA',
    code: 'XUA',
  },
  {
    name: 'Testing Currency Code',
    symbol: 'XTS',
    code: 'XTS',
  },
  {
    name: 'Belarusian Ruble',
    symbol: 'Br',
    code: 'BYN',
  },
  {
    name: 'Mongolian Tugrik',
    symbol: 'MNT',
    code: 'MNT',
  },
  {
    name: 'Aruban Florin',
    symbol: 'AWG',
    code: 'AWG',
  },
  {
    name: 'Belarusian New Ruble (1994–1999)',
    symbol: 'BYB',
    code: 'BYB',
  },
  {
    name: 'Swazi Lilangeni',
    symbol: 'SZL',
    code: 'SZL',
  },
  {
    name: 'Portuguese Escudo',
    symbol: 'PTE',
    code: 'PTE',
  },
  {
    name: 'Slovenian Tolar',
    symbol: 'SIT',
    code: 'SIT',
  },
  {
    name: 'Afghan Afghani (1927–2002)',
    symbol: 'AFA',
    code: 'AFA',
  },
  {
    name: 'Sucre',
    symbol: 'XSU',
    code: 'XSU',
  },
  {
    name: 'Haitian Gourde',
    symbol: 'HTG',
    code: 'HTG',
  },
  {
    name: 'East Caribbean Dollar',
    symbol: 'EC$',
    code: 'XCD',
  },
  {
    name: 'Greek Drachma',
    symbol: 'GRD',
    code: 'GRD',
  },
  {
    name: 'Irish Pound',
    symbol: 'IEP',
    code: 'IEP',
  },
  {
    name: 'Zimbabwean Dollar (2008)',
    symbol: 'ZWR',
    code: 'ZWR',
  },
  {
    name: 'Yugoslavian New Dinar (1994–2002)',
    symbol: 'YUM',
    code: 'YUM',
  },
  {
    name: 'ZWN',
    symbol: 'ZWN',
    code: 'ZWN',
  },
  {
    name: 'Zimbabwean Dollar (2009)',
    symbol: 'ZWL$',
    code: 'ZWL',
  },
  {
    name: 'Zimbabwean Dollar (1980–2008)',
    symbol: 'ZWD',
    code: 'ZWD',
  },
  {
    name: 'Bulgarian Hard Lev',
    symbol: 'BGL',
    code: 'BGL',
  },
  {
    name: 'Tajikistani Somoni',
    symbol: 'TJS',
    code: 'TJS',
  },
  {
    name: 'Cypriot Pound',
    symbol: 'CYP',
    code: 'CYP',
  },
  {
    name: 'European Unit of Account (XBD)',
    symbol: 'XBD',
    code: 'XBD',
  },
  {
    name: 'St. Helena Pound',
    symbol: 'SHP',
    code: 'SHP',
  },
  {
    name: 'European Monetary Unit',
    symbol: 'XBB',
    code: 'XBB',
  },
  {
    name: 'European Unit of Account (XBC)',
    symbol: 'XBC',
    code: 'XBC',
  },
  {
    name: 'Cayman Islands Dollar',
    symbol: 'KYD',
    code: 'KYD',
  },
  {
    name: 'European Composite Unit',
    symbol: 'XBA',
    code: 'XBA',
  },
  {
    name: 'Lesotho Loti',
    symbol: 'LSL',
    code: 'LSL',
  },
  {
    name: 'Guyanaese Dollar',
    symbol: 'GYD',
    code: 'GYD',
  },
  {
    name: 'North Korean Won',
    symbol: 'KPW',
    code: 'KPW',
  },
  {
    name: 'Turkish Lira (1922–2005)',
    symbol: 'TRL',
    code: 'TRL',
  },
  {
    name: 'VES',
    symbol: 'VES',
    code: 'VES',
  },
  {
    name: 'Zambian Kwacha',
    symbol: 'ZMW',
    code: 'ZMW',
  },
  {
    name: 'Maltese Lira',
    symbol: 'MTL',
    code: 'MTL',
  },
  {
    name: 'Ghanaian Cedi (1979–2007)',
    symbol: 'GHC',
    code: 'GHC',
  },
  {
    name: 'Liberian Dollar',
    symbol: 'LRD',
    code: 'LRD',
  },
  {
    name: 'Laotian Kip',
    symbol: 'LAK',
    code: 'LAK',
  },
  {
    name: 'Vanuatu Vatu',
    symbol: 'VUV',
    code: 'VUV',
  },
  {
    name: 'Venezuelan Bolívar (1871–2008)',
    symbol: 'VEB',
    code: 'VEB',
  },
  {
    name: 'US Dollar (Next day)',
    symbol: 'USN',
    code: 'USN',
  },
  {
    name: 'Colombian Real Value Unit',
    symbol: 'COU',
    code: 'COU',
  },
  {
    name: 'Spanish Peseta',
    symbol: 'ESP',
    code: 'ESP',
  },
  {
    name: 'Guinea-Bissau Peso',
    symbol: 'GWP',
    code: 'GWP',
  },
  {
    name: 'XXX',
    symbol: 'XXX',
    code: 'XXX',
  },
  {
    name: 'Bermudan Dollar',
    symbol: 'BMD',
    code: 'BMD',
  },
  {
    name: 'MRU',
    symbol: 'MRU',
    code: 'MRU',
  },
  {
    name: 'Mauritanian Ouguiya',
    symbol: 'MRO',
    code: 'MRO',
  },
  {
    name: 'Timorese Escudo',
    symbol: 'TPE',
    code: 'TPE',
  },
  {
    name: 'Papua New Guinean Kina',
    symbol: 'PGK',
    code: 'PGK',
  },
  {
    name: 'Azerbaijani Manat (1993–2006)',
    symbol: 'AZM',
    code: 'AZM',
  },
  {
    name: 'French UIC-Franc',
    symbol: 'XFU',
    code: 'XFU',
  },
  {
    name: 'French Gold Franc',
    symbol: 'XFO',
    code: 'XFO',
  },
  {
    name: 'AYM',
    symbol: 'AYM',
    code: 'AYM',
  },
  {
    name: 'Sierra Leonean Leone',
    symbol: 'SLL',
    code: 'SLL',
  },
  {
    name: 'Chilean Unit of Account (UF)',
    symbol: 'CLF',
    code: 'CLF',
  },
  {
    name: 'French Franc',
    symbol: 'FRF',
    code: 'FRF',
  },
  {
    name: 'Turkmenistani Manat',
    symbol: 'TMT',
    code: 'TMT',
  },
  {
    name: 'Luxembourgian Franc',
    symbol: 'LUF',
    code: 'LUF',
  },
  {
    name: 'Serbian Dinar (2002–2006)',
    symbol: 'CSD',
    code: 'CSD',
  },
  {
    name: 'Solomon Islands Dollar',
    symbol: 'SBD',
    code: 'SBD',
  },
  {
    name: 'Angolan Kwanza',
    symbol: 'AOA',
    code: 'AOA',
  },
  {
    name: 'Surinamese Guilder',
    symbol: 'SRG',
    code: 'SRG',
  },
  {
    name: 'Maldivian Rufiyaa',
    symbol: 'MVR',
    code: 'MVR',
  },
  {
    name: 'Surinamese Dollar',
    symbol: 'SRD',
    code: 'SRD',
  },
  {
    name: 'Gibraltar Pound',
    symbol: 'GIP',
    code: 'GIP',
  },
  {
    name: 'Romanian Leu (1952–2006)',
    symbol: 'ROL',
    code: 'ROL',
  },
  {
    name: 'Bolivian Mvdol',
    symbol: 'BOV',
    code: 'BOV',
  },
  {
    name: 'Malawian Kwacha',
    symbol: 'MWK',
    code: 'MWK',
  },
  {
    name: 'Finnish Markka',
    symbol: 'FIM',
    code: 'FIM',
  },
  {
    name: 'Malagasy Franc',
    symbol: 'MGF',
    code: 'MGF',
  },
  {
    name: 'German Mark',
    symbol: 'DEM',
    code: 'DEM',
  },
  {
    name: 'South Sudanese Pound',
    symbol: 'SSP',
    code: 'SSP',
  },
];
