import {
  getCustomerContactId,
  useCustomerContactsFilter,
} from 'components/Common/FilterComponents/Common/hooks/useCustomerContacts';
import { filterReducer } from 'components/Common/FilterComponents/reducers/filterListReducer';
import { FilterGroup, StandardFilterGroup } from 'components/Common/FilterComponents/types';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { identity, pickBy } from 'lodash';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CollectionPlanEnabledSelector } from 'store/collection-period/selector';
import { AccountConfigKey } from 'types/entities/account';
import { CustomFieldEntityType } from 'types/entities/custom-field';
import {
  invoiceFilterList,
  isCollectionPeriodEnabled,
  isEmailDeliveryEnabled,
  isStrategyFilter,
  useCustomerFilterList,
} from '../filter-options-list';
import {
  FilterValues,
  filterValuesReducer,
  initialFilterValues,
} from '../reducers/filterValuesReducer';
import { useCustomField } from './useCustomField';

export type InvoiceListFilterType = ReturnType<typeof useOpenInvoiceListFilter>;

export function useOpenInvoiceListFilter() {
  const strategyV2Enabled = useConfig(AccountConfigKey.STRATEGY_V2);
  const isWorkflowEnabled = useConfig(AccountConfigKey.WORKFLOW_ENABLED);
  const hasCollectionPeriod = useSelector(CollectionPlanEnabledSelector);
  const privilegeForStrategyPresent = usePrivilegeStatus([
    ERolesAndPrivileges.ASSIGN_COLLECTION_FOLLOWUP,
  ]);
  const customerFilterList = useCustomerFilterList();

  function validStrategyFilter(filterId: string) {
    return !isStrategyFilter(filterId) || (privilegeForStrategyPresent && strategyV2Enabled);
  }

  function collectionPeriodCheck(filterId: string) {
    return isCollectionPeriodEnabled(filterId);
  }

  function emailDeliveryStatusCheck(filterId: string) {
    return isEmailDeliveryEnabled(filterId);
  }

  const invoiceFilterGroupList: FilterGroup = {
    id: StandardFilterGroup.INVOICE,
    label: 'Invoice',
    filterList: invoiceFilterList.filter((filter) => {
      if (collectionPeriodCheck(filter.id)) {
        return hasCollectionPeriod;
      }
      if (emailDeliveryStatusCheck(filter.id)) {
        return isWorkflowEnabled;
      }
      return filter.removable && validStrategyFilter(filter.id);
    }),
  };

  const customerFilterGroupList: FilterGroup = {
    id: StandardFilterGroup.CUSTOMER,
    label: 'Customer',
    filterList: customerFilterList.filter((filter) => filter.removable),
  };

  const initialFilterList = [invoiceFilterGroupList, customerFilterGroupList];

  const [filterValues, updateFilterValues] = useReducer(filterValuesReducer, initialFilterValues);
  const [filterGroups, updateFilterGroups] = useReducer(filterReducer, initialFilterList);
  const [customerCustomFields] = useCustomField(CustomFieldEntityType.CUSTOMER);
  const [invoiceCustomFields] = useCustomField(CustomFieldEntityType.INVOICE);
  const [customerContacts] = useCustomerContactsFilter();

  /** To ensure mulitple reloads doesn't result in load of duplicate
   * props due to async callbacks, we have flags inplace to ensure we stop
   * when the fields have already been loaded. */

  const isCustomerCustomFieldsAddedToFilterGroup = useRef(false);
  const isInvoiceFieldsAddedToFilterGroup = useRef(false);
  const isCustomerContactsAddedToFilterGroup = useRef(false);

  const setInitialActiveFilterGroups = useCallback((newFilterValues: FilterValues) => {
    const nonFalsyFilters = pickBy(newFilterValues, identity);
    const reportingTagKeys = [] as string[];

    const customFieldKeys = nonFalsyFilters.custom_fields?.map((customField) => {
      return String(customField.id);
    });

    const customerContactKeys = nonFalsyFilters.user_association?.map((contact) =>
      getCustomerContactId(contact.category_id)
    );

    updateFilterGroups({
      type: 'UPDATE_INITIAL_GROUP',
      payload: {
        standardFieldKeys: Object.keys(nonFalsyFilters),
        reportingTagKeys,
        customFieldKeys,
        customerContactKeys,
      },
    });
  }, []);

  const setInitialFilterValue = useCallback((filterValues: FilterValues) => {
    updateFilterValues({
      type: 'UPDATE_INITIAL_VALUE',
      payload: filterValues,
    });
  }, []);

  /**
   * @param filterValues
   * This callback takes care of accomplishing 2 things.
   *
   * - @function setInitialFilterValue setting intial filter valeues that is being loaded from filter view.
   * - @function setInitialActiveFilterGroups Initialize the filtergroups against the supplied current filter values to
   *   ensure the filters are visible during intial load.
   *
   */

  const initializeFilterValuesAndFilterGroups = useCallback(
    (filterValues: FilterValues) => {
      setInitialFilterValue(filterValues);
      setInitialActiveFilterGroups(filterValues);
    },
    [setInitialActiveFilterGroups, setInitialFilterValue]
  );

  useEffect(() => {
    if (customerCustomFields.length && !isCustomerCustomFieldsAddedToFilterGroup.current) {
      const filterGroup = {
        id: StandardFilterGroup.CUSTOMER,
        label: 'Customer',
        filterList: customerCustomFields,
      };

      updateFilterGroups({ type: 'UPDATE_GROUP', payload: filterGroup });
      setInitialActiveFilterGroups(filterValues);
      isCustomerCustomFieldsAddedToFilterGroup.current = true;
    }
  }, [customerCustomFields, filterValues, setInitialActiveFilterGroups]);

  useEffect(() => {
    if (invoiceCustomFields.length && !isInvoiceFieldsAddedToFilterGroup.current) {
      const filterGroup = {
        id: StandardFilterGroup.INVOICE,
        label: 'Invoice',
        filterList: invoiceCustomFields,
      };

      updateFilterGroups({ type: 'UPDATE_GROUP', payload: filterGroup });
      setInitialActiveFilterGroups(filterValues);
      isInvoiceFieldsAddedToFilterGroup.current = true;
    }
  }, [filterValues, invoiceCustomFields, setInitialActiveFilterGroups]);

  useEffect(() => {
    if (customerContacts?.length && !isCustomerContactsAddedToFilterGroup.current) {
      const filterGroup = {
        id: StandardFilterGroup.CUSTOMER,
        label: 'Customer',
        filterList: customerContacts,
      };

      updateFilterGroups({ type: 'UPDATE_GROUP', payload: filterGroup });
      setInitialActiveFilterGroups(filterValues);
      isCustomerContactsAddedToFilterGroup.current = true;
    }
  }, [customerContacts, filterValues, setInitialActiveFilterGroups]);

  return {
    filterValues,
    updateFilterValues,
    filterGroups,
    updateFilterGroups,
    initializeFilterValuesAndFilterGroups,
  };
}
