import { Flex } from 'components/BaseComponents/Layout/Flex';
import styled from 'styled-components';

export const CategorisedContainerStyled = styled.div`
  --placeholders-body-title: 55px;
  overflow-y: auto;
  background: var(--gray-1);
`;

export const OptionsContainer = styled.div`
  padding: var(--space-16);
  background: var(--gray-1);
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    &-thumb {
      background: var(--gray-4);
      border-right: 2px solid var(--gray-1);
    }
  }
`;

export const StyledPlaceholder = styled(Flex)`
  border-radius: var(--br-1);
  text-align: left;
  overflow: hidden;
  padding: var(--space-0) var(--space-8);
  min-height: var(--space-32);

  .placeholder-icon {
    margin: var(--space-8) var(--space-0);
    padding-top: var(--space-2);
  }

  &.hover-styles:hover {
    cursor: pointer;
    background: var(--purple-1);
    .placeholder-icon {
      color: var(--purple-6);
    }
  }

  .label {
    padding-right: var(--space-4);
    color: var(--gray-9);
    line-height: var(--lh-16);
    margin: var(--space-8) var(--space-0);
  }
`;
