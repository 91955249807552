import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { useMemo } from 'react';
import { AppSubRoute } from '../sidebar.components';
import { getSidebarFontAwesomeIconComponent } from '../sidebar.utils';
import { PageListItems } from './usePageList';

function useCashAppsRoute() {
  const hasCashApps = usePrivilegeStatus([ERolesAndPrivileges.DEFAULT_CASH_APP_PRIVILEGE], {
    shallowCheck: true,
  });

  function handleCashAppsSubLinks() {
    const subLinks: AppSubRoute[] = [
      {
        label: 'Payments',
        url: '/ca/payments',
      },
      {
        label: 'Remittances',
        url: '/ca/remittances',
      },
      {
        label: 'Credit Memos',
        url: '/ca/credit-memos',
      },
    ];
    return subLinks;
  }
  const cashAppsSubLinks: AppSubRoute[] = useMemo(handleCashAppsSubLinks, []);
  const cashAppPageList: Array<PageListItems> = [
    {
      key: 'CASHAPPS',
      subLinks: cashAppsSubLinks,
      label: 'Cash App',
      access: hasCashApps,
      icon: getSidebarFontAwesomeIconComponent(['far', 'check-to-slot']),
    },
  ];
  return { cashAppPageList };
}

export default useCashAppsRoute;
