import { RelativeDateRange } from 'util/relative-date-range';

export const RelativeDateRangeLabels: Record<RelativeDateRange, string> = {
  [RelativeDateRange.GREATER_THAN]: 'Greater Than',
  [RelativeDateRange.LAST_30_DAYS]: 'Past 30 Days',
  [RelativeDateRange.LAST_7_DAYS]: 'Past 7 Days',
  [RelativeDateRange.LAST_MONTH]: 'Last Month',
  [RelativeDateRange.LESSER_THAN]: 'Lesser Than',
  [RelativeDateRange.MORE_THAN_30_DAYS]: 'More than 30 Days',
  [RelativeDateRange.NEXT_WEEK]: 'Next Week',
  [RelativeDateRange.NINE_MONTHS]: '9 Months',
  [RelativeDateRange.SIX_MONTHS]: '6 Months',
  [RelativeDateRange.THREE_MONTHS]: '3 Months',
  [RelativeDateRange.TWELVE_MONTHS]: '12 Months',
  [RelativeDateRange.THIS_MONTH]: 'This Month',
  [RelativeDateRange.THIS_WEEK]: 'This Week',
  [RelativeDateRange.TODAY]: 'Today',
  [RelativeDateRange.TOMORROW]: 'Tomorrow',
  [RelativeDateRange.YESTERDAY]: 'Yesterday',
};
