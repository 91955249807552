import { Form } from 'antd';
import { notify } from 'components/BaseComponents/Notifications';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { ActivityType } from 'types/activities/activity-types';
import useFormSubmit from '../hooks/useFormSubmit';
import useOnFormRemainderSubmit from '../hooks/useSubmitFormRemainder';
import TaskRemainder from '../TaskRemainder';
import { DisputeFormModalFormElementProps, DisputeFormModalProps, FormItemNames } from '../types';
import { modifyUploadFiles, parseTransformSchema } from '../Utils';
import DisputeFormItems from './DisputeFormItems';
import { getNotifyErrorMessage, getNotifySuccessMessage } from './notificationMessage';

export default function DisputeForm<DisputeElements extends DisputeFormModalFormElementProps>(
  props: Omit<DisputeFormModalProps<DisputeElements>, 'openModal'>
) {
  const {
    form,
    text,
    invoices,
    remainder,
    responseSchema,
    taskRemainderResponseSchema,
    onMutateFn,
    onFormClose,
    customTransformer,
    bulkAction,
    editMode,
    dateFormat,
  } = props;

  const assigneeOptionType = invoices?.length
    ? ActionableEntity.INVOICE
    : ActionableEntity.CUSTOMER;

  const { onFormSubmit } = useFormSubmit<DisputeElements['responseType']>(onMutateFn);

  const onFormRemainderSubmit = useOnFormRemainderSubmit<
    DisputeElements['responseType'],
    DisputeElements['taskRemainderResponseSchema']
  >({
    taskRemainderResponseSchema,
    customTransformer,
    onMutateFn,
    parentType: ActivityType.DISPUTE,
    onClose: onFormClose,
  });

  function onFormFinish(values: Record<FormItemNames, any>) {
    const formValues = parseTransformSchema<DisputeElements['responseType']>({
      data: values,
      type: ActivityType.DISPUTE,
      responseSchema,
      customTransformer,
    });

    const modifiedData = modifyUploadFiles<DisputeElements['responseSchema']>(
      'file_upload_ids',
      formValues
    );

    onFormSubmit(modifiedData, ActivityType.DISPUTE)
      .then((data) => {
        if (!remainder) {
          onFormClose();
        }
        onFormRemainderSubmit<DisputeElements['responseType']>({ values, data, remainder });
        const { title, description } = getNotifySuccessMessage(bulkAction, editMode);
        notify.success(title, {
          description: description,
        });
      })
      .catch(() => {
        const { title, description } = getNotifyErrorMessage(bulkAction, editMode);
        notify.error(title, {
          description: description,
        });
      });
  }

  return (
    <Form.Provider
      onFormFinish={(name, { values }) => {
        onFormFinish(values as Record<FormItemNames, any>);
      }}
    >
      <Form name={'activityForm'} form={form} layout="vertical" preserve={false}>
        <DisputeFormItems<DisputeElements> {...props} />
      </Form>
      <div className={`${remainder ? 'active-transition' : 'hide-transition'}`}>
        <Form name={'taskRemainderForm'} form={form} layout="vertical" preserve={false}>
          <TaskRemainder
            form={form}
            assigneeOptionType={assigneeOptionType}
            text={text}
            remainder={remainder}
            dateFormat={dateFormat}
          />
        </Form>
      </div>
    </Form.Provider>
  );
}
