import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Select } from 'antd';
import { EditOnHover } from 'components/BaseComponents/EditOnHover';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { notify } from 'components/BaseComponents/Notifications';
import { GrowText } from 'components/BaseComponents/Typography';
import { UserInfoBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import useUpdateActivity from 'components/CollectionActivities/hooks/useUpdateActivity';
import { filter, pick } from 'lodash';
import { useUpdateKeyActivities } from 'queries/activites';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AssignedToUsers } from 'store/activity-feed/type';
import { usersSelector } from 'store/user/user';
import { Task } from 'types/activities/task';
import { BaseRecepientsType } from 'types/entities/collection-strategy/contact-type';
import { User } from 'types/entities/user';
import useOpen from 'util/hooks/useOpen';

const t = {
  unassigned: 'Unassigned',
  updateSucces: 'Update success',
  updateFailure: 'Update Failed',
};

interface TaskOwnerProps {
  selectedUser?: User;
  onChangeCallBack?: (selectedUser: User) => void;
  data?: Task;
}

const CloseIcon = (
  <FontAwesomeIcon
    icon={['far', 'times']}
    className="cursor-pointer"
    color="var(--gray-7)"
    fontSize={14}
  />
);

const CheckIcon = (
  <FontAwesomeIcon
    icon={['far', 'check']}
    className="cursor-pointer"
    color="var(--primary-7)"
    fontSize={14}
  />
);
export function AssignTaskOwner(props: TaskOwnerProps) {
  const { selectedUser, data } = props;
  const { open, onClose, toggleOpen } = useOpen({ open: false });
  const users = useSelector(usersSelector);
  const selectedUserValue = {
    label: `${selectedUser?.first_name}${selectedUser?.last_name ?? ''}`,
    ...selectedUser,
  } as User;
  const [currentOwner, setCurrentOwner] = useState<User | undefined>(selectedUserValue);
  const { updateActivity } = useUpdateActivity<Task>();
  const { mutateAsync: updateKey, isLoading } = useUpdateKeyActivities<Task>();

  function UpdateOwner(value: User) {
    if (!data) return;
    const previousValues = filter(data.assigned_to_list ?? [], (value) => {
      return value.id !== selectedUser?.id;
    });

    //removing unnecessary params from the selected object
    const modifiedValue = pick(value, ['id', 'hash', 'first_name', 'last_name']) as User;

    const updatedParticipants: User[] = [...previousValues, modifiedValue];

    const config: AssignedToUsers[] = updatedParticipants.map((user) => {
      return {
        type: BaseRecepientsType.USER,
        id: user.id,
      };
    });

    const newValue = { ...data, assigned_to_list: updatedParticipants, participant_config: config };
    updateKey({
      params: {
        due_date: data.due_date,
        description: data.description,
        participant_config: config,
      },
      id: data.id,
      activity: 'tasks',
    }).then(() => {
      updateActivity(data, newValue);
      onClose();
      notify.success('Successfully updated');
    });
  }

  useEffect(() => {
    setCurrentOwner((currentOwner) =>
      currentOwner?.id === selectedUser?.id ? currentOwner : selectedUser
    );
  }, [selectedUser]);

  function getFormattedList() {
    if (!data) return [];

    const availableUsers = data.assigned_to_list
      ?.map((user) => user.id)
      .filter((user) => user !== currentOwner?.id);

    const filteredUsers = users?.filter((user) => !availableUsers?.includes(user.id));
    return filteredUsers?.map((user, index) => ({
      label: `${user.first_name}${user.last_name ?? ''}`,
      ...user,
      key: index,
    }));
  }
  const usersFormattedList = useMemo(getFormattedList, [currentOwner?.id, data, users]);

  return (
    <>
      {open ? (
        <Flex align="center" gap="var(--space-2)">
          <Select<User>
            showSearch
            value={currentOwner}
            options={usersFormattedList}
            onChange={(_, options) => {
              if (options) {
                setCurrentOwner(options as unknown as User);
              }
            }}
            style={{ width: '160px' }}
            fieldNames={{ label: 'label', value: 'id' }}
            loading={isLoading}
            disabled={isLoading}
            dropdownStyle={{ minWidth: '200px' }}
          />
          <Flex gap="var(--space-2)">
            <Button
              loading={isLoading}
              disabled={isLoading}
              onClick={() => {
                setCurrentOwner(selectedUser);
                onClose();
              }}
              icon={CloseIcon}
              type="text"
              ghost
              size="small"
            />

            <Button
              loading={isLoading}
              disabled={isLoading}
              ghost
              type="text"
              size="small"
              onClick={() => {
                if (currentOwner) {
                  UpdateOwner(currentOwner);
                }
              }}
              icon={CheckIcon}
            />
          </Flex>
        </Flex>
      ) : (
        <EditOnHover onEdit={toggleOpen} hoverMode="NORMAL">
          {currentOwner?.first_name ? (
            <UserInfoBlock user={currentOwner} onClick={toggleOpen} className="cursor-pointer" />
          ) : (
            <GrowText>{t.unassigned}</GrowText>
          )}
        </EditOnHover>
      )}
    </>
  );
}
