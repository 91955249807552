import { FilterGroup } from '../types';

interface ShowIfActiveProps {
  filterGroups: FilterGroup[];
  filterGroupId: string;
  filterId: string;
  children: React.ReactNode;
  destroyOnHide?: boolean;
  order?: number;
}
export function ShowIfActive({
  filterGroups,
  filterGroupId,
  filterId,
  children,
  destroyOnHide = true,
  order,
}: ShowIfActiveProps) {
  const filterGroup = filterGroups.find((filterGroup) => filterGroup.id === filterGroupId);
  if (!filterGroup) return null;

  const filter = filterGroup.filterList.find((filter) => filter.id === filterId);
  if (!filter) return null;

  if (!filter.active && destroyOnHide) return null;

  return (
    <div style={{ order: order ?? filter.order, display: filter.active ? 'initial' : 'none' }}>
      {children}
    </div>
  );
}
