import { Col, Row } from 'antd';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { StyledChildCard } from 'components/Dashboard/styles';
import { dashboardTexts } from 'components/Dashboard/texts';
import TotalCustomersInvoices from 'components/Dashboard/TotalCustomersInvoices';
import { PageLink } from 'lib/router';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import React from 'react';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';

interface Props extends DashboardComponentProps {
  totalOutstanding?: TotalOutstandingResponse;
  loading: boolean;
}

function UnappliedPayments(props: Props) {
  const { totalOutstanding, loading } = props;

  const unAppliedPayment = totalOutstanding?.total_outstanding.un_applied_payment_amount;
  const unAppliedPaymentCustomersCount = unAppliedPayment?.customer_count;

  const InputLoaderJSX = <InputLoader active={loading} />;

  const UnappliedPaymentAmount = unAppliedPayment && (
    <PageLink
      appPage="PAYMENT_LIST"
      searchParams={`filter=ALL`}
      state={{ addPrimaryPathAsChildPath: true }}
    >
      <AmountWithCurrencySplit
        type="multi"
        amountWithCurrencySplit={unAppliedPayment.amount}
        className="text-24 semi-bold"
        formatOptions={{ maximumFractionDigits: 0 }}
      />
    </PageLink>
  );

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices
      loading={loading}
      customersCount={unAppliedPaymentCustomersCount}
      showInvoice={false}
    />
  );

  return (
    <StyledChildCard
      style={{ position: 'relative', height: '100%' }}
      className="current-due-container"
      color="var(--green-2)"
    >
      <div style={{ marginBottom: 'var(--space-16)' }}>
        <Row className="no-wrap">
          <Col flex="max-content 1 0">{dashboardTexts.unappliedPayments.title}</Col>
        </Row>
        <Row>
          <Col flex="1">
            <GrowText size={'var(--fs-20)'} className="amount">
              {loading ? InputLoaderJSX : UnappliedPaymentAmount}
            </GrowText>
          </Col>
        </Row>
      </div>
      <Row>
        <Col flex={'1 0 1'}>{TotalCustInvoiceInfo}</Col>
      </Row>
    </StyledChildCard>
  );
}

export default React.memo(UnappliedPayments);
