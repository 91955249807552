import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Sorter, TableInitialState } from '@sinecycle/growcomponents';
import { Page } from 'components/BaseComponents/Table/Pagination/TablePagination';
import { BaseCustomer } from 'types/entities/customer';
import { AllCustomers, AllCustomersData, AllCustomersFilter, AllCustomersFilterView } from './type';

export interface AllCustomersProps {
  currentView: AllCustomersFilterView;
  totalSavedViews: AllCustomersFilterView[];
  page: Page;
  excludeRowIds: number[];
  customersCache: BaseCustomer[];
  hasFilterViewLoaded: boolean;
  customersDetail: AllCustomers[];
  tableState: TableInitialState<AllCustomers>;
}
export const initialState: AllCustomersProps = {
  currentView: {} as AllCustomersFilterView,
  totalSavedViews: [],
  hasFilterViewLoaded: false,

  page: {
    page: 1,
    size: 20,
  },

  excludeRowIds: [],
  customersCache: [],
  customersDetail: [],
  tableState: {
    selectAll: {
      selected: false,
      select_all: false,
    },
    selectedRows: [],
    currentPage: 1,
    bulk_records: 0,
  },
};

const AllCustomersSlice = createSlice({
  name: 'AllCustomers',
  initialState,
  reducers: {
    updateTableState(state, action: PayloadAction<TableInitialState<AllCustomers>>) {
      state.tableState = action.payload;
    },
    updateCurrentView(state, action: PayloadAction<AllCustomersFilterView>) {
      state.currentView = action.payload;
      state.page = initialState.page;
    },
    getFilterViews(
      state,
      action: PayloadAction<{
        filterViews: AllCustomersFilterView[];
        id?: string;
        sessionFilters?: AllCustomersFilter;
      }>
    ) {
      state.totalSavedViews = action.payload.filterViews.filter((filterViews) => filterViews);

      const selectedFilterView = action.payload.filterViews.find(
        (filterView) => filterView.id === action.payload.id
      ) as AllCustomersFilterView;

      if (selectedFilterView) {
        const updatedSelectedView = {
          ...selectedFilterView,
          customer_filter: action.payload.sessionFilters
            ? {
                ...action.payload.sessionFilters,
                with_receivables: action.payload.sessionFilters.with_receivables ?? null,
              }
            : {
                ...selectedFilterView.customer_filter,
                with_receivables: selectedFilterView.customer_filter.with_receivables ?? null,
              },
        };
        state.currentView = updatedSelectedView ?? ({} as AllCustomersFilterView);
      } else {
        const updatedFilterView = {
          ...action.payload.filterViews[0],
          customer_filter: {
            ...action.payload.filterViews[0].customer_filter,
            with_receivables:
              action.payload.filterViews[0].customer_filter.with_receivables ?? null,
          },
        };
        state.currentView = updatedFilterView ?? ({} as AllCustomersFilterView);
      }

      state.hasFilterViewLoaded = true;
    },

    updateFilterViewSaveAs(state, action: PayloadAction<AllCustomersFilterView>) {
      state.totalSavedViews = [...state.totalSavedViews, action.payload];
      state.currentView = action.payload;
    },

    updateFilterViewSave(state, action: PayloadAction<AllCustomersFilterView>) {
      state.totalSavedViews = state.totalSavedViews.map((filterViews) => {
        if (filterViews.id === action.payload.id) {
          return { ...action.payload };
        }
        return filterViews;
      });
    },

    deleteFilterViews(state, action: PayloadAction<string>) {
      state.totalSavedViews = state.totalSavedViews.filter(
        (filterViews) => filterViews.id !== action.payload
      );
    },

    updateSwitcherView(state, action: PayloadAction<boolean | null>) {
      state.page = initialState.page;
      state.currentView.customer_filter = {
        ...state.currentView.customer_filter,
        with_receivables: action.payload,
      };
    },

    updateCurrentPage(state, action: PayloadAction<Page>) {
      state.page = action.payload;
    },

    updateSortingCurrentFilter(state, action: PayloadAction<Sorter>) {
      state.currentView.customer_filter = {
        ...state.currentView.customer_filter,
        sort_by: action.payload.sortOrder,
        sort_col: action.payload.sortCol,
        sort_by_custom_field: action.payload.customCol,
      };
    },

    initializeTagsAndTotalPages(state, action: PayloadAction<AllCustomersData>) {
      state.customersCache = action.payload.list.map((customerDetail) => customerDetail.customer);
      state.tableState = { ...state.tableState, bulk_records: action.payload.total_records };
    },

    addExcludedRowId(state, action: PayloadAction<number>) {
      state.excludeRowIds = [...state.excludeRowIds, action.payload];
    },
    removeExcludedRowId(state, action: PayloadAction<number>) {
      state.excludeRowIds = state.excludeRowIds.filter((id) => id !== action.payload);
    },
    resetExcludedRowId(state) {
      state.excludeRowIds = [];
    },

    updateCurrentFilter(state, action: PayloadAction<AllCustomersFilter>) {
      state.currentView.customer_filter = action.payload;
      state.page = initialState.page;

      //reset selection mode when new filter is applied
      state.tableState = initialState.tableState;
    },
    updateCustomersDetail(state, action: PayloadAction<AllCustomers[]>) {
      state.customersDetail = action.payload;
    },
  },
});

export const {
  getFilterViews,
  updateFilterViewSaveAs,
  updateFilterViewSave,
  deleteFilterViews,
  updateCurrentView,
  updateSwitcherView,
  initializeTagsAndTotalPages,
  updateCurrentPage,
  addExcludedRowId,
  removeExcludedRowId,
  resetExcludedRowId,
  updateSortingCurrentFilter,
  updateCurrentFilter,
  updateCustomersDetail,
  updateTableState,
} = AllCustomersSlice.actions;
export default AllCustomersSlice.reducer;
