import { CellRenderProps } from '@sinecycle/growcomponents';
import FollowupAction from 'components/HigherOrderComponent/KeyActivitesContainer/FollowupAction';
import { IInvoices } from 'store/customer/types';
interface InvoiceRendererProps<T> extends CellRenderProps<T> {
  data: T;
}
function FollowUpAction(props: InvoiceRendererProps<IInvoices>) {
  const { data } = props;
  return (
    <FollowupAction
      customerId={data?.customer_id}
      nextFollowup={data.next_followup_task}
      openTaskCount={data.open_task_count}
      invoiceList={[
        {
          id: data.id,
          invoice_no: data.invoice_no,
          balance_due_amount: data.balance_due_amount,
          invoice_date: data.invoice_date,
          invoice_status: data.status,
          currency: data.currency,
          customer_id: data.customer_id,
          customer_name: data.customer_name,
          customer: data.customer
        },
      ]}
      context="Table"
    />
  );
}

export { FollowUpAction };
