import { CustomFieldDataType, CustomFieldOperatorType } from 'types/entities/custom-field';
import { getNumberBasedText } from 'util/getNumberBasedText';
import { BaseFilterBlockProps } from '../FilterBlocks/types';
import { FilterDropdown } from '../FilterDropdown';
import { CustomFieldRangeValueType } from '../Filters/CustomFiledRange';
import { FilterConfig, FilterType } from '../types';

interface CustomFieldRangeProps
  extends BaseFilterBlockProps<Partial<CustomFieldRangeValueType<CustomFieldOperatorType>>> {
  filterConfig: Pick<FilterConfig, 'name' | 'label'>;
  currencySymbol: string | undefined;
  dataType: CustomFieldDataType;
}

function CustomFieldRangeWrapper(props: CustomFieldRangeProps) {
  const {
    filterConfig,
    onClear,
    onSubmit,
    showClear = true,
    value,
    currencySymbol,
    dataType,
  } = props;

  const config: FilterConfig = {
    ...filterConfig,

    type: FilterType.CUSTOM_FIELD_RANGE,
    valueToString(value: CustomFieldRangeValueType<CustomFieldOperatorType>) {
      return getNumberBasedText(value, props.currencySymbol);
    },
    additionalProps: {
      currencySymbol,
      dataType,
    },
  };
  function handleClear() {
    onClear?.();
    onSubmit?.({ value: undefined, type: undefined });
  }

  function handleSubmit(value: CustomFieldRangeValueType<CustomFieldOperatorType>) {
    onSubmit?.(value);
  }
  return (
    <FilterDropdown
      value={value as CustomFieldRangeValueType<CustomFieldOperatorType>}
      filterConfig={config}
      onSubmit={handleSubmit}
      onClear={handleClear}
      showClear={showClear}
    />
  );
}

export default CustomFieldRangeWrapper;
