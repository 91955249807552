import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { Progress } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { notify } from 'components/BaseComponents/Notifications';
import { useQuickActionsMenu } from 'components/BaseComponents/RTE/ContextMenu/QuickActionsMenu';
import { styled } from 'lib/css-in-js';
import React, { LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import { deleteContentImage, uploadContentImage } from 'services/upload-images';
import { useImageResize } from './useImageResize';

const t = {
  error:
    'File Upload Failed. Exceeded maximum file size limit (1MB). Please upload a smaller file and try again',
  delete: 'Image delete failed, try again later!',
};

interface ImageLoaderProps extends NodeViewProps {}

const StyledImage = styled.div`
  position: relative;
  margin: var(--space-8) 0;
  .ant-progress-outer {
    padding-right: 0;
  }

  img {
    height: auto;
    border: solid rgba(55, 18, 84, 0.1);
    border-radius: 4px;
  }

  .resize-bar {
    visibility: hidden;
    background: var(--gray-6);

    &:hover {
      background: var(--purple-6) !important;
    }
  }

  &:hover .resize-bar {
    visibility: visible;
  }
`;

const StyledFlex = styled(Flex)`
  max-width: 300px;
  position: relative;
  padding: var(--space-20);
  overflow: hidden;
  border-radius: 4px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(55, 18, 84, 0.1);
    color: #fff;
    inset: 0;
  }
`;

const DEFAULT_IMAGE_WIDTH = '250px';

export function ImageLoader(props: ImageLoaderProps) {
  const { node, updateAttributes, getPos, editor } = props;
  const { image, imageSrc, imageStyle } = node.attrs;
  const [loading, setLoading] = useState(false);
  const [percent, setPercentage] = useState<number | undefined>();
  const hashRef = useRef<string>('');

  function onAlignClickCallback(align: React.CSSProperties['justifyContent']) {
    const imageStyle = {
      display: 'flex',
      ...node.attrs.imageStyle,
      justifyContent: align,
    };

    updateAttributes({
      imageStyle,
    });
  }

  function onDeleteCallback() {
    const from = getPos();
    const to = from + node.nodeSize;

    editor.commands.deleteRange({ from, to });
    try {
      deleteContentImage(hashRef.current);
    } catch (ex) {
      console.log('ex');
      notify.error(t.delete);
    }
  }

  const [QuickAction, triggerCallback, elementRef] = useQuickActionsMenu({
    onAlignClickCallback,
    onDeleteCallback,
  });

  const imgRef = useRef<HTMLImageElement>(null);
  const resizeRef = useRef<HTMLSpanElement>(null);

  function onMouseUpCallback(width: number, height: number) {
    const imageStyle = {
      display: 'flex',
      ...node.attrs.imageStyle,
      width: `${width}px`,
    };

    updateAttributes({
      imageStyle,
    });
  }

  useImageResize({ imgRef, resizeRef, onMouseUpCallback });

  const resizeBar = (
    <span
      className="resize-bar"
      ref={resizeRef}
      style={{
        width: '3px',
        height: '64px',
        borderRadius: '6px',
        cursor: 'col-resize',
      }}
    />
  );

  const ImageJSX = (
    <div
      ref={imgRef}
      className="image-canvas"
      style={{ width: node.attrs.imageStyle.width ?? DEFAULT_IMAGE_WIDTH, height: 'auto' }}
    >
      <img
        alt="insert-custom-img"
        className="rte-custom-image"
        src={imageSrc}
        style={{ maxHeight: '100%', maxWidth: '100%' }}
      />
    </div>
  );
  const ImageQuickAction = (
    <div
      ref={elementRef as LegacyRef<HTMLDivElement>}
      className="custom-button-node-view"
      style={{
        display: 'flex',
        position: 'relative',
        width: '100%',
        justifyContent: imageStyle?.justifyContent ?? 'start',
      }}
    >
      <div
        className="image-preview"
        style={{
          display: 'inline-flex',
          padding: 'var(--space-8)',
          gap: 'var(--space-8)',
          alignItems: 'center',
        }}
        {...triggerCallback}
      >
        {ImageJSX}
        {resizeBar}
      </div>
      {QuickAction}
    </div>
  );

  const UploadImage = (
    <FontAwesomeIcon icon={['far', 'image']} size="2x" color="var(--primary-3)" />
  );

  const Success = (
    <FontAwesomeIcon icon={['far', 'cloud-check']} size="2x" color="var(--primary-4)" />
  );

  const ProgressBar = (
    <Progress
      status="active"
      strokeColor={{
        '0%': 'var(--primary-3)',
        '100%': 'var(--primary-5)',
      }}
      percent={percent}
      format={() => ''}
    />
  );

  const uploadCallback = useCallback(async (image: File) => {
    const hashResponse = await uploadContentImage(image);
    const newURL = new URL(`${document.location.origin}/api/cdn/image/${hashResponse.hash}`);
    hashRef.current = hashResponse.hash;

    return newURL.toString();
  }, []);

  useEffect(() => {
    if (image) {
      setLoading(true);
      [30, 50, 75].forEach((item) => {
        setTimeout(() => {
          setPercentage(item);
        }, 500);
      });

      uploadCallback(image)
        .then((src: string) => {
          setPercentage(100);
          updateAttributes({
            imageSrc: src,
          });
        })
        .catch((ex) => {
          if (ex.response.status === 413) {
            notify.error(t.error);
          }
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    }
  }, [updateAttributes, image, uploadCallback]);

  const Loader = (
    <StyledFlex align="center" direction="column" style={{ width: DEFAULT_IMAGE_WIDTH }}>
      {percent === 100 ? Success : UploadImage}
      {ProgressBar}
    </StyledFlex>
  );

  return (
    <NodeViewWrapper>
      <StyledImage
        className="image-container"
        style={{ display: 'flex', justifyContent: imageStyle.justifyContent }}
      >
        {loading ? Loader : ImageQuickAction}
      </StyledImage>
    </NodeViewWrapper>
  );
}
