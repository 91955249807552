import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { formattingOptions } from 'lib/RichText';
import { commonButtonProps } from '../../Toolbar';
import { ToolbarOptionProps } from '../../type';

const t = {
  label: 'Redo',
};

const Icon = <FontAwesomeIcon icon={['far', 'arrow-rotate-right']} />;

const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
  title: t.label,
};

interface RedoProps extends ToolbarOptionProps {}

export function Redo({ editor }: RedoProps) {
  function onClickCallback(event: React.MouseEvent<HTMLElement>) {
    formattingOptions['redo'].onClick(editor);
  }

  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    icon: Icon,
    onClick: onClickCallback,
  };

  return <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />;
}
