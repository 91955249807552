export type ContactItem = {
  id: number;
  customerId?: number;
  customerName?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  type: CustomerContactType | AccountContactType;
  role: ContactRole;
};

export enum CustomerContactType {
  PRIMARY_CONTACT = 'Primary Contact',
  SECONDARY_CONTACT = 'Secondary Contact',
  PRIMARY_CONTACT_PARENT = 'Primary Contact (Parent)',
  SECONDARY_CONTACT_PARENT = 'Secondary Contact (Parent)',
  PURCHASER = 'Purchaser',
  PAYER = 'Payer',
}

export enum InternalContactType {
  ACCOUNT_MANAGER = 'Account Manager',
  COLLECTION_OWNER = 'Collection Owner',
  CS_MANAGER = 'Customer Success Manager',
}

export enum AccountContactType {
  COLLECTION_OWNER = 'Collection Owner',
}

export enum ContactRole {
  ACCOUNTS_REP = 'ACCOUNTS_REP',
  CFO = 'CFO',
  SALES_REP = 'SALES_REP',
  ACCOUNTS_MANAGER = 'ACCOUNTS_MANAGER',
  COLLECTIONS_SPECIALIST = 'COLLECTIONS_SPECIALIST',
}

export enum ParticipantRelationKeys {
  PARENT = 'PARENT',
  SELF = 'SELF',
}

export type ParticipantRelation = ParticipantRelationKeys.PARENT | ParticipantRelationKeys.SELF;
