import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from 'antd';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { Editor } from 'lib/RichText/type';

interface EditLinkProps {
  hide?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  editor: Editor;
}

const t = {
  tooltipText: 'Edit hyperlink ',
};

function EditLink(props: Readonly<EditLinkProps>) {
  if (props.hide) return null;

  const EditIcon = <FontAwesomeIcon icon={['far', 'pen']} color="--gray-9" />;

  function handleClick() {
    props.onVisibleChange && props.onVisibleChange(true);
    props.editor.commands.blur();
  }
  const Title = (
    <Texto breakWord={{ maxWidth: '65px', wordBreak: 'break-word' }} color="var(--gray-1)" as="div">
      {t.tooltipText}
    </Texto>
  );

  return (
    <Tooltip
      destroyTooltipOnHide={true}
      overlayStyle={{ padding: 0, width: '80px' }}
      title={Title}
      placement="bottomLeft"
    >
      <Button icon={EditIcon} type="text" onClick={handleClick} size="middle" />
    </Tooltip>
  );
}

export default EditLink;
