import { CancelButton, SaveButton } from '@sinecycle/growcomponents';
import { Space } from 'antd';
interface FormActionButtonsProps {
  onSuccess?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  saveDisabled?: boolean;
  cancelDisabled?: boolean;
  saveLoading?: boolean;
}

export function FormActionButtons(props: FormActionButtonsProps) {
  const { saveDisabled } = props;
  function onCancel() {
    props.onCancel && props.onCancel();
  }

  function onSuccess() {
    props.onSuccess && props.onSuccess();
  }

  const ActionButton = (
    <Space>
      <CancelButton onClick={onCancel} />
      <SaveButton
        type="default"
        onClick={onSuccess}
        disabled={saveDisabled}
        loading={props.saveLoading}
      />
    </Space>
  );
  return ActionButton;
}
