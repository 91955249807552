import { StatusCell } from 'components/Common/StatusCell/StatusCell';
import { PaymentStatus, paymentStatusConfig } from 'types/activities/payment';

export default function PaymentStatusTag({ value }: { value: PaymentStatus }) {
  if (!paymentStatusConfig[value]) {
    return null;
  }
  return (
    <StatusCell
      status={{
        mode: 'Tags',
        text: paymentStatusConfig[value].label,
        style: paymentStatusConfig[value].style,
      }}
    />
  );
}
