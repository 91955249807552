import { Empty } from 'antd';
import styled from 'styled-components';

export const PlaceholderComponentWrapper = styled.div`
  width: fit-content;
  box-shadow: var(--shadow-3);
  background: var(--gray-1);
  border-radius: var(--br-3);
  overflow: hidden;
  .ant-popover-inner {
    box-shadow: var(--shadow-3) !important;
    border-radius: var(--br-3) !important;
  }
`;

export const overrideStyles: React.CSSProperties = {
  overflow: 'auto',
  overflowX: 'hidden',
};

export const StyledEmptyAntComponent = styled(Empty)`
  &.ant-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: 300px;
    justify-content: center;
  }
`;
