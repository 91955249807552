import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { ChartTooltip } from 'components/Common/Charts/types';

import { GrowCurrencyText, GrowNumberFormat } from '@sinecycle/growcomponents';
import { getPercentage } from 'util/number-formatter';
import { InvoiceDisputeData } from '../type';
import { head } from 'lodash';

const t = {
  Customers: 'Customers',
  Receivables: 'Receivables',
  Invoices: 'Invoices',
};
interface InvoiceDisputesToolTipContent extends ChartTooltip {
  data: InvoiceDisputeData;
}
interface ToolTipProps {
  title: string;
  content: InvoiceDisputesToolTipContent[];
  locale?: string;
}
function ToolTip(props: ToolTipProps) {
  const content = head(props.content) as InvoiceDisputesToolTipContent;
  const percentage = getPercentage(content.data.value, content.data.totalAmount);
  const Receivables = (
    <Flex direction="column" gap="var(--space-2)">
      <Texto size="12" color="var(--gray-7)">
        {t.Receivables}
      </Texto>
      <GrowCurrencyText
        amount={content.data.valueObject.outstanding.amount.value}
        options={{
          currency: content.data.valueObject.outstanding.amount.currency,
          locale: props.locale,
        }}
      />
    </Flex>
  );
  const Customers = (
    <Flex direction="column" gap="var(--space-2)">
      <Texto size="12" color="var(--gray-7)">
        {t.Customers}
      </Texto>
      <GrowNumberFormat
        value={content.data.valueObject.outstanding.customer_count ?? 0}
        options={{ locale: props.locale }}
        textProps={{ fs: '12' }}
      />
    </Flex>
  );
  const Invoices = (
    <Flex direction="column" gap="var(--space-2)">
      <Texto size="12" color="var(--gray-7)">
        {t.Invoices}
      </Texto>
      <GrowNumberFormat
        value={content.data.valueObject.outstanding.invoice_count ?? 0}
        options={{ locale: props.locale }}
        textProps={{ fs: '12' }}
      />
    </Flex>
  );

  const ToolTipHeader = (
    <Flex align="center" gap="var(--space-4)">
      <Flex align="center" gap="var(--space-4)">
        <div style={{ background: content.color, width: '16px', height: '16px' }} />
        <Texto weight="semibold" breakWord={{ wordBreak: 'break-word', maxWidth: '110px' }}>
          {props.title}
        </Texto>
      </Flex>
      <Texto color="var(--gray-7)">{percentage}</Texto>
    </Flex>
  );

  const ToolTipBody = (
    <Flex direction="column" gap="var(--space-8)">
      {Receivables}
      {Customers}
      {Invoices}
    </Flex>
  );

  return (
    <Flex direction="column" gap="var(--space-8)" className="custom-tooltip">
      {ToolTipHeader}
      {ToolTipBody}
    </Flex>
  );
}

export default ToolTip;
