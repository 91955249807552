import { Col, Row, Skeleton } from 'antd';
import React from 'react';

type RowProps = React.ComponentProps<typeof Row>;
interface FiltersLoaderProps {
  loading: boolean;
  hide?: boolean;
  children: React.ReactNode;
  gutter?: RowProps['gutter'];
}
function FiltersLoader(props: Readonly<FiltersLoaderProps>) {
  if (props.hide) return null;
  const Loader = (
    <Row gutter={props.gutter}>
      <Col flex={48}>
        <Skeleton.Input active />
      </Col>
      <Col flex={144}>
        <Skeleton.Input active />
      </Col>
      <Col flex={240}>
        <Skeleton.Input active />
      </Col>
    </Row>
  );

  return <>{props.loading ? Loader : props.children}</>;
}

export default FiltersLoader;
