import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Popover, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import React from 'react';
import { cei_tile } from 'services/dashboard-datasets';
import { StyledTileCard } from '../styles';
import { dashboardTexts } from '../texts';
import {
  DangerText,
  SecondaryText,
  SectionTitle,
  SuccessText,
  TitleText,
  TooltipParagraph,
} from './style';

function CEITile() {
  return (
    <StyledTileCard>
      <Row align="middle" className="no-wrap">
        <Col span={24}>
          <Row align="middle">
            <Col style={{ marginRight: 'var(--space-8)' }}>
              <SectionTitle>
                {dashboardTexts.ceiTile.title}
                <Popover
                  title="CEI"
                  content={
                    <>
                      <SecondaryText>{dashboardTexts.ceiTile.helpTitle}</SecondaryText>
                      <TooltipParagraph>{dashboardTexts.ceiTile.helpText}</TooltipParagraph>
                    </>
                  }
                >
                  <FontAwesomeIcon
                    style={{ marginLeft: 'var(--space-8)' }}
                    icon={['far', 'question-circle']}
                  />
                </Popover>
              </SectionTitle>
            </Col>
          </Row>
          <Row align="middle">
            <Col style={{ paddingBottom: 2 }}>
              <TitleText
                style={{ fontWeight: 'var(--fs-semibold)', marginTop: 'var(--space-8)' }}
                className="text-16"
              >
                {cei_tile.cei}%
              </TitleText>
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: 'var(--space-4)' }}>
            <Flex direction="column" style={{ marginTop: 'var(--space-8)' }}>
              {cei_tile.cei_change >= 0 ? (
                <DangerText>
                  <FontAwesomeIcon
                    icon={['far', 'arrow-down']}
                    color="var(--red-5)"
                    style={{ marginRight: 'var(--space-4)' }}
                  />
                  {dashboardTexts.ceiTile.daysCount(cei_tile.cei_change)}
                </DangerText>
              ) : (
                <SuccessText>
                  <FontAwesomeIcon
                    icon={['far', 'arrow-up']}
                    color="var(--green-7)"
                    style={{ marginRight: 'var(--space-4)' }}
                  />
                  {dashboardTexts.ceiTile.daysCount(cei_tile.cei_change)}
                </SuccessText>
              )}
              <GrowText size="var(--fs-12)">
                {dashboardTexts.ceiTile.sinceLast(cei_tile.cei_since)}
              </GrowText>
            </Flex>
          </Row>
        </Col>
      </Row>
    </StyledTileCard>
  );
}

export default React.memo(CEITile);
