import { Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { produce } from 'immer';
import { StyledCustomerTag } from './style';

interface CustomerTagProps {
  tags: string[];
  currentTagStyle?: React.CSSProperties;
  showAll?: boolean;
}

export const CustomerTags = (props: Readonly<CustomerTagProps>) => {
  if (!props.tags) return null;

  function getPopoverTags() {
    return produce(props.tags, (draft) => {
      draft.pop();
      return draft;
    });
  }
  function getCurrentTag() {
    const modifiedArray = produce(props.tags, (draft) => {
      return draft.slice(-1);
    });
    return modifiedArray.toLocaleString();
  }

  const popOverTags = getPopoverTags();

  const showTags = getCurrentTag();

  const Content = (
    <Flex align="center" wrap="wrap" gap="var(--space-8)">
      {popOverTags.map((f) => {
        return <StyledCustomerTag key={f}>{f}</StyledCustomerTag>;
      })}
    </Flex>
  );

  const MainTag = (
    <StyledCustomerTag style={props.currentTagStyle}>
      <GrowText style={{ maxWidth: 'inherit', color: 'inherit' }} ellipsis={{ tooltip: showTags }}>
        {showTags}
      </GrowText>
    </StyledCustomerTag>
  );

  const ToolTipTags = (
    <Popover
      content={Content}
      placement="right"
      trigger="hover"
      overlayInnerStyle={{ maxWidth: '250px' }}
      arrow={false}
    >
      <StyledCustomerTag>{`+${popOverTags.length}`}</StyledCustomerTag>
    </Popover>
  );

  const CustomerTags = (
    <Flex gap="var(--space-4)" style={{ width: 'max-content', height: '22px' }}>
      {Boolean(showTags?.length) && <Flex.Child>{MainTag}</Flex.Child>}
      {Boolean(popOverTags?.length) && <Flex.Child>{ToolTipTags}</Flex.Child>}
    </Flex>
  );

  const AllTags = (
    <Flex align="center" gap="var(--space-4)">
      {props.tags.map((tag) => {
        return <StyledCustomerTag key={tag}>{tag}</StyledCustomerTag>;
      })}
    </Flex>
  );
  return props.showAll ? AllTags : CustomerTags;
};
