import { KyResponse } from 'ky';
import { User } from 'types/entities/user';
import { IPrivileges } from '../store/privilges/type';
import { Roles } from '../store/roles/types';
import ky from './ky';

export interface IPrivilegeParam {
  id: number;
  privileges: IPrivileges[];
  name: string;
  description: string;
}

const baseUrl = 'roles';

export async function getRolesForAccount() {
  return (await ky.get(`${baseUrl}`).json()) as Roles[];
}

export async function getUsersForRoles(id: number) {
  return (await ky.get(`${baseUrl}/${id}/users`).json()) as User[];
}

export async function getPrivilegesForRoles(id: number) {
  return (await ky.get(`${baseUrl}/${id}/privileges`).json()) as IPrivileges[];
}

// if the backend response does not return a json data, DO NOT chain the `.json()` method at the end
export async function putPrivilegesForRoles(roleId: number, data: IPrivilegeParam) {
  return await ky.put(`${baseUrl}/${roleId}`, { json: data });
}

export const deleteUsersFromRoles = async (userId: number, roleId: number): Promise<KyResponse> => {
  return await ky.delete(`${baseUrl}/${roleId}/users/${userId}`);
};
