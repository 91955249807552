import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssignedToUsers } from 'store/activity-feed/type';
import { BaseRecepientsType } from 'types/entities/collection-strategy/contact-type';
import { ContactType } from 'types/entities/contact';

interface AddCustomRecipientEmailListItemProps {
  onClick: (option: AssignedToUsers) => void;
  addCustomEmailButtonVisible: boolean;
  option: string | null;
}

export function AddCustomRecipientEmailListItem(props: AddCustomRecipientEmailListItemProps) {
  const { addCustomEmailButtonVisible, onClick, option } = props;
  return (
    <div
      style={{ color: 'var(--blue-6)', padding: '0 var(--space-12)' }}
      className="add-email-item cursor-pointer"
      onClick={() => {
        return onClick({
          type: BaseRecepientsType.USER,
          email: option ?? undefined,
          id: undefined,
          label: option ?? '',
          contact_type: ContactType.OTHER_CONTACT,
        });
      }}
    >
      {addCustomEmailButtonVisible && (
        <>
          <FontAwesomeIcon icon={['far', 'plus-circle']} />
          &nbsp;&nbsp;{option}
        </>
      )}
    </div>
  );
}
