import ActivitiesModalLoader from 'components/CollectionActivities/Common/ActivitiesModalLoader';
import { DetailsLayout } from 'components/CollectionActivities/Common/Details/DetailsLayout';
import useEmitActivityEvents from 'components/CollectionActivities/hooks/useEmitActivityEvents';
import { ActivityLoader } from 'components/CollectionActivities/style';
import { useCollectionActivityQuery } from 'queries/activites';
import { ActivityDetailsBaseProps } from 'types/activities/activity-types';
import { Note } from 'types/activities/note';
import { NoteDetailsBody } from './NoteDetailsBody';
import { NoteDetailsInfo } from './NoteDetailsInfo';

interface NoteDetailsProps extends ActivityDetailsBaseProps {}
function NoteDetails(props: NoteDetailsProps) {
  const {
    id,
    customer,
    type,
    hideActions,
    mode,
    subscriptionType,
    isListFetched,
    onDelete,
    editLoading,
    deleteLoading,
    activitiesMode,
  } = props;
  const { data, isLoading, error } = useCollectionActivityQuery<Note>({ type, id, isListFetched });
  const isUnauthorized = error ? error?.status === 403 : false;
  useEmitActivityEvents(isLoading);
  if (activitiesMode && isLoading) {
    return (
      <ActivityLoader>
        <ActivitiesModalLoader />
      </ActivityLoader>
    );
  }

  return (
    <DetailsLayout
      mode={mode}
      isUnauthorized={isUnauthorized}
      content={
        <NoteDetailsBody
          data={data}
          isLoading={isLoading}
          hideActions={hideActions}
          customer={customer}
          subscriptionType={subscriptionType}
          onDelete={onDelete}
          editLoading={editLoading}
          deleteLoading={deleteLoading}
          activitiesMode={activitiesMode}
        />
      }
      info={<NoteDetailsInfo loading={isLoading} data={data} customer={customer} />}
    />
  );
}

export { NoteDetails };
