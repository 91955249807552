import { CancelButton, SaveButton } from '@sinecycle/growcomponents';
import { Form, Item } from 'components/BaseComponents/Form';
import { useForm, useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { RadioGroup } from 'components/BaseComponents/Radio';
import { GrowText } from 'components/BaseComponents/Typography';
import dayjs from 'dayjs';
import { styled } from 'lib/css-in-js';
import { useMemo } from 'react';
import {
  ExchangeRate,
  ExchangeRateData,
  exchangeRateSource,
} from 'types/api/currency-page-settings';
import { CurrencyConfigProps, CurrencySettingPatchProps } from '../../../type';
import { useSelectedCurrencies } from '../../CurrencyContextApi/ContextApi';
import { useCurrencyExchangeConfigQuery } from '../../Hooks/CurrencyHooks';
import CurrentPeriodFormData from './CurrentPeriod/CurrentPeriodFormData';

const t = {
  growfinRate: 'Growfin’s rate',
  growfinRateDescription:
    'Exchange rate of all transactions will be automatically set by Growfin for all historical and future transactions.',
  erpRate: 'ERP’s rate',
  erpDescription:
    'The converted rate present in the source of ERP will be used by Growfin as well for all historical and future transactions.',
  customRate: 'Custom rate',
  customDescription:
    'The exchange rate for every time period would be set manually by the user which will be set by Growfin for all historical and future transactions.',
  CustomRateSucessMoreDetail: {
    firstHalf: 'Custom currency exchange rate for the period between',
    secondHalf: 'is set successfully',
  },
  warningMsg: 'Please submit the form...',
  OrganisationID: 'Organisation ID',
};

const StyledForm = styled(Form)`
  display: flex;
  gap: var(--space-16);
  flex-direction: column;

  .ant-form-item {
    margin-bottom: var(--space-16);
  }

  .ant-col-offset-8 {
    margin-left: 0 !important;
  }
`;

function DifferentRateOption(props: CurrencyConfigProps) {
  const { tabData, label, baseCurrency } = props;
  const shouldDisplayOrgIdRep = label?.includes('#');

  const {
    selectedCurrenciesWithValues,
    unsavedChanges,
    setSelectedCurrenciesWithValues,
    setUnsavedChanges,
  } = useSelectedCurrencies();
  const patchCurrencyConfig = useCurrencyExchangeConfigQuery(tabData.id);

  const [form] = useForm();
  const ratesUseWatch = useFormWatch('rates', form);

  const rateOptions = [
    {
      value: exchangeRateSource.GROWFIN,
      label: (
        <Flex direction="column" gap="var(--space-4)">
          <GrowText
            size="var(--fs-14)"
            weight={ratesUseWatch === exchangeRateSource.GROWFIN ? 'bold' : 'regular'}
          >
            {t.growfinRate}
          </GrowText>
          <GrowText
            size="var(--fs-14)"
            color={ratesUseWatch === exchangeRateSource.GROWFIN ? 'var(--gray-9)' : 'var(--gray-8)'}
          >
            {t.growfinRateDescription}
          </GrowText>
        </Flex>
      ),
    },
    {
      value: exchangeRateSource.ERP,
      label: (
        <Flex direction="column" gap="var(--space-4)">
          <GrowText
            size="var(--fs-14)"
            weight={ratesUseWatch === exchangeRateSource.ERP ? 'bold' : 'regular'}
          >
            {t.erpRate}
          </GrowText>
          <GrowText
            size="var(--fs-14)"
            color={ratesUseWatch === exchangeRateSource.ERP ? 'var(--gray-9)' : 'var(--gray-8)'}
          >
            {t.erpDescription}
          </GrowText>
        </Flex>
      ),
    },
    {
      value: exchangeRateSource.STATIC,
      label: (
        <Flex direction="column" gap="var(--space-4)">
          <GrowText
            size="var(--fs-14)"
            weight={ratesUseWatch === exchangeRateSource.STATIC ? 'bold' : 'regular'}
          >
            {t.customRate}
          </GrowText>
          <GrowText
            size="var(--fs-14)"
            color={ratesUseWatch === exchangeRateSource.STATIC ? 'var(--gray-9)' : 'var(--gray-8)'}
          >
            {t.customDescription}
          </GrowText>
        </Flex>
      ),
    },
  ];

  const save = (
    <Item wrapperCol={{ offset: 8, span: 16 }} style={{ margin: '0' }}>
      <SaveButton htmlType="submit" disabled={!unsavedChanges} />
    </Item>
  );

  const cancel = (
    <Item wrapperCol={{ offset: 8, span: 16 }} style={{ margin: '0' }}>
      <CancelButton htmlType="reset" disabled={!unsavedChanges} />
    </Item>
  );

  const orgIdRep = (
    <Flex
      direction="column"
      style={{ paddingBottom: 'var(--space-16)', paddingTop: 'var(--space-16)' }}
      gap="var(--space-4)"
    >
      <GrowText size="var(--fs-12)">{t.OrganisationID}</GrowText>
      <GrowText size="var(--fs-14)" weight="var(--fs-semibold)">
        {tabData?.org_id}
      </GrowText>
    </Flex>
  );

  const exchangeRateData: ExchangeRateData[] = useMemo(
    () =>
      selectedCurrenciesWithValues.map((currency) => ({
        source: currency.source,
        destination: currency.destination,
        exchange_rate: currency.exchangeRate,
      })),
    [selectedCurrenciesWithValues]
  );

  const onFinish = () => {
    const formValues = form.getFieldsValue();
    const rates: string = formValues.rates;
    let exchange_rate_config: ExchangeRate[] = [];

    if (rates !== exchangeRateSource.ERP && rates !== exchangeRateSource.GROWFIN) {
      const dateRange = formValues.dateRange;
      const [startDate, endDate] = dateRange;
      const stringifiedStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const stringifiedEndDate = dayjs(endDate).format('YYYY-MM-DD');

      exchange_rate_config = [
        {
          start_date: stringifiedStartDate,
          end_date: stringifiedEndDate,
          exchange_rate_data: exchangeRateData,
        },
      ];
    }

    const patchDataData: CurrencySettingPatchProps = {
      exchange_rate_source: rates,
      exchange_rate_config,
    };

    patchCurrencyConfig.mutateAsync(patchDataData);
  };

  const onReset = () => {
    form.resetFields();
    setUnsavedChanges(false);
    setSelectedCurrenciesWithValues([]);
  };

  const onValuesChange = () => {
    setUnsavedChanges(true);
  };

  return (
    <>
      {shouldDisplayOrgIdRep ? orgIdRep : null}
      <StyledForm form={form} onFinish={onFinish} onReset={onReset} onValuesChange={onValuesChange}>
        <Flex
          style={{ backgroundColor: 'var(--gray-2)', padding: 'var(--space-20)' }}
          direction="column"
        >
          <Item name={`rates`} initialValue={tabData.exchange_rate_source}>
            <RadioGroup
              options={rateOptions}
              style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space-16)' }}
            />
          </Item>
          <Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}>
            {({ getFieldValue }) =>
              getFieldValue(`rates`) === exchangeRateSource.STATIC ? (
                <CurrentPeriodFormData tabData={tabData} baseCurrency={baseCurrency} />
              ) : null
            }
          </Item>
        </Flex>
        <Flex gap="var(--space-12)">
          {save}
          {cancel}
        </Flex>
      </StyledForm>
    </>
  );
}

export default DifferentRateOption;
