import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowCheckBox } from '@sinecycle/growcomponents';
import { Card, Checkbox, Progress, Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { StyledIconButton } from 'components/Common/Styles/Styles';
import { capitalize } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import { ProgressStatus } from '../../type';

type CheckBoxProps = React.ComponentProps<typeof Checkbox>;
type ChangeEvent = Parameters<NonNullable<CheckBoxProps['onChange']>>['0'];

interface ItemContentProps {
  hide?: boolean;
  filename: string;
  icon: React.ReactNode;
  fileSize?: string;
  extraComp?: React.ReactNode;
  onRemove?: () => void;
  onPreview?: () => void;
  removeIcon?: React.ReactNode;
  onChange?: (status: boolean) => void;
  hideActions: boolean;

  filesCount?: string;
  enabled?: boolean;
  progress?: {
    percent: number | undefined;
    status: ProgressStatus;
  };
}
const t = {
  tooltipText: {
    exclude: 'Exclude File',
    include: 'Include File',
  },
  files: 'files',
  errorText: 'File size is too large',
};
const StyledCard = styled(Card)`
  border-color: var(--gray-1);
  border-radius: var(--br-1);
  .ant-card-body {
    padding: var(--space-16) var(--space-20);
    min-height: 70px;
    height: 100%;
    display: flex;
  }

  &.no-size {
    .ant-card-body {
      padding: var(--space-12);
      height: 100%;
    }
  }

  .ant-card-meta-avatar {
    visibility: hidden;
  }

  &:hover {
    border-color: var(--primary-4);

    .ant-card-meta-avatar {
      visibility: visible;
    }
  }
`;

const StyledProgress = styled(Progress)`
  width: 300px;
  &.hide {
    display: none;
  }
  &.show {
    display: block;
  }
`;
const RemoveIcon = <FontAwesomeIcon icon={['far', 'trash-alt']} color="var(--primary-7)" />;

const StyledMeta = styled(Card.Meta)`
  width: 100%;
  .ant-card-meta-avatar {
    order: 2;
  }
  .ant-card-meta-title {
    margin: 0 !important;
  }

  .ant-card-meta-description {
    margin-left: var(--space-24);
  }

  &.is-desc {
    .ant-card-meta-title {
      height: 100%;
    }
    .ant-card-meta-description {
      display: none;
      height: 0;
    }
  }
`;
function ItemContent(props: Readonly<ItemContentProps>) {
  const [checked, setChecked] = useState(initializeChecked);
  const isDesc = props.filesCount || props.fileSize;

  if (props.hide) return null;

  function initializeChecked() {
    return props.enabled;
  }
  function handleChange(event: ChangeEvent) {
    setChecked(event.target.checked);
    props.onChange && props.onChange(event.target.checked);
  }

  function handleRemove() {
    props.onRemove && props.onRemove();
  }

  const FileName = (
    <Texto
      weight="semibold"
      color="var(--purple-7)"
      truncate="180px"
      style={{ textTransform: 'capitalize' }}
    >
      {capitalize(props.filename)}
    </Texto>
  );
  const FileSize = (
    <Texto size="12" color="var(--gray-7)">
      {props.fileSize ?? null}
    </Texto>
  );
  const FileCount = (
    <Texto size="12" color="var(--gray-7)">
      {props.filesCount}&nbsp;{t.files}
    </Texto>
  );
  const ProgressBar = (
    <StyledProgress
      percent={props.progress?.percent}
      size="small"
      showInfo={true}
      status={props.progress?.status}
      strokeColor="var(--purple-7)"
    />
  );

  const FileSizeAndCount = (
    <Flex align="center" justify="space-between" gap="var(--space-12)">
      {props.filesCount && FileCount}
      {FileSize}
    </Flex>
  );

  const UploadErrorText = (
    <Texto color="var(--red-7)" size="12">
      {t.errorText}
    </Texto>
  );

  const ProgressInfo = (
    <Flex direction="column">
      {!props.hideActions && ProgressBar}
      {props.progress?.status === 'exception' ? UploadErrorText : null}
    </Flex>
  );

  const FileInfo = <>{FileName}</>;
  const FileDesc = (
    <Flex direction="column" gap="var(--space-8)" align="flex-start">
      {ProgressInfo}
      {FileSizeAndCount}
    </Flex>
  );

  const RemoveItem = (
    <StyledIconButton
      icon={props.removeIcon ? props.removeIcon : RemoveIcon}
      className={props.progress?.status === 'exception' ? '' : 'icon'}
      onClick={handleRemove}
      style={{ width: '7px' }}
    />
  );
  const FileIcon = props.icon;
  const ToolTipTitle =
    props.hideActions && checked
      ? t.tooltipText.exclude
      : props.hideActions && !checked
      ? t.tooltipText.include
      : undefined;

  const File = (
    <Tooltip trigger={['hover']} title={ToolTipTitle} zIndex={1400}>
      <Flex align="flex-start">
        <GrowCheckBox
          onChange={handleChange}
          disabled={!props.hideActions}
          checked={props.hideActions ? checked : true}
          design="border-design"
        >
          <Flex direction="column" gap="var(--space-8)">
            <Flex gap="var(--space-8)" align="flex-start">
              {FileIcon}
              {FileInfo}
            </Flex>
          </Flex>
        </GrowCheckBox>
      </Flex>
    </Tooltip>
  );

  const Title = (
    <Flex
      justify="space-between"
      align="center"
      style={{ width: '100%', height: isDesc ? 'auto' : '100%' }}
    >
      {File}
      {props.extraComp}
    </Flex>
  );

  const Info = (
    <StyledMeta
      title={Title}
      description={FileDesc}
      avatar={!props.hideActions && RemoveItem}
      className={isDesc ? undefined : 'is-desc'}
    />
  );
  return <StyledCard className={props.fileSize ? '' : 'no-size'}>{Info}</StyledCard>;
}

export default ItemContent;
