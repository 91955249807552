import { TooltipPlacement } from 'antd/es/tooltip';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { RTE } from 'components/BaseComponents/RTE';
import { InsertProps } from 'components/Insert';
import { useRichTextEditor } from 'lib/RichText/TipTapEditor/hooks/useRichTextEditor';
import { EmailTemplates } from 'types/entities/email-templates';
import { InsertArea, PopoverAlign, useEmailPlaceholders } from '../hooks/useEmailPlaceholders';

interface RichEmailContentEditorProps extends Omit<InsertProps, 'onInsert' | 'cancel'> {
  placeholder?: string;
  onChange: (body: string | null) => void;
  value?: string | null;
  signature?: string | null;
  popoverPlacement?: TooltipPlacement;
  popoverAlign?: PopoverAlign;
  area?: InsertArea;
}

export default function RichEmailContentEditor(props: RichEmailContentEditorProps) {
  const {
    validFolders,
    folderType,
    placeHoldersWrapperOverrides,
    signature,
    popoverPlacement,
    area,
  } = props;
  const { editor, replaceText, insertText } = useRichTextEditor({
    placeholder: props.placeholder,
    onChange: props.onChange,
    value: props.value,
  });

  const [PlaceholderButtonJSX, insertOpen] = useEmailPlaceholders({
    onTemplateSelect,
    validFolders,
    folderType,
    placeHoldersWrapperOverrides,
    onPlaceholderInsert,
    placement: popoverPlacement,
    area,
  });

  function onTemplateSelect(selectedTemplate: EmailTemplates) {
    replaceText(selectedTemplate.body);
    props.onTemplateSelect && props.onTemplateSelect(selectedTemplate);
  }

  function onPlaceholderInsert(placeholderString: string) {
    insertText(`<span class="grow-email-placeholder">{{${placeholderString}}}</span>`);
  }

  const InsertButton = (
    <Flex className="insert-button-container" align="center">
      {PlaceholderButtonJSX}
    </Flex>
  );

  return (
    <RTE
      wrapperClassname={insertOpen ? 'editor-focussed' : ''}
      editor={editor}
      signature={signature}
      extras={InsertButton}
    />
  );
}
