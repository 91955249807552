import { EventPayloadType } from 'events/type';
import { tracker } from 'lib/tracker';
import { debounce } from 'lodash';
import mitt from 'mitt';

const mxpEmitter = mitt();
const genericEmitter = mitt();

const getPathName = () => window.location.pathname;
const getSubdomain = () => window.location.hostname.split('.')[0];

export const dispatchAppEvent = (
  { type, payload }: EventPayloadType,
  options = { useMixp: true }
) => {
  const pathName = getPathName();
  const accountName = getSubdomain();

  if (options.useMixp) {
    mxpEmitter.emit(type, { ...payload, pathName, accountName });
  } else {
    genericEmitter.emit(type, { ...payload, pathName, accountName });
  }
};

mxpEmitter.on('*', (type, attrs) => {
  tracker.track(type.toString(), attrs);
});

export function getDebouncedAppEventDispatcher(dispatchEventArgs: EventPayloadType) {
  return debounce(() => {
    dispatchAppEvent(dispatchEventArgs);
  }, 1000);
}
