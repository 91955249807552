import { FilterGroup } from 'components/Common/FilterComponents/types';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { AccountConfigKey } from 'types/entities/account';
import { FilterKeys } from './reducers/type';

export const invoiceFilterList: FilterGroup['filterList'] = [
  {
    id: FilterKeys.INVOICE_STATUS,
    label: 'Invoice Status',
    active: false,
    removable: false,
    filterValueKeys: ['status'],
  },
  {
    id: FilterKeys.DUE_DATE,
    label: 'Due Date',
    active: false,
    removable: false,
    filterValueKeys: ['due_date_from', 'due_date_to', 'due_date_range'],
  },
  {
    id: FilterKeys.TOTAL_DUE_AMOUNT,
    label: 'Due Amount',
    active: false,
    removable: true,
    filterValueKeys: ['amount_min', 'amount_max'],
  },
  {
    id: FilterKeys.FOLLOWUP_STATUS,
    label: 'Followup Status',
    active: false,
    removable: true,
    filterValueKeys: ['followup_status'],
  },
  {
    id: FilterKeys.ISSUED_DATE,
    label: 'Issued date Range',
    active: false,
    removable: true,
    filterValueKeys: ['issue_date_from', 'issue_date_to', 'issue_date_range'],
  },
  {
    id: FilterKeys.LAST_ACTIVITY,
    label: 'Last Activity on',
    active: false,
    removable: true,
    filterValueKeys: ['last_activity_from', 'last_activity_to', 'last_activity_range'],
  },
  {
    id: FilterKeys.BILLING_CONTACT,
    label: 'Billing Contact',
    active: false,
    removable: true,
    filterValueKeys: ['contact_ids', 'is_billing_contact_assigned'],
  },
  // {
  //   id: FilterKeys.NEXT_FOLLOWUP,
  //   label: 'Next Followup Task on',
  //   active: false,
  //   removable: true,
  //   filterValueKeys: ['next_followup_from', 'next_followup_to', 'next_followup_range'],
  // },
  {
    id: FilterKeys.PROMISE_TO_PAY,
    label: 'Promise to Pay Date Range',
    active: false,
    removable: true,
    filterValueKeys: ['promise_pay_from', 'promise_pay_to', 'promise_pay_range'],
  },
  {
    id: FilterKeys.DISPUTE_TYPE,
    label: 'Dispute Type',
    active: false,
    removable: true,
    filterValueKeys: ['dispute_type_ids'],
  },
  {
    id: FilterKeys.COLLECTION_STRATEGIES,
    label: 'Collection Strategy',
    active: false,
    removable: true,
    filterValueKeys: ['collection_strategy_ids', 'is_collection_strategy_not_assigned'],
  },
  {
    id: FilterKeys.COLLECTION_STRATEGY_STATUSES,
    label: 'Strategy Status',
    active: false,
    removable: true,
    filterValueKeys: ['invoice_followup_statuses'],
  },
  {
    id: FilterKeys.COLLECTION_ACTIVITY,
    label: 'Collection Activity',
    active: false,
    removable: true,
    filterValueKeys: ['collection_activity'],
  },
  {
    id: FilterKeys.COLLECTION_PERIODS,
    label: 'Collection Periods',
    active: false,
    removable: true,
    filterValueKeys: ['collection_period_ids'],
  },
  {
    id: FilterKeys.EMAIL_DELIVERY_STATUS,
    label: 'Invoice Delivery Status',
    active: false,
    removable: true,
    filterValueKeys: ['email_delivery_status'],
  },
];

export function isStrategyFilter(filterId: string) {
  return [FilterKeys.COLLECTION_STRATEGIES, FilterKeys.COLLECTION_STRATEGY_STATUSES].includes(
    filterId as FilterKeys
  );
}

export function isCollectionPeriodEnabled(filterId: string) {
  return [FilterKeys.COLLECTION_PERIODS].includes(filterId as FilterKeys);
}
export function isEmailDeliveryEnabled(filterId: string) {
  return [FilterKeys.EMAIL_DELIVERY_STATUS].includes(filterId as FilterKeys);
}

const customerFilterList: FilterGroup['filterList'] = [
  {
    id: FilterKeys.TAGS,
    label: 'Tags',
    active: false,
    removable: true,
    filterValueKeys: ['tag_ids'],
  },
  {
    id: FilterKeys.BUSINESS_UNIT,
    label: 'Business Unit',
    active: false,
    removable: true,
    filterValueKeys: ['business_unit_ids', 'is_business_unit_not_assigned'],
  },
  {
    id: FilterKeys.REGION,
    label: 'Region',
    active: false,
    removable: true,
    filterValueKeys: ['region_ids', 'is_region_not_assigned'],
  },
  /* 
  commenting for future use
{
    id: FilterKeys.SUBSIDIARY,
    label: 'Subsidiary',
    active: false,
    removable: true,
    filterValueKeys: ['subsidiary_ids', 'is_subsidiary_not_assigned'],
  }, */
  {
    id: FilterKeys.COLLECTION_STATUS,
    label: 'Collection Status',
    active: false,
    removable: true,
    filterValueKeys: ['collection_status_ids', 'is_collection_status_not_assigned'],
  },
  {
    id: FilterKeys.COLLECTION_MANAGER_IDS,
    label: 'Collection Manager',
    active: false,
    removable: true,
    filterValueKeys: ['collection_manager_ids'],
  },
  {
    id: FilterKeys.SALES_MANAGER_IDS,
    label: 'AM/CSM Team Lead',
    active: false,
    removable: true,
    filterValueKeys: ['sales_manager_ids'],
  },
  {
    id: FilterKeys.CUSTOMER_DROPDOWN,
    label: 'Customer Name',
    active: true,
    removable: true,
    filterValueKeys: ['customer_ids'],
  },
];

export function useCustomerFilterList() {
  const isSubsidiaryEnabled = useConfig(AccountConfigKey.SUBSIDIARY_ENABLED);
  const blackListedColumns: (string | undefined)[] = [];

  if (!isSubsidiaryEnabled) {
    blackListedColumns.push(FilterKeys.SUBSIDIARY);
  }

  return customerFilterList.filter((column) => !blackListedColumns.includes(column.id));
}
