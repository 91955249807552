import { Filter, FilterGroup } from 'components/Common/FilterComponents/types';
import produce from 'immer';
import { replaceInArrayInPlace, replaceOrPushInArray } from 'util/array';

export const initialFilterList: FilterGroup[] = [
  {
    id: 'default',
    label: 'Default',
    filterList: [
      {
        id: 'business_units',
        label: 'Business Units',
        active: true,
        removable: false,
      },
      {
        id: 'regions',
        label: 'Regions',
        active: true,
        removable: false,
      },
      {
        id: 'subsidaries',
        label: 'Subsidaries',
        active: true,
        removable: false,
      },
    ],
  },
];

type FilterReducerAction =
  | { type: 'ADD_GROUP'; payload: { filterGroup: FilterGroup } }
  | { type: 'ADD_FILTER'; payload: AddFilterPayload }
  | { type: 'UPDATE_FILTER'; payload: UpdateFilterPayload };

type UpdateFilterPayload = {
  groupId: string;
  filterId: string;
  updatedValues: Partial<Filter>;
};

type AddFilterPayload = {
  groupId: string;
  filter: Filter;
};

export function filterReducer(filterGroups: FilterGroup[], action: FilterReducerAction) {
  function addGroup(filterGroup: FilterGroup) {
    const predicate = (filterGroupInArray: FilterGroup) => filterGroupInArray.id === filterGroup.id;
    return produce(filterGroups, (draft) => {
      return replaceOrPushInArray(draft, filterGroup, predicate);
    });
  }

  function addFilter({ groupId, filter }: AddFilterPayload) {
    return produce(filterGroups, (draft) => {
      const filterGroup = draft.find((filterGroup) => filterGroup.id === groupId);

      if (filterGroup) filterGroup.filterList.push(filter);
    });
  }

  function updateFilter({ groupId, filterId, updatedValues }: UpdateFilterPayload) {
    return produce(filterGroups, (draft) => {
      const filterGroup = draft.find((filterGroup) => filterGroup.id === groupId);

      if (!filterGroup?.filterList) return;

      const filterToUpdate = filterGroup.filterList.find((filter) => filter.id === filterId);

      if (!filterToUpdate) return;

      const replacePredicate = (filter: Filter) => filter.id === filterId;
      const updatedFilter: Filter = { ...filterToUpdate, ...updatedValues };
      replaceInArrayInPlace(filterGroup.filterList, updatedFilter, replacePredicate);
    });
  }

  switch (action.type) {
    case 'ADD_GROUP':
      return addGroup(action.payload.filterGroup);
    case 'ADD_FILTER':
      return addFilter(action.payload);
    case 'UPDATE_FILTER':
      return updateFilter(action.payload);
  }
}
