import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { Commento } from 'types/activities/comment';

type OnCommentAdded = (updatedCommentList: Commento[], newComment: Commento) => void;

export function useComments(initialComments: Commento[]) {
  const [comments, setComments] = useState(initialComments);

  useEffect(() => {
    setComments((initialComments) =>
      isEqual(initialComments, comments) ? initialComments : comments
    );
  }, [comments]);

  const addNewComment = useCallback(
    (newComment: Commento, onCommentAdded?: OnCommentAdded) => {
      const updatedCommentList = [newComment, ...comments];
      setComments(updatedCommentList);
      onCommentAdded && onCommentAdded(updatedCommentList, newComment);
    },
    [comments]
  );

  return { comments, addNewComment };
}
