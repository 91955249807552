import { useMutation, useQuery } from '@tanstack/react-query';
import { defaultErrorNotification, notify } from 'components/BaseComponents/Notifications';
import { assign, defaultTo, map, omit } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  fetchAccountDetails,
  getCountries,
  getLocale,
  postAccountDetails,
  updateAccountLogo,
} from 'services/accounts';
import { changePassword, gmsSync } from 'services/authentication';
import { IPrivilegeParam, putPrivilegesForRoles } from 'services/roles';
import { setAccountGeneralInformationHandler } from 'store/authentication/authentication';
import { Account } from 'types/entities/account';

export interface ChangePasswordProps {
  old_password: string;
  new_password: string;
  email: string;
}

export interface LocaleOptions {
  locale: string;
  display_name: string;
}
export function useUploadAccountLogo() {
  return useMutation({
    mutationFn: updateAccountLogo,
    onError: (e) => console.log(e),
  }).mutateAsync;
}

export function useGetAccountsData(isCurrentUserSucccess?: boolean) {
  const dispatch = useDispatch();

  const accountDetails: Account = {} as Account;
  return useQuery({
    queryKey: ['accounts-data'],
    queryFn: () => fetchAccountDetails(),
    enabled: Boolean(isCurrentUserSucccess),
    onSuccess: (data) => {
      assign(accountDetails, omit(data, 'ar_email_users'));
      accountDetails.ar_email_user_ids = map(defaultTo(data.ar_email_users, []), 'id');
      return dispatch(setAccountGeneralInformationHandler(accountDetails));
    },
  });
}

export function usePostAccountDetails() {
  const accountDetails: Account = {} as Account;
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (postData: Account) => postAccountDetails(postData),
    mutationKey: ['update-accounts-data'],
    onSuccess: (data) => {
      assign(accountDetails, omit(data, 'ar_email_users'));
      accountDetails.ar_email_user_ids = map(defaultTo(data.ar_email_users, []), 'id');
      notify.success('Changes Saved!');
      return dispatch(setAccountGeneralInformationHandler(accountDetails));
    },
    onError: () => {
      defaultErrorNotification();
    },
  });
}

export function useChangePasswordMutation() {
  return useMutation({
    mutationFn: (props: ChangePasswordProps) => changePassword(props),
  });
}

export function useGMSsync() {
  return useMutation({
    mutationFn: (isArEmail: boolean) => gmsSync(isArEmail),
  });
}

export function useGetCountriesList() {
  return useQuery({
    queryKey: ['countries-list'],
    queryFn: () => getCountries(),
    cacheTime: 0,
    staleTime: 0,
  });
}

export function useGetLocaleList() {
  return useQuery({
    queryKey: ['locale-list'],
    queryFn: () => getLocale(),
    cacheTime: 0,
    staleTime: 0,
  });
}

export function useUpdatePrivilegesForRolesMutation() {
  return useMutation({
    mutationFn: ({ id, params }: { id: number; params: IPrivilegeParam }) =>
      putPrivilegesForRoles(id, params),
    retry: false,
  });
}
