import { Typography } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;

const Texty = styled(Paragraph)<{ size?: string; weight?: string; color?: string }>`
  font-size: ${(props) => props.size ?? 'var(--fs-14)'};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
`;

export { Texty as GrowParagraph };
