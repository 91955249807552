import { Plot } from '@ant-design/plots';
import { FunnelOptions } from '@antv/g2plot';
import { Spin } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import EmptyChart, { getIsEmpty } from 'components/Common/Charts/EmptyChart';
import FunnelChart from 'components/Common/Charts/Funnel';
import { FunnelConfigProps } from 'components/Common/Charts/types';
import Legend from 'components/Common/Legend';
import { FollowupActionLabels } from 'constants/collection-activities/followup-statuses';
import { groupBy, sum } from 'lodash';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';
import { FollowupActivity } from 'types/activities/activity-types';
import {
  ActivitiesToShow,
  ExtraFollowupActivityShowRank,
  FollowupActivityLegendColor,
  FollowupActivityShowRank,
} from 'types/api/admin-dashboard';
import { OrderByRank } from 'util/customOrderby';
import ExtraFollowUpActivities from './ExtraComponent';
import ToolTip from './ToolTip';
import { FollowupActivitiesData } from './type';
const activitiesToShow = [
  FollowupActivity.ASSIGNED,
  FollowupActivity.FOLLOWED_UP,
  FollowupActivity.VIEWED,
  FollowupActivity.RESPONDED,
  FollowupActivity.OPEN_INVOICE_BALANCE,
];
const extraActivitiesToShow = [
  FollowupActivity.NOT_RESPONDED,
  FollowupActivity.NOT_VIEWED,
  FollowupActivity.NOT_FOLLOWED_UP,
  FollowupActivity.NOT_ASSIGNED,
  FollowupActivity.ASSIGNED,
];

const rank: FollowupActivityShowRank = {
  [FollowupActivity.OPEN_INVOICE_BALANCE]: 1,
  [FollowupActivity.ASSIGNED]: 2,
  [FollowupActivity.FOLLOWED_UP]: 3,
  [FollowupActivity.VIEWED]: 4,
  [FollowupActivity.RESPONDED]: 5,
};

const legendColor: FollowupActivityLegendColor = {
  [FollowupActivity.OPEN_INVOICE_BALANCE]: 'var(--purple-7)',
  [FollowupActivity.ASSIGNED]: 'var(--purple-5)',
  [FollowupActivity.FOLLOWED_UP]: 'var(--purple-3)',
  [FollowupActivity.VIEWED]: 'var(--cyan-6)',
  [FollowupActivity.RESPONDED]: 'var(--cyan-2)',
};

const extraRank: ExtraFollowupActivityShowRank = {
  [FollowupActivity.NOT_ASSIGNED]: 1,
  [FollowupActivity.NOT_FOLLOWED_UP]: 2,
  [FollowupActivity.NOT_VIEWED]: 3,
  [FollowupActivity.NOT_RESPONDED]: 4,
};
function FollowUpActivitiesChart(props: Omit<DashboardComponentProps, 'filterParams'>) {
  const { followupActivitiesList } = props;
  const locale = useSelector(localeSelector);
  const funnelRef = useRef<Plot<FunnelOptions> | null>(null);

  function handleFollowUpActivitiesData(): FollowupActivitiesData[] {
    if (followupActivitiesList && Boolean(followupActivitiesList.length)) {
      const groupedActivities = groupBy(followupActivitiesList, 'followup_activity');
      const activities = Object.keys(groupedActivities)
        .filter((activity) => activitiesToShow.includes(activity as FollowupActivity))
        .flatMap((activity) => groupedActivities[activity]);

      const totalAmount = sum(activities.map((activity) => activity.outstanding.amount.value));

      return activities
        .map((activity) => {
          return {
            type: FollowupActionLabels[activity.followup_activity],
            value: activity.outstanding.amount.value,
            valueObject: activity,
            totalAmount,
            activity: activity.followup_activity,
          };
        })
        .sort((activity1, activity2) => activity2.value - activity1.value);
    }
    return [];
  }

  function handleExtraFollowUpActivitiesData(): FollowupActivitiesData[] {
    if (followupActivitiesList && Boolean(followupActivitiesList.length)) {
      const groupedActivities = groupBy(followupActivitiesList, 'followup_activity');
      const activities = Object.keys(groupedActivities)
        .filter((activity) => extraActivitiesToShow.includes(activity as FollowupActivity))
        .flatMap((activity) => groupedActivities[activity]);

      const totalAmount = sum(activities.map((a) => a.outstanding.amount.value));

      return activities
        .map((status) => {
          return {
            type: FollowupActionLabels[status.followup_activity],
            value: status.outstanding.amount.value,
            valueObject: status,
            totalAmount,
            activity: status.followup_activity,
          };
        })
        .sort((activity1, activity2) => activity2.value - activity1.value);
    }
    return [];
  }
  const followUpActivitiesData: FollowupActivitiesData[] = useMemo(handleFollowUpActivitiesData, [
    followupActivitiesList,
  ]);

  const extraFollowsData: FollowupActivitiesData[] = useMemo(handleExtraFollowUpActivitiesData, [
    followupActivitiesList,
  ]);

  const rankedActivity = OrderByRank(followUpActivitiesData, rank, 'activity');
  const rankededExtraActivity = OrderByRank(extraFollowsData, extraRank, 'activity');

  const legendPropData = useMemo(
    () =>
      rankedActivity?.map((item) => {
        const label = item.type;
        const color = legendColor[item.activity as ActivitiesToShow];
        const disable = !item.valueObject.outstanding.invoice_count;

        return { label, color, disable, id: item.activity };
      }),
    [rankedActivity]
  );

  const currentLegends = legendPropData.map((item) => item.id);

  function handleOnClickLegend(type: FollowupActivity) {
    const funnel = funnelRef.current;
    if (!funnel) return;
    const exist = currentLegends.findIndex((t) => t === type);
    if (exist === -1) {
      currentLegends.push(type);
    } else {
      currentLegends.splice(exist, 1);
    }
    funnel.chart.filter('type', (_, item) => {
      return currentLegends.includes(item.activity);
    });
    funnel.chart.render(true);
  }

  const LoadingTemplate = <Spin />;
  const funnelConfig: Omit<FunnelConfigProps, 'data'> = {
    className: 'followup-activities-chart',
    renderer: 'canvas',
    xField: 'type',
    yField: 'value',
    label: false,
    style: {
      height: '160px',
      width: '70%',
    },
    tooltip: {
      customContent(title, content) {
        return (
          Boolean(content.length) && <ToolTip title={title} content={content} locale={locale} />
        );
      },
    },
    onReady: (funnel) => {
      funnelRef.current = funnel;
    },
    conversionTag: false,
    color: ['#531dab', '#9254de', '#d3adf7', '#13C2C2', '#B5F5EC'],

    legend: false,
    animation: {
      appear: {
        animation: 'scale-in-y',
      },
      enter: {
        animation: 'zoom-in',
      },
      leave: {
        animation: 'zoom-out',
      },
    },
  };

  const Extra = (
    <ExtraFollowUpActivities data={rankededExtraActivity} dataRankedActivity={rankedActivity} />
  );

  const isEmpty = getIsEmpty(rankedActivity);

  return (
    <EmptyChart isEmpty={isEmpty}>
      <Flex
        gap="var(--space-8)"
        justify="flex-start"
        style={{ marginBottom: 'var(--space-8)' }}
        direction="row"
      >
        <FunnelChart
          {...funnelConfig}
          data={rankedActivity}
          loadingTemplate={LoadingTemplate}
          dynamicHeight={true}
        />

        {legendPropData.length ? (
          <div className="legend-list">
            <Legend data={legendPropData} handleOnClickLegend={handleOnClickLegend} />
          </div>
        ) : null}
      </Flex>
      {Boolean(rankedActivity.length) && Extra}
    </EmptyChart>
  );
}

export default FollowUpActivitiesChart;
