import { GrowTypography } from '@sinecycle/growcomponents';
import { MinMaxRange } from 'components/Common/FilterComponents/Filters/MinMax';
import { useRangeValuesAndValidation } from 'components/InvoiceSegment/hooks';
import { useState } from 'react';
import { ValidateStatus } from 'types/entities/invoice-segments';
import SingleNumericField from '../CommonFilterHelper/SingleNumericField';
import { RangeStyledFormItem } from './style';

interface BetweenNumericValuesProps {
  initialRangeValues: MinMaxRange;
  fromOnChange?: (
    updatedFilter: number | null,
    toValue?: number | null,
    validateStatus?: ValidateStatus
  ) => void;
  toOnChange?: (
    updatedFilter: number | null,
    fromValue?: number | null,
    validateStatus?: ValidateStatus
  ) => void;
  prefix?: string;
  precision?: number;
  singleFieldInputWidth?: string;
  betweenText?: string;
  fromLabel?: string;
  toLabel?: string;
}
export default function RangeNumericFilter(props: BetweenNumericValuesProps) {
  const [range, setRange] = useState<MinMaxRange>(props.initialRangeValues);

  const { formValidation, toValidation } = useRangeValuesAndValidation({ allowDecimal: true });
  function handleFromChange(value: number | null) {
    const validationStatus = range && formValidation(value, range, props.toLabel);
    setRange((previousValue) => {
      return {
        from: { ...validationStatus, value },
        to:
          validationStatus.validateStatus === 'success'
            ? { ...previousValue.to, errorMsg: null, validateStatus: 'success' }
            : { ...previousValue.to },
      };
    });
    props?.fromOnChange?.(value, range?.to?.value, validationStatus.validateStatus);
  }
  function handleToChange(value: number | null) {
    const validationStatus = range && toValidation(value, range, props.fromLabel);

    setRange((previousValue) => {
      return {
        from:
          validationStatus.validateStatus === 'success'
            ? { ...previousValue.from, errorMsg: null, validateStatus: 'success' }
            : { ...previousValue.from },
        to: { ...validationStatus, value },
      };
    });
    props?.toOnChange?.(value, range?.from?.value, validationStatus.validateStatus);
  }
  const FromFieldItem = (
    <RangeStyledFormItem
      label={props.fromLabel}
      validateStatus={range.from.validateStatus}
      help={range.from.errorMsg}
      style={{ width: props.singleFieldInputWidth ? props.singleFieldInputWidth : '113px' }}
    >
      <SingleNumericField
        onChange={handleFromChange}
        prefix={props.prefix}
        value={range.from.value}
        percision={props.precision ?? 0}
        style={{ width: props.singleFieldInputWidth ? props.singleFieldInputWidth : '113px' }}
      />
    </RangeStyledFormItem>
  );
  const ToFieldItem = (
    <RangeStyledFormItem
      label={props.betweenText ? undefined : props.toLabel ?? 'and'}
      validateStatus={range.to.validateStatus}
      help={range.to.errorMsg}
      style={{ width: props.singleFieldInputWidth ? props.singleFieldInputWidth : '113px' }}
    >
      <SingleNumericField
        onChange={handleToChange}
        value={range.to.value}
        prefix={props.prefix}
        percision={props.precision ?? 0}
        style={{ width: props.singleFieldInputWidth ? props.singleFieldInputWidth : '113px' }}
      />
    </RangeStyledFormItem>
  );
  return (
    <>
      {FromFieldItem}
      {props.betweenText && (
        <GrowTypography.Text style={{ paddingTop: 'var(--space-8)' }}>
          {props.betweenText}
        </GrowTypography.Text>
      )}
      {ToFieldItem}
    </>
  );
}
