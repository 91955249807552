import { InvoiceListTabKeys } from 'components/InvoiceListV2';

export enum INVOICE_LIST_EVENT {
  INVOICE_LIST_TAB_SWITCH = 'Invoice List Tab Switch',
  INVOICE_LIST_DOWNLOAD_QUICK_REPORTS = 'Download Quick reports',
  INVOICE_LIST_VIEW_PDF = 'Invoice List View Pdf',
  INVOICE_LIST_DOWNLOAD_PDF = 'Invoice List Download Pdf',
  INVOICE_LIST_SORTING = 'Invoice List Sorting',
  INVOICE_LIST_PAGINATION = 'Invoice List Pagination',
}

type INVOICE_LIST_EVENTS_WITH_PROPS_PAYLOAD =
  | {
      type: INVOICE_LIST_EVENT.INVOICE_LIST_TAB_SWITCH;
      payload: { tab: InvoiceListTabKeys };
    }
  | {
      type: INVOICE_LIST_EVENT.INVOICE_LIST_DOWNLOAD_QUICK_REPORTS;
      payload: { tab: InvoiceListTabKeys; status: 'Open' | 'Download' };
    }
  | {
      type: INVOICE_LIST_EVENT.INVOICE_LIST_VIEW_PDF;
      payload: { tab: InvoiceListTabKeys };
    }
  | {
      type: INVOICE_LIST_EVENT.INVOICE_LIST_DOWNLOAD_PDF;
      payload: { tab: InvoiceListTabKeys };
    }
  | {
      type: INVOICE_LIST_EVENT.INVOICE_LIST_SORTING;
      payload: {
        column: string;
        tab: InvoiceListTabKeys;
      };
    }
  | {
      type: INVOICE_LIST_EVENT.INVOICE_LIST_PAGINATION;
      payload: {
        tab: InvoiceListTabKeys;
      };
    };

type INVOICE_LIST_WITH_PROPS = INVOICE_LIST_EVENTS_WITH_PROPS_PAYLOAD['type'];

type INVOICE_LIST_WITHOUT_PROPS = Exclude<INVOICE_LIST_EVENT, INVOICE_LIST_WITH_PROPS>;

export type INVOICE_LIST_EVENT_TYPES =
  | INVOICE_LIST_EVENTS_WITH_PROPS_PAYLOAD
  | { type: INVOICE_LIST_WITHOUT_PROPS; payload?: undefined };
