import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessUnit } from 'types/entities/business-unit';
import { RootState } from '../../reducers/rootReducer';
import { AllBusinessUnitsResponseState, CRUD_ACTIONS } from './types';

export const initialState: AllBusinessUnitsResponseState = {
  status: 0,
  message: '',
  businessUnits: [],
};

const businessUnitsSlice = createSlice({
  name: 'businessUnits',
  initialState,
  reducers: {
    getAllBusinessUnitsHandler(state, action: PayloadAction<AllBusinessUnitsResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.businessUnits = action.payload.businessUnits ?? [];
    },
    clearStatusAndMessageHandler(state) {
      state.status = 0;
      state.message = '';
    },
    updateBusinessUnitHandler(
      state,
      action: PayloadAction<{
        type: CRUD_ACTIONS;
        BusinessUnit: BusinessUnit;
      }>
    ) {
      let businessUnits = [] as BusinessUnit[];

      switch (action.payload.type) {
        case CRUD_ACTIONS.CREATE:
          businessUnits = [
            ...(state.businessUnits ? state.businessUnits : []),
            action.payload.BusinessUnit,
          ];
          break;
        case CRUD_ACTIONS.UPDATE:
          businessUnits = state.businessUnits
            ? state.businessUnits.map((unit) => {
                if (unit.id === action.payload.BusinessUnit.id) {
                  return action.payload.BusinessUnit;
                } else {
                  return unit;
                }
              })
            : [];
          break;

        case CRUD_ACTIONS.DELETE:
          businessUnits = state.businessUnits
            ? state.businessUnits.filter((unit) => {
                return unit.id !== action.payload.BusinessUnit.id;
              })
            : [];
          break;
      }

      state.businessUnits = businessUnits;
    },
  },
});

export const {
  getAllBusinessUnitsHandler,
  clearStatusAndMessageHandler,
  updateBusinessUnitHandler,
} = businessUnitsSlice.actions;

export default businessUnitsSlice.reducer;

const businessUnitsSelectors = {
  businessUnitsSelector: (state: RootState) => state.businessUnits,
};

export const { businessUnitsSelector } = businessUnitsSelectors;
