import { AcceptedFileType, FileExtension, FileType, ValidFileType } from 'types/common/attachments';

const EXTENSION_KEY_MAPPER: Record<AcceptedFileType, ValidFileType> = {
  [FileExtension.DOC]: FileType.DOC,
  [FileExtension.DOCX]: FileType.DOC,
  [FileExtension.JPG]: FileType.JPG,
  [FileExtension.JPEG]: FileType.JPG,
  [FileExtension.PNG]: FileType.PNG,
  [FileExtension.PDF]: FileType.PDF,
  [FileExtension.XLS]: FileType.XLS,
  [FileExtension.XLSX]: FileType.XLS,
  [FileExtension.EML]: FileType.EML,
};

export const AcceptedFileTypes: Array<AcceptedFileType> = [
  FileExtension.DOC,
  FileExtension.DOCX,
  FileExtension.PDF,
  FileExtension.JPG,
  FileExtension.JPEG,
  FileExtension.PNG,
  FileExtension.XLS,
  FileExtension.XLSX,
  FileExtension.EML,
];

export function getFileExtensionFromName(name: string | undefined): AcceptedFileType {
  if (!name) return '' as AcceptedFileType;

  const lastIndexOfDot = name.lastIndexOf('.');

  return (
    lastIndexOfDot !== -1 ? name.substring(lastIndexOfDot).toLowerCase() : ''
  ) as AcceptedFileType;
}

export function getFileTypeFromExtension(extension: AcceptedFileType): ValidFileType {
  return EXTENSION_KEY_MAPPER[extension];
}
