import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { ErrorBoundaryWrapper } from 'components/Common/ErrorBoundary';
import { StyledCard } from 'pages/Dashboard/AdminDashboard/SnapShot/Snapshot';
import { useCollectionCollectionStatuesQuery } from 'queries/admin-dashboard';
import ChartSpinner from '../Common/ChartSpinner';
import { DashboardChartProps } from '../Common/types';
import CollectionStatusChart from './CollectionStatusChart';

const t = {
  collection_status: 'Receivables by Collection status',
};

export default function CollectionStatusCard(props: DashboardChartProps) {
  const { snapshotFilterParams } = props;
  const {
    data: collectionStatuses,
    isLoading: loading,
    isSuccess,
  } = useCollectionCollectionStatuesQuery(snapshotFilterParams);

  const Title = (
    <GrowText size={'var(--fs-20)'} color="var(--gray-9)" strong>
      {t.collection_status}
    </GrowText>
  );

  if (loading && !isSuccess) {
    return <ChartSpinner title={Title} height={'250px'} />;
  }

  return (
    <StyledCard>
      <Flex direction="column" align="flex-start" gap="var(--space-8)">
        {Title}
        <ErrorBoundaryWrapper>
          <CollectionStatusChart collectionStatuses={collectionStatuses} />
        </ErrorBoundaryWrapper>
      </Flex>
    </StyledCard>
  );
}
