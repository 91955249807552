import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowTypography } from '@sinecycle/growcomponents';
import { Space } from 'antd';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { DropdownButton } from 'components/BaseComponents/RTE/Toolbar/common/DropdownWrapper';
import { formattingOptions } from 'lib/RichText';
import { AlignTypes } from 'lib/RichText/type';
import { commonButtonProps } from '../../Toolbar';
import { ToolbarOptionProps } from '../../type';

const t = {
  label: 'Align',
  left: 'Left',
  right: 'Right',
  center: 'Center',
};

const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
  title: t.label,
};

const Icon = <FontAwesomeIcon icon={['far', 'align-left']} />;
interface AlignProps extends ToolbarOptionProps {}

export function Align({ editor }: AlignProps) {
  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    icon: Icon,
  };

  const CurrentAlignMode = <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />;

  function onAlignClickCallback(align: AlignTypes) {
    formattingOptions['align'].onClick(editor, align);
  }

  const alignItems = [
    {
      key: 'left',
      label: (
        <Space>
          <FontAwesomeIcon icon={['far', 'align-left']} style={{ height: '16px' }} />
          <GrowTypography.Text>{t.left}</GrowTypography.Text>
        </Space>
      ),
    },
    {
      key: 'right',
      label: (
        <Space>
          <FontAwesomeIcon icon={['far', 'align-right']} style={{ height: '16px' }} />
          <GrowTypography.Text>{t.right}</GrowTypography.Text>
        </Space>
      ),
    },
    {
      key: 'center',
      label: (
        <Space>
          <FontAwesomeIcon icon={['far', 'align-center']} style={{ height: '16px' }} />
          <GrowTypography.Text>{t.center}</GrowTypography.Text>
        </Space>
      ),
    },
  ];

  return (
    <DropdownButton
      menu={{
        items: alignItems,
        onClick: (info) => {
          onAlignClickCallback(info.key as AlignTypes);
        },
      }}
      placement="top"
    >
      {CurrentAlignMode}
    </DropdownButton>
  );
}
