import { useSelector } from 'react-redux';
import { RootState } from '../reducers/rootReducer';

import { AsyncFetches } from '../store/async-fetches/fetches';

export default function useLoading(fetchType: AsyncFetches) {
  const fetches = useSelector((state: RootState) => state.asyncRequests.fetching);

  return fetches.includes(fetchType);
}
