import { Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { useMemo } from 'react';
import { CollectionPeriod } from 'store/customer/types';
import { CollectionPeriodPopOver } from '../CollectionPeriodPopOver';

const t = {
  previous_collection_periods: 'Previous Collection Periods',
};
interface CollectionPeriodProps {
  collectionPeriods?: CollectionPeriod[];
}

const InvoiceListCollectionPeriod = (props: CollectionPeriodProps) => {
  function handleCollectionPeriodInfo() {
    const latestPeriod = props.collectionPeriods;
    const filteredPeriod = props.collectionPeriods?.filter((period, i) => {
      return i !== 0;
    });

    return { latestPeriod: latestPeriod?.[0], periods: filteredPeriod };
  }

  const collectionPeriods = useMemo(handleCollectionPeriodInfo, [props.collectionPeriods]);
  const Title = (
    <Flex style={{ maxWidth: '110px' }}>
      <GrowText size="var(--fs-12)" color="var(--gray-7)">
        {t.previous_collection_periods}({collectionPeriods.periods?.length})
      </GrowText>
    </Flex>
  );

  const PopOverContent = <CollectionPeriodPopOver collectionPeriods={collectionPeriods.periods} />;
  return (
    <Popover
      content={collectionPeriods.periods?.length ? PopOverContent : undefined}
      title={collectionPeriods.periods?.length ? Title : undefined}
      placement="right"
      arrow={false}
    >
      <Flex gap="var(--space-4)" style={{ height: '100%' }} align="center">
        <GrowText size="var(--fs-14)">{collectionPeriods.latestPeriod?.name}</GrowText>
        {Boolean(collectionPeriods.periods) && Boolean(collectionPeriods.periods?.length) ? (
          <GrowText color="var(--gray-7)" size="var(--fs-12)">
            + {collectionPeriods.periods?.length} periods
          </GrowText>
        ) : null}
      </Flex>
    </Popover>
  );
};

export { InvoiceListCollectionPeriod };
