import { FollowupTrack, FollowupTrackType } from 'types/entities/collection-strategy';
import { DunningRule, StoppingRule } from 'types/entities/collection-strategy/rule';
import {
  FollowupEventType,
  StatusChangeEventData,
} from 'types/entities/collection-strategy/rule-event';
import { IdGenerator } from 'util/generators';

export function findStoppingRuleByStatus(
  statusData: StatusChangeEventData,
  stoppingRules: StoppingRule[]
) {
  return stoppingRules.find(
    (rule) =>
      rule.data.event.data.status === statusData.status &&
      rule.data.event.data.entity === statusData.entity
  );
}

export function findStoppingRuleIndexByStatus(
  statusData: StatusChangeEventData,
  stoppingRules: StoppingRule[]
) {
  return stoppingRules.findIndex(
    (rule) =>
      rule.data.event.data.status === statusData.status &&
      rule.data.event.data.entity === statusData.entity
  );
}

export function findStoppingRuleById(id: number, stoppingRules: StoppingRule[]) {
  return stoppingRules.find((rule) => rule.id === id);
}

export function findTrack(trackType: FollowupTrackType, tracks: FollowupTrack[]) {
  return tracks.find((track) => track.track_type === trackType);
}

export function findTrackIndex(trackType: FollowupTrackType, tracks: FollowupTrack[]) {
  return tracks.findIndex((track) => track.track_type === trackType);
}

export function findDunningRuleById(id: string | number, rules: DunningRule[]) {
  return rules.find((rule) => rule.id === id);
}

// returns -1 if event type is NOT invoice days
export function getInvoiceDunningRuleDays(rule: Omit<DunningRule, 'id'>) {
  return rule.data.event.type === FollowupEventType.INVOICE_AGE ? rule.data.event.data.days : -1;
}

export function getMaxSequenceCount(rules: DunningRule[]): number {
  return rules.reduce((acc, rule) => (rule.data.sequence > acc ? rule.data.sequence : acc), 1);
}

export function getSortedRulesBySequence(rules: DunningRule[]): DunningRule[] {
  return rules.sort((ruleA, ruleB) => ruleA.data.sequence - ruleB.data.sequence);
}

export const ruleIdGenerator = new IdGenerator('rule');

export const eventIdGenerator = new IdGenerator('event');

export const actionIdGenerator = new IdGenerator('action');
