export enum RealtimeChannel {
  USER = 'USER',
  ACCOUNT = 'ACCOUNT',
  GLOBAL = 'GLOBAL',
}

type CallbackFn = (data: unknown) => void;

export interface RealtimeInterface {
  bindHandlerToEvent: (
    channelType: RealtimeChannel,
    eventName: string,
    callback: CallbackFn
  ) => void;
  unbindHandlerFromEvent: (
    channelType: RealtimeChannel,
    eventName: string,
    callback: CallbackFn
  ) => void;
  subscribeToChannel: (channelName: string) => void;
  unsubscribeFromChannel: (channelName: string) => void;
}
