import { useQuery } from '@tanstack/react-query';
import { DashboardTab } from 'pages/Dashboard/AdminDashboard';
import { DashboardPostFilterParams } from 'pages/Dashboard/types';
import { useDispatch } from 'react-redux';
import {
  CollectionPerformanceProps,
  fetchADP,
  fetchAgingData,
  fetchBadDebt,
  fetchCashFlowTrends,
  fetchCollectionStatuses,
  fetchCurrentDSO,
  fetchFlaggedInvoices,
  fetchHighRiskCustomers,
  fetchLongPendingIssues,
  fetchMonthlyDSO,
  fetchSalesCollection,
  fetchTotalBalanceAmount,
  fetchTotalCollections,
  fetchTotalOutstandingAmount,
  fetchTotalOutstandingByFollowupActivities,
  fetchTotalOutstandingByInvoiceDisputeTypes,
  fetchTotalOutstandingByInvoiceFollowupStatuses,
  fetchTotalOutstandingByInvoiceStatuses,
  getCollectorsPerformance,
} from 'services/admin-dashboard';
import { getCollectionPeriods } from 'services/collection-period';
import { getCustomFields } from 'services/custom-field';
import { getSavedFilterViews } from 'services/filter-view';
import { CollectorsPerformanceActions } from 'store/collectors-performace';
import { CollectorsPerformanceResponse, LongPendingIssue } from 'types/api/admin-dashboard';
import { CustomFieldPage } from 'types/entities/custom-field';

interface PendingIssuesProps {
  params: DashboardPostFilterParams;
  onSuccess?: (data?: LongPendingIssue[]) => void;
}

const HALF_DAY = 12 * 60 * 60 * 1000;
const TIME_INTERVAL = 0;
export function useCashFlowTrendsQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['cash-flow-trends', params],
    queryFn: () => fetchCashFlowTrends(params),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
  });
}

export function useCollectionCollectionStatuesQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['collection-statuses', params],
    queryFn: () => fetchCollectionStatuses(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}

export function useTotalBalanceTileQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['total-balance-receivables', params],
    queryFn: () => fetchTotalBalanceAmount(params),
    cacheTime: 0,
    staleTime: 0,
    retry: false,
  });
}

export function useTotalOutstandingQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['total-outstanding', params],
    queryFn: () => fetchTotalOutstandingAmount(params),
    cacheTime: 0,
    staleTime: 0,
    retry: false,
  });
}

export function useTotalCollectionsTileQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['total-collections', params],
    queryFn: () => fetchTotalCollections(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}

export function useDSOTileQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['current-dso', params],
    queryFn: () => fetchCurrentDSO(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}

export function useBadDebtTileQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['bad-debt', params],
    queryFn: () => fetchBadDebt(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}

export function useOverDueInvoiceByAgeQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['over-due-invoice-by-age', params],
    queryFn: () => fetchAgingData(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}
export function useInvoiceStatusesQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['invoice-statuses', params],
    queryFn: () => fetchTotalOutstandingByInvoiceStatuses(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}

export function useDisputeStatusQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['dispute-statuses', params],
    queryFn: () => fetchTotalOutstandingByInvoiceDisputeTypes(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}

export function useFollowUpStatuesQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['follow-up-statuses', params],
    queryFn: () => fetchTotalOutstandingByInvoiceFollowupStatuses(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}

export function useFollowUpActivitiesQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['follow-up-activities', params],
    queryFn: () => fetchTotalOutstandingByFollowupActivities(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}

export function useHighRiskCustomersQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['high-risk-customers', params],
    queryFn: () => fetchHighRiskCustomers(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}

export function usePendingIssuesQuery(props: PendingIssuesProps) {
  const { params, onSuccess } = props;

  return useQuery({
    queryKey: ['pending-issues', params],
    queryFn: () => fetchLongPendingIssues(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
    onSuccess,
  });
}

export function useFlaggedInvoicesQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['flagged-invoices', params],
    queryFn: () => fetchFlaggedInvoices(params),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}

export function useDashboardCustomFieldsQuery(entity: string, page: CustomFieldPage) {
  return useQuery({
    queryKey: ['dashboard-custom-fields', entity, page],
    queryFn: () => getCustomFields({ entity, page }),
  });
}

export function useCollectionPeriodsQuery(
  hasAnalysisAccess: boolean,
  hasCollectionPeriod: boolean | undefined
) {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['dashboard-collection-periods'],
    queryFn: () => getCollectionPeriods(),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
    enabled: Boolean(hasAnalysisAccess && hasCollectionPeriod),
    onSuccess: (data) => {
      dispatch(CollectorsPerformanceActions.initializeCollectionPeriods(data));
    },
    onError: (err) => {
      console.log(err);
    },
  });
}

export function useMonthlyDSOQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['monthly-dso', params],
    queryFn: () => fetchMonthlyDSO(params),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
  });
}
export function useADPListQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['adp-list', params],
    queryFn: () => fetchADP(params),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
  });
}

export function useSalesCollectionsQuery(params: DashboardPostFilterParams) {
  return useQuery({
    queryKey: ['sales-collections', params],
    queryFn: () => fetchSalesCollection(params),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
  });
}

export function useDashboardFilterViewsQuery(activeTab: DashboardTab | undefined) {
  const searchParams = {
    type: `DASHBOARD_${activeTab}`,
  };
  return useQuery({
    queryKey: ['dashboard-filter-views', activeTab],
    queryFn: () => getSavedFilterViews('dashboard', searchParams),
    cacheTime: TIME_INTERVAL,
    staleTime: TIME_INTERVAL,
  });
}

export function useGetCollectorPerformances(props: {
  params: CollectionPerformanceProps;
  onSuccess: (data: CollectorsPerformanceResponse) => void;
}) {
  return useQuery({
    queryKey: ['collector-performance', props.params],
    queryFn: () => getCollectorsPerformance(props.params),
    onSuccess(data) {
      props.onSuccess(data);
    },
  });
}
