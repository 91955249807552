import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import FileItems from './FileItems';
import { AssociatedFile } from './type';

interface AssociatedFilesProps {
  hide?: boolean;
  associatedFiles: AssociatedFile[];
  onChange: (id?: number | string, status?: boolean) => void;
  extra?: React.ReactNode;
}
const t = {
  title: 'Associated Files',
};

function AssociatedFiles(props: Readonly<AssociatedFilesProps>) {
  if (props.hide) return null;

  const Title = <Texto weight="semibold">{t.title}</Texto>;
  const Files = (
    <Flex direction="column" gap="--space-8">
      {props.associatedFiles.map((file) => {
        return <FileItems file={file} key={file.id} onChange={props.onChange} />;
      })}
    </Flex>
  );
  return (
    <Flex direction="column" gap="var(--space-8)">
      {Title}
      {props.extra}
      {Files}
    </Flex>
  );
}

export default AssociatedFiles;
