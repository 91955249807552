import { QueryKey, useMutation, useQuery } from '@tanstack/react-query';
import { getAllDisputeTypes } from 'services/dispute-type';
import { createDisputeType } from 'services/invoice';
import { DisputeType } from 'types/entities/dispute-type';

interface useGetRecentDisputeTypesQueryProps<T> {
  queryFn: () => Promise<T[]>;
  queryKey: QueryKey;
}

interface useSearchDisputeTypeListMutationProps<U> {
  searchQueryFn: (searchTerm: string) => Promise<U[]>;
}

export function useGetRecentDisputeTypesQuery<T>(props: useGetRecentDisputeTypesQueryProps<T>) {
  const { queryFn, queryKey } = props;

  return useQuery({
    queryKey,
    queryFn,
  });
}

export function useSearchDisputeTypeListMutation<U>(
  props: useSearchDisputeTypeListMutationProps<U>
) {
  const { searchQueryFn } = props;

  return useMutation<U[], unknown, string, unknown>({
    mutationFn: async (searchTerm: string) => {
      const data = await searchQueryFn(searchTerm);
      return data || [];
    },
  });
}

export function useCreateDisputeTypeQuery(searchTerm: string) {
  return useQuery({
    queryKey: ['create-dispute-type'],
    queryFn: () => createDisputeType(searchTerm),
    retry: false,
  });
}

export function useCreateDisputeTypeMutation() {
  return useMutation<DisputeType, unknown, string>({
    mutationFn: (searchTerm: string) => createDisputeType(searchTerm),
  });
}

export function useGeteDisputeTypeQuery() {
  return useQuery({
    queryKey: ['get-all-dispute-types'],
    queryFn: () => getAllDisputeTypes(),
    retry: false,
  });
}
