import { Select, SelectProps, Spin } from 'antd';
import { debounce, uniqBy } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SelectWrapper } from './SelectWrapper';
import { t } from './text';

const LoadingIndicator = <Spin size="small" />;
const NotFound = <span>No result</span>;
const customStyle = { width: '100%' };

export interface IProps<T> {
  key?: any;
  value: any;
  label: string | React.ReactElement;
  valueobject?: T;
  children?: string;
  disabled?: boolean;
}

export type GroupedOptions<T extends any = any, k extends string = string> = Record<
  k,
  {
    key: string;
    label: React.ReactNode;
    options: T[];
  }
>;

type BaseSelectProps<T extends any> = {
  labelAtrributeName?: string;
  valueAtrributeName?: string;

  selectedValues?: any | any[];
  defaultValues?: any | any[];

  shouldFormat?: boolean;
  optionsList?: any[];
  groupedOptions?: GroupedOptions<T>;
  optionsStyle?: 'singular' | 'grouped';
  popupContainer?: null | (() => HTMLElement);

  onChangeCallBack?: (
    value: IProps<any> | any,
    selectedValues?: number | string | string[] | number[]
  ) => void;
  onSearchCallBack?: (value: string) => Promise<any>;
  optionRender?: (option: T) => React.ReactNode;
  style?: React.CSSProperties;
  onSelectCallBack?: (value: any, option?: any) => void;
};

type SelectComponentProps<T extends any> = Omit<SelectProps<IProps<T>>, 'dropdownRender'> &
  BaseSelectProps<T> &
  (
    | {
        optionsStyle?: 'singular';
        optionsList?: any[];
        dropdownRender?: (
          options: React.ReactElement,
          optionsList: IProps<T>[]
        ) => React.ReactElement;
      }
    | {
        optionsStyle: 'grouped';
        groupedOptions: GroupedOptions;
        dropdownRender?: (
          options: React.ReactElement,
          optionsList: GroupedOptions
        ) => React.ReactElement;
      }
  );

const StyledSelect = styled(Select)`
  .rc-virtual-list {
    width: 100%;
  }
`;

const SelectComponent = <T extends any>(props: SelectComponentProps<T>) => {
  const [optionsForDisplay, setOptionsForDisplay] = useState([] as IProps<T>[]);
  const [groupedOptionsForDisplay, setGroupedOptionsForDisplay] = useState<GroupedOptions<T>>({});
  const [selectedValue, setSelectedValue] = useState<string | string[] | null>([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (props.groupedOptions) {
      setGroupedOptionsForDisplay(props.groupedOptions);
    }
  }, [props.groupedOptions]);

  useEffect(() => {
    if (props.optionsList) {
      setOptionsForDisplay(props.optionsList ?? []);
    }
  }, [props.optionsList]);

  useEffect(() => {
    setSelectedValue(props.selectedValues);
  }, [props.selectedValues]);

  useEffect(() => {
    return () => {
      setOptionsForDisplay([]);
      setGroupedOptionsForDisplay({});
      setSelectedValue([]);
    };
  }, []);

  // Event Handlers
  const onSearchDebounced = debounce((searchTerm: string) => {
    return new Promise((resolve) => {
      setFetching(true);
      if (props.onSearchCallBack) {
        props.onSearchCallBack(searchTerm).then((data: IProps<any>[] | GroupedOptions) => {
          // if array it's ungrouped options list, else it's it's grouped options (object)
          if (Array.isArray(data)) {
            //trying to remove the duplicate entries in the optionsList.
            setOptionsForDisplay(
              props.valueAtrributeName ? uniqBy(data, props.valueAtrributeName) : data
            );
          }

          resolve(setFetching(false));
        });
      } else {
        setOptionsForDisplay([]);
        setGroupedOptionsForDisplay({});
        resolve(setFetching(false));
      }
    });
  }, 800);

  const onChange = (selectedValues: any | any[], options: any) => {
    props.onChangeCallBack && props.onChangeCallBack(options, selectedValues);
  };

  const formatOption = (option: any): IProps<any> => {
    return {
      label: option[props.labelAtrributeName as string],
      value: option[props.valueAtrributeName as string] ?? option?.id,
      valueobject: option,
    };
  };

  function renderOptionForDisplay(option: IProps<any>) {
    return (
      <Select.Option
        disabled={option?.valueobject?.disabled}
        value={option.value}
        label={option.label}
        valueobject={option.valueobject}
      >
        {props.optionRender ? props.optionRender(option.valueobject) : option.label}
      </Select.Option>
    );
  }

  function renderOptionsForDisplay(options: IProps<any>[]) {
    return options.map((option, index) => (
      <Fragment key={index}>
        {renderOptionForDisplay(props.shouldFormat ? formatOption(option) : option)}
      </Fragment>
    ));
  }

  function renderGroupedOptionsForDisplay(groupedOptions: GroupedOptions) {
    return Object.keys(groupedOptions).map((groupId) => {
      const groupObj = groupedOptions[groupId];

      if (!groupObj.options.length) {
        return null;
      }

      return (
        <Select.OptGroup key={groupObj.key} label={groupObj.label}>
          {renderOptionsForDisplay(groupObj.options)}
        </Select.OptGroup>
      );
    });
  }

  function onClear() {
    // if a condition to be run before clearning do that
    // else unset the selected value
    if (props.onClear) {
      props.onClear();
    } else {
      setSelectedValue(null);
    }
  }

  function dropdownRenderWrapper(options: React.ReactElement) {
    if (props.dropdownRender) {
      return props.optionsStyle && props.optionsStyle === 'grouped'
        ? props.dropdownRender(options, groupedOptionsForDisplay)
        : props.dropdownRender(options, optionsForDisplay);
    }

    return options;
  }

  return (
    <StyledSelect
      // Style
      className={props.className}
      open={props.open}
      size={props.size}
      style={props.style ? props.style : customStyle}
      dropdownStyle={props.dropdownStyle}
      // Pluggable React.ReactNode Elements
      notFoundContent={fetching ? LoadingIndicator : NotFound}
      dropdownRender={dropdownRenderWrapper}
      tagRender={props.tagRender}
      removeIcon={props.removeIcon}
      menuItemSelectedIcon={props.menuItemSelectedIcon}
      // Select Mode + Config
      mode={props.mode}
      allowClear={props.allowClear}
      maxTagCount={props.maxTagCount}
      disabled={props.disabled}
      showSearch={Boolean(props.onSearchCallBack)}
      filterOption={false}
      // Value & Placeholder
      value={selectedValue}
      defaultValue={props.defaultValues}
      placeholder={props.placeholder ?? t.placeHolder}
      // Event Callbacks
      onBlur={props.onBlur}
      onChange={onChange}
      onDropdownVisibleChange={props.onDropdownVisibleChange}
      onSearch={props.onSearchCallBack ? onSearchDebounced : undefined}
      onClear={onClear}
      onClick={props.onClick}
      onSelect={props.onSelectCallBack}
      // Misc
      getPopupContainer={
        props.popupContainer
          ? props.popupContainer
          : (trigger) => {
              return trigger;
            }
      }
      status={props.status}
      virtual={props.virtual}
      listHeight={props.listHeight}
      defaultOpen={props.defaultOpen}
      popupMatchSelectWidth={props.popupMatchSelectWidth}
      tokenSeparators={props.tokenSeparators}
    >
      {props.optionsStyle && props.optionsStyle === 'grouped'
        ? renderGroupedOptionsForDisplay(groupedOptionsForDisplay)
        : renderOptionsForDisplay(optionsForDisplay)}
    </StyledSelect>
  );
};

export default SelectComponent;

export { SelectWrapper };
