import { IconDashboard } from 'assets/icons';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { useDashboardAccess } from 'pages/Dashboard/hooks/useDashboardAccess';
import { useMemo } from 'react';
import { pagePath, pagePrivileges } from 'router/constants/page-info';
import { AppSubRoute } from '../sidebar.components';
import { getSidebarIconComponent } from '../sidebar.utils';
import { PageListItems } from './usePageList';

function useDashBoardRoute() {
  const {
    hasAdminDashboardAccess,
    hasDashboardCollaboratorAccess,
    hasTeamDashboardAccess,
    hasTeam,
    hasCollectorDashboardAccess,
  } = useDashboardAccess();

  function handleDashBoardSubLink() {
    const subLinks: AppSubRoute[] = [];
    if (hasAdminDashboardAccess) {
      subLinks.push({ label: 'Company Dashboard', url: pagePath('ADMIN_DASHBOARD') });
    }

    if (hasTeamDashboardAccess && hasTeam) {
      subLinks.push({ label: 'Company Dashboard', url: pagePath('TEAM_DASHBOARD') });
    }

    if (hasCollectorDashboardAccess) {
      subLinks.push({
        label: 'Collectors Dashboard',
        url: pagePath('COLLECTOR_DASHBOARD'),
      });
    }

    if (hasDashboardCollaboratorAccess) {
      subLinks.push({
        label: 'Collaborator Dashboard',
        url: pagePath('COLLABORATOR_DASHBOARD'),
      });
    }
    return subLinks;
  }
  const dashboardSubLinks: AppSubRoute[] = useMemo(handleDashBoardSubLink, [
    hasAdminDashboardAccess,
    hasCollectorDashboardAccess,
    hasDashboardCollaboratorAccess,
    hasTeam,
    hasTeamDashboardAccess,
  ]);
  const hasDashboard = usePrivilegeStatus(pagePrivileges('DASHBOARD'), {
    shallowCheck: true,
  });
  const dashboardPageList: Array<PageListItems> = [
    {
      key: 'DASHBOARD',
      subLinks: dashboardSubLinks,
      label: 'Dashboard',
      access: hasDashboard,
      icon: getSidebarIconComponent(IconDashboard),
    },
  ];
  return { dashboardPageList };
}

export default useDashBoardRoute;
