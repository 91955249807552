import { CellRenderProps, GrowTypography } from '@sinecycle/growcomponents';
import { InvoiceListCollectionPeriod } from 'components/HigherOrderComponent/KeyActivitesContainer/CollectionPeriod/InvoiceListCollectionPeriod';
import { CollectionPeriod, IInvoices } from 'store/customer/types';

interface CollectionPeriodProps<T> extends CellRenderProps<T, CollectionPeriod[]> {
  data: T;
}

const t = {
  not_set: 'Not set',
};
function CollectionPeriods(props: CollectionPeriodProps<IInvoices>) {
  const { value } = props;
  if (!value)
    return (
      <GrowTypography.Text style={{ color: 'var(--gray-7)' }}>{t.not_set}</GrowTypography.Text>
    );

  return <InvoiceListCollectionPeriod collectionPeriods={value} />;
}

export default CollectionPeriods;
