import { CellRenderProps, GrowTypography } from '@sinecycle/growcomponents';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';
import { formatAmountShort, formatCurrency } from 'util/number-formatter';

export interface AmountColumnProps {
  amount: number;
  extra?: JSX.Element;
  textProps?: React.ComponentProps<typeof GrowTypography.Text>;
  format?: 'SHORT' | 'FULL';
  prefix?: string;
  currency: string;
}

function AmountColumn<T>(props: CellRenderProps<T, AmountColumnProps>) {
  const { amount, textProps, format = 'SHORT', prefix, currency } = props.value;
  const locale = useSelector(localeSelector);
  const value =
    format === 'SHORT'
      ? formatAmountShort(amount, currency, locale)
      : formatCurrency(amount, { currency, locale });

  return isNaN(amount) ? (
    '-'
  ) : (
    <GrowTypography.Text {...textProps}>{`${prefix ?? ''} ${value}`}</GrowTypography.Text>
  );
}

export { AmountColumn };
