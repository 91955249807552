import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BulkAction, GrowButton } from '@sinecycle/growcomponents';
import { useIsMutating } from '@tanstack/react-query';
import { Button } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import {
  CustomActivityProps,
  FormItemNames,
  MutationFunctionProps,
} from 'components/Common/ActivitiesForm/types';
import { EActions, Eactions } from 'components/HigherOrderComponent/KeyActionsContainer/types';
import ActivityButtons from 'components/HigherOrderComponent/KeyActivitesContainer/ActivityButtons';
import useFormCustomTransformer from 'components/HigherOrderComponent/KeyActivitesContainer/hooks/useFormCustomTransformer';
import useFormSubmitMutate from 'components/HigherOrderComponent/KeyActivitesContainer/hooks/useFormSubmitMutate';
import { ActivityMutationProps } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { castArray, map } from 'lodash';
import { useSelector } from 'react-redux';
import { ELastContactActivity } from 'store/activity-feed/type';
import { customerDetailsSelector } from 'store/customer/customer';
import { IInvoices } from 'store/customer/types';
import { InvoiceDropdownInterfaceProps, OpenInvoicesListFilters } from 'store/invoice/type';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { eActionList } from './type';
import { useComputedCustomerList } from './useComputedCustomerList';
import { useComputedInvoiceList } from './useComputedInvoiceList';

export interface OpenInvoiceBulkActionProps extends BulkAction<IInvoices> {
  setAction: (key: EActions, invoiceList: InvoiceDropdownInterfaceProps[]) => void;
  setQuickAction: (key: ELastContactActivity, invoiceList: InvoiceDropdownInterfaceProps[]) => void;
  selectAll?: boolean;
  isFromInvoiceList?: boolean;
  currentFilterParam?: OpenInvoicesListFilters;
  bulkRecordsLength?: number;
  customerId?: number;
  action?: EActions;
}

function OpenInvoiceBulkAction(props: OpenInvoiceBulkActionProps) {
  const {
    selectAll,
    currentFilterParam,
    setQuickAction,
    isFromInvoiceList,
    bulkRecordsLength,
    action: actionKey,
  } = props;

  const customerDetails = useSelector(customerDetailsSelector);
  const hasPrivilegeToSetInvoiceFlag = usePrivilegeStatus([
    ERolesAndPrivileges.SET_CUSTOMER_DETAIL_FLAG_INVOICE,
    ERolesAndPrivileges.SET_INVOICE_LIST_FLAG_INVOICE,
  ]);
  const { computedQuickActivitiesInvoiceList } = useComputedInvoiceList({
    hasPrivilegeToSetInvoiceFlag,
  });
  const { computedCustomerDetailsOptions } = useComputedCustomerList({
    selectAll: props.selectAll,
    hasPrivilegeToSetInvoiceFlag,
  });
  const actions = props.isFromInvoiceList
    ? computedQuickActivitiesInvoiceList
    : computedCustomerDetailsOptions;

  const pauseResumestrategyLoading = useIsMutating({
    mutationKey: ['pause-resume-strategy'],
  });
  const pauseResumestrategyBulkLoading = useIsMutating({
    mutationKey: ['pause-resume-strategy', 'bulk-action'],
  });
  const unflagInvoiceLoading = useIsMutating({
    mutationKey: ['unflagInvoice'],
  });
  const unflagInvoiceBulkLoading = useIsMutating({
    mutationKey: ['unflagInvoice', 'bulk-action'],
  });

  const selectedRows = props.selectedRows?.map(
    (f) => f.data
  ) as unknown as InvoiceDropdownInterfaceProps[];

  const modifiedActions = actions.filter((f) => !eActionList.includes(f.key));
  const { onActivityFormTransform, onActivityAssociatedFormTransform } = useFormCustomTransformer();
  const onFormMutateFn = useFormSubmitMutate();
  const customerId = map(selectedRows, (item) => item?.customer_id);

  function customTransformer(data: Record<FormItemNames, any>, customProps?: CustomActivityProps) {
    if (!customProps) return {};

    const { associatedData, parentType } = customProps;
    const invoices = map(selectedRows, (item) => item.id);

    if (!associatedData && !parentType) {
      return onActivityFormTransform({
        data,
        customProps,
        noteRefid: invoices,
        customerId,
        invoices,
        selectAll,
      });
    } else if (associatedData && parentType) {
      return onActivityAssociatedFormTransform({
        data,
        customProps,
        customerId: castArray(associatedData?.customer_id),
      });
    }

    return {};
  }

  function onMutateFn(data: MutationFunctionProps<ActivityMutationProps>) {
    const bulkActionUrl = !isFromInvoiceList
      ? `customers/${customerDetails.id}/invoices`
      : 'invoices';

    return onFormMutateFn(data, bulkActionUrl, selectAll, currentFilterParam, !isFromInvoiceList);
  }

  const EmailActivitiesButton = (
    <Button
      onClick={() => {
        setQuickAction(ELastContactActivity.EMAIL, selectedRows);
      }}
      icon={<FontAwesomeIcon icon={['far', 'envelope']} color="var(--primary-7)" />}
    >
      Followup Email
    </Button>
  );

  return (
    <Flex align="center" gap="var(--space-12)" wrap="wrap">
      {EmailActivitiesButton}
      <ActivityButtons
        invoice={selectedRows as InvoiceBasicDetails[]}
        customerId={customerId}
        customerCurrency={customerDetails.currency}
        displayKeys={[
          { key: 'CALL_LOG', type: 'button', hide: selectAll || isFromInvoiceList },
          { key: 'PROMISE_TO_PAY', type: 'button', hide: selectAll || isFromInvoiceList },
          { key: 'NOTE', type: 'button' },
          { key: 'DISPUTE', type: 'button', hide: isFromInvoiceList },
          { key: 'ESCALATION', type: 'button' },
          { key: 'TASK', type: 'button' },
        ]}
        onMutateFn={onMutateFn}
        bulkRecordsLength={bulkRecordsLength}
        customTransformer={customTransformer}
        bulkAction={selectAll}
      />
      {modifiedActions.map((action) => {
        return (
          <GrowButton
            key={action.key}
            icon={<FontAwesomeIcon icon={action.icon as IconProp} color="var(--primary-7)" />}
            onClick={() => {
              if (Eactions.includes(action.key as EActions)) {
                props.setAction(action.key as EActions, selectedRows);
              }
            }}
            loading={
              actionKey === action.key
                ? Boolean(
                    pauseResumestrategyLoading ||
                      pauseResumestrategyBulkLoading ||
                      unflagInvoiceLoading ||
                      unflagInvoiceBulkLoading
                  )
                : false
            }
          >
            {action.label}
          </GrowButton>
        );
      })}
    </Flex>
  );
}

export { OpenInvoiceBulkAction };
