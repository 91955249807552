import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { GrowPicker } from 'components/BaseComponents/ColorPicker/GrowPicker';
import { formattingOptions } from 'lib/RichText';
import { styled } from 'lib/css-in-js';
import { commonButtonProps } from '../../Toolbar';
import { ColorPalleteContainer } from '../../common/ColorPallete';
import { ToolbarOptionProps } from '../../type';
const t = {
  label: 'Text Color',
  title: 'Text Color',
};

const defaultColor = '#000000';

const StyledIcon = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0px;
    height: 2px;
    width: 100%;
    border-radius: var(--br-1);
    background: ${(props) => props.color ?? defaultColor};
  }
`;

const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
  title: t.label,
};

interface TextColorProps extends ToolbarOptionProps {}

export function TextColor({ editor }: TextColorProps) {
  const currentColor = formattingOptions.fontColor.getCurrentColor(editor) ?? defaultColor;

  const Icon = (
    <StyledIcon className="color-container" color={currentColor}>
      <FontAwesomeIcon icon={['far', 'a']} />
    </StyledIcon>
  );

  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    icon: Icon,
  };

  const CurrentColorPalette = <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />;

  function onSelectCallback(
    color: Parameters<
      NonNullable<React.ComponentProps<typeof ColorPalleteContainer>['onSelectCallback']>
    >[0]
  ) {
    formattingOptions.fontColor.onClick(editor, color);
  }

  return (
    <GrowPicker
      defaultValue={currentColor}
      value={currentColor}
      onChange={onSelectCallback}
      overlay={CurrentColorPalette}
      showPicker
    />
  );
}
