import { PayloadAction } from '@reduxjs/toolkit';
import { NEW_DUNNING_RULE_ACTION_ID } from 'store/collection-strategy';
import {
  resetActionErrors,
  validateAction,
  validateDunningRule,
  validateRuleEvent,
  validateRuleTitle,
} from 'store/collection-strategy-rule/validations';
import { FollowupType } from 'types/entities/collection-strategy';
import {
  FollowupRuleActionForm,
  FollowupRuleActionType,
} from 'types/entities/collection-strategy/rule-action';
import { FollowupRuleEventForm } from 'types/entities/collection-strategy/rule-event';
import {
  FollowupRuleScopeType,
  InvoiceSegmentScope,
} from 'types/entities/collection-strategy/rule-scope';
import {
  DunningRuleForm,
  InitialState,
  RuleErrors,
  defaultEmailValues,
  defaultEscalationValues,
  defaultTaskValues,
  invoiceInitialState,
  statementInitialState,
} from './initial-state';

interface ToggleDrawerAction {
  visible: boolean;
  reset?: boolean;
  followupType?: FollowupType;
}
export function toggleDrawer(state: InitialState, action: PayloadAction<ToggleDrawerAction>) {
  const { visible, reset, followupType } = action.payload;

  if (followupType && reset) {
    const resettingState =
      followupType === FollowupType.INVOICE ? invoiceInitialState : statementInitialState;

    return { ...resettingState, formVisible: visible };
  }

  state.formVisible = visible;
  state.showErrors = false;
}

interface SetDunningRuleProps {
  rule: DunningRuleForm;
  followupType: FollowupType;
  formVisible?: boolean;
}
export function setDunningRule(state: InitialState, action: PayloadAction<SetDunningRuleProps>) {
  const { rule, followupType, formVisible = false } = action.payload;
  state.rule = rule;
  state.errors = validateDunningRule(rule);
  state.showErrors = false;
  state.followupType = followupType;
  state.formVisible = formVisible;
}

export function updateTitle(state: InitialState, action: PayloadAction<string>) {
  state.rule.data.title = action.payload;

  state.errors = { ...state.errors, ...validateRuleTitle(action.payload) };
}

export function saveInvoiceSegmentScope(
  state: InitialState,
  action: PayloadAction<InvoiceSegmentScope>
) {
  state.rule.data.scopes = state.rule.data.scopes
    .filter((scope) => scope.type !== FollowupRuleScopeType.INVOICE_SEGMENT)
    .concat(action.payload);
}

export function removeInvoiceSegmentScope(state: InitialState) {
  state.rule.data.scopes = state.rule.data.scopes.filter((scope) => {
    return scope.type !== FollowupRuleScopeType.INVOICE_SEGMENT;
  });
}

export function updateEventData(state: InitialState, action: PayloadAction<FollowupRuleEventForm>) {
  state.rule.data.event = {
    ...state.rule.data.event,
    ...action.payload,
  };

  state.errors = { ...state.errors, ...validateRuleEvent(state.rule.data.event) };
}

const RuleActionTypeDataMap: Record<FollowupRuleActionType, FollowupRuleActionForm['data']> = {
  [FollowupRuleActionType.EMAIL]: defaultEmailValues,
  [FollowupRuleActionType.TASK]: defaultTaskValues,
  [FollowupRuleActionType.ESCALATION]: defaultEscalationValues,
};

export function updateActionType(
  state: InitialState,
  action: PayloadAction<FollowupRuleActionType>
) {
  state.rule.data.action.type = action.payload;
  state.rule.data.action.data = RuleActionTypeDataMap[action.payload];
  state.rule.data.action.id = NEW_DUNNING_RULE_ACTION_ID;

  state.errors = {
    ...state.errors,
    ...resetActionErrors(),
    ...validateAction(state.rule.data.action),
  };
}

export function updateActionData(
  state: InitialState,
  action: PayloadAction<FollowupRuleActionForm>
) {
  state.rule.data.action = {
    ...state.rule.data.action,
    ...action.payload,
  };

  state.errors = { ...state.errors, ...validateAction(state.rule.data.action) };
}

interface RuleErrorsProps {
  showErrors: boolean;
  errors?: RuleErrors;
}
export function setRuleErrors(state: InitialState, action: PayloadAction<RuleErrorsProps>) {
  const { showErrors, errors: errorsToSet } = action.payload;
  state.showErrors = showErrors;
  state.errors = { ...state.errors, ...errorsToSet };
}

export function setShowErrors(state: InitialState, action: PayloadAction<boolean>) {
  state.showErrors = action.payload;
}
