export function requiredErrorMessage(entity: string) {
  return `${entity} is required`;
}
export function maxLengthErrorMessage(entity: string, length: number) {
  return `${entity} can have maximum length of ${length} `;
}
export function minLengthErrorMessage(entity: string, length: number) {
  return `${entity} should have minimum length of ${length} `;
}
export function minCountErrorMessage(entity: string, count: number) {
  return `Please input atleast ${count} ${entity}`;
}
export function duplicateErrorMessage(entity: string, value: string) {
  return `${entity} ${value} is already used`;
}
