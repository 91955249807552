export const t = {
  edit_filter_view: 'Edit View Label',
  save_filter_view: 'Save View ',
  delete_filter_view: 'Delete View ',
  save_as_filter_view: 'Save View as',
  reset_filter_view: 'Reset View ',
  warning_title: 'Delete Filter',
  warning_desc: 'Are you sure you want to delete this permanently?',
  save: 'Save',
  saving: 'Saving',
  validation_text: 'Filter view name already exists',
  title: 'title',
  custom_filter_views: 'Custom Filter Views',
  default_filter_views: 'Default Filter Views',
  err_message: {
    cant_save: 'Cannot save the filter!',
    cant_delete: 'Cannot delete the filter view',
  },
};
