import { SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { StyledSelect } from './style';

type Variants = React.ComponentProps<typeof StyledSelect>['displayIn'];
interface ArrowSelectProps<T> {
  value: string;
  onChange: SelectProps<T>['onChange'];
  options: { label: string; value: string; style?: React.CSSProperties }[];
  arrowStyle: Variants;
  style?: React.CSSProperties;
}
// Arrow: A borderless Ant select with overrided colors.
// Mainly for Dashboard and specifically to choose Time Period
export default function ArrowSelect<T>(props: ArrowSelectProps<T>) {
  return (
    <StyledSelect
      getPopupContainer={(trigger) => {
        // To make dropdown attached to the Select so style can be overrided from StyledComponents
        return trigger;
      }}
      style={{ ...props.style }}
      value={props.value}
      onChange={(value, options) => {
        props.onChange?.(value as unknown as T, options as DefaultOptionType | DefaultOptionType[]);
      }}
      options={props.options}
      displayIn={props.arrowStyle}
      popupMatchSelectWidth={false}
      variant='borderless'
    />
  );
}
