import { HTTPError } from 'ky';
import { notify } from '../components/BaseComponents/Notifications';
import { getSearchResults } from '../services/search';
import {
  addToFetchingHandler,
  AsyncFetches,
  clearFetchingHandler,
} from '../store/async-fetches/fetches';
import { clearStatusAndMessageHandler, getSearchResultsHandler } from '../store/search/search';
import { SearchResultsResponseState } from '../store/search/types';
import { AppThunk } from '../store/store';

export const getSearchResultsThunk = (
  text: string,
  filter: string
): AppThunk<Promise<{ payload: SearchResultsResponseState; type: string }>> => {
  return async (dispatch) => {
    dispatch(clearStatusAndMessageHandler());
    dispatch(addToFetchingHandler(AsyncFetches.GLOBAL_SEARCH));
    try {
      return dispatch(
        getSearchResultsHandler({ status: 200, results: await getSearchResults(text, filter) })
      );
    } catch (err) {
      const error = err as HTTPError;
      notify.error('Search Failed!', { description: 'Please try searching again.' });
      return dispatch(getSearchResultsHandler({ status: 500, message: error?.message }));
    } finally {
      dispatch(clearFetchingHandler(AsyncFetches.GLOBAL_SEARCH));
    }
  };
};

export const clearSearchResultsThunk = (): AppThunk<
  Promise<{ payload: undefined; type: string }>
> => {
  return async (dispatch) => {
    return dispatch(clearStatusAndMessageHandler());
  };
};
