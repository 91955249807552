import dayjs, { Dayjs } from 'dayjs';
import { FocusEventHandler, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { formatToDefault } from 'util/datetime-formatter';
import { DatePicker } from '../AntComponents';

const { RangePicker } = DatePicker;
const FORMAT = 'YYYY-MM-DD';

export interface BaseCalendarProps {
  size?: 'large' | 'middle' | 'small';
  format?: string;
  onBlur?: FocusEventHandler<any>;
}

interface DatePickerProps extends BaseCalendarProps {
  currentDate?: string;
  allowClear?: boolean;
  disabledDate?: (currentDate: Dayjs) => boolean;
  onChangeCallBack: (arg: Dayjs, arg1: string | string[]) => void;
  customFormat?: string;
  customStyle?: Object;
}

export interface DateRangePickerProps extends BaseCalendarProps {
  from?: string | number | undefined;
  to?: string | number | undefined;
  onChange: (arg: Dayjs[], arg1: string[]) => void;
  allowClear?: boolean;
}

export const DateRangePickerComponent = (props: DateRangePickerProps) => {
  const [from, setFrom] = useState(dayjs());
  const [to, setTo] = useState(dayjs().add(10, 'd'));
  const dateFormat = props.format ?? FORMAT;

  useEffect(() => {
    if (props.from) {
      setFrom(dayjs(props.from));
    } else {
      setFrom(dayjs());
    }
    if (props.to) {
      setTo(dayjs(props.to));
    } else {
      setTo(dayjs().add(10, 'd'));
    }
  }, [props.from, props.to, props.format]);

  return (
    <div className="calendarContainer" style={{ position: 'relative', minHeight: 40 }}>
      <RangePicker
        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
        format={dateFormat}
        value={[from, to] as [Dayjs, Dayjs]}
        size={props.size ? props.size : 'large'}
        onChange={(currentSelectedDateRange, currentSelectedDateRangeString) => {
          props.onChange &&
            props.onChange(currentSelectedDateRange as Dayjs[], currentSelectedDateRangeString);
        }}
        allowClear={props.allowClear}
      />
    </div>
  );
};

export const DatePickerComponent = (props: DatePickerProps) => {
  const [currentDate, setCurrentDate] = useState<Dayjs | string>();
  const format = useSelector(dateFormatSelector);
  useEffect(() => {
    if (props.currentDate) {
      setCurrentDate(dayjs(props.currentDate));
    }
  }, [format, props.currentDate, props.customFormat]);

  return (
    <div>
      <DatePicker
        {...props}
        onBlur={props.onBlur}
        size={props.size ? props.size : 'large'}
        format={format}
        value={currentDate as Dayjs}
        onChange={(currentSelectedDateRange, currentSelectedDateRangeString) => {
          const value = currentSelectedDateRange?.format(formatToDefault) ?? '';
          props.onChangeCallBack &&
               props.onChangeCallBack(currentSelectedDateRange as Dayjs, value);
        }}
        style={props?.customStyle}
      />
    </div>
  );
};
