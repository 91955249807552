import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers/rootReducer';
import { IReportingData, IReportingTags } from './type';

const initialState: IReportingData = {
  reportingTagsCollection: [] as IReportingTags[],
};

const reportingTagsSlice = createSlice({
  name: 'reportingTags',
  initialState,
  reducers: {
    updateReportingTags(state, action: PayloadAction<IReportingTags[]>) {
      state.reportingTagsCollection = action.payload;
    },
  },
});

export const { updateReportingTags } = reportingTagsSlice.actions;

export default reportingTagsSlice.reducer;

export const reportingTagsCollectionSelector = (state: RootState) => state.reportingTags;
export const reportingTagsSelector = createSelector(
  reportingTagsCollectionSelector,
  (reportingTagsCollectionSelector) => reportingTagsCollectionSelector.reportingTagsCollection
);
