import { DateRangeSelectInput } from 'components/BaseComponents/DateRangeSelect/DateRangeSelectInput';
import { DateRangeString } from 'components/BaseComponents/DateRangeSelect/types';
import { allDateRangeOptions } from 'components/BaseComponents/DateRangeSelect/utils';
import { Rank } from 'types/utils/utils';
import { OrderByRank } from 'util/customOrderby';
import { RelativeDateRange } from 'util/relative-date-range';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export interface DateRangeFilterAdditionalProps {
  format?: string;
  options?: RelativeDateRange[];
  customSortOrder?: Rank;
}

interface DateRangeFilterProps {
  value: DateRangeString;
  onChange?: (value: DateRangeString) => void;
  additionalProps?: {
    format?: string;
    options?: RelativeDateRange[];
    customSortOrder?: Rank;
  };
}

export function DateRangeFilter(props: DateRangeFilterProps) {
  let dateRangeOptions;
  const optionsList = props.additionalProps?.options;

  if (optionsList) {
    const options = allDateRangeOptions().filter((option) => optionsList?.includes(option.id));
    dateRangeOptions = props.additionalProps?.customSortOrder
      ? OrderByRank(options, props.additionalProps.customSortOrder, 'id')
      : options;
  }

  return (
    <DateRangeSelectInput
      value={props.value}
      onChange={props.onChange}
      format={props.additionalProps?.format ?? DEFAULT_DATE_FORMAT}
      options={dateRangeOptions}
    />
  );
}
