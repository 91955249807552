import { DocumentFilter, DocumentListView } from 'components/Documents/types';
import { ActivityMutationProps } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { RequestConfig } from 'types/common/common';
import { PageList } from 'types/common/page-list';
import {
  CreateADocumentTypes,
  CreateDocument,
  DocumentTypeFilterKeys,
  DocumentTypesList,
} from 'types/entities/documents';
import ky from './ky';

export async function createADocumentType(
  params: CreateADocumentTypes
): Promise<DocumentTypesList> {
  return await ky.post('v1/document-types', { json: params }).json();
}

export async function getDocumentTypesList(params: RequestConfig<DocumentTypeFilterKeys>) {
  const { page, size, filters } = params;
  return await ky
    .post('v1/document-types/report', {
      json: filters,
      searchParams: {
        page,
        size,
      },
    })
    .json<DocumentTypesList[]>();
}

export async function getDocumentTypesListPages(
  params: RequestConfig<DocumentTypeFilterKeys>
): Promise<PageList<DocumentTypesList, unknown>> {
  const { page, size, filters } = params;
  return await ky
    .post('v1/document-types/report', {
      json: filters,
      searchParams: {
        page,
        size,
      },
    })
    .json();
}
export async function updateDocumentTypes(
  params: Partial<DocumentTypesList>
): Promise<DocumentTypesList> {
  return await ky.patch(`v1/document-types/${params.id}`, { json: params }).json();
}

export async function deleteADocumentType(params: Pick<DocumentTypesList, 'id'>) {
  return await ky.delete(`v1/document-types/${params.id}`);
}
export async function createDocuments(
  params: CreateDocument,
  customerId: number
): Promise<ActivityMutationProps> {
  return (await ky.post(`customers/${customerId}/documents`, {
    json: params,
  })) as unknown as Promise<ActivityMutationProps>;
}

export async function getInvoiceDocumentsList(
  params: RequestConfig<DocumentFilter>,
  invoiceId: string
): Promise<PageList<DocumentListView, unknown>> {
  const { page, size, filters } = params;
  return await ky
    .post(`invoices/${invoiceId}/document-list`, {
      json: filters,
      searchParams: {
        page,
        size,
      },
    })
    .json();
}

export async function getCustomerDocumentsList(
  params: RequestConfig<DocumentFilter>,
  customerId: string
): Promise<PageList<DocumentListView, unknown>> {
  const { page, size, filters } = params;
  return await ky
    .post(`customers/${customerId}/document-list`, {
      json: filters,
      searchParams: {
        page,
        size,
      },
    })
    .json();
}

export async function updateDocuments(params: Partial<CreateDocument>, id: string) {
  return await ky.patch(`v1/documents/${id}`, { json: { ...params } }).json();
}

export async function deleteDocument(id: string) {
  return await ky.delete(`v1/documents/${id}`);
}
