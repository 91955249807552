import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';
import React, { ReactNode } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  DefaultValues,
  FieldError,
  FormProvider,
  useForm,
  UseFormStateReturn,
  ValidationMode,
} from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

type FieldName = string;
interface WrappedFormProviderProps {
  children: ReactNode;
  schema?: yup.AnyObjectSchema;
  mode?: keyof ValidationMode;
  shouldUnregister?: boolean;
}

export function WrappedFormProvider(props: Readonly<WrappedFormProviderProps>) {
  const formMethods = useForm({
    resolver: yupResolver((props.schema as yup.AnyObjectSchema) ?? yup.object().shape({})),
    mode: props.mode ?? 'all',
    shouldUnregister: props.shouldUnregister,
  });

  return <FormProvider {...formMethods}>{props.children}</FormProvider>;
}

interface ErrorFieldProps {
  field: ControllerRenderProps<any, string>;
  formState: UseFormStateReturn<any>;
  noImplicitGapForError?: boolean;
  validateParam?: string;
}
function ErrorFields({
  field,
  formState,
  noImplicitGapForError,
  validateParam,
}: Readonly<ErrorFieldProps>) {
  const { errors } = formState;

  function getNestedValidationMessage() {
    if (!errors[field.name]) return;
    if (!Array.isArray(errors[field.name])) return;
    const message = errors[field.name] ? [errors[field.name]].flat()[0] : undefined;
    return validateParam && message
      ? (message[validateParam as keyof typeof message] as FieldError).message
      : undefined;
  }
  const errorMessage = get(errors, field.name);

  const nestedMessage = getNestedValidationMessage();
  const validationMessage = nestedMessage ? nestedMessage : errorMessage?.message;

  return (
    <>
      {!noImplicitGapForError ? (
        <div className={`error-message label ${validationMessage ? '' : 'invisible'}`}>
          {validationMessage ?? ':'}
        </div>
      ) : (
        errorMessage && (
          <div
            className={`error-message label ${
              validationMessage ? 'active-transition' : 'hide-transition'
            }`}
          >
            {validationMessage}
          </div>
        )
      )}
    </>
  );
}

interface ControlledFormItemProps {
  fieldName: FieldName;
  control: Control<any>;
  defaultValue?: DefaultValues<any>;
  children: React.ReactElement;
  shouldUnregister?: boolean;
  customValueField?: string;
  customOnChangeField?: string;
  noImplicitGapForError?: boolean;
  style?: React.CSSProperties;
  validateParam?: string;
}
export const FormItem = styled.div`
  .error-message {
    color: var(--red-7);

    &.invisible {
      visibility: hidden;
    }
  }
`;

export function ControlledFormItem(props: Readonly<ControlledFormItemProps>) {
  const {
    noImplicitGapForError,
    control,
    fieldName,
    defaultValue,
    shouldUnregister,
    customValueField,
    customOnChangeField,
    style,
    validateParam,
  } = props;

  return (
    <Controller
      control={control}
      shouldUnregister={shouldUnregister ?? true}
      name={fieldName}
      defaultValue={defaultValue}
      render={({ field, formState }) => {
        const { errors } = formState;

        return (
          <FormItem
            style={style}
            className={`form-element ${errors[field.name] ? 'error-badge' : ''}`}
          >
            {React.cloneElement(props.children, {
              ...field,
              [customValueField ?? 'value']: field.value ?? undefined,
              [customOnChangeField ?? 'onChange']: field.onChange,
              onBlur: field.onBlur,
            })}
            <ErrorFields
              field={field}
              formState={formState}
              noImplicitGapForError={noImplicitGapForError}
              validateParam={validateParam}
            />
          </FormItem>
        );
      }}
    />
  );
}
