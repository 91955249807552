import { AvatarProps } from 'antd';
import { GrowAvatar } from 'components/BaseComponents/Avatar';
import React from 'react';
import { getInitials } from 'util/name-formatter';
import { getAvatarColor } from './predicate';

export interface UserAvatarProps extends AvatarProps {
  name?: string | [string, string];
  photoUrl?: string;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export function UserAvatar(props: Readonly<UserAvatarProps>) {
  const { name, photoUrl, icon, style, className, ...avatarProps } = props;
  function getChars(names: string[]) {
    return names.join(' ').length;
  }

  const ComponentToRender = photoUrl ? (
    <AvatarWithPhotoUrl
      className={className}
      style={style}
      url={photoUrl}
      avatarProps={avatarProps}
    />
  ) : icon ? (
    <AvatarWithIcon className={className} style={style} icon={icon} avatarProps={avatarProps} />
  ) : name ? (
    <AvatarWithNames
      colorIndex={Array.isArray(name) ? getChars(name) : getChars([name])}
      className={className}
      style={style}
      intials={Array.isArray(name) ? getInitials(name) : getInitials([name])}
      avatarProps={avatarProps}
    />
  ) : (
    <AvatarWithNames
      colorIndex={0}
      className={className}
      avatarProps={avatarProps}
      style={props.style}
    />
  );

  return <>{ComponentToRender}</>;
}

interface AvatarWithPhotoUrlProps {
  url: string;
  style?: React.CSSProperties;
  className?: string;
  avatarProps?: AvatarProps;
  colorIndex?: number;
}
function AvatarWithPhotoUrl(props: Readonly<AvatarWithPhotoUrlProps>) {
  return (
    <GrowAvatar
      {...props.avatarProps}
      className={props.className}
      style={props.style}
      src={props.url}
    />
  );
}

interface AvatarWithIconProps {
  icon: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  avatarProps?: AvatarProps;
}
function AvatarWithIcon(props: Readonly<AvatarWithIconProps>) {
  return (
    <GrowAvatar
      {...props.avatarProps}
      className={props.className}
      style={props.style}
      icon={props.icon}
    />
  );
}

interface AvatarWithNameProps {
  intials?: string;
  style?: React.CSSProperties;
  className?: string;
  avatarProps?: AvatarProps;
  colorIndex: number;
}
function AvatarWithNames(props: Readonly<AvatarWithNameProps>) {
  const color = getAvatarColor(props.colorIndex);
  const finalStyle: React.CSSProperties = {
    ...props.style,
    background: props.intials ? color : props.style?.backgroundColor,
  };

  return (
    <GrowAvatar {...props.avatarProps} style={finalStyle} className={props.className}>
      {props.intials}
    </GrowAvatar>
  );
}
