import TableHeader from '@tiptap/extension-table-header';
import { mergeAttributes } from '@tiptap/react';
import { TransformPastedHTML } from '../TipTapEditor/extensions/default-paste';

export const CustomTableHeader = TableHeader.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      style: {
        default:
          'background-color: #fafafa; font-weight: bold; text-align: left;border: 1px solid #ced4da; box-sizing: border-box; min-width: 1em; padding: 6px 8px; position: relative; vertical-align: top; color:#120338',
        parseHTML: (element) => {
          const style = element.getAttribute('style') || '';
          const thead = element.closest('thead');
          if (thead) {
            const theadStyle = thead.getAttribute('style');
            if (theadStyle) {
              return `${theadStyle}; ${style}`;
            }
          }

          return style;
        },
        renderHTML: (attributes) => {
          if (typeof attributes.style === 'object') {
            // Convert the style object to a CSS string
            return {
              style: Object.entries(attributes.style)
                .map(([key, value]) => `${key}: ${value}`)
                .join('; '),
            };
          }
          return { style: attributes.style };
        },
      },
    };
  },
  renderHTML(props) {
    return ['th', mergeAttributes(props.HTMLAttributes), 0];
  },
  addProseMirrorPlugins() {
    return [TransformPastedHTML()];
  },
});
