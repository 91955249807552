import { notify } from 'components/BaseComponents/Notifications';
import Tabs from 'components/BaseComponents/Tabs';
import { styled } from 'lib/css-in-js';
import { useState } from 'react';
import { AllAccountSourceProps } from 'types/api/currency-page-settings';
import { BaseCurrencySymbolProps } from '../../type';
import { useSelectedCurrencies } from '../CurrencyContextApi/ContextApi';
import DifferentRateOption from './DifferentRates/DifferentRateOption';
import { head } from 'lodash';
type TabProps = React.ComponentProps<typeof Tabs>;
interface CurrencyConfigProps extends BaseCurrencySymbolProps {
  accountSourceData: AllAccountSourceProps[];
}
const UNSAVED_CHANGES_MESSAGE =
  'There are unsaved changes. Please save your changes before switching tabs.';

function formatLabel(sourceType: string) {
  const words = sourceType.replace(/_/g, ' ').toLowerCase().split(' ');
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
const StyledTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  gap: var(--space-12);
  margin-left: 0 !important;
  margin-right: 0 !important;
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
  }
`;
function CurrencyExchangeTab({ accountSourceData, baseCurrency }: CurrencyConfigProps) {
  const { setSelectedCurrenciesWithValues, unsavedChanges } = useSelectedCurrencies();
  const [activeTab, setActiveTab] = useState<string>();

  function createERPTabOptions(accountSourceData: AllAccountSourceProps[]) {
    const accountSourceDataLookUp = new Map<string, Map<string, AllAccountSourceProps>>();

    accountSourceData.forEach((item: AllAccountSourceProps) => {
      const { source_type, org_id } = item;
      if (!accountSourceDataLookUp.has(source_type)) {
        accountSourceDataLookUp.set(source_type, new Map<string, AllAccountSourceProps>());
      }

      const sourceTypeMap = accountSourceDataLookUp.get(source_type);
      if (sourceTypeMap) {
        sourceTypeMap.set(org_id, item);
      }
    });

    const tabItems: TabProps['items'] = [];
    let keyCounter = 1;

    accountSourceDataLookUp.forEach((orgIdMap, source_type) => {
      orgIdMap.forEach((orgIdCorrespondData, orgId) => {
        const formattedLabel =
          orgIdMap.size === 1
            ? formatLabel(source_type)
            : `${formatLabel(source_type)} #${keyCounter++}`;

        tabItems.push({
          key: formattedLabel,
          label: formattedLabel,
          children: orgIdCorrespondData ? (
            <DifferentRateOption
              tabData={orgIdCorrespondData}
              key={orgId}
              label={formattedLabel}
              baseCurrency={baseCurrency}
            />
          ) : (
            <></>
          ),
        });
      });
    });

    return tabItems;
  }

  const tabItems = createERPTabOptions(accountSourceData);

  function handleTabChange(activeKey: string) {
    if (unsavedChanges) {
      notify.warning(UNSAVED_CHANGES_MESSAGE);
    } else {
      switchTab(activeKey);
    }
  }

  function switchTab(activeKey: string) {
    setActiveTab(activeKey);
    setSelectedCurrenciesWithValues([]);
  }

  return (
    <StyledTabs
      activeKey={activeTab ?? head(tabItems)?.key}
      items={tabItems}
      destroyInactiveTabPane
      onTabClick={handleTabChange}
    />
  );
}

export { CurrencyExchangeTab };
