import { Collapse, CollapseProps } from 'antd';
import React from 'react';

const { Panel } = Collapse;

interface CollapseComponentProps extends CollapseProps {
  title?: string;
  children?: React.ReactNode;
  defaultOpen?: Boolean;
}

const CollapseComponent = (props: CollapseComponentProps) => {
  const expandIcon = ({ isActive }: { isActive?: boolean }) => {
    return props.expandIcon ? props.expandIcon({ isActive }) : <></>;
  };

  return (
    <Collapse
      bordered={props.bordered}
      expandIconPosition="right"
      className={props.className}
      expandIcon={expandIcon}
      onChange={props.onChange}
      defaultActiveKey={props.defaultOpen ? '1' : '0'}
    >
      <Panel header={props.title} key="1">
        {props.children}
      </Panel>
    </Collapse>
  );
};

export default CollapseComponent;
