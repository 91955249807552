import { SortOrder } from '@sinecycle/growcomponents';
import { DateRangeOption } from 'components/BaseComponents/DateRangeSelect/types';
import { PaymentMode } from 'components/CollectionActivities/types';
import { Payment, PaymentStatus } from 'types/activities/payment';

export interface PaymentListFilterView {
  id: string;
  name: string;
  system_defined: boolean;
  payments_report_filter: PaymentListFilter;
}

export enum PaymentListSwitcher {
  ALL = 'ALL',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface CustomFieldOptions {
  id: number;
  selected_options: string[];
}

export interface PaymentListFilter {
  view: PaymentListSwitcher;
  sort_by?: SortOrder;
  sort_col?: string;
  from_date?: string;
  to_date?: string;
  payment_date_range?: DateRangeOption;
  my_customers_only?: boolean;
  my_team_customers_only?: boolean;
  // custom_fields?: CustomFieldFilterValue[];
  custom_fields?: CustomFieldOptions[];
  subsidiary_ids?: number[];

  mode?: PaymentMode[];
  status?: PaymentStatus[];

  collection_owner_ids?: number[];
  account_manager_ids?: number[];
  customer_success_manager_ids?: number[];
  customer_id?: number[];
  participant_scope: string;
  view_scope: string;
}

export type PaymentListSummaryData = {
  amount_base_currency: number;
  base_currency: string;
  splits: Array<{
    currency: string;
    amount: number;
    amount_base_currency: string;
    base_currency: string;
  }>;
};

export type PaymentListSummary = {
  total_amount: PaymentListSummaryData;
  total_applied_amount: PaymentListSummaryData;
  total_unapplied_amount: PaymentListSummaryData;
  base_currency: string;
};

export type PaymentListData = {
  list: Array<Payment>;
  total_records: number;
  current_page: number;
  total_pages: number;
  summary: PaymentListSummary;
};
