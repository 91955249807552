import { Divider, Form, Input, Radio, Space } from 'antd';
import { SelectWrapper } from 'components/BaseComponents/ASelect/SelectWrapper';
import { GrowPicker } from 'components/BaseComponents/ColorPicker/GrowPicker';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { FormActionButtons } from 'components/Common/FormActionButtons';
import React, { useMemo } from 'react';
import { LinkButton } from './LinkButton';
import { ButtonSize } from './util';
const t = {
  title: 'Add Button',
  linkURL: 'Link URL',
  placeholder: 'Placeholder',
  default: 'Default',
  small: 'Small',
  large: 'Large',
  preview: 'Preview',
  left: 'Left',
  center: 'Center',
  right: 'Right',
  displayText: 'Display Text',
  alignment: 'Alignment',
  size: 'Size',
  bgColor: 'Background Color',
};

export interface AddButtonProps {
  displayText?: string;
  linkURL?: string;
  align?: React.CSSProperties['justifyContent'];
  size?: ButtonSize;
  background?: string;
}

interface AddButtonCanvasProps {
  onSuccess: (buttonStyle: AddButtonProps) => void;
  onCancel?: () => void;
}

export function AddButtonCanvas(props: AddButtonCanvasProps) {
  const [form] = Form.useForm();
  const displayValue = Form.useWatch('buttonText', form);
  const buttonSizeValue = Form.useWatch('buttonSize', form) as ButtonSize;
  const buttonColorValue = Form.useWatch('buttonColor', form);
  const buttonURL = Form.useWatch('buttonURL', form);
  const buttonAlignProp = Form.useWatch('buttonAlign', form) ?? 'start';
  const urlGroup = Form.useWatch('buttonURLSelection', form) ?? 'url';
  const placeholderURL = Form.useWatch('placeholderURL', form);

  const title = (
    <Texto size="16" weight="regular">
      {t.title}
    </Texto>
  );

  const buttonStyle = useMemo(() => {
    return {
      displayText: displayValue,
      linkURL: buttonURL ?? placeholderURL,
      align: buttonAlignProp,
      size: buttonSizeValue,
      background: buttonColorValue,
    };
  }, [displayValue, buttonURL, placeholderURL, buttonAlignProp, buttonSizeValue, buttonColorValue]);

  const ButtonPreview = <LinkButton buttonStyle={buttonStyle} />;

  const DisplayText = (
    <div className="display-container">
      <Input placeholder="Type in a label ..." />
    </div>
  );

  const placeHolderOptions = [
    {
      value: '{{customer.payment_url}}',
      label: 'Payment Link',
    },
  ];

  const URL = (
    <Radio.Group name="radiogroup" defaultValue={'url'}>
      <Space direction="vertical">
        <Radio value={'url'}>
          <Texto weight={urlGroup === 'url' ? 'semibold' : undefined}>{t.linkURL}</Texto>
          {urlGroup === 'url' && (
            <Form.Item
              name="buttonURL"
              noStyle
              rules={[
                { required: true, message: 'Please input a valid URL!' },
                {
                  type: 'url',
                  message: 'Please input a valid URL! ',
                },
              ]}
            >
              <Input placeholder="Provide a valid link ..." />
            </Form.Item>
          )}
        </Radio>
        <Radio value={'placeholder'}>
          <Texto weight={urlGroup === 'placeholder' ? 'semibold' : undefined}>
            {t.placeholder}
          </Texto>
          {urlGroup === 'placeholder' && (
            <Form.Item
              noStyle
              name="placeholderURL"
              rules={[{ required: true, message: 'Please select a valid placeholder!' }]}
            >
              <SelectWrapper
                options={placeHolderOptions}
                style={{ width: '240px' }}
                placeholder="Select a URL placeholder..."
              />
            </Form.Item>
          )}
        </Radio>
      </Space>
    </Radio.Group>
  );

  const ButtonAlign = (
    <Radio.Group defaultValue="start">
      <Radio.Button value="start">{t.left}</Radio.Button>
      <Radio.Button value="center">{t.center}</Radio.Button>
      <Radio.Button value="end">{t.right}</Radio.Button>
    </Radio.Group>
  );

  const Size = (
    <Radio.Group defaultValue="default">
      <Radio.Button value="small">{t.small}</Radio.Button>
      <Radio.Button value="default">{t.default}</Radio.Button>
      <Radio.Button value="large">{t.large}</Radio.Button>
    </Radio.Group>
  );

  const BackgroundColor = (
    <>
      <GrowPicker
        onChange={(value) => {
          form.setFieldValue('buttonColor', value);
        }}
      />
    </>
  );

  const Preview = (
    <div
      className="preview-container"
      style={{
        padding: 'var(--space-12)',
        minHeight: '100px',
        border: '1px dashed var(--gray-6)',
        borderRadius: 'var(--br-1)',
        background: '#FAFAFA',
      }}
    >
      <Texto size="12">{t.preview}</Texto>
      <div className="button-container">{ButtonPreview}</div>
    </div>
  );

  function onCancel() {
    props.onCancel && props.onCancel();
  }

  function onSuccess() {
    form.submit();
  }

  const ActionButtonJSX = (
    <FormActionButtons
      onCancel={onCancel}
      onSuccess={onSuccess}
      // saveDisabled={
      //   !form.isFieldsTouched(['buttonURL', 'placeholderURL']) ||
      //   (!form.isFieldsTouched(['buttonText']) &&
      //     Boolean(form.getFieldsError().filter(({ errors }) => errors.length).length))
      // }
    />
  );

  function onFinish(formValues: AddButtonProps) {
    props.onSuccess(buttonStyle);
  }

  return (
    <div className="add-button-canvas">
      {title}
      <Form
        initialValues={{ buttonColor: '#C41D7F' }}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ marginTop: 'var(--space-12)' }}
      >
        <Form.Item
          label={<Texto size="12">{t.displayText}</Texto>}
          name="buttonText"
          rules={[{ required: true, message: 'Please input button display text!' }]}
        >
          {DisplayText}
        </Form.Item>
        <Form.Item name="buttonURLSelection">{URL}</Form.Item>
        <Divider style={{ margin: 'var(--space-20) 0' }} />
        <Form.Item
          label={<Texto size="12">{t.alignment}</Texto>}
          name="buttonAlign"
          initialValue={'left'}
        >
          {ButtonAlign}
        </Form.Item>
        <Form.Item
          label={<Texto size="12">{t.size}</Texto>}
          name="buttonSize"
          initialValue={'default'}
        >
          {Size}
        </Form.Item>
        <Form.Item label={<Texto size="12">{t.bgColor}</Texto>} name="buttonColor">
          {BackgroundColor}
        </Form.Item>
        <Form.Item>{Preview}</Form.Item>
        <Form.Item style={{ textAlign: 'end' }}>{ActionButtonJSX}</Form.Item>
      </Form>
    </div>
  );
}
