import { GrowText } from 'components/BaseComponents/Typography';
import { EmailTemplates } from 'types/entities/email-templates';

interface TemplateDescProps extends Pick<EmailTemplates, 'description'> {}

function TemplateDesc(props: TemplateDescProps) {
  const { description } = props;

  return (
    <GrowText
      style={{ fontSize: 'var(--fs-14)', maxWidth: '320px' }}
      ellipsis={{
        tooltip: {
          placement: 'leftTop',
          title: description,
        },
      }}
    >
      {description ?? '-'}
    </GrowText>
  );
}

export { TemplateDesc };
