import { UploadFileTypes } from 'services/attachments';
import { Emailable } from 'types/activities/email';
import { Attachment } from 'types/common/attachments';
import { CustomerStatement } from 'types/customerstatement/customer-statement';
import { CustomerContactType } from '../contacts/contacts';
const attachmentTypes = [
  'GENERATIVE_ATTACHMENT',
  'CURRENT_INVOICE',
  'ALL_OPEN_INVOICES',
  'FILE_UPLOADS',
  'ATTACHMENT',
  'DOCUMENT',
] as const;

export type AttachmentTypes = typeof attachmentTypes[number];

export type AllOpenInvoicesAttachment = { type: Extract<AttachmentTypes, 'ALL_OPEN_INVOICES'> };
export type CurrentInvoiceAttachment = { type: Extract<AttachmentTypes, 'CURRENT_INVOICE'> };
export type DocumentAttachment = {
  type: Extract<AttachmentTypes, 'DOCUMENT'>;
  customer_document_ids?: Array<string>;
  invoice_document_ids?: Array<string>;
  customer_document_type_ids?: Array<string>;
  invoice_document_type_ids?: Array<string>;
};
export type EmailAttachment = {
  type: Extract<AttachmentTypes, 'ATTACHMENT'>;
  list: Array<Attachment>;
};
export type FileUploadAttachment = {
  type: Extract<AttachmentTypes, 'FILE_UPLOADS'>;
  list: Array<string>;
};
export type GenerativeAttachment = {
  type: Extract<AttachmentTypes, 'GENERATIVE_ATTACHMENT'>;
  list: Array<CustomerStatement>;
};

export type Attachments = Array<
  Partial<
    | AllOpenInvoicesAttachment
    | CurrentInvoiceAttachment
    | DocumentAttachment
    | EmailAttachment
    | FileUploadAttachment
    | GenerativeAttachment
  >
>;
export type FileParams = {
  fileList: File[];
  type: UploadFileTypes;
};

export type AutomatedEmailTaskItem = {
  from: FromAddress;
  to: Array<ToAddress>;
  cc?: Array<ToAddress>;
  bcc?: Array<ToAddress>;
  reply_to?: ToAddress;
  subject: string;
  body: string;
  attach_current_invoice: boolean;
  attach_all_invoices: boolean;
  attach_invoice_statement: boolean;
  file_params: FileParams;
  attachments?: Attachment[];
};

export type FromAddress = {
  key: EmailAddressType;
  value: number;
};

export enum EmailAddressType {
  USER = 'USER',
  IC = 'IC',
  CONTACT_TYPE = 'CONTACT_TYPE',
  POC = 'POC',
  AR_EMAIL = 'AR_EMAIL',
}

export type ToAddress = {
  key: EmailAddressType;
  value: number | CustomerContactType | string;
};

export enum AttachmentType {
  CURRENT_INVOICE = 'Current Invoice',
  OPEN_INVOICES = 'All Open Invoices',
}

export enum OpenInvoicesAttachmentType {
  SEPERATE_INVOICE = 'Seperate Invoice',
  CONSOLIDATED_INVOICE = 'Consolidated Invoice',
}

export type EmailResponseState = {
  status: number;
  message?: string;
  content?: { subject: string; body: string };
};

type PlaceholdersItemType = {
  [key: string]: string;
};

export const CustomerBasicPlaceholders: PlaceholdersItemType = {
  'customer.name': 'Customer Name',
  'customer.logo_url': 'Customer Logo URL',
  'customer.address': 'Customer Address',
};

export const CustomerContactPlaceholders: PlaceholdersItemType = {
  'primary_contact.first_name': 'Primary Contact First Name',
  'primary_contact.full_name': 'Primary Contact Full Name',
  'primary_contact.email': 'Primary Contact Email',
  'primary_contact.mobile_phone': 'Primary Contact Mobile Phone',
  'primary_contact.work_phone': 'Primary Contact Work Phone',
  'other_contact.first_name': 'Other Contact First Name',
  'other_contact.full_name': 'Other Contact Full Name',
  'other_contact.email': 'Other Contact Email',
  'other_contact.mobile_phone': 'Other Contact Mobile Phone',
  'other_contact.work_phone': 'Other Contact Work Phone',
};

export const CustomerInternalContactPlaceholders: PlaceholdersItemType = {
  'account_manager.first_name': 'Account Manager First Name',
  'account_manager.full_name': 'Account Manager Full Name',
  'account_manager.email': 'Account Manager Email',
  'collection_owner.first_name': 'Collections owner First Name',
  'collection_owner.full_name': 'Collections owner Full Name',
  'collection_owner.email': 'Collections owner Email',
  'cs_manager.first_name': 'Customer success First Name',
  'cs_manager.full_name': 'Customer success Full Name',
  'cs_manager.email': 'Customer success Email',
};

export const InvoicePlaceholders: PlaceholdersItemType = {
  'current_invoice.reference_number': 'Invoice Reference number',
  'current_invoice.invoice_date': 'Invoice Issued date',
  'current_invoice.amount': 'Invoice Amount',
  'current_invoice.balance_amount': 'Invoice Balance Amount',
  'current_invoice.currency': 'Invoice Currency',
  'current_invoice.due_date': 'Invoice Due Date',
  'current_invoice.age': 'Invoice Age',
  'current_invoice.due_days': 'Invoice Due',
  'current_invoice.status': 'Invoice Status',
  'current_invoice.last_followup': 'Invoice Last followed up date',
  'current_invoice.so_number': 'Invoice Associated Sales order number',
};

export const ReceivablesPlaceholders: PlaceholdersItemType = {
  'receivables.total': 'Total Receivebles',
  'receivables.total_in_dispute': 'Total Receivebles in Dispute',
  'receivables.total_without_dispute': 'Total Receivebles without Dispute',
  'receivables.due': 'Total Due',
  'receivables.overdue': 'Total Overdue',
};

export const PendingInvoicesPlaceholders: PlaceholdersItemType = {
  'pending_invoices.all': 'Pending Invoices - All',
  'pending_invoices.due': 'Pending Invoices - Due',
  'pending_invoices.overdue': 'Pending Invoices - Overdue',
};

export const SenderPlaceholders: PlaceholdersItemType = {
  'sender.first_name': 'Sender First Name',
  'sender.last_name': 'Sender Last Name',
  'sender.full_name': 'Sender Full Name',
  'sender.email': 'Sender Email id',
  'sender.current_date': 'Current Date',
};

export const MultipleInvoicesPlaceholders: PlaceholdersItemType = {
  'multiple_invoices.statement_of_invoices': 'Statement of Invoices',
};

type PlaceholdersItemsType = {
  [key: string]: typeof CustomerBasicPlaceholders;
};

export const CustomerPlaceholders: PlaceholdersItemsType = {
  basic: CustomerBasicPlaceholders,
  customer_contact: CustomerContactPlaceholders,
  internal_contact: CustomerInternalContactPlaceholders,
  custom_fields: {} as PlaceholdersItemType,
};

export type ReceivablePlaceHolder =
  | 'invoice'
  | 'multiple_invoices'
  | 'receivables'
  | 'pending_invoices'
  | 'custom_fields';

// CAUTION: if you add a key here, please also add it to the `ReceivablePlaceHolder` type
export const ReceivablePlaceholders: PlaceholdersItemsType = {
  invoice: InvoicePlaceholders,
  multiple_invoices: MultipleInvoicesPlaceholders,
  receivables: ReceivablesPlaceholders,
  pending_invoices: PendingInvoicesPlaceholders,
  custom_fields: {} as PlaceholdersItemType,
};

export const SignaturePlaceholders: PlaceholdersItemsType = {
  sender: SenderPlaceholders,
};

export type PlaceholderGroupsType = {
  customer: typeof CustomerPlaceholders;
  receivables: typeof ReceivablePlaceholders;
  signature: typeof SignaturePlaceholders;
};

export const PlaceholderGroups: PlaceholderGroupsType = {
  customer: CustomerPlaceholders,
  receivables: ReceivablePlaceholders,
  signature: SignaturePlaceholders,
};
export type PlaceholderGroup = keyof typeof PlaceholderGroups;

export enum EContactType {
  PRIMARY_CONTACT = 'PRIMARY_CONTACT',
  SECONDARY_CONTACT = 'SECONDARY_CONTACT',
  PURCHASER = 'PURCHASER',
  PAYER = 'PAYER',
}
export interface EmailAttachments {}

export interface ISendEmailParams {
  invoice_ids?: number[];
  to: Emailable[];
  cc?: Emailable[];
  bcc?: Emailable[];
  from: Emailable;
  subject?: string;
  body?: string;
  parent_email_id?: number;
  email_template_id?: number;
  group_by_customer?: boolean;
  //  need to clean up email comp this is not needed but need for modify
  attach_all_open_invoices?: boolean;
  attach_current_invoice?: boolean;
  file_upload_ids?: Array<string>;
  generative_attachments?: Array<CustomerStatement>;
  customer_document_ids?: Array<string>;
  invoice_document_ids?: Array<string>;
  customer_document_type_ids?: Array<string>;
  invoice_document_type_ids?: Array<string>;
  //  need to clean up email comp this is not needed but need for modify
  attachment: Attachments;
}
