export enum ERolesAndPrivileges {
  /**Dashboard*/
  VIEW_COLLECTOR_DASHBOARD = 'VIEW_COLLECTOR_DASHBOARD',
  ADMIN_DASHBOARD_SNAPSHOT = 'ADMIN_DASHBOARD_SNAPSHOT',
  ADMIN_DASHBOARD_ANALYSIS = 'ADMIN_DASHBOARD_ANALYSIS',
  TEAM_DASHBOARD_SNAPSHOT = 'TEAM_DASHBOARD_SNAPSHOT',
  TEAM_DASHBOARD_ANALYSIS = 'TEAM_DASHBOARD_ANALYSIS',
  VIEW_COLLABORATOR_DASHBOARD = 'VIEW_COLLABORATOR_DASHBOARD',

  /**Inbox */
  INBOX = 'INBOX',
  AR_EMAIL = 'AR_EMAIL',

  /**AR AGING*/
  AGING_NUMBERS = 'AGING_NUMBERS',
  CREATE_MANAGE_SAVED_FILTERS = 'CREATE_MANAGE_SAVED_FILTERS',
  BASIC_FILTER_FIELDS_AR_AGING = 'BASIC_FILTER_FIELDS_AR_AGING',
  ADVANCED_FILTER_FIELDS_AR_AGING = 'ADVANCED_FILTER_FIELDS_AR_AGING',
  CUSTOMER_LIST_PAGE = 'CUSTOMER_LIST_PAGE',
  CUSTOMER_SINGLE_BULK_ACTIONS = 'CUSTOMER_SINGLE_BULK_ACTIONS',
  SET_COLLECTION_OWNER_AR_AGING = 'SET_COLLECTION_OWNER',
  SET_COLLECTION_STRATEGY_AR_AGING = 'SET_COLLECTION_STRATEGY',

  /**CUSTOMER DETAIL*/
  BASIC_CUSTOMER_INFORMATION = 'BASIC_CUSTOMER_INFORMATION',
  SET_COLLECTION_STATUS = 'SET_COLLECTION_STATUS',
  SET_COLLECTION_OWNER_CUSTOMER_DETAIL = 'SET_COLLECTION_OWNER',
  SET_COLLECTION_STRATEGY_CUSTOMER_DETAIL = 'SET_COLLECTION_STRATEGY',
  VIEW_CUSTOMER_ACTIVITIES = 'VIEW_CUSTOMER_ACTIVITIES',
  VIEW_STATEMENT_INFORMATION = 'VIEW_STATEMENT_INFORMATION',
  VIEW_OPEN_INVOICES_CUSTOMER_DETAIL = 'VIEW_OPEN_INVOICES',
  ACT_OPEN_INVOICES_CUSTOMER_DETAIL = 'ACT_OPEN_INVOICES',
  VIEW_DRAFT_INVOICES_CUSTOMER_DETAIL = 'VIEW_DRAFT_INVOICES',
  ACT_DRAFT_INVOICES_CUSTOMER_DETAIL = 'ACT_DRAFT_INVOICES',
  VIEW_CLOSED_INVOICES_CUSTOMER_DETAIL = 'VIEW_CLOSED_INVOICES',
  ACT_CLOSED_INVOICES_CUSTOMER_DETAIL = 'ACT_CLOSED_INVOICES',
  SET_CUSTOMER_DETAIL_FLAG_INVOICE = 'SET_CUSTOMER_DETAIL_FLAG_INVOICE',

  /**INVOICE DETAIL*/
  BASIC_INVOICE_INFORMATION = 'BASIC_INVOICE_INFORMATION',
  VIEW_INVOICE_LINE_ITEMS = 'VIEW_INVOICE_LINE_ITEMS',
  PERFORM_INVOICE_LEVEL_ACTIONS = 'PERFORM_INVOICE_LEVEL_ACTIONS',
  VIEW_INVOICE_ACTIVITIES = 'VIEW_INVOICE_ACTIVITIES',
  SET_INVOICE_DETAIL_FLAG_INVOICE = 'SET_INVOICE_DETAIL_FLAG_INVOICE',
  SET_COLLECTION_STRATEGY_INVOICE_DETAIL = 'SET_COLLECTION_STRATEGY',

  /**INVOICE LIST PAGE*/
  VIEW_OPEN_INVOICES_INVOICE_LIST = 'VIEW_OPEN_INVOICES',
  ACT_OPEN_INVOICES_INVOICE_LIST = 'ACT_OPEN_INVOICES',
  VIEW_DRAFT_INVOICES_INVOICE_LIST = 'VIEW_DRAFT_INVOICES',
  ACT_DRAFT_INVOICES_INVOICE_LIST = 'ACT_DRAFT_INVOICES',
  VIEW_CLOSED_INVOICES_INVOICE_LIST = 'VIEW_CLOSED_INVOICES',
  ACT_CLOSED_INVOICES_INVOICE_LIST = 'ACT_CLOSED_INVOICES',
  BASIC_FILTER_FIELDS_INVOICE_LIST = 'BASIC_FILTER_FIELDS_INVOICE_LIST',
  ADVANCED_FILTER_FIELDS_INVOICE_LIST = 'ADVANCED_FILTER_FIELDS_INVOICE_LIST',
  SET_INVOICE_LIST_FLAG_INVOICE = 'SET_INVOICE_LIST_FLAG_INVOICE',
  SET_COLLECTION_STRATEGY_INVOICE_LIST = 'SET_COLLECTION_STRATEGY',

  /**COLLECTION FOLLOWUP*/
  CREATE_MANAGE_COLLECTION_FOLLOWUP = 'CREATE_MANAGE_COLLECTION_FOLLOWUP',
  ASSIGN_COLLECTION_FOLLOWUP = 'ASSIGN_COLLECTION_FOLLOWUP',

  /**SETTINGS*/
  COMPANY_SETTINGS = 'COMPANY_SETTINGS',
  USER_ADMINISTRATION = 'USER_ADMINISTRATION',
  COLLECTIONS_MANAGEMENT = 'COLLECTIONS_MANAGEMENT',
  APPS = 'APPS',
  DEFAULT_PRIVILEGE = 'DEFAULT_PRIVILEGE',
  CONTACT_TYPE_MANAGEMENT = 'CONTACT_TYPE_MANAGEMENT',
  CONTACT_TYPE_DEFAULT = 'CONTACT_DEFAULT_PRIVILEGE',

  /**REPORTS*/
  GENERATE_REPORTS = 'GENERATE_REPORTS',
  PAYMENT_REPORT = 'VIEW_PAYMENTS_REPORT',
  ADVANCED_REPORTS = 'ADVANCED_REPORTS',
  GENERATE_AGING_EXPORT_REPORT = 'GENERATE_AGING_EXPORT_REPORT',

  /** CUSTOMER_LIST page */
  CUSTOMER_LIST = 'CUSTOMER_LIST',

  /**COLLECTION_ACTIVITIES*/
  VIEW_COLLECTION_ACTIVITIES = 'VIEW_COLLECTION_ACTIVITIES',
  CHANGE_DISPUTE_PARTICIPANTS = 'CHANGE_DISPUTE_PARTICIPANTS',

  /** custom field edit*/
  EDIT_CUSTOM_FIELD_DATA = 'EDIT_CUSTOM_FIELD_DATA',
  VIEW_COLLECTOR_INFORMATION = 'VIEW_COLLECTOR_INFORMATION',
  MODIFY_COLLECTION_TARGET = 'MODIFY_COLLECTION_TARGET',
  VIEW_COLLECTOR_PERFORMANCE = 'VIEW_COLLECTOR_PERFORMANCE',

  /** cashapps */
  DEFAULT_CASH_APP_PRIVILEGE = 'DEFAULT_CASH_APP_PRIVILEGE',
  PORTAL_SETTINGS_CUSTOMER = 'PORTAL_SETTINGS_CUSTOMER',
  PORTAL_SETTINGS = 'PORTAL_SETTINGS',
  DOCUMENT_TYPE_SETTINGS = 'DOCUMENT_TYPE_SETTINGS',

  /** Documents */

  VIEW_DOCUMENT = 'VIEW_DOCUMENT',
  MANAGE_DOCUMENT = 'MANAGE_DOCUMENT',
  VIEW_DOCUMENT_TYPE = 'VIEW_DOCUMENT_TYPE',
  MANAGE_DOCUMENT_TYPE = 'MANAGE_DOCUMENT_TYPE',

  /** GMS */
  GMS = 'GMAIL_SYNC',
}
