import { useQueryClient } from '@tanstack/react-query';
import { DisputeStatus } from 'components/CollectionActivites/Disputes/types';
import produce from 'immer';
import { subtract, sum } from 'lodash';
import { inboxQueryKeys } from 'queries/inbox';
import { useCallback, useEffect } from 'react';
import { ActivityType } from 'types/activities/activity-types';
import { PromiseToPayStatus } from 'types/activities/promise-to-pay';
import { TaskStatus } from 'types/activities/task';
import {
  AssignedActivities,
  InboxActivityMode,
  InboxActivitySearchParam,
  InboxActivityStatusParams,
  InboxActivityType,
  PerformedActionType,
  SubscribedActivities,
} from 'types/api/inbox/activity';
import { activityStatusConfig, useActivityFilterParams } from './useActivityFilterParams';
import { useActivitySearchParams } from './useActivitySearchParams';

/*
we have Activity Card  and Activity Details we need to update the Card info from the Details 
 So we're using the Custom Events to  Update these values we have three events
'update_count' | 'updating_query' | 'filter_query'

1. update_count
to update the unread count and pagination count while doing any activity updating because any
updating in activity is marked as unread.

2.updating_query
to update any property in the activity eg: like status or due_date and we need to updated the last activity updation

3. filter_query
so while closing any activity we need to filter out that activity from the list

*/

export type UpdateType = 'PAGINATION' | 'COUNT';
interface useActivityUpdatingProps {
  updateCountCallBack?: (e: ActivityEvent) => void;
  onChangeCallBack?: (value: number, type: UpdateType) => void;
}

type ActivityValueProp = {
  status?: PromiseToPayStatus | TaskStatus | DisputeStatus;
  date?: string;
  action?: PerformedActionType;
  count?: number;
};

export type ActivityEvents = 'update_count' | 'updating_query';

export type ActivityEvent = Omit<CustomEvent<ActivityEventProps>, 'type'> & {
  type: ActivityEvents;
};
export type ActivityEventProps = {
  id?: number | string;
  activity?: ActivityType;
  value: ActivityValueProp;
};

export function handleAddCustomEventCallBack(
  type: ActivityEvents,
  callback?: (e: ActivityEvent) => void
) {
  document.addEventListener(type, callback as any);
}
export function handleRemoveCustomEventCallBack(
  type: ActivityEvents,
  callback?: (e: ActivityEvent) => void
) {
  document.removeEventListener(type, callback as any);
}

export function handleCreateCustomEventCallBack(type: ActivityEvents, value: ActivityEventProps) {
  const event = new CustomEvent(type, { detail: value });
  document.dispatchEvent(event);
}

function useActivityUpdating(props: useActivityUpdatingProps) {
  const { updateCountCallBack, onChangeCallBack } = props;
  const { filters } = useActivityFilterParams();
  const { activitySearchParams, setActivitySearchParams, removeActivitySearchParams } =
    useActivitySearchParams();
  const queryKeys =
    activitySearchParams.activity_mode === InboxActivityMode.SUBSCRIBED
      ? inboxQueryKeys.subscribed_activities
      : inboxQueryKeys.assigned_activities;
  const queryClient = useQueryClient();

  function getDataAndKey() {
    const currentData = queryClient.getQueriesData<AssignedActivities | SubscribedActivities>([
      queryKeys,
    ]);

    if (!currentData.length) return;
    const [queryKey, previousData] = currentData[0];

    return { queryKey, previousData };
  }
  const query = getDataAndKey();

  function handleChange(id?: number | string, activity?: ActivityType) {
    if (!query?.previousData) return;

    const currentActivity = query.previousData.data.find(
      (f) => String(f.entity_detail.id) === String(id) && f.entity_type === activity
    );

    if (!currentActivity) return;

    if (currentActivity.read) {
      onChangeCallBack?.(sum([query.previousData?.unread_count, 1]), 'COUNT');
    }
  }

  const handleChangeCallBack = useCallback(handleChange, [onChangeCallBack, query?.previousData]);

  function updateCallBack(params: ActivityEventProps) {
    const { id, activity, value } = params;
    if (!query?.previousData) return;

    const updatedValue = produce(query.previousData.data, (draft) => {
      const isExist = draft.findIndex(
        (f) => String(f.entity_detail.id) === String(id) && f.entity_type === activity
      );

      if (isExist === -1) {
        return draft;
      }

      if (value?.status && value?.date) {
        draft[isExist].entity_detail = {
          ...draft[isExist].entity_detail,
          status: value.status,
          due_date: value.date,
        };
      }
      if (value?.status) {
        draft[isExist].entity_detail = {
          ...draft[isExist].entity_detail,
          status: value.status,
        };
      }

      if (draft[isExist].read) {
        draft[isExist].read = false;
      }

      draft[isExist].latest_activity = {
        ...draft[isExist].latest_activity,
        type: value.action ? value.action : draft[isExist].latest_activity.type,
      };
    });

    handelUpdateQueryDataCallBack({ ...query.previousData, data: updatedValue });
    handleChangeCallBack(id, activity);
  }

  function updateQueryData(newData: AssignedActivities | SubscribedActivities) {
    if (!query) return;
    queryClient.setQueryData<AssignedActivities | SubscribedActivities>(query.queryKey, (old) =>
      old ? { ...old, ...newData } : old
    );
  }

  const handelUpdateQueryDataCallBack = useCallback(updateQueryData, [query, queryClient]);
  const handleUpdateCallBack = useCallback(updateCallBack, [
    handelUpdateQueryDataCallBack,
    handleChangeCallBack,
    query?.previousData,
  ]);

  function filterQuery(id?: number | string, activity?: ActivityType) {
    if (!query?.previousData) return;

    const updatedValue = produce(query.previousData.data, (draft) => {
      return draft.filter(
        (f) => !(String(f.entity_detail.id) === String(id) && f.entity_type === activity)
      );
    });

    if (updatedValue.length <= 3) {
      removeActivitySearchParams([
        InboxActivitySearchParam.activityId,
        InboxActivitySearchParam.activityType,
      ]);
      queryClient.refetchQueries([queryKeys]);
    }

    const record = subtract(query.previousData.total_records, 1);

    handelUpdateQueryDataCallBack({
      ...query.previousData,
      data: updatedValue,
      total_records: record,
    });
    if (updatedValue.length) {
      setActivitySearchParams({
        activity_id: updatedValue[0].entity_detail.id,
        activity_type: updatedValue[0].entity_type,
      });
    }
    onChangeCallBack?.(record, 'PAGINATION');
  }

  const filterQueryCallBack = useCallback(filterQuery, [
    handelUpdateQueryDataCallBack,
    onChangeCallBack,
    query?.previousData,
    queryClient,
    queryKeys,
    removeActivitySearchParams,
    setActivitySearchParams,
  ]);

  function handleEvent(event: ActivityEvent) {
    if (event.type !== 'updating_query') {
      return;
    }
    const entityTypeFilterEmpty = !filters[InboxActivitySearchParam.entityType]?.length;
    const activityTypeIncluded = filters[InboxActivitySearchParam.entityType]?.includes(
      event.detail.activity as InboxActivityType
    );
    const status = filters[
      activityStatusConfig[event.detail.activity as ActivityType] as InboxActivityStatusParams
    ] as string[];

    if (entityTypeFilterEmpty) {
      handleUpdateCallBack({
        id: event.detail.id,
        activity: event.detail.activity,
        value: event.detail.value,
      });
      return;
    } else if (entityTypeFilterEmpty || activityTypeIncluded) {
      if (
        entityTypeFilterEmpty ||
        (status?.length === 1 &&
          status.includes('CLOSED') &&
          event.detail.value.status !== 'CLOSED')
      ) {
        filterQueryCallBack(event.detail.id, event.detail.activity);
      } else if (event.detail.value.status === 'CLOSED') {
        filterQueryCallBack(event.detail.id, event.detail.activity);
      } else {
        handleUpdateCallBack({
          id: event.detail.id,
          activity: event.detail.activity,
          value: event.detail.value,
        });
      }
    }

    return;
  }

  const onActivityEvents = useCallback(handleEvent, [
    filterQueryCallBack,
    filters,
    handleUpdateCallBack,
  ]);
  function handleUpdateCountEvent() {
    handleAddCustomEventCallBack('update_count', updateCountCallBack);

    return () => {
      handleRemoveCustomEventCallBack('update_count', updateCountCallBack);
    };
  }

  function handleUpdatingEvent() {
    handleAddCustomEventCallBack('updating_query', onActivityEvents);

    return () => {
      handleRemoveCustomEventCallBack('updating_query', onActivityEvents);
    };
  }

  useEffect(handleUpdateCountEvent, [updateCountCallBack]);
  useEffect(handleUpdatingEvent, [onActivityEvents]);

  return;
}

export { useActivityUpdating };
