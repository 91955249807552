import { map } from 'lodash';
import { useEffect } from 'react';
import transformData from 'util/transformData';
import { generateInvoiceOptions } from '../predicate';
import { useGetFormElementQuery } from '../services/query';
import { InvoiceFieldMapping, useGetInvoiceSelectOptionsProps } from '../types';
import { updateInvoiceData } from '../Utils';

export default function useGetInvoiceSelectOptions<T>(props: useGetInvoiceSelectOptionsProps<T>) {
  const { queryFn, queryKey, transformSchema, openInvoices, selectedInvoices, onSuccess } = props;

  const { data, isLoading, isSuccess } = useGetFormElementQuery<T>({
    queryFn,
    queryKey,
  });

  useEffect(() => {
    if (!isLoading && isSuccess) {
      const optionsSelectedList = updateInvoiceData(data, selectedInvoices);
      const optionsListData = map(optionsSelectedList, (item) => {
        return transformData<T, InvoiceFieldMapping>({
          originalData: item,
          schemaMapping: transformSchema,
        });
      });
      const optionsMapList = new Map(map(optionsListData, (item) => [item.id, item]));
      const optionsList = generateInvoiceOptions(optionsListData, openInvoices);
      onSuccess(optionsList, optionsMapList);
    }
  }, [data, isLoading, isSuccess, onSuccess, openInvoices, selectedInvoices, transformSchema]);

  return { data, isLoading };
}
