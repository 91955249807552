import { Select, Skeleton } from 'antd';
import { useState } from 'react';
import useGetDocumentTypes from '../hooks/useGetDocumentTypes';
import { ListOptions } from '../predicate';
import { DocumentsTypesSelectProps } from '../types';

export default function DocumentsTypeSelect<T>(props: DocumentsTypesSelectProps<T>) {
  const { placeholder, onChangeDocumentItems, queryFn, queryKey, transformSchema, entityLevel } =
    props;

  const [selectedValues, setSelectedValues] = useState<number | undefined>();
  const { options, isLoading } = useGetDocumentTypes<T>({
    queryFn,
    queryKey,
    transformSchema,
    entityLevel,
  });

  if (isLoading) {
    return <Skeleton.Input active block />;
  }

  return (
    <Select
      placeholder={placeholder}
      listHeight={250}
      value={selectedValues}
      style={{ width: '100%' }}
      suffixIcon={null}
      filterOption
      showSearch
      fieldNames={{ label: 'name', value: 'id' }}
      optionFilterProp="name"
      options={options}
      loading={isLoading}
      dropdownRender={(options) => {
        return ListOptions(options, isLoading);
      }}
      onChange={(value: number) => {
        setSelectedValues(value);
        onChangeDocumentItems(value);
      }}
    />
  );
}
