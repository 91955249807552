import { Flex, FlexBoxChild } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { ErrorBoundaryWrapper } from 'components/Common/ErrorBoundary';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { InfoPopover } from 'components/Common/UtilityPopover';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { PageLink } from 'lib/router';
import { DashboardPostFilterParams } from 'pages/Dashboard/types';
import React from 'react';
import styled from 'styled-components';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';
import { AccountConfigKey } from 'types/entities/account';
import { StyledTileCard } from '../styles';
import { dashboardTexts } from '../texts';
import UnappliedCredits from './UnappliedCredits';
import UnappliedPayments from './UnappliedPayments';

const componentTexts = dashboardTexts.totalOutstanding;
const StyledExtra = styled.div``;
const t = {
  totalOustanding: 'Total Outstanding',
  extraContent: 'Total Balance - ( Unapplied Credits + Unapplied Payments)',
  extraContentWhenFlagEnabled: 'Sum of all customer balances',
};

function ExtraInfo() {
  const isBalanceComputed = useConfig(AccountConfigKey.SHOULD_EVALUATE_BALANCES);

  const TooltipWhenBalanceisComputed = (
    <>
      <Flex align="center" gap="var(--space-4)">
        <GrowText size="var(--fs-16)" strong>
          {t.totalOustanding}
        </GrowText>
        =
      </Flex>
      <GrowText>{t.extraContent}</GrowText>
    </>
  );

  const Content = (
    <StyledExtra className="extra-info">
      {!isBalanceComputed ? t.extraContentWhenFlagEnabled : TooltipWhenBalanceisComputed}
    </StyledExtra>
  );

  return (
    <div style={{ cursor: 'pointer' }}>
      <InfoPopover content={Content} />
    </div>
  );
}

interface TotalOutstandingTileProps {
  totalOutstanding?: TotalOutstandingResponse;
  loading: boolean;
  filterParams: DashboardPostFilterParams;
}

function TotalOutstandingTile({
  totalOutstanding,
  loading,
  filterParams,
}: TotalOutstandingTileProps) {
  const total_outstanding = totalOutstanding?.total_outstanding;

  const InputLoaderJSX = <InputLoader active={loading} />;

  const OutstandingAmount = total_outstanding?.detailed_outstanding_amount?.amount ? (
    <PageLink
      appPage="AR_AGING"
      searchParams={`sort_col=TOTAL_DUE_AMOUNT&sort_by=DESC`}
      state={{ addPrimaryPathAsChildPath: true }}
    >
      <AmountWithCurrencySplit
        type="multi"
        amountWithCurrencySplit={total_outstanding.detailed_outstanding_amount.amount}
        className="text-30 semi-bold"
        showTooltip
        context={componentTexts.title}
        formatOptions={{ maximumFractionDigits: 0 }}
      />
    </PageLink>
  ) : null;

  const TotalOutstandingInformation = (
    <>
      <div className="main-card-item">
        <div className="title" style={{ paddingBottom: 'var(--space-8)' }}>
          <Flex gap="var(--space-4)" align="center">
            <GrowText size="var(--fs-16)">{componentTexts.title}</GrowText>
            <ExtraInfo />
          </Flex>
        </div>
        <Flex className="value amount" align="center" gap="var(--space-4)">
          {loading ? InputLoaderJSX : OutstandingAmount}
        </Flex>
      </div>
    </>
  );

  return (
    <Flex gap={'var(--space-16)'} direction="column">
      <FlexBoxChild>
        <StyledTileCard style={{ height: '123px' }}>{TotalOutstandingInformation}</StyledTileCard>
      </FlexBoxChild>
      <FlexBoxChild>
        <ErrorBoundaryWrapper>
          <UnappliedCredits
            filterParams={filterParams}
            loading={loading}
            totalOutstanding={totalOutstanding}
          />
        </ErrorBoundaryWrapper>
      </FlexBoxChild>
      <FlexBoxChild>
        <ErrorBoundaryWrapper>
          <UnappliedPayments
            filterParams={filterParams}
            loading={loading}
            totalOutstanding={totalOutstanding}
          />
        </ErrorBoundaryWrapper>
      </FlexBoxChild>
    </Flex>
  );
}

export default React.memo(TotalOutstandingTile);
