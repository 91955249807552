import { SearchParamsOption } from 'ky';
import { CurrentUser, User, UserBasic, UserRole } from 'types/entities/user';
import ky from './ky';

interface UserResponse {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}

export interface CollectionOwnerProps extends UserResponse {
  hash: string;
}

const baseUrl = 'users';

export async function getUsersForIds(userIds: number[]) {
  return (await ky
    .get(`${baseUrl}`, { searchParams: { ids: userIds.toString() } })
    .json()) as UserResponse[];
}

export async function getUserInformation(userId: number) {
  return (await ky.get(`${baseUrl}/${userId}`).json()) as User;
}

export async function allUsers() {
  return (await ky.get(`${baseUrl}/all`).json()) as User[];
}

export async function activeUsers() {
  return (await ky.get(`${baseUrl}/active`).json()) as User[];
}

export async function createNewUser(userData: User) {
  return (await ky.post(`${baseUrl}`, { json: userData }).json()) as User;
}

export async function updateUser(userId: number, userData: User) {
  return (await ky.put(`${baseUrl}/${userId}`, { json: userData }).json()) as User;
}

export async function updateCurrentUser(userData: CurrentUser) {
  return (await ky.put(`${baseUrl}/current-user`, { json: userData }).json()) as User;
}

export async function deleteUser(userId: number) {
  return await ky.delete(`${baseUrl}/${userId}`);
}

export async function getInActiveUsers() {
  return (await ky.get(`${baseUrl}/not-activated`).json()) as User[];
}

export async function getDeletedUsers() {
  return (await ky.get(`${baseUrl}/deleted`).json()) as User[];
}

export async function resendActivationEmail(userId: number) {
  return await ky.post(`${baseUrl}/${userId}/resend-activation-email`);
}

export async function getActiveCollectionOwners(searchParams?: SearchParamsOption) {
  return (await ky
    .get(`${baseUrl}/collection-owners`, {
      searchParams,
    })
    .json()) as User[];
}

export async function getActiveAccountManagers(searchParams?: SearchParamsOption) {
  return (await ky
    .get(`${baseUrl}/account-managers`, {
      searchParams,
    })
    .json()) as User[];
}

export async function getActiveCustomerSuccessManager(searchParams?: SearchParamsOption) {
  return (await ky
    .get(`${baseUrl}/account-executives`, {
      searchParams,
    })
    .json()) as User[];
}

export async function getActiveAccountExecutives() {
  return (await ky.get(`${baseUrl}/account-executives`).json()) as User[];
}

export async function getCollectionHeads() {
  return (await ky.get(`${baseUrl}/collection-heads`).json()) as UserBasic[];
}

export async function searchUsers(searchWord: string, includeRelativePath?: boolean) {
  const url = includeRelativePath
    ? `${baseUrl}/suggestions/with-collection-owner?keyword=${searchWord}`
    : `${baseUrl}/suggestions/?keyword=${searchWord}`;

  return await ky.get(url).json<User[]>();
}

export async function getSalesManager() {
  return (await ky.get(`${baseUrl}/sales-managers`).json()) as UserBasic[];
}

export async function getAllowedLead(role: UserRole, userId?: number) {
  const params = { role, user_id: userId };
  return (await ky
    .get(`teams/allowed-leads`, { searchParams: params as SearchParamsOption })
    .json()) as UserBasic[];
}

export async function getDisputeOwners() {
  return (await ky.get(`${baseUrl}/dispute-participants`).json()) as UserBasic[];
}

export async function getCollectionManagers(searchParams?: SearchParamsOption) {
  return (await ky
    .get(`${baseUrl}/collection-leads`, {
      searchParams,
    })
    .json()) as User[];
}

export async function getSalesManagers(searchParams?: SearchParamsOption) {
  return (await ky
    .get(`${baseUrl}/sales-leads`, {
      searchParams,
    })
    .json()) as User[];
}

export async function getCollectionOwner() {
  return (await ky.get(`customers/collection-owner-users`).json()) as CollectionOwnerProps[];
}

export async function getCustomerContacts(searchParams: SearchParamsOption, categoryId?: number) {
  return (await ky
    .get(`${baseUrl}/category/${categoryId}`, {
      searchParams,
    })
    .json()) as User[];
}

export async function getSyncGms(params: SearchParamsOption) {
  await ky
    .get(`gms/auth`, {
      searchParams: params,
    })
    .json();
}
