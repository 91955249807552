const emptyPlaceholder = '-';

interface EmptyProps {
  isEmpty: boolean;
  emptyPlaceholder?: React.ReactNode;
  children: React.ReactChild;
}

export function Empty(props: EmptyProps) {
  const placeHolder = props.emptyPlaceholder ?? emptyPlaceholder;

  return <>{props.isEmpty ? placeHolder : props.children}</>;
}
