import { PayloadAction } from '@reduxjs/toolkit';
import {
  InvoiceSegments,
  UpdateCustomFieldAndValidationActions,
  UpdateInvoiceSegmentValidationActions,
} from 'components/Settings/CollectionPlanConfiguration/CPBody/PeriodConditions/Filters/types';
import { PeriodsMonth } from 'components/Settings/CollectionPlanConfiguration/CPBody/PeriodPlanning/PeriodSelection/config';
import { CollectionPlanResponse } from 'types/api/collection-planning';
import { CustomFieldFilterValues, NumberFields } from 'types/entities/custom-field';
import { getSanitizedObject } from 'util/remove-null-and-undefined';
import { initialState } from '.';
import { PeriodsWeek } from '../../components/Settings/CollectionPlanConfiguration/CPBody/PeriodPlanning/PeriodSelection/config';
import {
  CustomFieldOperatorType,
  validNumberBasedFields,
} from './../../types/entities/custom-field/index';
import {
  CollectionPlan,
  PeriodType,
  PlanDayActions,
  PlanMode,
  SegmentDateFilters,
  UpdateInvoiceFilterConditionsValues,
  defaultDateFilterValues,
  validDateFields,
} from './type';

export function updateCollectionPlan(
  state: CollectionPlan,
  action: PayloadAction<CollectionPlanResponse>
) {
  if (!(Boolean(Object.keys(action.payload).length) && Object.keys(action.payload).length)) {
    return;
  }
  state.collectionPlan.periodType = action.payload.collection_period;
  state.collectionPlan.planStatus = action.payload.enabled;
  state.collectionPlan.planMode = action.payload.collection_plan ?? PlanMode.MANUAL;
  state.collectionPlan.planDayMonth = String(action.payload.start_day);
  state.collectionPlan.planId = parseInt(action.payload.id as string);
  if (action.payload.invoice_filter_condition) {
    const removedNullValues = getSanitizedObject(action.payload.invoice_filter_condition);

    state.collectionPlan.currentCondition = {
      ...initialState.collectionPlan.currentCondition,
      ...removedNullValues,
    };

    state.collectionPlan.persistedCondition = removedNullValues;
  }

  state.collectionPlan.lastActivity = action.payload.updated_at;
}
export function resetConditionValidation(state: CollectionPlan) {
  state.conditionsValidation = initialState.conditionsValidation;
}

export function updatePeriodType(state: CollectionPlan, action: PayloadAction<PeriodType>) {
  state.collectionPlan.periodType = action.payload;
}

export function updatePlanStatus(state: CollectionPlan, action: PayloadAction<boolean>) {
  state.collectionPlan.planStatus = action.payload;
}

export function updatePlanMode(state: CollectionPlan, action: PayloadAction<PlanMode>) {
  state.collectionPlan.planMode = action.payload;
}

export function updatePlanDay(state: CollectionPlan, action: PayloadAction<PlanDayActions>) {
  if (action.payload.type === PeriodType.MONTH) {
    state.collectionPlan.planDayMonth = action.payload.value ?? PeriodsMonth[0].id;
  }
  if (action.payload.type === PeriodType.WEEK) {
    state.collectionPlan.planDayWeek = action.payload.value ?? PeriodsWeek[0].id;
  }
}

export function resetCollectionPlan(state: CollectionPlan) {
  state.collectionPlan = initialState.collectionPlan;
}

export function updateConditionValidationAndCurrentConditions(
  state: CollectionPlan,
  action: PayloadAction<UpdateInvoiceSegmentValidationActions>
) {
  if (action.payload.type === 'ADD') {
    if (validDateFields.includes(action.payload.value as SegmentDateFilters[number])) {
      state.collectionPlan.currentCondition = {
        ...state.collectionPlan.currentCondition,
        [action.payload.value]:
          defaultDateFilterValues[action.payload.value as SegmentDateFilters[number]],
      };
    }
    state.conditionsValidation = {
      ...state.conditionsValidation,
      [action.payload.value]: false,
    };
  }
  if (action.payload.type !== 'REMOVE') {
    return;
  }
  const current_condition_copy = { ...state.collectionPlan.currentCondition };
  const validation_condition_copy = { ...state.conditionsValidation };

  delete current_condition_copy[action.payload.value];
  delete validation_condition_copy[action.payload.value];

  state.collectionPlan.currentCondition = { ...current_condition_copy };
  state.conditionsValidation = { ...validation_condition_copy };
}

export function updateCustomFiledConditionsAndValidation(
  state: CollectionPlan,
  action: PayloadAction<UpdateCustomFieldAndValidationActions>
) {
  if (action.payload.type === 'ADD') {
    state.collectionPlan.currentCondition = {
      ...state.collectionPlan.currentCondition,
      custom_fields: state.collectionPlan.currentCondition?.custom_fields
        ? ([
            ...state.collectionPlan.currentCondition?.custom_fields,
            {
              id: action.payload.id,
              include_unassigned: false,
              operator: {
                type: validNumberBasedFields.includes(action.payload.data_type as NumberFields)
                  ? 'GOE'
                  : 'IN',
                operands: {
                  type: 'DEPRECATED_ABSOLUTE',
                  value: validNumberBasedFields.includes(action.payload.data_type as NumberFields)
                    ? '1'
                    : [],
                },
              },
            },
          ] as CustomFieldFilterValues<CustomFieldOperatorType>[])
        : ([
            {
              id: action.payload.id,
              include_unassigned: false,
              operator: {
                type: validNumberBasedFields.includes(action.payload.data_type as NumberFields)
                  ? 'GOE'
                  : 'IN',
                operands: {
                  type: 'DEPRECATED_ABSOLUTE',
                  value: validNumberBasedFields.includes(action.payload.data_type as NumberFields)
                    ? '1'
                    : [],
                },
              },
            },
          ] as CustomFieldFilterValues<CustomFieldOperatorType>[]),
    };
  }
  if (action.payload.type !== 'REMOVE') {
    return;
  }
  const filteredCustomFiled = state.collectionPlan.currentCondition?.custom_fields?.filter(
    (customField) => customField.id !== action.payload.id
  );
  if (filteredCustomFiled?.length) {
    state.collectionPlan.currentCondition = {
      ...state.collectionPlan.currentCondition,
      custom_fields: filteredCustomFiled,
    };
  } else {
    delete state.collectionPlan.currentCondition.custom_fields;
  }
}

export function updateInvoiceFilterConditionsValues(
  state: CollectionPlan,
  action: PayloadAction<UpdateInvoiceFilterConditionsValues>
) {
  switch (action.payload.key) {
    case InvoiceSegments.INVOICE_STATUS:
      state.collectionPlan.currentCondition = {
        ...state.collectionPlan.currentCondition,
        invoice_status: action.payload.options,
      };

      break;
    case InvoiceSegments.COLLECTION_STATUS:
      state.collectionPlan.currentCondition = {
        ...state.collectionPlan.currentCondition,
        customer_collection_status: action.payload.options?.filter((value) => Boolean(value)),
      };

      break;
    case InvoiceSegments.ISSUE_DATE:
      state.collectionPlan.currentCondition = {
        ...state.collectionPlan.currentCondition,
        issued_date: action.payload.options,
      };

      break;
    case InvoiceSegments.DUE_DATE:
      state.collectionPlan.currentCondition = {
        ...state.collectionPlan.currentCondition,
        due_date: action.payload.options,
      };
      break;
    case InvoiceSegments.PTP_DATE:
      state.collectionPlan.currentCondition = {
        ...state.collectionPlan.currentCondition,
        [InvoiceSegments.PTP_DATE]: action.payload.options,
      };

      break;
    case InvoiceSegments.CUSTOM_FIELD:
      const payload_value = action.payload.payload;
      state.collectionPlan.currentCondition = {
        ...state.collectionPlan.currentCondition,
        custom_fields: state.collectionPlan.currentCondition?.custom_fields?.map((customFiled) => {
          if (customFiled.id === payload_value.id) {
            return { ...customFiled, ...payload_value };
          }
          return customFiled;
        }),
      };

      break;

    default:
      state.collectionPlan.currentCondition = {
        ...state.collectionPlan.currentCondition,
      };
      break;
  }
}

export function updateConditionsValidation(
  state: CollectionPlan,
  action: PayloadAction<{ key: string; value: boolean }>
) {
  state.conditionsValidation = {
    ...state.conditionsValidation,
    [action.payload.key]: action.payload.value,
  };
}
