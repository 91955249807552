import { useMutation, useQuery } from '@tanstack/react-query';
import { getAllCustomFields, getCustomFields } from 'services/custom-field';
import { UpdateCustomFieldData, editCustomFieldData } from 'services/custom-field-edit';
import { CustomFieldDataResponse, CustomFieldPage } from 'types/entities/custom-field';
export type InvoiceCustomFieldParam = {
  params: CustomFieldPage;
  enabled?: boolean;
  onSuccessCallBack?: (data: CustomFieldDataResponse[]) => void;
};
export function useCustomerCustomFieldQuery(params: CustomFieldPage, enabled?: boolean) {
  return useQuery({
    queryKey: ['customer-custom-fields', params],
    queryFn: () => getCustomFields({ page: params, entity: 'CUSTOMER' }),
    retry: false,
    staleTime: Infinity,
  });
}

export function useInvoiceCustomFieldQuery(
  params: CustomFieldPage,
  onSuccessCallBack?: (data: CustomFieldDataResponse[]) => void,
  onErrorCallBack?: () => void,
  enabled?: boolean
) {
  return useQuery({
    queryKey: ['invoice-custom-fields', params],
    queryFn: () => getCustomFields({ page: params, entity: 'INVOICE' }),
    retry: false,
    enabled,
    onSuccess(data) {
      onSuccessCallBack?.(data);
    },
    onError(err) {
      onErrorCallBack?.();
    },
  });
}

export function useInvoiceAllCustomFieldQuery() {
  return useQuery({
    queryKey: ['invoice-all-custom-fields'],
    queryFn: () => getAllCustomFields('INVOICE'),
    retry: false,
    staleTime: Infinity,
  });
}

export function useCustomerAllCustomFieldQuery() {
  return useQuery({
    queryKey: ['customer-all-custom-fields'],
    queryFn: () => getAllCustomFields('CUSTOMER'),
    retry: false,
    staleTime: Infinity,
  });
}

export function useCustomersCustomFieldQuery(
  params: CustomFieldPage,
  onSuccessCallBack?: (data: CustomFieldDataResponse[]) => void,
  onErrorCallBack?: () => void,
  enabled?: boolean
) {
  return useQuery({
    queryKey: ['customer-custom-fields', params],
    queryFn: () => getCustomFields({ page: params, entity: 'CUSTOMER' }),
    retry: false,
    enabled,
    onSuccess(data) {
      onSuccessCallBack?.(data);
    },
    onError(err) {
      onErrorCallBack?.();
    },
  });
}

export function useCustomersAllCustomFieldQuery(
  params: CustomFieldPage,
  onSuccessCallBack?: (data: CustomFieldDataResponse[]) => void,
  onErrorCallBack?: () => void,
  enabled?: boolean
) {
  return useQuery({
    queryKey: ['all-customer-custom-fields', params],
    queryFn: () => getCustomFields({ page: params, entity: 'CUSTOMER' }),
    retry: false,
    enabled,
    onSuccess(data) {
      onSuccessCallBack?.(data);
    },
    onError(err) {
      onErrorCallBack?.();
    },
  });
}

export function useUpdateCustomFieldActions(custom_field_id?: number) {
  return useMutation({
    mutationFn: (data: UpdateCustomFieldData) => editCustomFieldData(data, custom_field_id),
  });
}
