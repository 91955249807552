import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { useDispatch, useSelector } from 'react-redux';
import { folderIdSelector, setCurrentFolderId } from 'store/email-template/email-template';
import { Folders } from 'types/entities/email-templates';
import { StyledMenuItem, folderNameStyles, sideBarMenuStyles } from './style';

interface EmailTemplateListSideBarProps {
  folders: Folders[];
  onSelectCallBack: (id: number) => void;
}

function EmailTemplateListSideBar(props: EmailTemplateListSideBarProps) {
  const selectedFolderId = useSelector(folderIdSelector);
  const [{ id }] = props.folders;
  const dispatch = useDispatch();

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[`${id}`]}
      onSelect={(value) => {
        props.onSelectCallBack(parseInt(value.key));
        dispatch(setCurrentFolderId({ folderId: parseInt(value.key) }));
      }}
      style={{ ...sideBarMenuStyles, height: '100%' }}
      selectedKeys={[`${selectedFolderId}`]}
    >
      {props.folders.map((folder) => {
        return (
          <StyledMenuItem key={`${folder.id}`}>
            <Flex direction="column">
              <Flex align="center" gap="8px">
                <FontAwesomeIcon
                  icon={['far', 'folder']}
                  color="var(--purple-5)"
                  style={{ alignSelf: 'flex-start' }}
                />
                <Texto
                  color="var(--default-10)"
                  size="14"
                  style={folderNameStyles}
                  className="folder-name"
                >
                  {folder.name}
                  <span> {`(${folder.templateCount})`}</span>
                </Texto>
              </Flex>
            </Flex>
          </StyledMenuItem>
        );
      })}
    </Menu>
  );
}

export { EmailTemplateListSideBar };
