import { Filter } from 'components/Common/FilterComponents/types';
import { useUserCategoryGetQuery } from 'components/Settings/UserCategory/UserCategoryQueries';
import { UserCategory } from 'types/api/user-category';

export function getCustomerContactId(id: number) {
  return `customer-contacts-${id}`;
}

export function useCustomerContactsFilter() {
  //get the categories
  const { data: userCategory } = useUserCategoryGetQuery();

  //construct a list and return
  const customerContacts: Filter<UserCategory>[] | undefined = userCategory?.map((category) => ({
    id: getCustomerContactId(category.id),
    label: category.title,
    active: false,
    removable: true,
    filterObject: category,
    filterValueKeys: [getCustomerContactId(category.id)],
  }));

  return [customerContacts];
}
