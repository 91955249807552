import {
  UpdateUserCategoryProps,
  createUserCategoryProps,
} from 'components/Settings/UserCategory/UserCategoryQueries';
import { UserCategory } from 'types/api/user-category';
import ky from './ky';

export async function getUserCategory() {
  return (await ky.get('user-category').json()) as UserCategory[];
}

export async function updateUserCategory(data: UpdateUserCategoryProps) {
  return (await ky.put(`user-category/${data.id}`, { json: data }).json()) as UserCategory;
}
export async function createUserCategory(data: createUserCategoryProps) {
  return (await ky.post(`user-category`, { json: data }).json()) as UserCategory;
}

export async function deleteUserCategory(id: number) {
  return await ky.delete(`user-category/${id}`);
}
