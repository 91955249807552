import { Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { TextBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { drop, take } from 'lodash';
import { DisputeType } from 'types/entities/dispute-type';

interface DisputeTypeProps {
  types: DisputeType[];
}

function DisputeTypes(props: DisputeTypeProps) {
  const { types } = props;

  const first = take(types, 4);
  const rest = drop(types, 4);

  const Content = (
    <Flex direction="column" gap="var(--space-8)">
      {first.map((type) => {
        return <TextBlock key={type.id} value={type.name} />;
      })}
    </Flex>
  );

  const Rest = (
    <Popover
      arrow={false}
      placement="right"
      align={{ offset: [-10, 0] }}
      overlayInnerStyle={{ height: '180px', overflow: 'auto' }}
      content={
        <Flex direction="column" gap="var(--space-8)">
          {rest.map((type) => {
            return <TextBlock key={type.id} value={type.name} />;
          })}
        </Flex>
      }
    >
      <GrowText className="cursor-pointer" strong>{`+${rest.length} more`}</GrowText>
    </Popover>
  );

  return (
    <Flex direction="column" gap="var(--space-4)">
      {Content}
      {rest.length ? Rest : null}
    </Flex>
  );
}

export { DisputeTypes };
