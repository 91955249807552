import { AutomatedEmailTaskItem } from '../email/types';
import { TaskItem } from '../task/task';

export enum FollowupRuleTask {
  AUTO_EMAIL = 'Automated Email',
  // EMAIL_TASK = "Followup Task - Send a manual Email",
  // CALL_TASK = "Followup Task - Set a call",
  GENERIC_TASK = 'Followup Task',
}

export enum FollowupRuleTaskEnum {
  AUTO_EMAIL = 'AUTO_EMAIL',
  // EMAIL_TASK = "Followup Task - Send a manual Email",
  // CALL_TASK = "Followup Task - Set a call",
  GENERIC_TASK = 'GENERIC_TASK',
}

export enum FollowupRuleDayCondition {
  BEFORE = 'BEFORE',
  ON = 'ON',
  AFTER = 'AFTER',
}

export enum FollowupRuleTriggerCondition {
  INVOICE_DAYS = 'INVOICE_DAYS',
  DAY_OF_MONTH = 'DAY_OF_MONTH',
  GENERIC = 'GENERIC',
  CALENDAR = 'CALENDAR',
}

export enum FollowupRuleStatementsTriggerCondition {
  DAY_OF_WEEK = 'RECURRING',
  DAY_OF_MONTH = 'CUSTOM',
}

export enum FollowupRuleDateType {
  ISSUE_DATE = 'ISSUE_DATE',
  DUE_DATE = 'DUE_DATE',
  EXPECTED_PAY_DATE = 'EXPECTED_PAY_DATE',
  MONTH_START_DATE = 'MONTH_START_DATE',
}

export enum CollectionFollowupType {
  ALL_INVOICE = 'ALL_INVOICE',
  OLD_INVOICE = 'OLD_INVOICE',
  STATEMENT = 'STATEMENT',
}

export enum PauseEventType {
  PARTIAL_PAYMENT = 'Partial Payment',
  CUSTOMER_RESPONDED = 'Customer Response',
}

export enum PauseEventTypeEnum {
  PARTIAL_PAYMENT = 'PARTIAL_PAYMENT',
  CUSTOMER_RESPONDED = 'CUSTOMER_RESPONDED',
}

export enum ResumeEventType {
  CUSTOMER_CONTACTED = 'Customer is Contacted',
}

export enum ResumeDateType {
  PAUSE_DATE = 'Pause date',
}

export enum StopEventTypeEnum {
  INVOICE_CLOSED = 'INVOICE_CLOSED',
  INVOICE_PAID = 'INVOICE_PAID',
  INVOICE_CHANGED_FROM_OPEN = 'INVOICE_CHANGED_FROM_OPEN',
}

export enum StopEventType {
  INVOICE_CLOSED = 'Invoice is marked as Closed',
  INVOICE_PAID = 'Invoice amount is paid in full',
  INVOICE_CHANGED_FROM_OPEN = 'Invoice status is NOT Open',
}

export enum WeekDay {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum AllDay {
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6,
  SUN = 7,
}

export type FollowupRuleItem = {
  id?: number;
  deleted: boolean;
  title: string;
  event: FollowupRuleEventItem;
  action?: FollowupRuleAutoEmailActionItem | FollowupRuleTaskActionItem;
};

export type FollowupRuleEventItem = {
  id?: number;
  deleted: boolean;
  name?: string;
  days: number | Array<number>;
  eventable_type: FollowupRuleTriggerCondition;
  type?: FollowupRuleStatementsTriggerCondition;
  date_type?: FollowupRuleDateType;
};

export type FollowupRuleAutoEmailActionItem = AutomatedEmailTaskItem & {
  id?: number;
  deleted: false;
  actionable_type: FollowupRuleTaskEnum;
};

export type FollowupRuleTaskActionItem = TaskItem & {
  id?: number;
  deleted: false;
  actionable_type: FollowupRuleTaskEnum;
};

export type CollectionFollowupItem = {
  id?: number;
  deleted: boolean;
  title: string;
  description?: string;
  customer_count: number;
  invoice_count: number;
  type?: CollectionFollowupType;
  enabled: boolean;
  default: boolean;
  dunning_rules?: Array<FollowupRuleItem>;
  pausing_rules: Array<FollowupPauseResumeRuleItem>;
  stopping_rules: Array<FollowupStopRuleItem>;
  settings: FollowupSettingsItem;
};

export type FollowupPauseResumeRuleItem = {
  id?: number;
  deleted: boolean;
  event: FollowupRulePauseEventItem;
  resume_event?: FollowupRuleResumeEventItem;
};

export type FollowupRulePauseEventItem = {
  eventable_type: FollowupRuleTriggerCondition;
  id?: number;
  deleted: boolean;
  name: PauseEventType | PauseEventTypeEnum;
};

export type FollowupRuleResumeEventItem = {
  eventable_type: FollowupRuleTriggerCondition;
  id?: number;
  deleted: boolean;
  name?: ResumeEventType;
  days?: number;
  date_type?: ResumeDateType;
};

export type FollowupStopRuleItem = {
  id?: number;
  deleted: boolean;
  event: FollowupRuleStopEventItem;
};

export type FollowupRuleStopEventItem = {
  id?: number;
  deleted: false;
  eventable_type: FollowupRuleTriggerCondition;
  name: StopEventType | StopEventTypeEnum;
};

export type FollowupSettingsItem = {
  email_delivery_time: string;
  email_delivery_start_dow: number;
  email_delivery_end_dow: number;
  run_on_old_invoices: boolean;
  run_when_invoice_file_missing: boolean;
  run_when_email_missing: boolean;
};

export type GetCollectionFollowupResponseState = {
  status: number;
  message?: string;
  collectionFollowup?: CollectionFollowupItem;
};

export type DeleteCollectionFollowupRuleResponseState = {
  status: number;
  message?: string;
  followupId?: number;
  ruleId?: number;
};

export type SaveCollectionFollowupRuleResponseState = {
  status: number;
  message?: string;
  followupId?: number;
  followupRule?: FollowupRuleItem;
};

export type CollectionFollowupPauseResumeRuleResponseState = {
  status: number;
  message?: string;
  followupId?: number;
  pauseResumeRule?: FollowupPauseResumeRuleItem;
};

export type CollectionFollowupStopRuleResponseState = {
  status: number;
  message?: string;
  followupId?: number;
  stopRules?: Array<FollowupStopRuleItem>;
};

export type CollectionFollowupStatsResponseState = {
  id: number;
  collection_startegy_title: string;
  followup_action_stats: Array<FollowupActionStat>;
};

export type FollowupActionStat = {
  id: number;
  title: string;
  email_sent: number;
  email_viewed: number;
  email_responded: number;
};
