import { CustomFieldFilterValues, CustomFieldOperatorType } from 'types/entities/custom-field';
import { InvoiceStatus } from 'types/entities/invoice';
import {
  InvoiceAmountSegmentFilter,
  InvoiceBalanceAmountFilters,
  InvoiceCustomFieldSegmentFilter,
  InvoiceDueDaysSegmentFilter,
  InvoiceSegment,
  InvoiceSegmentFilterType,
  InvoiceSegmentId,
  InvoiceStatusSegmentFilter,
  InvoiceSubsidiarySegmentFilter,
  SegmentOperator,
} from 'types/entities/invoice-segments';
import {
  GreaterThanOperator,
  InOperator,
  InvoiceSegmentOperatorType,
} from 'types/entities/invoice-segments/operators';

export enum InvoiceSegmentErrorField {
  title = 'rule_title',
}

export type InvoiceSegmentErrorFields<
  T extends InvoiceSegmentErrorField = InvoiceSegmentErrorField
> = Partial<{
  [key in T]: string;
}>;

export interface InitialState {
  invoiceSegmentManagementOpen: boolean;
  currentSelectedSegmentId: InvoiceSegmentId;
  invoiceSegments: InvoiceSegment[];
  invoiceStatus: InvoiceSegment[];
  invoiceSegmentForm: InvoiceSegment;
  errors: Record<string, string | undefined>;
  errorsVisible: boolean;
}

export const defaultInvoiceSegment: InvoiceSegment = {
  id: 'new-segment',
  title: 'New Segment',
  deleted: false,
  operator: SegmentOperator.ANY,
  filters: [],
};

export const NEW_SEGMENT_ID = 'new-segment';

export const initialState: InitialState = {
  invoiceSegmentManagementOpen: false,
  currentSelectedSegmentId: NEW_SEGMENT_ID,
  invoiceSegments: [],
  invoiceSegmentForm: defaultInvoiceSegment,
  errors: {},
  errorsVisible: false,
  invoiceStatus: [],
};

const defaultGreaterThanOperatorData: GreaterThanOperator<number> = {
  type: InvoiceSegmentOperatorType.GREATER_THAN,
  operands: {
    type: 'DEPRECATED_ABSOLUTE',
    value: 0,
  },
};
const defaultInOperatorData: InOperator<unknown> = {
  type: InvoiceSegmentOperatorType.IN,
  operands: {
    type: 'DEPRECATED_ABSOLUTE',
    value: [],
  },
};

const defaultCustomFieldInOperatorData: CustomFieldFilterValues<'IN'>['operator'] = {
  type: 'IN',
  operands: {
    type: 'DEPRECATED_ABSOLUTE',
    value: [],
  },
};
const defaultNumberBasedOperatorData: CustomFieldFilterValues<CustomFieldOperatorType>['operator'] =
  {
    type: 'GOE',
    operands: {
      type: 'DEPRECATED_ABSOLUTE',
      value: '1',
    },
  };
export const defaultInvoiceAmountData: InvoiceAmountSegmentFilter = {
  type: InvoiceSegmentFilterType.INVOICE_AMOUNT,
  operator: defaultGreaterThanOperatorData,
  id: 'inv-amount',
};
export const defaultInvoiceStatusData: InvoiceStatusSegmentFilter = {
  type: InvoiceSegmentFilterType.INVOICE_STATUS,
  operator: defaultInOperatorData as InOperator<InvoiceStatus>,
  id: 'inv-status',
};
export const defaultInvoiceDueDaysData: InvoiceDueDaysSegmentFilter = {
  type: InvoiceSegmentFilterType.INVOICE_DUE_DAYS,
  operator: defaultGreaterThanOperatorData,
  id: 'inv-due-days',
};
export const defaultInvoiceCustomFieldData: InvoiceCustomFieldSegmentFilter = {
  type: InvoiceSegmentFilterType.CUSTOM_FIELD,
  operator: defaultCustomFieldInOperatorData,
  id: -1,
};
export const defaultInvoiceCustomFieldDataNumberBased: InvoiceCustomFieldSegmentFilter = {
  type: InvoiceSegmentFilterType.CUSTOM_FIELD,
  operator: defaultNumberBasedOperatorData,
  id: -1,
};

export const defaultBalanceAmountData: InvoiceBalanceAmountFilters = {
  type: InvoiceSegmentFilterType.INVOICE_BALANCE_AMOUNT,
  operator: defaultGreaterThanOperatorData,
  id: 'balance-amount',
};

export const defaultInvoiceSubsidiaryData: InvoiceSubsidiarySegmentFilter = {
  type: InvoiceSegmentFilterType.INVOICE_SUBSIDIARY,
  operator: defaultInOperatorData as InOperator<number>,
  id: 'inv-subsidiary',
};
