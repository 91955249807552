import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'antd';
import { DateText } from 'components/BaseComponents/Date/DateText';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Item, List, Meta } from 'components/BaseComponents/Lists';
import { GrowText } from 'components/BaseComponents/Typography';
import { usePageNavigate } from 'lib/router';
import styled from 'styled-components';
import { EmailTemplates } from 'types/entities/email-templates';
import Actions from './Actions/Actions';
import { TemplateDesc } from './TemplateDesc/TemplateDesc';
import { TemplateTitle } from './TemplateTitle/TemplateTitle';

interface EmailTemplateListCardProps {
  templates: EmailTemplates[];
}

const StyledWrapper = styled(Card)`
  min-width: 800px;
  border-color: var(--gray-5);
  min-height: 70px;
  cursor: pointer;
  margin-bottom: var(--space-8);
  border-radius: 4px;

  .ant-list-item-meta {
    max-width: 400px;
  }

  .ant-list-item-meta-title {
    margin-bottom: 0;
    line-height: 1.2;
  }
  .ant-list-item-meta-avatar {
    margin-right: var(--space-12);
  }

  .ant-list-item-action {
    margin-left: auto;
    li {
      .hide-actions {
        visibility: hidden;
      }
    }
  }
  &:hover {
    border-color: var(--purple-4) !important;
    box-shadow: var(--shadow-2);
    .ant-list-item-action {
      li {
        .hide-actions {
          visibility: visible;
        }
      }
    }
  }
`;

const EmailIcon = (
  <FontAwesomeIcon icon={['far', 'envelope']} color="var(--geekblue-5)" fontSize={18} />
);
function EmailTemplateListCard(props: EmailTemplateListCardProps) {
  const navigate = usePageNavigate();
  return (
    <List<EmailTemplates>
      dataSource={props.templates}
      itemLayout="horizontal"
      renderItem={(item) => {
        return (
          <StyledWrapper
            styles={{
              body: { padding: 'var(--space-16) var(--space-24)' },
            }}
            bordered
          >
            <Item
              style={{ padding: 0 }}
              actions={[<Actions key={item.id} template={item} />]}
              onClick={(e) => {
                e.stopPropagation();
                navigate({ appPage: 'EMAIL_TEMPLATE', pathParams: { id: item.id } });
              }}
            >
              <Meta
                avatar={EmailIcon}
                title={<TemplateTitle title={item.title} />}
                description={<TemplateDesc description={item.description} />}
              />

              <Flex direction="column" justify="flex-start">
                <GrowText size="var(--fs-12)" style={{ color: 'var(--gray-7)' }}>
                  Last Modified
                </GrowText>
                <Flex align="center">
                  <GrowText
                    size="var(--fs-14)"
                    style={{ color: 'var(--gray-9)', maxWidth: '140px' }}
                    ellipsis={{
                      tooltip: `${item.updated_by.first_name} ${item.updated_by.last_name ?? ''}`,
                    }}
                  >
                    {item.updated_by.deleted
                      ? 'unknown'
                      : `${item.updated_by.first_name} ${item.updated_by.last_name ?? ''}`}
                  </GrowText>
                  ,&nbsp;
                  <DateText
                    value={item.updated_at}
                    textProps={{ style: { color: 'var(--gray-7)' } }}
                  />
                </Flex>
              </Flex>
            </Item>
          </StyledWrapper>
        );
      }}
    />
  );
}

export { EmailTemplateListCard };
