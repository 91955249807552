import { ErrorBoundary } from '@sentry/react';

import { useCallback, useState } from 'react';
import { FallbackRender } from './FallbackRender';
import { ErrorBoundaryWrapperProps, ErrorHandler, ErrorType } from './types';

export { ErrorType };

type Fallback = React.ComponentProps<typeof ErrorBoundary>['fallback'];

const fallbackComponent: React.CSSProperties = {
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
};

/**
 * @param props of type IErrorBoundaryWrapper
 * @description
 * A toplevel component for handling errors when
 * wrapped around a plausible component that could
 * result in an error that potentially breaking the UI.
 * @returns React component
 */

export const ErrorBoundaryWrapper = (props: ErrorBoundaryWrapperProps) => {
  const FallbackComponent: Fallback = (fallbackProps) => {
    return (
      <div style={{ ...fallbackComponent, ...props.style }}>
        <FallbackRender error={fallbackProps.error} redirect={props.redirect} />
      </div>
    );
  };

  return (
    <ErrorBoundary
      fallback={FallbackComponent}
      beforeCapture={(scope) => {
        scope.setTag('location', window.location.pathname);
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
};

/**
 * @param errorHanlderProps
 * @description
 * a custom hook to set the incoming propType
 * that interally calls a hook provided by
 * react error boundary. This provides an ability
 * to manually trigger an error fallback to be shown.
 *
 * @example
 *  useErrorHandler({
    type: EErrorTypes.API_ERROR,
    message: "test description",
    serverErrorDesc: {
      status: "info",
      title: "server Error",
      subTitle: "Server error description"
    }
  })

 * @returns reactHanlderError hook
 */

const fallBackErrorProps = {
  message: 'Something went wrong..',
  serverErrorDesc: {
    status: 'error',
    title: 'Something went wrong..',
  },
};

export const useAsyncError = () => {
  const [_, setError] = useState();

  return useCallback((e: ErrorHandler) => {
    setError(() => {
      throw e ?? fallBackErrorProps;
    });
  }, []);
};
