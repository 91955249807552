import { DateRangeString } from 'components/BaseComponents/DateRangeSelect/types';
import useDateFormat from 'components/HigherOrderComponent/KeyActivitesContainer/hooks/useDateFormat';
import { RelativeDateRangeLabels } from 'constants/date-time';
import { useMemo } from 'react';
import { getFormattedDateForRelativeOptions, RelativeDateRange } from 'util/relative-date-range';
import { BaseFilterBlockProps } from '../FilterBlocks/types';
import { FilterDropdown } from '../FilterDropdown';
import { FilterConfig, FilterType } from '../types';

const t = {
  all: 'All',
};

interface DateRangeProps extends BaseFilterBlockProps<Partial<DateRangeString>> {
  filterConfig: Pick<FilterConfig, 'name' | 'label' | 'emptyText' | 'options'>;
  showDefaultValue?: boolean;
  showAllRelativeOptions?: boolean;
}

const allRelativeOptions = [
  RelativeDateRange.TODAY,
  RelativeDateRange.TOMORROW,
  RelativeDateRange.THIS_WEEK,
  RelativeDateRange.NEXT_WEEK,
  RelativeDateRange.LAST_7_DAYS,
  RelativeDateRange.THIS_MONTH,
  RelativeDateRange.LAST_MONTH,
  RelativeDateRange.LAST_30_DAYS,
  RelativeDateRange.MORE_THAN_30_DAYS,
];

export function DateRangeWrapper(props: DateRangeProps) {
  const {
    filterConfig,
    onClear,
    onSubmit,
    showClear = true,
    value,
    showDefaultValue,
    showAllRelativeOptions,
  } = props;
  const { emptyText } = filterConfig;
  const formatDate = useDateFormat();
  const filterConfigo: FilterConfig = {
    type: FilterType.DATE_RANGE,
    valueToString: (value: DateRangeString) => {
      if (value) {
        return value.type && value?.type !== 'CUSTOM_RANGE'
          ? RelativeDateRangeLabels[value.type]
          : `${formatDate(value.from)} to ${formatDate(value.to)}`;
      }

      return emptyText ?? t.all;
    },
    additionalProps: {},
    ...filterConfig,
  };

  if (showAllRelativeOptions) {
    filterConfigo.additionalProps = {
      options: allRelativeOptions,
    };
  }

  function handleClear() {
    onClear && onClear();
    onSubmit && onSubmit({ from: undefined, to: undefined, type: undefined });
  }

  function handleSubmit({ from, to, type }: DateRangeString) {
    onSubmit && onSubmit({ from, to, type });
  }

  const defaultValue: DateRangeString = useMemo(() => {
    const [from, to] = getFormattedDateForRelativeOptions(RelativeDateRange.THIS_WEEK);

    return {
      from,
      to,
      type: RelativeDateRange.THIS_WEEK,
    };
  }, []);

  const computedValue: DateRangeString | undefined = useMemo(() => {
    if (value?.type) {
      return value as DateRangeString;
    }

    //Use case is to show placeholders instead of default values in general

    return showDefaultValue ? defaultValue : undefined;
  }, [defaultValue, showDefaultValue, value]);

  return (
    <FilterDropdown
      value={computedValue}
      filterConfig={filterConfigo}
      onSubmit={handleSubmit}
      onClear={handleClear}
      showClear={showClear}
    />
  );
}
