import { Flex } from 'components/BaseComponents/Layout/Flex';
import useGetCurrencyName from 'hooks/use-currency';
import { useGetAccountsData } from 'queries/Settings/account';
import useGetBaseCurrencySymbol from 'util/hooks/useGetBaseCurrencySymbol';
import SettingsComponent from '../template';
import BaseCurrency from './CurrencySettingBody/BaseCurrency/BaseCurrency';
import CurrencyExchangeConfig from './CurrencySettingBody/Configuration/CurrencyExchangeConfig';
import { SelectedCurrenciesProvider } from './CurrencySettingBody/CurrencyContextApi/ContextApi';

const t = {
  headerTitle: 'Currency',
  headerDescription: 'Manage your current settings and exchange rates for currency conversion',
};

const bodyStyles: React.CSSProperties = {
  marginTop: 'var(--space-40)',
  marginBottom: 'var(--space-40)',
};

export default function CurrencPage() {
  const { data, isLoading } = useGetAccountsData();
  const { currencySymbol } = useGetBaseCurrencySymbol();
  const baseCurrency = useGetCurrencyName(data?.currency);

  const Body = (
    <Flex
      direction="column"
      gap="var(--space-24)"
      justify="center"
      align="center"
      style={bodyStyles}
    >
      <BaseCurrency
        baseCurrencyName={baseCurrency}
        baseCurrency={data?.currency}
        baseCurrencySymbol={currencySymbol}
        accountDataLoading={isLoading}
      />
      <CurrencyExchangeConfig baseCurrency={data?.currency} accountDataLoading={isLoading} />
    </Flex>
  );

  return (
    <SelectedCurrenciesProvider>
      <SettingsComponent headerTitle={t.headerTitle} headerDescription={t.headerDescription}>
        {Body}
      </SettingsComponent>
    </SelectedCurrenciesProvider>
  );
}
