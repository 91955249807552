import * as SentrySdk from '@sentry/react';
import { handleBeforeBreadcrumb } from './beforeBreadcrumbHandlers';
import { handleBeforeSend } from './beforeSendHandlers';
import { allowedUrls, deniedUrls, ignoredErrors } from './constants';

function getAccountName() {
  return window.location.hostname.split('.')[0];
}
function getCurrentPage() {
  return window.location.pathname;
}

// Do not use this directly in app, use the wrapper `src/lib/error-monitor` instead
export const Sentry = {
  init() {
    SentrySdk.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: import.meta.env.VITE_VERSION,
      environment: import.meta.env.VITE_ENV,
      maxBreadcrumbs: 50,
      
      integrations: [
        new SentrySdk.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
        new SentrySdk.BrowserTracing(),
      ],
      // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,
      beforeSend: handleBeforeSend,
      beforeBreadcrumb: handleBeforeBreadcrumb,
      allowUrls: allowedUrls(),
      denyUrls: deniedUrls(),
      ignoreErrors: ignoredErrors(),
      initialScope: (scope) => {
        scope.setTag('account', getAccountName());
        return scope;
      },

      // Session Replay
      replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  },

  identify({ email }: { email: string }) {
    SentrySdk.setUser({ email });
  },

  logError(error: Error, errorInfo: any = null) {
    SentrySdk.withScope((scope) => {
      errorInfo && scope.setExtras(errorInfo);
      scope.setTag('page', getCurrentPage());
      SentrySdk.captureException(error, errorInfo);
    });
  },
};

/**
 * Pluggable Integrations (Ref: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/plugin/):
 * Offline -> caches errors locally if no network and sends to sentry after network is established
 * CaptureConsole -> listens to console logs and sends them to sentry (eg: we can configure to send console.errors)
 */
