import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowCheckBox, GrowTypography } from '@sinecycle/growcomponents';
import { Card, Checkbox, Form, Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledIconButton } from 'components/FilterViews/style';
import { useState } from 'react';
import styled from 'styled-components';
import { CustomerStatement } from 'types/customerstatement/customer-statement';
import useKeyGenerator from 'util/hooks/useKeyGenerator';
import { togglePredicate } from 'util/predicates';
import CustomerStatements, { FormName } from './Statements';

interface AssociatedCustomerStatementProps {
  hide?: boolean;
  checked?: boolean;
  onChange: (status: boolean) => void;
  statement?: CustomerStatement;
  onFormChange: (formName: FormName, statement: CustomerStatement) => void;
}

type CheckBoxProps = React.ComponentProps<typeof Checkbox>;
type ChangeEvent = Parameters<NonNullable<CheckBoxProps['onChange']>>['0'];
type FormProvider = React.ComponentProps<typeof Form.Provider>;
type FormInfo = Parameters<NonNullable<FormProvider['onFormChange']>>['1'];
const StyledCard = styled(Card)`
  border-color: var(--gray-1);
  border-radius: var(--br-1);
  .ant-card-body {
    padding: var(--space-16) var(--space-12);
  }

  &:hover {
    border-color: var(--primary-4);
  }
`;

const StyledMeta = styled(Card.Meta)`
  width: 100%;
  &.ant-card-meta-title {
    margin: 0 !important;
  }
  .ant-card-meta-avatar {
    order: 2;
  }
`;

const t = {
  tooltipText: {
    exclude: 'Exclude File',
    include: 'Include File',
  },
  title: 'Customer Statement',
  desc: 'Configure to attach',
  format: 'File Format',
};

function AssociatedCustomerStatement(props: Readonly<AssociatedCustomerStatementProps>) {
  const [checked, setChecked] = useState(initializeChecked);
  const [configure, setConfigure] = useState(initializeChecked);
  const key = useKeyGenerator({ items: props.statement });

  function initializeChecked() {
    return props.checked;
  }

  if (props.hide) return null;

  function handleChange(event: ChangeEvent) {
    setChecked(event.target.checked);

    props.onChange(event.target.checked);
  }
  function handleConfigure(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setConfigure(togglePredicate);
  }

  function handleFormChange(name: string, info: FormInfo) {
    const values: CustomerStatement = { ...info.forms[name].getFieldsValue() };
    props.onFormChange(name as FormName, values);
  }

  const icon: IconProp = configure ? ['far', 'chevron-up'] : ['far', 'chevron-down'];
  const StatementIcon = (
    <FontAwesomeIcon icon={['far', 'file-lines']} color="var(--cyan-6)" fontSize={16} />
  );
  const ChevRonIcon = <FontAwesomeIcon icon={icon} color="var(--primary-7)" />;

  const Info = <GrowTypography.Text strong>{t.title}</GrowTypography.Text>;

  const ToggleButton = <StyledIconButton icon={ChevRonIcon} onClick={handleConfigure} />;

  const Statement = (
    <Tooltip title={checked ? t.tooltipText.exclude : t.tooltipText.include} zIndex={100_000}>
      <GrowCheckBox
        checked={checked}
        onChange={handleChange}
        design="border-design"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Flex align="flex-start" justify="flex-start" gap="var(--space-8)">
          {StatementIcon}
          {Info}
        </Flex>
      </GrowCheckBox>
    </Tooltip>
  );

  const StatementArea = (
    <Form.Provider onFormChange={handleFormChange}>
      <CustomerStatements hide={!configure} statement={props.statement} key={key} />
    </Form.Provider>
  );
  const Header = (
    <Flex justify="space-between">
      {Statement}
      {ToggleButton}
    </Flex>
  );

  return (
    <StyledCard>
      <StyledMeta title={Header} description={StatementArea} />
    </StyledCard>
  );
}

export default AssociatedCustomerStatement;
