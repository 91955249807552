import { RawData, EncodedData } from './types';

export function encodeEditorState(json: RawData): EncodedData {
  const resJson: EncodedData = {
    b: [],
    i: [],
  };

  json.blocks.forEach((block, idx) => {
    resJson.b[idx] = { t: '' };
    resJson.b[idx].t = block.text;
    if (block.entityRanges?.length) {
      resJson.b[idx].e = block.entityRanges.map((range) => ({
        o: range.offset,
        l: range.length,
        k: range.key,
      }));
    }
  });

  Object.keys(json.entityMap).forEach((entityKey) => {
    const entity = json.entityMap[entityKey];
    entity.data && resJson.i.push(entity.data.mention.id);
  });

  return resJson;
}

export function decodeEditorState(jsonObj: EncodedData, users: any): RawData {
  const rawData = {} as RawData;

  rawData.blocks = jsonObj?.b?.map((block, index) => {
    return {
      text: block.t,
      key: index.toString(),
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: block.e
        ? block.e.map((entityRange) => ({
            offset: entityRange.o,
            length: entityRange.l,
            key: entityRange.k,
          }))
        : [],
      data: {},
    };
  });

  const entities: Record<string, object> = {};
  jsonObj?.i?.forEach((userId, idx) => {
    entities[idx.toString()] = {
      type: 'mention',
      mutability: 'IMMUTABLE',
      data: {
        mention: users[userId],
      },
    };
  });

  rawData.entityMap = entities;

  return rawData;
}
