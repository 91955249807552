import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { Expansion } from 'components/Common/Expansion/Expansion';
export function removeAttributeFromHTMLString(html: string, attr: string, element: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const docElement = doc.querySelector(element);
  docElement?.removeAttribute(attr);
  return docElement?.outerHTML;
}

export function removeEmailContentClass(html: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html').body;
  doc.querySelectorAll('.reply-email').forEach((element) => {
    element.classList.remove('reply-email');
  });
  return doc.innerHTML;
}
interface EmailContentProps extends NodeViewProps {}

interface ContentProps {
  value: string;
  onClick: () => void;
}

export default function EmailContent(props: EmailContentProps) {
  const { node, editor } = props;

  function handleClickCallBack() {
    const html = `<p></p><p></p>${removeEmailContentClass(editor.getHTML())}`;
    editor.commands.setContent(html);
  }
  return (
    <NodeViewWrapper contentEditable={false} as="span">
      <Content value={node.attrs['data-html']} onClick={handleClickCallBack} />
    </NodeViewWrapper>
  );
}

function Content(params: ContentProps) {
  return (
    <Expansion
      onClick={() => {
        params.onClick();
      }}
    />
  );
}
