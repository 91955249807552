import { AdvancedReport } from 'components/AdvancedReports';
import { PageContainer } from 'router/PageContainer';

export function AdvanceReportPage(props: unknown) {
  return (
    <PageContainer page={'ADVANCED_REPORTS'}>
      <AdvancedReport />
    </PageContainer>
  );
}
