import { SizeType } from 'antd/lib/config-provider/SizeContext';
import SelectComponent, { IProps } from 'components/BaseComponents/ASelect';
import { notify } from 'components/BaseComponents/Notifications';
import { ClosedDesc } from 'components/CollectionActivities/Common/Details/ClosedDesc';
import { Style } from 'lib/style/style';
import { useUpdateEscalationStatus } from 'queries/activites';
import { useEffect, useState } from 'react';
import { EscalationType, EscalationTypeMenu } from 'types/activities/escalation';

const t = {
  successMessage: 'Escalation Status Updated',
  error: {
    title: 'Status not updated',
    description: 'Please try performing the status update again',
  },
};

const StyledEscalationStatus = Style(SelectComponent, {
  '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    border: '1px solid var(--gray-6) !important',
  },

  '.ant-select-arrow': {
    color: 'var(--primary-7)',
  },

  '&:hover': {
    '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      border: '1px solid var(--primary-7) !important',
    },
  },

  variants: {
    status: {
      OPEN: {
        '.ant-select-selection-item': {
          color: 'var(--red-7) !important',
        },

        '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
          background: 'var(--red-1) !important',
        },
      },
      CLOSED: {
        '.ant-select-selection-item': {
          color: 'var(--green-8) !important',
        },

        '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
          background: 'var(--green-1) !important',
        },
      },
    },
  },
});

interface EscalationStatusProps {
  status: EscalationType;
  escalationActivityId: number;
  onChangeCallBack?: (status: EscalationType) => void;
  size?: SizeType;
  activitiesMode?: boolean;
}

export function EscalationStatusQuickSelect(props: EscalationStatusProps) {
  const [escalationStatus, setEscalationStatus] = useState<EscalationType | undefined>();
  const { status, escalationActivityId, onChangeCallBack, size, activitiesMode } = props;
  const { mutateAsync: updateEscalationStatus } = useUpdateEscalationStatus();

  useEffect(() => {
    setEscalationStatus(status);
  }, [status]);

  async function handleChange(status: IProps<EscalationType>) {
    const statusValue = status.value === 'CLOSED';
    try {
      await updateEscalationStatus({ params: { status: status.value }, id: escalationActivityId });
      notify.success(statusValue ? 'Activity Marked as Closed' : t.successMessage, {
        description: !activitiesMode && statusValue ? <ClosedDesc entity="escalation" /> : null,
      });
      setEscalationStatus(status.value);
      onChangeCallBack?.(status.value);
    } catch {
      throw new Error();
    }
  }

  return (
    <StyledEscalationStatus
      status={escalationStatus}
      selectedValues={escalationStatus}
      optionsList={EscalationTypeMenu}
      onChangeCallBack={handleChange}
      size={size}
      dropdownStyle={{ minWidth: 'fit-content' }}
    />
  );
}
