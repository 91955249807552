import { CellRenderProps, defaultCellComponents } from '@sinecycle/growcomponents';
import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';

function NumberCell<T>(props: CellRenderProps<T, number>) {
  const locale = useSelector(localeSelector);

  return isNumber(props.value) ? (
    defaultCellComponents.numberCell({
      ...props,
      numberCellProps: {
        ...props.numberCellProps,
        options: {
          locale,
        },
      },
    })
  ) : (
    <div>-</div>
  );
}

export { NumberCell };
