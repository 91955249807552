import { CellRenderProps } from '@sinecycle/growcomponents';
import { Empty } from 'components/BaseComponents/Empty/Empty';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { CustomerTags } from 'components/Common/HighRiskCustomertags/CustomerTags';

function CustomerTagsCell<T>(props: CellRenderProps<T>) {
  const { value } = props;
  const tags = value as string[];

  if (!value) return '-';

  return (
    <Empty isEmpty={!tags.length}>
      <Flex align="center" style={{ height: '100%' }}>
        <CustomerTags tags={tags} />
      </Flex>
    </Empty>
  );
}

export { CustomerTagsCell };
