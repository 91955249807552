import { MutationFunction, useMutation } from '@tanstack/react-query';
import { MutationFunctionProps } from '../types';

export function useFormSubmitMutation<T>(
  mutationFn: MutationFunction<T, MutationFunctionProps<T>>
) {
  return useMutation({
    mutationFn,
    retry: false,
    mutationKey: ['activity-forms'],
  });
}

export function useFormMutation<T>(mutationFn: MutationFunction<T[], string>) {
  return useMutation({
    mutationFn,
    retry: false,
  });
}
