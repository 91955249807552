import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { NumberFormat } from 'components/Common/NumberFormat/NumberFormat';
import { InfoPopover } from 'components/Common/UtilityPopover';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useDSOTileQuery } from 'queries/admin-dashboard';
import React from 'react';
import { AccountConfigKey } from 'types/entities/account';
import useShowBadDebtAndIsDemo from '../hooks/useShowBadDebtAndIsDemo';
import { StyledTileCard } from '../styles';
import { dashboardTexts } from '../texts';
import { DangerText, StyledDivider, SuccessText, TooltipParagraph } from './style';

const componentTexts = dashboardTexts.dsoTile;

function DSOTile({ filterParams }: DashboardComponentProps) {
  const { data: currentDSO, isLoading: loading } = useDSOTileQuery(filterParams);

  const enableNetSales = useConfig(AccountConfigKey.NET_SALES_ENABLED);
  const showBadDebtandIsDemo = useShowBadDebtAndIsDemo();

  const currentMonthDSO = currentDSO?.current_month.dso ?? 0,
    prevMonthDSO = currentDSO?.previous_month.dso ?? 0,
    bpDSO = currentDSO?.bpdso,
    sales = currentDSO && {
      value: currentDSO?.current_month.sales,
      currency: currentDSO?.currency,
    };

  const InputLoaderJSX = <InputLoader active={loading} style={{ width: '90px' }} />;

  const dsoChange = (currentMonthDSO - prevMonthDSO).toFixed(1);

  const SuccessTextJSX = (
    <SuccessText>
      <FontAwesomeIcon
        icon={['far', 'arrow-down']}
        style={{ marginRight: 'var(--space-4)', color: 'var(--green-7)' }}
      />
      {componentTexts.daysCount(dsoChange)}
    </SuccessText>
  );

  const WarningTextJSX = (
    <DangerText>
      <FontAwesomeIcon icon={['far', 'arrow-up']} style={{ marginRight: 'var(--space-4)' }} />
      {componentTexts.daysCount(dsoChange)}
    </DangerText>
  );

  const DsoDays = (
    <Flex direction="column" justify={showBadDebtandIsDemo ? 'space-between' : 'flex-start'}>
      <GrowText size={showBadDebtandIsDemo ? 'var(--fs-24)' : 'var(--fs-20)'} strong>
        {loading ? InputLoaderJSX : componentTexts.daysCount(currentMonthDSO.toFixed(0) ?? '')}
      </GrowText>
      <div style={{ marginTop: showBadDebtandIsDemo ? 'var(--space-12)' : 0 }}>
        {loading ? (
          InputLoaderJSX
        ) : (
          <>
            {Number(dsoChange) >= 0 ? WarningTextJSX : SuccessTextJSX}
            <div>
              <GrowText>{componentTexts.sinceLast}</GrowText>
            </div>
          </>
        )}
      </div>
    </Flex>
  );

  const BpdsoSales = (
    <Flex
      direction={showBadDebtandIsDemo ? 'row' : 'column'}
      gap={showBadDebtandIsDemo ? 'var(--space-48)' : 'var(--space-12)'}
      align={showBadDebtandIsDemo ? 'center' : 'flex-start'}
      justify={'flex-start'}
    >
      <Flex direction="column">
        <Flex align="center" gap="var(--space-4)">
          <GrowText color={'var(--gray-8)'}>{componentTexts.bpdso}</GrowText>
          <div style={{ cursor: 'pointer' }}>
            <InfoPopover
              icon="help"
              title={componentTexts.bdpsoHelpTitle}
              content={<TooltipParagraph>{componentTexts.bpdsoHelpText}</TooltipParagraph>}
            />
          </div>
        </Flex>
        {loading ? (
          InputLoaderJSX
        ) : (
          <NumberFormat
            value={Number(bpDSO)}
            textProps={{ strong: true, fs: '16' }}
            mode="DECIMAL"
          />
        )}
      </Flex>
      <Flex direction="column" style={{ marginTop: showBadDebtandIsDemo ? 'var(--space-4)' : 0 }}>
        <GrowText style={{ marginRight: 'var(--space-8)' }} color={'var(--gray-8)'}>
          {enableNetSales ? componentTexts.net_sales : componentTexts.sales}
        </GrowText>
        {loading ? (
          InputLoaderJSX
        ) : sales ? (
          <AmountWithCurrencySplit
            type="multi"
            amountWithCurrencySplit={sales}
            showTooltip
            className="text-16 semi-bold"
          />
        ) : null}
      </Flex>
    </Flex>
  );

  return (
    <StyledTileCard>
      <Row gutter={[4, 4]}>
        <Col flex={'1 1 30%'}>
          <Flex direction="row" gap={'var(--space-4)'}>
            <GrowText size={'var(--fs-16)'}>{componentTexts.title}</GrowText>
            <div style={{ cursor: 'pointer' }}>
              <InfoPopover
                icon="help"
                title={componentTexts.helpTitle}
                content={<TooltipParagraph>{componentTexts.helpText}</TooltipParagraph>}
              />
            </div>
          </Flex>
          {DsoDays}
        </Col>
        <StyledDivider
          type={showBadDebtandIsDemo ? 'horizontal' : 'vertical'}
          hideBadDebtandIsDemo={showBadDebtandIsDemo}
        />
        <Col flex={'1 1 30%'}>{BpdsoSales}</Col>
      </Row>
    </StyledTileCard>
  );
}

export default React.memo(DSOTile);
