import { CellRenderProps } from '@sinecycle/growcomponents';
import produce from 'immer';
import { CustomFieldData, CustomFieldDataResponse } from 'types/entities/custom-field';
import { CustomFiledColumn } from '../hooks/useCustomFieldColumns';
import EditCustomField from './EditCustomField';
interface CustomColumnProps<T> extends CellRenderProps<T> {
  fieldData: CustomFieldDataResponse;
  value: string;
}
export default function CustomCellEditor<T>(props: CustomColumnProps<CustomFiledColumn<T>>) {
  const field = props.column?.getColId() as string;
  const entityId = (props?.data as any)?.customer?.id || (props?.data as any)?.id;

  function updateCustomFieldData(
    currentCustomFieldData: CustomFieldData[],
    fieldDataId: number,
    newValue?: string
  ): CustomFieldData[] {
    const isFieldExisting = currentCustomFieldData.some(
      (item) => `${item.id}` === `${props.fieldData.id}`
    );

    return produce(currentCustomFieldData, (draft) => {
      if (isFieldExisting) {
        draft.forEach((item, index) => {
          if (`CUSTOM_FIELD_${item.id}` === field) {
            draft[index] = { ...item, value: newValue };
          }
        });
      } else {
        draft.push({
          id: fieldDataId,
          entityId,
          value: newValue,
        });
      }
    });
  }
  function handleSuccess(value?: string) {
    const currentCustomFieldData =
      (props?.node?.data?.custom_field_data as CustomFieldData[]) ?? [];
    const updatedCustomFieldData = updateCustomFieldData(
      currentCustomFieldData,
      props.fieldData.id,
      value
    );
    props.node.updateData({
      ...props.node.data,
      custom_field_data: updatedCustomFieldData,
    } as CustomFiledColumn<T>);
    props.api.stopEditing();
  }

  return (
    <EditCustomField
      key={props.fieldData?.id}
      options={props.fieldData?.options ?? []}
      successCallBack={handleSuccess}
      ui_type={props.fieldData.ui_type}
      entity_type={props.fieldData?.entity_type}
      entity_id={entityId ?? -1}
      custom_field_id={props.fieldData?.id}
      value={props.value}
      data_type={props.fieldData?.data_type}
    />
  );
}
