import { MutationFunctionProps } from 'components/Common/ActivitiesForm/types';
import { assign, head, isArray } from 'lodash';
import { ActivityKeyMap } from 'queries/activites';
import { postKeyActivitiesCustomerDetailsBulk } from 'services/bulkaction';
import { createDocuments } from 'services/documents';
import { createBulkTasks, postActivityFormData } from 'services/invoice';
import { agingFilter } from 'store/aging/types';
import { DraftInvoicesListFilters, OpenInvoicesListFilters } from 'store/invoice/type';
import { ActivityType } from 'types/activities/activity-types';
import { CreateDocument } from 'types/entities/documents';
import { ActivityMutationProps } from '../type';

export default function useFormSubmitMutate(customerId?: number) {
  function onFormMutateFn(
    data: MutationFunctionProps<ActivityMutationProps>,
    baseUrl?: string,
    selectAll?: boolean,
    currentFilterParam?: OpenInvoicesListFilters | DraftInvoicesListFilters | agingFilter,
    nested: boolean = true
  ) {
    if (selectAll && baseUrl) {
      const res = assign(
        {
          bulk_action: data.type,
          [data.type.toLowerCase()]: data.params,
        },
        nested ? { filter: currentFilterParam } : currentFilterParam
      );
      return postKeyActivitiesCustomerDetailsBulk(res, baseUrl, ['page', 'size']);
    }

    if (data.type === ActivityType.DOCUMENT) {
      return createDocuments(data.params as unknown as CreateDocument, customerId!);
    }

    if (data.bulkAction) {
      return createBulkTasks(data.params);
    }

    return postActivityFormData(
      isArray(data.params) ? head(data.params) : data.params,
      ActivityKeyMap[data.type]
    );
  }

  return onFormMutateFn;
}
