import Image from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/react';
const CustomImage = Image.extend({
  content: 'inline*',

  inline: true,
  draggable: true,
  selectable: false,

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (element) =>
          element.querySelector('img')?.getAttribute('src') || element.getAttribute('src'),
        renderHTML: (attributes) => {
          return { src: attributes.src };
        },
      },
      alt: {
        default: null,
        parseHTML: (element) =>
          element.querySelector('img')?.getAttribute('alt') || element.getAttribute('alt'),
        renderHTML: (attributes) => {
          return { alt: attributes.alt };
        },
      },
      title: {
        default: null,
        parseHTML: (element) =>
          element.querySelector('img')?.getAttribute('title') || element.getAttribute('title'),
        renderHTML: (attributes) => {
          return { title: attributes.title };
        },
      },
      width: {
        default: '',
        parseHTML: (element) =>
          element.querySelector('img')?.getAttribute('width') || element.getAttribute('width'),
        renderHTML: (attributes) => {
          return { width: attributes.width };
        },
      },
      href: {
        default: '',
        parseHTML: (element) => element.querySelector('a')?.getAttribute('href'),
        renderHTML: (attributes) => {
          return { href: attributes.href };
        },
      },
      target: {
        default: '',
        parseHTML: (element) => element.querySelector('a')?.getAttribute('target'),
        renderHTML: (attributes) => {
          return { target: attributes.target };
        },
      },
      style: {
        default: '',
        parseHTML: (element) => element.getAttribute('style') || '',
        renderHTML: (attributes) => {
          if (typeof attributes.style === 'object') {
            // Convert the style object to a CSS string
            return {
              style: Object.entries(attributes.style)
                .map(([key, value]) => `${key}: ${value}`)
                .join('; '),
            };
          }
          return { style: attributes.style };
        },
      },
      'data-drag-handle': {
        default: true,
      },
      oldUrl: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'figure',
      },
      {
        tag: 'img:not(.rte-custom-image)',
      },
      {
        tag: 'div.gf-rte-image-container',
        ignore: true,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { href, target, style, width, ...imgAttrs } = HTMLAttributes;

    // Wrap image in link if href is provided
    const image = [
      'img',
      mergeAttributes(imgAttrs, { draggable: true, contenteditable: false, style, width }),
    ];

    if (href) {
      return ['a', { href, target }, image];
    }

    return [
      'img',
      mergeAttributes(imgAttrs, { draggable: false, contenteditable: false, style, width }),
    ];
  },

  // waiting for BE implementation
  // addNodeView() {
  //   return getNodeViewRender(DefaultImage);
  // },
});

export { CustomImage };
