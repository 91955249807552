import {
  Account,
  AccountConfig,
  AccountDetails,
  AccountLogoDetails,
  LOGO_TYPE,
} from 'types/entities/account';
import ky from './ky';
import { LocaleOptions } from 'queries/Settings/account';

const baseUrl = 'accounts';

export async function fetchAccountDetails() {
  return (await ky.get(`${baseUrl}`).json()) as AccountDetails;
}

export async function postAccountDetails(data: Partial<Account>) {
  return (await ky.put(`${baseUrl}`, { json: data }).json()) as AccountDetails;
}

export async function postArEmailDetailsForAccount(data: Account) {
  return (await ky.put(`${baseUrl}/save-and-send-ar-email-activation`, { json: data })) as Response;
}

export async function verifyArEmailForAccount(hash_id: string) {
  return await ky.put(`${baseUrl}/ar-email-verification/${hash_id}`);
}

export async function resendArEmailActivationLink() {
  return await ky.put(`${baseUrl}/resend-ar-email-activation`);
}

export async function updateAccountConfig(accountConfig: AccountConfig) {
  return (await ky
    .patch(`${baseUrl}/update-account-config`, { json: accountConfig })
    .json()) as AccountConfig;
}

export async function updateAccountLogo(data: AccountLogoDetails) {
  const formdata = new FormData();
  formdata.append('file', (data[LOGO_TYPE.FAV_ICON] ?? data[LOGO_TYPE.LOGO])!);
  formdata.append('type', Object.keys(data)[0]);
  return (await ky.put(`${baseUrl}/image`, { body: formdata }).json()) as {
    logo?: string;
    fav_icon?: string;
  };
}
export async function getCountries() {
  return await ky.get(`${baseUrl}/countries`).json<string[]>();
}

export async function getLocale() {
  return await ky.get(`${baseUrl}/locales`).json<LocaleOptions[]>();
}
