import { GrowDate } from '@sinecycle/growcomponents';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';

type DateComponentProps = React.ComponentProps<typeof GrowDate>;

function DateText(props: Omit<DateComponentProps, 'format'>) {
  const format = useSelector(dateFormatSelector);
  return <GrowDate {...props} format={format} />;
}

export { DateText };
