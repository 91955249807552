import { notify } from 'components/BaseComponents/Notifications';

enum customEventNames {
  REDIRECTION_EVENT = 'growfin-redirect',
}

const customEventHandlers = {
  [customEventNames.REDIRECTION_EVENT]: actUponRedirectionEvent,
};

function actUponRedirectionEvent(customEvent: any) {
  const eventDetailsData = customEvent?.detail?.data?.data;
  const [, pathName] = eventDetailsData?.event?.split('=');
  const [basePath, variablePathKey] = pathName?.split(':');
  const domain = window.location.origin;

  let URL = `${domain}${basePath}`;
  const variablePathValue =
    variablePathKey && eventDetailsData?.extraData?.[variablePathKey]?.['value'];

  if (variablePathValue) {
    URL += variablePathValue;
  }
  window.open(URL);
}

export function getCustomEventHandler(customEvent: any) {
  const [eventName] = customEvent?.detail?.data?.data?.event?.split('=');
  if (customEventHandlers.hasOwnProperty(eventName)) {
    customEventHandlers[eventName as customEventNames](customEvent);
  } else {
    notify.error('Detected Unhandled Dashboard Event.');
  }
}
