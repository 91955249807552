import styled from 'styled-components';
import { GrowParagraph } from './GrowParagraph';
import { GrowText } from './GrowText';

export const ErrorText = styled.span`
  color: var(--red-5);
  overflow-wrap: break-word;
  font-size: var(--fs-14);
`;

export { GrowParagraph, GrowText };
