export enum FollowupRuleScopeType {
  INVOICE_SEGMENT = 'INVOICE_SEGMENT',
}

interface BaseFollowupRuleScope {
  id: number;
  type: FollowupRuleScopeType;
}
export interface InvoiceSegmentScope extends BaseFollowupRuleScope {
  type: FollowupRuleScopeType.INVOICE_SEGMENT;
  data: {
    title: string;
  };
}
export type FollowupRuleScope = InvoiceSegmentScope;
