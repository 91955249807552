import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { FileType } from 'types/common/attachments';
import { DocumentLevel, DocumentTypesList } from 'types/entities/documents';

export interface AssociatedFile {
  name?: string;
  id?: number | string;
  file_name?: string;
  file_format?: FileType;
  files?: string;
  file_size?: number;
  checked?: boolean;
  mode?: 'SINGLE' | 'BULK';
  level?: ActionableEntity;
  entity?: DocumentLevel;
  typeId?: string;
  docType?: DocumentTypesList;
  previewId?: number;
}

export const AFiles: AssociatedFile[] = [
  {
    name: 'invoice file',
    id: 1,
    file_format: FileType.PDF,
    checked: false,
  },
];
