import { GrowModal } from '@sinecycle/growcomponents';
import { Form, Input } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { t } from '../constants';
import { FiledValues, FilterView, FormItemProps, InputProps } from '../type';

interface FilterViewModalProps<T> {
  defaultValue: InputProps['defaultValue'];
  hide?: boolean;
  onOkCallBack: (values: FiledValues) => void;
  totalSavedViews: FilterView<T>[];
  saveAsMode: boolean;
  title?: React.ReactNode;
  open?: boolean;
  loading?: boolean;
  defaultValueDesc?: InputProps['defaultValue'];
  onCancelCallBack: () => void;
  disableOkButton: boolean;
}

function FilterViewModal<T>(props: Readonly<FilterViewModalProps<T>>) {
  const [form] = Form.useForm();

  if (!props.hide) {
    return null;
  }

  function getIsFieldDirty(name: NamePath) {
    return form.isFieldTouched(name);
  }
  function isSameViewName(value: string) {
    if (!value) return false;
    return Boolean(props.totalSavedViews.find((view) => view.name === value)?.name);
  }

  function handleEditViewValidation(_: RuleObject, value: string) {
    const isViewNameDirty = getIsFieldDirty(t.title);
    const hasSameFilterName = isSameViewName(value);
    if (!value) return Promise.reject('Please Fill this Filed');
    if (isViewNameDirty && hasSameFilterName) return Promise.reject(t.validation_text);
    return Promise.resolve();
  }

  function handleSaveAsViewValidation(_: RuleObject, value: string) {
    const hasSameFilterName = isSameViewName(value);
    const isViewNameDirty = getIsFieldDirty(t.title);
    if (!value) return Promise.reject('Please Fill this Filed');
    if ((!isViewNameDirty && hasSameFilterName) || (isViewNameDirty && hasSameFilterName))
      return Promise.reject(t.validation_text);

    return Promise.resolve();
  }

  const validator = props.saveAsMode ? handleSaveAsViewValidation : handleEditViewValidation;

  const nameRules: FormItemProps['rules'] = [
    {
      required: true,
      validateTrigger: 'onBlur',
      validator,
    },
  ];

  const Name = (
    <StyledFormItem
      name={t.title}
      label="Title"
      rules={nameRules}
      initialValue={props.defaultValue}
      preserve={false}
      className="sp-1"
    >
      <Input placeholder={t.title} />
    </StyledFormItem>
  );
  const Desc = (
    <StyledFormItem
      className="sp-1"
      name="description"
      label={'Description'}
      preserve={false}
      initialValue={props.defaultValueDesc}
    >
      <Input.TextArea />
    </StyledFormItem>
  );

  function handleOk() {
    form
      .validateFields()
      .then(handleSuccess)
      .catch(() => {});
  }

  function handleSuccess(values: FiledValues) {
    props.onOkCallBack(values);
  }

  return (
    <GrowModal
      mask={false}
      closable={false}
      title={props.title}
      open={props.open}
      onCancel={props.onCancelCallBack}
      onOk={handleOk}
      width={360}
      okText={props.loading ? t.saving : t.save}
      getContainer={false}
      confirmLoading={props.loading}
      styles={{
        header: {
          background: 'none',
        },
        footer: {
          background: 'none',
        },
      }}
      style={{
        margin: 0,
      }}
    >
      <Form layout="vertical" form={form} name="filterViews">
        {Name}
        {Desc}
      </Form>
    </GrowModal>
  );
}

export default FilterViewModal;
