import { Fragment, useCallback, useMemo } from 'react';
import { searchCustomFields } from 'services/custom-field';
import {
  CustomField,
  CustomFieldOption,
  CustomFieldSearchOptions,
} from 'types/entities/custom-field';
import { MultiSelectCheckboxWrapperWithSearch } from '../../FilterWrapper/MultiSelectCheckboxWrapperWithSearch';
import { FilterConfigWrapperProps } from '../../FilterWrapper/type';
import { CustomFieldFilter } from '../CustomerOrInvoice';

type CustomFieldFilterBlockProps = {
  searchable: boolean;
  hide?: boolean;
  onClear: (id: string) => void;
  customField: CustomField;
  handleSubmit: (value: CustomFieldOption[], customFiledId: number) => void;
  value: CustomFieldOption[] | undefined;
  searchType?: 'local' | 'remote';
  customFiledId: number;
  filterId: string;
  filterConfig: FilterConfigWrapperProps;
};

const customFiledOptionPredicate = (fieldOption: CustomFieldOption) => {
  return {
    label: `${fieldOption}`,
    value: String(fieldOption),
  };
};

function CustomFieldFilterBlock(props: CustomFieldFilterBlockProps) {
  const { searchType = 'local' } = props;

  function getCustomFiledOptions(): CustomFieldSearchOptions[] {
    return props.customField.options.map(customFiledOptionPredicate);
  }

  function handleSubmit(value: CustomFieldOption[]) {
    props.handleSubmit(value, props.customFiledId);
  }

  function onClear() {
    props.onClear(props.filterId);
  }
  async function searchCustomFieldLocal(keyword: string) {
    const customFiledOptions = getCustomFiledOptions();

    return customFiledOptions.filter((option) =>
      option.label.toLowerCase().includes(keyword.toLowerCase())
    );
  }

  function searchOptionsCallBack(value: string[]): CustomFieldSearchOptions[] {
    return value.map((option) => {
      return {
        label: option,
        value: option,
      };
    });
  }

  function memoizedOptionsCallback() {
    return props.filterConfig.options;
  }

  const searchOptions = useCallback(searchOptionsCallBack, []);
  async function searchCustomFieldRemote(keyword: string): Promise<CustomFieldSearchOptions[]> {
    const value = (await searchCustomFields(keyword, props.customFiledId)).options;
    return searchOptions(value);
  }

  const handleSearch = searchType === 'local' ? searchCustomFieldLocal : searchCustomFieldRemote;

  const options = useMemo(memoizedOptionsCallback, [props.filterConfig.options]);

  return (
    <Fragment>
      {props.searchable ? (
        <MultiSelectCheckboxWrapperWithSearch<CustomFieldSearchOptions, string[]>
          filterConfig={{ ...props.filterConfig, shouldIncludeUnassigned: true, options }}
          value={props.value as string[]}
          onSubmit={handleSubmit}
          onClear={onClear}
          onSearch={handleSearch}
          valueAttribute="value"
          labelAttribute="label"
          showClear
          shouldDebounce
          hide={!props.searchable}
          emptyText="No Custom Field Found"
        />
      ) : (
        <CustomFieldFilter
          shouldIncludeUnassigned
          customField={props.customField}
          value={props.value as string[]}
          onSubmit={handleSubmit}
          onClear={onClear}
          showClear
        />
      )}
    </Fragment>
  );
}

export default CustomFieldFilterBlock;
