import { Form, Radio } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { useState } from 'react';
import { FileType } from 'types/common/attachments';
import { CustomerStatement, StatementTypes } from 'types/customerstatement/customer-statement';
import OutstandingStatements from './OutstandingTransactions';

// const t = {
//   all_transactions: 'All Transactions',
//   outstanding_transactions: 'Outstanding Transactions',
// };

export enum FormName {
  ATTACHMENT_TYPE = 'ATTACHMENT_TYPE',
  PERIOD = 'PERIOD',
  CUSTOM_DATE = 'CUSTOM_DATE',
  CUSTOM_RANGE = 'CUSTOM_RANGE',
  FILE_FORMAT = 'FILE_FORMAT',
}
type RadioGroupProps = React.ComponentProps<typeof Radio.Group>;
// type RadioChangeEvent = Parameters<NonUndefined<NonNullable<RadioGroupProps>['onChange']>>['0'];
// const stateMentOptions: RadioGroupProps['options'] = [
//   {
//     label: t.outstanding_transactions,
//     value: StatementTypes.OUTSTANDING_TRANSACTIONS,
//     style: { borderRadius: 'var(--br-1) 0 0 var(--br-1)' },
//   },
//   {
//     label: t.all_transactions,
//     value: StatementTypes.ALL_TRANSACTIONS,
//     style: { borderRadius: '0 var(--br-1) var(--br-1)  0' },
//   },
// ];
const filleTypeOptions: RadioGroupProps['options'] = [
  {
    label: 'PDF',
    value: FileType.PDF,
  },
  {
    label: 'CSV',
    value: FileType.CSV,
  },
  {
    label: 'XLS',
    value: FileType.XLS,
  },
  {
    label: 'XLSX',
    value: FileType.XLSX,
  },
];
interface CustomerStatementsProps {
  hide?: boolean;
  statement?: CustomerStatement;
}
function CustomerStatements(props: Readonly<CustomerStatementsProps>) {
  const [statementType] = useState<StatementTypes>(initializeStatement);

  function initializeStatement() {
    return props.statement?.attachment_type ?? StatementTypes.OUTSTANDING_TRANSACTIONS;
  }

  if (props.hide) return null;

  // function handleOnChange(event: RadioChangeEvent) {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   setStatementType(event.target.value);
  // }
  // const StatementOption = (
  //   <StyledFormItem name="attachment_type" initialValue={statementType} shouldUpdate>
  //     <Radio.Group
  //       optionType="button"
  //       options={stateMentOptions}
  //       onChange={handleOnChange}
  //       size="middle"
  //     />
  //   </StyledFormItem>
  // );

  const FileFormat = (
    <Form layout="vertical" name={FormName.FILE_FORMAT}>
      <StyledFormItem
        label="File Format"
        initialValue={props.statement?.file_type}
        name="file_type"
        shouldUpdate
      >
        <Radio.Group options={filleTypeOptions} />
      </StyledFormItem>
    </Form>
  );

  return (
    <Flex direction="column" style={{ paddingLeft: '38px' }} gap="--space-16">
      {/* <Form name={FormName.ATTACHMENT_TYPE}>{StatementOption}</Form> */}
      <OutstandingStatements
        hide={statementType === StatementTypes.ALL_TRANSACTIONS}
        statement={props.statement}
      />
      {/* <AllTransactionsStatements
        hide={statementType === StatementTypes.OUTSTANDING_TRANSACTIONS}
        statement={props.statement}
      /> */}
      {FileFormat}
    </Flex>
  );
}

export default CustomerStatements;
