import { stakeHolderListAttributes } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import { ContactType, ParentContactType } from 'types/entities/contact';

function isParentRelation(contactType: ParentContactType | ContactType) {
  return [
    ParentContactType.PRIMARY_CONTACT_PARENT,
    ParentContactType.SECONDARY_CONTACT_PARENT,
  ].includes(contactType as ParentContactType);
}

export function serializeContacts(contacts: stakeHolderListAttributes[]) {
  return contacts.map((contact) => {
    const newContact = { ...contact };

    if (newContact.contact_type) {
      const isParentContactType = isParentRelation(newContact.contact_type);

      if (isParentContactType) {
        newContact.contact_type = newContact.contact_type?.concat('_PARENT') as ContactType;
      }
    }

    return newContact;
  });
}

export function deSerializeContacts(contacts: stakeHolderListAttributes[]) {
  return contacts.map((contact) => {
    const newContact = { ...contact };

    if (newContact.contact_type) {
      const isParentContactType = isParentRelation(newContact.contact_type);

      if (isParentContactType) {
        newContact.contact_type = newContact.contact_type?.replace('_PARENT', '') as ContactType;
      }
    }

    return newContact;
  });
}
