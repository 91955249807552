import { CallLogCard } from 'components/CollectionActivities/CallLog/Card';
import { CallLogDetails } from 'components/CollectionActivities/CallLog/Details/CallLogDetails';
import { LoaderDetails } from 'components/CollectionActivities/Common/Details/LoaderDetails';
import { CreditMemoCard } from 'components/CollectionActivities/CreditMemo/Card';
import { CreditMemoDetails } from 'components/CollectionActivities/CreditMemo/Details/CreditMemoDetails';

import { DisputeCard } from 'components/CollectionActivities/Dispute/Card';
import { DisputeDetails } from 'components/CollectionActivities/Dispute/Details/DisputeDetails';
import { EscalationCard } from 'components/CollectionActivities/Escalation/Card';
import { EscalationDetails } from 'components/CollectionActivities/Escalation/Details/EscaltionDetails';
import { NoteCard } from 'components/CollectionActivities/Note/Card';
import { NoteDetails } from 'components/CollectionActivities/Note/Details/NoteDetails';
import { PaymentCard } from 'components/CollectionActivities/Payment/Card';
import { PaymentDetails } from 'components/CollectionActivities/Payment/Details/PaymentDetails';
import { PromiseToPayCard } from 'components/CollectionActivities/PromiseToPay/Card';
import { PTPDetails } from 'components/CollectionActivities/PromiseToPay/PTPDetails';
import { TaskCard } from 'components/CollectionActivities/Task/Card';
import { WriteOffCard } from 'components/CollectionActivities/WriteOff/Card';
import { WriteoffDetails } from 'components/CollectionActivities/WriteOff/Details/WriteOffDetails';
import { ActivityDetailsBaseProps, ActivityType } from 'types/activities/activity-types';
import {
  ActivitySubcriptionType as ActivitySubscriptionType,
  SubscribedEntityType,
} from 'types/api/inbox/activity';
import { TaskDetails } from './Task/TaskDetails';
import { AdditionalActivityCardProps } from './types';

interface ActivityCardComponents {
  Header: React.ReactNode;
  Body: React.ReactNode;
  Footer: React.ReactNode;
}

const subscriptionTypeInfoMap: Record<ActivitySubscriptionType, string> = {
  [ActivitySubscriptionType.OWNER]: "You're owner of this activity",
  [ActivitySubscriptionType.ASSIGNED]: "You're assigned to this activity",
  [ActivitySubscriptionType.COMMENT_TAGGED]: "You've been tagged in a comment",
  [ActivitySubscriptionType.ESCALATION_TAGGED]: "You've been tagged in a comment",
  [ActivitySubscriptionType.NOTE_TAGGED]: "You've been tagged in a comment",
  [ActivitySubscriptionType.PTP_TAGGED]: "You've been tagged in a comment",
  [ActivitySubscriptionType.COMMENTED]: "You've added a comment",
};
// Gets the Collection Activity Card Component to be Rendered based on the Payload provided
export function getActivityCard(
  assignment?: SubscribedEntityType,
  additionalProps?: AdditionalActivityCardProps
): ActivityCardComponents {
  const commonProps = {
    viewMode: additionalProps?.viewMode,
    activitiesMode: additionalProps?.activitiesMode,
    activityBg: additionalProps?.activityBg,
    onStatusUpdate: additionalProps?.onStatusUpdate,
  };

  switch (assignment?.entity_type) {
    case ActivityType.ESCALATION: {
      const { Header, Body, Footer } = EscalationCard;

      return {
        Header: <Header escalation={assignment} {...commonProps} />,
        Body: <Body escalation={assignment} {...commonProps} />,
        Footer: <Footer escalation={assignment} viewMode={additionalProps?.viewMode} />,
      };
    }

    case ActivityType.DISPUTE: {
      const { Header, Body, Footer } = DisputeCard;

      return {
        Header: <Header dispute={assignment} {...commonProps} />,
        Body: <Body dispute={assignment} {...commonProps} />,
        Footer: <Footer dispute={assignment} viewMode={additionalProps?.viewMode} />,
      };
    }

    case ActivityType.TASK: {
      const { Header, Body, Footer } = TaskCard;

      return {
        Header: <Header task={assignment} {...commonProps} />,
        Body: <Body task={assignment} {...commonProps} />,
        Footer: <Footer task={assignment} viewMode={additionalProps?.viewMode} />,
      };
    }

    case ActivityType.CALL_LOG: {
      const { Header, Body, Footer } = CallLogCard;

      return {
        Header: <Header call_log={assignment} {...commonProps} />,
        Body: <Body call_log={assignment} {...commonProps} />,
        Footer: <Footer call_log={assignment} viewMode={additionalProps?.viewMode} />,
      };
    }

    case ActivityType.NOTE: {
      const { Header, Body, Footer } = NoteCard;

      return {
        Header: <Header note={assignment} {...commonProps} />,
        Body: <Body note={assignment} {...commonProps} />,
        Footer: <Footer note={assignment} viewMode={additionalProps?.viewMode} />,
      };
    }

    case ActivityType.PAYMENT: {
      const { Header, Body, Footer } = PaymentCard;

      return {
        Header: <Header payment={assignment} {...commonProps} />,
        Body: <Body payment={assignment} {...commonProps} />,
        Footer: <Footer payment={assignment} viewMode={additionalProps?.viewMode} />,
      };
    }

    case ActivityType.WRITE_OFF: {
      const { Header, Body, Footer } = WriteOffCard;

      return {
        Header: <Header write_off={assignment} {...commonProps} />,
        Body: <Body write_off={assignment} {...commonProps} />,
        Footer: <Footer write_off={assignment} viewMode={additionalProps?.viewMode} />,
      };
    }

    case ActivityType.PROMISE_TO_PAY: {
      const { Header, Body, Footer } = PromiseToPayCard;

      return {
        Header: <Header promise_to_pay={assignment} {...commonProps} />,
        Body: <Body promise_to_pay={assignment} {...commonProps} />,
        Footer: <Footer promise_to_pay={assignment} viewMode={additionalProps?.viewMode} />,
      };
    }
    case ActivityType.CREDIT_MEMO: {
      const { Header, Body, Footer } = CreditMemoCard;

      return {
        Header: <Header credit_memo={assignment} {...commonProps} />,
        Body: <Body credit_memo={assignment} {...commonProps} />,
        Footer: <Footer credit_memo={assignment} viewMode={additionalProps?.viewMode} />,
      };
    }

    default:
      return { Header: null, Body: null, Footer: null };
  }
}

export function getSubscriptionTypeInfo(subscriptionType?: ActivitySubscriptionType) {
  if (!subscriptionType) return;
  return subscriptionTypeInfoMap[subscriptionType];
}

export function getActivityDetails(props: ActivityDetailsBaseProps) {
  switch (props.type) {
    case ActivityType.ESCALATION: {
      return <EscalationDetails {...props} />;
    }

    case ActivityType.DISPUTE: {
      return <DisputeDetails {...props} />;
    }

    case ActivityType.TASK: {
      return <TaskDetails {...props} />;
    }

    case ActivityType.CALL_LOG: {
      return <CallLogDetails {...props} />;
    }

    case ActivityType.NOTE: {
      return <NoteDetails {...props} />;
    }

    case ActivityType.PAYMENT: {
      return <PaymentDetails {...props} />;
    }

    case ActivityType.WRITE_OFF: {
      return <WriteoffDetails {...props} />;
    }
    case ActivityType.CREDIT_MEMO: {
      return <CreditMemoDetails {...props} />;
    }

    case ActivityType.PROMISE_TO_PAY: {
      return <PTPDetails {...props} />;
    }

    default:
      return <LoaderDetails />;
  }
}
