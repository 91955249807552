import { GrowFormHeader } from '@sinecycle/growcomponents';
import { Texto } from 'components/BaseComponents/Typography/Texto';

interface HeaderProps {
  onClose?: () => void;
  title?: string | React.ReactNode;
  icon?: JSX.Element;
}

const t = {
  add_files: 'Add Files',
};

function DefaultFileUploadHeader(props: Readonly<HeaderProps>) {
  function getTitleComponent(): React.ReactNode {
    if (!props.title) return <Texto weight="semibold">{t.add_files}</Texto>;
    if (typeof props.title === 'string') return <Texto weight="semibold">{props.title}</Texto>;
    return props.title;
  }
  function handleClick() {
    props.onClose?.();
  }
  const Title = getTitleComponent();

  const DrawerHeader = (
    <GrowFormHeader title={Title} closeIcon={props.icon} onClose={handleClick} />
  );
  return <>{DrawerHeader}</>;
}

export default DefaultFileUploadHeader;
