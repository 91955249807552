import { Flex } from 'components/BaseComponents/Layout/Flex';
import { CurrencyConfigProps } from 'components/Settings/Currency/type';
import CurrentPeriodForm from './CurrentPeriodForm';

function CurrentPeriodFormData(props: CurrencyConfigProps) {
  const { tabData, baseCurrency } = props;

  return (
    <div style={{ paddingLeft: 'var(--space-20)' }}>
      <Flex direction="column" gap="var(--space-16)">
        <CurrentPeriodForm exchangeRateData={tabData} baseCurrency={baseCurrency} />
      </Flex>
    </div>
  );
}

export default CurrentPeriodFormData;
