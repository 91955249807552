import * as SentrySdk from '@sentry/react';

function isLogRocketBreadCrumb(breadcrumb: SentrySdk.Breadcrumb) {
  const url = breadcrumb.data?.url ?? ('' as string);
  const LOG_ROCKET_REQ_REGEX = /https?:\/\/(\w+\.)?lr-ingest\.io/;

  return LOG_ROCKET_REQ_REGEX.test(url);
}

// Breadcrumb data structure ref: https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
// Adding it here since it's not captured in SentrySdk's types
export function handleBeforeBreadcrumb(breadcrumb: SentrySdk.Breadcrumb) {
  // do not need to send logrocket xhr requests to breadcrumbs
  if (isLogRocketBreadCrumb(breadcrumb)) {
    return null;
  }

  return breadcrumb;
}
