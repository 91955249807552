import { notify } from 'components/BaseComponents/Notifications/index';
import { KyResponse } from 'ky';
import { useState } from 'react';
import { unprefixedKy as ky } from 'services/ky';

export interface DownloadOptions {
  fileName?: string;
  data?: any;
  searchParams?: any;
}

const t = {
  success_text: 'You will receive the export in your mail shortly',
  error_text: "Reports couldn't be downloaded!",
};

const saveFileInDisk = async (fileResponse: Response, fileName?: string) => {
  return fileResponse.blob().then((bloburl) => {
    const newUrl = URL.createObjectURL(bloburl);

    const a = document.createElement('a');
    a.href = newUrl;
    a.download = fileName ?? '';

    document.body.appendChild(a);
    a.click();
    a.remove();
  });
};

function getFileName(fileResponse: KyResponse) {
  const disposition = fileResponse.headers.get('content-disposition');
  const re = /filename="([\S]*)"/;

  return disposition?.match(re)?.[1];
}

const useDownloadDocument = (successText?: string, errorText?: string) => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  const downloadReports = async (url: string, options: DownloadOptions) => {
    try {
      setFetching(true);
      // some urls are generated dynamically
      await ky.post(`${url}`, { json: options.data });
      notify.success(successText ?? t.success_text);
    } catch (error) {
      setError(true);
      notify.error(errorText ?? t.error_text);
      setFetching(false);
      console.log('error', error);
    } finally {
      setFetching(false);
    }
  };
  const download = async (url: string, options: DownloadOptions) => {
    try {
      setFetching(true);
      // some urls are generated dynamically
      const searchParams = options?.searchParams;

      const fileResponse = await ky.get(`${url}`, { searchParams });
      const fileName = options.fileName ?? getFileName(fileResponse) ?? undefined;

      if (fileResponse) {
        await saveFileInDisk(fileResponse, fileName);
      }
      notify.success(successText ?? t.success_text);
    } catch (error) {
      setError(true);
      setFetching(false);
      console.log('error', error);
      notify.error(errorText ?? t.error_text);
    } finally {
      setFetching(false);
    }
  };

  return {
    fetching,
    error,
    downloadReports,
    download,
  };
};

export { useDownloadDocument };
