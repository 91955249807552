import { Typography } from 'antd';
import styled from 'styled-components';

const { Text, Paragraph } = Typography;

export const BodyText = styled(Paragraph)`
  color: var(--gray-8);
`;

export const DaysSinceText = styled(Text)`
  color: var(--volcano-7);
`;

export const ListItemParagraph = styled(Paragraph)`
  .issue-text {
    font-size: var(--fs-16);
  }
`;
