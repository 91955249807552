import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsMutating } from '@tanstack/react-query';
import { Flex } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { GrowText } from 'components/BaseComponents/Typography';
import useOpen from 'util/hooks/useOpen';
import FormModal from '../FormModal/FormModal';
import { activityText } from '../text';
import { TaskFormModalProps, TaskModalFormElementProps } from '../types';
import { onFormSave } from '../Utils';
import TaskForm from './TaskForm';

export default function TaskFormModal<TaskElements extends TaskModalFormElementProps>(
  props: Omit<TaskFormModalProps<TaskElements>, 'form' | 'onFormClose'>
) {
  const { openModal, id, onClose, editMode } = props;
  const [form] = useForm();
  const isFormSaving = useIsMutating({
    mutationKey: ['activity-forms'],
  });

  const { open, onClose: modalClose } = useOpen({
    open: openModal,
  });

  function formSubmit() {
    onFormSave(form);
  }

  function onCancel() {
    modalClose();
    onClose?.();
  }

  const Header = (
    <Flex gap={'var(--space-8)'} align="center">
      <FontAwesomeIcon icon={['far', 'check']} color="var(--magenta-5)" fontSize={18} />
      <GrowText size="var(--fs-20)" strong>
        {editMode ? activityText.edit_task : activityText.create_task}
      </GrowText>
    </Flex>
  );

  return (
    <FormModal
      open={open}
      onCancel={onCancel}
      formSubmit={formSubmit}
      isLoading={Boolean(isFormSaving)}
      id={Number(id)}
      enableExtraAction={false}
      headerTitle={Header}
    >
      <TaskForm<TaskElements> form={form} onFormClose={onCancel} {...props} />
    </FormModal>
  );
}
