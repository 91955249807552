import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';
import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import React from 'react';
import {
  ActivityAction,
  ActivityType,
  LastActivity,
  LastActivityType,
} from 'types/activities/activity-types';
import {
  ELastContactActivity,
  IActivityItem,
  TypeofActivity,
} from '../../../store/activity-feed/type';
import Email from './Email';
import EmailExpandedView from './Email/expanded-view';
import { emailSchema } from './Email/validation';

const activityPaths = [
  'email_conversations',
  'call-logs',
  'promise-to-pay',
  'notes',
  'disputes',
  'escalations',
  'tasks',
  'write-offs',
  'payments',
  'credit-memos',
] as const;
export type ActivityPaths = (typeof activityPaths)[number];
export interface BaseCardProps {
  activity: IActivityItem<TypeofActivity, TypeofActivity>;
  modifyActivity: ({
    parentType,
    parentId,
    modifiedParentProps,
  }: {
    parentType: string;
    parentId: number;
    modifiedParentProps: object;
  }) => void;
  icon: IconProp;
}
// type BaseCardComponent = (props: BaseCardProps) => JSX.Element;

export const keyActivites = [
  {
    key: ELastContactActivity.EMAIL,
    cardLabel: 'Followup Email',
    label: 'Followup Email',
    icon: ['far', 'envelope'] as IconProp,
    Icon: <FontAwesomeIcon icon={['far', 'envelope']} color="var(--primary-7)" />,
    color: 'var(--primary-7)',
    classNames: '',
    path: 'email_conversations',
    config: {
      title: 'Email',
      Component: Email,
      expandedView: EmailExpandedView,
      props: {
        isEditMode: true,
      },
      schema: emailSchema,
      talkbacks: {
        create: {
          success: { title: 'Email Sent', message: 'Your Email was successfully Sent!' },
          error: {
            title: 'Delivery Unsuccessful',
            message: 'The contents are saved in draft. Please try resending the Email :(',
          },
        },
        bulkAction: {
          create: {
            success: { title: 'Emails Sent', message: 'Emails were succesfully Sent!' },
            error: {
              title: 'Delivery Unsuccessful',
              message: 'The contents are saved in draft. Please try resending the Email :(',
            },
          },
        },
      },
    },
    iconBg: 'var(--geekblue-1)',
  },
  {
    key: ELastContactActivity.CALL_LOG,
    label: 'Call Log',
    cardLabel: 'Call Log',
    expandedLabel: 'Call Log - Details',
    icon: ['far', 'phone'] as IconProp,
    Icon: <FontAwesomeIcon icon={['far', 'phone']} color="var(--blue-5)" fontSize={14} />,
    color: 'var(--blue-5)',
    iconBg: 'var(--blue-1)',
  },
  {
    iconBg: 'var(--lime-1)',
    key: ELastContactActivity.PROMISE_TO_PAY,
    label: 'Promise to Pay',
    cardLabel: 'Promise to Pay',
    expandedLabel: 'Promise to Pay - Details',
    Icon: <FontAwesomeIcon icon={['far', 'handshake']} color="var(--lime-8)" fontSize={14} />,
    icon: ['far', 'handshake'] as IconProp,
    color: 'var(--lime-8)',
    classNames: '',
    path: 'promise-to-pay',
  },
  {
    iconBg: 'var(--blue-1)',
    key: ELastContactActivity.NOTE,
    label: 'Add Note',
    cardLabel: 'Note',
    expandedLabel: 'Note - Details',
    Icon: <FontAwesomeIcon icon={['far', 'clipboard']} color="var(--blue-7)" fontSize={14} />,
    icon: ['far', 'clipboard'] as IconProp,
    color: 'var(--blue-7)',
    classNames: '',
    path: 'notes',
  },
  {
    iconBg: 'var(--volcano-1)',
    key: ELastContactActivity.DISPUTE,
    label: 'Raise a Dispute',
    cardLabel: 'Dispute',
    expandedLabel: 'Dispute - Details',
    Icon: (
      <FontAwesomeIcon
        color="var(--volcano-6)"
        icon={['far', 'comment-times'] as IconProp}
        fontSize={14}
      />
    ),
    icon: ['far', 'comment-times'] as IconProp,
    color: 'var(--volcano-6)',
    classNames: '',
    path: 'disputes',
  },
  {
    iconBg: 'var(--volcano-1)',
    key: ELastContactActivity.ESCALATION,
    label: 'Escalate',
    cardLabel: 'Escalation',
    expandedLabel: 'Escalation - Details',
    Icon: (
      <FontAwesomeIcon
        icon={['far', 'exclamation-circle']}
        color="var(--volcano-8)"
        fontSize={14}
      />
    ),
    icon: ['fal', 'exclamation-circle'] as IconProp,
    color: 'var(--volcano-8)',
    classNames: '',
    path: 'escalations',
  },
  {
    iconBg: 'var(--magenta-1)',
    key: ELastContactActivity.TASK,
    label: 'Create Task',
    cardLabel: 'Task',
    expandedLabel: 'Task - Details',
    icon: ['far', 'check'] as IconProp,
    Icon: <FontAwesomeIcon icon={['far', 'check']} color="var(--magenta-5)" fontSize={14} />,
    color: 'var(--magenta-5)',
    classNames: '',
    path: 'tasks',
  },
  {
    iconBg: 'var(--gray-1)',
    key: ELastContactActivity.WRITE_OFF,
    label: 'Write Off',
    cardLabel: 'Write Off',
    expandedLabel: 'Write Off - Details',
    Icon: <FontAwesomeIcon icon={['far', 'cut']} color="var(--gray-7)" fontSize={14} />,
    icon: ['far', 'cut'] as IconProp,
    color: 'var(--gray-7)',
    classNames: '',
    path: 'write-offs',
  },
  {
    iconBg: 'var(--green-1)',
    key: ELastContactActivity.PAYMENT,
    label: 'Payment',
    cardLabel: 'Payment',
    expandedLabel: 'Payment - Details',
    Icon: <FontAwesomeIcon icon={['far', 'credit-card']} color="var(--green-8)" fontSize={14} />,
    icon: ['far', 'credit-card'] as IconProp,
    color: 'var(--green-8)',
    path: 'payments',
  },
  {
    iconBg: 'var(--green-1)',
    key: ELastContactActivity.CREDIT_MEMO,
    label: 'Credit Memo',
    cardLabel: 'Credit Memo',
    expandedLabel: 'Credit Memo - Details',
    icon: ['far', 'coin'] as IconProp,
    Icon: <FontAwesomeIcon icon={['far', 'coin']} color="var(--green-6)" />,
    color: 'var(--green-6)',
    path: 'credit-memos',
  },
];

type DetailedActivity = {
  key: LastActivity;
  title: string;
  icon: IconProp;
  color: string;
  detail?: string;
  iconBg: string;
};
const detailedActivites: DetailedActivity[] = [
  {
    key: ActivityType.EMAIL,
    title: 'Email Sent',
    icon: ['far', 'envelope'] as IconProp,
    color: 'var(--geekblue-7)',
    iconBg: 'var(--geekblue-1)',
  },
  {
    key: ActivityAction.EMAIL_SENT,
    title: 'Email Delivered',
    detail: 'Email Delivered',
    icon: ['far', 'envelope'] as IconProp,
    color: 'var(--geekblue-7)',
    iconBg: 'var(--geekblue-1)',
  },
  {
    key: ActivityAction.EMAIL_OPENED,
    title: 'Email Opened',
    detail: 'Email Opened',
    icon: ['far', 'envelope'] as IconProp,
    color: 'var(--geekblue-7)',
    iconBg: 'var(--geekblue-1)',
  },
  {
    key: ActivityAction.EMAIL_BOUNCED,
    title: 'Email not delivered',
    detail: 'Email not delivered',
    icon: ['far', 'times-circle'] as IconProp,
    color: 'var(--red-7)',
    iconBg: 'var(--red-1)',
  },
  {
    key: ActivityAction.VIEWED_ATTACHMENT,
    title: 'No Response',
    detail: 'Viewed Attachment',
    icon: ['far', 'envelope'] as IconProp,
    color: 'var(--geekblue-7)',
    iconBg: 'var(--geekblue-1)',
  },
  {
    key: ActivityAction.RESPONDED,
    title: 'Responded',
    icon: ['far', 'envelope'] as IconProp,
    color: 'var(--geekblue-7)',
    iconBg: 'var(--geekblue-1)',
  },
  {
    key: ActivityType.DISPUTE,
    title: 'Dispute',
    icon: ['far', 'comment-times'] as IconProp,
    color: 'var(--volcano-6)',
    iconBg: 'var(--volcano-1)',
  },
  {
    key: ActivityAction.DISPUTE_OPEN,
    title: 'Dispute Open',
    icon: ['far', 'comment-times'] as IconProp,
    color: 'var(--volcano-6)',
    iconBg: 'var(--volcano-1)',
  },
  {
    key: ActivityAction.DISPUTE_WAITING_ON_CUSTOMER,
    title: 'Dispute Waiting on Customer',
    icon: ['far', 'comment-times'] as IconProp,
    color: 'var(--volcano-6)',
    iconBg: 'var(--volcano-1)',
  },
  {
    key: ActivityAction.DISPUTE_CLOSED,
    title: 'Dispute Closed',
    icon: ['far', 'comment-times'] as IconProp,
    color: 'var(--volcano-6)',
    iconBg: 'var(--volcano-1)',
  },
  {
    key: ActivityType.CALL_LOG,
    title: 'Call Logged',
    icon: ['far', 'phone'] as IconProp,
    color: 'var(--blue-5)',
    iconBg: 'var(--blue-1)',
  },
  {
    key: ActivityType.PROMISE_TO_PAY,
    title: 'Promise to Pay',
    icon: ['far', 'handshake'] as IconProp,
    color: 'var(--lime-8)',
    iconBg: 'var(--lime-1)',
  },
  {
    key: ActivityAction.PTP_OPEN,
    title: 'PTP Open',
    icon: ['far', 'handshake'] as IconProp,
    color: 'var(--lime-8)',
    iconBg: 'var(--lime-1)',
  },
  {
    key: ActivityAction.PTP_CLOSED,
    title: 'PTP Closed',
    icon: ['far', 'handshake'] as IconProp,
    color: 'var(--lime-8)',
    iconBg: 'var(--lime-1)',
  },
  {
    key: ActivityType.NOTE,
    title: 'Note Added',
    icon: ['far', 'clipboard'] as IconProp,
    color: 'var(--blue-7)',
    iconBg: 'var(--blue-1)',
  },
  {
    key: ActivityType.ESCALATION,
    title: 'Escalate',
    icon: ['far', 'exclamation-circle'] as IconProp,
    color: 'var(--volcano-8)',
    iconBg: 'var(--volcano-1)',
  },
  {
    key: ActivityAction.ESCALATION_OPEN,
    title: 'Escalation Open',
    icon: ['far', 'exclamation-circle'] as IconProp,
    color: 'var(--volcano-8)',
    iconBg: 'var(--volcano-1)',
  },
  {
    key: ActivityAction.ESCALATION_CLOSED,
    title: 'Escalation Closed',
    icon: ['far', 'exclamation-circle'] as IconProp,
    color: 'var(--volcano-8)',
    iconBg: 'var(--volcano-1)',
  },
  {
    key: ActivityType.TASK,
    title: 'Task Created',
    icon: ['far', 'check'] as IconProp,
    color: 'var(--magenta-5)',
    iconBg: 'var(--magenta-1)',
  },
  {
    key: ActivityType.WRITE_OFF,
    title: 'Write Off',
    icon: ['far', 'cut'] as IconProp,
    color: 'var(--gray-7)',
    iconBg: 'var(--gray-1)',
  },
];

export function getKeyActivityConfig(key?: ELastContactActivity | ActivityType) {
  return keyActivites.find((activity) => activity.key === key);
}

export function getKeyActivityIcon(key: ELastContactActivity) {
  return (
    keyActivites.find((activity) => activity.key === key)?.icon ?? (['fal', 'check'] as IconProp)
  );
}

export function getKeyActivityColor(key: ELastContactActivity) {
  return keyActivites.find((activity) => activity.key === key)?.color ?? 'var(--primary-7)';
}

export const getLastKeyActivity = (
  activityStatus: ELastContactActivity,
  style?: React.CSSProperties,
  hideTitle?: boolean
) => {
  const currentActiviyConfig = keyActivites.find((item) => item.key === activityStatus);
  const { cardLabel, Icon, iconBg, label } = getActivityUnitItems(
    activityStatus as unknown as ActivityType
  );

  return currentActiviyConfig ? (
    <Flex align="center" gap="var(--space-8)">
      <Tooltip title={hideTitle ? cardLabel : null}>
        <div>
          <ActivityIconButton icon={Icon} size="small" css={{ '--activityBg': iconBg }} viewMode />
        </div>
      </Tooltip>
      {hideTitle ? null : <GrowText>{cardLabel ?? label}</GrowText>}
    </Flex>
  ) : (
    <></>
  );
};

export const getLastActivityInformation = (activityStatus: LastActivity | LastActivityType) => {
  const detailActivity = detailedActivites.find((item) => item.key === activityStatus);

  if (detailActivity) {
    const Icon = <FontAwesomeIcon icon={detailActivity.icon} color={detailActivity.color} />;

    return {
      title: detailActivity.title,
      Icon,
      detail: detailActivity.detail,
      iconBg: detailActivity.iconBg,
    };
  }

  return null;
};

const activitiesExcludedListCustomerDetails = [
  ELastContactActivity.WRITE_OFF,
  ELastContactActivity.PAYMENT,
  ELastContactActivity.CREDIT_MEMO,
];

export const quickActivitesCustomerDetails = keyActivites.filter(
  (activity) => !activitiesExcludedListCustomerDetails.includes(activity.key)
);

const activitiesIncludedListInvoiceList = [ELastContactActivity.EMAIL];

export const quickActivitesInvoiceList = keyActivites.filter((activity) =>
  activitiesIncludedListInvoiceList.includes(activity.key)
);
