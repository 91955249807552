import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, MenuProps } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { OverlayMenuStyles, StyledIconButton } from '../style';

interface FilterViewDropdownProps {
  id: string | undefined;
  onClickCallBack: MenuProps['onClick'];
  items: MenuProps['items'];
  open: boolean;
  toggleOpen: () => void;
  handleOpenChange: (open: boolean) => void;
}
function FilterViewDropdown(props: Readonly<FilterViewDropdownProps>) {
  const FilterIcon = (
    <FontAwesomeIcon icon={['far', 'filter']} color="var(--primary-7)" size="sm" />
  );
  const BarsIcon = (
    <FontAwesomeIcon icon={['far', 'bars-staggered']} color="var(--primary-7)" size="sm" />
  );
  const FilterViewActionIcons = (
    <Flex align="center" gap="var(--space-2)">
      {FilterIcon}
      {BarsIcon}
    </Flex>
  );

  return (
    <Dropdown
      trigger={['click']}
      menu={{ items: props.items, selectedKeys: [`${props.id}`], onClick: props.onClickCallBack }}
      overlayStyle={OverlayMenuStyles}
      forceRender={false}
      open={props.open}
    >
      <StyledIconButton icon={FilterViewActionIcons} onClick={props.toggleOpen} />
    </Dropdown>
  );
}

export default FilterViewDropdown;
