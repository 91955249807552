import { RootState } from 'reducers/rootReducer';
import { collectionPeriodFilterPages } from './types';

export const CollectionPeriodSelector = (state: RootState) =>
  state.collectionPeriod.currentCollectionPeriod;

export const CollectionPeriodIdSelector = (state: RootState) =>
  state.collectionPeriod.currentCollectionPeriod?.id;

export const CollectionPeriodModeSelector = (state: RootState) =>
  state.collectionPeriod.currentCollectionPeriod?.plan_type;

export const CollectionPeriodFilterSelector =
  (key: typeof collectionPeriodFilterPages[number]) => (state: RootState) =>
    state.collectionPeriod.collectionPeriodFilters[key];

export const CollectionPlanEnabledSelector = (state: RootState) =>
  state.collectionPeriod.collectionPlanInfo?.enabled;
