import { Descriptions, Divider, Typography } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ActionButtons } from 'components/CollectionActivities/Common/Details/ActionButons/ActionButtons';
import {
  AmountBlock,
  AttachmentsBlock,
  DateBlock,
  InvoicesBlock,
  LabelBlock,
  TextBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { ViewInvoicesButton } from 'components/CollectionActivities/Common/Details/HeaderButtons';
import { ActivityContent, ActivityTitle } from 'components/CollectionActivities/style';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';

import { DateText } from 'components/BaseComponents/Date/DateText';
import { GrowText } from 'components/BaseComponents/Typography';
import { OPEN_TYPE, useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';
import { PromiseToPay } from 'types/activities/promise-to-pay';
import { SubscriptionType } from '../Common/SubscriptionType';
import useActivityBackButton from '../hooks/useActivityBackButton';
import useGetActivityLink from '../hooks/useGetActivityLink';
import { PTPCommentBlock } from './Details/Common/PTPCommentBlock';

interface PromiseToPayDetailsBodyProps extends ActivityDetailsBase {
  data?: PromiseToPay;
  isLoading: boolean;
}
const t = {
  promiseToPayDate: 'PTP Date',
  paymentAmount: 'PTP Amount',
  ptp_type: 'PTP Type',
  balance_amount: 'PTP Balance Amount',
  slippage_count: 'Payment Slippages',
  associated_invoice: 'Associated Invoice',
  expected_paydate: 'Expected Pay Date',
  customer_paydate: 'Customer Pay Date',
  deleteTitle: 'Delete Promise to Pay',
  deleteDescription: 'Are you sure you want to Delete the Promise to Pay entry?',
};

function PTPDetailsBody(props: PromiseToPayDetailsBodyProps) {
  const {
    hideActions = false,
    data,
    isLoading,
    subscriptionType,
    onDelete,
    deleteLoading,
    customer,
    activitiesMode,
  } = props;
  const { cardLabel, Icon } = getActivityUnitItems(ActivityType.PROMISE_TO_PAY);
  const { entitySearchParams } = useEntitySearchParams();
  const ActivityCopyLink = useGetActivityLink(activitiesMode);
  const ActivityBackButton = useActivityBackButton();
  const Title = (
    <ActivityTitle align="center" gap="var(--space-8)">
      <ActivityBackButton />
      <ActivityIconButton
        icon={Icon}
        size="small"
        css={{ '--activityBg': 'var(--lime-1)' }}
        viewMode
        loading={isLoading}
        hideBackground
      />
      <GrowText>{cardLabel}</GrowText>
      <ActivityCopyLink data={data} customer={customer} type={ActivityType.PROMISE_TO_PAY} />
    </ActivityTitle>
  );

  const Header = (
    <Flex justify="space-between">
      {Title}
      <ActionButtons
        hide={hideActions}
        onDelete={onDelete}
        id={data?.id}
        deleteLoading={deleteLoading}
        title={t.deleteTitle}
        description={t.deleteDescription}
        hideEdit
        type={ActivityType.PROMISE_TO_PAY}
      />
    </Flex>
  );

  const InvoiceArea =
    !isLoading && data ? (
      data.invoices ? (
        <InvoicesBlock invoices={data.invoices} isBaseCurrency />
      ) : data.invoice ? (
        <InvoicesBlock invoices={[data.invoice]} isBaseCurrency />
      ) : null
    ) : null;

  const Amount =
    !isLoading && data ? <AmountBlock amount={data.amount} currency={data.currency} /> : null;

  const BalanceAmount =
    !isLoading && data ? (
      <AmountBlock amount={data.balance_amount} currency={data.currency} />
    ) : null;
  const Date = !isLoading && data ? <DateBlock dateString={data.pay_date} dateOnly strong /> : null;

  const SliPage =
    !isLoading && data ? <TextBlock value={String(data.slippage_count)} strong /> : null;

  const PTPAmount =
    !isLoading && data ? (
      <AmountBlock amount={data.amount} currency={data.currency} size={'var(--fs-20)'} />
    ) : null;

  const PromiseToPayDetails =
    !isLoading && data ? (
      <Flex direction="column" gap="var(--space-8)">
        <Typography style={{ fontSize: 'var(--fs-20)', fontWeight: 'var(--fs-semibold)' }}>
          Date: <DateText value={data?.pay_date ?? ''} textProps={{ fs: '20' }} /> Amount:{' '}
          {PTPAmount}
        </Typography>
      </Flex>
    ) : null;

  const Note =
    !isLoading && data ? (
      <Flex direction="column" gap="var(--space-8)">
        <TextBlock value={data.description} />
      </Flex>
    ) : null;
  const Attachments =
    !isLoading && data?.attachments?.length ? (
      <ActivityContent layout="vertical" colon={false}>
        <Descriptions.Item label={<LabelBlock label="Attachments" />}>
          <AttachmentsBlock attachments={data.attachments ?? []} hideTitle />
        </Descriptions.Item>
      </ActivityContent>
    ) : null;

  const Actions = data?.invoice ? (
    <Flex style={{ marginTop: 'var(--space-8)' }} gap="var(--space-4)">
      <ViewInvoicesButton invoices={[data.invoice]} text="Invoice" />
    </Flex>
  ) : null;
  const Sub = !isLoading && data ? <SubscriptionType subscriptionType={subscriptionType} /> : null;

  const Content = (
    <>
      <Flex gap="var(--space-12)" direction="column">
        <Flex direction="column" gap="var(--space-8)">
          {Header}
          {Sub}
        </Flex>
        <Flex gap="var(--space-16)" direction="column">
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item>{PromiseToPayDetails}</Descriptions.Item>
          </ActivityContent>
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item>{Note}</Descriptions.Item>
          </ActivityContent>
          {Attachments}
          {Actions}
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item label={<LabelBlock label="Invoices" />}>
              {InvoiceArea}
            </Descriptions.Item>
          </ActivityContent>
          <Divider style={{ margin: 'var(--space-16) 0', borderColor: 'var(--gray-5)' }} />
        </Flex>
      </Flex>
      <Flex direction="column" gap="var(--space-12)">
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item label={<LabelBlock label="PTP Amount" />}>{Amount}</Descriptions.Item>
          <Descriptions.Item label={<LabelBlock label="PTP Date" />}>{Date}</Descriptions.Item>
        </ActivityContent>
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item label={<LabelBlock label="Payment Slippages" />}>
            {SliPage}
          </Descriptions.Item>
          <Descriptions.Item label={<LabelBlock label={t.balance_amount} />}>
            {BalanceAmount}
          </Descriptions.Item>
        </ActivityContent>
      </Flex>
    </>
  );

  const Footer =
    data && !isLoading && props.customer ? (
      <PTPCommentBlock
        promiseToPay={data}
        customerId={props.customer?.id}
        activityId={data.id}
        isOpen={entitySearchParams[EActivityQueryParams.OPEN] === OPEN_TYPE.COMMENTS}
      />
    ) : null;
  return (
    <>
      {Content}
      <Divider style={{ margin: 'var(--space-24) 0', borderColor: 'var(--gray-5)' }} />
      {Footer}
    </>
  );
}

export { PTPDetailsBody };
