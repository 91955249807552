import { useQuery } from '@tanstack/react-query';
import { getReportAvailableDates } from 'services/ar-aging';

export function useGetReportAvailableDates() {
  return useQuery({
    queryKey: ['get-unavailable-date'],
    retry: false,
    queryFn: () => getReportAvailableDates(),
  });
}
