import { NavigateOptions, useSearchParams } from 'lib/router';
import { useCallback, useMemo, useRef } from 'react';

type PrimitiveSearchValue = string | number | boolean;
type SearchValue = PrimitiveSearchValue | Array<PrimitiveSearchValue>;

function arrayOrString(value: string) {
  const arr = value.split(',');
  return arr.length === 1 ? arr[0] : arr;
}

function stringToValue<T>(value: string): T {
  try {
    const newValue = JSON.parse(value);
    return typeof newValue === 'string' ? arrayOrString(newValue) : newValue;
  } catch {
    return value as unknown as T;
  }
}

function valueToString(value: SearchValue) {
  return value.toString();
}

export function useURLSearchParams(initialState?: Record<string, SearchValue>) {
  const initialStateRef = useRef(initialState);

  const intialSearchParamValues = useMemo(() => {
    if (!initialStateRef.current) return undefined;

    const initialParams: Record<string, string | string[]> = {};
    const keyValPairs = Object.entries(initialStateRef.current);
    keyValPairs.forEach(([k, v]) => {
      if (v) initialParams[k] = valueToString(v);
    });

    return initialParams;
  }, []);

  const [searchParams, _setSearchParams] = useSearchParams(intialSearchParamValues);

  const getSearchParam = useCallback(
    (key: string) => {
      const valueString = searchParams.get(key);

      return valueString ? stringToValue(valueString) : undefined;
    },
    [searchParams]
  );

  const setSearchParams = useCallback(
    (newValue: Record<string, SearchValue>, options?: NavigateOptions) => {
      const keyValPairs = Object.entries(newValue);

      keyValPairs.forEach(([k, v]) => {
        if (v) searchParams.set(k, valueToString(v));
      });

      _setSearchParams(searchParams, options);
    },
    [_setSearchParams, searchParams]
  );

  const removeSearchParams = useCallback(
    (keys: string[], options?: NavigateOptions) => {
      keys.forEach((key) => {
        searchParams.delete(key);
      });
      _setSearchParams(searchParams, { state: null, replace: true });
    },
    [_setSearchParams, searchParams]
  );

  return {
    setSearchParams,
    getSearchParam,
    removeSearchParams,
  };
}

interface Options<T> {
  initial?: T;
}
export function useURLSearchParam<T extends SearchValue>(key: string, options: Options<T> = {}) {
  const { initial } = options;

  const initialValue = initial ? { [key]: valueToString(initial) } : undefined;
  const [searchParams, setSearchParams] = useSearchParams(initialValue);
  const paramValue = searchParams.get(key);

  const value = useMemo(
    () => (paramValue ? stringToValue<T>(paramValue) : undefined),
    [paramValue]
  );

  const setValue = useCallback(
    (newValue: T, options?: NavigateOptions) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, valueToString(newValue));
      setSearchParams(newSearchParams, options);
    },
    [key, searchParams, setSearchParams]
  );

  return [value, setValue] as [typeof value, typeof setValue];
}
