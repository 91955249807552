import { unprefixedKy } from 'services/ky';

export interface FreshdeskTicketProps {
  name: string;
  subject: string;
  email: string;
  status: number;
  priority: number;
  description: string;
  custom_fields: {
    cf_customer: string;
  };
  type: string;
  group_id: number;
}

export async function createFreshDeskTicket(ticket: FreshdeskTicketProps) {
  const DOMAIN = 'newaccount1634013346098';
  const API_KEY = '8so01Cs8Levv1zr5mUrR';

  return unprefixedKy
    .post(`https://${DOMAIN}.freshdesk.com/api/v2/tickets`, {
      headers: {
        Authorization: `Basic ${window.btoa(API_KEY)}:X`,
      },
      json: ticket,
    })
    .json();
}
