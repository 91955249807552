import { createSlice } from '@reduxjs/toolkit';
import {
  PeriodsMonth,
  PeriodsWeek,
} from 'components/Settings/CollectionPlanConfiguration/CPBody/PeriodPlanning/PeriodSelection/config';
import { InvoiceStatus } from 'types/entities/invoice';
import {
  resetCollectionPlan,
  resetConditionValidation,
  updateCollectionPlan,
  updateConditionsValidation,
  updateConditionValidationAndCurrentConditions,
  updateCustomFiledConditionsAndValidation,
  updateInvoiceFilterConditionsValues,
  updatePeriodType,
  updatePlanDay,
  updatePlanMode,
  updatePlanStatus,
} from './reducers';
import { CollectionPlan, PeriodType, PlanMode } from './type';

export const initialState: CollectionPlan = {
  collectionPlan: {
    periodType: PeriodType.MONTH,
    planMode: PlanMode.AUTOMATED,
    planDayMonth: PeriodsMonth[0].id,
    planDayWeek: PeriodsWeek[0].value,
    currentCondition: {
      invoice_status: [InvoiceStatus.PENDING],
    },
  },
  conditionsValidation: {},
};
const CollectionPlanSlice = createSlice({
  name: 'CollectionPlan',
  initialState,
  reducers: {
    updateCollectionPlan,
    updatePeriodType,
    updatePlanStatus,
    updatePlanMode,
    updatePlanDay,
    resetCollectionPlan,
    updateConditionValidationAndCurrentConditions,
    updateCustomFiledConditionsAndValidation,
    updateInvoiceFilterConditionsValues,
    resetConditionValidation,
    updateConditionsValidation,
  },
});
export const collectionPlanningActions = CollectionPlanSlice.actions;

export default CollectionPlanSlice.reducer;
