import { Flex } from 'components/BaseComponents/Layout/Flex';
import { CollectionPeriod } from 'store/customer/types';
import { Widget } from '../Widget';

interface CollectionPeriodProps {
  collectionPeriods?: CollectionPeriod[];
}

const CollectionPeriodPopOver = (props: CollectionPeriodProps) => {
  const PopOverStyle: React.CSSProperties = {
    overflow: 'auto',
    maxHeight: '220px',
    maxWidth: '210px',
  };

  return (
    <Flex style={PopOverStyle} direction="column" gap="var(--space-16)">
      {props.collectionPeriods?.map((value, index) => {
        return (
          <Widget
            key={index}
            name={value.name}
            first_name={value.first_name}
            last_name={value.last_name}
          />
        );
      })}
    </Flex>
  );
};

export { CollectionPeriodPopOver };
