import { Breadcrumb } from 'antd';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { PageNavLink } from 'lib/router';
import { useSelector } from 'react-redux';
import { currentSideNavStateSelector } from 'store/UI/selector';
import styled from 'styled-components';
import { useBreadCrumbs } from '../LayoutContext';

const StyledBreadCrumb = styled(Breadcrumb)`
  li:not(:only-child) {
    a {
      color: var(--primary-7) !important ;
    }
  }
  li:last-child a {
    color: var(--gray-9) !important;
  }
`;
export function Breadcrumbs() {
  const { breadcrumbs } = useBreadCrumbs();
  const lastIndex = breadcrumbs.length - 1;
  const isExpand = useSelector(currentSideNavStateSelector);
  return (
    <StyledBreadCrumb>
      {breadcrumbs.map((breadcrumb, index) => (
        <Breadcrumb.Item key={breadcrumb.url}>
          <PageNavLink aria-disabled={index === lastIndex} to={breadcrumb.url}>
            <TypographyText
              style={{ color: 'inherit', maxWidth: isExpand ? '640px' : '400px' }}
              ellipsis={{ tooltip: breadcrumb.title }}
            >
              {breadcrumb.title}
            </TypographyText>
          </PageNavLink>
        </Breadcrumb.Item>
      ))}
    </StyledBreadCrumb>
  );
}
