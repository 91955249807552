import { FilterViews } from 'components/FilterViews';
import FilterViewsLoader from 'components/FilterViews/Loader/FilterViewsLoader';
import { FilterView } from 'components/FilterViews/type';
import produce from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import {
  openInvoiceCurrentFilterViewSelector,
  openInvoiceSavedFilterViewsSelector,
  updateOpenInvoiceCurrentFilterview,
  updateOpenInvoiceSavedFilterview,
} from 'store/invoice-v2/invoice';
import { InvoiceFilterView } from 'types/filter-view/common';
import { OpenInvoiceFilterParams } from 'types/filter-view/open-invoice';

export function OpenInvoiceFilterViews() {
  /**
   * currentFilterViewLabel is incorrectly used but cleaning up
   * FilterViewListComponent is not in the scope since a new
   * Filter View list is being worked on
   */

  const dispatch = useDispatch();

  /** selectors */
  const currentFilterView = useSelector(openInvoiceCurrentFilterViewSelector);
  const savedFilterViews = useSelector(openInvoiceSavedFilterViewsSelector);

  function deleteFilterViewCallback(filterId: string) {
    const filterViews = savedFilterViews.filter((filterView) => filterView.id !== filterId);
    dispatch(updateOpenInvoiceSavedFilterview(filterViews));
    dispatch(updateOpenInvoiceCurrentFilterview());
  }

  function saveFilterViewCallback(filterViews: InvoiceFilterView<OpenInvoiceFilterParams>) {
    const updatedViews = produce(savedFilterViews, (draft) => {
      const isExists = draft.findIndex((view) => view.id === filterViews.id);
      draft[isExists] = filterViews;
    });
    dispatch(updateOpenInvoiceSavedFilterview(updatedViews));
    dispatch(updateOpenInvoiceCurrentFilterview(filterViews.id));
  }

  function updateCurrentView(filterViews: InvoiceFilterView<OpenInvoiceFilterParams>) {
    dispatch(updateOpenInvoiceCurrentFilterview(filterViews.id));
  }

  function saveAsFilterViewCallBack(filterViews: InvoiceFilterView<OpenInvoiceFilterParams>) {
    const updatedViews = produce(savedFilterViews, (draft) => {
      draft.push(filterViews);
    });
    dispatch(updateOpenInvoiceSavedFilterview(updatedViews));
    dispatch(updateOpenInvoiceCurrentFilterview(filterViews.id));
  }
  /**
   * filterViewList is again an in-efficently coded,
   * Will be re-worked once we have the new filter
   * view list
   */

  const FilterViewList = (
    <FilterViewsLoader
      loading={currentFilterView ? !Object.keys(currentFilterView).length : true}
    >
      <FilterViews
        totalSavedViews={savedFilterViews as unknown as FilterView<OpenInvoiceFilterParams>[]}
        currentView={currentFilterView as unknown as FilterView<OpenInvoiceFilterParams>}
        filterType="invoice_filter"
        entity="open-invoices"
        updatedFilterValue={currentFilterView?.invoice_filter}
        deleteFilterViewCallBack={deleteFilterViewCallback}
        saveAsFilterViewCallBack={saveAsFilterViewCallBack}
        saveFilterViewCallBack={saveFilterViewCallback}
        updateFilterViewCallBack={updateCurrentView}
      />
    </FilterViewsLoader>
  );

  return FilterViewList;
}
