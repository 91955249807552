import { forwardRef, useCallback } from 'react';
import {
  Link,
  matchPath as matchPagePath,
  Navigate,
  NavLink,
  Outlet as PageOutlet,
  To,
  useLocation as usePageLocation,
  useNavigate,
  useParams as usePageParams,
  useSearchParams as usePageSearchParams,
} from 'react-router-dom';
import { AppPageName, pagePath } from 'router/constants/page-info';

// <PageLink appPageName="DASHBOARD" pathParams={{}} searchParams={{}} state={{}} replace={false} />
// <Navigate appPageName="DASHBOARD" pathParams={{}} searchParams={{}} state={{}} replace={false} />
// navigate({ appPageName="DASHBOARD", pathParams={{}}, searchParams={{}}, state={{}}, replace={false} })

export interface NavigateOptions {
  replace?: boolean;
  state?: any;
}

interface NavigateProps {
  to: To;
  replace?: boolean;
  state?: any;
}

export function PageNavigate(props: NavigateProps) {
  return <Navigate {...props} />;
}

interface PageNavigateProps {
  navigationType?: never;
  appPage: AppPageName;
  pathParams?: Record<string, string | number>;
  searchParams?: string | URLSearchParams;
  state?: unknown;
  replace?: boolean;
}

interface PageLinkProps
  extends PageNavigateProps,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  TO_LINK?: To;
}

export function PageLink({
  appPage,
  pathParams,
  searchParams,
  state,
  replace,
  TO_LINK,
  ...htmlProps
}: PageLinkProps) {
  if (TO_LINK) {
    return <Link {...htmlProps} to={TO_LINK} state={state} replace={replace} />;
  }

  const searchParamsString = searchParams ? `?${new URLSearchParams(searchParams).toString()}` : '';
  const toPath = pagePath(appPage, pathParams) + searchParamsString;
  return <Link {...htmlProps} to={toPath} state={state} replace={replace} />;
}

export function DefaultPageLink(props: Omit<Parameters<typeof Link>[0], 'to'>) {
  return <Link {...props} to="/" />;
}

export interface PageNavLinkProps
  extends NavigateProps,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {}

export const PageNavLink = forwardRef(function PageNavLink(
  { to, state, replace, ...htmlProps }: PageNavLinkProps,
  ref
) {
  return <NavLink {...htmlProps} to={to} state={state} replace={replace} />;
});

export function Outlet() {
  return <PageOutlet />;
}

interface OriginalNavigateProps {
  navigationType: 'original';
  TO_PROPS: { to: To; options?: NavigateOptions };
}
function isOriginalNavigateProps(
  props: PageNavigateProps | OriginalNavigateProps
): props is OriginalNavigateProps {
  return props.navigationType === 'original';
}
export function usePageNavigate() {
  const pageNavigate = useNavigate();

  return useCallback(
    (props: PageNavigateProps | OriginalNavigateProps | number) => {
      // for things like: navigate(-1)
      if (typeof props === 'number') {
        pageNavigate(props);
        return;
      }

      // Keeping Usage of Escape Hatch Ugly to discourage overusing this
      if (isOriginalNavigateProps(props)) {
        pageNavigate(props.TO_PROPS.to, props.TO_PROPS.options);
        return;
      }

      const searchParamsString = props.searchParams
        ? `?${new URLSearchParams(props.searchParams).toString()}`
        : '';

      const toPath = pagePath(props.appPage, props.pathParams) + searchParamsString;
      pageNavigate(toPath, { state: props.state, replace: props.replace });
    },
    [pageNavigate]
  );
}

export function useLocation() {
  return usePageLocation();
}

export function useParams<
  ParamsOrKey extends string | Record<string, string | undefined> = string
>() {
  return usePageParams<ParamsOrKey>();
}

export function useSearchParams(...params: Parameters<typeof usePageSearchParams>) {
  return usePageSearchParams(...params);
}

export function matchPath(...props: Parameters<typeof matchPagePath>) {
  return matchPagePath(...props);
}

export type NavigatableProps = number | PageNavigateProps | OriginalNavigateProps;
