import styled from 'styled-components';

export const EmailEditorStyles = styled.div`
  .ProseMirror .is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: var(--gray-6);
    pointer-events: none;
    height: 0;
  }

  ul li {
    list-style: initial;
  }

  p {
    margin-bottom: initial;
  }

  code {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: var(--fs-16);
    padding: var(--space-2);
  }

  font-size: var(--fs-14);

  .ProseMirror:focus {
    outline: none;
  }

  /* To contain the table inside the preview slider
  Table exceeding the width will have scroll */
  .grow-preview-table {
    width: 450px !important;
    overflow: auto;
  }

  /* table Style */

  .ProseMirror {
    blockquote {
      margin: 0px 0px 0px 0.8ex;
      border-left: 1px solid rgb(204, 204, 204);
      padding-left: 1ex;
    }
  }

  .tiptap {
    a {
      cursor: pointer;
    }
    img {
      object-fit: contain;
    }
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  .ProseMirror-hideselection .grow-email-placeholder {
    &::selection {
      background: var(--geekblue-1);
      color: var(--gray-7);
    }
  }
`;

export const RichContentEditorWrapper = styled(EmailEditorStyles)`
  padding: var(--space-8);
  overflow: auto;
  height: 30vh;

  .selectedCell {
    background: var(--primary-4);
  }

  ul li {
    list-style: initial;
  }

  code {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: var(--fs-16);
    padding: var(--space-2);
  }

  font-size: var(--fs-14);

  .ProseMirror {
    min-height: 180px;
  }
`;
