import { CancelButton, SaveButton } from '@sinecycle/growcomponents';
import { Form, Input, Radio, Space } from 'antd';
import { SelectWrapper } from 'components/BaseComponents/ASelect';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { Editor } from 'lib/RichText/type';
import React from 'react';
import { PopoverButton } from '../../common/PopoverWrapper';

type PopOver = React.ComponentProps<typeof PopoverButton>;

interface FormValues {
  text: string;
  link: string;
}

export interface LinkSelection {
  text: string;
  from: number;
  to: number;
  href?: string;
}
interface InsertLinkProps {
  hide?: boolean;
  editor: Editor;
  onCancel: (value: false) => void;
  open: boolean;
  selection?: LinkSelection;
}

const t = {
  heading: ' Hyperlink Text',
  label: {
    display_text: 'Display Text',
    linkURL: 'Link URL',
    linkPlaceholder: 'Placeholder',
  },
  name: {
    displayText: 'text',
    urlLink: 'urlLink',
    placeholderLink: 'buttonURL',
    urlGroup: 'urlSelection',
  },
  placeholder: {
    text: 'Enter the Hyperlink Text...',
    link: 'Enter the Hyperlink URL...',
  },
  message: {
    empty: 'Required Field',
    validation: 'Enter a Valid URL',
  },
};

const popOverInnerStyles: React.CSSProperties = {
  padding: 'var(--space-16)',
  width: '320px',
  boxShadow: 'var(--shadow-3)',
  borderRadius: 'var(--br-1)',
};

const popOverAlign: PopOver['align'] = {
  targetOffset: [0, -10],
};
function InsertLink(props: Readonly<InsertLinkProps>) {
  const [form] = Form.useForm();
  const { selection } = props;
  const urlGroup = Form.useWatch(t.name.urlGroup, form) ?? 'url';
  const placeholderURL = Form.useWatch(t.name.placeholderLink, form);
  const absoluteURL = Form.useWatch(t.name.urlLink, form);
  const text = Form.useWatch(t.name.displayText, form);
  const URL = absoluteURL ?? placeholderURL;

  const selectedText = selection?.text;
  const selectedLink = selection?.href;

  const pattern = /^https?:\/\//;
  const isSelectedTextUrl = selectedText && pattern.test(selectedText);

  if (!props.open) return null;

  const Heading = (
    <Texto color="--gray-9" weight="semibold">
      {t.heading}
    </Texto>
  );

  const LinkTextLabel = (
    <Texto size="12" color="var(--gray-9)">
      {t.label.display_text}
    </Texto>
  );

  const LinkText = (
    <Form.Item
      name={t.name.displayText}
      label={LinkTextLabel}
      initialValue={isSelectedTextUrl ? undefined : selectedText}
      style={{ margin: 0 }}
    >
      <Input width="100%" placeholder={t.placeholder.text} />
    </Form.Item>
  );

  const placeHolderOptions = [
    {
      value: '{{customer.payment_url}}',
      label: 'Payment Link',
    },
  ];

  const LinkURL = (
    <Radio.Group name="radiogroup" defaultValue={'url'}>
      <Space direction="vertical">
        <Radio value={'url'}>
          <Texto weight={urlGroup === 'url' ? 'semibold' : undefined}>{t.label.linkURL}</Texto>
          {urlGroup === 'url' && (
            <Form.Item
              initialValue={selectedLink}
              name={t.name.urlLink}
              noStyle
              rules={[
                { required: true, message: 'Please input a valid URL!' },
                {
                  type: 'url',
                  message: 'Please input a valid URL! ',
                },
              ]}
            >
              <Input placeholder="Provide a valid link ..." />
            </Form.Item>
          )}
        </Radio>
        <Radio value={'placeholder'}>
          <Texto weight={urlGroup === 'placeholder' ? 'semibold' : undefined}>
            {t.label.linkPlaceholder}
          </Texto>
          {urlGroup === 'placeholder' && (
            <Form.Item noStyle name={t.name.placeholderLink}>
              <SelectWrapper
                options={placeHolderOptions}
                style={{ width: '240px' }}
                placeholder="Select a URL placeholder..."
              />
            </Form.Item>
          )}
        </Radio>
      </Space>
    </Radio.Group>
  );

  const Title = (
    <Form name="addLink" layout="vertical" form={form} preserve={false}>
      <Flex direction="column" gap="--space-12">
        {LinkText}
        <Form.Item name={t.name.urlGroup}>{LinkURL}</Form.Item>
      </Flex>
    </Form>
  );

  function handleInsertLinkWithText(text: string, link: string) {
    props.editor
      .chain()
      .insertContent({
        type: 'text',
        marks: [
          {
            type: 'link',
            attrs: {
              href: link,
              class: null,
              target: '_blank',
            },
          },
        ],
        text,
      })
      .selectNodeForward()
      .run();

    // props.editor.commands.unsetLink();
  }

  function handleReplaceText(text: string, link: string) {
    if (selection) {
      const { from, to } = selection;

      props.editor
        .chain()
        .insertContentAt({ from, to }, `<a href=${link} target='_blank'>${text}</a>`)
        .run();
    }
  }

  function handleInsertLinkToHighlightText(text: string, link: string) {
    if (selection) {
      const { from, to } = selection;
      props.editor
        .chain()
        .focus()
        .setTextSelection({ from, to })
        .extendMarkRange('link')
        .setLink({ href: link })
        .run();
    }
  }

  function handleSettled() {
    props.editor.commands.blur();
    props.onCancel(false);
    form.resetFields();
  }

  function handleSuccessResolve(values: FormValues) {
    if (!selectedText || isSelectedTextUrl) {
      handleInsertLinkWithText(text, URL);
    } else if (selectedText !== text) {
      handleReplaceText(text, URL);
    } else {
      handleInsertLinkToHighlightText(text, URL);
    }
  }
  function handleClick() {
    form
      .validateFields()
      .then(handleSuccessResolve)
      .then(handleSettled)
      .catch(() => {});
  }

  function handleCancel() {
    props.editor.commands.blur();
    props.onCancel(false);
    form.resetFields();
  }

  const Save = (
    <SaveButton
      onClick={handleClick}
      disabled={
        Boolean(form.getFieldsError().filter(({ errors }) => errors.length).length) || !URL || !text
      }
    />
  );
  const Cancel = <CancelButton onClick={handleCancel} />;

  const ActionButtons = (
    <Flex gap="--space-12" align="center" justify="flex-end">
      {Cancel}
      {Save}
    </Flex>
  );

  const PopUp = (
    <Flex direction="column" gap="--space-12">
      {Heading}
      {Title}
      {ActionButtons}
    </Flex>
  );

  return (
    <PopoverButton
      destroyTooltipOnHide
      content={PopUp}
      trigger={undefined}
      placement="top"
      open={props.open}
      key={selectedText}
      overlayClassName="ant-popover-body-no-padding-rich"
      overlayInnerStyle={popOverInnerStyles}
      align={popOverAlign}
      style={{ borderRadius: 'var(--br-1)' }}
    />
  );
}

export default InsertLink;
