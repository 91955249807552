import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import styled from 'styled-components';

const StyledSidebarContainer = styled.div`
  background: var(--gray-2);
  border-right: 1px solid var(--gray-4);

  &.view {
    .ant-menu {
      height: calc(85vh - var(--placeholders-search-height));
    }
  }

  .ant-menu {
    overflow: auto;
    background: var(--gray-2);
    max-width: 250px;
    height: calc(100% - var(--placeholders-search-height));
    padding-bottom: var(--space-4);
    padding-top: var(--space-8);
    &::-webkit-scrollbar {
      &-thumb {
        background: var(--gray-4);
        border-right: 2px solid var(--gray-1);
      }
    }
    .ant-menu-item-group {
      padding: var(--space-0) var(--space-16) var(--space-8) var(--space-16);
      background: var(--gray-2);
      overflow: auto !important;

      .ant-menu-item {
        padding: var(--space-8) !important;
        border-radius: var(--br-1);
        margin: var(--space-0) !important;
        height: var(--space-32);
        svg {
          color: var(--purple-7);
          opacity: 0;
        }
        &:hover {
          color: var(--purple-7);
        }
      }
      .ant-menu-item-group-title {
        width: 100%;
        text-transform: capitalize;
        display: block;
        padding-left: var(--space-0);
        height: auto;
        &:first-child {
          padding-top: var(--space-0);
        }
      }
      .ant-menu-title-content {
        color: var(--gray-9);
      }
      .ant-menu-item-selected {
        .ant-menu-title-content,
        .ant-typography {
          color: var(--purple-7) !important;
        }
        color: var(--purple-7);
        svg {
          opacity: 1;
        }
        &::after {
          display: none;
        }
      }
      .ant-menu-item-selected {
        color: var(--purple-7);
        svg {
          opacity: 1;
        }
        &::after {
          display: none;
        }
      }
    }
  }
`;

const StyledEmptyPlaceHolderSections = styled(Flex)`
  height: 70%;
  max-width: 250px;
  padding: var(--space-12) var(--space-16);
  .ant-empty-description {
    color: var(--gray-7);
  }
`;

const StyledSearchResultCount = styled(GrowText)`
  font-size: var(--fs-12);
  margin: var(--space-0) var(--space-16) var(--space-0) var(--space-16);
  padding-bottom: var(--space-8);
  color: var(--gray-8);
  border-bottom: 1px solid var(--gray-4);
  margin-top: -4px;
  display: block;
`;

export { StyledEmptyPlaceHolderSections, StyledSearchResultCount, StyledSidebarContainer };
