import { ListSkeleton } from 'components/BaseComponents/Skeletons/ListSkeleton';
import { StyledTileCard } from 'components/Dashboard/styles';

function DashboardLoader() {
  return (
    <StyledTileCard>
      <ListSkeleton active itemsCount={1} />
    </StyledTileCard>
  );
}

export default DashboardLoader;
