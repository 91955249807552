import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowParagraph, GrowText } from 'components/BaseComponents/Typography';
import { StyledMeta } from 'components/CollectionActivities/Comment';
import { DateBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { Amount } from 'components/Common/MultiCurrency';
import { UserAvatar } from 'components/HigherOrderComponent/UserAvatar';
import { Slippage } from 'types/activities/promise-to-pay';
import { getFormattedDate } from 'util/datetime-formatter';

const t = {
  tp_date_changed: 'PTP Date Changed',
  slipped_amount: 'Slipped Amount',
  payment_slip: 'Payment Slip',
};
export default function SlippageBlock({
  slippage,
  currency,
}: {
  slippage: Slippage;
  currency: string;
}) {
  const slipBadge = (
    <Tooltip title={`${t.payment_slip} #${slippage.serial_no}`}>
      <FontAwesomeIcon icon={['far', 'pen-swirl']} fontSize={14} color="var(--red-5)" />
    </Tooltip>
  );

  const name = `${slippage.created_by.first_name}${slippage.created_by.last_name ?? ''}`;
  const Date = <DateBlock dateString={slippage.created_date} color="var(--gray-7)" />;
  const Title = (
    <Flex justify="space-between">
      <Flex align="center" gap="var(--space-4)">
        <GrowText strong ellipsis={{ tooltip: { title: name } }}>
          {name}
        </GrowText>
        {slipBadge}
      </Flex>
      {Date}
    </Flex>
  );

  const Avatar = (
    <UserAvatar name={[slippage.created_by.first_name, slippage.created_by.last_name ?? '']} />
  );

  const body = (
    <Flex direction="column" gap="var(--space-8)">
      <Flex
        direction="column"
        style={{
          background: 'var(--gold-1)',
          padding: 'var(--space-8)',
        }}
        gap="var(--space-4)"
      >
        <div>
          <GrowText color="var(--gray-7)">{t.tp_date_changed}:</GrowText>
          <GrowText>
            &nbsp; From {getFormattedDate(slippage.from_date)} to{' '}
            {getFormattedDate(slippage.to_date)}
          </GrowText>
        </div>
        <div>
          <GrowText color="var(--gray-7)">{t.slipped_amount}:</GrowText>
          &nbsp;
          <GrowText>
            <Amount amount={slippage.amount} currency={currency} />
          </GrowText>
        </div>
      </Flex>
      <div>
        {slippage.comments && (
          <GrowParagraph style={{ whiteSpace: 'pre-wrap', margin: 0 }} ellipsis={{ rows: 4 }}>
            {slippage.comments}
          </GrowParagraph>
        )}
      </div>
    </Flex>
  );
  return <StyledMeta avatar={Avatar} title={Title} description={body} />;
}
