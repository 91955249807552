export enum FilterKeys {
  /** INVOICE START */
  INVOICE_STATUS = 'invoice_status',
  DUE_DATE = 'due_date',
  TOTAL_DUE_AMOUNT = 'total_due_amount',
  FOLLOWUP_STATUS = 'followup_status',
  ISSUED_DATE = 'issued_date',
  LAST_ACTIVITY = 'last_activity',
  NEXT_FOLLOWUP = 'next_followup',
  PROMISE_TO_PAY = 'promise_to_pay',
  ACCOUNT_EXECUTIVE = 'account_executive',
  ACCOUNT_MANAGER_IDS = 'account_manager_ids',
  CS_MANAGER_IDS = 'cs_manager_ids',
  COLLECTION_OWNER = 'collection_owner',
  COLLECTION_STATUS = 'collection_status',
  CUSTOM_FIELD = 'custom_field',
  DISPUTE_TYPE = 'dispute_type',
  COLLECTION_STRATEGIES = 'collection_strategy_ids',
  COLLECTION_STRATEGY_STATUSES = 'invoice_followup_statuses',
  IS_COLLECTION_STRATEGY_NOT_ASSIGNED = 'is_collection_strategy_not_assigned',
  COLLECTION_ACTIVITY = 'collection_activity',
  COLLECTION_PERIODS = 'collection_period_ids',
  COLLECTION_MANAGER_IDS = 'collection_manager_ids',
  SALES_MANAGER_IDS = 'sales_manager_ids',
  BILLING_CONTACT = 'billing_contact',
  EMAIL_DELIVERY_STATUS = 'email_delivery_status',
  CUSTOMER_DROPDOWN = 'CUSTOMER_DROPDOWN',
  /** INVOICE END */

  /**CUSTOMER START*/
  TAGS = 'TAGS',
  BUSINESS_UNIT = 'business_unit',
  REGION = 'region',
  SUBSIDIARY = 'SUBSIDIARY',
  /**CUSTOMER START*/
}
