import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Col, Row, Skeleton } from 'antd';
import { EmailEditorStyles } from 'lib/RichText';

import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { FollowupRuleAutoEmailActionItem, FollowupRuleTask } from 'store/collection-followup/types';
import { CustomerContactType } from 'store/contacts/contacts';
import { EmailAddressType, FromAddress, ToAddress } from 'store/email/types';
import { TaskItem } from 'store/task/task';
import styled from 'styled-components';
import { EmailPreviewContent } from './style';
import { EmailPreviewProps } from './types';

const t = {
  Subject: 'Subject',
  Body: 'Body',
  To: 'To',
  From: 'From',
};

const StyledEmailToPreview = styled.div`
  &.preview-email {
    &:not(:last-child):after {
      content: ', ';
    }
  }
`;

function EmailPreview(props: EmailPreviewProps) {
  function resolveAddress(address: FromAddress | ToAddress) {
    switch (address.key) {
      case EmailAddressType.IC:
        const ic = props.internalContacts.find(
          (internalContact) => internalContact.id === address.value
        );
        return ic?.title;
      case EmailAddressType.USER:
        const user = props.users.find((user) => user.id === address.value);
        return user?.first_name + (user?.last_name ?? '');
      case EmailAddressType.CONTACT_TYPE:
        const contactType =
          CustomerContactType[
            (address as ToAddress).value as unknown as keyof typeof CustomerContactType
          ];
        return contactType;
    }
  }

  const addresses: any = {};

  if (
    props.action &&
    FollowupRuleTask[props.action?.actionable_type as unknown as keyof typeof FollowupRuleTask] ===
      FollowupRuleTask.AUTO_EMAIL
  ) {
    const action = props.action as FollowupRuleAutoEmailActionItem;
    if (action.from) addresses.from = { name: 'From', value: resolveAddress(action.from) };
    if ((action.to ?? []).length > 0)
      addresses.to = {
        name: 'To',
        value: (action.to ?? []).map((toAddress) => resolveAddress(toAddress)),
      };
    if ((action.cc ?? []).length > 0)
      addresses.cc = {
        name: 'CC',
        value: (action.cc ?? []).map((ccAddress) => resolveAddress(ccAddress)),
      };
    if ((action.bcc ?? []).length > 0)
      addresses.bcc = {
        name: 'BCC',
        value: (action.bcc ?? []).map((bccAddress) => resolveAddress(bccAddress)),
      };
    if (action.reply_to)
      addresses.replyTo = { name: 'Reply To', value: resolveAddress(action.reply_to) };
  } else if (props.action) {
    const action = props.action as TaskItem;
    if (action.assign_to)
      addresses.assignTo = { name: 'Assign To', value: resolveAddress(action.assign_to) };
  }

  const attach_invoices: any = {};

  if (props.attachments) {
    attach_invoices.attach_all_invoices = props.attachments.attach_all_invoices;
  }

  function generateAttachmentMock(invoiceName: string) {
    return (
      <Row style={{ marginBottom: 'var(--space-8)' }}>
        <Col>
          <Alert
            message={
              <>
                <FontAwesomeIcon
                  icon={['far', 'file-pdf']}
                  style={{ marginRight: 'var(--space-8)' }}
                  color="var(--red-5)"
                />
                <span style={{ color: 'var(--blue-6' }}>{invoiceName}</span>
              </>
            }
          />
        </Col>
      </Row>
    );
  }

  function generateAttachmentMocks(invoiceName: string, count?: number) {
    if (!count || isNaN(count) || count === 1) return generateAttachmentMock(invoiceName);

    const attachments: JSX.Element[] = [];
    for (let i = 0; i < count; i++) {
      attachments.push(generateAttachmentMock(`Invoice ${i + 1}.pdf`));
    }
    return attachments;
  }

  function showAttachmentMock() {
    return props.attachments?.attach_invoice_statement
      ? generateAttachmentMocks('All Open Invoices.pdf')
      : props.attachments?.attach_current_invoice
      ? generateAttachmentMocks('Current Invoice.pdf')
      : props.attachments?.attach_all_invoices
      ? generateAttachmentMocks('Attachments.pdf', 3)
      : null;
  }

  const SubjectLoader = <Skeleton.Input active size="small" />;

  const BodyLoader = <Skeleton active />;

  return (
    <EmailPreviewContent>
      {Object.keys(addresses).map((address, index) => {
        return (
          <Row style={{ marginBottom: 'var(--space-24)' }} key={index}>
            <Col style={{ width: '100%' }}>
              <GrowText strong>{addresses[address].name}</GrowText>
            </Col>
            <Col>
              {Array.isArray(addresses[address].value)
                ? addresses[address].value.join(', ')
                : addresses[address].value}
            </Col>
          </Row>
        );
      })}
      {props.emailPreview?.to && (
        <Row style={{ marginBottom: 'var(--space-24)' }}>
          <Col style={{ width: '100%', marginBottom: 'var(--space-8)' }}>
            <GrowText strong>{t.To}</GrowText>
          </Col>
          <GrowText className="email-preview-to">
            <Flex gap="var(--space-4)" wrap="wrap">
              {props.emailPreview?.to.map((previewItem) => (
                <StyledEmailToPreview
                  key={previewItem.email}
                  className={
                    props.emailPreview?.to && props.emailPreview.to.length > 1
                      ? 'preview-email'
                      : ''
                  }
                >
                  {previewItem.label ?? ''}
                </StyledEmailToPreview>
              ))}
            </Flex>
          </GrowText>
        </Row>
      )}
      {props.emailPreview?.from && (
        <Row style={{ marginBottom: 'var(--space-24)' }}>
          <Col style={{ width: '100%', marginBottom: 'var(--space-8)' }}>
            <GrowText strong>{t.From}</GrowText>
          </Col>
          <GrowText className="email-preview-from">
            <span>{props.emailPreview?.from.email}</span>
          </GrowText>
        </Row>
      )}
      <Row style={{ marginBottom: 'var(--space-24)' }}>
        <Col style={{ width: '100%' }}>
          <GrowText strong>{t.Subject}</GrowText>
        </Col>
        <Col>
          <div className="email-preview-subject" style={{ width: '100%', overflow: 'auto' }}>
            {props?.isLoading ? (
              SubjectLoader
            ) : (
              <EmailEditorStyles
                dangerouslySetInnerHTML={{ __html: props.emailPreview?.subject ?? '' }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: 'var(--space-24)' }}>
        <Col style={{ width: '100%', marginBottom: 'var(--space-8)' }}>
          <GrowText strong>{t.Body}</GrowText>
        </Col>
        <div className="email-preview-body" style={{ width: '100%', overflow: 'auto' }}>
          {props?.isLoading ? (
            BodyLoader
          ) : (
            <EmailEditorStyles
              dangerouslySetInnerHTML={{ __html: props.emailPreview?.body ?? '' }}
            />
          )}
        </div>
      </Row>
      {props.attachments && showAttachmentMock()}
    </EmailPreviewContent>
  );
}

export default EmailPreview;
