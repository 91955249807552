const avatarColors = [
  'var(--cyan-8)',
  'var(--yellow-8)',
  'var(--blue-7)',
  'var(--orange-8)',
  'var(--green-8)',
  'var(--magenta-7)',
  'var(--geekblue-7)',
  'var(--volcano-8)',
  'var(--lime-8)',
  'var(--gold-8)',
];

export function getAvatarColor(colorIndex: number) {
  return avatarColors[Number(String(colorIndex).charAt(String(colorIndex).length - 1))];
}
