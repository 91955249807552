import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { EActions } from './types';

type KeyActions = {
  key: EActions;
  label: string;
  icon?: IconProp;
};
const keyActions: KeyActions[] = [
  {
    key: EActions.FLAG_INVOICE,
    label: 'Flag Invoice',
    icon: ['far', 'flag'],
  },
  {
    key: EActions.UNFLAG_INVOICE,
    label: 'Unflag Invoice',
    icon: ['fal', 'times-circle'],
  },
  {
    key: EActions.PAUSE_STRATEGY,
    label: 'Pause Strategy',
    icon: ['fal', 'pause-circle'],
  },
  {
    key: EActions.RESUME_STRATEGY,
    label: 'Resume Strategy',
    icon: ['fal', 'play-circle'],
  },
];

const flagInvoiceActions: EActions[] = [EActions.FLAG_INVOICE, EActions.UNFLAG_INVOICE];
const setStrategyStatusActions: EActions[] = [EActions.PAUSE_STRATEGY, EActions.RESUME_STRATEGY];

export const keyActionsForInvoices = keyActions.filter((action) =>
  flagInvoiceActions.includes(action.key)
);
export const keyActionForSettingStrategy = keyActions.filter((action) =>
  setStrategyStatusActions.includes(action.key)
);
