import { ActivityEventsPayload } from 'components/CollectionActivities/types';
import { useState } from 'react';
import { useEventListener } from 'util/hooks/useCustomEventEmitter';
import { ActivitiesPageLoaded } from '../ActivitiesModal/type';

export default function useGetActivityLoading(previousId?: number, nextId?: number) {
  const [nextPageLoading, setNextPageLoading] = useState<boolean>();
  const [previousPageLoading, setPreviousPageLoading] = useState<boolean>();
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>();

  useEventListener<ActivityEventsPayload>('activitiesLoading', (data) => {
    if (data.id === nextId || data?.nextPageLoading) {
      setNextPageLoading(true);
    }
    if (data.id === previousId) {
      setPreviousPageLoading(true);
    }

    if (!data.loading) {
      setPreviousPageLoading(false);
      setNextPageLoading(false);
    }
  });

  useEventListener<ActivitiesPageLoaded>('isPageLoaded', (data) => {
    setIsPageLoaded(data.pageLoaded);
  });

  return { nextPageLoading, previousPageLoading, isPageLoaded };
}
