import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { formattingOptions } from 'lib/RichText';
import { startCase } from 'lodash';
import { RTEActionButton } from '../../../type';
import { commonButtonProps } from '../../Toolbar';
import { ToolbarOptionProps } from '../../type';
interface TextStylesProps extends ToolbarOptionProps {}

export const TextStyleList: RTEActionButton[] = [
  {
    label: <FontAwesomeIcon icon={['far', 'bold']} />,
    key: 'bold',
    onClick: formattingOptions['bold'].onClick,
    isActive: formattingOptions['bold'].isActive,
  },
  {
    label: <FontAwesomeIcon icon={['far', 'italic']} />,
    key: 'italic',
    onClick: formattingOptions['italic'].onClick,
    isActive: formattingOptions['italic'].isActive,
  },
  {
    label: <FontAwesomeIcon icon={['far', 'underline']} />,
    key: 'underline',
    onClick: formattingOptions['underline'].onClick,
    isActive: formattingOptions['underline'].isActive,
  },
  {
    label: <FontAwesomeIcon icon={['far', 'strikethrough']} />,
    key: 'strikethrough',
    onClick: formattingOptions['strikethrough'].onClick,
    isActive: formattingOptions['strikethrough'].isActive,
  },
  // {
  //   label: <FontAwesomeIcon icon={['far','quote-right']} />,
  //   key: 'quote',
  //   onClick: formattingOptions['quote'].onClick,
  //   isActive: formattingOptions['quote'].isActive,
  // },
];

export function TextStyles({ editor }: TextStylesProps) {
  const buttonRenderer = (action: RTEActionButton) => {
    function onClickCallback(event: React.MouseEvent<HTMLElement>) {
      action.onClick(editor);
    }

    const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
      ...commonButtonProps,
      icon: action.label,
      onClick: onClickCallback,
      type: action.isActive(editor) ? 'default' : 'text',
    };

    const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
      title: startCase(action.key),
    };

    return <Button key={action.key} buttonProps={buttonProps} toolTipProps={toolTipProps} />;
  };

  const LineActionsArea = <>{TextStyleList.map(buttonRenderer)}</>;

  return <>{LineActionsArea}</>;
}
