import { EmailTemplateItem } from '../store/email-template/types';
import ky from './ky';

const baseUrl = 'email_templates';

export async function getEmailTemplates() {
  return (await ky.get(`${baseUrl}`).json()) as EmailTemplateItem[];
}

export async function getEmailTemplate(id: number) {
  return (await ky.get(`${baseUrl}/${id}`).json()) as EmailTemplateItem;
}

export async function removeEmailTemplate(id: number) {
  return await ky.delete(`${baseUrl}/${id}`);
}

export async function saveEmailTemplate(emailTemplateItem: EmailTemplateItem) {
  const requestBody: EmailTemplateItem = {
    ...emailTemplateItem,
  };
  return (await ky.post(`${baseUrl}`, { json: requestBody }).json()) as EmailTemplateItem;
}

export async function getEmailTemplateClone(id: number) {
  return (await ky.get(`${baseUrl}/${id}/copy`).json()) as EmailTemplateItem;
}
