import { COMMON_EVENT } from 'events/common';
import { dispatchAppEvent } from 'lib/pub-sub';
import { SelectOption } from './types';

interface Params {
  options?: SelectOption[];
  value: any;
  emptyText?: string;
}
export function getSelectedValues({ options, value, emptyText }: Params) {
  const noneString = emptyText ?? 'None';

  if (Array.isArray(options) && Array.isArray(value)) {
    function getStringOfValueLabels() {
      const finalValues = (options ?? [])
        .filter((option) => value.includes(option.value))
        .map((option) => option.label);

      return finalValues.join(', ');
    }

    if (!value.length) {
      return noneString;
    }

    // assumption that values will only be from an Option
    if (options.length === value.length && options.length > 1) {
      return 'All';
    }

    return getStringOfValueLabels();
  } else if (Array.isArray(options) && value) {
    return options.find((option) => option.value === value)?.label ?? '';
  } else if (Array.isArray(value)) {
    return value.join(', ');
  } else if (value) {
    return value as string;
  } else {
    return noneString;
  }
}

// DISPATCH APP EVENT FUNCTIONS
export function notifyFilterApply(
  title: string = 'unknown filter',
  isCustomFilter: boolean = false
) {
  dispatchAppEvent({
    type: COMMON_EVENT.FILTER_APPLY,
    payload: {
      filterName: title,
      customFilter: isCustomFilter,
      status: 'open', // what is open | close for ? -> ask yaddy
    },
  });
}

export function notifyFilterCancel(
  title: string = 'unknown filter',
  isCustomFilter: boolean = false
) {
  dispatchAppEvent({
    type: COMMON_EVENT.FILTER_CANCEL,
    payload: {
      filterName: title,
      customFilter: isCustomFilter,
    },
  });
}

export function notifyFilterFocus(title: string = 'unknown filter', visible: boolean = false) {
  dispatchAppEvent({
    type: COMMON_EVENT.FILTER_FOCUS,
    payload: {
      filterName: title,
      status: visible ? 'open' : 'close',
    },
  });
}

export function notifyFilterRemove(
  title: string = 'unknown filter',
  isCustomFilter: boolean = false
) {
  dispatchAppEvent({
    type: COMMON_EVENT.REMOVE_FILTER,
    payload: {
      filterName: title,
      customFilter: isCustomFilter,
    },
  });
}

export function notifyCustomFilterSelect(selectedFilter: string) {
  dispatchAppEvent({
    type: COMMON_EVENT.CUSTOM_FILTER_SELECTED,
    payload: {
      selectedOption: selectedFilter,
    },
  });
}
