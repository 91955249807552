import { configureStore, Action, createAction } from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import appReducer, { RootState } from './../reducers/rootReducer';

export const resetRootState = createAction('resetRootState');

const rootReducer = (state: any, action: any) => {
  /** reset the state on logout */
  if (action.type === 'resetRootState') {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export type AppThunk<R> = ThunkAction<R, RootState, unknown, Action>;
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, Action>;

export default store;
