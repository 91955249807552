import { CloseButton } from 'components/BaseComponents/Buttons/CloseButton';
import { DeleteButton } from 'components/BaseComponents/Buttons/DeleteButton';
import { ViewInvoicePDFButton } from 'components/Common/ViewInvoiceButton';

export interface MinimalInvoiceDetailList {
  invoice_no: string;
  id: number;
}
function getInvoicesListForView(invoices: MinimalInvoiceDetailList[]) {
  return invoices.map((invoice) => ({
    invoiceNumber: invoice.invoice_no,
    invoiceId: invoice.id,
  }));
}

interface CloseButtonProps {
  onClick?: () => void;
}
export function HeaderCloseButton(props: CloseButtonProps) {
  return <CloseButton onClick={props.onClick} />;
}

interface DeleteButtonProps {
  onClick?: () => void;
  onCancel?: () => void;
}
export function HeaderDeleteButton(props: DeleteButtonProps) {
  return <DeleteButton onClick={props.onClick} />;
}

interface ViewInvoicesButtonProps {
  invoices?: MinimalInvoiceDetailList[];
  title?: string;
  text?: string;
}
export function ViewInvoicesButton(props: ViewInvoicesButtonProps) {
  if (!props.invoices) return null;

  const mappedInvoicesList = getInvoicesListForView(props.invoices);

  return (
    <ViewInvoicePDFButton invoices={mappedInvoicesList} title={props.title} text={props.text} />
  );
}
