import { Divider, Typography } from 'antd';
import styled from 'styled-components';

interface StyledDividerProps {
  hideBadDebtandIsDemo: boolean;
}

const { Text, Paragraph } = Typography;

export const SecondaryText = styled(Text)`
  display: block;
  font-size: var(--fs-12);
  color: rgba(0, 0, 0, 0.45);
  line-height: normal;
`;

export const TitleText = styled(Text)`
  color: var(--gray-9);
`;

export const TooltipParagraph = styled(Paragraph)`
  width: 20rem;
  color: var(--gray-8);
`;

export const SuccessText = styled(Text)`
  color: var(--green-7);
`;

export const DangerText = styled(Text)`
  color: var(--red-5);
`;

export const BPDSOText = styled(Text)`
  color: var(--gray-9);
`;

export const StyledDivider = styled(Divider)<StyledDividerProps>`
  height: ${(props) => (props.hideBadDebtandIsDemo ? 'auto' : '90px')};
  color: var(--gray-8);
  margin: ${(props) =>
    props.hideBadDebtandIsDemo
      ? 'var(--space-16) 0 var(--space-20) 0'
      : '0 var(--space-16) 0 var(--space-16)'};
`;
