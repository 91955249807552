import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import styled from 'styled-components';
interface PlaceholderHeaderProps {
  onClose: () => void;
  title: string;
  viewOnly?: boolean;
  isEmpty?: boolean;
}

const StyledAlertComp = styled(Alert)`
  border: 0;
  background: var(--geekblue-1);
  display: flex;
  align-items: center;
  color: var(--gray-9);

  &.ant-alert-info .ant-alert-icon {
    color: var(--geekblue-6);
    margin-bottom: calc(var(--space-16) + var(--space-2));
    margin-right: calc(var(--space-4) + var(--space-4));
  }
`;

const headerWrapperStyle: React.CSSProperties = {
  borderBottom: '1px solid var(--gray-4)',
  borderRadius: 'var(--br-1) var(--br-1) 0 0',
  background: 'var(--gray-1)',
  width: '100%',
};

const closeButtonStyle: React.CSSProperties = {
  fontSize: 'var(--fs-14)',
  border: '1px solid var(--gray-5)',
  borderRadius: 'var(--br-2)',
  padding: 'var(--space-0)',
};

const t = {
  searchPlaceholders: 'search placeholders',
  infoMessage:
    'All placeholders are shown here. During Template creation only applicable placeholders for the chosen Folder will be available.',
};

export default function PlaceholderHeader(props: PlaceholderHeaderProps) {
  const InfoIcon = <FontAwesomeIcon icon={['far', 'info-circle']} />;

  const CloseIcon = <FontAwesomeIcon icon={['fal', 'times']} color="var(--gray-8)" />;

  const CloseButton = (
    <Button size="small" icon={CloseIcon} style={closeButtonStyle} onClick={props.onClose} />
  );

  const AlertComponent = (
    <StyledAlertComp
      message={t.infoMessage}
      className="placeholder-info"
      type="info"
      showIcon
      banner={false}
      icon={InfoIcon}
    />
  );

  const HeaderTitle = (
    <Flex align="center" gap="var(--space-8)">
      <Texto color="var(--default-10)" weight="semibold" size="16">
        {props.title}
      </Texto>
    </Flex>
  );

  return (
    <Flex direction="column">
      <Flex
        align="center"
        justify="space-between"
        style={{
          ...headerWrapperStyle,
          padding: `${props.viewOnly ? 'var(--space-16)' : 'var(--space-12)'}  var(--space-16)`,
          height: props.viewOnly ? '56px' : '50px',
        }}
      >
        {HeaderTitle}
        {CloseButton}
      </Flex>
      {props.viewOnly && AlertComponent}
    </Flex>
  );
}
