import { Flex } from 'components/BaseComponents/Layout/Flex';
import { AddButtonProps } from './AddButtonCanvas';
import { buttonCSSProps } from './util';

interface LinkButtonProps {
  buttonStyle?: AddButtonProps;
}

export function LinkButton(props: LinkButtonProps) {
  const { buttonStyle } = props;
  if (!buttonStyle) {
    return null;
  }

  const style: React.CSSProperties = buttonCSSProps(
    buttonStyle.size ?? 'default',
    buttonStyle.background
  );

  return (
    <Flex
      className="gf-custom-button"
      style={{
        justifyContent: buttonStyle.align,
        visibility: buttonStyle.displayText ? 'visible' : 'hidden',
      }}
    >
      <a href={buttonStyle.linkURL} target="_blank" rel="noreferrer" style={style}>
        {buttonStyle.displayText}
      </a>
    </Flex>
  );
}
