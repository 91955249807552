import {
  keyActionForSettingStrategy,
  keyActionsForInvoices,
} from 'components/HigherOrderComponent/KeyActionsContainer/key-actions-config';
import { EActions } from 'components/HigherOrderComponent/KeyActionsContainer/types';
import { quickActivitesInvoiceList } from 'components/HigherOrderComponent/KeyActivitesContainer/key-acivities-config';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { strategyV2EnabledSelector } from 'store/authentication/authentication';

interface useComputedInvoiceListProps {
  hasPrivilegeToSetInvoiceFlag: boolean;
}

function useComputedInvoiceList({ hasPrivilegeToSetInvoiceFlag }: useComputedInvoiceListProps) {
  const strategyV2Enabled = useSelector(strategyV2EnabledSelector);
  const privilegeForStrategyPresent = usePrivilegeStatus([
    ERolesAndPrivileges.SET_COLLECTION_STRATEGY_CUSTOMER_DETAIL,
  ]);

  const computedQuickActivitiesInvoiceList = useMemo(() => {
    const invoiceListOptions = [
      ...quickActivitesInvoiceList,
      {
        key: EActions.NOTE,
        label: 'Add Note',
        icon: ['far', 'clipboard'],
      },
      {
        key: EActions.ESCALATE,
        label: 'Escalate',
        icon: ['far', 'circle-exclamation'],
      },
      {
        key: EActions.TASK,
        label: 'Create Task',
        icon: ['far', 'check'],
      },
      ...(hasPrivilegeToSetInvoiceFlag ? keyActionsForInvoices : []),
      ...(strategyV2Enabled && privilegeForStrategyPresent ? keyActionForSettingStrategy : []),
      {
        key: EActions.DOWNLOAD_PDF,
        label: 'Download PDF',
        icon: ['fal', 'download'],
      },
    ];

    return invoiceListOptions;
  }, [hasPrivilegeToSetInvoiceFlag, privilegeForStrategyPresent, strategyV2Enabled]);

  return { computedQuickActivitiesInvoiceList };
}

export { useComputedInvoiceList };
