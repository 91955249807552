import { AddDocumentTypes } from 'components/Settings/DocumentTypes/AddDocumentTypes/AddDocumentTypes';
import { DocumentTypesBody } from 'components/Settings/DocumentTypes/Body/DocumentTypesBody';
import { useDocumentTypesFilters } from 'components/Settings/DocumentTypes/Filters/useDocumentTypesFilters';
import { DocumentTypesHeader } from 'components/Settings/DocumentTypes/Header/Header';
import { usePageNavigate } from 'lib/router';
import { PageContainer } from 'router/PageContainer';

const t = {
  Documents: 'Documents',
  desc: 'Manage additional capabilities for custom documents',
};

export const documentTypeTargetId = 'documentTypeTarget';

function DocumentsSettings() {
  const navigate = usePageNavigate();
  const { filterValues, FilterArea } = useDocumentTypesFilters();

  return (
    <PageContainer page={'DOCUMENT_MANAGEMENT'}>
      <DocumentTypesHeader
        title={t.Documents}
        description={t.desc}
        handleClick={() => {
          navigate({ appPage: 'SETTINGS' });
        }}
        ExtraComponent={<AddDocumentTypes />}
      />
      {FilterArea}
      <div
        id={documentTypeTargetId}
        style={{
          position: 'relative',
          overflow: 'auto',
          height: 'calc(100% - 132px)',
        }}
      >
        <DocumentTypesBody filter={filterValues} />
      </div>
    </PageContainer>
  );
}

export { DocumentsSettings };
