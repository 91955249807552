import { GrowText } from 'components/BaseComponents/Typography';
import { EmailTemplates } from 'types/entities/email-templates';

interface TemplateTitleProps extends Pick<EmailTemplates, 'title'> {}

function TemplateTitle(props: TemplateTitleProps) {
  const { title } = props;
  return (
    <GrowText strong style={{ maxWidth: '300px' }}>
      {title}
    </GrowText>
  );
}

export { TemplateTitle };
