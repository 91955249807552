import { GrowTypography } from '@sinecycle/growcomponents';
import React from 'react';
interface ExtraInfoProps {
  value: React.ReactNode;
  truncate?: boolean;
  daysValue?: number;
}
function ExtraInfo(props: ExtraInfoProps) {
  return (
    <GrowTypography.Text style={{ borderBottom: '1px dashed #737373' }} ellipsis={props.truncate}>
      {props.value}
    </GrowTypography.Text>
  );
}

export { ExtraInfo };
