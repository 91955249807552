import { Style } from 'lib/style/style';
import styled from 'styled-components';
import { Flex } from '../Layout/Flex';

export const HoverBox = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--space-8);
  padding: var(--space-4) var(--space-8);
  border-radius: 0.25rem;
  &:hover,
  &.popover-visible {
    background: var(--hover-bg, var(--gray-2));
    .modify-icon {
      opacity: 1;
    }
  }
  .modify-icon {
    opacity: 0;
    width: 100%;
  }
`;

export const HoverWrapper = Style(Flex, {
  '.modify-icon': {
    display: 'none',
  },
  '.ant-typography': {
    maxWidth: '170px',
  },
  '&:hover': {
    '.modify-icon': {
      display: 'block',
    },
    '.ant-typography': {
      maxWidth: '120px',
    },
  },

  variants: {
    mode: {
      '.ant-typography': {
        maxWidth: '100% !important;',
      },
      CELL: {
        '&:hover': {
          '.ant-typography': {
            maxWidth: '130px !important;',
          },
        },
      },
      DETAILS: {
        justifyContent: 'space-between !important',

        '&:hover': {
          '.ant-typography': {
            maxWidth: '150px !important',
          },
        },
      },
      AUTOMATIONS: {
        justifyContent: 'space-between !important',

        '&:hover': {
          '.ant-typography': {
            maxWidth: '430px !important',
          },
        },
      },
    },
  },
});
