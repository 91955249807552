import produce from 'immer';
import { CollectionStrategy, FollowupTrack } from 'types/entities/collection-strategy';

type DeepPartial<T> = Partial<{ [P in keyof T]: DeepPartial<T[P]> }>;

/**
 * ids with type 'string' are browser generated
 * to perfom CRUD operations on locally created dunning rules
 * which are not yet saved in backend.
 * So we're removing string ids so when backend sees ids missing for any of the data
 * it assumes it's newly created structure and acts appropriately
 *
 * NOTE: the response from the backend would contain ids of type number
 */
export function filterStringIds(strategy: DeepPartial<CollectionStrategy>) {
  const updatedStrategy = produce(strategy, (strategyDraft) => {
    if (!strategyDraft?.id || isNaN(strategyDraft?.id) || strategyDraft?.id < 1) {
      delete strategyDraft.id;
    }

    function filterTracks(tracks: DeepPartial<FollowupTrack[]> | undefined) {
      tracks?.forEach((track) => {
        track?.dunning_rules?.forEach((dunningRule) => {
          if (typeof dunningRule?.id === 'string') {
            delete dunningRule.id;
          }
          if (typeof dunningRule?.data?.event?.id === 'string') {
            delete dunningRule.data.event.id;
          }
          if (typeof dunningRule?.data?.action?.id === 'string') {
            delete dunningRule.data.action.id;
          }
        });
        track?.stopping_rules?.forEach((stoppingRule) => {
          if (typeof stoppingRule?.id === 'string') {
            delete stoppingRule.id;
          }
          if (typeof stoppingRule?.data?.event?.id === 'string') {
            delete stoppingRule.data.event.id;
          }
        });
        track?.pausing_rules?.forEach((pausingRule) => {
          if (typeof pausingRule?.id === 'string') {
            delete pausingRule.id;
          }
          if (typeof pausingRule?.data?.event?.id === 'string') {
            delete pausingRule.data.event.id;
          }
          if (typeof pausingRule?.data?.resume_event?.id === 'string') {
            delete pausingRule.data.resume_event.id;
          }
        });
      });
    }

    filterTracks(strategyDraft.invoice_followup?.tracks);
    filterTracks(strategyDraft.statement_followup?.tracks);
  });

  return updatedStrategy;
}

interface FollowupParams {
  enabled: boolean;
  tracks: FollowupTrack[];
}
interface Params {
  invoiceFollowup: FollowupParams;
  statementFollowup: FollowupParams;
}
export function countActiveDunningRules({ invoiceFollowup, statementFollowup }: Params) {
  let totalEnabledDunningRules = 0;

  if (invoiceFollowup.enabled) {
    invoiceFollowup.tracks.forEach((track) => {
      totalEnabledDunningRules += track.dunning_rules.length;
    });
  }

  if (statementFollowup.enabled) {
    statementFollowup.tracks.forEach((track) => {
      totalEnabledDunningRules += track.dunning_rules.length;
    });
  }

  return totalEnabledDunningRules;
}
