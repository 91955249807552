import { useURLSearchParams } from 'hooks/use-search-params';
import { InboxPath } from 'pages/Inbox/type';
import { useGetRecentRepliesTreads } from 'queries/inbox';
import { useCallback, useMemo } from 'react';
import { NavigateOptions } from 'react-router-dom';
import {
  EmailThreadSearchParams,
  InboxEmailConversationType,
  InboxEmailMode,
  InboxEmailSearchParam,
} from 'types/api/inbox/email';
import { RelativeDateRange, getFormattedDateForRelativeOptions } from 'util/relative-date-range';

export function useEmailSearchParams() {
  const { setSearchParams, getSearchParam, removeSearchParams } = useURLSearchParams();

  const emailSearchParams = useMemo(() => {
    const emailSearchParams: Partial<EmailThreadSearchParams> = {};

    emailSearchParams[InboxEmailSearchParam.page] = getSearchParam(InboxEmailSearchParam.page);
    emailSearchParams[InboxEmailSearchParam.threadId] = getSearchParam(
      InboxEmailSearchParam.threadId
    );
    emailSearchParams[InboxEmailSearchParam.dateRange] = getSearchParam(
      InboxEmailSearchParam.dateRange
    );
    emailSearchParams[InboxEmailSearchParam.fromDate] = getSearchParam(
      InboxEmailSearchParam.fromDate
    );
    emailSearchParams[InboxEmailSearchParam.toDate] = getSearchParam(InboxEmailSearchParam.toDate);
    emailSearchParams[InboxEmailSearchParam.pageSize] = getSearchParam(
      InboxEmailSearchParam.pageSize
    );
    emailSearchParams[InboxEmailSearchParam.emailType] = getSearchParam(
      InboxEmailSearchParam.emailType
    );
    emailSearchParams[InboxEmailSearchParam.emailMode] = getSearchParam(
      InboxEmailSearchParam.emailMode
    );

    return emailSearchParams;
  }, [getSearchParam]);

  const setEmailSearchParams = useCallback(
    (newValue: Partial<EmailThreadSearchParams>, options?: NavigateOptions) => {
      setSearchParams(newValue, options);
    },
    [setSearchParams]
  );

  const removeEmailSearchParams = useCallback(
    (keys: string[]) => {
      removeSearchParams(keys);
    },
    [removeSearchParams]
  );

  return {
    emailSearchParams,
    setEmailSearchParams,
    removeEmailSearchParams,
  };
}

function useOpenEmailCardInbox() {
  const [from, to] = getFormattedDateForRelativeOptions(RelativeDateRange.LAST_30_DAYS);
  const searchParams: Partial<EmailThreadSearchParams> = useMemo(() => {
    return {
      [InboxEmailSearchParam.page]: 1,
      [InboxEmailSearchParam.pageSize]: 10,
      [InboxEmailSearchParam.fromDate]: from,
      [InboxEmailSearchParam.toDate]: to,
      [InboxEmailSearchParam.dateRange]: RelativeDateRange.LAST_30_DAYS,
      [InboxEmailSearchParam.emailType]: InboxEmailConversationType.UNREAD,
    };
  }, [from, to]);

  const { data: recentReplies, isLoading } = useGetRecentRepliesTreads(searchParams);

  const defaultQueryParams = useMemo(() => {
    return {
      ...searchParams,
      [InboxEmailSearchParam.emailMode]: InboxEmailMode.MY_INBOX,
    };
  }, [searchParams]);

  function handleOpenInNewWindow(parsedSearchParam: URLSearchParams) {
    const url = `/inbox/${InboxPath.EMAIL_CONVERSATION}?${parsedSearchParam.toString()}`;
    const win = window.open(url, '_blank');
    win?.focus();
  }

  function handleOpenCardInInbox(threadId?: number) {
    const searchQueryParam = {
      ...defaultQueryParams,
      [InboxEmailSearchParam.threadId]: threadId,
    };

    const parsedSearchParam = new URLSearchParams(
      searchQueryParam as unknown as Record<string, string>
    );
    handleOpenInNewWindow(parsedSearchParam);
  }

  function handleViewMore() {
    const searchQueryParam = {
      ...defaultQueryParams,
    };

    const parsedSearchParam = new URLSearchParams(
      searchQueryParam as unknown as Record<string, string>
    );
    handleOpenInNewWindow(parsedSearchParam);
  }
  const openCardInInbox = useCallback(handleOpenCardInInbox, [defaultQueryParams]);
  const onViewMore = useCallback(handleViewMore, [defaultQueryParams]);
  return { recentReplies, isLoading, openCardInInbox, onViewMore };
}

export { useOpenEmailCardInbox };
