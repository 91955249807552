import { Tag } from 'antd';
import styled from 'styled-components';

export const StyledCustomerTag = styled(Tag)`
  color: var(--geekblue-9);
  padding: var(--space-4) var(--space-8);
  background-color: var(--geekblue-1);
  border-radius: var(--br-2);
  border: unset;
  text-transform: capitalize;
  margin: 0;
  font-size: var(--fs-12);
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
