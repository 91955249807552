export enum NAVIGATION_EVENT {
  NAVIGATION = 'Navigation',
}

type NAVIGATION_EVENT_WITH_PROPS_PAYLOAD = {
  type: NAVIGATION_EVENT.NAVIGATION;
  payload: { to?: string; context?: string };
};

export type NAVIGATION_EVENT_TYPES = NAVIGATION_EVENT_WITH_PROPS_PAYLOAD;
