import { errorMonitor } from 'lib/error-monitor';
import HeapWrapper from 'lib/heap/heap-wrapper';
import { logRocket } from 'lib/logrocket';
import { tracker } from 'lib/tracker';
import { CurrentUser } from 'types/entities/user';

function identifyUserForUserEventTracker(user: CurrentUser, sessionURL: string) {
  tracker.identify(user.email, {
    role: user.roles[0],
    first_name: user.first_name,
    last_name: user.last_name,
    designation: user.designation ?? '',
    logRocketSessionId: sessionURL,
    userId: user.id,
    accountId: user.account_id,
  });
}

export function identifyUserForTrackers(user: CurrentUser) {
  logRocket.identify(String(user.id), {
    email: user.email ?? '',
    name: `${user.first_name} ${user.last_name}`,
  });

  errorMonitor.identify({ email: user.email });

  HeapWrapper.identify(`${user?.first_name}_${user.id}`);
  HeapWrapper.addUserProperties({
    'Account ID': user?.account_id,
    'Full Name': `${user?.first_name} ${user?.last_name}`,
    'Time Zone': user?.timezone,
    Email: user?.email,
    Role: user?.roles[0],
    'Account Domain': window.location.hostname,
  });
  logRocket.getSessionURL().then((sessionURL) => identifyUserForUserEventTracker(user, sessionURL));
}
