import styled from 'styled-components';
import { AntCheckBoxComponent } from './Antd';

export const StyledGridCheckBox = styled(AntCheckBoxComponent)`
  .ant-checkbox:not(:hover) {
    .ant-checkbox-inner {
      border: 1px solid var(--gray-9);
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: var(--gray-1);
    border: 1px solid var(--purple-7);
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 1.5px solid var(--primary-7);
    border-top: 0;
    border-left: 0;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background: var(--gray-3);
    border: 1px solid var(--gray-5);
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: var(--gray-6);
  }
`;
