import { CurrencySettingPatchProps } from 'components/Settings/Currency/type';
import { IntegrationNames } from 'pages/Settings/types';
import { AllAccountSourceProps } from 'types/api/currency-page-settings';
import { AccountSource, CredentialsPostBody, Integrations } from '../store/integrations/types';
import ky, { kyWithSerializer } from './ky';

type ActionUrls = {
  create: string;
  read: string;
  remove?: string;
};

export interface HubspotCredential {
  accountSourceType: string;
  accountSourceTypeDisplayName: string;
  enabled: boolean;
  id: number;
  sourceAccountId: string;
}

export interface InstallationUrlResponse {
  redirectUrl: string;
}

export type IntegrationsRedirection<T> = T & { redirectUrl?: string };

const integrationUrls: Partial<Record<Integrations, ActionUrls>> = {
  zoho_books: {
    create: 'account-sources/zoho-books',
    read: 'account-sources/zoho-books',
  },
  stripe: {
    create: 'account-sources/stripe',
    read: 'account-sources/stripe',
  },
  zoho_invoice: {
    create: 'account-sources/zoho-invoice',
    read: 'account-sources/zoho-invoice',
  },
  // chargebee: {
  //   create: 'account-sources/chargebee',
  //   read: 'account-sources/chargebee',
  // },
  netsuite: {
    create: 'account-sources/netsuite',
    read: 'account-sources/netsuite',
  },
  // zuora: {
  //   create: 'account-sources/zuora',
  //   read: 'account-sources/zuora',
  // },
  // invoiced: {
  //   create: 'account-sources/invoiced',
  //   read: 'account-sources/invoiced',
  // },
  quick_books: {
    create: 'account-sources/quickbooks',
    read: 'account-sources/quickbooks',
  },
  xero: {
    create: 'account-sources/xero',
    read: 'account-sources/xero',
  },
  salesforce: {
    create: 'account-sources/salesforce',
    read: 'account-sources/salesforce',
  },
  sage: {
    create: 'account-sources/sage',
    read: 'account-sources/sage',
  },
};

const IntegrationsWithRedirection = [
  Integrations.ZOHO_BOOKS,
  Integrations.ZOHO_INVOICE,
  Integrations.SALESFORCE,
  Integrations.XERO,
];

export async function postIntegrationCredentials(
  integrationName: Integrations,
  credentials: CredentialsPostBody
) {
  if (integrationUrls[integrationName]) {
    if (IntegrationsWithRedirection.includes(integrationName))
      return await kyWithSerializer
        .post(integrationUrls[integrationName].create, { json: credentials })
        .json();
    else {
      return await kyWithSerializer.post(integrationUrls[integrationName].create, {
        json: credentials,
      });
    }
  } else {
    throw new Error('invalid integration name');
  }
}

export async function getIntegrationCredentials<T extends Integrations>(
  integrationName: Integrations,
  accountId: number
) {
  if (integrationUrls[integrationName]) {
    return (await kyWithSerializer
      .get(integrationUrls[integrationName].read)
      .json()) as AccountSource<T>[];
  }
  return null;
}

export async function setIntegrationStatus(account_source_id: number, enabled: boolean) {
  const data = { enabled: enabled };
  return await ky.put(`account-sources/${account_source_id}`, { json: data });
}

export async function getAllAccountSources() {
  return (await ky.get(`account-sources`).json()) as AllAccountSourceProps[];
}

export async function configureExchangeRate(data: CurrencySettingPatchProps, id: number) {
  return (await ky.patch(`account-sources/${id}`, { json: data }).json()) as AllAccountSourceProps;
}

export async function getCredentialsList<Tdata>(type: IntegrationNames) {
  return await ky.get(`integrations/account-sources/${type}`).json<{ data: Tdata[] }>();
}

export async function getIntegrationCredentialsList<Tdata>() {
  return await ky.get(`integrations/account-sources`).json<{ data: Tdata[] }>();
}

export async function editIntegrationCredentialsList<Tdata>(
  type: IntegrationNames,
  data: Tdata,
  id: number
) {
  return ky
    .put(`integrations/account-sources/${type}/${id}/credentials`, {
      json: data,
    })
    .json<IntegrationsRedirection<Tdata>>();
}

export async function postIntegrationCredentialsList<Tdata>(type: IntegrationNames, data: Tdata) {
  return ky
    .post(`integrations/account-sources/${type}/credentials`, {
      json: data,
    })
    .json<IntegrationsRedirection<Tdata>>();
}

export async function getHubspotCredentialsList() {
  return await ky.get(`account-sources/hubspot`).json<HubspotCredential[]>();
}

export async function connectHubspotCredentials() {
  return await ky.post('integrations/account-sources/hubspot/credentials').json<InstallationUrlResponse>();
}
