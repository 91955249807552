import { DefaultTableContext, StatusCellConfig } from '@sinecycle/growcomponents';
import { PaymentMode } from 'components/CollectionActivities/types';
import { PaymentListData } from 'store/payments/type';
import { AmountMaybeWithMultiCurrencySplit } from 'types/common/multi-currency';
import { Customer } from 'types/entities/customer';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { Subsidiary } from 'types/entities/subsidiary';
import { UserBasic } from 'types/entities/user';
import { ActivityBase } from './activity';

export enum InvoicePaymentStatus {
  PENDING = 'PENDING',
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

export enum PaymentStatus {
  VOID = 'VOID',
  FULLY_APPLIED = 'FULLY_APPLIED',
  UNAPPLIED = 'UNAPPLIED',
  PARTIALLY_APPLIED = 'PARTIALLY_APPLIED',
}

export interface RecentPayment {
  payment_id: number;
  payment_reference_number: string;
  payment_date: string;
  payment_amount: AmountMaybeWithMultiCurrencySplit;
  customer_id: number;
  customer_name?: string;
  invoice_list: InvoiceBasicDetails[];
}

export interface PaymentBase {
  id: number;
  mode: PaymentMode;
  currency: string;
  notes: string;
  status?: PaymentStatus;
  amount: number;
  invoices: InvoiceBasicDetails[];
  reconciled_by: UserBasic;
  reference_no: string;
  payment_date: string;
  amount_base_currency: number;
  payment_mode?: string;
  customer?: Customer;
  applied_amount?: number;
  applied_amount_base_currency?: number;
  unapplied_amount?: number;
  unapplied_amount_base_currency?: number;
  note?: string;
  amount_data: AmountMaybeWithMultiCurrencySplit;
  amount_customer_currency_data: AmountMaybeWithMultiCurrencySplit;
  applied_amount_data: AmountMaybeWithMultiCurrencySplit;
  un_applied_amount_data: AmountMaybeWithMultiCurrencySplit;
  subsidiary?: Subsidiary;
}

export interface Payment extends PaymentBase, ActivityBase {}

export const paymentStatusConfig: Record<PaymentStatus, StatusCellConfig> = {
  [PaymentStatus.FULLY_APPLIED]: {
    style: {
      textColor: 'var(--green-9)',
      backgroundColor: 'var(--green-1)',
    },

    label: 'Fully Applied',
  },
  [PaymentStatus.PARTIALLY_APPLIED]: {
    style: {
      textColor: 'var(--orange-9)',
      backgroundColor: 'var(--orange-1)',
    },
    label: 'Partially Applied',
  },
  [PaymentStatus.UNAPPLIED]: {
    style: {
      textColor: 'var(--red-9)',
      backgroundColor: 'var(--red-1)',
    },
    label: 'Unapplied',
  },
  [PaymentStatus.VOID]: {
    style: {
      textColor: 'var(--volcano-9)',
      backgroundColor: 'var(--volcano-1)',
    },
    label: 'Void',
  },
};

export type PaymentTableContext = DefaultTableContext<Payment> & {
  summary?: PaymentListData['summary'];
};

export function getPaymentStatus(status: PaymentStatus) {
  return paymentStatusConfig[status];
}
