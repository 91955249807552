import { Skeleton } from 'antd';
import { SkeletonInputProps } from 'antd/lib/skeleton/Input';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import React from 'react';
import styled from 'styled-components';
const StyledSkeleton = styled(Skeleton.Input)`
  width: 100%;
  min-height: 12px;

  &.ant-skeleton-input {
    height: inherit;
  }
`;
function SkeletonLoader(props: SkeletonInputProps) {
  const { block, size, style } = props;
  const dummyArray = new Array(5).fill(1);

  return (
    <Flex
      justify="flex-start"
      gap="var(--space-8)"
      style={{ width: '100%', minHeight: '100px' }}
      direction="column"
    >
      {dummyArray.map((item, key) => {
        return <StyledSkeleton key={key} active block={block} size={size} style={style} />;
      })}
    </Flex>
  );
}

function InputLoader({
  active,
  size = 'small',
  style,
}: React.ComponentProps<typeof Skeleton.Input>) {
  return <Skeleton.Input active={active} size={size} style={style} />;
}

export { InputLoader };

export default SkeletonLoader;
