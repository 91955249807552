//https://github.com/ueberdosis/tiptap/issues/412#issuecomment-1077961832
export const GROWFIN_EMPTYLINE = /(<div\s?((style=")([a-zA-Z0-9:;.\s()\-,]*)("))?>)<br>(<\/div>)/g;
export const TIPTAP_EMPTYLINE = /(<div\s?((style=")([a-zA-Z0-9:;.\s()\-,]*)("))?>)(<\/div>)/g;
export const TIPTAP_EMPTYLINE_OLD = /(<p\s?((style=")([a-zA-Z0-9:;.\s()\-,]*)("))?>)(<\/p>)/g; //soon to be deprecated
export const subjectRegEx = /^<div[^>]*>|<\/div>$/gi;
export const tableRegEx = /<table\b[^>]*>.*<\/table>/s;
export const styleRemoveRegex = /<style\b[^>]*>[\s\S]*?<\/style>/gi;
export const isContainsNewlineRegex = /<(br|hr)\/?>/;
export const tagsPattern = /<[^>]*>/g;
export const PlaceholderRegex = /\{\{([^{}]+)\}\}/g;

export function serialize(text?: string | null) {
  if (!text) return text;
  //Converting format to suit RTE
  const converted = text.replaceAll(GROWFIN_EMPTYLINE, '$1$6');
  return converted;
}

export function deSerialize(text?: string | null) {
  if (!text) return text;
  //Converting format from RTE suitable for external use
  return text.replaceAll(TIPTAP_EMPTYLINE, '$1<br>$6').replaceAll(TIPTAP_EMPTYLINE_OLD, '$1<br>$6');
}

export function deSerializePlaceholder(text?: string) {
  if (!text) return text;
  const replacement = '<span class="grow-email-placeholder">{{$1}}</span>';
  return text.replace(PlaceholderRegex, replacement);
}

export function removeClassFromHtml(htmlString: string | null, classNameToRemove: string) {
  if (!htmlString) {
    return '';
  }

  // Create a regular expression to match the class attribute in <span> tags
  const regex = new RegExp(
    `<span[^>]*\\sclass\\s*=\\s*["'][^"']*\\b${classNameToRemove}\\b[^"']*["'][^>]*>`,
    'gi'
  );

  return htmlString.replace(/^<div>([\s\S]*?)<\/div>$/, '$1').replace(regex, (match) =>
    match
      .replace(classNameToRemove, '')
      .replace(/\s{2,}/g, ' ')
      .trim()
  );
}
