import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { end } from '@popperjs/core';
import { Col, Popover, Row } from 'antd';

import { Flex } from 'components/BaseComponents/Layout/Flex';
import React, { useEffect, useState } from 'react';
import {
  EmailStakeHolderType,
  IConversationParticipant,
  IEmailActivity,
} from '../../../../store/activity-feed/type';
import { getFormattedDate } from '../../../../util/datetime-formatter';
import { FilePreviewList } from '../../../BaseComponents/UploadFiles/FilePreview';
import InvoiceDetailsList from '../../InvoiceList';
import UserInfoAvatar from '../../UserInfoAvatar';

import { GrowTypography } from '@sinecycle/growcomponents';
import { GrowText } from 'components/BaseComponents/Typography';
import { EmailRecepientStatus } from './EmailRecepientStatus';
import * as Styled from './style';
import { FieldValue } from './style';

const t = {
  show_more: 'show more',
  attachments: 'Attachments',
};

interface IEmailTemplate {
  emailActivity?: IEmailActivity;
  isFromCard?: boolean;
  setQuickAction?: () => void;
  isParentCard?: boolean;
}

const EmailDisplayTemplate = (props: IEmailTemplate) => {
  const [showExtraInformation, setShowExtraInformation] = useState(false);
  const [from, setFrom] = useState<IConversationParticipant>({} as IConversationParticipant);
  const [to, setTo] = useState<IConversationParticipant[]>([]);
  const [bcc, setBcc] = useState<IConversationParticipant[]>([]);
  const [cc, setCc] = useState<IConversationParticipant[]>([]);
  const [body, setBody] = useState<string>('');

  useEffect(() => {
    if (props.emailActivity) {
      if (props.emailActivity?.body) {
        if (props.emailActivity?.body.length < 200) {
          setBody(props.emailActivity?.body);
        } else {
          setBody(props.emailActivity?.body.substr(0, 200));
        }
      }

      if (props.emailActivity?.conversation_participants?.length) {
        const to: IConversationParticipant[] = [];
        const cc: IConversationParticipant[] = [];
        const bcc: IConversationParticipant[] = [];
        let from: IConversationParticipant = {} as IConversationParticipant;

        props.emailActivity.conversation_participants.forEach((item) => {
          switch (item.type) {
            case EmailStakeHolderType.FROM:
              from = item;
              break;

            case EmailStakeHolderType.TO:
              to.push(item);
              break;

            case EmailStakeHolderType.CC:
              cc.push(item);
              break;

            case EmailStakeHolderType.BCC:
              bcc.push(item);
              break;
          }
        });

        setFrom(from);
        setTo(to);
        setCc(cc);
        setBcc(bcc);
      }
    }
  }, [props.emailActivity]);

  return (
    <>
      <Row gutter={10}>
        <Col span={18}>
          {!Array.isArray(from) ? (
            <UserInfoAvatar
              name={from.first_name ? [from.first_name, from.last_name as string] : undefined}
            />
          ) : (
            <UserInfoAvatar />
          )}
        </Col>
        <Col
          style={{
            textAlign: end,
            paddingRight: '0.8rem',
            justifyContent: 'flex-end',
            display: 'flex',
            gap: 'var(--space-16)',
          }}
          span={6}
        >
          {props.isParentCard &&
            (props.emailActivity?.invoice || props.emailActivity?.invoices) &&
            Boolean(props.emailActivity?.invoices.length) && (
              <Flex justify="flex-end" className="label" gap="var(--space-4)" align="flex-start">
                <GrowText color="var(--purple-10)"> Invoice#</GrowText>
                <InvoiceDetailsList
                  titleWidth="70px"
                  invoiceList={props.emailActivity?.invoices ?? [props.emailActivity?.invoice]}
                />
              </Flex>
            )}
          {!props.emailActivity?.incoming && <EmailRecepientStatus to={to} bcc={bcc} cc={cc} />}
        </Col>
      </Row>
      <div>
        <div className="label">{props.emailActivity?.subject}</div>
        <div className="to label">
          <span>To</span>
          {to?.map((item, key) => {
            return (
              !Array.isArray(item.emailable) && (
                <span key={key} className="ms-2">
                  <span className="to-user">{`${item.first_name} `}</span>
                  {item.emailable && item.emailable.title && (
                    <span className="ms-1">{`(${item.emailable.title})`}</span>
                  )}
                </span>
              )
            );
          })}
          <Styled.EmailInfoCaret
            className="extra-information"
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              event.stopPropagation();
              setShowExtraInformation(true);
            }}
          >
            <Popover
              placement="right"
              getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
              content={
                <>
                  <div
                    style={{
                      position: 'relative',
                    }}
                    className="email-info"
                  >
                    <Row className="from-container" gutter={10}>
                      <Col className="label">From</Col>
                      <Col>
                        {!Array.isArray(from) && <FieldValue>{from.email}</FieldValue>}
                        <span></span>
                      </Col>
                      <Col style={{ marginLeft: 'auto' }} className="icon-close">
                        <span
                          className="cursor-pointer"
                          style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                          }}
                          onClick={(event: React.MouseEvent) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setShowExtraInformation(false);
                          }}
                        >
                          <FontAwesomeIcon icon={['fal', 'times']} />
                        </span>
                      </Col>
                    </Row>
                    <Row className="to-container" gutter={10}>
                      <Col className="label">To</Col>
                      <Col>
                        {Array.isArray(to) &&
                          to?.map((item, key) => {
                            return (
                              <div key={key}>
                                {!Array.isArray(item) && <FieldValue> {item.email}</FieldValue>}
                              </div>
                            );
                          })}
                      </Col>
                    </Row>
                    <Row
                      className={`cc-container ${Array.isArray(cc) && !cc.length ? 'd-none' : ''}`}
                      gutter={10}
                    >
                      <Col className="label">CC</Col>
                      <Col>
                        {Array.isArray(cc) &&
                          cc?.map((item, key) => {
                            return (
                              <div key={key}>
                                {!Array.isArray(item) && <FieldValue>{item.email}</FieldValue>}
                              </div>
                            );
                          })}
                      </Col>
                    </Row>
                    <Row
                      className={`bcc-container ${
                        Array.isArray(bcc) && !bcc.length ? 'd-none' : ''
                      }`}
                    >
                      <Col className="label">BCC</Col>
                      <Col>
                        {Array.isArray(bcc) &&
                          bcc?.map((item, key) => {
                            return (
                              <div key={key}>
                                {!Array.isArray(item) && <FieldValue>{item.email}</FieldValue>}
                              </div>
                            );
                          })}
                      </Col>
                    </Row>
                    <Row className="subject" gutter={10}>
                      <Col className="label">Subject</Col>
                      <Col>
                        <FieldValue>{`${props.emailActivity?.subject}`}</FieldValue>
                      </Col>
                    </Row>
                    <Row className="date" gutter={10}>
                      <Col className="label">Date</Col>
                      <Col>
                        <FieldValue>
                          {getFormattedDate(props.emailActivity?.sent_at as string, 'WITH_TIME')}
                        </FieldValue>
                      </Col>
                    </Row>
                  </div>
                </>
              }
              trigger="click"
              open={showExtraInformation}
              onOpenChange={() => {}}
            >
              <FontAwesomeIcon icon={['fas', 'sort-down']} />
            </Popover>
          </Styled.EmailInfoCaret>
        </div>
      </div>
      <Row
        className="date"
        style={{
          borderBottom: '1px solid var(--gray-5)',
          paddingBottom: '10px',
          marginRight: '10px',
          marginBottom: '10px',
        }}
      >
        <span className="time label-sm">
          {getFormattedDate(props.emailActivity?.sent_at as string, 'WITH_TIME')}
        </span>
      </Row>
      <Row gutter={10}>
        <Col style={{ marginBottom: '1rem', width: '100%' }}>
          <div
            dangerouslySetInnerHTML={{
              __html: props.isFromCard ? body : props.emailActivity?.body ?? '',
            }}
          />
          {props.isFromCard &&
            props?.emailActivity?.body?.length &&
            props?.emailActivity?.body?.length > 300 && (
              <GrowTypography.Link>{t.show_more}</GrowTypography.Link>
            )}
        </Col>
      </Row>
      <Row gutter={10}>
        <Col style={{ marginBottom: '2rem' }}>
          {props?.emailActivity?.attachments?.length ? (
            <div className="attachments">
              <span>{t.attachments}</span>
              <div
                className="files-container"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <FilePreviewList files={props.emailActivity.attachments} />
              </div>
            </div>
          ) : null}
        </Col>
      </Row>
      {/* {!props.isFromCard && <ShowEmailThread />} */}
    </>
  );
};

export default EmailDisplayTemplate;
