import React, { createContext, useContext, useState } from 'react';
export interface SelectedCurrenciesWithValueProps {
  source: string;
  destination: string;
  exchangeRate?: number;
}

type SelectedCurrenciesContextType = {
  selectedCurrenciesWithValues: SelectedCurrenciesWithValueProps[];
  setSelectedCurrenciesWithValues: (newValues: SelectedCurrenciesWithValueProps[]) => void;
  unsavedChanges: boolean;
  setUnsavedChanges: (newValue: boolean) => void;
};

const SelectedCurrenciesContext = createContext<SelectedCurrenciesContextType | undefined>(
  undefined
);

export const useSelectedCurrencies = () => {
  const context = useContext(SelectedCurrenciesContext);
  if (context === undefined) {
    throw new Error('useSelectedCurrencies must be used within a SelectedCurrenciesProvider');
  }
  return context;
};

export const SelectedCurrenciesProvider: React.FC = ({ children }) => {
  const [selectedCurrenciesWithValues, setSelectedCurrenciesWithValues] = useState<
    SelectedCurrenciesWithValueProps[]
  >([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  return (
    <SelectedCurrenciesContext.Provider
      value={{
        selectedCurrenciesWithValues,
        setSelectedCurrenciesWithValues,
        unsavedChanges,
        setUnsavedChanges,
      }}
    >
      {children}
    </SelectedCurrenciesContext.Provider>
  );
};
