import { amountFormats } from '@sinecycle/growcomponents';
import { TypographyParagraph } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import GrowPopover from 'components/BaseComponents/PopOver';
import { GrowToolTip } from 'components/BaseComponents/Tooltip';
import { GrowText } from 'components/BaseComponents/Typography';
import { CSSProperties, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';
const ListStyle: CSSProperties = { maxWidth: '300px', maxHeight: '200px', overflowY: 'scroll' };
const wrapperStyles: React.CSSProperties = { fontSize: 'var(--fs-16)', width: '100%' };

interface EllipsisListProps {
  label?: string;
  totalRecords?: number;
  list?: string[];
  isEmailPreview?: boolean;
  showScrollablePopover?: boolean;
}

export function EllipsisList(props: EllipsisListProps) {
  const locale = useSelector(localeSelector);
  const selectedValues = useMemo(() => {
    return [...(props.list ?? [])].slice(0, 3).join(', ');
  }, [props.list]);

  const totalCount = props.totalRecords
    ? props.totalRecords > 3
      ? `+ ${amountFormats.number(props.totalRecords - 3, { locale })} more`
      : ''
    : '';
  const restList = [...(props.list ?? [])].slice(3);
  const rest = restList?.join(', ');
  const Label = (
    <GrowText color="var(--gray-7)" size="var(--fs-12)" style={{ marginBottom: 'var(--space-4)' }}>
      {props.label}
    </GrowText>
  );
  const ListRenderer = (
    <Flex direction="column" gap="var(--space-12)" style={ListStyle}>
      {restList?.map((subItem) => {
        return <GrowText key={subItem}>{subItem}</GrowText>;
      })}
    </Flex>
  );
  const List = (
    <Flex direction="column" gap="var(--space-8)">
      {Label}
      {ListRenderer}
    </Flex>
  );
  const extra = (
    <GrowPopover content={List} placement="topRight" arrow={false}>
      <GrowText>{totalCount}</GrowText>
    </GrowPopover>
  );
  return (
    <Flex
      gap="var(--space-4)"
      direction="column"
      align={props.isEmailPreview ? 'flex-end' : 'flex-start'}
      style={{ width: 'inherit' }}
    >
      {props.label && <GrowText>{props.label}</GrowText>}
      <Flex gap="var(--space-8)" align="center" style={{ ...wrapperStyles }}>
        <TypographyParagraph
          strong
          ellipsis={{
            rows: 2,
            tooltip: `${selectedValues} ${totalCount}`,
            onEllipsis(ellipsis) {},
          }}
          style={{
            width: props.isEmailPreview ? '150px' : 'auto',
            maxWidth: 'fit-content',
            margin: 0,
          }}
        >
          {selectedValues}
        </TypographyParagraph>
        <Flex>
          {props.showScrollablePopover ? (
            extra
          ) : (
            <GrowToolTip title={rest}>
              <GrowText>{totalCount}</GrowText>
            </GrowToolTip>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
