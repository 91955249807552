import { sortBy } from 'lodash';
import { GenericObject, Rank } from 'types/utils/utils';

/**
 *
 *
 * @param list list of objects that needs to be sorted
 * @param rank on what order list to be sorted
 * @param key key of object based on that value list will be sorted
 * @returns sorted list
 */

export function OrderByRank<T>(list: GenericObject<T>[], rank: Rank, key: keyof GenericObject<T>) {
  return sortBy(list, (element) => {
    return rank[element[key] as unknown as keyof typeof rank];
  });
}
