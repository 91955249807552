import { isArray, map } from 'lodash';
import { ActivityType } from 'types/activities/activity-types';
import {
  onFormRemainderSubmitProps,
  ParseDateTransformSchemaProps,
  useOnFormRemainderSubmitProps,
} from '../types';
import { parseDateTransformSchema } from '../Utils';
import useFormSubmit from './useFormSubmit';

export default function useOnFormRemainderSubmit<T, U>(props: useOnFormRemainderSubmitProps<T, U>) {
  const { taskRemainderResponseSchema, customTransformer, onMutateFn, parentType, onClose } = props;

  const { onFormSubmit } = useFormSubmit<T>(onMutateFn);

  function onFormRemainderSubmit<T>(props: onFormRemainderSubmitProps<T>) {
    const { values, data, remainder } = props;

    if (!remainder) return;

    const taskRemainderProps: ParseDateTransformSchemaProps<T, U> = {
      data: values,
      type: ActivityType.TASK,
      responseSchema: taskRemainderResponseSchema,
      dateKey: ['taskRemainderDueDate'],
      customTransformer,
      parentType,
      associatedData: data,
    };

    const taskRemainderValue: (T | undefined)[] | T | undefined = isArray(data)
      ? map(data, (item) =>
          parseDateTransformSchema<T, U>({
            ...taskRemainderProps,
            associatedData: item,
          })
        )
      : parseDateTransformSchema<T, U>({ ...taskRemainderProps });
    const isBulkAction = isArray(taskRemainderValue) && parentType !== ActivityType.TASK;

    onFormSubmit(taskRemainderValue, ActivityType.TASK, isBulkAction).then(() => {
      onClose();
    });
  }

  return onFormRemainderSubmit;
}
