import React from 'react';
import styled from 'styled-components';
import { StyleProps } from 'types/common/styles';
import { wrapCssProp } from 'util/css-utils';

interface FlexBoxProps {
  align?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between';
  gap?: string;
  rowGap?: string;
  columnGap?: string;
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  wrap?: 'wrap' | 'nowrap';
}

const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex-wrap: ${(props) => props.wrap};
  row-gap: ${(props) => wrapCssProp(props.rowGap) ?? wrapCssProp(props.gap)};
  column-gap: ${(props) => wrapCssProp(props.columnGap) ?? wrapCssProp(props.gap)};
`;

export interface FlexChildProps {
  alignSelf?: 'center' | 'flex-start' | 'flex-end';
  justifySelf?: 'center' | 'flex-start' | 'flex-end';
  order?: number;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: string;
  flex?: string;
}

export const FlexBoxChild = styled.div<FlexChildProps>`
  align-self: ${(props) => props.alignSelf};
  justify-self: ${(props) => props.justifySelf};
  order: ${(props) => props.order};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
  flex-basis: ${(props) => props.flexBasis};
  flex: ${(props) => props.flex};
`;

export interface FlexProps extends FlexBoxProps, Omit<StyleProps, 'align'> {
  children: React.ReactNode;
  hidden?: boolean;
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLElement>) => void);
}

export function Flex({ children, style, className, hidden, onClick, ...flexProps }: FlexProps) {
  return (
    <FlexBox {...flexProps} style={style} className={className} hidden={hidden} onClick={onClick}>
      {children}
    </FlexBox>
  );
}

// Compound Components (Flex, Flex.Child)
Flex.Child = FlexBoxChild;
