import { BroadCastChannelEvent, BroadCastChannelType, BroadCastEventType } from './broadcast.type';

export function handleLoggedOutEvent() {
  window.location.reload();
}

export function handleSignOutEventMessages(event: BroadCastEventType) {
  if (event === BroadCastEventType.LOGGED_OUT) {
    handleLoggedOutEvent();
  }
}

export function handleBroadCastEvent(payload: BroadCastChannelEvent) {
  switch (payload.channel) {
    case BroadCastChannelType.SIGNOUT_CHANNEL:
      handleSignOutEventMessages(payload.event);
      break;
    default:
      break;
  }
}
