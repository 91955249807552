import { useLocation } from 'lib/router';
import { useMemo } from 'react';
import { isJson } from '../util/json-utils';

/**
 * Scans the current URL and captures the searchparams
 * eg: `?sort_col=DUE_AMOUNT&sort_by=ASC&dispute_type_ids=[2]&status=["DISPUTE"]`
 * converts into key value pair using URLSearchParams
 * eg: sort_col = "DUE_AMOUNT", sort_by = "ASC"
 *
 * @returns usable object | null;
 */
export const useFormattedURLSearchParam = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const queryParams = new URLSearchParams(search),
      fitersParams = {} as any;

    queryParams?.forEach((value, key) => {
      if (value) {
        fitersParams[key] = isJson(value) ? JSON.parse(value) : value;
      }
    });

    return Object.keys(fitersParams).length ? fitersParams : null;
  }, [search]);
};
