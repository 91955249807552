import { Button } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterConfig } from '../types';
import { getInputFilterAction } from './getFilterComponent';

const t = {
  submit: 'Submit',
  cancel: 'Cancel',
};

const footerButtonsStyle: React.CSSProperties = {
  borderTop: '1px solid var(--gray-4)',
  paddingTop: 'var(--space-12)',
  marginTop: 'var(--space-8)',
};

///////////////////////// FILTER DROPDOWN CONTENT /////////////////////////////
interface FilterDropdownContentProps<T extends unknown> {
  filterConfig: FilterConfig;
  value?: T;
  onSubmit?: (value: T) => void;
  onChange?: (value: T) => void;
  onCancel?: () => void;
}
function FilterDropdownContent<T extends unknown>(props: FilterDropdownContentProps<T>) {
  const { filterConfig, onSubmit, onCancel, onChange } = props;

  // have a 'internal' state/ref with updated value : set when -> onChange from input components,
  // does ant support that internal state ? -> or should I use `Ant Form` for that???
  const [_value, _setValue] = useState(props.value);

  const [_disabled, _setDisabled] = useState(false);
  // sets value again if filterConfig updates it
  // only updates when the new value is NOT deeply equal to existing value
  useEffect(() => {
    _setValue((value: T | undefined) => (isEqual(value, props.value) ? value : (props.value as T)));
  }, [props.value]);

  const InputFilterAction = useMemo(() => {
    function handleOnChange(newValue: T) {
      _setValue(newValue);
      onChange && onChange(newValue);
    }

    const fnParam = {
      value: _value,
      onChange: handleOnChange,
    };

    return getInputFilterAction(filterConfig, fnParam);
  }, [_value, filterConfig, onChange]);

  function handleOnSubmit() {
    onSubmit && _value && onSubmit(_value);
  }

  function handleOnCancel() {
    // resets to filterConfig
    _setValue(props.value);
    onCancel && onCancel();
  }

  const onCustomEventCallBack = useCallback((event: CustomEvent) => {
    if (event?.type === 'RANGE_ERROR') {
      _setDisabled(event.detail.error);
    }
  }, []);

  function customEventListener() {
    document.addEventListener('RANGE_ERROR', onCustomEventCallBack as EventListener);

    return () => {
      document.removeEventListener('RANGE_ERROR', onCustomEventCallBack as EventListener);
    };
  }

  useEffect(customEventListener, [onCustomEventCallBack]);
  const FooterButtons = (
    <Flex style={footerButtonsStyle} gap="--space-4">
      <Button type="primary" onClick={handleOnSubmit} disabled={_disabled}>
        {t.submit}
      </Button>
      <Button onClick={handleOnCancel}>{t.cancel}</Button>
    </Flex>
  );

  return (
    <Flex direction="column" gap="--space-4">
      {InputFilterAction}
      {FooterButtons}
    </Flex>
  );
}

export { FilterDropdownContent };
