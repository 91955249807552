import { Form } from 'antd';
import { notify } from 'components/BaseComponents/Notifications';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { ActivityType } from 'types/activities/activity-types';
import useFormSubmit from '../hooks/useFormSubmit';
import useOnFormRemainderSubmit from '../hooks/useSubmitFormRemainder';
import TaskRemainder from '../TaskRemainder';
import { FormItemNames, NoteFormModalProps, NoteModalFormElementProps } from '../types';
import { parseTransformSchema } from '../Utils';
import NoteFormItems from './NoteFormItems';
import { getNotifyErrorMessage, getNotifySuccessMessage } from './notificationMessage';

export default function NoteForm<NoteElements extends NoteModalFormElementProps>(
  props: Omit<NoteFormModalProps<NoteElements>, 'openModal'>
) {
  const {
    form,
    text,
    invoices,
    remainder,
    responseSchema,
    taskRemainderResponseSchema,
    onMutateFn,
    onFormClose,
    customTransformer,
    bulkAction,
    editMode,
    dateFormat,
  } = props;

  const assigneeOptionType = invoices?.length
    ? ActionableEntity.INVOICE
    : ActionableEntity.CUSTOMER;

  const { onFormSubmit } = useFormSubmit<NoteElements['responseType']>(onMutateFn);

  const onFormRemainderSubmit = useOnFormRemainderSubmit<
    NoteElements['responseType'],
    NoteElements['taskRemainderResponseSchema']
  >({
    taskRemainderResponseSchema,
    customTransformer,
    onMutateFn,
    parentType: ActivityType.NOTE,
    onClose: onFormClose,
  });

  function onFormFinish(values: Record<FormItemNames, any>) {
    const formValues = parseTransformSchema<NoteElements['responseType']>({
      data: values,
      type: ActivityType.NOTE,
      responseSchema,
      customTransformer,
    });

    onFormSubmit(formValues, ActivityType.NOTE)
      .then((data) => {
        if (!remainder) {
          onFormClose();
        }
        onFormRemainderSubmit<NoteElements['responseType']>({
          values,
          data,
          remainder,
        });
        const { title, description } = getNotifySuccessMessage(bulkAction, editMode);
        notify.success(title, {
          description: description,
        });
      })
      .catch(() => {
        const { title, description } = getNotifyErrorMessage(bulkAction, editMode);
        notify.error(title, {
          description: description,
        });
      });
  }

  return (
    <Form.Provider
      onFormFinish={(name, { values }) => {
        onFormFinish(values as Record<FormItemNames, any>);
      }}
    >
      <Form name={'activityForm'} form={form} layout="vertical" preserve={false}>
        <NoteFormItems<NoteElements> {...props} />
      </Form>
      <div className={`${remainder ? 'active-transition' : 'hide-transition'}`}>
        <Form name={'taskRemainderForm'} form={form} layout="vertical" preserve={false}>
          <TaskRemainder
            form={form}
            assigneeOptionType={assigneeOptionType}
            text={text}
            remainder={remainder}
            dateFormat={dateFormat}
          />
        </Form>
      </div>
    </Form.Provider>
  );
}
