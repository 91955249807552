import { IconSalesforceBadge } from 'assets/icons';
import { createElement } from 'react';
import { ExternalMappingEventType } from 'types/activities/comment';

const t = {
  salesforce: 'Salesforce',
  via: 'via',
};

interface CommentSourceProps {
  source?: ExternalMappingEventType;
  containerStyle?: React.CSSProperties;
}

export function CommentSource(props: CommentSourceProps) {
  let Element;
  const { source, containerStyle } = props;

  switch (source) {
    case ExternalMappingEventType.SALESFORCE:
      Element = (
        <>
          <span style={{ paddingLeft: 'var(--space-4)' }}>
            {createElement(IconSalesforceBadge, { fill: 'white', height: 24, width: 24 }, null)}
          </span>
          <span style={{ paddingLeft: 'var(--space-4)', color: 'var(--primary-sf-1)' }}>
            {t.salesforce}
          </span>
        </>
      );
      break;
    default:
  }

  if (!source) {
    return null;
  }

  return (
    <div className="comment-source-container" style={containerStyle}>
      {t.via}
      {Element}
    </div>
  );
}
