import { Tooltip } from 'antd';
import React, { useLayoutEffect, useRef, useState } from 'react';

export type TextSizes = '12' | '14' | '16' | '20' | '24' | '30' | '38' | '46' | '56';

type FontWeights = 'regular' | 'bold' | 'semibold';

const EMPTY_STATE_CHAR = <>{'—'}</>;

interface BreakWord {
  wordBreak: React.CSSProperties['wordBreak'];
  maxWidth?: React.CSSProperties['maxWidth'];
}
interface TextProps {
  size?: TextSizes;
  style?: React.CSSProperties;
  color?: string;
  weight?: FontWeights;
  className?: string;
  children?: React.ReactNode;
  as?: keyof HTMLElementTagNameMap;
  truncate?: string | boolean;
  showToolTip?: boolean | ToolTipProps;
  breakWord?: BreakWord;
  textTransform?: React.CSSProperties['textTransform'];
}

const sizeToClassMap: Record<TextSizes, string> = {
  12: 'text-12',
  14: 'text-14',
  16: 'text-16',
  20: 'text-20',
  24: 'text-24',
  30: 'text-30',
  38: 'text-38',
  46: 'text-46',
  56: 'text-56',
};

const weightToStyleMap: Record<FontWeights, string> = {
  regular: 'var(--fs-regular)',
  bold: 'var(--fs-bold)',
  semibold: 'var(--fs-semibold)',
};

type ToolTipProps = React.ComponentProps<typeof Tooltip>;

const defaultTooltipProps: ToolTipProps = { placement: 'top', overlayStyle: { padding: 0 } };

function wrapWithTooltip(
  children: React.ReactNode,
  tooltipText: React.ReactNode,
  tooltipProps?: ToolTipProps
) {
  const finalTooltipProps = {
    ...defaultTooltipProps,
    ...tooltipProps,
  };

  return React.createElement(
    Tooltip,
    {
      title: tooltipText,
      ...finalTooltipProps,
    },
    children
  );
}

function getColor(color: string) {
  return color.startsWith('--') ? `var(${color})` : color;
}

function getValidChildren(children: React.ReactNode) {
  const isInvalidChildren =
    (typeof children === 'number' && isNaN(children)) || typeof children === 'undefined';

  if (isInvalidChildren) {
    return EMPTY_STATE_CHAR;
  }

  return children;
}

function isTruncated(el: HTMLElement) {
  return el.scrollWidth > el.clientWidth;
}

export function Texto(props: TextProps) {
  const {
    className = '',
    style,
    size = '14',
    children,
    as: tagName = 'span',
    color = 'var(--primary-10)',
    weight = 'regular',
    truncate,
    showToolTip,
    breakWord,
    textTransform,
  } = props;

  const elementRef = useRef<HTMLElement>();
  const [truncated, setTruncated] = useState(false);

  // runs after dom manipulations are done
  // necessary in-order to have element ref to calculate it's width
  useLayoutEffect(() => {
    const element = elementRef.current;

    if (element && isTruncated(element)) {
      setTruncated(true);
    }
  }, []);

  const textClass = sizeToClassMap[size];

  const truncateStyle = {
    overflow: truncate ? 'hidden' : undefined,
    textOverflow: truncate ? 'ellipsis' : undefined,
    whiteSpace: truncate ? 'nowrap' : undefined,
    display: typeof truncate === 'string' ? 'block' : undefined,
    maxWidth: typeof truncate === 'string' ? truncate : undefined,
  };

  const finalStyle = {
    ...truncateStyle,
    ...style,
    ...breakWord,
    textTransform,
    color: getColor(color),
    fontWeight: weightToStyleMap[weight],
  } as React.CSSProperties;

  const safeChildren = getValidChildren(children);

  const elementToRender = React.createElement(
    tagName,
    {
      ref: elementRef,
      style: finalStyle,
      className: `${textClass} ${className}`,
    },
    safeChildren
  );

  const shouldShowTooltip = showToolTip ? true : truncate ? truncated : false;

  if (shouldShowTooltip) {
    const tooltipProps = typeof showToolTip === 'object' ? showToolTip : {};
    return wrapWithTooltip(elementToRender, safeChildren, tooltipProps);
  }

  return elementToRender;
}
