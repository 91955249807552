import { BulkAction } from '@sinecycle/growcomponents';
import EscalationFormModal from 'components/Common/ActivitiesForm/Escalation/EscalationFormModal';
import { EscalationFormText } from 'components/Common/ActivitiesForm/text';
import {
  CustomActivityProps,
  FormItemNames,
  MutationFunctionProps,
} from 'components/Common/ActivitiesForm/types';
import {
  EscalationFormActivityProps,
  EscalationResponseSchema,
} from 'components/Common/hooks/type';
import {
  ActivitySchema,
  invoiceTransformSchema,
} from 'components/HigherOrderComponent/KeyActivitesContainer/ActivitySchema';
import useFormSubmitMutate from 'components/HigherOrderComponent/KeyActivitesContainer/hooks/useFormSubmitMutate';
import {
  ActivityMutationProps,
  MutationFunctionResponseType,
} from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import {
  invoiceItemsFn,
  lineItemsFn,
} from 'components/HigherOrderComponent/KeyActivitesContainer/utils';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { IInvoices } from 'store/customer/types';
import useOpen from 'util/hooks/useOpen';

export interface CreateEscalationProps extends BulkAction<IInvoices> {
  customerId?: number;
  customTransformer: (
    data: Record<FormItemNames, any>,
    customProps?: CustomActivityProps
  ) => Record<string, any>;
  invoices: IInvoices[];
}

export default function CreateEscalation(props: CreateEscalationProps) {
  const { selectedKey, customerId, customTransformer, invoices, onClose } = props;
  const onFormMutateFn = useFormSubmitMutate();
  const dateFormat = useSelector(dateFormatSelector);

  const { onClose: closeModal, open } = useOpen({
    open: Boolean(selectedKey === 'ESCALATE'),
  });

  function onMutateFn(data: MutationFunctionProps<ActivityMutationProps>) {
    return onFormMutateFn(data);
  }

  function onModalClose() {
    onClose?.();
    closeModal();
  }

  const invoiceItemsQueries = invoiceItemsFn<IInvoices>(customerId);
  const lineItemsQueries = lineItemsFn(customerId);

  return (
    <EscalationFormModal<EscalationFormActivityProps<IInvoices>>
      openModal={open}
      onClose={onModalClose}
      onMutateFn={onMutateFn as MutationFunctionResponseType<EscalationResponseSchema>}
      invoices={invoices}
      customTransformer={customTransformer}
      text={EscalationFormText}
      taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
      invoiceTransformSchema={invoiceTransformSchema}
      responseSchema={ActivitySchema.ESCALATION}
      dateFormat={dateFormat}
      {...lineItemsQueries}
      {...invoiceItemsQueries}
    />
  );
}
