import { TableProps } from '@sinecycle/growcomponents';

export function getColumnCustomConfig<T>(
  storageKey: string,
  sortIndex?: number,
  footer?: React.ReactNode,
  wrapperStyles?: React.CSSProperties
): TableProps<T>['enableColumnCustom'] {
  return {
    type: 'header-only',

    content: {
      preserve: {
        type: 'LOCAL',
        storageKey,
      },
      wrapperStyles,
      sortIndex,
      footer,
    },
  };
}
