import { DateRangeOption } from 'components/BaseComponents/DateRangeSelect/types';
import { useURLSearchParams } from 'hooks/use-search-params';
import { useCallback, useMemo } from 'react';
import { NavigateOptions } from 'react-router-dom';
import { ActivityType } from 'types/activities/activity-types';
import {
  ActivityStatus,
  InboxActivityMode,
  InboxActivitySearchParam,
} from 'types/api/inbox/activity';

interface ActivitySearchParams {
  [InboxActivitySearchParam.page]?: number;
  [InboxActivitySearchParam.pageSize]?: number;
  [InboxActivitySearchParam.activityMode]?: InboxActivityMode;
  [InboxActivitySearchParam.activityId]?: number;
  [InboxActivitySearchParam.selectedEntities]?: string;
  [InboxActivitySearchParam.status]?: ActivityStatus;
  [InboxActivitySearchParam.activityType]?: ActivityType;
  [InboxActivitySearchParam.dateRange]?: DateRangeOption;
  [InboxActivitySearchParam.fromDate]?: string;
  [InboxActivitySearchParam.toDate]?: string;
}

export function useActivitySearchParams() {
  const { getSearchParam, setSearchParams, removeSearchParams } = useURLSearchParams();

  const activitySearchParams = useMemo<ActivitySearchParams>(() => {
    const searchParamsValue: ActivitySearchParams = {};

    searchParamsValue[InboxActivitySearchParam.dateRange] = getSearchParam(
      InboxActivitySearchParam.dateRange
    );
    searchParamsValue[InboxActivitySearchParam.fromDate] = getSearchParam(
      InboxActivitySearchParam.fromDate
    );
    searchParamsValue[InboxActivitySearchParam.toDate] = getSearchParam(
      InboxActivitySearchParam.toDate
    );
    searchParamsValue[InboxActivitySearchParam.page] = getSearchParam(
      InboxActivitySearchParam.page
    );

    searchParamsValue[InboxActivitySearchParam.pageSize] = getSearchParam(
      InboxActivitySearchParam.pageSize
    );

    searchParamsValue[InboxActivitySearchParam.activityId] =
      getSearchParam(InboxActivitySearchParam.activityId) ?? undefined;

    searchParamsValue[InboxActivitySearchParam.selectedEntities] =
      getSearchParam(InboxActivitySearchParam.selectedEntities) ?? undefined;

    searchParamsValue[InboxActivitySearchParam.activityMode] =
      getSearchParam(InboxActivitySearchParam.activityMode) ?? undefined;
    searchParamsValue[InboxActivitySearchParam.status] = getSearchParam(
      InboxActivitySearchParam.status
    );
    searchParamsValue[InboxActivitySearchParam.activityType] = getSearchParam(
      InboxActivitySearchParam.activityType
    );

    return searchParamsValue;
  }, [getSearchParam]);

  const setActivitySearchParams = useCallback(
    (updatedValue: Partial<ActivitySearchParams>, options?: NavigateOptions) => {
      setSearchParams(updatedValue, options);
    },
    [setSearchParams]
  );

  const removeActivitySearchParams = useCallback(
    (keys: string[]) => {
      removeSearchParams(keys);
    },
    [removeSearchParams]
  );

  return {
    activitySearchParams,
    setActivitySearchParams,
    removeActivitySearchParams,
  };
}
