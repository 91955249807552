import { Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { InfoPopover } from 'components/Common/UtilityPopover';
import { PageLink } from 'lib/router';
import React from 'react';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';
import CurrentDueTile from '../CurrentDueTile';
import OverdueTile from '../OverdueTile';
import TotatalCustomersInvoices from '../TotalCustomersInvoices';
import { StyledTileCard } from '../styles';
import { dashboardTexts } from '../texts';
import { TotalBalance } from './style';
const t = {
  extraContent: 'Sum of all open invoice balances',
};

const componentTexts = dashboardTexts.totalBalance;

function ExtraInfo() {
  const Content = (
    <div className="extra-info">
      <GrowText>{t.extraContent}</GrowText>
    </div>
  );

  return (
    <div style={{ cursor: 'pointer' }}>
      <InfoPopover content={Content} />
    </div>
  );
}

interface TotalBalanceTileProps {
  totalOutstanding?: TotalOutstandingResponse;
  loading: boolean;
}

function TotalBalanceTile({ totalOutstanding, loading }: TotalBalanceTileProps) {
  const totalOutstandingAmount = totalOutstanding?.total_outstanding;
  const customersCount =
    totalOutstanding?.total_outstanding.open_invoices_balance_amount?.customer_count;
  const invoicesCount =
    totalOutstanding?.total_outstanding.open_invoices_balance_amount?.invoice_count;

  const InputLoaderJSX = <InputLoader active={loading} />;

  const TotalInvoiceBalance = totalOutstandingAmount?.open_invoices_balance_amount && (
    <PageLink appPage="INVOICE_LIST" state={{ addPrimaryPathAsChildPath: true }}>
      <AmountWithCurrencySplit
        type="multi"
        amountWithCurrencySplit={totalOutstandingAmount.open_invoices_balance_amount.amount}
        className="text-30 semi-bold"
        context={componentTexts.title}
        formatOptions={{ maximumFractionDigits: 0 }}
      />
    </PageLink>
  );

  const TotalBalanceInformation = (
    <TotalBalance>
      <Flex className="value amount" align="center" gap="--space-4">
        {loading && !totalOutstanding ? InputLoaderJSX : TotalInvoiceBalance}
      </Flex>
    </TotalBalance>
  );

  const TotalCustInvoiceInfo = (
    <TotatalCustomersInvoices
      loading={loading}
      customersCount={customersCount}
      invoicesCount={invoicesCount}
    />
  );

  const LabelJSX = (
    <div className="title">
      <Flex align="center" gap="var(--space-4)">
        <GrowText size={'var(--fs-16)'}>{componentTexts.title}</GrowText>
        <ExtraInfo />
      </Flex>
    </div>
  );

  return (
    <StyledTileCard>
      <div>
        <Row>
          <Col flex={'max-content 1 0'}>{LabelJSX}</Col>
        </Row>
        <Row>{TotalBalanceInformation}</Row>
        <Row style={{ marginTop: 'var(--space-12)' }}>
          <Col flex={'1 0 1'}>{TotalCustInvoiceInfo}</Col>
        </Row>
      </div>
      <div style={{ marginTop: 'var(--space-16)' }}>
        <Row>
          <Col flex={1}>
            <CurrentDueTile loading={loading} totalOutstanding={totalOutstanding} />
          </Col>
        </Row>
        <Row style={{ marginTop: 'var(--space-16)' }}>
          <Col flex={1}>
            <OverdueTile loading={loading} totalOutstanding={totalOutstanding} />
          </Col>
        </Row>
      </div>
    </StyledTileCard>
  );
}

export default React.memo(TotalBalanceTile);
