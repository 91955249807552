import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col } from 'antd';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { EmailTemplates, FolderType } from 'types/entities/email-templates';
import useOpen from 'util/hooks/useOpen';
import { InsertEmailTemplatesBody } from './Body';
import { StyledEmailTemplatesModal, TemplateButtonStyled } from './style';

const t = {
  template: 'Template',
  title: 'Insert Template',
};

interface InsertEmailTemplatesProps {
  onTemplateSelect?: (selectedTemplate: EmailTemplates) => void;
  validFolders?: FolderType[];
  confirmBeforeInsert: () => boolean;
}

function InsertEmailTemplates(props: InsertEmailTemplatesProps) {
  const { toggleOpen, open, onClose } = useOpen({
    open: false,
  });

  const ModalCloseIcon = <FontAwesomeIcon icon={['fal', 'times']} fontSize={12} />;
  const ModalCloseButton = <Button size="small" icon={ModalCloseIcon} onClick={onClose} />;

  const Title = (
    <Flex align="center" justify="space-between">
      <TypographyText style={{ color: 'var(--gray-9)' }}>{t.title}</TypographyText>
      {ModalCloseButton}
    </Flex>
  );

  return (
    <Col>
      <TemplateButtonStyled onClick={toggleOpen}>
        <FontAwesomeIcon icon={['far', 'envelopes-bulk']} />
        <TypographyText>{t.template}</TypographyText>
      </TemplateButtonStyled>
      <StyledEmailTemplatesModal
        width={770}
        centered
        onCancel={toggleOpen}
        open={open}
        title={Title}
        footer={null}
        closable={false}
        destroyOnClose
      >
        <InsertEmailTemplatesBody
          onTemplateInsert={props.onTemplateSelect}
          validFolders={props.validFolders}
          onCancel={toggleOpen}
          confirmBeforeInsert={props.confirmBeforeInsert}
        />
      </StyledEmailTemplatesModal>
    </Col>
  );
}

export default InsertEmailTemplates;
