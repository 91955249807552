import { CustomFieldEntityType } from 'types/entities/custom-field';
import { Period } from '../../PeriodPlanning/PeriodSelection/config';
import { CustomFieldDataType } from './../../../../../../types/entities/custom-field/index';

export enum InvoiceSegments {
  INVOICE_STATUS = 'invoice_status',
  COLLECTION_STATUS = 'customer_collection_status',
  ISSUE_DATE = 'issued_date',
  DUE_DATE = 'due_date',
  PTP_DATE = 'ptp_date',
  CUSTOM_FIELD = 'custom_fields',
}

export interface SelectOptions {
  key?: string;
  value: string | number;
  label: string;
  disabled?: boolean;
}
export interface SegmentDate {
  day: string;
  period: Period;
}

export interface FilterConditionGroups {
  id: string | number;
  value: string;
  label: string;
  active: boolean;
  key: InvoiceSegments;
  order?: number;
  default?: boolean;
  data_type?: CustomFieldDataType;
}

export interface CustomFieldConditionGroups extends FilterConditionGroups {
  options: Array<string | number>;
}

export interface CustomFieldPayload {
  entity: CustomFieldEntityType;
  value: CustomFieldConditionGroups[];
}

export type updateActions = 'ADD' | 'REMOVE';

export interface UpdateInvoiceSegmentValidationActions {
  type: updateActions;
  value: InvoiceSegments;
  order?: number;
}
export interface UpdateCustomFieldAndValidationActions
  extends Omit<UpdateInvoiceSegmentValidationActions, 'value'> {
  id: number;
  data_type?: CustomFieldDataType;
}
