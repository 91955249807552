import { map } from 'lodash';
import { useSearchUsersMutation } from 'queries/invoices';
import { EmailableType } from 'types/activities/email';
import { getFormatedRecentUsers } from '../Utils';
import { stakeHolderListAttributes } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import { Dispatch, SetStateAction } from 'react';

export default function useSearchUsers() {
  const { mutateAsync: searchUsers } = useSearchUsersMutation();
  async function handleSearch(
    searchTerm: string,
    setSearchOptions: Dispatch<SetStateAction<stakeHolderListAttributes[]>>
  ) {
    try {
      const data = await searchUsers(searchTerm);
      const recipientEmailingList = getFormatedRecentUsers(data);

      map(recipientEmailingList, (item) => {
        item.type = EmailableType.USER;
        return item;
      });

      setSearchOptions(recipientEmailingList);
    } catch {
      throw new Error();
    }
  }

  return handleSearch;
}
