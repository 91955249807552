import { Row } from 'antd';
import { ScrollableTabs } from 'components/BaseComponents/Tabs/ScrollableTabs';
import styled from 'styled-components';

export const DashboardContainer = styled.div`
  background-color: var(--gray-3);
  padding-right: 0;
  padding-left: 0;

  ul.amcharts-amexport-menu {
    li.amcharts-amexport-item {
      background-color: var(--gray-1);
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: none;
      margin: 0;
      border-radius: 0;
      text-align: center;
      .amcharts-amexport-label {
        color: #3a3a3a;
      }
      .amcharts-amexport-label.amcharts-amexport-label-level-0 {
        transform: rotate(90deg);
        font-size: var(--fs-20);
        font-weight: 600;
        margin-right: -1rem;
        color: #1976d2;
      }
      &.amcharts-amexport-item-level-0 {
        border: none;
        top: -50px;
        left: 20px;
      }
      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        &.amcharts-amexport-item-level-0 {
          border: none;
        }
      }
      &:hover {
        background-color: #1976d2;
      }
      &:hover > .amcharts-amexport-label {
        color: var(--gray-1);
      }
    }
  }
`;

export const CardsRow = styled(Row)`
  // overrides styles from Row's gutter prop
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
`;

export const CollectedUnpaidRowHeader = styled(Row)`
  margin-bottom: 3.15rem;
`;

export const StyledTabs = styled(ScrollableTabs)`
  .ant-tabs-nav {
    background: var(--gray-1);
    padding: 0 var(--space-24);
    box-shadow: var(--shadow-1);
    margin-bottom: 0;
  }
  .ant-tabs-tabpane {
    ${CardsRow}:not(:first-child) {
      margin-top: var(--space-24);
    }
  }
`;
