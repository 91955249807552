import { DataNode } from 'antd/lib/tree';
import { GrowText } from 'components/BaseComponents/Typography';
import { difference, find, includes } from 'lodash';
import { useMemo, useState } from 'react';
import { Unpacked } from 'types/utils/utils';
import { GenericSelectOption } from '../../types';
import { StyledTree } from './style';
import { TreeMultiSelectAdditionalProps, TreeMultiSelectOptions, TreeValue } from './type';
interface FilterInputTreeMultiSelectProps<T> {
  label?: string;
  name: string;
  value?: T[];
  options: Array<GenericSelectOption<T, 'children'>>;
  onChange?: (value: T[]) => void;
  additionalProps?: TreeMultiSelectAdditionalProps;
  disabled?: boolean;
}

const mapTreeOptionPredicate = (
  option: GenericSelectOption<any, 'children'>
): Unpacked<TreeMultiSelectOptions> => {
  return {
    key: option.value,
    title: option.label,
    children: option.valueObj?.children.map(mapTreeOptionPredicate),
  };
};

export function TreeMultiSelectFilter<T extends TreeValue>(
  props: FilterInputTreeMultiSelectProps<T>
) {
  const { options, onChange, value, disabled } = props;

  const [selectedKeys, setSelectedKeys] = useState<TreeValue>(value as unknown as TreeValue);
  function getOptions() {
    return options.map(mapTreeOptionPredicate);
  }

  const treeOptions = useMemo(getOptions, [options]);

  const expandedKeys = treeOptions.map((option) => option.key);

  /**
   * in Tree select we antd don't provide the default selection update of checkbox selection so we need a custom logic to
   * handle the selection update 
   * when you click on the parent option all the children will be selected at the same time when you remove it all the children will be removed
   * catch here while click on the particular children we need to handle the parent key and child key 
   
   */

  function updateSelectedValues(node: DataNode) {
    const currentSelectedOption =
      find(treeOptions, { key: node.key }) || find(treeOptions, { children: [{ key: node.key }] });

    const currentValues = {
      key: currentSelectedOption ? [currentSelectedOption.key] : [],
      children: currentSelectedOption?.children?.map((child) => child.key).filter(Boolean) ?? [],
    };

    const toggleSelectionForNonChildrenOption = (values: TreeValue) =>
      values.includes(node.key) ? values.filter((f) => f !== node.key) : [...values, node.key];
    const toggleSelectionForHasChildrenOption = (values: TreeValue) => {
      // Check if the node's key is already in the selected values
      if (includes(values, node.key)) {
        // If the node's key is in the selected values
        // Determine the keys to exclude based on whether the node's key matches the first key in currentValues
        const keysToExclude =
          node.key === currentValues.key[0]
            ? [...currentValues.children, ...currentValues.key]
            : [...currentValues.key, node.key];
        // Filter out keys that are already selected but not in keysToExclude
        return difference(values, keysToExclude);
      } else {
        // If the node's key is not in the selected values
        // Determine the keys to add based on whether the node's key matches the first key in currentValues
        const keysToAdd =
          node.key === currentValues.key[0]
            ? [...(currentValues.children ?? []), ...currentValues.key]
            : [node.key];
        // Add the keys to the selected values
        return [...values, ...keysToAdd];
      }
    };

    const newValues = currentValues.children.length
      ? toggleSelectionForHasChildrenOption
      : toggleSelectionForNonChildrenOption;

    setSelectedKeys(newValues);

    onChange?.(
      currentValues.children.length
        ? (toggleSelectionForHasChildrenOption(value as unknown as TreeValue) as unknown as T[])
        : (toggleSelectionForNonChildrenOption(value as unknown as TreeValue) as unknown as T[])
    );
  }

  return (
    <StyledTree
      design="border-design"
      defaultExpandAll
      expandAction={false}
      treeData={treeOptions}
      selectedKeys={selectedKeys}
      checkedKeys={selectedKeys}
      checkable
      height={250}
      defaultExpandParent
      onCheck={(value) => {
        onChange?.(value as unknown as T[]);
        setSelectedKeys(value as TreeValue);
      }}
      showIcon={false}
      expandedKeys={expandedKeys}
      disabled={disabled}
      virtual={false}
      titleRender={(node) => {
        return (
          <GrowText
            onClick={() => {
              updateSelectedValues(node as DataNode);
            }}
          >
            {(node as DataNode).title}
          </GrowText>
        );
      }}
    />
  );
}
