import { useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { PageList } from 'types/common/page-list';

export default function useCustomInfiniteQuery<T>(
  props: UseInfiniteQueryOptions<PageList<T, unknown>>
) {
  const { queryKey, enabled, queryFn, ...rest } = props;

  return useInfiniteQuery({
    queryKey,
    queryFn: queryFn,
    getNextPageParam: (lastPage) =>
      lastPage?.current_page && lastPage.current_page < lastPage.total_pages
        ? lastPage.current_page + 1
        : undefined,
    enabled,
    cacheTime: 0,
    ...rest,
  });
}
