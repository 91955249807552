import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';

export function CloseButton({ onClick, style, size }: ButtonProps) {
  return (
    <Button
      onClick={onClick}
      size={size}
      style={{
        width: '32px',
        height: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      <FontAwesomeIcon color="var(--gray-8)" icon={['fal', 'times']} />
    </Button>
  );
}
