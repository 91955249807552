import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import FileItems from 'components/Common/AssociatedFiles/FileItems';
import { AssociatedFile } from 'components/Common/AssociatedFiles/type';
import { StatusCell } from 'components/Common/StatusCell/StatusCell';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import produce from 'immer';
import { useGetDocumentTypesLists } from 'queries/documents/documents';
import { useCallback, useEffect, useState } from 'react';
import { DocumentTypeFilterKeys } from 'types/entities/documents';
import { getDocumentConfig } from '../predicate';

interface BulkDocumentsProps {
  leveL?: ActionableEntity;
  onChange?: (id: string | number, status: boolean) => void;
  selectedValues?: string[];
}

function BulkDocumentsComp(props: BulkDocumentsProps) {
  const { leveL, selectedValues } = props;

  const filter: DocumentTypeFilterKeys = {
    entity_types: leveL
      ? leveL === ActionableEntity.CUSTOMER
        ? ['CUSTOMER']
        : ['CUSTOMER', 'INVOICE']
      : ['CUSTOMER', 'INVOICE'],
  };
  const [documents, setDocuments] = useState<AssociatedFile[] | undefined>();
  const { data } = useGetDocumentTypesLists(filter);
  function handleEventCallBack(e: CustomEvent) {
    if (e.type === 'update-document') {
      setDocuments(e.detail);
    }
  }

  const eventCallBack = useCallback(handleEventCallBack, []);

  useEffect(() => {
    document.addEventListener('update-document', eventCallBack as any);

    return () => {
      document.removeEventListener('update-document', eventCallBack as any);
    };
  }, [eventCallBack]);

  useEffect(() => {
    const docs: AssociatedFile[] =
      data?.list
        .filter((f) => Boolean(f.enabled))
        .map((f) => {
          return {
            file_name: f.name,
            checked: selectedValues ? selectedValues.includes(f.id) : false,
            id: String(f.id),
            mode: 'BULK',
            level: leveL,
            entity: f.entity_type,
            typeId: String(f.id),
          };
        }) ?? [];
    setDocuments(docs);
    const event = new CustomEvent('add-document', { detail: docs });
    document.dispatchEvent(event);
  }, [data?.list, leveL, selectedValues]);

  return (
    <Flex direction="column" gap="var(--space-8)">
      <>
        {documents?.map((f) => {
          return (
            <FileItems
              key={f.id}
              file={f}
              onChange={(id, status) => {
                if (!id) return;

                const updatedFiles = produce(documents, (draft) => {
                  const isExist = draft.findIndex((file) => file.id === id);

                  draft[isExist].checked = status;
                  return draft;
                });
                setDocuments(updatedFiles);

                const event = new CustomEvent('add-document', { detail: updatedFiles });
                document.dispatchEvent(event);
              }}
              extra={
                f.entity ? (
                  <StatusCell
                    status={{
                      mode: 'Tags',
                      text: getDocumentConfig(f.entity).label,
                      style: getDocumentConfig(f.entity).style,
                    }}
                  />
                ) : undefined
              }
              fileIcon={
                <FontAwesomeIcon icon={['far', 'files']} fontSize={16} color="var(--blue-4)" />
              }
            />
          );
        })}
      </>
    </Flex>
  );
}

function BulkDocuments(props: BulkDocumentsProps) {
  const isDocumentTypeView = usePrivilegeStatus([ERolesAndPrivileges.VIEW_DOCUMENT_TYPE]);
  return (
    <HideWrapper hide={!isDocumentTypeView}>
      <BulkDocumentsComp {...props} />
    </HideWrapper>
  );
}

export default BulkDocuments;
