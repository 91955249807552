export enum EActions {
  FLAG_INVOICE = 'FLAG_INVOICE',
  UNFLAG_INVOICE = 'UNFLAG_INVOICE',
  PAUSE_STRATEGY = 'PAUSE_STRATEGY',
  RESUME_STRATEGY = 'RESUME_STRATEGY',
  DOWNLOAD_PDF = 'DOWNLOAD_PDF',
  EMAIL = 'EMAIL',
  NOTE = 'NOTE',
  ESCALATE = 'ESCALATE',
  TASK = 'TASK',
}

export const Eactions: Array<EActions> = [
  EActions.FLAG_INVOICE,
  EActions.PAUSE_STRATEGY,
  EActions.UNFLAG_INVOICE,
  EActions.RESUME_STRATEGY,
  EActions.DOWNLOAD_PDF,
  EActions.EMAIL,
];
