import { isUndefined, uniq } from 'lodash';
import { NUMBER_ONLY_REGEX, NUMBER_WITH_DECIMAL_REGEX } from 'util/regex';

export function customFieldFilterPredicate(
  customField: CustomFieldFilterValues<CustomFieldOperatorType>
) {
  const isUnassigned = customField.include_unassigned;
  let isOperandPresent = false;
  isOperandPresent =
    customField.operator.type === 'IN'
      ? (customField.operator.operands.value as CustomFiledInOperands['value']).length > 0
      : !isUndefined(customField.operator.operands.value);

  if (isUnassigned) {
    return true;
  }

  return isOperandPresent;
}

export function customFieldSearchPredicate(options: CustomFieldSearch): CustomFieldSearchOptions[] {
  return options.options.map((option) => {
    return {
      label: String(option),
      value: String(option),
    };
  });
}

export function customFieldFilterUnassigned(field: string | number) {
  return field !== -1;
}
export enum CustomFieldPage {
  CUSTOMER_SEGMENT = 'CUSTOMER_SEGMENT',
  AR_AGING = 'AR_AGING',
  EMAIL_PLACEHOLDER = 'EMAIL_PLACEHOLDER',
  ADMIN_DASHBOARD = 'ADMIN_DASHBOARD',
  INVOICE_LIST = 'INVOICE_LIST',
  INVOICE_SEGMENT = 'INVOICE_SEGMENT',
  ALL_CUSTOMERS = 'ALL_CUSTOMERS',
  COLLECTION_PLANNING = 'COLLECTION_PLANNING',
  INVOICE_LIST_UI = 'INVOICE_LIST_UI',
  WORKFLOW = 'WORKFLOW',
  ALL_CUSTOMERS_TABLE = 'ALL_CUSTOMERS_TABLE',
}

export enum CustomFieldEntityType {
  CUSTOMER = 'CUSTOMER',
  INVOICE = 'INVOICE',
  CREDIT_MEMO = 'CREDIT_MEMO',
  PAYMENT = 'PAYMENT',
  CONTACT = 'CONTACT',
  ADDRESS = 'ADDRESS',
}

export type DecimalFields = Extract<CustomFieldDataType, 'DECIMAL' | 'CURRENCY'>;

export type CustomFieldDataType =
  | 'STRING'
  | 'NUMBER'
  | 'DECIMAL'
  | 'DATETIME'
  | 'DATE'
  | 'BOOLEAN'
  | 'TEXT'
  | 'CURRENCY';

export enum CustomFieldUIType {
  DROPDOWN = 'DROPDOWN',
  TEXT_BOX = 'TEXT_BOX',
  TEXT_AREA = 'TEXT_AREA',
  DATEPICKER = 'DATEPICKER',
  NONE = 'NONE',
}

export type CustomFieldSearch = {
  options: Array<string>;
  custom_field_id: number;
};
export type CustomFieldSearchOptions = {
  label: string;
  value: string;
};
export type NumberFields = Extract<CustomFieldDataType, 'NUMBER' | 'DECIMAL' | 'CURRENCY'>;

export type NumberBasedField = Array<
  Extract<CustomFieldDataType, 'NUMBER' | 'DECIMAL' | 'CURRENCY'>
>;

export const validNumberBasedFields: NumberBasedField = uniq(['CURRENCY', 'DECIMAL', 'NUMBER']);

// currently we are not supporting new payload structure for absolute operand type. So, we introduced the operand type as DEPRECATED_ABSOLUTE. We'll take this as a enhancement.
type CustomFieldOperandType = 'DEPRECATED_ABSOLUTE' | 'RELATIVE';
export type CustomFieldOperatorType = 'GOE' | 'LOE' | 'EQ' | 'BTWN' | 'IN' | 'UNS';

export type CustomFieldOperatorOptions = {
  label: string;
  key: CustomFieldOperatorType;
  value: CustomFieldOperatorType;
};
type CustomFieldBetweenValues = {
  from: string;
  to: string;
};

export type CustomFieldInValues = Array<string | number>;
export type CustomFieldData = {
  id: number;
  value?: string;
  entityId: number;
};

export interface CustomFieldOptions {
  id: number;
  selected_options: string[];
}
export type CustomFiledInOperands = {
  type: CustomFieldOperandType;
  value: CustomFieldInValues;
};
export type CustomFiledBetweenOperands = {
  type: CustomFieldOperandType;
  value: CustomFieldBetweenValues;
};
export type CustomFiledNumberOperands = {
  type: CustomFieldOperandType;
  value: string;
};

export type CustomFiledOperands<T extends CustomFieldOperatorType> = T extends 'BTWN'
  ? CustomFiledBetweenOperands
  : T extends 'IN'
  ? CustomFiledInOperands
  : CustomFiledNumberOperands;

export type CustomFieldOption = string | number;

export interface CustomField {
  id: number;
  name: string;
  display_name: string;
  placeholder_name: string;
  data_type: CustomFieldDataType;
  entity_type: CustomFieldEntityType;
  ui_type: CustomFieldUIType;
  options: CustomFieldOption[];
  editable: boolean;
  data?: CustomFieldData;
}
export type UnFilteredCustomFiled = Array<Omit<CustomField, 'options'>>;

export type CustomFieldFilterValues<T extends CustomFieldOperatorType> = {
  id: number;
  operator: {
    type: T;
    operands: CustomFiledOperands<T>;
  };
  include_unassigned: boolean;
};

export interface CustomFieldDataResponse extends CustomField {}

export const customFieldOptions: CustomFieldOperatorOptions[] = [
  {
    key: 'GOE',
    label: 'Greater than or equals',
    value: 'GOE',
  },
  {
    key: 'LOE',
    label: 'Less than or equals',
    value: 'LOE',
  },
  {
    key: 'IN',
    label: 'Equals',
    value: 'IN',
  },
  {
    key: 'BTWN',
    label: 'Between',
    value: 'BTWN',
  },
];

export const validDecimalTypes: DecimalFields[] = ['CURRENCY', 'DECIMAL'];

export function getValidationRegex(dataType: DecimalFields) {
  return validDecimalTypes.includes(dataType as DecimalFields)
    ? NUMBER_WITH_DECIMAL_REGEX
    : NUMBER_ONLY_REGEX;
}
export function getValidationText(dataType: DecimalFields) {
  return validDecimalTypes.includes(dataType as DecimalFields)
    ? 'Value should be a number'
    : 'Decimal values are not allowed';
}
