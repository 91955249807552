import { blue, geekblue } from '@ant-design/colors';
import { Bar, Plot } from '@ant-design/plots';
import { BarOptions } from '@antv/g2plot';
import { Spin } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import EmptyChart, { getIsEmpty } from 'components/Common/Charts/EmptyChart';
import { PieChartEventData } from 'components/Common/Charts/Pie/type';
import { BarConfigProps, Datum } from 'components/Common/Charts/types';
import { FollowupStatusLabels } from 'constants/collection-activities/followup-statuses';
import { usePageNavigate } from 'lib/router';
import { sum } from 'lodash';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';
import { getPercentage } from 'util/number-formatter';
import ToolTip from './ToolTip';
import { ChartRef, FollowupStatusData } from './type';

function FollowUpStatusChart(props: Omit<DashboardComponentProps, 'filterParams'>) {
  const navigate = usePageNavigate();
  const { followupStatuses } = props;
  const chartRef = useRef<ChartRef>();
  const locale = useSelector(localeSelector);
  const colorPalette = useMemo(
    () => [
      blue[10],
      blue[8],
      blue[7],
      blue[6],
      blue[5],
      blue[4],
      blue[3],
      blue[2],
      blue[1],

      geekblue[1],
      geekblue[2],
      geekblue[3],
      geekblue[4],
      geekblue[5],
      geekblue[6],
      geekblue[7],
      geekblue[8],
      geekblue[10],
    ],
    []
  );

  function handleFollowUpStatusData(): FollowupStatusData[] {
    if (followupStatuses && Boolean(followupStatuses.length)) {
      const totalAmount = sum(followupStatuses?.map((status) => status.outstanding.amount.value));
      //sorting the values for the larger bar to come first.
      const sortedStatuses = followupStatuses
        .map((status, index) => ({
          value: status.outstanding.amount.value,
          annotedLabelXAxis: getPercentage(status.outstanding.amount.value, totalAmount),
          valueObject: status,
          totalAmount,
          ylabel: FollowupStatusLabels[status.followup_status],
        }))
        .sort((a, b) => b.value - a.value)
        .map((status, index) => ({
          ...status,
          type: colorPalette[index + 1],
        }));

      return sortedStatuses;
    }
    return [];
  }

  function handleOnReady(plot: Plot<BarOptions>) {
    plot.on('plot:click', handleBarClick);
  }

  const followUpStatusData: FollowupStatusData[] = useMemo(handleFollowUpStatusData, [
    followupStatuses,
    colorPalette,
  ]);

  function handleBarClick(event: PieChartEventData<FollowupStatusData>) {
    event.gEvent.stopPropagation();
    event.gEvent.preventDefault();
    if (event.data) {
      const status = event.data.data.valueObject.followup_status;
      const metaActivityData = new URLSearchParams({
        sort_col: 'TOTAL_DUE_AMOUNT',
        sort_by: 'DESC',
      });
      navigate({
        appPage: 'AR_AGING',
        searchParams: `${metaActivityData}&followup_status=["${status}"]`,
        state: {
          addPrimaryPathAsChildPath: true,
        },
      });
    }
  }

  //Commenting for future reference
  /* const annotationsArray = followUpStatusData?.map(({ ylabel, value, totalAmount }) => ({
    type: 'text',
    content: getPercentage(value, totalAmount),
    position: [ylabel, value + 200000],
    style: { fill: 'var(--gray-9)' },
  })); */

  const barConfig: Omit<BarConfigProps, 'data'> = {
    yField: 'ylabel',
    xField: 'value',
    tooltip: {
      customContent(title, content) {
        return (
          Boolean(content.length) && <ToolTip title={title} content={content} locale={locale} />
        );
      },
    },
    yAxis: {
      label: { formatter: (text) => (text.length > 20 ? `${text.slice(0, 12)}...` : text) },
    },
    seriesField: 'type',
    legend: false,
    color: ({ type }) => type,
    label: {
      position: 'right',
      style: { fill: 'var(--gray-9)' },
      offset: 5,
      formatter: (data: Datum) => {
        return data['annotedLabelXAxis'];
      },
    },
    xAxis: {
      label: {
        formatter: (value) => {
          const num = Number(value);
          if (num >= 1000) {
            return `${num / 1000}k`;
          }
          return value;
        },
      },
      position: 'left',

      grid: {
        line: {
          style: {
            opacity: 0,
            cursor: 'pointer',
          },
        },
      },
    },
    onReady: handleOnReady,
    style: {
      height: '600px',
      width: '100%',
      paddingRight: 'var(--space-8)',
    },
    supportCSSTransform: true,
    animation: {
      appear: {
        animation: 'scale-in-y',
      },
      enter: {
        animation: 'zoom-in',
      },
      leave: {
        animation: 'zoom-out',
      },
    },
  };

  const LoadingTemplate = <Spin />;

  const isEmpty = getIsEmpty(followUpStatusData);

  return (
    <Flex
      style={{ width: '100%', alignItems: 'center', height: '292px' }}
      gap="var(--space-4)"
      direction="row"
    >
      <EmptyChart isEmpty={isEmpty}>
        <div style={{ height: '280px', width: '100%', overflowY: 'scroll', overflowX: 'auto' }}>
          <Bar
            {...barConfig}
            data={followUpStatusData}
            chartRef={chartRef}
            loadingTemplate={LoadingTemplate}
          />
        </div>
      </EmptyChart>
    </Flex>
  );
}

export default FollowUpStatusChart;
