import { MinusCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputNumber, Select } from 'antd';
import { DatePicker } from 'components/BaseComponents/AntComponents';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { Item } from 'components/BaseComponents/Form';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowToolTip } from 'components/BaseComponents/Tooltip';
import { GrowText } from 'components/BaseComponents/Typography';
import { BaseCurrencySymbolProps } from 'components/Settings/Currency/type';
import { currencyList } from 'constants/currency';
import { styled } from 'lib/css-in-js';
import { useEffect, useMemo } from 'react';
import { AllAccountSourceProps } from 'types/api/currency-page-settings';
import { getCurrencySymbol } from 'util/number-formatter';
import {
  SelectedCurrenciesWithValueProps,
  useSelectedCurrencies,
} from '../../../CurrencyContextApi/ContextApi';
import ApplyHistoric from '../PastPeriod/ApplyHistoric';
import PastPeriodForm from '../PastPeriod/PastPeriodForm';
import {
  getCurrencyInfo,
  handleDisableDate,
  useGetInitialAvailableCurrencies,
} from './CurrentPeriodHelper';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
interface CurentPeriodFormProps extends BaseCurrencySymbolProps {
  exchangeRateData: AllAccountSourceProps;
}

const { RangePicker } = DatePicker;

const StyledRangePicker = styled(RangePicker)`
  width: 260px !important;
`;

const t = {
  choosePeriod: 'Choose Period',
  addCurrency: 'Currency',
  remove: 'Remove',
  setExchangeRate: 'Set exchange rate for each currency',
};

const rangeConfig = {
  rules: [{ type: 'array' as const, required: true, message: 'Please select date range!' }],
};

const PlusIcon = <FontAwesomeIcon icon={['far', 'plus-circle']} color="var(--purple-7)" />;

export const SetExchangeRate = styled(Flex)`
  padding: var(--space-16) var(--space-20);
  border-radius: var(--space-8);
  background: var(--gray-1);
  max-height: 488px;
  overflow: scroll;
  .ant-select {
    width: 220px !important;
  }
  .ant-input-number {
    width: 88px !important;
  }
`;

function CurrentPeriodForm(props: CurentPeriodFormProps) {
  const { exchangeRateData, baseCurrency } = props;
  const format = useSelector(dateFormatSelector);
  const { selectedCurrenciesWithValues, setSelectedCurrenciesWithValues, setUnsavedChanges } =
    useSelectedCurrencies();
  const { startDate, endDate, responseSelectCurrencyWithValue } =
    useGetInitialAvailableCurrencies(exchangeRateData);
  useEffect(() => {
    setSelectedCurrenciesWithValues(responseSelectCurrencyWithValue);
  }, [responseSelectCurrencyWithValue, setSelectedCurrenciesWithValues]);

  const dateRangeField = `dateRange`;
  const initialavailableCurrencies = currencyList.map((currency) => currency.code);

  const availableCurrencies = useMemo(() => {
    const selectedSources = selectedCurrenciesWithValues.map(
      (selectedCurrency) => selectedCurrency.source
    );
    return initialavailableCurrencies.filter((currency) => !selectedSources.includes(currency));
  }, [initialavailableCurrencies, selectedCurrenciesWithValues]);

  const addCurrency = () => {
    if (availableCurrencies.length <= 0) {
      return;
    }
    const selectedCurrencyCode = availableCurrencies[0];
    const updatedSelectedCurrencies = [...(selectedCurrenciesWithValues ?? [])];
    const newlySelectedCurrency = {
      source: selectedCurrencyCode,
      destination: baseCurrency ? baseCurrency : 'USD',
    };

    updatedSelectedCurrencies.push(newlySelectedCurrency);
    setSelectedCurrenciesWithValues(updatedSelectedCurrencies);
    setUnsavedChanges(true);
    return;
  };

  const handleCurrencySelectionChange = (newSelectedCurrency: string, index: number) => {
    const updatedSelectedCurrencies = [...selectedCurrenciesWithValues];
    updatedSelectedCurrencies[index] = {
      source: newSelectedCurrency,
      destination: baseCurrency ? baseCurrency : 'USD',
    };
    setSelectedCurrenciesWithValues(updatedSelectedCurrencies);
    setUnsavedChanges(true);
  };

  const handleValueChange = (index: number, newValue: number | null) => {
    if (!newValue) return;
    const updatedCurrencies = [...selectedCurrenciesWithValues];
    updatedCurrencies[index].exchangeRate = newValue;
    setSelectedCurrenciesWithValues(updatedCurrencies);
    setUnsavedChanges(true);
  };
  const removeCurrency = (index: number) => {
    const updatedSelectedCurrencies = [...selectedCurrenciesWithValues];
    updatedSelectedCurrencies.splice(index, 1);
    setSelectedCurrenciesWithValues(updatedSelectedCurrencies);
    setUnsavedChanges(true);
  };

  const currencyOptions = availableCurrencies.map((currency) => {
    const sourceCurrencyInfo = getCurrencyInfo(currency);
    const label = `1 ${sourceCurrencyInfo.name} ${sourceCurrencyInfo.code} (${sourceCurrencyInfo.symbol})`;

    return {
      value: `${currency}`,
      label,
      key: currency,
    };
  });
  return (
    <>
      <Flex justify="space-between">
        <Flex direction="column" gap="var(--space-4)">
          <GrowText size="var(--fs-14)">{t.choosePeriod}</GrowText>
          <Item name={dateRangeField} {...rangeConfig} style={{ margin: '0' }} required>
            <StyledRangePicker
              style={{ width: 'fit-content' }}
              format={format}
              defaultValue={[startDate ?? null, endDate ?? null]}
              disabledDate={handleDisableDate}
            />
          </Item>
        </Flex>
        <PastPeriodForm pastPeriodData={exchangeRateData} />
      </Flex>
      <ApplyHistoric />
      <SetExchangeRate direction="column" align="flex-start" gap="var(--space-12)">
        <GrowText size="var(--fs-14)" weight="var(--fs-semibold)">
          {t.setExchangeRate}
        </GrowText>

        {selectedCurrenciesWithValues.map(
          (selectedCurrency: SelectedCurrenciesWithValueProps, index: number) => {
            const sourceCurrencyInfo = getCurrencyInfo(selectedCurrency.source);
            const label = `1 ${sourceCurrencyInfo.name} ${sourceCurrencyInfo.code} (${sourceCurrencyInfo.symbol})`;

            return (
              <Flex align="center" gap="var(--space-12)" key={index}>
                <GrowToolTip title={t.remove} placement="left">
                  <MinusCircleOutlined
                    style={{ color: 'var(--red-6)' }}
                    onClick={() => removeCurrency(index)}
                  />
                </GrowToolTip>
                <Select
                  style={{ width: 200 }}
                  value={label}
                  onChange={(newSelectedCurrencyCode: string) =>
                    handleCurrencySelectionChange(newSelectedCurrencyCode, index)
                  }
                  options={currencyOptions}
                />
                =
                <InputNumber
                  required
                  value={selectedCurrency.exchangeRate}
                  onChange={(newValue) => handleValueChange(index, newValue)}
                />
                {selectedCurrency.destination} (
                {getCurrencySymbol(selectedCurrency.destination ?? baseCurrency)})
              </Flex>
            );
          }
        )}
        {availableCurrencies.length ? (
          <Button
            buttonProps={{
              children: <>{t.addCurrency}</>,
              onClick: addCurrency,
              icon: PlusIcon,
              type: 'link',
              style: { padding: '0', color: 'var(--primary-7)' },
            }}
          />
        ) : (
          <></>
        )}
      </SetExchangeRate>
    </>
  );
}
export default CurrentPeriodForm;
