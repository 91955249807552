import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { Komento } from 'components/CollectionActivities/Comment';
import { CommentSection } from 'components/CollectionActivities/Comment/CommentsList';
import { BaseSecondaryActivityDetailProps } from 'components/CollectionActivities/types';
import { Expansion } from 'components/Common/Expansion/Expansion';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { WrappedFormProvider } from 'components/HigherOrderComponent/GenerateForm';
import { handleCreateCustomEventCallBack } from 'components/Inbox/CollectionActivitiesV2/hooks/useActivityUpdation';
import { INVOICE_ACTIVITY_EVENTS } from 'events/invoice-activity';
import { dispatchAppEvent } from 'lib/pub-sub';
import { drop, take } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ActivityType } from 'types/activities/activity-types';
import {
  PTPSecActivity,
  PromiseToPay as PromiseToPayType,
  SecondaryActivityTypes,
  Slippage,
} from 'types/activities/promise-to-pay';
import { PerformedActionType } from 'types/api/inbox/activity';
import useOpen from 'util/hooks/useOpen';
import SlippageBlock from '../../PaymentSlip/PaymentSlipBlock';
import PaymentSlipForm from '../../PaymentSlip/PaymentSlipForm';
import { getPaymentSlipSchema } from '../../validation';
interface PTPSecondaryProps
  extends Omit<BaseSecondaryActivityDetailProps<PromiseToPayType>, 'comments' | 'propagateChange'> {
  promiseToPay: PromiseToPayType;
  isOpen?: boolean;
}

/* 
  The below type is used for both display of form as 
  well as in the list 
*/

const t = {
  comment: 'Comment',
  record_payment_slip: 'Record Payment Slip',
};

export function PTPCommentBlock(props: PTPSecondaryProps) {
  const { promiseToPay, activityId, customerId, isOpen } = props;
  const [showForm, setShowForm] = useState<string | null>(null);
  const [secondaryActivities, setSecondaryActivities] = useState<PTPSecActivity[]>(
    promiseToPay?.activities ?? []
  );
  const [updatedPayDate, setUpdatedPayDate] = useState<string | null>(null);
  const [updatedPayAmount, setUpdatedPayAmount] = useState<number | null>(null);
  const { open, toggleOpen } = useOpen({ open: false });

  const first = useMemo(() => take(secondaryActivities, 3), [secondaryActivities]);
  const rest = useMemo(() => drop(secondaryActivities, 3), [secondaryActivities]);

  useEffect(() => {
    if (isOpen) {
      setShowForm(SecondaryActivityTypes.COMMENT);
    }
  }, [isOpen]);

  const hideDivider = !rest.length && open;
  function onNewActivityAdded(newActivity: PTPSecActivity) {
    setSecondaryActivities([...secondaryActivities, newActivity]);
    setShowForm(null);
    dispatchAppEvent({
      type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_CARD_QUICK_ACTION_SAVE,
      payload: {
        actionName: 'PromiseToPay',
      },
    });
    handleCreateCustomEventCallBack('updating_query', {
      id: String(activityId),
      activity: ActivityType.PROMISE_TO_PAY,
      value: {
        action: PerformedActionType.COMMENT_ADDED,
      },
    });
  }

  function onNewSlipAdded(data: Slippage) {
    setUpdatedPayAmount(Number(data.amount));
    setUpdatedPayDate(data.to_date);
    if (!data.serial_no) {
      // Hack to calculate serial no when backend sends null
      const oldSlipsCounts = secondaryActivities.filter(
        (x) => x.type === SecondaryActivityTypes.PAYMENT_SLIP
      ).length;
      data.serial_no = oldSlipsCounts + 1;
    }
    onNewActivityAdded({ type: SecondaryActivityTypes.PAYMENT_SLIP, data });
  }

  function getSecActivityBlock(secActivity: PTPSecActivity, hideDivider: boolean) {
    return secActivity.type === SecondaryActivityTypes.COMMENT ? (
      <CommentSection
        key={secActivity.data.id}
        comment={secActivity.data}
        hideDivider={hideDivider}
      />
    ) : (
      <SlippageSection
        key={secActivity.data.id}
        currency={promiseToPay.currency}
        slippage={secActivity.data}
        hideDivider={hideDivider}
      />
    );
  }

  const SlippageForm = (
    <HideWrapper hide={showForm !== SecondaryActivityTypes.PAYMENT_SLIP}>
      <div
        style={{
          borderRadius: '6px',
          border: '1px solid var(--gray-4)',
          boxShadow: 'var(--shadow-2)',
        }}
      >
        <WrappedFormProvider
          schema={getPaymentSlipSchema({
            maxAmount: updatedPayAmount ?? promiseToPay.balance_amount ?? 0,
            minDate: updatedPayDate ?? promiseToPay.pay_date,
          })}
          shouldUnregister={true}
          mode={'all'}
        >
          <PaymentSlipForm
            onNewSlipAdded={onNewSlipAdded}
            currentPtpDate={updatedPayDate ?? promiseToPay.pay_date}
            amount={updatedPayAmount ?? promiseToPay.balance_amount ?? 0}
            activityId={activityId}
            currency={promiseToPay.currency}
            onCancel={() => {
              setShowForm(null);
            }}
          />
        </WrappedFormProvider>
      </div>
    </HideWrapper>
  );

  const CommentForm = (
    <HideWrapper hide={showForm !== SecondaryActivityTypes.COMMENT}>
      <Komento.Form
        referenceActivityId={activityId}
        newCommentCallBack={(newComment) =>
          onNewActivityAdded({ type: SecondaryActivityTypes.COMMENT, data: newComment })
        }
        customerId={customerId}
        isOpen={true}
        onCancel={() => setShowForm(null)}
        referenceType={ActivityType.PROMISE_TO_PAY}
      />
    </HideWrapper>
  );
  const Rest = (
    <HideWrapper hide={!rest.length}>
      <>
        {open ? (
          rest.map((f, index) => getSecActivityBlock(f, Boolean(index === rest.length - 1)))
        ) : (
          <Divider style={{ margin: 'var(--space-16) 0' }}>
            <Expansion onClick={toggleOpen} text={`${rest.length} more`} />
          </Divider>
        )}
      </>
    </HideWrapper>
  );
  const NoComments = <GrowText color="var(--gray-7)">No comments available</GrowText>;
  const Body = (
    <>
      {SlippageForm}
      {CommentForm}
      {secondaryActivities.length ? (
        <Flex direction="column" gap="var(--space-8)">
          {first.map((f, index) =>
            getSecActivityBlock(f, open ? hideDivider : Boolean(index === first.length - 1))
          )}
          {Rest}
        </Flex>
      ) : (
        NoComments
      )}
    </>
  );

  return (
    <Flex direction="column" gap="var(--space-24)">
      {!showForm && (
        <Flex gap="var(--space-16)">
          <Button
            icon={<FontAwesomeIcon icon={['fal', 'comment-alt-lines']} color="var(--primary-7)" />}
            onClick={() => setShowForm(SecondaryActivityTypes.COMMENT)}
            style={{ color: 'var(--primary-7)' }}
          >
            {t.comment}
          </Button>
          <Button
            icon={<FontAwesomeIcon icon={['fal', 'pen-swirl']} color="var(--primary-7)" />}
            onClick={() => setShowForm(SecondaryActivityTypes.PAYMENT_SLIP)}
            style={{ color: 'var(--primary-7)' }}
          >
            {t.record_payment_slip}
          </Button>
        </Flex>
      )}
      {Body}
    </Flex>
  );
}

interface SlippageSectionProps {
  slippage: Slippage;
  currency: string;
  hideDivider: boolean;
}
function SlippageSection(props: SlippageSectionProps) {
  const { slippage, currency, hideDivider } = props;
  return (
    <>
      <SlippageBlock currency={currency} slippage={slippage} />
      <HideWrapper hide={hideDivider}>
        <Divider style={{ margin: 'var(--space-16) 0' }} />
      </HideWrapper>
    </>
  );
}
