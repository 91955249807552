import { GenericRadioOptions } from 'components/BaseComponents/Radio/Antd/type';
import { DocumentLevel } from 'types/entities/documents';

export const entityLevelOptions: GenericRadioOptions<DocumentLevel> = [
  {
    label: 'Customer',
    value: 'CUSTOMER',
  },

  {
    label: 'Invoice',
    value: 'INVOICE',
  },
];
