import mixpanel, { Dict } from 'mixpanel-browser';
import { IS_LOCAL } from 'util/http-utils';
const AUTH_KEY = import.meta.env.VITE_MIXPANEL_KEY;

export const tracker = {
  init() {
    if (IS_LOCAL) return;

    AUTH_KEY && mixpanel.init(AUTH_KEY);
  },
  track<T>(event: string, eventAttrs: T) {
    if (IS_LOCAL) return;

    mixpanel.track(event, eventAttrs as unknown as Dict);
  },
  identify(email: string, peopleData: any) {
    if (IS_LOCAL) return;

    mixpanel.identify(email);
    mixpanel.people.set({
      $email: email,
      name: `${peopleData.first_name} ${peopleData.last_name}`,
      roles: peopleData.role,
      logSession: peopleData.logRocketSessionId,
      userId: peopleData.userId,
      accountId: peopleData.accountId,
    });
  },
};
