import BubbleMenu from '@tiptap/extension-bubble-menu';
import Color from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { AnyExtension, Node, mergeAttributes, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { CustomImage } from 'lib/RichText/Image/CustomImage';
import { CustomTable } from 'lib/RichText/Table/CustomTable';
import { CustomTableCell } from 'lib/RichText/Table/CustomTableCell';
import { CustomTableHeader } from 'lib/RichText/Table/CustomTableHeader';
import { CustomTableRow } from 'lib/RichText/Table/CustomTableRow';
import { CustomImageNode } from 'lib/RichText/TipTapEditor/custom-nodes/custom-image-node';
import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { CustomButtonNode } from '../custom-nodes/custom-button-node';
import { CustomEmailContent } from '../custom-nodes/custom-email-content';
import { CustomParagraph } from '../custom-nodes/custom-paragraph-node';
import { CustomPlaceholderNode } from '../custom-nodes/custom-placeholder-node';
import { CustomTextStyle } from '../custom-nodes/custom-text-style-node';
import FontSize from '../extensions/FontSize';
import { deSerialize, serialize } from '../utils/adapters';
interface useTipTapRTEProps {
  onChange?: (body: string | null) => void;
  placeholder?: string;
  defaultValue?: string | null;
  value?: string | null;
  CustomNodes?: ReturnType<(typeof Node)['create']>[];
  disableFormatOnPaste?: boolean;
  editable?: boolean;
  isSignature?: boolean;
}

const t = {
  placeHolderLabel: 'Insert',
};

export function useRichTextEditor(props: useTipTapRTEProps) {
  const { CustomNodes, disableFormatOnPaste = false, editable = true } = props;

  const extensions: AnyExtension[] = [
    StarterKit.configure({
      paragraph: false,
      blockquote: {
        HTMLAttributes: {
          style:
            'margin: 0px 0px 0px 0.8ex; border-left: 1px solid rgb(204, 204, 204); padding-left: 1ex;',
        },
      },
    }),
    CustomParagraph(disableFormatOnPaste),
    Underline,
    CustomTableRow,
    CustomTableHeader.configure({}),
    CustomTableCell.configure({}),
    //https://github.com/ueberdosis/tiptap/issues/2041
    CustomTable.configure({
      resizable: true,
    }),
    Link.configure({
      validate: (href) => /^https?:\/\//.test(href),
    }).extend({
      addAttributes() {
        return {
          ...this.parent?.(),
          style: {
            default: {},
            parseHTML: (element) => element.getAttribute('style') || '',
            renderHTML: (attributes) => {
              if (typeof attributes.style === 'object') {
                // Convert the style object to a CSS string
                return {
                  style: Object.entries(attributes.style)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join('; '),
                };
              }
              return { style: attributes.style };
            },
          },
        };
      },
      renderHTML(props) {
        return ['a', mergeAttributes(props.HTMLAttributes), 0];
      },
    }),
    Placeholder.configure({
      placeholder: props.placeholder ?? t.placeHolderLabel,
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    CustomTextStyle,
    FontFamily,
    Color.configure({
      types: ['textStyle'],
    }),
    Typography,
    BubbleMenu,
    Highlight.configure({ multicolor: true }),
    Highlight.extend({
      renderHTML({ HTMLAttributes }) {
        return ['span', mergeAttributes(HTMLAttributes), 0];
      },
    }),
    FontSize,
    CustomButtonNode,
    CustomPlaceholderNode,
    CustomImageNode(),
    CustomEmailContent,
    CustomImage.configure({ inline: true, allowBase64: true }),
  ];

  if (CustomNodes) {
    extensions.push(...CustomNodes);
  }

  const editor = useEditor({
    editable,
    extensions,
    onUpdate: ({ editor }) => {
      if (!props.onChange) {
        return;
      }
      const html = deSerialize(editor.getHTML());

      props.onChange(html ?? null);
      editor.commands.focus();
    },

    autofocus: 'start',
    parseOptions: {
      preserveWhitespace: 'full',
    },

    content: props.defaultValue ? serialize(props.defaultValue) : null,
  });

  /**  Replaces text entirely - Used for templates  */
  const replaceText = useCallback(
    (text?: string | null) => {
      text = serialize(text);
      editor
        ?.chain()
        .focus(undefined, { scrollIntoView: false })
        .setContent(text ?? null, true)
        .run();
    },
    [editor]
  );

  /**  Insert text at current cursor position - Used primarily for placeholders*/
  const insertText = useCallback(
    (text: string, atEnd?: boolean) => {
      editor &&
        editor
          .chain()
          .focus(atEnd ? 'end' : null, { scrollIntoView: false })
          .insertContent(text)
          .run();
    },
    [editor]
  );

  useEffect(() => {
    // Do nothing on Non-Initialized editor or No value is passed
    if (!editor || isEmpty(props.value)) return;

    // Replace Content if value is different
    if (serialize(props?.value) !== editor.getHTML()) {
      replaceText(props?.value);
    }
    // serialize(props?.value) !== editor.getHTML() && replaceText(props?.value);
  }, [props.value, replaceText, editor]);

  return { insertText, replaceText, editor };
}
