import { useMutation, useQuery } from '@tanstack/react-query';
import { FreshdeskTicketProps, createFreshDeskTicket } from 'lib/freshdesk';
import { UserItemForActivation } from 'pages/public/ActivateAccount/ActivateAccount';
import { useDispatch } from 'react-redux';
import {
  activateAccount,
  fetchAccountDetailsForActivation,
  forgotPassword,
  getPasswordConfig,
  loginUser,
  profileOfUser,
  resetPassword,
  validateHash,
} from 'services/authentication';
import { loginHandler } from 'store/authentication/authentication';

interface ResetPasswordProps {
  hash: string;
  password: string;
}

interface ActivateAccountProps {
  hash: string;
  userData: UserItemForActivation;
}

export interface LoginProps {
  email: string;
  password: string;
}

export function useValidateHashQuery(hash: string | undefined) {
  return useQuery({
    queryKey: ['validate-hash'],
    queryFn: () => validateHash(hash!),
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(hash),
  });
}

export function useAccountActivationDetailsQuery(hash: string | undefined) {
  return useQuery({
    queryKey: ['account-activation-details'],
    queryFn: () => fetchAccountDetailsForActivation(hash!),
    enabled: Boolean(hash),
  });
}

export function useActivateAccountMutation() {
  return useMutation({
    mutationFn: (props: ActivateAccountProps) => activateAccount(props.hash, props.userData),
  });
}

export function useResetPasswordMutation() {
  return useMutation({
    mutationFn: (props: ResetPasswordProps) => resetPassword(props.hash, props.password),
  });
}

export function useOnLoginMutation() {
  return useMutation({
    mutationFn: (props: LoginProps) => loginUser(props),
    onError: () => {},
  });
}

export function useFreshDeskTicketMutation() {
  return useMutation({
    mutationFn: (props: FreshdeskTicketProps) => createFreshDeskTicket(props),
    onError: () => {},
  });
}

export function usePasswordConfigQuery() {
  return useQuery({
    queryKey: ['password-config'],
    queryFn: () => getPasswordConfig(),
  });
}

export function useForgotPasswordMutation() {
  return useMutation({
    mutationFn: (props: { email: string }) => forgotPassword(props.email),
  });
}
export function useAuthCurrentUser() {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ['auth-current-user'],
    queryFn: () => profileOfUser(),
    onSuccess(data) {
      dispatch(loginHandler({ status: 200, user: data }));
    },
    onError: (error: any) => {
      if (error?.response?.status === 401)
        return dispatch(loginHandler({ status: error?.response.status }));
    },
  });
}
