import { InputNumber } from 'antd';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { getCurrencySymbol } from 'util/number-formatter';
import { ErrorMessageState, PTPAmountInputProps } from '../types';
import PTPTableError from './PTPTableError';

export default function PTPAmountInput(props: PTPAmountInputProps) {
  const { amount, form, record, customerCurrency } = props;
  const [errorMessage, setErrorMessage] = useState<ErrorMessageState>();
  const currencySymbol = customerCurrency ? getCurrencySymbol(customerCurrency) : '';

  useEffect(initialValidation, [amount, form, record]);

  function initialValidation() {
    if (amount === 0) {
      setError(`ptpAmount_${record?.id}`, 'PTP_GRATER');
    }
  }

  function setError(formKey: string, value?: string) {
    setErrorMessage((prevValue) => {
      if (!prevValue) {
        return { [formKey]: { errorType: value } };
      }
      return { ...prevValue, [formKey]: { errorType: value } };
    });
  }

  function amountValidator(value: number, maxAmount: number, formKey: string) {
    if (!record.amount) {
      setError(formKey, 'PTP_GRATER');
      return Promise.reject();
    }
    if (!value) {
      setError(formKey, 'EMPTY');
      return Promise.reject();
    }
    if (value < 0) {
      setError(formKey, 'LESS_THAN_ZERO');
      return Promise.reject();
    }
    if (value > maxAmount) {
      setError(formKey, 'PTP_GRATER');
      return Promise.reject();
    }
    setError(formKey, undefined);
    return Promise.resolve();
  }

  function onValueChange(invoiceId: number, value: number | null) {
    const currentInvoices = form.getFieldValue('ptpTable');
    const updatedInvoices = map(currentInvoices, (invoice) => {
      if (invoice.id === invoiceId) {
        return { ...invoice, amount: value };
      }
      return invoice;
    });

    form.setFieldValue('ptpTable', updatedInvoices);
  }

  return (
    <StyledFormItem
      name={`ptpAmount_${record?.id}`}
      rules={[
        {
          validator: async (_, value) =>
            await amountValidator(value, amount, `ptpAmount_${record?.id}`),
        },
      ]}
      initialValue={amount}
    >
      <InputNumber
        className="right-align-amount"
        style={{ padding: 'var(--space-8)', paddingRight: '0px' }}
        addonBefore={
          <div style={{ width: '15px' }}>
            {errorMessage && errorMessage[`ptpAmount_${record?.id}`]?.errorType ? (
              <PTPTableError error={errorMessage[`ptpAmount_${record?.id}`]} />
            ) : (
              currencySymbol
            )}
          </div>
        }
        controls={false}
        precision={2}
        min={0}
        disabled={amount <= 0}
        value={amount}
        onChange={(value) => onValueChange(record?.id, value)}
      />
    </StyledFormItem>
  );
}
