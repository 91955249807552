import { Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import ActivityCardActions from 'components/CollectionActivities/Common/Card/ActivityCardActions';
import ActivityCardHeader from 'components/CollectionActivities/Common/Card/ActivityCardHeader';
import { LatestActivityFooter } from 'components/CollectionActivities/Common/LatestActivity';
import { InboxActivityCardProps } from 'components/CollectionActivities/types';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';
import { CustomerAndInvoice } from 'components/Inbox/CollectionActivitiesV2/Activities/CustomerAndInvoice';
import { ToggleActivityReadButton } from 'components/Inbox/CollectionActivitiesV2/Activities/ToggleActivityReadButton';
import { ActivityType } from 'types/activities/activity-types';

interface NotesProps extends InboxActivityCardProps<ActivityType.NOTE> {}

const { cardLabel } = getActivityUnitItems(ActivityType.NOTE);

function CardHeader({ note, viewMode, activitiesMode, activityBg }: NotesProps) {
  const MarkAsRead = (
    <ToggleActivityReadButton
      activity={note}
      viewMode={viewMode}
      hideDot={activitiesMode}
      css={activityBg}
    />
  );

  const Title = (
    <GrowText color="var(--gray-8)" strong={!note.read}>
      {cardLabel}
    </GrowText>
  );
  const Heading = (
    <Flex gap="var(--space-4)" align="center">
      {MarkAsRead}
      {Title}
    </Flex>
  );
  return (
    <ActivityCardHeader activitiesMode={activitiesMode}>
      {Heading}
      <ActivityCardActions activity={note} />
    </ActivityCardHeader>
  );
}

function CardBody({ note, activitiesMode }: NotesProps) {
  const Body = (
    <GrowText ellipsis strong={!note.read} size="var(--fs-16)">
      {note.title}
    </GrowText>
  );

  const Details = (
    <CustomerAndInvoice
      customer={note.customer}
      invoices={note.invoices}
      activitiesMode={activitiesMode}
    />
  );

  return (
    <Row gutter={[0, 6]}>
      <Col span={24}> {Body}</Col>
      <Col span={24}> {Details}</Col>
    </Row>
  );
}

function CardFooter({ note }: NotesProps) {
  const { latest_activity } = note;

  return <LatestActivityFooter latestActivity={latest_activity} />;
}

const NoteCard = () => <></>;

NoteCard.Header = CardHeader;
NoteCard.Body = CardBody;
NoteCard.Footer = CardFooter;

export { NoteCard };
