import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import useOpen from 'util/hooks/useOpen';
import { DocumentFormElementProps, DocumentFormModalProps } from '../types';
import DocumentFormModal from './DocumentFormModal';

export default function DocumentButton<DocumentElements extends DocumentFormElementProps>(
  props: Omit<
    DocumentFormModalProps<DocumentElements>,
    'openModal' | 'form' | 'onClose' | 'onFormClose' | 'handleDisable'
  >
) {
  const { buttonConfig, disabled } = props;
  const { open, toggleOpen, onClose } = useOpen({ open: false });
  const iconColor = disabled
    ? undefined
    : buttonConfig?.color
    ? buttonConfig?.color
    : 'var(--primary-7)';
  return (
    <>
      <Button
        icon={
          !buttonConfig?.hideIcon ? (
            <FontAwesomeIcon icon={['far', 'file-arrow-up']} color={iconColor} />
          ) : null
        }
        onClick={toggleOpen}
        disabled={disabled}
        {...buttonConfig}
      >
        {!buttonConfig?.hidetext && (buttonConfig?.text || 'Add Document')}
      </Button>

      <DocumentFormModal<DocumentElements> openModal={open} onClose={onClose} {...props} />
    </>
  );
}
