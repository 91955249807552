import { Space } from 'antd';
import { Divider } from 'components/BaseComponents/Layout/Divider';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { SubsidiaryFilter } from 'components/Common/EntityFilter';
import { CustomFilterSelect } from 'components/Common/FilterComponents/ExtraFiltersSelect';
import {
  BusinessUnits,
  Regions,
} from 'components/Common/FilterComponents/FilterBlocks/CustomerFilters';
import { Filter, FilterGroup } from 'components/Common/FilterComponents/types';
import FilterStrip from 'components/FilterViews/Strips/FilterStrips';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { useRef } from 'react';
import { AccountConfigKey } from 'types/entities/account';
import { CustomFieldEntityType } from 'types/entities/custom-field';
import { CustomFieldFilters } from './CustomFieldFilters';
import { ReportingTagsFilters } from './ReportingTagFilter';
import { useDashboardFilter } from './hooks/useDashboardFilter';

type Props = ReturnType<typeof useDashboardFilter>;
export function DashboardFilterStrip(props: Props) {
  const { filterGroups, updateFilterGroups, filterValues, updateFilterValues } = props;
  const order = useRef<number>(0);
  const isSubsidiaryEnabled = useConfig(AccountConfigKey.SUBSIDIARY_ENABLED);

  function onSelect(filter: Filter, fitlerGroup: FilterGroup) {
    const filterId = filter.id,
      groupId = fitlerGroup.id;

    updateFilterGroups({
      type: 'UPDATE_FILTER',
      payload: { groupId, filterId, updatedValues: { active: true, order: order.current + 1 } },
    });
    order.current = order.current + 1;
  }

  function updateBussinessUnits(value: number[]) {
    updateFilterValues({ type: 'UPDATE_BUSINESSUNITS', payload: value });
  }
  function updateRegions(value: number[]) {
    updateFilterValues({ type: 'UPDATE_REGIONS', payload: value });
  }
  function updateSubsidaries(value: number[]) {
    updateFilterValues({ type: 'UPDATE_SUBSIDARIES', payload: value });
  }

  return (
    <FilterStrip style={{ minHeight: '62px', boxShadow: 'var(--shadow-1)' }}>
      <Flex align="center" gap="--space-8" wrap="wrap">
        <BusinessUnits value={filterValues.business_unit_ids} onSubmit={updateBussinessUnits} />
        <Regions value={filterValues.region_ids} onSubmit={updateRegions} />
        {isSubsidiaryEnabled && (
          <SubsidiaryFilter value={filterValues.subsidiary_ids} onSubmit={updateSubsidaries} />
        )}
        <ReportingTagsFilters {...props} />
        <CustomFieldFilters {...props} customFieldType={CustomFieldEntityType.CUSTOMER} />
        <CustomFieldFilters {...props} customFieldType={CustomFieldEntityType.INVOICE} />

        <Space style={{ order: order.current + 1 }}>
          <Divider size="32" />
          <CustomFilterSelect filterGroups={filterGroups} onSelect={onSelect} />
        </Space>
      </Flex>
    </FilterStrip>
  );
}
