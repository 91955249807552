/**
 * source: https://github.com/alice-health/ky-hooks-change-case
 * used to convert snake case to camel case or vice-versa
 * added first for usage in http request/response payload serialization
 */

import { NormalizedOptions } from 'ky';
import { camelCase, isObject, isPlainObject, snakeCase } from 'lodash';

type Modifier = (key: string) => string;

const uuidValidate = function (value: string) {
  const regex =
    /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;
  return regex.test(value);
};

const mapKeysDeep = function (obj: Record<string, unknown>, fn: Modifier): object {
  if (Array.isArray(obj)) {
    return obj.map((item) => {
      return mapKeysDeep(item, fn);
    });
  }

  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce((accumulator: Record<string, unknown>, key) => {
      const value = obj[key] as unknown;
      const newKey = uuidValidate(key) ? key : fn(key);
      accumulator[newKey] = isObject(value)
        ? mapKeysDeep(value as Record<string, unknown>, fn)
        : value;
      return accumulator;
    }, {});
  }

  return obj;
};

function createRequestModify(modifier: Modifier) {
  return async (request: Request, options: NormalizedOptions) => {
    if (options.body && typeof options.body === 'string') {
      const body = JSON.parse(options.body);
      const convertedBody = mapKeysDeep(body, modifier);
      return new Request(request, { body: JSON.stringify(convertedBody) });
    }
  };
}

function createResponseModify(modifier: Modifier) {
  return async (input: unknown, options: NormalizedOptions, response: Response) => {
    try {
      const body = await response.json();
      const convertedBody = mapKeysDeep(body, modifier);
      return new Response(JSON.stringify(convertedBody), response);
    } catch (e) {
      return;
    }
  };
}

export const requestToSnakeCase = createRequestModify(snakeCase);
export const responseToCamelCase = createResponseModify(camelCase);

// Extras (if needed)
// export const requestToCamelCase = createRequestModify(camelCase);
// export const responseToSnakeCase = createResponseModify(snakeCase);

// export const requestToKebabCase = createRequestModify(kebabCase);
// export const responseToKebabCase = createResponseModify(kebabCase);
