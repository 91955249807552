import { Flex } from 'components/BaseComponents/Layout/Flex';
import { notify } from 'components/BaseComponents/Notifications';
import { useCallback, useState } from 'react';
import { saveCurrentFilterView } from 'services/filter-view';
import { localStore, sessionStore } from 'util/browser-storage';
import useOpen from 'util/hooks/useOpen';
import { FilterViewSaveAction, FilterViewSaveAsAction } from './Body/FilterViewActions';
import FilterViewDelete from './Body/FilterViewDelete';
import FilterViewDesc from './Body/FilterViewDesc';
import FilterViewDropdown from './Body/FilterViewDropdown';
import FilterViewEdit from './Body/FilterViewEdit';
import FilterViewName from './Body/FilterViewInfo';
import FilterViewModal from './Body/FilterViewModal';
import { t } from './constants';
import useFilterComparison from './hooks/useFilterComparison';
import { FiledValues, FilterView, FilterViewsProps, MenuClickEvent } from './type';
import getMenuItems from './utils/getMenuItem';
import localStorageInitialization from './utils/localStorageInitalization';
import sessionStorageInitialization from './utils/sessionStorageInitalization';
import ClickOutsideWrapper from 'components/Common/ClickOutsideWrapper';

function FilterViews<T, M>(props: Readonly<FilterViewsProps<T, M>>) {
  const {
    totalSavedViews,
    entity,
    deleteFilterViewCallBack,
    saveFilterViewCallBack,
    updateFilterViewCallBack,
    filterType,
    saveAsFilterViewCallBack,
    updatedFilterValue,
    storageKey,
    currentView,
    hideIcons,
  } = props;

  //** Local states */
  const [currentFilterView, setCurrentFilterView] = useState<FilterView<M> | undefined>(
    currentViewInitialization
  );
  const [editFilter, setEditFilter] = useState(false);
  const [saveAsFilterView, setSaveAsFilterView] = useState(false);
  const [filterViewName, setFilterViewName] = useState<string | undefined>(
    currentFilterViewNameInitialization
  );
  const [filterViewDesc, setFilterViewDec] = useState<string | undefined>(
    currentFilterViewDescInitialization
  );
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [disable, setDisable] = useState(false);
  const { setItem: setToLocalStorage } = localStore;
  const { getItem: getFromSessionStorage, setItem: setToSessionStorage } = sessionStore;
  const modalTitle = saveAsFilterView ? t.save_as_filter_view : t.edit_filter_view;
  const isModalOpen = editFilter || saveAsFilterView;
  const { isDirty } = useFilterComparison({
    currentFilterView,
    totalSavedViews,
    filterType,
    updatedFilterValue,
  });

  localStorageInitialization({ storageKey, id: currentView.id });

  sessionStorageInitialization({ storageKey, id: currentView.id, updatedFilterValue });

  const { menuItems } = getMenuItems({ totalSavedViews });
  const { open, toggleOpen, onClose, handleOpenChange } = useOpen({ open: false });
  const handleClickOutside = useCallback(onClose, [onClose]);

  //** Local states */

  function currentViewInitialization() {
    return currentView;
  }

  function currentFilterViewNameInitialization() {
    return currentView.name;
  }
  function currentFilterViewDescInitialization() {
    return currentView.description;
  }
  //** handle functions */
  function handleEditFilter() {
    setEditFilter(true);
  }

  function handleSessionDelete(currentViewId?: string) {
    if (currentViewId && storageKey) {
      const sessionFilter = getFromSessionStorage(`${storageKey}_FILTERS`) as any;
      if (Object.keys(sessionFilter).length) {
        delete sessionFilter[`${currentViewId}`];
        setToSessionStorage(`${storageKey}_FILTERS`, sessionFilter);
      }
    }
  }

  function updateCurrentFilterView(id: string) {
    const updateCurrenFilterView = totalSavedViews.find((savedViews) => savedViews.id === id);
    handleFilterViewLocalStorage(id);
    setCurrentFilterView(updateCurrenFilterView);
    updateFilterViewCallBack && updateFilterViewCallBack(updateCurrenFilterView as unknown as T);
    setEditFilter(false);
    setFilterViewName(updateCurrenFilterView?.name);
    setFilterViewDec(updateCurrenFilterView?.description);
  }

  function handleDeleteResolve() {
    deleteFilterViewCallBack && deleteFilterViewCallBack(currentFilterView?.id as string);
    handleSessionDelete(currentFilterView?.id);
    updateFilterViewCallBack && updateFilterViewCallBack(totalSavedViews[0] as unknown as T);
    setCurrentFilterView(totalSavedViews[0]);
    storageKey && setToLocalStorage(storageKey, String(totalSavedViews[0].id));
    setFilterViewName(totalSavedViews[0].name);
    setFilterViewDec(totalSavedViews[0].description);
  }
  function handleDeleteSettled() {
    notify.success('filter deleted');
  }

  function handleFilterViewSaveAs(name: string, desc?: string) {
    if (currentFilterView) {
      const config = {
        [String(filterType)]: { ...updatedFilterValue },
        name: name ?? filterViewName,
        description: desc ?? filterViewDesc,
      };
      setConfirmLoading(true);
      setDisable(true);

      saveCurrentFilterView(config as any, entity ? entity : '')
        .then((data) => {
          saveAsFilterViewCallBack && saveAsFilterViewCallBack(data as unknown as T);
          setCurrentFilterView(data as any);
          handleFilterViewLocalStorage(currentFilterView.id);
          setFilterViewName(name);
          setFilterViewDec(desc);
        })
        .then(() => {
          setSaveAsFilterView(false);
          setConfirmLoading(false);
          notify.success('filter saved');
          setDisable(false);
        })
        .catch((e) => {
          notify.error(t.err_message.cant_save);
          console.log(e);
          setDisable(false);
          setConfirmLoading(false);
        });
    }
  }
  function handleFilterViewSave(name?: string, desc?: string) {
    if (currentFilterView) {
      const config = {
        [String(filterType)]: { ...updatedFilterValue },
        id: currentFilterView.id,
        name: name ?? filterViewName,
        description: desc ?? filterViewDesc,
      };
      setConfirmLoading(true);
      setDisable(true);
      saveCurrentFilterView(config as any, entity ? entity : '')
        .then((data) => {
          saveFilterViewCallBack && saveFilterViewCallBack(data as unknown as T);
          setCurrentFilterView(data as any);
          handleFilterViewLocalStorage(currentFilterView.id);
          setFilterViewName(name ?? currentFilterView.name);
          setFilterViewDec(desc ?? currentFilterView.description);
        })
        .then(() => {
          setEditFilter(false);
          setConfirmLoading(false);
          notify.success('filter saved');
          setDisable(false);
        })
        .catch((e) => {
          notify.error(t.err_message.cant_save);
          console.log(e);
          setConfirmLoading(false);
          setDisable(false);
        });
    }
  }

  function handleSaveAsCallBack() {
    setSaveAsFilterView(true);
  }
  function handleFilterViewSaveCallBack() {
    handleFilterViewSave();
  }

  function handleFilterViewSubmit(values: FiledValues) {
    if (saveAsFilterView) {
      handleFilterViewSaveAs(values.title, values.description);
    } else {
      handleFilterViewSave(values.title, values.description);
    }
  }

  function handleMenuClick(info: MenuClickEvent) {
    updateCurrentFilterView(info.key);
    toggleOpen();
  }
  const handleFilterViewLocalStorage = useCallback(
    (id: string) => {
      storageKey && setToLocalStorage(storageKey, String(id));
    },
    [setToLocalStorage, storageKey]
  );

  function handleModalCancel() {
    setEditFilter(false);
    setSaveAsFilterView(false);
  }

  const ViewName = (
    <FilterViewName
      viewName={filterViewName}
      hide={hideIcons}
      isDirty={isDirty}
      toggleOpen={toggleOpen}
    />
  );
  const ViewDesc = <FilterViewDesc viewDesc={filterViewDesc} toggleOpen={toggleOpen} />;

  const EditFilterView = (
    <FilterViewEdit
      hide={hideIcons}
      isDirty={!currentFilterView?.system_defined}
      onClickCallBack={handleEditFilter}
    />
  );
  const ActionIconsArea = (
    <>
      <FilterViewSaveAction
        hide={currentFilterView?.system_defined || hideIcons}
        isDirty={isDirty}
        onClick={handleFilterViewSaveCallBack}
      />
      <FilterViewSaveAsAction hide={hideIcons} isDirty={isDirty} onClick={handleSaveAsCallBack} />
    </>
  );

  const DeleteFilterView = (
    <FilterViewDelete
      hide={currentFilterView?.system_defined}
      viewId={currentFilterView?.id}
      entity={entity}
      resolveCallBack={handleDeleteResolve}
      settleCallBack={handleDeleteSettled}
    />
  );

  const ViewModal = (
    <FilterViewModal
      hide={isModalOpen}
      title={modalTitle}
      open={isModalOpen}
      onCancelCallBack={handleModalCancel}
      onOkCallBack={handleFilterViewSubmit}
      defaultValue={currentFilterView?.name}
      defaultValueDesc={filterViewDesc}
      loading={confirmLoading}
      totalSavedViews={totalSavedViews}
      saveAsMode={saveAsFilterView}
      disableOkButton={disable}
    />
  );

  const ViewDropDown = (
    <FilterViewDropdown
      id={currentFilterView?.id}
      onClickCallBack={handleMenuClick}
      items={menuItems}
      open={open}
      toggleOpen={toggleOpen}
      handleOpenChange={handleOpenChange}
    />
  );

  const ViewActions = (
    <Flex align="flex-end" gap="--space-16">
      {EditFilterView}
      {ActionIconsArea}
      {DeleteFilterView}
    </Flex>
  );
  const ViewHeader = (
    <Flex align="center" gap="--space-8">
      {ViewName}
      {ViewActions}
    </Flex>
  );
  const CurrentFilterView = (
    <Flex direction="column" gap="--space-2">
      {ViewHeader}
      {ViewDesc}
    </Flex>
  );
  /** JSX AREA **/

  return (
    <ClickOutsideWrapper handleClickOutside={handleClickOutside}>
      <Flex align="center" gap="--space-8">
        {ViewDropDown}
        {CurrentFilterView}
      </Flex>
      {ViewModal}
    </ClickOutsideWrapper>
  );
}

export { FilterViews };
