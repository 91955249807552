import { Plugin } from 'prosemirror-state';

export type UploadFn = (image: File) => Promise<string>;
export function CustomImagePlugin() {
  return new Plugin({
    props: {
      handlePaste(view, event, slice) {
        const hasFiles = event.clipboardData?.files?.length;

        if (!hasFiles) {
          return;
        }

        const clipboardTypes = event.clipboardData?.types;

        if (!clipboardTypes || clipboardTypes.length > 2) {
          return; // Prevent calling custom image node renderer, if it doesn't match the image types
        }
        const images = Array.from(event?.clipboardData?.files as ArrayLike<File>).filter((file) =>
          /image/i.test(file.type)
        );

        if (images.length === 0) {
          return;
        }

        event.preventDefault();

        const { schema } = view.state;

        images.forEach((image) => {
          const reader = new FileReader();

          reader.onload = (readerEvent) => {
            const node = schema.nodes.customImageCanvas.create({
              image,
            });

            const transaction = view.state.tr.replaceSelectionWith(node);
            view.dispatch(transaction);
          };
          reader.readAsDataURL(image);
        });
      },

      handleDOMEvents: {
        drop: (view, event) => {
          const hasFiles =
            event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length;

          if (!hasFiles) {
            return false;
          }

          const images = Array.from(event.dataTransfer?.files ?? []).filter((file) =>
            /image/i.test(file.type)
          );

          if (images.length === 0) {
            return false;
          }

          event.preventDefault();

          const { schema } = view.state;
          const coordinates = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });
          if (!coordinates) return false;

          images.forEach(async (image) => {
            const reader = new FileReader();

            if (image) {
              const node = schema.nodes.customImageCanvas.create({
                image: image,
              });

              const transaction = view.state.tr.insert(coordinates.pos, node);
              view.dispatch(transaction);
            } else {
              reader.onload = (readerEvent) => {
                const node = schema.nodes.image.create({
                  src: readerEvent.target?.result,
                });
                const transaction = view.state.tr.insert(coordinates.pos, node);
                view.dispatch(transaction);
              };
              reader.readAsDataURL(image);
            }
          });

          return true;
        },
      },
    },
  });
}
