import { CollectionStrategyAction } from 'components/CustomerDetails/CustomerDetailsHeaderNew/types';
import { Dayjs } from 'dayjs';
import {
  IClosedInvoicesParams,
  ICreditAdjustmentsParams,
  IInvoices,
  IInvoicesQueryParams,
  IPaymentStatmentsQueryParams,
  StatementsResponse,
} from 'store/customer/types';
import { PaymentListData } from 'store/payments/type';
import { CreditMemo } from 'types/activities/credit-memo';
import { Emailable } from 'types/activities/email';
import { PaginationResponse } from 'types/api/common';
import { CustomerBulkActionParams, UpdateCustomerInformationParams } from 'types/api/customer';
import { CustomerInternalContactProps } from 'types/api/internal-contact';
import { Contact } from 'types/entities/contact';
import { CustomFieldDataResponse } from 'types/entities/custom-field';
import {
  BaseCustomer,
  CustomerDropDownDTO,
  CustomerWorkflowMapping,
} from 'types/entities/customer';
import { User, UserStatus } from 'types/entities/user';
import { pruneObject } from 'util/json-utils';
import ky from './ky';

const dummyUserData = {
  first_name: 'NA',
  last_name: '',
  id: -1,
  email: '',
  sender_email: '',
  deleted: '',
  business_units: [],
  roles: [],
  regions: [],
  status: UserStatus.NOT_ACTIVATED,
} as User;

const dummyContactData = {
  id: -1,
  email: '',
  phone: '',
  type: 'PRIMARY_CONTACT',
  designation: '',
  first_name: 'NA',
  last_name: '',
} as Contact;

const baseUrl = 'customers';

export interface CustomerDropdownParams {
  text: string;
  page: number;
  size: number;
  customer_ids: string;
}

export interface WorkflowStatusUpdate {
  mapping_enabled: boolean;
  workflow_id: number;
  customer_id: number;
}

export interface CustomerRegionUpdateProps {
  customerId?: string;
  regionId: number;
}

export async function getCustomerDetail(id: number) {
  const customerDetail = (await await ky.get(`${baseUrl}/${id}`).json()) as BaseCustomer;
  customerDetail.health_score = customerDetail.health_score ?? customerDetail.risk_score;
  return customerDetail;
}

export async function getCustomersOpenInvoices(id: number, queryParams: IInvoicesQueryParams) {
  const cleanedSearchParams = pruneObject(queryParams);
  const response: StatementsResponse<IInvoices> = await ky
    .post(`${baseUrl}/${id}/invoices/open`, { json: cleanedSearchParams })
    .json();

  response.list.forEach((item) => {
    if (!item.created_by || !item.created_by.first_name) {
      item.created_by = dummyUserData;
    }
    if (!item.closed_by || !item.closed_by.first_name) {
      item.closed_by = dummyUserData;
    }
  });

  return response;
}

export async function getCustomersDraftInvoices(id: number, queryParams: IInvoicesQueryParams) {
  const cleanedSearchParams = pruneObject(queryParams);
  const response: StatementsResponse<IInvoices> = await ky
    .post(`${baseUrl}/${id}/invoices/draft`, { json: cleanedSearchParams })
    .json();

  response.list.forEach((item) => {
    if (!item.created_by || !item.created_by.first_name) {
      item.created_by = dummyUserData;
    }
    if (!item.closed_by || !item.closed_by.first_name) {
      item.closed_by = dummyUserData;
    }
  });

  return response;
}

export async function getCustomersPaymentStatements(
  id: number,
  params: IPaymentStatmentsQueryParams
) {
  const searchParams = {
    ...params,
  };

  const response: PaymentListData = await ky
    .get(`${baseUrl}/${id}/payments`, { searchParams })
    .json();

  response.list.forEach((item) => {
    if (!item.reconciled_by) {
      item.reconciled_by = dummyUserData;
    } else if (!item.reconciled_by.first_name) {
      item.reconciled_by = dummyUserData;
    }
  });

  return response;
}

export async function getCustomersCreditsAdjustments(id: number, params: ICreditAdjustmentsParams) {
  const searchParams = pruneObject(params);

  const response: PaginationResponse<CreditMemo> = await ky
    .get(`${baseUrl}/${id}/credit-memos`, { searchParams: { ...searchParams } })
    .json();

  response.list.forEach((item) => {
    if (!item.created_by || !item.created_by.first_name) {
      item.created_by = dummyUserData;
    }
    if (!item.closed_by || !item.closed_by.first_name) {
      item.closed_by = dummyContactData;
    }
    if (!item.memo_created_by || !item.memo_created_by.first_name) {
      item.memo_created_by = dummyContactData;
    }
  });

  return response;
}

export async function getCustomersClosedInvoices(id: number, params: IClosedInvoicesParams) {
  const cleanedSearchParams = pruneObject(params);
  const response: StatementsResponse<IInvoices> = await ky
    .post(`${baseUrl}/${id}/invoices/closed`, { json: cleanedSearchParams })
    .json();

  response.list.forEach((item) => {
    if (!item.created_by || !item.created_by.first_name) {
      item.created_by = dummyUserData;
    }
    if (!item.closed_by || !item.closed_by.first_name) {
      item.closed_by = dummyUserData;
    }
  });

  return response;
}

export async function updateCustomerInformation(
  id: number,
  params: UpdateCustomerInformationParams
) {
  return await ky.put(`${baseUrl}/${id}`, { json: params });
}

export async function modifyCollectionStrategy(
  customer_ids: number[],
  actionType: CollectionStrategyAction,
  collection_strategy_id: number
) {
  if (customer_ids) {
    const data =
      customer_ids.length === 1
        ? { collection_strategy_id }
        : { collection_strategy_id, customer_ids };
    if (customer_ids.length === 1) {
      return await ky.post(`${baseUrl}/${customer_ids[0]}/${actionType}`, { json: data }).json();
    }
    return await ky.post(`${baseUrl}/${actionType}`, { json: data }).json();
  }
}

export async function getCustomerEmailRecepients(customerId?: number) {
  return (await ky.get(`${baseUrl}/${customerId}/email_recepients`).json()) as Emailable[];
}

export async function updateCustomerActions(
  bulkParams: CustomerBulkActionParams
): Promise<unknown> {
  return await ky.put(`${baseUrl}/aggregate_update`, { json: bulkParams });
}

export async function getCustomerCustomFieldValues(customerId: number) {
  return (await ky
    .get(`${baseUrl}/${customerId}/custom-fields`)
    .json()) as CustomFieldDataResponse[];
}

export async function setCollectionStrategyV2(customerId: number, strategyId?: number) {
  const json = { strategy_id: strategyId ?? null };
  return await ky.put(`${baseUrl}/${customerId}/strategies`, { json });
}

export async function modifyCollectionStrategyV2(customer_ids: number[], strategy_id?: number) {
  const json = {
    strategy_id: strategy_id ?? null,
    customer_ids: customer_ids,
  };

  return await ky.put(`${baseUrl}/strategies`, { json });
}

export async function downloadCustomerStatement(id: number, from: Dayjs, to: Dayjs) {
  const DATE_FORMAT = 'YYYY-MM-DD';
  const searchParams = {
    from: from.format(DATE_FORMAT),
    to: to.format(DATE_FORMAT),
  };

  return await ky.get(`${baseUrl}/${id}/statements`, { searchParams });
}

export async function getCustomerInternalContacts(id: number) {
  const url = `customers/${id}/internal-contacts`;
  return (await ky.get(url).json()) as CustomerInternalContactProps[];
}

export async function getCustomersDropdownList(params: Partial<CustomerDropdownParams>) {
  return (await ky.get(`${baseUrl}/drop-down/search`, { searchParams: { ...params } })).json<
    CustomerDropDownDTO[]
  >();
}

export async function getCustomerWorkflowMapping(customerId: number) {
  return await ky
    .get(`workflow/mapping?customer_id=${customerId}`)
    .json<CustomerWorkflowMapping[]>();
}

export async function updateWorkflowStatus(props: WorkflowStatusUpdate) {
  return await ky.put(`workflow/mapping`, { json: props });
}

export async function updateCustomerRegion(props: CustomerRegionUpdateProps) {
  return await ky.patch(`${baseUrl}/${props?.customerId}/regions/${props.regionId}`).json();
}

export async function getCreditTerms() {
  return await ky.get(`credit-term`).json<string[]>();
}
