import { Attachments } from 'store/email/types';
import { CustomField } from './custom-field';

export enum FolderType {
  SINGLE_CUSTOMER = 'SINGLE_CUSTOMER',
  SINGLE_INVOICE = 'SINGLE_INVOICE',
  MULTIPLE_INVOICE = 'MULTIPLE_INVOICE',
  OTHERS = 'OTHERS',
}

export interface EmailTemplateFoldersProps {
  folders: Folders[];
  custom_fields: CustomField[];
  templates: EmailTemplates[];
  placeholders: {
    [FolderType.SINGLE_CUSTOMER]: PlaceholderProps[];
    [FolderType.SINGLE_INVOICE]: PlaceholderProps[];
    [FolderType.MULTIPLE_INVOICE]: PlaceholderProps[];
    [FolderType.OTHERS]: PlaceholderProps[];
  };
}

export interface Folders {
  id: number;
  name: string;
  email_template_type: FolderType;
  description: string;
  templateCount: number;
}

export interface EmailTemplates {
  id: number;
  deleted: boolean;
  title: string;
  description: string;
  subject: string;
  body: string;
  template_folder_id: number;
  copy_count: number;
  updated_at: string;
  parent_template_id?: number;
  updated_by: EmailTemplateActivity;
  attachments: Attachments;
}

export interface EmailTemplateActivity {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  deleted: boolean;
}

export interface PlaceholderProps {
  placeholder: string;
  label: string;
  description: string;
  section: string;
  subSection: string;
  category?: string;
  displayName?: string;
}

export type RemoveEmailTemplateProps = {
  id: number;
  folderId?: number;
};
