import { Radio, RadioChangeEvent, Space } from 'antd';
import { Dayjs } from 'dayjs';
import { head, nth } from 'lodash';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { RelativeDateRange } from 'util/relative-date-range';
import { DateRangePickerComponent } from '../Calendar';
import { DateRangeDropdownOption, DateRangeOption, DateRangeString } from './types';
import { getDateRange, getDefaultOptions } from './utils';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

const t = {
  selectCustomRange: 'Select Custom Range',
  dateRange: 'Date Range',
  selectDateRange: 'Select Date Range',
};

interface DateRangeSelectInputProps {
  value?: DateRangeString;
  onChange?: (value: DateRangeString) => void;
  options?: DateRangeDropdownOption[];
  showCustomRangeSelect?: boolean;
  format?: string;
}

export function DateRangeSelectInput(props: DateRangeSelectInputProps) {
  const {
    value,
    format: dateFormat = DEFAULT_DATE_FORMAT,
    options: dateRangeOptions = getDefaultOptions(),
    showCustomRangeSelect = true,
  } = props;

  const format = useSelector(dateFormatSelector);
  function onDateRangeTypeChange(e: RadioChangeEvent) {
    const selectedRange = e.target.value as DateRangeOption;

    if (selectedRange !== 'CUSTOM_RANGE') {
      const newValue = getDateRange({ type: selectedRange }, dateRangeOptions, dateFormat);

      props.onChange && props.onChange(newValue);
    } else {
      // using THIS_WEEK as initial value for custom range
      const newValue = getDateRange(
        { type: RelativeDateRange.THIS_WEEK },
        dateRangeOptions,
        dateFormat
      );
      props.onChange && props.onChange({ ...newValue, type: 'CUSTOM_RANGE' });
    }
  }

  function onCustomDateRangeChange(dateRange: Dayjs[], [from, to]: string[]) {
    const fromValue = head(dateRange)?.format(DEFAULT_DATE_FORMAT) ?? '';
    const toValue = nth(dateRange, 1)?.format(DEFAULT_DATE_FORMAT) ?? '';
    const newValue: DateRangeString = {
      type: 'CUSTOM_RANGE',
      from: fromValue,
      to: toValue,
    };

    props.onChange && props.onChange(newValue);
  }

  return (
    <Radio.Group value={value?.type} onChange={onDateRangeTypeChange}>
      <Space direction="vertical">
        {dateRangeOptions.map((option) => (
          <Radio value={option.id} key={option.id}>
            {option.label}
          </Radio>
        ))}
        {showCustomRangeSelect && (
          <Radio value="CUSTOM_RANGE">
            <span>{t.selectCustomRange}</span>
            {value && value.type === 'CUSTOM_RANGE' && (
              <DateRangePickerComponent
                format={format}
                from={value.from}
                to={value.to}
                onChange={onCustomDateRangeChange}
                size="middle"
              />
            )}
          </Radio>
        )}
      </Space>
    </Radio.Group>
  );
}
