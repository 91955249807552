import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { GrowText } from 'components/BaseComponents/Typography';
import { activityText } from '../text';
import { PtpErrorIconProps } from '../types';

export default function PTPTableError(props: PtpErrorIconProps) {
  const { error } = props;
  if (!error) return <></>;

  const errorType = error?.errorType;

  const ErrorTooltip = (
    <div>
      <GrowText color="var(--red-3)">Error(s)</GrowText>
      <br />
      {errorType === 'EMPTY' ? activityText.error_type_empty : null}
      {errorType === 'LESS_THAN_ZERO' ? activityText.error_type_less_than_zero : null}
      {errorType === 'PTP_GRATER' ? activityText.error_type_greater : null}
      <br />
      {(errorType === 'LESS_THAN_ZERO' || errorType === 'PTP_GRATER') && (
        <ul style={{ listStyle: 'initial' }}>
          {activityText.error_help.map((helptext) => (
            <li key={helptext}>{helptext}</li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <GrowText size="var(--space-12)" color="var(--red-8)">
      <Tooltip title={ErrorTooltip}>
        <FontAwesomeIcon icon={['fal', 'info-circle']} />
      </Tooltip>
    </GrowText>
  );
}
