import { useEffect } from 'react';
import { TFilterViews } from 'store/aging/types';
import { DraftInvoicesListFilters } from 'store/invoice/type';
import { DraftInvoiceFilterStrip } from './FilterComponentsContainer';
import { useDraftInvoiceListFilter } from './hooks/useDraftInvoiceListFilter';
import { FilterValues } from './reducers/filterValuesReducer';

interface DraftInvoiceFilterHeaderProps {
  currentFilter: TFilterViews;
  setCurrentFilter: React.Dispatch<React.SetStateAction<TFilterViews>>;
}

export function DraftInvoiceFilterHeader(props: DraftInvoiceFilterHeaderProps) {
  const { currentFilter, setCurrentFilter } = props;

  /** Filter start*/
  const draftInvoiceListFilterStripProps = useDraftInvoiceListFilter();

  const { initializeFilterValuesAndFilterGroups, filterValues } = draftInvoiceListFilterStripProps;

  const FilterStrip = <DraftInvoiceFilterStrip {...draftInvoiceListFilterStripProps} />;
  /**Filter end */

  useEffect(() => {
    if (Object.keys(currentFilter).length) {
      initializeFilterValuesAndFilterGroups(currentFilter as unknown as FilterValues);
    }
  }, [currentFilter, initializeFilterValuesAndFilterGroups]);

  useEffect(() => {
    function getFilteredData(invoice_filter: DraftInvoicesListFilters) {
      setCurrentFilter(invoice_filter);
    }

    getFilteredData(filterValues as unknown as DraftInvoicesListFilters);
  }, [filterValues, setCurrentFilter]);

  return FilterStrip;
}
