import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { handleCreateCustomEventCallBack } from 'components/Inbox/CollectionActivitiesV2/hooks/useActivityUpdation';
import { useCallback } from 'react';
import { ActivityType } from 'types/activities/activity-types';
import { Commento } from 'types/activities/comment';
import { PerformedActionType } from 'types/api/inbox/activity';
import { Komento } from '.';
import { useComments } from '../hooks/useComments';
import { CommentsList } from './CommentsList';

interface CommentFormBlockProps {
  activityId: number;
  activityType: ActivityType;
  customerId?: number;
  onNewCommentAdded: (newComment: Commento) => void;
  isOpen?: boolean;
}

export function CommentFormBlock(props: CommentFormBlockProps) {
  const { activityId, activityType, customerId, onNewCommentAdded, isOpen } = props;

  return (
    <Komento.Form
      referenceActivityId={activityId}
      newCommentCallBack={onNewCommentAdded}
      customerId={customerId as number}
      referenceType={activityType}
      isOpen={isOpen}
    />
  );
}

interface CommentsBlockProps {
  activityId: number;
  activityType: ActivityType;
  customerId?: number;
  onNewCommentAdded?: (newCommentsList: Commento[]) => void;
  comments: Commento[];
  context?: string;
  isOpen?: boolean;
}

export function CommentsBlock(props: CommentsBlockProps) {
  const { activityId, activityType, customerId, onNewCommentAdded, isOpen } = props;

  const { comments, addNewComment } = useComments(props.comments);

  const handleNewCommentAdded = useCallback(
    (newCommentsList: Commento[]) => {
      onNewCommentAdded?.(newCommentsList);
      handleCreateCustomEventCallBack('updating_query', {
        id: activityId,
        activity: activityType,
        value: {
          action: PerformedActionType.COMMENT_ADDED,
        },
      });
    },
    [activityId, activityType, onNewCommentAdded]
  );

  const CommentArea = (
    <Flex direction="column" gap="var(--space-24)">
      <CommentFormBlock
        activityId={activityId}
        customerId={customerId}
        activityType={activityType}
        onNewCommentAdded={(newComment) => addNewComment(newComment, handleNewCommentAdded)}
        isOpen={isOpen}
      />
      {comments && Boolean(comments.length) ? (
        <CommentsList comments={comments} />
      ) : (
        <GrowText color="var(--gray-7)">No comments available</GrowText>
      )}
    </Flex>
  );

  return (
    <Flex direction="column" gap="var(--space-12)">
      <GrowText strong color="var(--gray-8)">
        Comments
      </GrowText>
      {CommentArea}
    </Flex>
  );
}
