import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AllCustomerSegmentsResponseState,
  CustomerSegmentResponseState,
  CustomerSegmentUpdateResponseState,
} from './types';

export const initialState: AllCustomerSegmentsResponseState = {
  status: 0,
  message: '',
  customerSegments: [],
};

const customerSegmentsSlice = createSlice({
  name: 'customerSegments',
  initialState,
  reducers: {
    getAllCustomerSegmentsHandler(state, action: PayloadAction<AllCustomerSegmentsResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.customerSegments = action.payload.customerSegments ?? [];
      state.unassignedCustomers = action.payload.unassignedCustomers;
    },
    removeCustomerSegmentHandler(state, action: PayloadAction<CustomerSegmentResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (action.payload.status >= 200 && action.payload.status < 300 && action.payload.id)
        state.customerSegments = (state.customerSegments ?? []).filter(
          (customerSegment) => customerSegment.id !== action.payload.id
        );
    },
    updateCustomerSegmentsHandler(
      state,
      action: PayloadAction<CustomerSegmentUpdateResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (action.payload.status >= 200 && action.payload.status < 300 && action.payload.data?.id) {
        state.customerSegments = state.customerSegments?.map((customerSegment) => {
          if (customerSegment.id === action.payload.data?.id) return action.payload.data;
          return customerSegment;
        });
      } else if (
        action.payload.status >= 200 &&
        action.payload.status < 300 &&
        action.payload.data
      ) {
        state.customerSegments = state.customerSegments ?? [];
        state.customerSegments = state.customerSegments.filter(
          (customerSegment) => customerSegment.id !== -1
        );
        state.customerSegments.push(Object.assign({}, action.payload.data, { id: -1 }));
      }
    },
    clearStatusAndMessageHandler(state) {
      state.status = 0;
      state.message = '';
    },
  },
});

export const {
  getAllCustomerSegmentsHandler,
  removeCustomerSegmentHandler,
  clearStatusAndMessageHandler,
  updateCustomerSegmentsHandler,
} = customerSegmentsSlice.actions;

export default customerSegmentsSlice.reducer;
