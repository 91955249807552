import {
  getCustomerContactId,
  useCustomerContactsFilter,
} from 'components/Common/FilterComponents/Common/hooks/useCustomerContacts';
import { filterReducer } from 'components/Common/FilterComponents/reducers/filterListReducer';
import { FilterGroup, StandardFilterGroup } from 'components/Common/FilterComponents/types';
import { identity, pickBy } from 'lodash';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { CustomFieldEntityType } from 'types/entities/custom-field';
import { InvoiceFilterGroupList, useCustomerFilterList } from '../filter-options-list';
import {
  FilterValues,
  filterValuesReducer,
  initialFilterValues,
} from '../reducers/filter-values-reducer';
import { useCustomField } from './useCustomField';
// import { useCustomField } from './useCustomField';

export type ClosedInvoiceFilterType = ReturnType<typeof useClosedInvoiceListFilter>;

export function useClosedInvoiceListFilter() {
  const customerGroupList = useCustomerFilterList();

  const initialFilterList: FilterGroup[] = [InvoiceFilterGroupList, customerGroupList];
  const [filterValues, updateFilterValues] = useReducer(filterValuesReducer, initialFilterValues);
  const [filterGroups, updateFilterGroups] = useReducer(filterReducer, initialFilterList);
  const [customerContacts] = useCustomerContactsFilter();
  const isCustomerContactsAddedToFilterGroup = useRef(false);

  const [customerCustomFields] = useCustomField(CustomFieldEntityType.CUSTOMER);
  const [invoiceCustomFields] = useCustomField(CustomFieldEntityType.INVOICE);
  /** To ensure mulitple reloads doesn't result in load of duplicate
   * props due to async callbacks, we have flags inplace to ensure we stop
   * when the fields have already been loaded. */
  const isCustomerCustomFieldsAddedToFilterGroup = useRef(false);
  const isInvoiceFieldsAddedToFilterGroup = useRef(false);
  const setInitialActiveFilterGroups = useCallback((newFilterValues: FilterValues) => {
    const nonFalsyFilters = pickBy(newFilterValues, identity);
    const reportingTagKeys = [] as string[];
    const customerContactKeys = nonFalsyFilters.user_association?.map((contact) =>
      getCustomerContactId(contact.category_id)
    );

    const customFieldKeys = nonFalsyFilters.custom_fields?.map((customField) => {
      return String(customField.id);
    });

    updateFilterGroups({
      type: 'UPDATE_INITIAL_GROUP',
      payload: {
        standardFieldKeys: Object.keys(nonFalsyFilters),
        reportingTagKeys,
        customFieldKeys,
        customerContactKeys,
      },
    });
  }, []);

  const setInitialFilterValue = useCallback((filterValues: FilterValues) => {
    updateFilterValues({
      type: 'UPDATE_INITIAL_VALUE',
      payload: filterValues,
    });
  }, []);

  useEffect(() => {
    if (!(customerCustomFields.length && !isCustomerCustomFieldsAddedToFilterGroup.current)) {
      return;
    }
    const filterGroup = {
      id: StandardFilterGroup.CUSTOMER,
      label: 'Customer',
      filterList: customerCustomFields,
    };

    updateFilterGroups({ type: 'UPDATE_GROUP', payload: filterGroup });
    setInitialActiveFilterGroups(filterValues);
    isCustomerCustomFieldsAddedToFilterGroup.current = true;
  }, [customerCustomFields, filterValues, setInitialActiveFilterGroups]);

  useEffect(() => {
    if (!(invoiceCustomFields.length && !isInvoiceFieldsAddedToFilterGroup.current)) {
      return;
    }
    const filterGroup = {
      id: StandardFilterGroup.INVOICE,
      label: 'Invoice',
      filterList: invoiceCustomFields,
    };

    updateFilterGroups({ type: 'UPDATE_GROUP', payload: filterGroup });
    setInitialActiveFilterGroups(filterValues);
    isInvoiceFieldsAddedToFilterGroup.current = true;
  }, [filterValues, invoiceCustomFields, setInitialActiveFilterGroups]);

  /**
   * @param newFilterValues
   * This callback takes care of accomplishing 2 things.
   *
   * - @function setInitialFilterValue setting intial filter valeues that is being loaded from filter view.
   * - @function setInitialActiveFilterGroups Initialize the filtergroups against the supplied current filter values to
   *   ensure the filters are visible during intial load.
   *
   */

  const initializeFilterValuesAndFilterGroups = useCallback(
    (filterValues: FilterValues) => {
      setInitialFilterValue(filterValues);
      setInitialActiveFilterGroups(filterValues);
    },
    [setInitialActiveFilterGroups, setInitialFilterValue]
  );

  useEffect(() => {
    if (customerContacts?.length && !isCustomerContactsAddedToFilterGroup.current) {
      const filterGroup = {
        id: StandardFilterGroup.CUSTOMER,
        label: 'Customer',
        filterList: customerContacts,
      };

      updateFilterGroups({ type: 'UPDATE_GROUP', payload: filterGroup });
      setInitialActiveFilterGroups(filterValues);
      isCustomerContactsAddedToFilterGroup.current = true;
    }
  }, [customerContacts, filterValues, setInitialActiveFilterGroups]);

  return {
    filterValues,
    updateFilterValues,
    filterGroups,
    updateFilterGroups,
    initializeFilterValuesAndFilterGroups,
  };
}
