import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SaveButton } from '@sinecycle/growcomponents';
import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface NextPrevButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

const t = {
  download: 'Download',
  downloading: 'Downloading',
  export: 'Export',
};

export function NextButton({ onClick, disabled }: NextPrevButtonProps) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      icon={<FontAwesomeIcon icon={['far', 'chevron-right']} fontSize={12} />}
    />
  );
}

export function PrevButton({ onClick, disabled }: NextPrevButtonProps) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      icon={<FontAwesomeIcon icon={['far', 'chevron-left']} fontSize={12} />}
    />
  );
}

interface DownloadButtonProps {
  onClick?: () => void;
  loading?: boolean;
  style?: React.CSSProperties;
  text?: string;
  hideIcon?: boolean;
}
export function DownloadButton({ onClick, loading, style }: DownloadButtonProps) {
  return (
    <SaveButton
      onClick={onClick}
      loading={loading}
      style={style}
      text={t.download}
      savingText={t.downloading}
    />
  );
}

export function DownloadButtonWithText({
  onClick,
  loading,
  style,
  text,
  hideIcon,
}: DownloadButtonProps) {
  return (
    <Button
      onClick={onClick}
      loading={loading}
      style={style}
      icon={<FontAwesomeIcon icon={['far', 'arrow-to-bottom']} color="var(--purple-7)" />}
    >
      {t.export}
    </Button>
  );
}

export const ButtonWithIcon = styled(Button)`
  &.ant-btn > svg {
    padding-right: var(--space-4);
  }
`;

export const CreateNewButton = styled(Button)``;

export const LinkButton = styled(Button)`
  border: 1px solid;
`;
