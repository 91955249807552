import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { StyledIconButton, StyledPreviewItem } from 'components/Common/Styles/Styles';
import { getDocTypeIcon } from 'components/HigherOrderComponent/DocViewer';
import { ValidFileType } from 'types/common/attachments';
import { AssociatedFile } from '../type';

interface PreviewAssociatedFilesProps {
  associatedFile: AssociatedFile;
  onRemove: (id?: number | string, status?: boolean) => void;
  fileIcon?: React.ReactNode;
}

function PreviewAssociatedFiles(props: Readonly<PreviewAssociatedFilesProps>) {
  function handleRemove() {
    props.onRemove(props.associatedFile.id, false);
  }

  const CloseIcon = <FontAwesomeIcon icon={['far', 'times']} color="var(--gray-7)" />;
  const CloseButton = (
    <Tooltip placement="top" title="Remove" mouseEnterDelay={0.5}>
      <StyledIconButton
        icon={CloseIcon}
        size="small"
        style={{ width: '12px', height: '12px', display: 'flex' }}
        onClick={handleRemove}
      />
    </Tooltip>
  );
  const FileIcon = props.fileIcon
    ? props.fileIcon
    : getDocTypeIcon(props.associatedFile.file_format as ValidFileType);
  const FileName = (
    <Texto color="var(--purple-7)">
      {props.associatedFile.file_name || props.associatedFile.name}
    </Texto>
  );
  return (
    <StyledPreviewItem justify="space-between" align="center" gap="--space-8">
      {FileIcon}
      {FileName}
      {CloseButton}
    </StyledPreviewItem>
  );
}

export default PreviewAssociatedFiles;
