import { Descriptions, Divider } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { CommentsBlock } from 'components/CollectionActivities/Comment/CommentsBlock';
import {
  AmountBlock,
  InvoicesBlock,
  LabelBlock,
  multiCurrencyAmount,
  TextBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { SubscriptionType } from 'components/CollectionActivities/Common/SubscriptionType';
import useGetActivityLink from 'components/CollectionActivities/hooks/useGetActivityLink';
import { ActivityContent, ActivityTitle } from 'components/CollectionActivities/style';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';
import { OPEN_TYPE, useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';

import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { useSelector } from 'react-redux';
import { baseCurrencySelector } from 'store/authentication/authentication';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';
import { CreditMemo } from 'types/activities/credit-memo';

interface CreditMemoDetailsProps extends ActivityDetailsBase {
  data?: CreditMemo;
  isLoading: boolean;
}

function CreditMemoDetailsBody(props: CreditMemoDetailsProps) {
  const { data, isLoading, subscriptionType, customer, activitiesMode } = props;
  const { cardLabel, Icon } = getActivityUnitItems(ActivityType.CREDIT_MEMO);
  const { entitySearchParams } = useEntitySearchParams();
  const baseCurrency = useSelector(baseCurrencySelector) ?? 'USD';
  const ActivityCopyLink = useGetActivityLink(activitiesMode);
  const Title = (
    <ActivityTitle align="center" gap="var(--space-8)">
      <ActivityIconButton
        icon={Icon}
        size="small"
        css={{ '--activityBg': 'var(--green-1)' }}
        viewMode
        loading={isLoading}
        hideBackground
      />
      <GrowText>{cardLabel}</GrowText>
      <ActivityCopyLink data={data} customer={customer} type={ActivityType.CREDIT_MEMO} />
    </ActivityTitle>
  );

  const Header = <Flex justify="space-between">{Title}</Flex>;

  const InvoiceArea =
    !isLoading && data ? (
      <InvoicesBlock invoices={data.invoices} showTransactionalCurrency />
    ) : null;

  const Amount =
    data && !isLoading ? (
      <AmountBlock
        amount={data.amount}
        currency={data.currency}
        amountComp={
          <AmountWithCurrencySplit
            showTooltip
            type="multi"
            amountWithCurrencySplit={multiCurrencyAmount(
              data.amount,
              baseCurrency,
              data.currency,
              data.amount_base_currency
            )}
          />
        }
      />
    ) : null;

  const Sub = !isLoading && data ? <SubscriptionType subscriptionType={subscriptionType} /> : null;
  const Content = (
    <>
      <Flex gap="var(--space-12)" direction="column">
        <Flex direction="column" gap="var(--space-8)">
          {Header}
          {Sub}
        </Flex>
        <Flex gap="var(--space-16)" direction="column">
          <TextBlock value={data?.note} size={'var(--fs-20)'} strong />
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item label={<LabelBlock label="Invoices" />}>
              {InvoiceArea}
            </Descriptions.Item>
          </ActivityContent>
          <Divider style={{ margin: 'var(--space-24) 0', borderColor: 'var(--gray-5)' }} />
        </Flex>
      </Flex>
      <Flex direction="column">
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item label={<LabelBlock label="Credited Amount" />}>
            {Amount}
          </Descriptions.Item>
        </ActivityContent>
      </Flex>
    </>
  );

  const Footer =
    data && !isLoading ? (
      <CommentsBlock
        customerId={props.customer?.id}
        activityId={data.id}
        activityType={ActivityType.CREDIT_MEMO}
        comments={data.comments}
        isOpen={entitySearchParams[EActivityQueryParams.OPEN] === OPEN_TYPE.COMMENTS}
      />
    ) : null;
  return (
    <>
      {Content}
      <Divider style={{ margin: 'var(--space-24) 0', borderColor: 'var(--gray-5)' }} />
      {Footer}
    </>
  );
}

export { CreditMemoDetailsBody };
