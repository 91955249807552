import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { DocumentTypesHeaderProps } from './types';

function DocumentTypesHeader(props: DocumentTypesHeaderProps) {
  const { title, description, handleClick, ExtraComponent } = props;

  return (
    <Flex
      align="center"
      justify="space-between"
      style={{
        boxShadow: 'var(--shadow-2)',
        padding: 'var(--space-16) 22px',
        background: 'var(--gray-2)',
        height: '72px',
        zIndex: 2,
      }}
    >
      <Flex align="center" gap="var(--space-12)">
        <Button
          icon={<FontAwesomeIcon icon={['fal', 'arrow-left']} color="var(--gray-9)" />}
          style={{ width: '45px' }}
          onClick={handleClick}
        />
        <Flex gap="var(--space-4)" direction="column">
          <Texto color="var(--primary-10)" size="20" weight="semibold">
            {title}
          </Texto>
          <Texto color="var(--gray-9)" size="14">
            {description}
          </Texto>
        </Flex>
      </Flex>
      {ExtraComponent}
    </Flex>
  );
}

export { DocumentTypesHeader };
