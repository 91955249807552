import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { baseCurrencySelector } from 'store/authentication/authentication';
import { getCurrencySymbol } from 'util/number-formatter';
import { BaseFilterBlockProps } from '../FilterBlocks/types';
import { FilterDropdown } from '../FilterDropdown';
import { FilterConfig, FilterType } from '../types';

type MinMaxValue = { min?: number; max?: number };

type Prefix =
  | {
      type: 'ACCOUNT_CURRENCY';
    }
  | {
      type: 'GENERIC';
      value: ReactNode;
    };

interface MinMaxWrapperProps extends BaseFilterBlockProps<MinMaxValue> {
  filterConfig: Pick<FilterConfig, 'name' | 'label'>;
  prefix?: Prefix;
}

function isNullorUndefined(valCheck: any) {
  return [null, undefined].includes(valCheck);
}

function useGetPrefix(prefix: MinMaxWrapperProps['prefix']): ReactNode {
  const currency = useSelector(baseCurrencySelector);

  switch (prefix?.type) {
    case 'ACCOUNT_CURRENCY':
      return getCurrencySymbol(currency);

    case 'GENERIC':
      return prefix.value;
  }
}

export function MinMaxWrapper(props: MinMaxWrapperProps) {
  const { filterConfig, onClear, onSubmit, value, showClear = true, prefix } = props;

  const prefixValue = useGetPrefix(prefix);

  const filterConfigo: FilterConfig = {
    ...filterConfig,
    type: FilterType.MIN_MAX,
    valueToString: ({ min, max }: MinMaxValue) => {
      if (isNullorUndefined(min) && isNullorUndefined(max)) return 'All';

      if (isNullorUndefined(min)) {
        return <span>&le; {`${prefixValue ?? ''}${max}`}</span>;
      }

      if (isNullorUndefined(max)) {
        return <span>&ge; {`${prefixValue ?? ''}${min}`}</span>;
      }

      return `${prefixValue ?? ''}${min} - ${prefixValue ?? ''}${max}`;
    },
  };

  function handleSubmit(value: MinMaxValue) {
    onSubmit && onSubmit(value);
  }

  function handleClear() {
    onClear && onClear();
    onSubmit && onSubmit({ min: undefined, max: undefined });
  }

  return (
    <FilterDropdown
      value={value}
      filterConfig={filterConfigo}
      onSubmit={handleSubmit}
      onClear={handleClear}
      showClear={showClear}
    />
  );
}
