import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'reducers/rootReducer';
import {
  IInvoiceFlagList,
  IInvoiceFlagState,
  IInvoiceIdAndFlag,
  ITotalInvoiceFlagCount,
} from './types';

export const initialState: IInvoiceFlagState = {
  list: [] as IInvoiceIdAndFlag[],
  count: 0,
};

const invoiceFlagSlice = createSlice({
  name: 'invoiceFlag',
  initialState,
  reducers: {
    setInvoiceFlagList(state, action: PayloadAction<IInvoiceFlagList>) {
      state.list = action.payload.list;
    },
    updateInvoiceFlagList(state, action: PayloadAction<IInvoiceFlagList>) {
      const newList = [...action.payload.list];
      state.list.forEach((item) => {
        if (!action.payload.list.find((existing) => existing.id === item.id)) {
          newList.push(item);
        }
      });

      let change = 0;
      action.payload.list.forEach((item) => {
        const newItemState = state.list.find((newItem) => newItem.id === item.id);
        if (newItemState && newItemState.flag === !item.flag) {
          change += newItemState.flag ? -1 : 1;
        }
      });
      state.list = newList;
      state.count = state.count + change;
    },

    resetInvoiceFlagCount(state) {
      state.count = 0;
    },

    updateInvoiceFlagCount(state, action: PayloadAction<ITotalInvoiceFlagCount>) {
      state.count = action.payload.count < 0 ? 0 : action.payload.count;
    },
  },
});

export const invoiceFlagListSelector = (state: RootState) => state.invoiceFlag.list;

export const invoiceFlagCountSelector = (state: RootState) => state.invoiceFlag.count;

export const {
  setInvoiceFlagList,
  updateInvoiceFlagList,
  resetInvoiceFlagCount,
  updateInvoiceFlagCount,
} = invoiceFlagSlice.actions;

export default invoiceFlagSlice.reducer;
