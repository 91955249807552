type tickFn = (progress: number, total: number) => void;

/*
 * A `Promise.all` but emits a callback on each promise resolution
 * to track the progress of the promises.
 * Mainly created to handle initial data fetching in the app and show it in loader
 */
export function PromiseAllWithProgress(promises: Promise<any>[], tickCallback: tickFn) {
  // const { promises, tickCallback } = props;

  const len = promises.length;
  let progress = 0;

  function tick(promise: Promise<any>) {
    promise.then(function () {
      progress++;
      tickCallback(progress, len);
    });
    return promise;
  }

  return Promise.all(promises.map(tick));
}
