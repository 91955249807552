import { GrowDrawer } from '@sinecycle/growcomponents';
import { DrawerProps } from 'antd/lib/drawer';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface ISlidingModal extends DrawerProps {
  children?: ReactNode;
  isEmail?: boolean;
}

const ContentContainer = styled.div`
  padding-bottom: 0.5rem;

  & {
    height: 100%;
  }

  .container-common {
    height: 100%;

    .ant-spin-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      .body {
        flex: 1;
        overflow: auto;
      }
    }
  }
`;

const SlidingModal = (props: ISlidingModal) => (
  <GrowDrawer
    {...props}
    rootClassName={` ${props.className}`}
    mask={props.mask}
    title={props.title}
    extra={props.extra}
    width={props.width ?? '35%'}
    onClose={props.onClose}
    closable={props.closable ?? false}
    open={props.open}
    destroyOnClose={props.destroyOnClose}
    getContainer={props.getContainer}
    rootStyle={props.style}
    styles={{
      ...props.styles,
      header: {
        padding: '0',
        backgroundColor: 'var(--gray-2)',
        borderBottom: '1px solid var(--gray-5)',
      },
      footer: props.isEmail
        ? {
            padding: '0',
            backgroundColor: 'var(--gray-2)',
            borderTop: '1px solid var(--gray-5)',
          }
        : {
            padding: '0',
            backgroundColor: 'var(--gray-2)',
            borderTop: '1px solid var(--gray-5)',
          },
    }}
    push={props.push}
    design={props.isEmail ? undefined : 'growfin_design_default'}
  >
    {props.isEmail ? (
      props.children
    ) : (
      <ContentContainer className="content-container">{props.children}</ContentContainer>
    )}
  </GrowDrawer>
);

export default SlidingModal;
