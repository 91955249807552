import { Rank } from 'types/utils/utils';
import { EmailDeliveryStatusConfig, EmailDeliveryStatusList } from './type';

export const EmailDeliveryStatusConfigs: EmailDeliveryStatusConfig = {
  [EmailDeliveryStatusList.READ]: {
    label: 'Read',
    iconName: ['fal', 'check-double'],
    iconColor: 'var(--geekblue-7)',
    labelColor: 'var(--geekblue-7)',
    dateProp: 'opened_at',
  },
  [EmailDeliveryStatusList.SENT]: {
    label: 'Sent',
    iconName: ['fal', 'paper-plane-alt'],
    iconColor: 'var(--cyan-8)',
    labelColor: 'var(--cyan-8)',
    dateProp: 'created_at',
  },
  [EmailDeliveryStatusList.DELIVERED]: {
    label: 'Delivered',
    iconName: ['fal', 'check'],
    iconColor: 'var(--green-8)',
    labelColor: 'var(--green-8)',
    dateProp: 'delivered_at',
  },
  [EmailDeliveryStatusList.NOT_DELIVERED]: {
    label: 'Not Delivered',
    iconName: ['fal', 'times-circle'],
    iconColor: 'var(--red-8)',
    labelColor: 'var(--red-8)',
    dateProp: 'bounced_at',
  },
};

export const emailDeliveryStatusRank: Rank = {
  [EmailDeliveryStatusList.NOT_DELIVERED]: 0,
  [EmailDeliveryStatusList.READ]: 1,
  [EmailDeliveryStatusList.DELIVERED]: 2,
  [EmailDeliveryStatusList.SENT]: 3,
};
