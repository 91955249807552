import { kyWithSerializer } from './ky';
import { RegionItem } from '../store/regions/types';

const baseUrl = 'regions';

export async function getRegions() {
  return (await kyWithSerializer.get(`${baseUrl}`).json()) as RegionItem[];
}

export async function postRegions(data: RegionItem) {
  return (await kyWithSerializer.post(`${baseUrl}`, { json: data }).json()) as RegionItem;
}

export async function updateRegions(data: RegionItem) {
  return (await kyWithSerializer.put(`${baseUrl}/${data.id}`, { json: data }).json()) as RegionItem;
}

export async function deleteRegions(id: number) {
  return await kyWithSerializer.delete(`${baseUrl}/${id}`);
}
