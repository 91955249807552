import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import Tag from 'components/BaseComponents/Tags';
import { GrowText } from 'components/BaseComponents/Typography';
import { Style } from 'lib/style/style';

const MoreIcon = <FontAwesomeIcon icon={['far', 'ellipsis']} color="var(--primary-7)" />;

interface ExpansionProps {
  onClick: () => void;
  text?: string;
  open?: boolean;
}

const ExpansionTag = Style(Tag, {
  '&.ant-tag': {
    background: 'var(--primary-1)',
    cursor: 'pointer',
    margin: 0,
  },

  variants: {
    text: {
      true: {
        '&.ant-tag': {
          padding: '4px 8px',
        },
      },
    },
    icon: {
      true: {
        '&.ant-tag': {
          padding: '0px',
          width: '32px',
          height: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
  },
  defaultVariants: {
    icon: true,
  },
});
function Expansion(props: ExpansionProps) {
  const { onClick, text, open } = props;

  const title = open ? 'Hide trimmed content' : 'Show trimmed content';
  return (
    <Tooltip title={text ? undefined : title} placement="bottom" arrow={false}>
      <ExpansionTag onClick={onClick} text={Boolean(text)} icon={!text}>
        {text ? (
          <GrowText style={{ color: 'var(--primary-7)', fontWeight: 'var(--fs-regular)' }}>
            {text}
            &nbsp;
            {MoreIcon}
          </GrowText>
        ) : (
          MoreIcon
        )}
      </ExpansionTag>
    </Tooltip>
  );
}

export { Expansion };
