import {
  InvoiceSegment,
  InvoiceSegmentCreatePayload,
  mapInvoiceSegmentFilterPredicate,
  MatchedInvoice,
} from 'types/entities/invoice-segments';
import ky from './ky';

const baseUrl = 'invoice-segments';

export async function getInvoiceSegments() {
  return (await ky.get(baseUrl).json()) as InvoiceSegment[];
}

export async function saveInvoiceSegment(segmentToSave: InvoiceSegment) {
  const segmentId = segmentToSave.id;
  const modifiedSegment = segmentToSave.filters.map(mapInvoiceSegmentFilterPredicate);
  return (await ky
    .put(`${baseUrl}/${segmentId}`, { json: { ...segmentToSave, filters: modifiedSegment } })
    .json()) as InvoiceSegment;
}

export async function createInvoiceSegment(segmentToSave: InvoiceSegment) {
  const clonedSegmentToSave: InvoiceSegmentCreatePayload = { ...segmentToSave };
  delete clonedSegmentToSave.id;
  const modifiedSegment = segmentToSave.filters.map(mapInvoiceSegmentFilterPredicate);

  return (await ky
    .post(baseUrl, { json: { ...clonedSegmentToSave, filters: modifiedSegment } })
    .json()) as InvoiceSegment;
}

export async function deleteInvoiceSegment(segmentId: number) {
  return await ky.delete(`${baseUrl}/${segmentId}`);
}

export async function fetchInvoiceCount(invoiceSegment: InvoiceSegmentCreatePayload) {
  const segmentWithoutId = { ...invoiceSegment };
  delete segmentWithoutId.id;

  const modifiedSegmentFilters = invoiceSegment.filters.map(mapInvoiceSegmentFilterPredicate);

  return (await ky
    .post(`${baseUrl}/invoices-count `, {
      json: { ...segmentWithoutId, filters: modifiedSegmentFilters },
    })
    .json()) as MatchedInvoice[];
}
