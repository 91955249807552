import { SearchParamsOption } from 'ky';
import { omitBy } from 'lodash';
import { PaymentListFilter } from 'store/payments/type';
import { InvoiceFilterView } from 'types/filter-view/common';
import { OpenInvoiceFilterParams } from 'types/filter-view/open-invoice';
import { FilterViewInterface, TFilterViews } from '../store/aging/types';
import ky from './ky';

const baseUrl = 'filters';

export async function saveCurrentFilterView(data: FilterViewInterface<TFilterViews>, type: string) {
  if (data.payments_report_filter) {
    data.payments_report_filter = {
      ...omitBy(data.payments_report_filter, (item) => Array.isArray(item) && item.length === 0),
    } as PaymentListFilter;
  }

  return (await ky
    .post(`${baseUrl}/${type}`, { json: data })
    .json()) as FilterViewInterface<TFilterViews>;
}

export async function getSavedFilterViews(type: string, params?: SearchParamsOption) {
  return (await ky
    .get(`${baseUrl}/${type}`, { searchParams: params })
    .json()) as FilterViewInterface<TFilterViews>[];
}

export async function deleteSavedFilterViews(id: string, type: string) {
  return await ky.delete(`${baseUrl}/${id}`);
}

//Latest URL with TYPES
export async function saveFilterView<T extends OpenInvoiceFilterParams>(
  data: InvoiceFilterView<T>,
  type: string
) {
  return (await ky.post(`${baseUrl}/${type}`, { json: data }).json()) as InvoiceFilterView<T>;
}

export async function deleteFilterView(id: number) {
  return await ky.delete(`${baseUrl}/${id}`);
}
