import { Card } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import RichComments from 'components/BaseComponents/RichComment';
import { GrowText } from 'components/BaseComponents/Typography';
import { UserAvatar } from 'components/HigherOrderComponent/UserAvatar';
import { Style } from 'lib/style/style';
import { Commento } from 'types/activities/comment';
import { DateBlock } from '../Common/Details/BodyBlocks';
import { CommentSource } from './CommentSource';
import { Form } from './Form';
const { Meta } = Card;
interface Comments extends Commento {}

export const StyledMeta = Style(Meta, {
  '.ant-card-meta-detail > div:not(:last-child)': {
    marginBottom: 'var(--space-16)',
  },
  '.ant-card-meta-avatar': {
    paddingRight: 'var(--space-8)',
  },
  '.ant-card-meta-detail': {
    width: '100%',
  },
  '.ant-card-meta-title': {
    fontWeight: 'var(--fs-regular)',
  },
  '.ant-card-meta-description': {
    color: 'var(--gray-9)',
  },
});
const Komento = (props: Comments) => {
  const { created_by, updated_date, external_event_type } = props;

  const Avatar = <UserAvatar name={[created_by.first_name ?? '', created_by.last_name ?? '']} />;

  const Comments = <RichComments mode="read" jsonString={props.comments} />;

  const Name = (
    <GrowText strong ellipsis>{`${created_by.first_name} ${created_by.last_name ?? ''}`}</GrowText>
  );

  const Date = (
    <DateBlock
      dateString={updated_date}
      color="var(--gray-7)"
      style={{ fontWeight: 'var(--fs-regular)', fontSize:'var(--fs-14)' }}
    />
  );

  const Title = (
    <Flex justify="space-between">
      <Flex align="center">
        {Name}
        {<CommentSource source={external_event_type} containerStyle={{ alignSelf: 'start' }} />}
      </Flex>
      {Date}
    </Flex>
  );

  return <StyledMeta avatar={Avatar} title={Title} description={Comments} />;
};

Komento.Form = Form;

export { Komento };
