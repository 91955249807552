import { customerNameFormatter } from '@sinecycle/growcomponents';
import { Select } from 'antd';
import SelectComponent from 'components/BaseComponents/ASelect';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import produce from 'immer';
import { useEffect, useMemo, useState } from 'react';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import styled from 'styled-components';
import { BaseCustomer } from 'types/entities/customer';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { InvoiceFollowupAction } from '../Email';
import { t } from './text';
import { groupInvoicesByCustomer } from './utils';

interface GroupedInvoicesSelectProps {
  invoiceList: InvoiceDropdownInterfaceProps[] | InvoiceBasicDetails[];
  onChange: (customerId: number, invoiceIds: number[]) => void;
  invoiceFollowupAction?: InvoiceFollowupAction;
}

interface CustomerWithInvoices {
  id: number;
  name?: string;
  invoices: {
    invoice_no: string;
    invoice_id: number;
  }[];
}

const StyledDropdownOption = styled.div`
  padding: var(--space-12);
  margin-left: calc(-1 * var(--space-16));
  margin-right: calc(-1 * var(--space-16));
  display: block;
  cursor: pointer;
  &.selected {
    background: var(--purple-1);
    pointer-events: none;
  }
  &.not-selected:hover {
    background: var(--gray-2);
  }
`;

export function GroupedInvoicesSelect({
  invoiceList,
  onChange,
  invoiceFollowupAction,
}: GroupedInvoicesSelectProps) {
  const customersWithInvoices: CustomerWithInvoices[] = useMemo(() => {
    return groupInvoicesByCustomer(invoiceList);
  }, [invoiceList]);

  const [selectedValue, setSelectedValue] = useState({
    id: invoiceList[0].customer_id,
    label: `${invoiceList[0].invoice_no}`,
  });

  function handleChange(value: number, label: string) {
    const selectedCustomer = customersWithInvoices.find((customer) => customer.id === value);

    if (selectedCustomer) {
      setSelectedValue({ id: value, label });

      onChange(
        selectedCustomer.id,
        selectedCustomer.invoices.map((invoice) => invoice.invoice_id)
      );
    }
  }

  const dropdownRender = (options: React.ReactNode) => {
    return <div style={{ padding: '0 var(--space-16)' }}>{options}</div>;
  };
  const optionRenderInvoices = (option: InvoiceDropdownInterfaceProps) => {
    return (
      <StyledDropdownOption
        onClick={() => {
          handleChange(option.customer_id, `${option.invoice_no}`);
        }}
        className={option.customer_id === selectedValue.id ? 'selected' : 'not-selected'}
      >
        <Flex gap="var(--space-4)" style={{ pointerEvents: 'none' }}>
          <Texto size="14">{option.invoice_no}</Texto>
        </Flex>
      </StyledDropdownOption>
    );
  };
  const optionRenderStatements = (option: CustomerWithInvoices) => {
    return (
      <StyledDropdownOption
        key={option.id}
        onClick={() => {
          handleChange(option.id, `${option.name} (${option.invoices.length})`);
        }}
        className={option.id === selectedValue.id ? 'selected' : 'not-selected'}
      >
        <Flex gap="var(--space-4)" style={{ pointerEvents: 'none' }}>
          <Texto size="14">{option.name}</Texto>
          <Texto size="14" color="var(--gray-7)">
            ({option.invoices.length})
          </Texto>
        </Flex>
      </StyledDropdownOption>
    );
  };

  const optionList =
    invoiceFollowupAction === InvoiceFollowupAction.INDIVIDUAL_INVOICE
      ? invoiceList
      : customersWithInvoices;
  const optionRender: (option: any) => React.ReactNode =
    invoiceFollowupAction === InvoiceFollowupAction.INDIVIDUAL_INVOICE
      ? optionRenderInvoices
      : optionRenderStatements;

  useEffect(() => {
    const statementsState = {
      id: customersWithInvoices[0].id,
      label: `${customersWithInvoices[0].name} (${customersWithInvoices[0].invoices.length})`,
    };
    if (invoiceFollowupAction === InvoiceFollowupAction.INDIVIDUAL_INVOICE) {
      setSelectedValue({
        id: invoiceList[0].customer_id,
        label: `${invoiceList[0].invoice_no}`,
      });
    }
    if (invoiceFollowupAction === InvoiceFollowupAction.STATEMENT_OF_INVOICES) {
      setSelectedValue(statementsState);
    }
  }, [customersWithInvoices, invoiceFollowupAction, invoiceList]);

  return (
    <SelectComponent
      selectedValues={selectedValue.label}
      style={{ width: '200px', textAlign: 'left' }}
      optionsList={optionList}
      placeholder={t.groupedInvoicesSelectPlaceholder}
      labelAtrributeName={
        invoiceFollowupAction === InvoiceFollowupAction.INDIVIDUAL_INVOICE ? 'invoice_no' : 'name'
      }
      valueAtrributeName="id"
      shouldFormat={true}
      dropdownRender={dropdownRender}
      optionRender={optionRender}
    />
  );
}

interface CustomerSelectProps {
  customerList: BaseCustomer[];
  onChange: (value: number) => void;
  selectAll?: boolean;
  totalRecords?: number;
}

interface customerListOptionProps {
  value?: number;
  label: string;
  disabled?: boolean;
}

export function CustomerSelect({
  customerList,
  onChange,
  selectAll,
  totalRecords,
}: CustomerSelectProps) {
  const [customerId, setCustomerId] = useState(
    customerList.length ? customerList[0].id : undefined
  );

  const options: customerListOptionProps[] = customerList.map((customer) => ({
    value: customer.id,
    label: customerNameFormatter({ isUnnamedCustomer: true, name: customer.name }),
  }));

  function getOptions() {
    if (selectAll && totalRecords && totalRecords > 10) {
      return produce(options, (draft) => {
        draft.push({ label: `+ ${totalRecords - 10} more`, disabled: true });
      });
    }
    return options;
  }

  const customerListOptions = getOptions();

  function handleChange(value: number) {
    setCustomerId(value);
    onChange(value);
  }

  return (
    <Select
      size="middle"
      style={{ width: '200px', textAlign: 'left' }}
      value={customerId}
      options={customerListOptions}
      placeholder={t.customerSelectPlaceholder}
      onChange={handleChange}
      showSearch
    />
  );
}
