import { CUSTOMER_DETAILS_SECTION } from 'components/CustomerDetails/Body';
import { InvoiceListTabKeys } from 'components/InvoiceListV2';

export enum CUSTOMER_DETAILS_EVENT {
  STOP_COLLECTION_STRATEGY = 'Customer details: Stop Collection Strategy',
  SET_COLLECTION_STRATEGY = 'Customer details: Set Collection Strategy',
  OPEN_COLLECTION_OWNER = ' Customer details: Open Collection Owner Change',
  SAVE_COLLECTION_OWNER = ' Customer details: Save Collection Owner',
  UNFLAG_ALL_INVOICES = 'Customer details: Unflag All Invoices',
  RIGHT_PANE_TOGGLE = 'Customer details: Right pane Toggle',
  QUICK_FILTERS = 'Customer details: Payments: Quick Filters ',
  SORTING = 'Customer details: Sorting ',
  PAGINATION = 'Customer details:Pagination',
  DOWNLOAD_PDF = 'Customer details: Download Pdf',
  VIEW_PDF = 'Customer details:View Pdf',
  SELECT_ACTIVITY_OR_STATEMENTS = 'Customer details: Select Activity or Statements',
  ACTIVITY_TAB_SWITCH = 'Customer details: Activity Tab Switch',
}

type CUSTOMER_DETAILS_EVENTS_WITH_PROPS_PAYLOAD =
  | {
      type: CUSTOMER_DETAILS_EVENT.SET_COLLECTION_STRATEGY;
      payload: {
        strategy?: string;
      };
    }
  | {
      type: CUSTOMER_DETAILS_EVENT.RIGHT_PANE_TOGGLE;
      payload: {
        state: 'Open' | 'Close';
        currentView?: CUSTOMER_DETAILS_SECTION;
      };
    }
  | {
      type: CUSTOMER_DETAILS_EVENT.QUICK_FILTERS;
      payload: {
        filterOptions: Array<'FULL' | 'PARTIAL'>;
      };
    }
  | {
      type: CUSTOMER_DETAILS_EVENT.SORTING;
      payload: {
        column: string;
        tab: InvoiceListTabKeys | 'Credit Notes' | 'Payments';
      };
    }
  | {
      type: CUSTOMER_DETAILS_EVENT.PAGINATION;
      payload: {
        tab: InvoiceListTabKeys | 'Credit Notes' | 'Payments';
      };
    }
  | {
      type: CUSTOMER_DETAILS_EVENT.DOWNLOAD_PDF;
      payload: {
        tab: InvoiceListTabKeys;
      };
    }
  | {
      type: CUSTOMER_DETAILS_EVENT.VIEW_PDF;
      payload: {
        tab: InvoiceListTabKeys;
      };
    }
  | {
      type: CUSTOMER_DETAILS_EVENT.SELECT_ACTIVITY_OR_STATEMENTS;
      payload: {
        currentView: CUSTOMER_DETAILS_SECTION;
      };
    }
  | {
      type: CUSTOMER_DETAILS_EVENT.ACTIVITY_TAB_SWITCH;
      payload: {
        tab: string;
      };
    };

type CUSTOMER_DETAILS_WITH_PROPS = CUSTOMER_DETAILS_EVENTS_WITH_PROPS_PAYLOAD['type'];

type CUSTOMER_DETAILS_WITHOUT_PROPS = Exclude<CUSTOMER_DETAILS_EVENT, CUSTOMER_DETAILS_WITH_PROPS>;

export type CUSTOMER_DETAILS_EVENT_TYPES =
  | CUSTOMER_DETAILS_EVENTS_WITH_PROPS_PAYLOAD
  | { type: CUSTOMER_DETAILS_WITHOUT_PROPS; payload?: undefined };
