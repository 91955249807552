import { Style } from 'lib/style/style';
import { AntBadge } from './Antd/Antd';

export const GrowBadge = Style(AntBadge, {
  variants: {
    notification: {
      'notification-1': {
        '.ant-badge-dot': {
          width: '8px',
          height: '8px',
          background: 'var(--red-7)',
          margin: 'calc(-1 * var(--space-4))',
        },
      },
    },
    read: {
      'read-status': {
        '.ant-badge-dot': {
          width: '8px',
          height: '8px',
          background: 'var(--red-7)',
        },
      },
    },
  },
});
