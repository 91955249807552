import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm, Tooltip } from 'antd';
import { PopConfirmContent } from 'components/BaseComponents/ButtonWithConfirmation/PopconfirmContent';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { RaiseADisputeActivity } from 'store/activity-feed/type';
import { ActivityType } from 'types/activities/activity-types';

interface ActionButtonsProps {
  hide?: boolean;
  onDelete?: (id: string, type?: ActivityType) => void;
  id?: string | number;
  deleteLoading?: boolean;
  editLoading?: boolean;
  title?: string;
  description?: string;
  activity?: RaiseADisputeActivity;
  hideEdit?: boolean;
  type?: ActivityType;
}

const t = {
  edit: 'Edit',
  delete: 'Delete',
};

const EditIcon = <FontAwesomeIcon icon={['far', 'pen']} color="var(--primary-7)" fontSize={14} />;
const DeleteIcon = (
  <FontAwesomeIcon icon={['far', 'trash-alt']} color="var(--primary-7)" fontSize={14} />
);
function ActionButtons(props: ActionButtonsProps) {
  const {
    hide = false,
    onDelete,
    id,
    deleteLoading,
    editLoading,
    title,
    description,
    hideEdit,
    type,
  } = props;
  const activityId = String(id);
  const { setEntitySearchParams } = useEntitySearchParams();

  const Edit = (
    <Tooltip title={t.edit}>
      <ActivityIconButton
        icon={EditIcon}
        size="small"
        onClick={() => setEntitySearchParams({ [EActivityQueryParams.EDIT]: true })}
        loading={editLoading}
        type="text"
      />
    </Tooltip>
  );

  const ConfirmHeader = (
    <PopConfirmContent
      title={title}
      desc={description}
      titleStyles={{ color: 'var(--gold-6)', fontWeight: 'var(--fs-semibold)' }}
    />
  );

  const Delete = (
    <Popconfirm
      title={ConfirmHeader}
      onConfirm={() => onDelete?.(activityId, type)}
      placement={'top'}
      okText={'Yes'}
      arrow={false}
      icon={false}
      overlayClassName="ant-popover-title-no-padding"
    >
      <Tooltip title={t.delete}>
        <ActivityIconButton icon={DeleteIcon} size="small" loading={deleteLoading} type="text" />
      </Tooltip>
    </Popconfirm>
  );

  return (
    <HideWrapper hide={hide}>
      <Flex align="center" gap="var(--space-8)">
        <HideWrapper hide={Boolean(hideEdit)}>{Edit}</HideWrapper>
        {Delete}
      </Flex>
    </HideWrapper>
  );
}

export { ActionButtons };
