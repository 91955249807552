import { ShowIfActive } from 'components/Common/FilterComponents/Common/ShowIfActive';
import CustomFieldFilterBlock from 'components/Common/FilterComponents/FilterBlocks/CustomField';
import NumberBasedFilterBlock from 'components/Common/FilterComponents/FilterBlocks/CustomField/NumberBasedFilterBlock';
import { FilterConfigWrapperProps } from 'components/Common/FilterComponents/FilterWrapper/type';
import { CustomFieldRangeValueType } from 'components/Common/FilterComponents/Filters/CustomFiledRange';
import { StandardFilterGroup } from 'components/Common/FilterComponents/types';
import { isEqual, uniq } from 'lodash';
import { useCallback } from 'react';
import {
  CustomField,
  CustomFieldEntityType,
  CustomFieldFilterValues,
  CustomFieldOperatorType,
  CustomFieldOption,
  CustomFiledOperands,
  customFieldFilterUnassigned,
  validNumberBasedFields,
} from 'types/entities/custom-field';
import useGetBaseCurrencySymbol from 'util/hooks/useGetBaseCurrencySymbol';
import { useDashboardFilter } from './hooks/useDashboardFilter';

type Props = ReturnType<typeof useDashboardFilter> & {
  customFieldType: CustomFieldEntityType.CUSTOMER | CustomFieldEntityType.INVOICE;
};
const customFiledOptionPredicate = (fieldOption: CustomFieldOption) => {
  return {
    label: `${fieldOption}`,
    value: fieldOption,
  };
};
export function CustomFieldFilters(props: Props) {
  const { filterGroups, updateFilterGroups, filterValues, updateFilterValues, customFieldType } =
    props;
  const { currencySymbol } = useGetBaseCurrencySymbol();
  const filterGroupId: StandardFilterGroup =
    props.customFieldType === CustomFieldEntityType.CUSTOMER
      ? StandardFilterGroup.CUSTOMER
      : StandardFilterGroup.INVOICE;

  const customFieldFilters =
    filterGroups
      .find((filterGroup) => filterGroup.id === filterGroupId)
      ?.filterList.filter((filterItem) => {
        return filterItem.id === `${customFieldType}-custom-field-${filterItem?.filterObject?.id}`;
      }) ?? [];

  function updateCustomFieldValue({
    id,
    operator,
    include_unassigned,
  }: CustomFieldFilterValues<'IN'>) {
    updateFilterValues({
      type: 'UPDATE_CUSTOM_FIELDS',
      payload: {
        id,
        operator: {
          type: operator.type,
          operands: {
            type: operator.operands.type,
            value: operator.operands.value.filter((value) => value !== -1) ?? [],
          },
        },
        include_unassigned,
      },
    });
  }
  function removeCustomField(customFieldId: string, customFieldGroupId: StandardFilterGroup) {
    updateFilterGroups({
      type: 'UPDATE_FILTER',
      payload: {
        groupId: customFieldGroupId,
        filterId: customFieldId,
        updatedValues: { active: false },
      },
    });
  }
  function getCustomField(customFieldId: number) {
    const selectedCustomField = filterValues.custom_fields?.find(
      (customField) => customField.id === customFieldId
    ) as unknown as CustomFieldFilterValues<'IN'>;

    return selectedCustomField
      ? selectedCustomField.include_unassigned
        ? [...selectedCustomField.operator.operands.value, -1]
        : selectedCustomField.operator.operands.value
      : [];
  }

  const customFields = useCallback(getCustomField, [filterValues.custom_fields]);

  function getCustomFieldConfigCallBack(customField: CustomField): FilterConfigWrapperProps {
    const value = customFields(customField.id).filter(customFieldFilterUnassigned);

    const options = isEqual(customField.options, value)
      ? customField.options.map(customFiledOptionPredicate)
      : uniq([...value, ...customField.options]).map(customFiledOptionPredicate);

    return {
      name: `custom-field-${customField.id}`,
      label: customField.display_name,
      options,
    };
  }

  const getCustomFieldConfig = useCallback(getCustomFieldConfigCallBack, [customFields]);

  function handleClear(id: string) {
    removeCustomField(id, filterGroupId);
  }

  function handleSubmit(newValue: CustomFieldOption[], id: number) {
    updateCustomFieldValue({
      id,
      operator: {
        type: 'IN',
        operands: {
          type: 'DEPRECATED_ABSOLUTE',
          value: newValue ?? [],
        },
      },
      include_unassigned: newValue.includes(-1),
    });
  }

  function getCustomFieldValue(customFieldId: number) {
    const selectedCustomField = filterValues.custom_fields?.find(
      (customField) => customField.id === customFieldId
    ) as unknown as CustomFieldFilterValues<CustomFieldOperatorType>;

    return selectedCustomField
      ? {
          value: selectedCustomField.operator.operands.value,
          type: selectedCustomField.include_unassigned ? 'UNS' : selectedCustomField.operator.type,
          should_include_unassigned: selectedCustomField.include_unassigned,
        }
      : { should_include_unassigned: false };
  }

  function handleSubmitNumber(
    value: Partial<CustomFieldRangeValueType<CustomFieldOperatorType>>,
    id: number
  ) {
    updateFilterValues({
      type: 'UPDATE_CUSTOM_FIELDS',
      payload: {
        id,
        operator: {
          type:
            (value.type as CustomFieldOperatorType) === 'UNS'
              ? 'IN'
              : (value.type as CustomFieldOperatorType),
          operands: {
            type: 'DEPRECATED_ABSOLUTE',
            value: value.value,
          } as CustomFiledOperands<CustomFieldOperatorType>,
        },
        include_unassigned: value.should_include_unassigned ?? false,
      },
    });
  }

  if (!customFieldFilters) return null;

  return (
    <>
      {customFieldFilters.map((customFieldFilter) => {
        return (
          <ShowIfActive
            key={customFieldFilter.id}
            filterGroupId={filterGroupId}
            filterId={customFieldFilter.id}
            filterGroups={filterGroups}
            order={customFieldFilter.order}
          >
            {validNumberBasedFields.includes(customFieldFilter.filterObject.data_type) ? (
              <NumberBasedFilterBlock
                onClear={handleClear}
                handleSubmit={handleSubmitNumber}
                customField={customFieldFilter.filterObject}
                value={getCustomFieldValue(customFieldFilter.filterObject.id)}
                filterId={customFieldFilter.id}
                customFiledId={customFieldFilter.filterObject.id}
                currencySymbol={
                  customFieldFilter.filterObject.data_type === 'CURRENCY'
                    ? currencySymbol
                    : undefined
                }
                dataType={customFieldFilter.filterObject.data_type}
              />
            ) : (
              <CustomFieldFilterBlock
                searchable={customFieldFilter.filterObject.data_type === 'STRING'}
                onClear={handleClear}
                customField={customFieldFilter.filterObject}
                handleSubmit={handleSubmit}
                value={customFields(customFieldFilter.filterObject.id)}
                searchType="remote"
                customFiledId={customFieldFilter.filterObject.id}
                filterId={customFieldFilter.id}
                filterConfig={getCustomFieldConfig(customFieldFilter.filterObject)}
              />
            )}
          </ShowIfActive>
        );
      })}
    </>
  );
}
