import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import { MoreActionsProps } from './types';

const DefaultTitle = styled.div`
  cursor: pointer;
  background: var(--gray-1);
  padding: var(--space-8);
  border: 1px solid var(--gray-5);
  border-radius: var(--br-1);
`;

const t = {
  chooseAction: 'Choose Action',
};

const MoreActions = (props: MoreActionsProps) => {
  const { actionArea = 'OTHERS', style, removePadding } = props;
  // do not render trigger element if no options to display
  if (!props.options.length) return null;

  const MenuItems = () => {
    return (
      <Menu>
        {props.options.map((option, index) => (
          <Menu.Item
            className="dropdown-item"
            style={style}
            key={index}
            onClick={() => option.handleClick && option.handleClick()}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const RenderHeading = (
    <DefaultTitle className="default-title">
      <span style={{ marginRight: 'var(--space-8)' }}>{t.chooseAction}</span>
      <FontAwesomeIcon icon={['fal', 'chevron-down']} />
    </DefaultTitle>
  );

  return (
    <Dropdown
      // getPopupContainer={trigger => trigger.parentNode as HTMLElement}   //Needs verification
      overlay={MenuItems}
      trigger={['click']}
      className={`more-actions  drop${props.direction} ${props.className}`}
      onOpenChange={props.onVisibleChange}
    >
      <div
        role="button"
        style={
          actionArea === 'OTHERS' ? { padding: removePadding ? '0' : 'var(--space-8)' } : undefined
        }
      >
        {props.children ? props.children : RenderHeading}
      </div>
    </Dropdown>
  );
};

export default MoreActions;
