import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsMutating } from '@tanstack/react-query';
import { Flex } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { GrowText } from 'components/BaseComponents/Typography';
import useOpen from 'util/hooks/useOpen';
import FormModal from '../FormModal/FormModal';
import { CallLogModalFormElementProps, WriteOffFormModalProps } from '../types';
import { onFormSave } from '../Utils';
import WriteOffForm from './WriteOffForm';

export default function WriteOffFormModal<WriteOffElements extends CallLogModalFormElementProps>(
  props: Omit<WriteOffFormModalProps<WriteOffElements>, 'form' | 'remainder' | 'onFormClose'>
) {
  const { openModal, id, onClose } = props;
  const [form] = useForm();
  const isFormSaving = useIsMutating({
    mutationKey: ['activity-forms'],
  });

  const { open, onClose: modalClose } = useOpen({
    open: openModal,
  });

  function formSubmit() {
    onFormSave(form);
  }

  function onCancel() {
    modalClose();
    onClose();
  }

  const Header = (
    <Flex gap={'var(--space-8)'} align="center">
      <FontAwesomeIcon icon={['far', 'scissors']} fontSize={18} />
      <GrowText size="var(--fs-20)" strong>
        Write Off
      </GrowText>
    </Flex>
  );

  return (
    <FormModal
      open={open}
      onCancel={onCancel}
      formSubmit={formSubmit}
      isLoading={Boolean(isFormSaving)}
      headerTitle={Header}
      id={Number(id)}
      enableExtraAction={false}
    >
      <WriteOffForm<WriteOffElements> form={form} onFormClose={onCancel} {...props} />
    </FormModal>
  );
}
