export const t = {
  loggedOut: 'LOGGED_OUT',
  signOutChannel: 'SIGNOUT_CHANNEL',
};

export enum BroadCastChannelType {
  SIGNOUT_CHANNEL = 'SIGNOUT_CHANNEL',
}

export enum BroadCastEventType {
  LOGGED_OUT = 'LOGGED_OUT',
}

export type BroadCastChannelEvent = {
  channel: BroadCastChannelType;
  event: BroadCastEventType;
  message?: object;
};
