import { CUSTOMER_DETAILS_SECTION } from 'components/CustomerDetails/Body';

export enum COMMON_EVENT {
  CHOOSE_VIEW = 'Choose View',
  EDIT_VIEW = 'Edit View',
  SAVE_VIEW = 'Save View',
  CANCEL_VIEW = 'Cancel View',
  SAVE_AS_NEW = 'Save as new View',
  MULTI_CURRENCY_HOVER = 'Multi currency Hover',
  AMOUNT_HOVER = 'Number Hover',
  LOGIN = 'Login',
  LOG_OUT = 'Logout ',
  CUSTOM_FILTER_SELECTED = 'Custom Filter Selected',
  FILTER_FOCUS = 'Filter Focus',
  FILTER_APPLY = 'Filter Apply',
  FILTER_CANCEL = 'Filter Cancel',
  GLOBAL_SEARCH = 'Global Search',
  GLOABL_SEARCH_SHORTCUT_PRESSED = 'Global Search Shortcut Pressed',
  REMOVE_FILTER = 'Remove Filter',
  NAVIGATION = 'Navigation',
  COLLECTION_ACTION = 'Collection Action',
  VIEW_INVOICE_PDF = 'View Invoice PDF',
  EMAIL_PREVIEW = 'Email Preview',
  CHOOSE_INVOICES_EMAIL = 'Choose Invoices Email',
  EDIT_COLLECTION_STATUS = 'Edit Collection Status',
  SELECT_COLLECTION_STATUS = 'Select Collection Status',
  UPDATE_COLLECTION_STATUS = 'Update Collection Status',
  CANCEL_COLLECTION_STATUS = 'Cancel Collection Status',
  SORTING = 'SORTING',
  ACTION_WITHIN_COLLECTION_ACTIVITY_CREATION = 'Action within Collection Activity Creation',
  CONTACT_CARD_TOGGLE = 'Contact Card Toggle',
}

type COMMON_EVENTS_WITH_PROPS_PAYLOAD =
  | { type: COMMON_EVENT.MULTI_CURRENCY_HOVER; payload: { context: string } }
  | { type: COMMON_EVENT.AMOUNT_HOVER; payload: { tile: string } }
  | { type: COMMON_EVENT.CUSTOM_FILTER_SELECTED; payload: { selectedOption: string } }
  | {
      type: COMMON_EVENT.FILTER_APPLY;
      payload: {
        filterName: string;
        customFilter?: boolean;
        status: 'open' | 'close';
      };
    }
  | {
      type: COMMON_EVENT.FILTER_CANCEL;
      payload: {
        filterName: string;
        customFilter?: boolean;
      };
    }
  | {
      type: COMMON_EVENT.FILTER_FOCUS;
      payload: {
        filterName: string;
        status: 'open' | 'close';
      };
    }
  | {
      type: COMMON_EVENT.REMOVE_FILTER;
      payload: {
        filterName: string;
        customFilter?: boolean;
      };
    }
  | {
      type: COMMON_EVENT.GLOBAL_SEARCH;
      payload?: {
        searchOption: string;
      };
    }
  | {
      type: COMMON_EVENT.NAVIGATION;
      payload: {
        query: string;
        event: string;
      };
    }
  | {
      type: COMMON_EVENT.COLLECTION_ACTION;
      payload: {
        actionName?: string;
        actionType?: string;
        context?: string;
        currentView?: CUSTOMER_DETAILS_SECTION;
      };
    }
  | {
      type: COMMON_EVENT.VIEW_INVOICE_PDF;
      payload: {
        context: string;
      };
    }
  | {
      type: COMMON_EVENT.SORTING;
      payload: {
        column: string;
      };
    }
  | { type: COMMON_EVENT.CHOOSE_VIEW; payload: { filterOption: string; totalOptions: string[] } }
  | { type: COMMON_EVENT.SAVE_VIEW; payload: { filterOption: string } }
  | { type: COMMON_EVENT.SAVE_AS_NEW; payload: { filterOption: string } }
  | {
      type: COMMON_EVENT.ACTION_WITHIN_COLLECTION_ACTIVITY_CREATION;
      payload: { collectionAction?: string; actionName?: string };
    };

type COMMON_EVENTS_WITH_PROPS = COMMON_EVENTS_WITH_PROPS_PAYLOAD['type'];

type COMMON_EVENTS_WITHOUT_PROPS = Exclude<COMMON_EVENT, COMMON_EVENTS_WITH_PROPS>;

export type COMMON_EVENT_TYPES =
  | COMMON_EVENTS_WITH_PROPS_PAYLOAD
  | { type: COMMON_EVENTS_WITHOUT_PROPS; payload?: undefined };
