import { CellRenderProps, defaultCellComponents } from '@sinecycle/growcomponents';
import { Popover } from 'antd';
import { DueStatus } from 'components/CollectionActivities/Common/DueStatus/DueStatus';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { IInvoices } from 'store/customer/types';
import { InvoiceStatuses, invoiceStatusesConfig } from 'types/entities/invoice';

function DueDateCell(props: CellRenderProps<IInvoices>) {
  const { data } = props;
  const format = useSelector(dateFormatSelector);
  const Info = invoiceStatusesConfig.CLOSED.includes(
    data?.status as InvoiceStatuses
  ) ? undefined : data ? (
    <DueStatus days={data?.due_days} />
  ) : undefined;
  return (
    <Popover content={Info} placement="right" arrow={false}>
      {defaultCellComponents.dateCell({
        ...props,
        dateCellProps: { format },
        textCellProps: { style: { borderBottom: '1px dashed #737373' } },
      })}
    </Popover>
  );
}

export { DueDateCell };
