import { cleanedString } from 'components/BaseComponents/RTE/util/sanitizeSubject';
import { DunningRuleForm, RuleErrors } from 'store/collection-strategy-rule/initial-state';
import {
  EmailActionData,
  EscalationActionData,
  FollowupRuleActionForm,
  FollowupRuleActionType,
  TaskActionData,
} from 'types/entities/collection-strategy/rule-action';
import {
  FollowupEventType,
  FollowupRuleEventForm,
} from 'types/entities/collection-strategy/rule-event';
import {
  maxLengthErrorMessage,
  minCountErrorMessage,
  minLengthErrorMessage,
  requiredErrorMessage,
} from 'util/validation-messages';

export enum DunningRuleField {
  title = 'rule_title',
  event = 'rule_event',
}

export enum DunningRuleTaskField {
  title = 'task_title',
}

export enum DunningRuleEscalationField {
  assignee = 'escalation_assignee',
  description = 'description',
}

export enum DunningRuleEmailField {
  subject = 'email_subject',
  body = 'email_body',
  to = 'email_to',
}

export type RuleErrorField =
  | DunningRuleField
  | DunningRuleTaskField
  | DunningRuleEscalationField
  | DunningRuleEmailField;

const t = {
  title: 'Title',
  day: 'Day',
  subject: 'Subject',
  body: 'Body',
  to: 'To',
  from: 'From',
  notes: 'Notes',
  assignee: 'Assignee',
};

export function validateRuleTitle(title: string): RuleErrors {
  const errorState: RuleErrors = {};

  if (!title.length) {
    errorState[DunningRuleField.title] = requiredErrorMessage(t.title);
  } else if (title.length > 100) {
    errorState[DunningRuleField.title] = maxLengthErrorMessage(t.title, 100);
  } else {
    errorState[DunningRuleField.title] = undefined;
  }

  return errorState;
}

export function validateRuleEvent(event: FollowupRuleEventForm): RuleErrors {
  const errorState: RuleErrors = {};

  if (event.type === FollowupEventType.CALENDAR) {
    if (!event.data.days?.length) {
      errorState[DunningRuleField.event] = minCountErrorMessage(t.day, 1);
    } else {
      errorState[DunningRuleField.event] = undefined;
    }
  }

  return errorState;
}

export function validateEmail(email: Partial<EmailActionData>): RuleErrors {
  const errorState: RuleErrors = {};
  const subject = cleanedString(email.subject);
  const body = cleanedString(email.body);
  errorState[DunningRuleEmailField.subject] =
    !subject || !subject.length ? minLengthErrorMessage(t.subject, 2) : undefined;

  errorState[DunningRuleEmailField.body] =
    !body || !body.length ? requiredErrorMessage(t.body) : undefined;

  errorState[DunningRuleEmailField.to] = email?.to?.length ? undefined : requiredErrorMessage(t.to);

  return errorState;
}
export function resetEmailErrors(): RuleErrors {
  const errorState: RuleErrors = {};

  errorState[DunningRuleEmailField.subject] = undefined;
  errorState[DunningRuleEmailField.body] = undefined;
  errorState[DunningRuleEmailField.to] = undefined;

  return errorState;
}

export function validateTask(task: Partial<TaskActionData>): RuleErrors {
  const errorState: RuleErrors = {};

  errorState[DunningRuleTaskField.title] = task?.title?.length
    ? undefined
    : requiredErrorMessage(t.title);

  return errorState;
}
export function resetTaskErrors(): RuleErrors {
  const errorState: RuleErrors = {};

  errorState[DunningRuleTaskField.title] = undefined;

  return errorState;
}

export function validateEscalation(escalation: Partial<EscalationActionData>): RuleErrors {
  const errorState: RuleErrors = {};

  if (!escalation?.assign_to) {
    errorState[DunningRuleEscalationField.assignee] = requiredErrorMessage(t.assignee);
  } else {
    errorState[DunningRuleEscalationField.assignee] = undefined;
  }

  if (!escalation?.description) {
    errorState[DunningRuleEscalationField.description] = requiredErrorMessage(t.notes);
  } else {
    errorState[DunningRuleEscalationField.description] = undefined;
  }

  return errorState;
}
export function resetEscalationErrors(): RuleErrors {
  const errorState: RuleErrors = {};

  errorState[DunningRuleEscalationField.assignee] = undefined;

  return errorState;
}

export function validateAction(action: FollowupRuleActionForm): RuleErrors {
  switch (action.type) {
    case FollowupRuleActionType.EMAIL:
      return validateEmail(action.data);
    case FollowupRuleActionType.ESCALATION:
      return validateEscalation(action.data);
    case FollowupRuleActionType.TASK:
      return validateTask(action.data);
  }
}
export function resetActionErrors(): RuleErrors {
  return {
    ...resetEmailErrors(),
    ...resetTaskErrors(),
    ...resetEscalationErrors(),
  };
}

export function validateDunningRule(rule: DunningRuleForm) {
  return {
    ...validateRuleTitle(rule.data.title),
    ...validateRuleEvent(rule.data.event),
    ...validateAction(rule.data.action),
  };
}
