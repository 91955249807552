import { Flex, FlexBoxChild } from 'components/BaseComponents/Layout/Flex';
import DraftInvoicesSatements, {
  draftInvoiceStatus,
} from 'components/CustomerDetails/Body/Statements/DraftInvoiceSatements/DraftInvoicesSatements';
import FiltersLoader from 'components/FilterViews/Loader/FiltersLoaderLoader';
import FilterStrip from 'components/FilterViews/Strips/FilterStrips';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { useFormattedURLSearchParam } from 'hooks/use-formatted-url-searchparam';
import { useQuickActions } from 'hooks/use-quick-actions';
import produce from 'immer';
import { usePageNavigate } from 'lib/router';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCollectionPeriods, getSavedCollectionPeriods } from 'services/collection-period';
import { getSavedFilterViews } from 'services/filter-view';
import { CollectionPeriodActions } from 'store/collection-period';
import { CollectionPeriodsHistory } from 'store/collectors-performace/type';
import {
  DraftCurrentPage,
  draftInvoiceCurrentFilterSelector,
  draftInvoiceQuerParams,
} from 'store/invoice-v2/draft-invoice-selector';
import {
  resetDraftInvoiceState,
  updateDraftCurrentPage,
  updateDraftInvoiceCurrentFilters,
  updateDraftInvoiceCurrentFilterview,
  updateDraftInvoiceQuerParams,
  updateDraftInvoiceSavedFilterview,
  updateTotalCustomerCount,
} from 'store/invoice-v2/invoice';
import { DraftInvoicesListFilters } from 'store/invoice/type';
import { InvoiceFilterView } from 'types/filter-view/common';
import { DraftInvoiceFilterParams } from 'types/filter-view/draft-invoice';
import { DraftInvoiceFilterHeader } from './DraftInvoiceFilters/DraftInvoiceFilterHeaderProps';

function DraftInvoiceList() {
  const hasSavedFilterUpdated = useRef(false);
  const queryParams = useFormattedURLSearchParam();
  const persistedFilter = useSelector(draftInvoiceCurrentFilterSelector);
  const currentPage = useSelector(DraftCurrentPage);
  const draftInvoiceQueryParams = useSelector(draftInvoiceQuerParams);
  const navigate = usePageNavigate();
  const location = useLocation();
  const currentFilterParam = useMemo(() => {
    return draftInvoiceQueryParams ?? persistedFilter;
  }, [draftInvoiceQueryParams, persistedFilter]);
  const currentFilter =
    currentFilterParam &&
    produce(currentFilterParam, (draft) => {
      draft.status = draftInvoiceStatus;
      return draft;
    });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const {
    selectAll,
    setSelectAll,
    setQuickActivitiesCallback,
    setQuickActionsCallback,
    setTotalRecords,
    QuickActions,
    action,
  } = useQuickActions({ currentFilter, queryKey: ['draft-invoice-list'] });

  const hasActOnDraftInvoiceAccess = usePrivilegeStatus([
    ERolesAndPrivileges.ACT_DRAFT_INVOICES_INVOICE_LIST,
  ]);

  function updateQueryParams() {
    if (queryParams) {
      dispatch(updateDraftInvoiceQuerParams(queryParams as DraftInvoiceFilterParams));
    }
  }

  function handleGetSaveCollectionPeriods(optionIds: number[], persistedIds?: number[]) {
    if (persistedIds && Boolean(persistedIds.length)) {
      const filterIds: number[] = persistedIds.filter((d) => !optionIds.includes(d));
      if (filterIds.length) {
        getSavedCollectionPeriods(filterIds)
          .then((data) => {
            dispatch(
              CollectionPeriodActions.updateCollectionPeriodFilters({
                key: 'invoice-list',
                filter: data,
              })
            );
            hasSavedFilterUpdated.current = true;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }

  const getSavedCollectionPeriodsCallBack = useCallback(handleGetSaveCollectionPeriods, [dispatch]);

  function handleSuccessResolve(data: CollectionPeriodsHistory[]) {
    dispatch(
      CollectionPeriodActions.initializeCollectionPeriodFilters({
        key: 'invoice-list',
        filter: data,
      })
    );
    if (currentFilter?.collection_period_ids?.length) {
      const savedFilterIds = currentFilter?.collection_period_ids?.filter(
        (filterId) => filterId !== -1
      );
      const optionIds = data.map((option) => option.id);
      getSavedCollectionPeriodsCallBack(optionIds, savedFilterIds);
    } else {
      hasSavedFilterUpdated.current = true;
    }
  }

  const successResolveCallBack = useCallback(handleSuccessResolve, [
    currentFilter?.collection_period_ids,
    dispatch,
    getSavedCollectionPeriodsCallBack,
  ]);

  function handleResolveReject(e: any) {
    console.log(e);
  }
  function fetchCollectionPeriods() {
    if (!hasSavedFilterUpdated.current) {
      getCollectionPeriods().then(successResolveCallBack).catch(handleResolveReject);
    }
  }
  useEffect(updateQueryParams, [dispatch, queryParams]);
  useEffect(() => {
    return () => {
      //replacing the query params when shifting to another tab
      if (queryParams) {
        dispatch(updateDraftInvoiceQuerParams(undefined));
        //replacing history to remove query param when changing tabs.
        if (location.pathname.match('/invoice-list')) {
          navigate({ appPage: 'INVOICE_LIST' });
        }
      }

      dispatch(resetDraftInvoiceState());
    };
  }, [dispatch, navigate, queryParams, location.pathname]);

  useEffect(() => {
    function updateSavedFilterViews(filterViews: unknown) {
      dispatch(
        updateDraftInvoiceSavedFilterview(
          filterViews as InvoiceFilterView<DraftInvoiceFilterParams>[]
        )
      );
    }

    function updateCurrentFilterView() {
      dispatch(updateDraftInvoiceCurrentFilterview());
    }

    setIsLoading(true);
    getSavedFilterViews('draft-invoices')
      .then((filterViews) => {
        updateSavedFilterViews(filterViews);
        updateCurrentFilterView();
        hasSavedFilterUpdated.current = true;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const updateCurrentFilter = useCallback(
    (filter: unknown) => {
      if (queryParams) {
        dispatch(updateDraftInvoiceQuerParams(filter as DraftInvoiceFilterParams));
      } else {
        dispatch(
          updateDraftInvoiceCurrentFilters({
            ...(filter as DraftInvoiceFilterParams),
          } as DraftInvoiceFilterParams)
        );
      }
    },
    [dispatch, queryParams]
  );

  useEffect(fetchCollectionPeriods, [successResolveCallBack]);

  const DraftInvoiceStatement = (
    <DraftInvoicesSatements
      scroll={{
        x: 1900,
        y: 'calc(100vh - var(--app-header-height) - var(--draft-invoice-fixed-contents-height-invoice-details))',
      }}
      isFromInvoiceList
      hasActionAccess={hasActOnDraftInvoiceAccess}
      isLoading={isLoading}
      currentFilter={currentFilter as unknown as DraftInvoicesListFilters}
      invoiceStatusList={[]}
      saveFilterViews={updateCurrentFilter}
      setQuickAction={setQuickActivitiesCallback}
      setAction={setQuickActionsCallback}
      selectAll={selectAll}
      action={action}
      setSelectAll={setSelectAll}
      setTotalRecords={setTotalRecords}
      setCustomerTotalRecords={(records) => {
        dispatch(updateTotalCustomerCount(records as number));
      }}
      currentPage={currentPage}
      handlePageChange={(page) => {
        dispatch(updateDraftCurrentPage(page));
      }}
      hasFilterViewLoaded={hasSavedFilterUpdated.current}
    />
  );

  return (
    <Flex className="open-invoice-list" direction="column" style={{ height: '100%' }}>
      <FilterStrip style={{ minHeight: '62px', boxShadow: 'var(--shadow-1)' }}>
        <FiltersLoader loading={!currentFilterParam} gutter={[8, 8]}>
          <DraftInvoiceFilterHeader
            currentFilter={currentFilterParam as unknown as DraftInvoicesListFilters} //will have to be removedno //need to change
            setCurrentFilter={updateCurrentFilter}
          />
        </FiltersLoader>
      </FilterStrip>
      <FlexBoxChild className="statement" style={{ padding: 'var(--space-24)' }} flex="1">
        {DraftInvoiceStatement}
      </FlexBoxChild>
      {QuickActions}
    </Flex>
  );
}

export default memo(DraftInvoiceList);
