import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsMutating } from '@tanstack/react-query';
import { Flex } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { GrowText } from 'components/BaseComponents/Typography';
import useOpen from 'util/hooks/useOpen';
import FormModal from '../FormModal/FormModal';
import { activityText } from '../text';
import { EscalationFormModalProps, EscalationModalFormElementProps } from '../types';
import { onFormSave } from '../Utils';
import EscalationForm from './EscalationForm';

export default function EscalationFormModal<
  EscalationElements extends EscalationModalFormElementProps
>(props: Omit<EscalationFormModalProps<EscalationElements>, 'form' | 'remainder' | 'onFormClose'>) {
  const { openModal, id, onClose, disableRemainder, editMode } = props;
  const [form] = useForm();
  const isFormSaving = useIsMutating({
    mutationKey: ['activity-forms'],
  });

  const {
    open: openRemainder,
    onClose: closeRemainder,
    toggleOpen,
  } = useOpen({
    open: false,
  });
  const { open, onClose: modalClose } = useOpen({
    open: openModal,
  });

  function formSubmit() {
    onFormSave(form);
  }

  function onCancel() {
    modalClose();
    onClose();
    closeRemainder();
  }

  const Header = (
    <Flex gap={'var(--space-8)'} align="center">
      <FontAwesomeIcon
        icon={['far', 'exclamation-circle']}
        color="var(--volcano-8)"
        fontSize={18}
      />
      <GrowText size="var(--fs-20)" strong>
        {editMode ? activityText.edit_escalation : activityText.create_escalation}
      </GrowText>
    </Flex>
  );

  return (
    <FormModal
      open={open}
      onCancel={onCancel}
      formSubmit={formSubmit}
      isLoading={Boolean(isFormSaving)}
      disableRemainder={disableRemainder}
      setRemainder={toggleOpen}
      headerTitle={Header}
      id={Number(id)}
      enableExtraAction={!editMode}
    >
      <EscalationForm<EscalationElements>
        form={form}
        remainder={openRemainder}
        onFormClose={onCancel}
        {...props}
      />
    </FormModal>
  );
}
