import { FilterConfigWrapperProps } from '../FilterComponents/FilterWrapper/type';

export enum CustomerHierarchy {
  PARENT = 'PARENT',
  CHILD = 'CHILD',
  SINGLE = 'SINGLE',
  ALL = 'ALL',
}

type CustomerHierachyDropdownItem = {
  label: string;
  value: CustomerHierarchy;
};

const CustomerHierachyDropDownList: CustomerHierachyDropdownItem[] = [
  {
    label: 'Parent',
    value: CustomerHierarchy.PARENT,
  },
  {
    label: 'Child',
    value: CustomerHierarchy.CHILD,
  },
  {
    label: 'Independent',
    value: CustomerHierarchy.SINGLE,
  },
];

export const getHierarchyFilterConfig = (): FilterConfigWrapperProps => {
  return {
    name: 'Hierarchy',
    label: 'Hierarchy',
    options: CustomerHierachyDropDownList.map((item) => ({
      label: item.label,
      value: item.value,
    })),
  };
};
