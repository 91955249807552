import { createSlice } from '@reduxjs/toolkit';
import { ClosedInvoiceFilterParams } from 'types/filter-view/closed-invoice';
import { InvoiceFilterView } from 'types/filter-view/common';
import { DraftInvoiceFilterParams } from 'types/filter-view/draft-invoice';
import { OpenInvoiceFilterParams } from 'types/filter-view/open-invoice';
import { closedInvoiceActions } from './closed-invoice-actions';
import {
  closedInvoiceCurrentFilterSelector,
  closedInvoiceCurrentFilterViewSelector,
  closedInvoiceSavedFilterViewsSelector,
} from './closed-invoice-selectors';
import { draftInvoiceActions } from './draft-invoice-actions';
import {
  draftInvoiceCurrentFilterSelector,
  draftInvoiceCurrentFilterViewSelector,
  draftInvoiceQuerParams,
  draftInvoiceSavedFilterViewsSelector,
} from './draft-invoice-selector';
import { openInvoiceActions } from './open-invoice-actions';
import {
  openInvoiceCurrentFilterSelector,
  openInvoiceCurrentFilterViewSelector,
  openInvoiceQuerParams,
  openInvoiceSavedFilterViewsSelector,
} from './open-invoice-selectors';

export interface intitalStateProps {
  openInvoiceSavedFilterviews: InvoiceFilterView<OpenInvoiceFilterParams>[];
  draftInvoiceSavedFilterviews: InvoiceFilterView<DraftInvoiceFilterParams>[];
  closedInvoiceSavedFilterviews: InvoiceFilterView<OpenInvoiceFilterParams>[];
  openInvoiceCurrentFilterView?: InvoiceFilterView<OpenInvoiceFilterParams>;
  draftInvoiceCurrentFilterView?: InvoiceFilterView<DraftInvoiceFilterParams>;
  closedInvoiceCurrentFilterView?: InvoiceFilterView<ClosedInvoiceFilterParams>;
  openInvoiceFilterQueryParams?: OpenInvoiceFilterParams;
  draftInvoiceFilterQueryParams?: DraftInvoiceFilterParams;
  totalCustomerCount: number;
  openCurrentPage: number;
  closedCurrentPage: number;
  draftCurrentPage: number;
}

const initialState: intitalStateProps = {
  openInvoiceSavedFilterviews: [],
  closedInvoiceSavedFilterviews: [],
  openInvoiceCurrentFilterView: {} as intitalStateProps['openInvoiceCurrentFilterView'],
  draftInvoiceCurrentFilterView: {} as intitalStateProps['draftInvoiceCurrentFilterView'],
  closedInvoiceCurrentFilterView: {} as intitalStateProps['closedInvoiceCurrentFilterView'],
  totalCustomerCount: 0,
  openCurrentPage: 1,
  closedCurrentPage: 1,
  draftInvoiceSavedFilterviews: [],
  draftCurrentPage: 1,
};

const InvoiceListSlice = createSlice({
  name: 'invoiceList',
  initialState,
  reducers: {
    ...openInvoiceActions,
    ...closedInvoiceActions,
    ...draftInvoiceActions,
  },
});

const {
  updateOpenInvoiceSavedFilterview,
  updateOpenInvoiceCurrentFilterview,
  updateOpenInvoiceCurrentFilters,
  updateOpenInvoiceQuerParams,
  updateOpenCurrentPage,
  resetOpenCurrentPage,

  //closed actions
  updateClosedInvoiceSavedFilterview,
  updateClosedInvoiceCurrentFilterview,
  updateClosedInvoiceCurrentFilters,
  updateTotalCustomerCount,
  resetOpenInvoiceState,
  resetClosedInvoiceState,
  updateClosedCurrentPage,
  resetClosedCurrentPage,

  //draft
  updateDraftInvoiceSavedFilterview,
  updateDraftCurrentPage,
  updateDraftInvoiceCurrentFilterview,
  updateDraftInvoiceQuerParams,
  resetDraftInvoiceState,
  updateDraftInvoiceCurrentFilters,
} = InvoiceListSlice.actions;

export {
  closedInvoiceCurrentFilterSelector,
  closedInvoiceCurrentFilterViewSelector,
  closedInvoiceSavedFilterViewsSelector,
  draftInvoiceCurrentFilterSelector,
  draftInvoiceCurrentFilterViewSelector,
  draftInvoiceQuerParams,
  draftInvoiceSavedFilterViewsSelector,
  openInvoiceCurrentFilterSelector,
  openInvoiceCurrentFilterViewSelector,
  openInvoiceQuerParams,
  openInvoiceSavedFilterViewsSelector,
  resetClosedCurrentPage,
  resetClosedInvoiceState,
  resetDraftInvoiceState,
  resetOpenCurrentPage,
  resetOpenInvoiceState,
  updateClosedCurrentPage,
  updateClosedInvoiceCurrentFilters,
  updateClosedInvoiceCurrentFilterview,
  updateClosedInvoiceSavedFilterview,
  updateDraftCurrentPage,
  updateDraftInvoiceCurrentFilters,
  updateDraftInvoiceCurrentFilterview,
  updateDraftInvoiceQuerParams,
  updateDraftInvoiceSavedFilterview,
  updateOpenCurrentPage,
  updateOpenInvoiceCurrentFilters,
  updateOpenInvoiceCurrentFilterview,
  updateOpenInvoiceQuerParams,
  updateOpenInvoiceSavedFilterview,
  updateTotalCustomerCount,
};

export default InvoiceListSlice.reducer;
