import { useSubsidiariesQuery } from 'queries/subsidaries';
import { useMemo } from 'react';
import { getCurrencySymbol } from 'util/number-formatter';
import { MultiSelectCheckboxWrapper } from '../FilterComponents/FilterWrapper/MultiSelectCheckboxWrapper';
import { SingleSelectWrapper } from '../FilterComponents/FilterWrapper/SingleSelectWrapper';
import { CustomerHierarchy, getHierarchyFilterConfig } from './type';

interface MultiSelectArray<T> {
  value?: T[];
  onSubmit: (value: T[]) => void;
  onClear?: () => void;
  shouldIncludeUnassigned?: boolean;
}

interface SingleSelectType<T> {
  value?: T;
  onSubmit: (value: T) => void;
  onClear?: () => void;
  shouldIncludeUnassigned?: boolean;
}

export function SubsidiaryFilter(props: MultiSelectArray<number>) {
  const { value, onSubmit, onClear } = props;
  const { data: subsidaries } = useSubsidiariesQuery();

  const subsidiaryFilterConfig = useMemo(() => {
    return {
      name: 'Subsidiary',
      label: 'Subsidiary',
      options:
        subsidaries?.map((subsidiary) => {
          const currency = getCurrencySymbol(subsidiary.currency);
          const label = subsidiary.currency
            ? `${subsidiary.display_name} (${currency} ${subsidiary.currency})`
            : subsidiary.display_name ?? subsidiary.name;
          return { label, value: subsidiary.id };
        }) ?? [],
    };
  }, [subsidaries]);

  return (
    <MultiSelectCheckboxWrapper
      shouldIncludeUnassigned
      filterConfig={subsidiaryFilterConfig}
      value={value}
      showClear
      onClear={onClear}
      onSubmit={onSubmit}
    />
  );
}

export function HierarchyFilter(props: SingleSelectType<CustomerHierarchy>) {
  const { value, onSubmit, onClear } = props;

  const hierarchyFilterConfig = useMemo(getHierarchyFilterConfig, []);
  return (
    <SingleSelectWrapper
      filterConfig={hierarchyFilterConfig}
      value={value}
      showClear
      onClear={onClear}
      onSubmit={onSubmit}
    />
  );
}
