import { RootState } from 'reducers/rootReducer';
import { CollectionPeriodsHistory } from './type';

export const cprCurrentFilterSelector = (state: RootState) =>
  state.collectorsPerformance.currentFilter ?? ({} as CollectionPeriodsHistory);

export const cprCollectionPeriodsSelector = (state: RootState) =>
  state.collectorsPerformance.collectionPeriods;

export const cprCurrentPageSelector = (state: RootState) => state.collectorsPerformance.page;
