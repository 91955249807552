import { Flex } from 'components/BaseComponents/Layout/Flex';
import React from 'react';
import styled from 'styled-components';

interface FilterStripProps {
  hide?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const StyledStrip = styled(Flex)`
  padding: var(--space-20) var(--space-24);
  background-color: var(--geekblue-1);
  border-bottom: ${(props) => props.style?.borderBottom};
  box-shadow: ${(props) => props.style?.boxShadow};
  min-height: ${(props) => props.style?.minHeight};
  height: ${(props) => props.style?.height};
  padding: ${(props) => props.style?.padding};
`;

export default function FilterStrip(props: Readonly<FilterStripProps>) {
  if (props.hide) return null;
  return (
    <StyledStrip
      justify="space-between"
      align="center"
      style={props.style}
      className={props.className}
    >
      {props.children}
    </StyledStrip>
  );
}
