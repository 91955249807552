import { Flex } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { AttributeTable, EmptyPlaceHolder } from 'components/Common/ActivitiesModal/style';
import { InvoiceLink } from 'components/Common/InvoiceLink';
import { Amount } from 'components/Common/MultiCurrency';
import { activityText } from '../text';
import { InvoiceFieldMapping, P2PTableFormProps } from '../types';
import PTPAmountInput from './PTPAmountInput';

export default function P2PTableForm(props: P2PTableFormProps) {
  const { invoiceList, customerCurrency, totalAmount, form } = props;

  const isData = Boolean(invoiceList?.length);
  const columns: ColumnsType<InvoiceFieldMapping> = [
    {
      key: 'invoiceNo',
      width: 90,
      title() {
        return <TypographyText strong>Invoice ID</TypographyText>;
      },
      render(value, record) {
        return <InvoiceLink invoiceId={record?.id} invoiceNumber={value} />;
      },
      dataIndex: 'invoiceNo',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      key: 'balanceDueAmount',
      title() {
        return <TypographyText strong>Invoice Balance</TypographyText>;
      },
      render(value, record) {
        return <Amount amount={value} currency={record.invoiceCurrency} />;
      },
      dataIndex: 'balanceDueAmount',
      align: 'right',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      key: 'ptpAmount',
      title() {
        return (
          <TypographyText
            strong
            ellipsis={{ tooltip: 'Existing PTP Balance' }}
            style={{ lineHeight: '1.5' }}
          >
            Existing PTP Balance
          </TypographyText>
        );
      },
      render(value, record) {
        return <Amount amount={value ?? 0} currency={record.invoiceCurrency} />;
      },
      align: 'right',
      dataIndex: 'ptpAmount',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      key: 'amount',
      title() {
        return <TypographyText strong>PTP Amount</TypographyText>;
      },
      width: 200,
      render(value, record) {
        const maxAmount = record?.amount ? Math.max(record?.amount, 0) : 0;

        return (
          <PTPAmountInput
            amount={maxAmount}
            record={record}
            form={form}
            customerCurrency={customerCurrency}
          />
        );
      },
      dataIndex: 'amount',
      align: 'right',
    },
  ];

  const noData = (
    <EmptyPlaceHolder justify="center" align="center">
      Add Invoices to set payment amount
    </EmptyPlaceHolder>
  );

  return (
    <AttributeTable
      design={isData ? 'data' : 'empty'}
      showHeader={isData}
      columns={columns}
      dataSource={invoiceList}
      pagination={false}
      style={{
        boxShadow: isData ? 'var(--shadow-2)' : 'none',
        border: isData ? '1px solid var(--gray-4)' : '0',
        borderRadius: 'var(--br-1)',
      }}
      scroll={{ y: 200 }}
      locale={{
        emptyText() {
          return noData;
        },
      }}
      rowKey={(record) => record.id}
      footer={() => {
        return isData ? (
          <Flex justify="space-between" style={{ width: '100%' }}>
            <Flex>
              <TypographyText strong>{activityText.total_amount}</TypographyText>
            </Flex>
            <Flex>
              <Amount
                amount={totalAmount ?? 0}
                currency={customerCurrency}
                style={{ fontWeight: 'var(--fs-semibold)' }}
              />
            </Flex>
          </Flex>
        ) : null;
      }}
    />
  );
}
