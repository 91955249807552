import { generateUniqueID } from 'components/Common/Contact';
import { stakeHolderListAttributes } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import {
  concat,
  cond,
  debounce,
  has,
  intersectionBy,
  isEqual,
  isString,
  map,
  stubTrue,
  uniqBy,
} from 'lodash';
import { useState } from 'react';
import { AssignedToUsers } from 'store/activity-feed/type';
import { BaseRecepientsType } from 'types/entities/collection-strategy/contact-type';
import { computedGroupedOptions } from '../predicate';
import { useGetAssigneesProps, useGetAssigneesReturnProps } from '../types';
import {
  InternalAssignedToRecepient,
  transformToGroupOptions,
  useComputeInternalContactRecepient,
} from '../Utils';
import useSearchUsers from './useSearchUsers';

export default function useGetAssignees(props: useGetAssigneesProps): useGetAssigneesReturnProps {
  const { assigneeUserList, assigneeOptionType, context, onChange, value } = props;
  const [searchOptions, setSearchOptions] = useState<stakeHolderListAttributes[]>([]);
  const [searchSelectionTo, setSearchSelectionTo] = useState<stakeHolderListAttributes[]>([]);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const optionsMap: Map<number, stakeHolderListAttributes> = new Map();
  const handleSearch = useSearchUsers();

  function onChangeUser(usersList: AssignedToUsers[]) {
    const selection = InternalAssignedToRecepient(usersList, context);
    const selectionsToAdd = intersectionBy(searchOptions, selection, 'id');

    if (selectionsToAdd && Boolean(selectionsToAdd.length)) {
      setSearchSelectionTo((oldValue) => {
        const uniqueValues = uniqBy(concat(oldValue, selectionsToAdd), 'email');

        return uniqueValues;
      });
    }

    setSearch(undefined);
    setSearchOptions([]);
    onChange?.(selection);
  }

  function generateIdForUser(item: AssignedToUsers) {
    return generateUniqueID(
      isEqual(item.association_level, 'CUSTOMER')
        ? 'CUSTOMER_INTERNAL_CONTACT'
        : 'INVOICE_INTERNAL_CONTACT',
      item.user_type as string
    );
  }

  function generateIdForPOC(item: AssignedToUsers) {
    return generateUniqueID(
      isEqual(item.association_level, 'CUSTOMER')
        ? 'CUSTOMER_INTERNAL_CONTACT'
        : 'INVOICE_INTERNAL_CONTACT',
      item.value as string
    );
  }

  const selectedUsers = map(
    value,
    cond<AssignedToUsers, string | number | undefined>([
      [
        (item) =>
          isEqual(item.type, BaseRecepientsType.USER) &&
          has(item, 'association_level') &&
          has(item, 'user_type'),
        generateIdForUser,
      ],
      [
        (item) => isEqual(item.type, BaseRecepientsType.POC) && isString(item.value),
        generateIdForPOC,
      ],
      [stubTrue, (item) => item.id],
    ])
  );

  const { computedGroupedOptions: usersTemplate, isLoading } = useComputeInternalContactRecepient({
    entity: assigneeOptionType,
  });

  const transformedSearchOptions = transformToGroupOptions(searchOptions);

  const options = computedGroupedOptions({
    search,
    searchOptionsList: transformedSearchOptions,
    activityRecepients: usersTemplate,
    searchSelectionTo,
    assigneeUserList: assigneeUserList,
  });

  options &&
    map(options, (optionItem) => {
      return map(optionItem.options, (item) => optionsMap.set(item.id, item));
    });

  const onSearch = debounce((searchTerm: string) => {
    handleSearch(searchTerm, setSearchOptions);
    setSearch(searchTerm);
  }, 800);

  return {
    options,
    optionsMap,
    selectedUsers,
    onChangeUser,
    onSearch,
    setSearch,
    isLoading,
  };
}
