import {
  BetweenRelativeOperands,
  DeprecatedBetweenAbsoluteOperands,
  DeprecatedGreaterThanAbsoluteOperands,
  DeprecatedInAbsoluteOperands,
  DeprecatedLessThanAbsoluteeOperands,
  DprecatedEqualToAbsoluteOperands,
  EqualToRelativeOperands,
  GreaterThanOrEqualsRelativeOperands,
  InRelativeOperands,
  LessThanOrEqualsRelativeOperands,
} from './operands';

export enum InvoiceSegmentOperatorType {
  GREATER_THAN = 'GOE',
  LESS_THAN = 'LOE',
  EQUAL_TO = 'EQ',
  BETWEEN = 'BTWN',
  IN = 'IN',
}

export type invoiceSegmentOperatorOptions = {
  label: string;
  value: InvoiceSegmentOperatorType;
};

interface BaseOperator {
  type: InvoiceSegmentOperatorType;
}

export interface GreaterThanOperator<T extends unknown> extends BaseOperator {
  type: InvoiceSegmentOperatorType.GREATER_THAN;
  operands: GreaterThanOrEqualsRelativeOperands | DeprecatedGreaterThanAbsoluteOperands<T>;
}

export interface LessThanOperator<T extends unknown> extends BaseOperator {
  type: InvoiceSegmentOperatorType.LESS_THAN;
  operands: LessThanOrEqualsRelativeOperands | DeprecatedLessThanAbsoluteeOperands<T>;
}

export interface InOperator<T extends unknown> extends BaseOperator {
  type: InvoiceSegmentOperatorType.IN;
  operands: InRelativeOperands | DeprecatedInAbsoluteOperands<T>;
}

export interface BetweenOperator<T extends unknown> extends BaseOperator {
  type: InvoiceSegmentOperatorType.BETWEEN;
  operands: BetweenRelativeOperands | DeprecatedBetweenAbsoluteOperands<T>;
}

export interface EqualToOperator<T extends unknown> extends BaseOperator {
  type: InvoiceSegmentOperatorType.EQUAL_TO;
  operands: EqualToRelativeOperands | DprecatedEqualToAbsoluteOperands<T>;
}

export type SegmentFilterOperator<T extends unknown> =
  | GreaterThanOperator<T>
  | LessThanOperator<T>
  | InOperator<T>
  | BetweenOperator<T>
  | EqualToOperator<T>;

export const operatorOptions: invoiceSegmentOperatorOptions[] = [
  {
    label: 'Greater than or equals',
    value: InvoiceSegmentOperatorType.GREATER_THAN,
  },
  {
    label: 'Equals',
    value: InvoiceSegmentOperatorType.IN,
  },
  {
    label: 'Less than or equals',
    value: InvoiceSegmentOperatorType.LESS_THAN,
  },
  {
    label: 'Between',
    value: InvoiceSegmentOperatorType.BETWEEN,
  },
];
