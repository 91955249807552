import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Menu } from 'antd';
import { MenuOptions } from 'components/BaseComponents/Menu/Antd/type';
import { notify } from 'components/BaseComponents/Notifications';
import { DocPreview } from 'components/HigherOrderComponent/DocViewer';
import { COMMON_EVENT } from 'events/common';
import { dispatchAppEvent } from 'lib/pub-sub';
import React, { useState } from 'react';
import { getInvoiceUrl } from 'services/invoice';
import { FileType } from 'types/common/attachments';

export type PDFInvoices = { invoiceNumber: string; invoiceId: number };
interface ViewInvoiceButtonProps {
  invoices: PDFInvoices[];
  title?: string;
  loading?: boolean;
  text?: string;
}
export function ViewInvoicePDFButton(props: ViewInvoiceButtonProps) {
  const [currentDocUrl, setCurrentDocUrl] = useState('');
  const [loading, setLoading] = useState(props.loading);
  const moreThanOneInvoice = props.invoices.length > 1;

  function getItems(): MenuOptions {
    return props.invoices.map((invoice) => {
      return {
        key: invoice.invoiceNumber,
        label: invoice.invoiceNumber,
        onClick: () => {
          handleSingleInvoiceOpen(invoice.invoiceId);
        },
      };
    });
  }

  const items = getItems();
  const InvoicesList = <Menu items={items} />;

  function handleSingleInvoiceOpen(invoiceId: number) {
    notifyEvent();
    setLoading(true);
    getInvoiceUrl(invoiceId)
      .then((invoiceUrl) => {
        setCurrentDocUrl(invoiceUrl);
      })
      .catch((e) => {
        notify.error('Failed to fetch invoice PDF');
        console.log('error fetching invoice', e);
      })
      .finally(() => setLoading(false));
  }

  function notifyEvent() {
    dispatchAppEvent({
      type: COMMON_EVENT.VIEW_INVOICE_PDF,
      payload: { context: props.title ?? '' },
    });
  }

  const invoiceButtonStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--space-8)',
    padding: 'var(--space-8)',
    color: 'var(--primary-7)',
  };

  function handleInvoiceButtonClick() {
    // in case of multiple invoices, trigger button should only open dropdown
    if (props.invoices.length === 1) {
      handleSingleInvoiceOpen(props.invoices[0].invoiceId);
    }
  }

  return (
    <>
      <Dropdown
        menu={{
          items: moreThanOneInvoice ? items : [],
          style: { maxHeight: '200px', overflow: 'auto' },
        }}
      >
        <Button
          onClick={handleInvoiceButtonClick}
          loading={loading}
          style={invoiceButtonStyle}
          icon={<FontAwesomeIcon icon={['far', 'file-pdf']} color="var(--red-7)" />}
        >
          {props.text}
          {moreThanOneInvoice && (
            <FontAwesomeIcon icon={['far', 'chevron-down']} color="var(--primary-7)" />
          )}
        </Button>
      </Dropdown>
      <DocPreview
        width="60%"
        closeIcon={false}
        open={Boolean(currentDocUrl)}
        onClose={() => setCurrentDocUrl('')}
        destroyOnClose={true}
        docType={FileType.PDF}
        docUrl={currentDocUrl}
      />
    </>
  );
}
