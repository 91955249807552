import { InvoiceStatus } from 'types/entities/invoice';
import { InvoiceStatusConfig } from './type';

export const invoiceStatusConfig: InvoiceStatusConfig = {
  [InvoiceStatus.PENDING]: {
    label: 'Payment Pending',
    style: {
      backgroundColor: 'var(--orange-1)',
      textColor: 'var(--orange-6)',
    },
  },
  [InvoiceStatus.REOPEN]: {
    label: 'Re-open',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-6);',
    },
  },
  [InvoiceStatus.DISPUTE]: {
    label: 'In Dispute',
    style: {
      backgroundColor: 'var(--cyan-1)',
      textColor: 'var(--cyan-6);',
    },
  },
  [InvoiceStatus.PARTIAL_PAYMENT]: {
    label: 'Partially Paid',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-6);',
    },
  },
  [InvoiceStatus.FULL_PAYMENT]: {
    label: 'Fully Paid',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-6);',
    },
  },
  [InvoiceStatus.WRITE_OFF]: {
    label: 'Write off',
    style: {
      backgroundColor: 'var(--red-1)',
      textColor: 'var(--red-6);',
    },
  },
  [InvoiceStatus.CLOSED]: {
    label: 'Closed',
    style: {
      backgroundColor: 'var(--red-1)',
      textColor: 'var(--red-6);',
    },
  },
  [InvoiceStatus.VOID]: {
    label: 'Void',
    style: {
      backgroundColor: 'var(--volcano-1)',
      textColor: 'var(--volcano-6);',
    },
  },
  [InvoiceStatus.DRAFT]: {
    label: 'Draft',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-6);',
    },
  },
};

export function getInvoiceStatusConfig(status: InvoiceStatus) {
  return invoiceStatusConfig[status];
}
