import { RadioGroup } from 'components/BaseComponents/Radio';
import { GenericRadioOptions } from 'components/BaseComponents/Radio/Antd/type';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { InvoiceListTabKeys } from 'components/InvoiceListV2';
import dayjs from 'dayjs';
import { AR_AGING_EVENT } from 'events/ar-aging';
import { INVOICE_LIST_EVENT } from 'events/invoice-List';
import { useDownloadDocument } from 'hooks/use-download-document';
import { dispatchAppEvent } from 'lib/pub-sub';
import { useState } from 'react';
import { AllCustomersFilter } from 'store/AllCustomers/type';
import { agingFilter } from 'store/aging/types';
import { ClosedInvoiceFilterParams } from 'types/filter-view/closed-invoice';
import { OpenInvoiceFilterParams } from 'types/filter-view/open-invoice';
import { getDateStringForRequestPayload } from 'util/datetime-formatter';
import { pruneObject } from 'util/json-utils';
import { DateInputType, ExportOption, QuickReportsCard, ReportType } from './QuickReportsCard';

interface QuickReportsCardProps {
  filters?: agingFilter | OpenInvoiceFilterParams | ClosedInvoiceFilterParams | AllCustomersFilter;
  onDownloadFinish?: () => void;
  handleCloseCallback: () => void;
}

export enum ARAgingExportTabType {
  CURRENT,
  HISTORY,
}

export enum CurrentPage {
  OUTSTANDING,
  INVOICE,
  CUSTOMER,
  PAYMENT,
}

const t = {
  openInvoices: 'Open Invoices',
  openInvoicesDescription: `List of open invoices with basic details & aging information.`,
  outstandingCustomers: 'AR Aging Report by',
  outstandingCustomersCurrentDescription: `This report contains list of customers with receivables data & outstanding across different aging buckets.`,
  outstandingCustomersHistoricDescription: `This report contains list of customers with receivables data & outstanding across different aging buckets for last 30 days.`,
  closedInvoices: 'Closed Invoices',
  closedInvoicesDescription: `List of closed invoices their collection amount for a selected period with their payment information.`,
  draftInvoices: 'Draft Invoices',
  draftInvoicesDescription: `List of draft invoices with basic details & aging information.`,
  AllCustomers: 'All Customers',
  AllCustomersDescription: 'This report contains list of all customers with receivables data.',
  ar_aging_tab_type: {
    current: 'Current',
    history: 'History',
  },
};

export function OpenInvoicesReportsCard(props: QuickReportsCardProps) {
  const { fetching, downloadReports } = useDownloadDocument();

  function onDownload(option: ExportOption, date?: string) {
    const url = `/api/reports/open-invoices`;
    const fileName = `open-invoices-${Date.now()}.csv`;
    const postParams =
      option === ExportOption.ALL
        ? { filter_type: 'OPEN', filters_applied: false }
        : { ...props.filters, filters_applied: true };
    downloadReports(url, { fileName, data: postParams })
      .then(props.onDownloadFinish)
      .catch((e) => {
        console.log(e);
      });
    dispatchAppEvent({
      type: INVOICE_LIST_EVENT.INVOICE_LIST_DOWNLOAD_QUICK_REPORTS,
      payload: {
        tab: InvoiceListTabKeys.OPEN_INVOICE,
        status: 'Download',
      },
    });
  }

  return (
    <QuickReportsCard
      title={t.openInvoices}
      description={t.openInvoicesDescription}
      dateInputType={DateInputType.SINGLE}
      onDownload={onDownload}
      downloading={fetching}
      handleClose={props.handleCloseCallback}
      currentPage={CurrentPage.INVOICE}
    />
  );
}
export function DraftInvoicesReportsCard(props: QuickReportsCardProps) {
  const { fetching, downloadReports } = useDownloadDocument();

  function onDownload(option: ExportOption, date?: string) {
    const url = `/api/reports/draft-invoices`;
    const fileName = `draft-invoices-${Date.now()}.csv`;
    const postParams =
      option === ExportOption.ALL
        ? { filter_type: 'DRAFT', filters_applied: false }
        : { ...props.filters, filters_applied: true };

    downloadReports(url, { fileName, data: postParams })
      .then(props.onDownloadFinish)
      .catch((e) => {
        console.log(e);
      });
    dispatchAppEvent({
      type: INVOICE_LIST_EVENT.INVOICE_LIST_DOWNLOAD_QUICK_REPORTS,
      payload: {
        tab: InvoiceListTabKeys.DRAFT_INVOICE,
        status: 'Download',
      },
    });
  }

  return (
    <QuickReportsCard
      title={t.draftInvoices}
      description={t.draftInvoicesDescription}
      dateInputType={DateInputType.SINGLE}
      onDownload={onDownload}
      downloading={fetching}
      handleClose={props.handleCloseCallback}
      currentPage={CurrentPage.INVOICE}
    />
  );
}

export function ClosedInvoicesReportsCard(props: QuickReportsCardProps) {
  const { fetching, downloadReports } = useDownloadDocument();

  function onDownload() {
    const url = `/api/reports/closed-invoices`;
    const fileName = `closed-invoices-${Date.now()}.csv`;

    downloadReports(url, {
      fileName,
      data: pruneObject({
        ...props.filters,
        filters_applied: true,
      }),
    })
      .then(props.onDownloadFinish)
      .catch((e) => {
        console.log(e);
      });
    dispatchAppEvent({
      type: INVOICE_LIST_EVENT.INVOICE_LIST_DOWNLOAD_QUICK_REPORTS,
      payload: {
        tab: InvoiceListTabKeys.CLOSED_INVOICE,
        status: 'Download',
      },
    });
  }

  return (
    <QuickReportsCard
      title={t.closedInvoices}
      description={t.closedInvoicesDescription}
      dateInputType={DateInputType.NO_DATE}
      onDownload={onDownload}
      downloading={fetching}
      handleClose={props.handleCloseCallback}
      hideOptions={true}
    />
  );
}

export function OutstandingCustomersReportsCard(props: QuickReportsCardProps) {
  const { fetching, downloadReports } = useDownloadDocument();

  const [tabType, setTabTye] = useState<ARAgingExportTabType>(ARAgingExportTabType.CURRENT);

  const BU_SWITCHER_OPTIONS: GenericRadioOptions<ARAgingExportTabType> = [
    { label: t.ar_aging_tab_type.current, value: ARAgingExportTabType.CURRENT },
    {
      label: t.ar_aging_tab_type.history,
      value: ARAgingExportTabType.HISTORY,
      disabled: usePrivilegeStatus([ERolesAndPrivileges.GENERATE_AGING_EXPORT_REPORT])
        ? false
        : true,
    },
  ];

  const extra = (
    <RadioGroup
      options={BU_SWITCHER_OPTIONS}
      onChange={({ target: { value } }) => setTabTye(value)}
      optionType="button"
      value={tabType}
    />
  );

  const description =
    tabType === ARAgingExportTabType.CURRENT
      ? t.outstandingCustomersCurrentDescription
      : t.outstandingCustomersHistoricDescription;

  const shouldHideOptions = tabType === ARAgingExportTabType.HISTORY;

  function onDownload(option: ExportOption, reportType: ReportType, date?: string) {
    const formattedDate = getDateStringForRequestPayload(date);
    const url = `/api/reports/outstanding-customers`;
    const fileName = `outstanding-customers-${Date.now()}.csv`;
    const postParams =
      option === ExportOption.ALL
        ? {
            date: formattedDate,
            filters_applied: false,
            report_type: reportType,
          }
        : { date: formattedDate, ...props.filters, filters_applied: true, report_type: reportType };
    formattedDate &&
      downloadReports(url, { fileName, data: postParams })
        .then(props.onDownloadFinish)
        .catch((e) => {
          console.log(e);
        });
    dispatchAppEvent({ type: AR_AGING_EVENT.DOWNLOAD });
  }

  return (
    <QuickReportsCard
      title={t.outstandingCustomers}
      description={description}
      dateInputType={DateInputType.SINGLE}
      extra={extra}
      onDownload={onDownload}
      downloading={fetching}
      hideOptions={shouldHideOptions}
      handleClose={props.handleCloseCallback}
      currentPage={CurrentPage.OUTSTANDING}
      tabType={tabType}
      selectedDate={dayjs().format('YYYY-MM-DD')}
    />
  );
}

export function AllCustomersReportsCards(props: QuickReportsCardProps) {
  const { fetching, downloadReports } = useDownloadDocument();
  function onDownload() {
    const url = `/api/reports/all-customers`;
    const fileName = `  All-Customers-${Date.now()}.csv`;
    downloadReports(url, { fileName, data: { ...props.filters } })
      .then(props.onDownloadFinish)
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <QuickReportsCard
      title={t.AllCustomers}
      description={t.AllCustomersDescription}
      downloading={fetching}
      onDownload={onDownload}
      dateInputType={DateInputType.NO_DATE}
      handleClose={props.handleCloseCallback}
      hideOptions={true}
    />
  );
}
