import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { styled } from 'lib/css-in-js';
import { useEffect } from 'react';

const ContainerDiv = styled(Flex)`
  position: relative;
  // denotes the dot inside the HW_badge_cont class
  .HW_badge {
    left: unset;
    right: 0%;
    bottom: 0%;
    transform: translate(50%, -50%) scale(0.6) !important;
  }
`;

// Config for Headway
const TARGET_CLASS_NAME = 'growfin-headway-widget';
const HEADWAY_ACCOUNT_ID = 'JPdarJ';

export default function ChangeLogWidget() {
  useEffect(() => {
    // Initiating everytime the component is rendered
    const config = {
      selector: `.${TARGET_CLASS_NAME}`,
      account: HEADWAY_ACCOUNT_ID,
    };
    window.Headway?.init(config);
  }, []);

  return (
    <>
      <ContainerDiv
        align="center"
        justify="center"
        className={TARGET_CLASS_NAME}
        style={{
          backgroundColor: 'var(--primary-1)',
          height: 32,
          width: 32,
          borderRadius: 'var(--space-4)',
          color: 'var(--primary-6)',
        }}
      >
        <FontAwesomeIcon icon={['far', 'bullhorn']} style={{ position: 'absolute' }} />
      </ContainerDiv>
      {/* 
        Headway places its dot here. We are hiding it and using our own icon.
        We are keeping the their widget overlay on ours
       */}
    </>
  );
}
