import { amountFormats } from '@sinecycle/growcomponents';
import { chain, escapeRegExp, toNumber } from 'lodash';

type LocaleOptions = {
  currency: string;
  locale?: string;
};

type NumberLocalOptions = Parameters<typeof amountFormats.number>['1'];

export function formatCurrency(
  amount: number = 0,
  options: LocaleOptions,
  formatOptions?: Intl.NumberFormatOptions
) {
  const { currency, locale } = options;
  const maximumFractionDigits = formatOptions?.maximumFractionDigits ?? 2;
  const minimumFractionDigits = maximumFractionDigits === 0 ? 0 : 2;
  const formattedAmount = new Intl.NumberFormat(locale ?? 'en-US', {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
    ...formatOptions,
  }).format(amount);

  return `${getCurrencySymbol(currency)}\u00A0${formattedAmount}`;
}

export function formatNumber(
  amount: number = 0,
  options: LocaleOptions,
  formatOptions?: Intl.NumberFormatOptions
) {
  const { locale } = options;
  const maximumFractionDigits = formatOptions?.maximumFractionDigits ?? 2;
  const minimumFractionDigits = maximumFractionDigits === 0 ? 0 : 2;

  return new Intl.NumberFormat(locale ?? 'en-US', {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
    ...formatOptions,
  }).format(amount);
}
export function getCurrencySymbol(currency: string = 'USD') {
  // tries to format "1" and return the first element of the string (viz. currency symbol)
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol',
    }).format(1)[0];
  } catch (e) {
    return '$';
  }
}

export function getPercentage(numerator: number, denominator: number = 1) {
  if (denominator === 0) return new Intl.NumberFormat('en-US', { style: 'percent' }).format(0);
  return new Intl.NumberFormat('en-US', { style: 'percent' }).format(numerator / denominator);
}

export function formatPercent(percent: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(percent / 100);
}

export function formatAmountShort(amount: number | string, currency: string, locale?: string) {
  return formatCurrency(
    Number(amount),
    { currency, locale },
    { compactDisplay: 'short', notation: 'compact' }
  );
}

export function formatAmount(amount: number | string, currency: string, locale?: string) {
  return formatCurrency(
    Number(amount),
    { currency, locale },
    { compactDisplay: 'long', notation: 'standard' }
  );
}

export function parseFormattedNumber(formattedValue: string, options: NumberLocalOptions): number {
  const { locale } = options;

  // Get the decimal and group separators for the locale
  const parts = new Intl.NumberFormat(locale ?? 'en-US').formatToParts(12_345.6).reduce(
    (acc, part) => {
      if (part.type === 'decimal') acc.decimalSeparator = part.value;
      if (part.type === 'group') acc.groupSeparator = part.value;
      return acc;
    },
    { decimalSeparator: '.', groupSeparator: ',' }
  );

  // Remove the group separator and replace the decimal separator
  const normalizedValue = chain(formattedValue)
    .replace(new RegExp(`\\${escapeRegExp(parts.groupSeparator)}`, 'g'), '')
    .replace(parts.decimalSeparator, '.')
    .value();

  return toNumber(normalizedValue);
}
