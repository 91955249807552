import { GrowFormHeader } from '@sinecycle/growcomponents';
import { Select } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import SlidingModal from 'components/BaseComponents/SliderModal';
import { GrowText } from 'components/BaseComponents/Typography';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { AllAccountSourceProps, ExchangeRate } from 'types/api/currency-page-settings';
import { getCurrencyInfo } from '../CurrentPeriod/CurrentPeriodHelper';

const t = {
  choosePeriod: 'Choose a past period',
  drawerTitle: 'Past Periods',
  exchangeRate: 'Exchange rate for each currency',
};

const Title = (
  <GrowText weight="var(--fs-bold)" size="var(--fs-16)">
    {t.drawerTitle}
  </GrowText>
);
interface PastPeriodDrawerProps {
  pastPeriodData: AllAccountSourceProps;
  handleDrawerToggle: () => void;
  open: boolean;
}

export default function PastPeriodDrawer(props: PastPeriodDrawerProps) {
  const [selectedPeriodList, setSelectedPeriodList] = useState<ExchangeRate>();
  const format = useSelector(dateFormatSelector);
  const pastPeriodOption = useMemo(() => {
    return props.pastPeriodData.exchange_rate_config
      ? props.pastPeriodData.exchange_rate_config
          .filter((item) => item.is_past_period === true)
          .map((item) => ({
            label: `${dayjs(item.start_date).format(format)} - ${dayjs(item.end_date).format(
              format
            )}`,
            value: `${item.start_date} - ${item.end_date}`,
          }))
      : [];
  }, [format, props.pastPeriodData.exchange_rate_config]);

  const initialSelectedValue = useMemo(() => {
    if (pastPeriodOption.length > 0 && props.pastPeriodData?.exchange_rate_config) {
      return pastPeriodOption[0].value;
    }
    return undefined;
  }, [pastPeriodOption, props.pastPeriodData?.exchange_rate_config]);

  const computeInitialSelectedPeriodList = () => {
    if (pastPeriodOption.length > 0 && props.pastPeriodData?.exchange_rate_config) {
      return props.pastPeriodData.exchange_rate_config.find(
        (period) => `${period.start_date} - ${period.end_date}` === pastPeriodOption[0].value
      );
    }
  };

  const initialSelectedPeriodList = computeInitialSelectedPeriodList();

  function handleDateChange(dateRangeValue: string) {
    const dateRangeValueIndex = props.pastPeriodData.exchange_rate_config.find((period) => {
      return `${period.start_date} - ${period.end_date}` === dateRangeValue;
    });

    setSelectedPeriodList(dateRangeValueIndex);
  }

  function exchangeRateRow(selectedPeriodIndex?: ExchangeRate) {
    const selectedPeriodData = props.pastPeriodData.exchange_rate_config.find(
      (rate) => rate === selectedPeriodIndex
    );

    if (!selectedPeriodData || !selectedPeriodData.exchange_rate_data) {
      return null;
    }

    return selectedPeriodData.exchange_rate_data.map((sourceDesti, index) => {
      const sourceCurrencyInfo = getCurrencyInfo(sourceDesti.source);
      const destinationCurrencyInfo = getCurrencyInfo(sourceDesti.destination);

      return (
        <Flex gap="var(--space-12)" key={index}>
          <GrowText style={{ width: '200px' }} ellipsis>
            1 {sourceCurrencyInfo.name} {sourceCurrencyInfo.code} ({sourceCurrencyInfo.symbol})
          </GrowText>
          <span>=</span>
          <GrowText>
            {sourceDesti.exchange_rate} {destinationCurrencyInfo.code} (
            {destinationCurrencyInfo.symbol})
          </GrowText>
        </Flex>
      );
    });
  }

  const ChoosePeriod = (
    <Flex direction="column" gap="var(--space-4)">
      <GrowText>{t.choosePeriod}</GrowText>
      <Select
        style={{ width: 'fit-content' }}
        options={pastPeriodOption}
        defaultValue={initialSelectedValue}
        onChange={handleDateChange}
      />
    </Flex>
  );

  const DrawerArea = (
    <Flex direction="column" gap="var(--space-24)">
      <>{ChoosePeriod}</>
      <Flex direction="column" gap="var(--space-16)">
        <GrowText weight="var(--fs-semibold)">{t.exchangeRate}</GrowText>
        {selectedPeriodList
          ? exchangeRateRow(selectedPeriodList)
          : props.pastPeriodData?.exchange_rate_config &&
            exchangeRateRow(initialSelectedPeriodList)}
      </Flex>
    </Flex>
  );

  const DrawerHeader = <GrowFormHeader title={Title} onClose={props.handleDrawerToggle} />;
  return (
    <SlidingModal
      title={DrawerHeader}
      placement="right"
      width={500}
      onClose={props.handleDrawerToggle}
      open={props.open}
      closable={false}
      destroyOnClose
    >
      {DrawerArea}
    </SlidingModal>
  );
}
