import { useMutation } from '@tanstack/react-query';
import { activeUsers, allUsers, updateCurrentUser } from 'services/users';
import { AppThunk } from 'store/store';
import { AllUsersResponseState } from 'store/user/types';
import { getActiveUsersHandler, getUsersHandler } from 'store/user/user';
import { CurrentUser } from 'types/entities/user';

export const getUsersThunk = (): AppThunk<
  Promise<{ payload: AllUsersResponseState; type: string }>
> => {
  return async (dispatch) => {
    try {
      const users = await allUsers();
      return dispatch(getUsersHandler({ status: 200, users }));
    } catch (err: any) {
      return dispatch(getUsersHandler({ status: 500, message: err.message }));
    }
  };
};

export const getActiveUsersThunk = (): AppThunk<
  Promise<{ payload: AllUsersResponseState; type: string }>
> => {
  return async (dispatch) => {
    try {
      const users = await activeUsers();
      return dispatch(getActiveUsersHandler({ status: 200, users }));
    } catch (err: any) {
      return dispatch(getActiveUsersHandler({ status: 500, message: err.message }));
    }
  };
};

export function useUpdateCurrentUser() {
  return useMutation({
    mutationFn: (putData: CurrentUser) => updateCurrentUser(putData),
    retry: false,
  });
}
