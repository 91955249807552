import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GrowCheckBox,
  GrowFormFooter,
  GrowFormHeader,
  GrowModal,
  HideWrapper,
} from '@sinecycle/growcomponents';
import { Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { activityText } from '../text';
import { FormModalProps } from '../types';

export default function FormModal(props: FormModalProps) {
  const {
    open,
    onCancel,
    children,
    formSubmit,
    setRemainder,
    isLoading,
    disableRemainder,
    enableExtraAction,
    headerTitle,
    disabled,
  } = props;

  const header = (
    <div style={{ marginBottom: 'var(--space-16)' }}>
      <GrowFormHeader title={headerTitle} mode="modal" onClose={onCancel} />
    </div>
  );

  const InfoTooltip = (
    <Tooltip title={activityText.remainder_info_text}>
      <FontAwesomeIcon icon={['far', 'circle-info']} color={'var(--geekblue-6)'} />
    </Tooltip>
  );

  const RemainderCheckbox = (
    <HideWrapper hide={Boolean(disableRemainder)}>
      <Flex align="center" direction="row">
        <GrowCheckBox
          onChange={() => setRemainder?.()}
          style={{ alignItems: 'center' }}
          design="border-design"
        >
          {activityText.set_reminder}
        </GrowCheckBox>
        {InfoTooltip}
      </Flex>
    </HideWrapper>
  );

  const footer = (
    <GrowFormFooter
      extra={enableExtraAction ? RemainderCheckbox : null}
      saveButtonProps={{
        onClick: formSubmit,
        loading: isLoading,
        disabled,
      }}
      cancelButtonProps={{
        onClick: onCancel,
      }}
    />
  );

  return (
    <GrowModal
      open={open}
      width={640}
      type={'activities'}
      closable={false}
      centered
      title={header}
      footer={footer}
      destroyOnClose
      onCancel={onCancel}
      styles={{ body: { maxHeight: '560px', overflow: 'auto' } }}
    >
      {children}
    </GrowModal>
  );
}
