import { GenericSelectOption } from 'components/Common/FilterComponents/types';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { ActivityType } from 'types/activities/activity-types';
import { InboxActivityMode } from 'types/api/inbox/activity';
import { cleanArrayPredicate } from 'util/predicates';
import { InboxAssignedTypeList, InboxSubscriptionTypeList } from '../FilterStrip/predicate';

/*predicates */
const hasChildPredicate = (value: GenericSelectOption<ActivityType, 'children'>) =>
  value.valueObj?.children ? value.value : undefined;

const filterClosedValuesPredicate = (value: GenericSelectOption<ActivityType, 'children'>) =>
  value.key !== 'CLOSED';

const mapValuePredicate = (value: GenericSelectOption<ActivityType, 'children'>) => value.value;

/*predicates */
function getDefaultValue(options: GenericSelectOption<ActivityType, 'children'>[]): string[] {
  const hasChildKeys = options.map(hasChildPredicate).filter(cleanArrayPredicate);

  const totalOptions = options.flatMap((option) => {
    const child = option.valueObj?.children
      .filter(filterClosedValuesPredicate)
      .map(mapValuePredicate);

    return [option.value, ...(child ?? [])];
  });
  return filter(totalOptions, (value) => {
    return !hasChildKeys.includes(value);
  });
}

type DefaultValue = Record<InboxActivityMode, string[]>;

function useGetActivityFilterOption() {
  const [defaultFilterValue, setDefaultValues] = useState<DefaultValue>(initializeDefaultValue);

  function initializeDefaultValue(): DefaultValue {
    return {
      ASSIGNED: getDefaultValue(InboxAssignedTypeList),
      SUBSCRIBED: getDefaultValue(InboxSubscriptionTypeList),
    };
  }

  function clearFilterCallBack(e: CustomEvent) {
    if (e.type === 'clear_filter') {
      setDefaultValues({ ASSIGNED: [], SUBSCRIBED: [] });
    }
  }

  function handleFilterEvent() {
    document.addEventListener('clear_filter', clearFilterCallBack as any);

    return () => {
      document.removeEventListener('clear_filter', clearFilterCallBack as any);
    };
  }

  useEffect(handleFilterEvent, []);

  return { defaultFilterValue };
}

export { useGetActivityFilterOption };
