import { Channel } from 'pusher-js';
import ky from 'services/ky';

const base_url = 'realtime';

interface AuthData {
  auth: string;
  channel_data?: string;
  shared_secret?: string;
}

type AuthorizerCallback = (error: Error | null, authData: AuthData) => void;

export function pusherAuthorizer(channel: Channel) {
  return {
    authorize: async function (socketId: string, callback: AuthorizerCallback) {
      const data = {
        socket_id: socketId,
        channel_name: channel.name,
      };

      try {
        const response = (await ky.post(`${base_url}/auth`, { json: data }).json()) as AuthData;
        callback(null, response);
      } catch (err) {
        callback(new Error(`Error authorizing pusher: ${err}`), { auth: '' });
      }
    },
  };
}
