import { realtime } from 'lib/realtime';
import { handleBroadCastEvent } from 'lib/realtime/broadcast';
import { BroadCastChannelEvent, BroadCastChannelType } from 'lib/realtime/broadcast.type';
import { useRegisterGlobalListener } from 'lib/realtime/hooks/useRegisterGlobalListener';
import { useGetAccountsData } from 'queries/Settings/account';
import { useAuthCurrentUser } from 'queries/authentication';
import { useSubsidiariesQuery } from 'queries/subsidaries';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { identifyUserForTrackers } from 'router/utils/identify-users';
import { appLoadingHandler, appLoadingProgressHandler } from 'store/authentication/authentication';
import { clearLocalStorageValues } from 'util/clearStorage';
import { TOTAL_LOADER_PROGRESS, useInitialDataFetcher } from './useInitialDataFetcher';

export function useAppInit() {
  const dispatch = useDispatch();

  const { data: authenticateUser } = useAuthCurrentUser();
  useSubsidiariesQuery(Boolean(authenticateUser));
  useGetAccountsData(Boolean(authenticateUser));
  const { loadIntitalData } = useInitialDataFetcher();
  const { registerGlobalListeners } = useRegisterGlobalListener();
  clearLocalStorageValues();
  const signOutChannel = new BroadcastChannel(BroadCastChannelType.SIGNOUT_CHANNEL);
  signOutChannel.onmessage = function (event: MessageEvent) {
    const payload: BroadCastChannelEvent = event.data;
    handleBroadCastEvent(payload);
  };

  // using it here since redux store is injected in Index.tsx
  // useRegisterGlobalListener();

  useEffect(() => {
    async function authenticateAndLoadInitialData() {
      //Incrementing loader progress as authication is done
      dispatch(appLoadingProgressHandler(2 / TOTAL_LOADER_PROGRESS));

      if (authenticateUser) {
        identifyUserForTrackers(authenticateUser);
        realtime.subscribeToAllChannels(authenticateUser.id, authenticateUser.account_id);
        registerGlobalListeners();
        await loadIntitalData();
      }
    }

    dispatch(appLoadingHandler(true));
    authenticateAndLoadInitialData()
      .catch((e) => console.log(e))
      .finally(() => dispatch(appLoadingHandler(false)));
  }, [authenticateUser, dispatch, loadIntitalData, registerGlobalListeners]);
}
