import { Form } from 'antd';
import RangeNumericFilter from 'components/Settings/WorkFlowAutomation/CustomAutomations/Trigger/Filters/NumericFilterHelper/RangeNumericFilter';
import { ValidateStatus } from 'types/entities/invoice-segments';
import { dispatchErrorValidation } from '../CustomFiledRange/CustomRange';
export type RangeItem = {
  value?: number | null;
  validateStatus?: ValidateStatus;
  errorMsg?: string | null;
};
export type MinMaxRange = {
  from: RangeItem;
  to: RangeItem;
};
interface MinMaxProps<T> {
  name: string;
  value?: T;
  onChange?: (value: T) => void;
}

type MinMaxValueType = {
  min: number | undefined;
  max: number | undefined;
};

export function MinMaxFilter<T extends MinMaxValueType>(props: MinMaxProps<T>) {
  const { onChange, value } = props;
  const rangeValues: MinMaxRange = {
    from: { value: value?.min },
    to: { value: value?.max },
  };
  function handleMinChange(
    minValue: number | null,
    toValue?: number | null,
    validateStatus?: ValidateStatus
  ) {
    if (validateStatus === 'error') {
      dispatchErrorValidation(true);
    }
    if (validateStatus === 'success') {
      onChange && onChange({ min: minValue, max: toValue } as T);
      dispatchErrorValidation(false);
    }
  }
  function handleMaxChange(
    maxValue: number | null,
    fromValue?: number | null,
    validateStatus?: ValidateStatus
  ) {
    if (validateStatus === 'error') {
      dispatchErrorValidation(true);
    }

    if (validateStatus === 'success') {
      onChange && onChange({ min: fromValue, max: maxValue } as T);
      dispatchErrorValidation(false);
    }
  }

  return (
    <Form
      layout="vertical"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 'var(--space-8)',
        width: '242px',
      }}
    >
      <RangeNumericFilter
        fromOnChange={handleMinChange}
        toOnChange={handleMaxChange}
        initialRangeValues={rangeValues}
        fromLabel={'Min'}
        toLabel={'Max'}
        singleFieldInputWidth={'100%'}
      />
    </Form>
  );
}
