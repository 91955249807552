import Tag from 'components/BaseComponents/Tags';
import styled from 'styled-components';

export const StyledStatusTag = styled(Tag)<{
  background: string;
  textcolor: string;
  bordercolor?: string;
}>`
  --tagText: ${(props) => props.textcolor};
  --tagBorder: ${(props) => props.bordercolor};
  --tagBg: ${(props) => props.background};
  color: var(--tagText);
  background-color: var(--tagBg);
  font-weight: var(--fs-regular);
  font-size: 13px;
  margin: 0;

  &.break {
    white-space: break-spaces;
    line-height: 1.2;
  }

  &.small {
    padding: var(--space-2) var(--space-4);
  }
`;
