import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers/rootReducer';
import {
  ReceivableDisplayStoreState,
  ReceivableDisplayResponseState,
  AgingPreviewResponseState,
} from './types';

export const initialState: ReceivableDisplayStoreState = {
  receivableDisplay: {
    status: 0,
  },
  aging: {
    status: 0,
  },
};

const receivableDisplaySlice = createSlice({
  name: 'receivableDisplay',
  initialState,
  reducers: {
    getReceivableDisplaySettingsHandler(
      state,
      action: PayloadAction<ReceivableDisplayResponseState>
    ) {
      state.receivableDisplay.status = action.payload.status;
      state.receivableDisplay.message = action.payload.message;
      state.receivableDisplay.data = action.payload.data;
    },
    getAgingPreviewHandler(state, action: PayloadAction<AgingPreviewResponseState>) {
      state.aging.status = action.payload.status;
      state.aging.message = action.payload.message;
      state.aging.data = action.payload.data;
    },
    clearStatusAndMessageHandler(state) {
      state.receivableDisplay.status = 0;
      state.receivableDisplay.message = '';
      state.aging.status = 0;
      state.aging.message = '';
    },
  },
});

export const {
  getReceivableDisplaySettingsHandler,
  clearStatusAndMessageHandler,
  getAgingPreviewHandler,
} = receivableDisplaySlice.actions;

export const receivableDisplaySelector = (state: RootState) =>
  state.receivableDisplay.receivableDisplay;
export const receivableAgingSelector = (state: RootState) => state.receivableDisplay.aging;

export default receivableDisplaySlice.reducer;
