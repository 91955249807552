import { Typography } from 'antd';
import React from 'react';

const { Text, Paragraph } = Typography;

type TextProps = React.ComponentProps<typeof Text>;
type ParagraphProps = React.ComponentProps<typeof Paragraph>;
function AntText(props: TextProps) {
  return <Text {...props} />;
}

function AntParagraph(props: ParagraphProps) {
  return <Paragraph {...props} />;
}
export { AntParagraph as TypographyParagraph, AntText as TypographyText };
