import { Node } from '@tiptap/core';
import { PlaceHolderTagView } from 'components/BaseComponents/RTE/Toolbar/common/nodes/PlaceholderTags';
import { getNodeViewRender } from 'lib/RichText';

interface PlaceholderTagAttributes {
  placeholder: string;
}

function getRenderNode(attributes: PlaceholderTagAttributes) {
  const content = document.createElement('span');
  content.className = 'grow-email-placeholder';

  if (attributes?.placeholder) {
    content.innerText = attributes?.placeholder;
  }

  return {
    dom: content,
  };
}

export const CustomPlaceholderNode = Node.create({
  name: 'placeholderTag',
  atom: true,
  inline: true,
  content: 'text*',
  group: 'inline',
  whitespace: 'normal',
  selectable: true,
  showGapCursor: true,
  addAttributes() {
    return {
      placeholder: {
        default: null,
        parseHTML: (element) => element.innerText,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.grow-email-placeholder',
      },
    ];
  },

  renderHTML({ node }) {
    return getRenderNode(node?.attrs as PlaceholderTagAttributes);
  },

  addNodeView() {
    return getNodeViewRender(PlaceHolderTagView);
  },
});
