import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CellRenderProps,
  customerNameFormatter,
  defaultCellComponents,
} from '@sinecycle/growcomponents';
import { Tag } from 'antd';
import { DateText } from 'components/BaseComponents/Date/DateText';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { getInvoiceStatusConfig } from 'components/Common/InvoiceStatus/predicate';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { PageLink } from 'lib/router';
import { AdminFlaggedInvoice } from 'types/api/admin-dashboard';
import { StatementViews } from 'types/entities/invoice';
import { StyledFlaggedInvoiceItem } from './style';

const t = {
  by: 'by',
  popover: {
    body: {
      info1: 'Do you want to unflag these',
      info2: ' flagged invoice(s)?',
    },
  },
  unflagInvoice: 'Unflag Invoice',
};

export const FlaggedInvoiceItem = ({ flaggedInvoice }: { flaggedInvoice: AdminFlaggedInvoice }) => {
  const customerName = customerNameFormatter({
    isUnnamedCustomer: true,
    name: flaggedInvoice.customer_name,
  });
  const FlaggedInvoiceDetail = (
    <Flex direction="column" gap="var(--space-4)">
      <PageLink
        appPage="INVOICE_DETAILS"
        pathParams={{ id: flaggedInvoice.invoice_id }}
        style={{ display: 'flex' }}
        searchParams={
          {
            [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
            [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
          } as unknown as URLSearchParams
        }
      >
        <GrowText color="var(--primary-7)" strong>
          {flaggedInvoice.invoice_no}
        </GrowText>
      </PageLink>
      <Flex gap="var(--space-4)" align="baseline">
        <PageLink
          appPage="CUSTOMER_DETAILS"
          pathParams={{ id: flaggedInvoice.customer_id }}
          style={{ display: 'flex' }}
          searchParams={
            {
              [EActivityQueryParams.CURRENT_SECTION]: StatementViews.INVOICES,
              [EActivityQueryParams.CURRENT_VIEW]: StatementViews.OPEN_INVOICES,
            } as unknown as URLSearchParams
          }
        >
          <Tag
            color="var(--gray-3)"
            style={{
              padding: 'var(--space-4) var(--space-8)',
              borderRadius: 'var(--br-2)',
            }}
          >
            <GrowText
              style={{ maxWidth: '200px' }}
              ellipsis={{ tooltip: customerName }}
              color={'var(--purple-7)'}
            >
              {customerName}
            </GrowText>
          </Tag>
        </PageLink>
        <Flex align="center" gap="var(--space-4)">
          <GrowText color="var(--gray-9)">{t.by}</GrowText>
          <GrowText strong>{flaggedInvoice.flagged_by.first_name}</GrowText>
        </Flex>
        <DateText
          value={flaggedInvoice.flagged_at}
          textProps={{ style: { color: 'var(--gray-7)' } }}
        />
      </Flex>

      {Boolean(flaggedInvoice.flag_comment?.length) && (
        <GrowText style={{ margin: 'var(--space-12) 0 0 0', color: 'var(--gray-9)' }}>
          {flaggedInvoice.flag_comment}
        </GrowText>
      )}
    </Flex>
  );

  return (
    <StyledFlaggedInvoiceItem gap="var(--space-8)">
      <FontAwesomeIcon
        icon={['far', 'flag']}
        color="var(--volcano-6)"
        style={{ margin: 'var(--space-4) 0 0 0' }}
      />
      <Flex justify="space-between" style={{ width: '100%' }}>
        <Flex.Child flexGrow={1}>{FlaggedInvoiceDetail}</Flex.Child>
        <Flex.Child>
          {defaultCellComponents.statusCell({
            statusCellProps: {
              getter: getInvoiceStatusConfig,
            },
            value: flaggedInvoice.invoice_status,
          } as CellRenderProps<unknown>)}
        </Flex.Child>
      </Flex>
    </StyledFlaggedInvoiceItem>
  );
};
