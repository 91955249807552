import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const FallbackStyledWrapper = styled.span``;

export const MultiCurrencyBox = styled.div`
  .title {
    font-size: var(--fs-14);
    color: var(--gray-8);
    border-bottom: 1px solid var(--gray-8);
  }
  .multi-currency-list {
    margin-top: 0.2rem;
    margin-bottom: 0.4rem;
  }
  .multi-currency-item {
    margin: 0.8rem 0;
    display: flex;
    flex-direction: column;
  }
  .invoice-count {
    color: var(--cyan-10);
  }
  .curreny-name,
  .invoice-count {
    font-size: var(--fs-12);
  }
  .currency-amount,
  .total {
    display: flex;
    justify-content: space-between;
  }
  .total {
    font-size: var(--fs-14);
    font-weight: 600;
    border-top: 1px solid var(--gray-5);
    padding-top: var(--space-8);
  }
`;

export const ExchangeIcon = (
  <FontAwesomeIcon
    icon={['far', 'exchange']}
    color="var(--geekblue-7)"
    style={{
      height: 16,
      width: 16,
      boxSizing: 'border-box',
      cursor: 'pointer',
      borderRadius: 4,
      padding: 2,
      background: 'var(--geekblue-1)',
      marginLeft: '5px',
    }}
  />
);
