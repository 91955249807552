import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmailTemplates } from 'types/entities/email-templates';
const initialState = {
  template: {},
};

const emailTemplateNewSlice = createSlice({
  name: 'emailTemplateNew',
  initialState,
  reducers: {
    getAnEmailTemplate(state, action: PayloadAction<EmailTemplates>) {
      state.template = { ...action.payload };
    },
  },
});

export const { getAnEmailTemplate } = emailTemplateNewSlice.actions;
export default emailTemplateNewSlice.reducer;
