import { Row } from 'antd';

interface ActivityCardHeaderProps {
  children: React.ReactNode;
  activitiesMode?: boolean;
}

export default function ActivityCardHeader(props: ActivityCardHeaderProps) {
  const { children, activitiesMode } = props;

  return (
    <Row
      align="middle"
      justify="space-between"
      style={{ height: activitiesMode ? '28px' : 'auto' }}
      wrap={false}
    >
      {children}
    </Row>
  );
}
