import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ContentState, EditorState } from 'draft-js';

import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
} from '@draft-js-plugins/mention';

import { MentionBox } from './styles';

interface Props {
  mentions: MentionData[];
  onChange: (state: ContentState) => void;
  contentState?: ContentState;
}

export function CommentWrite(props: Props) {
  const { mentions } = props;

  const ref = useRef<Editor>(null);

  const mentionsList = useMemo(() => mentions, [mentions]);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(mentionsList);

  function handleChange(editorState: EditorState) {
    props.onChange(editorState.getCurrentContent());
    setEditorState(editorState);
  }

  useEffect(() => {
    if (props.contentState) {
      setEditorState(EditorState.push(editorState, props.contentState, 'change-block-data'));
    }
  }, [props.contentState, editorState]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      supportWhitespace: true,
      mentionPrefix: '@',
      theme: {
        mentionSuggestionsPopup: 'mention-popup',
      },

      mentionComponent(mentionProps) {
        return (
          <span style={{ color: 'var(--blue-4)', textDecoration: 'none' }}>
            {mentionProps.children}
          </span>
        );
      },
    });

    const plugins = [mentionPlugin],
      { MentionSuggestions } = mentionPlugin;

    return { plugins, MentionSuggestions };
  }, []);

  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      setSuggestions(defaultSuggestionsFilter(value, mentionsList));
    },
    [mentionsList]
  );

  return (
    <MentionBox onClick={() => ref.current!.focus()}>
      <div className="editor">
        <Editor
          editorKey={'editor'}
          editorState={editorState}
          onChange={handleChange}
          plugins={plugins}
          ref={ref}
        />
      </div>
      <MentionSuggestions
        open={open}
        onOpenChange={setOpen}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
      />
    </MentionBox>
  );
}
