import { CustomFieldRangeValueType } from 'components/Common/FilterComponents/Filters/CustomFiledRange';
import { CustomFieldOperatorType } from 'types/entities/custom-field';

export function getNumberBasedText(
  value: CustomFieldRangeValueType<CustomFieldOperatorType>,
  currencySymbol: string | undefined
) {
  switch (value.type) {
    case 'IN':
      return `Equals ${currencySymbol ?? ''}${value.value}`;
    case 'GOE':
      return `Greater than or equals ${currencySymbol ?? ''}${value.value}`;
    case 'LOE':
      return `Less than or equals ${currencySymbol ?? ''}${value.value}`;
    case 'BTWN':
      return `${currencySymbol ?? ''}${
        (value.value as CustomFieldRangeValueType<'BTWN'>['value']).from
      } to  ${currencySymbol ?? ''}${
        (value.value as CustomFieldRangeValueType<'BTWN'>['value']).to
      } `;

    case 'UNS':
      return 'Is unassigned';
    default:
      return 'None';
  }
}
