import { FilterViews } from 'components/FilterViews';
import FilterViewsLoader from 'components/FilterViews/Loader/FilterViewsLoader';
import { FilterView } from 'components/FilterViews/type';
import produce from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import {
  draftInvoiceCurrentFilterViewSelector,
  draftInvoiceSavedFilterViewsSelector,
} from 'store/invoice-v2/draft-invoice-selector';
import {
  updateDraftInvoiceCurrentFilterview,
  updateDraftInvoiceSavedFilterview,
} from 'store/invoice-v2/invoice';
import { InvoiceFilterView } from 'types/filter-view/common';
import { DraftInvoiceFilterParams } from 'types/filter-view/draft-invoice';

export function DraftInvoiceFilterViews() {
  const dispatch = useDispatch();

  const currentFilterView = useSelector(draftInvoiceCurrentFilterViewSelector);
  const savedFilterViews = useSelector(draftInvoiceSavedFilterViewsSelector);

  function deleteFilterViewCallback(filterId: string) {
    const filterViews = savedFilterViews.filter((filterView) => filterView.id !== filterId);
    dispatch(updateDraftInvoiceSavedFilterview(filterViews));
    dispatch(updateDraftInvoiceCurrentFilterview());
  }

  function saveFilterViewCallback(filterViews: InvoiceFilterView<DraftInvoiceFilterParams>) {
    const updatedViews = produce(savedFilterViews, (draft) => {
      const isExists = draft.findIndex((view) => view.id === filterViews.id);
      draft[isExists] = filterViews;
    });
    dispatch(updateDraftInvoiceSavedFilterview(updatedViews));
    dispatch(updateDraftInvoiceCurrentFilterview(filterViews.id));
  }

  function updateCurrentView(filterViews: InvoiceFilterView<DraftInvoiceFilterParams>) {
    dispatch(updateDraftInvoiceCurrentFilterview(filterViews.id));
  }

  function saveAsFilterViewCallBack(filterViews: InvoiceFilterView<DraftInvoiceFilterParams>) {
    const updatedViews = produce(savedFilterViews, (draft) => {
      draft.push(filterViews);
    });
    dispatch(updateDraftInvoiceSavedFilterview(updatedViews));
    dispatch(updateDraftInvoiceCurrentFilterview(filterViews.id));
  }

  return (
    <FilterViewsLoader
      loading={currentFilterView ? !Object.keys(currentFilterView).length : true}
    >
      <FilterViews
        totalSavedViews={savedFilterViews as unknown as FilterView<DraftInvoiceFilterParams>[]}
        currentView={currentFilterView as unknown as FilterView<DraftInvoiceFilterParams>}
        filterType="invoice_filter"
        entity="draft-invoices"
        updatedFilterValue={currentFilterView?.invoice_filter}
        deleteFilterViewCallBack={deleteFilterViewCallback}
        saveAsFilterViewCallBack={saveAsFilterViewCallBack}
        saveFilterViewCallBack={saveFilterViewCallback}
        updateFilterViewCallBack={updateCurrentView}
      />
    </FilterViewsLoader>
  );
}
