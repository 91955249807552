import { Style } from 'lib/style/style';

const StyledList = Style('div', {
  variants: {
    styleType: {
      assignedToStyle: {
        '.ant-select-item-group': {
          fontSize: 'var(--fs-14) !important',
        },

        '.ant-select-item-option-selected': {
          background: 'inherit !important',
          fontWeight: 'var(--fs-semibold)',
        },

        '.ant-select-item-option-state': {
          color: 'var(--gray-10) !important',
        },
      },
      strategySelectStyle: {
        '.ant-select-item-group': {
          paddingTop: 'var(--space-16) !important',
        },
      },
      emailSelect: {
        '.ant-select-item-option-selected': {
          fontWeight: 'var(--fs-regular) !important',
        },
        '.ant-select-item-option-state': {
          display: 'none',
        },
      },
    },
  },
});

type Variants = React.ComponentProps<typeof StyledList>['styleType'];
interface RecepientListProps {
  options: React.ReactNode;
  styleType?: Variants;
}

export function RecepientList(props: RecepientListProps) {
  return <StyledList styleType={props.styleType}>{props.options}</StyledList>;
}
