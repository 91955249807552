import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Skeleton } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { FileUploadPreview } from 'components/BaseComponents/UploadFiles/FilePreview';
import { Fragment } from 'react';
import styled from 'styled-components';

interface UploadFilesProps<T> {
  fileList: T[] | undefined;
  showLoading?: boolean;
  children: React.ReactNode;
  onRemove?: (file?: T) => void;
  onClick?: () => void;
  extra?: React.ReactNode;
  customRenderer?: React.ReactNode;
}

const t = {
  add_files: 'Attach Files',
  attachments: 'Attachments',
};

const StyledWrapper = styled(Flex)`
  .ant-image {
    display: none !important;
  }
`;

function UploadFiles<T extends RcFile>(props: Readonly<UploadFilesProps<T>>) {
  const UploadIcon = (
    <FontAwesomeIcon icon={['fal', 'file-circle-plus']} color="var(--primary-7)" />
  );

  function handleClick() {
    props.onClick && props.onClick();
  }

  function handleRemove(file?: T) {
    if (!file) return;
    props.onRemove && props.onRemove(file);
  }
  const UploadButton = (
    <Button onClick={handleClick} icon={UploadIcon} color="var(--primary-7)">
      {t.add_files}
    </Button>
  );

  const UploadedFiles = (
    <Flex gap="--space-8" wrap="wrap">
      {props.fileList?.map((file) => {
        return <FileUploadPreview key={file.uid} file={file} onRemove={handleRemove} />;
      })}
    </Flex>
  );

  const Loader = <Skeleton.Button active style={{ width: '180px' }} />;

  const Title = (
    <Flex align="center" gap="var(--space-4)">
      {t.attachments}
    </Flex>
  );
  const Header = (
    <Flex direction="column" gap="var(--space-4)">
      {Title}
      {UploadButton}
    </Flex>
  );

  const Extra = (
    <Flex align="center" gap="--space-8" wrap="wrap">
      {props.extra}
    </Flex>
  );
  const Body = (
    <StyledWrapper align="center" wrap="wrap" gap="--space-8">
      {props.extra && Extra}
      <Flex.Child style={{ order: 1 }}> {props.showLoading && Loader}</Flex.Child>
      <Flex.Child> {props.customRenderer ? props.customRenderer : UploadedFiles}</Flex.Child>
    </StyledWrapper>
  );
  return (
    <Fragment>
      <Flex direction="column" gap="--space-12" style={{ width: '100%' }} align="flex-start">
        {Header}
        {Body}
      </Flex>
      {props.children}
    </Fragment>
  );
}

export default UploadFiles;
