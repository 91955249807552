import { amountFormats } from '@sinecycle/growcomponents';
import { useUpdateCustomFieldActions } from 'queries/custom-field';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CustomFieldTypes } from 'services/custom-field-edit';
import { localeSelector } from 'store/authentication/authentication';
import {
  CustomFieldDataType,
  CustomFieldEntityType,
  CustomFieldOption,
  CustomFieldUIType,
  NumberBasedField,
  validNumberBasedFields,
} from 'types/entities/custom-field';
import useGetBaseCurrencySymbol from 'util/hooks/useGetBaseCurrencySymbol';
import { notify } from '../../Notifications';
import DatePickerComp from './DatePicker';
import NumericEditorCell from './NumericEditorCell';
import SelectEditorCell from './SelectEditorCell';
import TextEditorCell from './TextEditorCell';
const t = {
  success_text: 'Custom Filed Value Updated',
  error_text: 'Custom Filed Not Updated',
  validation_error_empty: 'This field should not be empty',
  validation_error_maxLen: 'Characters Should not exceed 500 characters',
};
interface CustomFieldEditProps {
  successCallBack?: (value: string) => void;
  options: CustomFieldOption[];
  ui_type: CustomFieldUIType;
  entity_type: CustomFieldEntityType;
  entity_id: number;
  custom_field_id: number;
  value: string;
  data_type: CustomFieldDataType;
}

export default function EditCustomField(props: CustomFieldEditProps) {
  const { currencySymbol } = useGetBaseCurrencySymbol();
  const { mutateAsync: updateCustomFieldData, isLoading } = useUpdateCustomFieldActions(
    props.custom_field_id
  );

  const locale = useSelector(localeSelector);
  const precision = props?.data_type === 'DECIMAL' ? 2 : 0;
  const prefixSymbol = props.data_type === 'CURRENCY' ? currencySymbol : undefined;
  const customFieldOptions = useMemo(handleCustomFieldOptions, [
    currencySymbol,
    locale,
    props.data_type,
    props.options,
  ]);

  const NumericCell = (
    <NumericEditorCell
      currencySymbol={prefixSymbol}
      onChange={handleSelect}
      value={Number(props.value)}
      precision={precision}
    />
  );
  const InputCustomField = (
    <>
      {validNumberBasedFields.includes(props.data_type as NumberBasedField[number]) ? (
        NumericCell
      ) : (
        <TextEditorCell onChange={handleSelect} value={props.value} prefixSymbol={prefixSymbol} />
      )}
    </>
  );

  const EditCustomField =
    props.ui_type === CustomFieldUIType.DROPDOWN ? (
      <SelectEditorCell
        options={customFieldOptions}
        value={props.value}
        isLoading={isLoading}
        onChange={handleSelected}
        dataType={props.data_type}
        customOptions={props.options}
      />
    ) : props.ui_type === CustomFieldUIType.DATEPICKER ? (
      <DatePickerComp onChange={handleSelect} value={props.value} />
    ) : (
      InputCustomField
    );

  function handleCustomFieldOptions() {
    if (props.options.length) {
      return props.options.map((option) => {
        return {
          key: option,
          value: option,

          label:
            props.data_type === 'CURRENCY'
              ? `${currencySymbol}${option}`
              : props.data_type === 'NUMBER'
              ? amountFormats.number(option as number, { locale })
              : props.data_type === 'DECIMAL'
              ? amountFormats.number(option as number, { locale, mode: 'DECIMAL' })
              : option,
        };
      });
    }
    return [];
  }
  function handleSelected(value?: string | number) {
    handleSelect(value);
  }
  function handleSelect(value?: CustomFieldTypes) {
    updateCustomFieldData({
      value,
      entity_id: props.entity_id,
      entity_type: props.entity_type,
      custom_field_id: props.custom_field_id,
    })
      .then(() => {
        notify.success(t.success_text);
        props?.successCallBack?.(value as string);
      })

      .catch((e) => {
        notify.error(t.error_text);
        console.log(e);
      });
  }

  return (
    <div style={{ width: '100%', background: 'var(--gray-1)', borderRadius: '4px' }}>
      {EditCustomField}
    </div>
  );
}
