import { useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { inboxQueryKeys, useActivityMarkAsRead, useActivityMarkAsUnRead } from 'queries/inbox';
import { ActivityType } from 'types/activities/activity-types';
import {
  AssignedActivities,
  InboxActivityMode,
  SubscribedActivities,
} from 'types/api/inbox/activity';
import { useActivitySearchParams } from './useActivitySearchParams';
import { handleCreateCustomEventCallBack } from './useActivityUpdation';

type MarkAs = 'Read' | 'Unread';
function useUpdateReadActivity(id?: number, read?: boolean, type?: ActivityType) {
  const { activitySearchParams } = useActivitySearchParams();
  const { mutateAsync: markAsRead } = useActivityMarkAsRead();
  const { mutateAsync: markAsUnRead } = useActivityMarkAsUnRead();
  const queryClient = useQueryClient();

  const queryKeys =
    activitySearchParams.activity_mode === InboxActivityMode.SUBSCRIBED
      ? inboxQueryKeys.subscribed_activities
      : inboxQueryKeys.assigned_activities;

  function handleSuccessCallBack(threadId: number, mark_as: MarkAs, type?: ActivityType) {
    //on `MarkAsReadSuccess` we need to subtract the present unread count thats what we're doing here
    const currentData = queryClient.getQueriesData<AssignedActivities | SubscribedActivities>([
      queryKeys,
    ]);

    if (!currentData.length) return;
    const [queryKey, previousData] = currentData[0];
    if (!previousData?.data) return;
    const currentUnreadCount = previousData?.unread_count ?? 0;
    const subtractionValue = previousData?.unread_count ? 1 : 0;
    const newUnreadCount = currentUnreadCount - subtractionValue;
    const unreadCount = currentUnreadCount + 1;

    const updatedValue = produce(previousData.data, (draft) => {
      const isExists = draft?.findIndex(
        (f) => f.user_activity_id === threadId && f.entity_type === type
      );
      if (isExists === -1) {
        return draft;
      }
      draft[isExists].read = mark_as === 'Read';
      return draft;
    });

    queryClient.setQueryData<AssignedActivities | SubscribedActivities>(queryKey, (old) =>
      old
        ? {
            ...old,
            data: updatedValue,
            unread_count: mark_as === 'Read' ? newUnreadCount : unreadCount,
          }
        : old
    );
    handleCreateCustomEventCallBack('update_count', {
      value: { count: mark_as === 'Read' ? newUnreadCount : unreadCount },
    });
  }

  function handleMarkAsUnRead(id: number, type?: ActivityType) {
    markAsUnRead([id]).then(() => {
      handleSuccessCallBack(id, 'Unread', type);
    });
  }

  function handleMarkAsRead(id: number, type?: ActivityType) {
    markAsRead([id]).then(() => {
      handleSuccessCallBack(id, 'Read', type);
    });
  }
  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.stopPropagation();

    if (read) {
      handleMarkAsUnRead(id as number, type);
    } else {
      handleMarkAsRead(id as number, type);
    }
  }
  return { handleClick, handleMarkAsUnRead, handleMarkAsRead };
}

export default useUpdateReadActivity;
