import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Empty } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { PageLink } from 'lib/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { folderIdSelector, setCurrentFolderId } from 'store/email-template/email-template';
import { folderSelector, insertEmailTemplateSelector } from 'store/insert';
import { EmailTemplates } from 'types/entities/email-templates';
import { EmailTemplateListOptions } from './Options';
import { EmailTemplateListSideBar } from './menu';

const t = {
  emptyDesc: 'No Templates Available',
  buttonText: 'New Email Template',
};

export function sortTemplates(templates: EmailTemplates[]) {
  return templates.sort((set1, set2) => {
    const set2Time = new Date(set2.updated_at).getTime();
    const set1Time = new Date(set1.updated_at).getTime();
    return set2Time - set1Time;
  });
}

function EmailTemplateListBody() {
  const folders = useSelector(folderSelector);
  const templatesFolderId = useSelector(folderIdSelector);
  const dispatch = useDispatch();
  const [{ id }] = folders;
  const [folderId, setFolderId] = useState<number>(id);
  const emailTemplates = useSelector(
    insertEmailTemplateSelector(templatesFolderId ? (templatesFolderId as number) : folderId)
  );
  const [{ name, description, templateCount }] = folders.filter(
    (folder) => folder.id === (templatesFolderId ? (templatesFolderId as number) : folderId)
  );
  const handleSelectCallBack = (id: number) => {
    setFolderId(id);
  };

  useEffect(() => {
    if (!templatesFolderId) {
      dispatch(setCurrentFolderId({ folderId: id }));
    }
  }, [dispatch, id, templatesFolderId]);

  function getTemplateList() {
    const nonDeletedTemplates = emailTemplates.filter((template) => !template.deleted);

    return sortTemplates(nonDeletedTemplates);
  }

  const templateList = getTemplateList();
  return (
    <div
      style={{ display: 'grid', gridTemplateColumns: '250px auto', height: 'calc(100vh - 155px)' }}
    >
      <EmailTemplateListSideBar folders={folders} onSelectCallBack={handleSelectCallBack} />
      {Boolean(emailTemplates.length) && (
        <EmailTemplateListOptions
          folderName={name}
          desc={description}
          templates={templateList}
          count={templateCount}
        />
      )}
      {!emailTemplates.length && (
        <Flex align="center" direction="column" justify="center">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t.emptyDesc} />
          <PageLink appPage="EMAIL_TEMPLATE_NEW">
            <Button
              type="default"
              icon={<FontAwesomeIcon icon={['far', 'plus']} color="var(--gray-10)" />}
            >
              {t.buttonText}
            </Button>
          </PageLink>
        </Flex>
      )}
    </div>
  );
}

export { EmailTemplateListBody };
