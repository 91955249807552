import { groupBy, isEmpty, pick, uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  insertCustomFieldSelector,
  insertOtherPlaceholdersSelector,
  insertPlaceholderSelector,
  insertPlaceholdersSelector,
  viewPlaceholdersSelector,
} from 'store/insert';
import styled from 'styled-components';
import { FolderType, PlaceholderProps } from 'types/entities/email-templates';
import { OptionProps } from '../type';
import { PlaceholderSideBar } from './Menu';
import { PlaceholderOptions } from './Options';

const PlaceholderBodyStyled = styled.div`
  --placeholders-body-title: 50px;
  --placeholders-search-height: 70px;

  height: calc(100% - var(--placeholders-body-title));
  display: flex;
`;

interface PlaceholderBodyProps {
  onPlaceholderInsertCallback: (value: string) => void;
  viewOnly?: boolean;
  optionsOverrideStyles?: React.CSSProperties;
  sideBarOverrideStyles?: React.CSSProperties;
  validFolders: FolderType[] | undefined;
  folderType: string | undefined;
}

/**
 * selectors
 *view only selector for viewing total placeholders.
 *insertPlaceholderSelector for in email templates page
 *insertPlaceholdersSelector for in followup email scoped placeholders
 *insertCustomFieldSelector for customFieldsPlacesHolders
 */

function PlaceholderBody(props: PlaceholderBodyProps) {
  const [selectedSection, setSelectedSection] = useState<string>();
  const [placeholderOptions, setPlaceholderOptions] = useState<OptionProps[]>();
  const [searchKeyword, setSearchKeyword] = useState<string>();

  const insertComponentPlaceholders = useSelector(insertPlaceholdersSelector);
  const [searchFilteredPlaceholder, setSearchFilteredPlaceholder] = useState<PlaceholderProps[]>(
    []
  );

  const selector = props.viewOnly
    ? viewPlaceholdersSelector
    : insertPlaceholderSelector(props.folderType as FolderType);
  const customFieldsPlacesHolders = useSelector(insertCustomFieldSelector);
  const allOtherPlacesHolders = useSelector(insertOtherPlaceholdersSelector);
  const placeholders = useSelector(selector);

  const scopedOtherPlaceholders = useMemo(() => {
    const isInvoiceEntity = props.validFolders?.includes(FolderType.SINGLE_INVOICE);
    if (isInvoiceEntity) {
      return allOtherPlacesHolders ?? [];
    } else {
      const scoped = allOtherPlacesHolders?.filter((placeholder) =>
        placeholder.subSection.includes('Customer')
      );
      return scoped ?? [];
    }
  }, [allOtherPlacesHolders, props.validFolders]);

  const placeHolderArrayStructure = useCallback((placeholders: PlaceholderProps[]) => {
    const removedDuplicatesFromArray = uniqBy(placeholders, 'placeholder');
    const sectionObj = groupBy(removedDuplicatesFromArray, 'section');

    return Object.keys(sectionObj).map((key) => {
      const subSectionObj = groupBy(sectionObj[key], 'subSection');
      return {
        section: key,
        options: Object.keys(subSectionObj).map((subSectionKey) => {
          return {
            subSection: subSectionKey,
            placeholders: subSectionObj[subSectionKey],
          };
        }),
      };
    });
  }, []);

  const handlePlaceholderArrayStructure = useCallback(
    (placeholders: PlaceholderProps[]) => {
      if (!searchFilteredPlaceholder.length) {
        let placeHolderArray = placeholders;
        if (customFieldsPlacesHolders.length > 0) {
          placeHolderArray = [...placeHolderArray, ...customFieldsPlacesHolders];
        }
        if (scopedOtherPlaceholders.length > 0) {
          placeHolderArray = [...placeHolderArray, ...scopedOtherPlaceholders];
        }
        return placeHolderArrayStructure(placeHolderArray);
      } else {
        return placeHolderArrayStructure(searchFilteredPlaceholder);
      }
    },
    [
      customFieldsPlacesHolders,
      placeHolderArrayStructure,
      scopedOtherPlaceholders,
      searchFilteredPlaceholder,
    ]
  );

  const scopingPlaceholders: PlaceholderProps[] = useMemo(
    () =>
      Object.values(pick(insertComponentPlaceholders, props.validFolders as FolderType[])).flat(),
    [insertComponentPlaceholders, props.validFolders]
  );

  const placeholdersArray = useMemo(() => {
    if (placeholders) {
      return handlePlaceholderArrayStructure(placeholders);
    } else if (!placeholders && Boolean(props.validFolders?.length)) {
      return handlePlaceholderArrayStructure(scopingPlaceholders);
    }
    return [];
  }, [
    placeholders,
    props.validFolders?.length,
    handlePlaceholderArrayStructure,
    scopingPlaceholders,
  ]);

  const defaultOpenSection = useMemo(() => {
    const [
      {
        section,
        options: [{ subSection }],
      },
    ] = placeholdersArray;
    return `${section}-${subSection}`;
  }, [placeholdersArray]);

  const handleClearCallBack = () => {
    setSearchKeyword('');
    updateAfterSearch();
  };

  const handleSearchCallBack = (value: string) => {
    setSearchFilteredPlaceholder([]);
    setSearchKeyword(value);
    updateAfterSearch();
  };

  const noResultOnSearch = isEmpty(searchFilteredPlaceholder) && !isEmpty(searchKeyword);

  const filterPlaceholders = useCallback(
    (placeholdersArray: PlaceholderProps[]) => {
      return placeholdersArray.filter((object) =>
        Object.values(object).some(
          (value) => value && value.toLowerCase().includes(searchKeyword as string)
        )
      );
    },
    [searchKeyword]
  );

  const searchedPlaceholdersCount = useMemo(() => {
    return searchFilteredPlaceholder.length;
  }, [searchFilteredPlaceholder.length]);

  const updateAfterSearch = useCallback(() => {
    setSelectedSection(defaultOpenSection);
  }, [defaultOpenSection]);

  useEffect(() => {
    if (selectedSection) {
      const [section, subsection] = selectedSection.split('-');
      const selectedPlaceholders = placeholdersArray
        .find((placeholder) => placeholder.section === section)
        ?.options.filter((option) => option.subSection === subsection);

      if (selectedPlaceholders) {
        setPlaceholderOptions(selectedPlaceholders);
      }
    } else {
      setSelectedSection(defaultOpenSection);
    }
  }, [defaultOpenSection, placeholdersArray, selectedSection]);

  const handleSelectCallBack = (value: string) => {
    setSelectedSection(value);
  };

  useEffect(() => {
    if (searchKeyword) {
      const searchFiltered = props.validFolders?.length
        ? filterPlaceholders([
            ...scopingPlaceholders,
            ...customFieldsPlacesHolders,
            ...scopedOtherPlaceholders,
          ])
        : filterPlaceholders([
            ...placeholders,
            ...customFieldsPlacesHolders,
            ...scopedOtherPlaceholders,
          ]);

      setSearchFilteredPlaceholder(searchFiltered);
      if (searchFiltered.length) {
        const { section, subSection } = searchFiltered[0];
        setSelectedSection(`${section}-${subSection}`);
      }
    }
  }, [
    filterPlaceholders,
    scopingPlaceholders,
    placeholders,
    props.validFolders?.length,
    searchKeyword,
    customFieldsPlacesHolders,
    scopedOtherPlaceholders,
  ]);

  return (
    <PlaceholderBodyStyled>
      <PlaceholderSideBar
        selectedKeys={selectedSection}
        onClearCallBack={handleClearCallBack}
        onSearchCallback={handleSearchCallBack}
        placeholdersArray={placeholdersArray}
        onSelectCallback={handleSelectCallBack}
        sideBarOverrideStyles={props.sideBarOverrideStyles}
        searchKeyword={searchKeyword}
        searchResultCount={searchedPlaceholdersCount}
        viewOnly={props.viewOnly}
      />

      {!noResultOnSearch && placeholderOptions && (
        <PlaceholderOptions
          selectedSection={selectedSection}
          placeholdersOptions={placeholderOptions}
          onPlaceholderInsertCallback={props.onPlaceholderInsertCallback}
          viewOnly={props.viewOnly}
          optionsOverrideStyles={props.optionsOverrideStyles}
        />
      )}
    </PlaceholderBodyStyled>
  );
}

export { PlaceholderBody };
