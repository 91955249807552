import { TableSelectedRows } from '@sinecycle/growcomponents';
import { message } from 'antd';
import { useDownloadDocument } from 'hooks/use-download-document';
import { head, snakeCase } from 'lodash';
import { IInvoices } from 'store/customer/types';

function useDownloadPdf() {
  const { download } = useDownloadDocument();
  function handleDownload(selectedRows: TableSelectedRows<IInvoices>) {
    const url = head(selectedRows)?.data.invoice_url;
    const filename = `${snakeCase(head(selectedRows)?.data.invoice_no.trim())}.pdf`;

    if (!url) return;
    message.loading({
      type: 'loading',
      content: 'Action in progress..',
      duration: 0,
    });
    download(url, { fileName: filename }).then(() => {
      message.destroy();
    });
  }
  return { handleDownload };
}

export default useDownloadPdf;
