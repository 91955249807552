import { BulkAction } from '@sinecycle/growcomponents';
import TaskFormModal from 'components/Common/ActivitiesForm/Task/TaskFormModal';
import { TaskFormText } from 'components/Common/ActivitiesForm/text';
import {
  CustomActivityProps,
  FormItemNames,
  MutationFunctionProps,
} from 'components/Common/ActivitiesForm/types';
import { TaskFormActivityProps, TaskResponseSchema } from 'components/Common/hooks/type';
import {
  ActivitySchema,
  invoiceTransformSchema,
} from 'components/HigherOrderComponent/KeyActivitesContainer/ActivitySchema';
import useFormSubmitMutate from 'components/HigherOrderComponent/KeyActivitesContainer/hooks/useFormSubmitMutate';
import {
  ActivityMutationProps,
  MutationFunctionResponseType,
} from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { invoiceItemsFn } from 'components/HigherOrderComponent/KeyActivitesContainer/utils';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { IInvoices } from 'store/customer/types';
import useOpen from 'util/hooks/useOpen';

export interface CreateTaskProps extends BulkAction<IInvoices> {
  customerId?: number;
  customTransformer: (
    data: Record<FormItemNames, any>,
    customProps?: CustomActivityProps
  ) => Record<string, any>;
  invoices: IInvoices[];
}

export default function CreateTask(props: CreateTaskProps) {
  const { selectedKey, customerId, customTransformer, invoices } = props;
  const onFormMutateFn = useFormSubmitMutate();
  const dateFormat = useSelector(dateFormatSelector);

  const { onClose, open } = useOpen({
    open: Boolean(selectedKey === 'TASK'),
  });

  function onMutateFn(data: MutationFunctionProps<ActivityMutationProps>) {
    return onFormMutateFn(data);
  }

  const invoiceItemsQueries = invoiceItemsFn<IInvoices>(customerId);

  return (
    <TaskFormModal<TaskFormActivityProps<IInvoices>>
      openModal={open}
      onClose={onClose}
      onMutateFn={onMutateFn as MutationFunctionResponseType<TaskResponseSchema>}
      invoices={invoices}
      invoiceTransformSchema={invoiceTransformSchema}
      customTransformer={customTransformer}
      text={TaskFormText}
      responseSchema={ActivitySchema.TASK}
      dateFormat={dateFormat}
      {...invoiceItemsQueries}
    />
  );
}
