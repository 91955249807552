import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { useMemo } from 'react';
import { IConversationParticipant } from 'store/activity-feed/type';
import { getFormattedDate } from 'util/datetime-formatter';
import { EmailDeliveryStatusConfigs } from './predicate';
import { EmailDeliveryStatusList } from './type';

interface EmailStatusProps {
  recipient: IConversationParticipant;
  hideEmail?: boolean;
  hideDate?: boolean;
}

export function getEmailStatus(recipient: IConversationParticipant) {
  if (recipient.bounced && !(recipient.delivered || recipient.opened)) {
    return EmailDeliveryStatusList.NOT_DELIVERED;
  } else if (recipient.opened) {
    return EmailDeliveryStatusList.READ;
  } else if (recipient.delivered) {
    return EmailDeliveryStatusList.DELIVERED;
  } else {
    return EmailDeliveryStatusList.SENT;
  }
}
function EmailStatus(props: EmailStatusProps) {
  const { recipient, hideDate = false, hideEmail = false } = props;
  const status = useMemo(() => getEmailStatus(recipient), [recipient]);
  const config = EmailDeliveryStatusConfigs[status];
  const Email = (
    <GrowText ellipsis style={{ maxWidth: '240px' }}>
      {recipient.email}
    </GrowText>
  );
  const Status = (
    <Flex align="center" gap="var(--space-4)">
      <FontAwesomeIcon icon={config.iconName} color={config.iconColor} fontSize={14} />
      <GrowText style={{ color: config.labelColor, fontWeight: 'var(--fs-regular)' }}>
        {config.label}
      </GrowText>
    </Flex>
  );

  const Date = recipient[config.dateProp] ? (
    <GrowText style={{ color: 'var(--gray-7)' }}>
      {getFormattedDate(recipient[config.dateProp], 'WITH_TIME')}
    </GrowText>
  ) : null;

  const hide = Boolean(hideEmail) || Boolean(hideDate);
  const isDatePresent = Boolean(hideDate) || !recipient[config.dateProp];

  return (
    <Row
      gutter={hide ? 0 : [16, 16]}
      style={{ width: '100%', marginBottom: hideEmail ? undefined : 'var(--space-6)' }}
    >
      <HideWrapper hide={Boolean(hideEmail)}>
        <Col flex="auto" style={{ lineHeight: '1.2' }}>
          {Email}
        </Col>
      </HideWrapper>
      <Row gutter={[16, 16]}>
        <Col flex="auto">{Status}</Col>
        <HideWrapper hide={isDatePresent}>
          <Col flex="auto" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {Date}
          </Col>
        </HideWrapper>
      </Row>
    </Row>
  );
}

export { EmailStatus };
