import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getFormattedDateForRelativeOptions,
  RelativeDateRange,
} from '../../util/relative-date-range';
import { FilterViewInterface, SavedFilterViewInterface, TFilterViews } from '../aging/types';
import { IFilterType } from '../type';
import {
  ClosedInvoicesListFilters,
  DraftInvoicesListFilters,
  InvoiceListProps,
  OpenInvoicesListFilters,
} from './type';

const initialState: InvoiceListProps = {
  filterViews: {
    currentView: {},
    totalSavedViews: [{}],
  } as SavedFilterViewInterface<TFilterViews>,
  shouldTransitionToInvoiceDetails: false,
};

const invoiceListNormalizer = (
  filterType: 'OPEN_INVOICES' | 'CLOSED_INVOICES' | 'DRAFT_INVOICES',
  payload: FilterViewInterface<
    OpenInvoicesListFilters | ClosedInvoicesListFilters | DraftInvoicesListFilters
  >[]
) => {
  const dateRangeCallBack = () => {
    if (payload.length && filterType === 'OPEN_INVOICES') {
      return [
        'due_date_range',
        'issue_date_range',
        'last_activity_range',
        'next_followup_range',
        'promise_pay_range',
      ];
    } else if (payload.length && filterType === 'DRAFT_INVOICES') {
      return [
        'due_date_range',
        'issue_date_range',
        'last_activity_range',
        'next_followup_range',
        'promise_pay_range',
      ];
    } else {
      return ['due_date_range', 'issue_date_range', 'closed_date_range'];
    }
  };

  const dateRange = dateRangeCallBack();

  let currentFilter = {} as FilterViewInterface<
    OpenInvoicesListFilters | ClosedInvoicesListFilters | DraftInvoicesListFilters
  >;
  const normalizedRangeValue = {} as any;

  //check using local storage
  const item: IFilterType = JSON.parse(localStorage.getItem('filters') as string) ?? {};
  currentFilter =
    payload?.find((filter) => {
      return filter?.id === item[filterType];
    }) || payload[0];

  type attr = keyof OpenInvoicesListFilters &
    keyof ClosedInvoicesListFilters &
    keyof DraftInvoicesListFilters;

  currentFilter.invoice_filter &&
    Object.keys(currentFilter.invoice_filter).forEach((attribute) => {
      if (currentFilter.invoice_filter) {
        if (dateRange.includes(attribute)) {
          const from = currentFilter.invoice_filter[`${attribute.replace(/range/i, 'from')}` as attr];
          const to = currentFilter.invoice_filter[`${attribute.replace(/range/i, 'to')}` as attr];
          const relativeDateIndex = currentFilter.invoice_filter[attribute as attr];

          const [newFrom, newTo] = !(relativeDateIndex === 'CUSTOM_RANGE')
            ? getFormattedDateForRelativeOptions(relativeDateIndex as RelativeDateRange)
            : [from, to];

          normalizedRangeValue[`${attribute.replace(/range/i, 'from')}` as attr] = newFrom;
          normalizedRangeValue[`${attribute.replace(/range/i, 'to')}` as attr] = newTo;
          normalizedRangeValue[attribute as attr] = relativeDateIndex;
        } else {
          normalizedRangeValue[attribute as attr] = currentFilter.invoice_filter[attribute as attr];
        }
      }
    });

  return {
    ...currentFilter,
    invoice_filter: normalizedRangeValue,
  };
};

const InvoiceListSlice = createSlice({
  name: 'invoiceList',
  initialState,
  reducers: {
    updateAllFilterList(state, action: PayloadAction<FilterViewInterface<TFilterViews>[]>) {
      state.filterViews.totalSavedViews = action.payload;
    },
    updateSavedFilterViewsAction(
      state,
      action: PayloadAction<{
        type: 'OPEN_INVOICES' | 'CLOSED_INVOICES' | 'DRAFT_INVOICES';
      }>
    ) {
      state.filterViews.currentView = invoiceListNormalizer(
        action.payload.type,
        state.filterViews.totalSavedViews as FilterViewInterface<
          OpenInvoicesListFilters | ClosedInvoicesListFilters | DraftInvoicesListFilters
        >[]
      );
    },
    toggleTransitionToInvoiceDetails(state, action: PayloadAction<boolean>) {
      state.shouldTransitionToInvoiceDetails = action.payload;
    },
  },
});

export const {
  updateAllFilterList,
  updateSavedFilterViewsAction,
  toggleTransitionToInvoiceDetails,
} = InvoiceListSlice.actions;

export default InvoiceListSlice.reducer;
