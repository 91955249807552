import { sessionStore } from 'util/browser-storage';

interface SessionStorageInitializationProps {
  storageKey?: string;
  id: string;
  updatedFilterValue: any;
}

function sessionStorageInitialization(props: Readonly<SessionStorageInitializationProps>) {
  const { getItem: getFromSessionStorage, setItem: setToSessionStorage } = sessionStore;
  const { id, updatedFilterValue, storageKey } = props;
  const sessionFilter = getFromSessionStorage(`${storageKey}_FILTERS`) as any;
  if (!storageKey) return;
  if (id && updatedFilterValue && Object.keys(updatedFilterValue).length) {
    const storageValue = {
      [id]: updatedFilterValue,
    };

    if (!sessionFilter) {
      setToSessionStorage(`${storageKey}_FILTERS`, storageValue);
    } else {
      const updatedSessionValue = { ...sessionFilter, [id]: updatedFilterValue };
      setToSessionStorage(`${storageKey}_FILTERS`, updatedSessionValue);
    }
  }
  return;
}

export default sessionStorageInitialization;
