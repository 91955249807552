import { Col, DatePicker, Input, Row } from 'antd';
import { TextArea } from 'components/BaseComponents/Input';
import { EllipsisList } from 'components/Common/EllipsisList';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import dayjs from 'dayjs';
import { filter, map, some } from 'lodash';
import AssignOwners from '../FormElements/AssignOwners';
import InvoiceSelect from '../FormElements/InvoiceSelect';
import { ActivityFormItem, EllipsisListContainer } from '../style';
import { TaskFormModalProps, TaskModalFormElementProps } from '../types';

export default function TaskFormItems<TaskElements extends TaskModalFormElementProps>(
  props: Omit<TaskFormModalProps<TaskElements>, 'openModal' | 'remainderForm'>
) {
  const {
    title,
    participants,
    participantConfig,
    invoices,
    form,
    invoiceQueryKey,
    invoiceSearchFn,
    invoiceQueryFn,
    invoiceTransformSchema,
    description,
    dueDate,
    text,
    bulkAction,
    bulkRecordsLength,
    dateFormat,
  } = props;
  const {
    invoiceLabel,
    invoicePlaceholder,
    titleLabel,
    titlePlaceholder,
    dateLabel,
    datePlaceholder,
    descriptionLabel,
    descriptionPlaceholder,
    assignOwnersPlaceholder,
    assignedOwnersLabel,
  } = text;

  const selectedInvoiceIds = map(invoices, (item) => (item as { id: number })?.id);
  const selectedInvoiceNo = map(invoices, (item) => (item as any)?.invoice_no);
  const selectedUsers =
    filter(participants, (valueItem) =>
      some(participantConfig, (assigneeItem) => valueItem.id === assigneeItem.id)
    ) || [];
  const assigneeOptionType = selectedInvoiceIds.length
    ? ActionableEntity.INVOICE
    : ActionableEntity.CUSTOMER;

  function onChangeInvoiceList(invoiceList: number[]) {
    form.setFieldValue('taskInvoices', invoiceList);
  }

  const InvoiceSelectList = (
    <ActivityFormItem label={invoiceLabel} name={'taskInvoices'} initialValue={selectedInvoiceIds}>
      <InvoiceSelect<TaskElements['invoiceSelect']>
        placeholder={invoicePlaceholder}
        selectedInvoicesId={selectedInvoiceIds}
        selectedInvoices={invoices}
        onChangeInvoice={onChangeInvoiceList}
        queryFn={invoiceQueryFn}
        searchQueryFn={invoiceSearchFn}
        queryKey={invoiceQueryKey}
        transformSchema={invoiceTransformSchema}
      />
    </ActivityFormItem>
  );

  const TaskTitle = (
    <ActivityFormItem
      label={titleLabel}
      name="taskTitle"
      initialValue={title}
      rules={[
        {
          required: true,
          message: `${titleLabel} is required`,
        },
      ]}
      required
    >
      <Input placeholder={titlePlaceholder} />
    </ActivityFormItem>
  );

  const TaskDescription = (
    <ActivityFormItem label={descriptionLabel} name="taskDescription" initialValue={description}>
      <TextArea placeholder={descriptionPlaceholder} />
    </ActivityFormItem>
  );

  const TaskDate = (
    <ActivityFormItem
      label={dateLabel}
      name="taskDate"
      initialValue={dueDate ? dayjs(dueDate) : undefined}
      rules={[
        {
          required: true,
          message: `${dateLabel} is required`,
        },
      ]}
    >
      <DatePicker placeholder={datePlaceholder} format={dateFormat} style={{ width: '100%' }} />
    </ActivityFormItem>
  );

  const AssignedToTaskOwner = (
    <ActivityFormItem label={assignedOwnersLabel} name="taskOwner" initialValue={participantConfig}>
      <AssignOwners
        assigneeUserList={selectedUsers}
        placeholder={assignOwnersPlaceholder}
        assigneeOptionType={assigneeOptionType}
      />
    </ActivityFormItem>
  );

  return (
    <>
      {bulkAction ? (
        <EllipsisListContainer>
          <EllipsisList
            label="Added Invoices"
            list={map(selectedInvoiceNo, (item) => String(item))}
            totalRecords={bulkRecordsLength}
            showScrollablePopover
          />
        </EllipsisListContainer>
      ) : (
        InvoiceSelectList
      )}
      {TaskTitle}
      {TaskDescription}
      <Row gutter={16}>
        <Col span={12}>{AssignedToTaskOwner}</Col>
        <Col span={12}>{TaskDate}</Col>
      </Row>
    </>
  );
}
