import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { COMMON_EVENT } from 'events/common';
import { dispatchAppEvent } from 'lib/pub-sub';
import { concat } from 'lodash';
import { useCreateDisputeTypeMutation } from 'queries/dispute-type';
import { CreateDisputeContaniner } from '../style';
import { CreateDisputeTypeProps } from '../types';

export default function CreateDisputeType(props: CreateDisputeTypeProps) {
  const { disputeName, selectedValue, queryKey, onDisputeCreate } = props;
  const { mutateAsync: createDisputeType } = useCreateDisputeTypeMutation();
  const queryClient = useQueryClient();

  async function onCreateDisputeType() {
    try {
      const data = await createDisputeType(disputeName);
      await queryClient.refetchQueries(queryKey);

      dispatchAppEvent({
        type: COMMON_EVENT.ACTION_WITHIN_COLLECTION_ACTIVITY_CREATION,
        payload: {
          collectionAction: 'Raise a Dispute',
          actionName: 'New Dispute type',
        },
      });

      if (data) {
        onDisputeCreate(concat(selectedValue || [], data.id));
      }
    } catch {
      throw new Error();
    }
  }

  return (
    <CreateDisputeContaniner
      className="add-dispute-item cursor-pointer"
      onClick={onCreateDisputeType}
    >
      <FontAwesomeIcon icon={['far', 'plus-circle']} />
      &nbsp;&nbsp;{`'${disputeName}' as Dispute Type`}
    </CreateDisputeContaniner>
  );
}
