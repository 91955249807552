import { Flex } from 'components/BaseComponents/Layout/Flex';
import {
  CreatedBlock,
  CustomerBlock,
  LabelBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { ActivityDetailsBase } from 'types/activities/activity-types';
import { CallLog } from 'types/activities/callLog';

interface CallLogInfoProps extends ActivityDetailsBase {
  data?: CallLog;
  loading: boolean;
}

function CallLogDetailsInfo(props: CallLogInfoProps) {
  const { data, loading, customer } = props;

  const Created =
    data && !loading ? (
      <CreatedBlock createdBy={data.created_by} createdDate={data.created_date} />
    ) : null;
  const Customer =
    customer && !loading ? <CustomerBlock id={customer.id} name={customer.name} /> : null;
  return (
    <Flex direction="column" gap="var(--space-16)">
      <InfoContent content={Created} label={<LabelBlock label="Created" color="var(--gray-7)" />} />
      <InfoContent
        content={Customer}
        label={<LabelBlock label="Customer" color="var(--gray-7)" />}
      />
    </Flex>
  );
}

export { CallLogDetailsInfo };
