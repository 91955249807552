import { List, Skeleton } from 'antd';
import { Item, Meta } from 'components/BaseComponents/Lists';
import { DocumentTypesList } from 'types/entities/documents';
import { DocActions } from './DocActions';
import { DocLevel } from './DocLevel';
import { DocumentDesc, DocumentTitle } from './DocTitleAndDesc';
import { DocTypeEnable } from './DocTypeEnable';
import { StyledItemCard } from './style';

interface DocumentTypesListCardProps {
  isLoading: boolean;
  documentData: DocumentTypesList[];
}

const listStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '40px',
};

export default function DocumentTypesListCard(props: DocumentTypesListCardProps) {
  const { isLoading, documentData } = props;

  return (
    <List<DocumentTypesList>
      itemLayout="horizontal"
      loading={isLoading}
      dataSource={documentData}
      style={listStyles}
      renderItem={(item) => {
        return (
          <StyledItemCard>
            <Skeleton loading={isLoading} active>
              <Item actions={[<DocActions key={item.id} value={item} />]} style={{ padding: 0 }}>
                <Meta
                  avatar={
                    <DocTypeEnable
                      enabled={item.enabled}
                      is_default={item.is_default}
                      id={item.id}
                    />
                  }
                  title={<DocumentTitle name={item.name} />}
                  description={<DocumentDesc description={item.description} />}
                />
                <DocLevel entity_type={item.entity_type} />
              </Item>
            </Skeleton>
          </StyledItemCard>
        );
      }}
    />
  );
}
