import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Empty, Menu } from 'antd';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { MenuProps } from 'components/FilterViews/type';
import { capitalize } from 'lodash';
import { placeholdersArrayProps } from '../../type';
import { PlaceholderSearch } from '../Search';
import {
  StyledEmptyPlaceHolderSections,
  StyledSearchResultCount,
  StyledSidebarContainer,
} from './style';

const t = {
  emptyPlaceholder: 'No results found. Please try a different search term.',
  searchResult: 'Results Found',
};

const menuItemStyles: React.CSSProperties = {
  maxWidth: 'calc(100% - var(--space-24))',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
interface PlaceholderSideBarProps {
  placeholdersArray: placeholdersArrayProps[];
  onSelectCallback?: (value: string) => void;
  sideBarOverrideStyles?: React.CSSProperties;
  onSearchCallback: (value: string) => void;
  onClearCallBack: () => void;
  searchKeyword: string | undefined;
  selectedKeys: string | undefined;
  searchResultCount: number;
  viewOnly?: boolean;
}

function PlaceholderSideBar(props: PlaceholderSideBarProps) {
  const [
    {
      section,
      options: [{ subSection }],
    },
  ] = props.placeholdersArray;
  const noSearchResult = !props.searchResultCount && props.searchKeyword;

  const handleSearchCallBack = (value: string) => {
    props.onSearchCallback(value);
  };

  const EmptyPlaceHolderSections = (
    <StyledEmptyPlaceHolderSections justify="center" align="center">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t.emptyPlaceholder} />
    </StyledEmptyPlaceHolderSections>
  );

  const SearchResultCount = (
    <StyledSearchResultCount>{`${props.searchResultCount} ${t.searchResult}`}</StyledSearchResultCount>
  );

  const SearchResultCountView =
    props.searchKeyword && props.searchResultCount ? SearchResultCount : null;

  const Search = (
    <PlaceholderSearch
      onSearchCallBack={handleSearchCallBack}
      onClear={props.onClearCallBack}
      searchQuery={props.searchKeyword}
    />
  );

  const items: MenuProps['items'] = props.placeholdersArray.map((placeholderSection) => ({
    key: `${placeholderSection.section}`,
    type: 'group',
    label: capitalize(placeholderSection.section.split('_').join(' ')),
    children: placeholderSection.options.map((option) => ({
      key: `${placeholderSection.section}-${option.subSection}`,
      label: (
        <Flex gap="var(--space-2)" justify="space-between" align="center">
          <Flex.Child style={menuItemStyles}>
            <TypographyText
              style={{ marginRight: 'var(--space-2)', maxWidth: '90%' }}
              ellipsis={{
                tooltip: { title: option.subSection, align: { targetOffset: [0, -15] } },
              }}
            >
              {option.subSection}
            </TypographyText>
            ({option.placeholders.length})
          </Flex.Child>
          <Flex.Child>
            <FontAwesomeIcon icon={['far', 'chevron-right']} />
          </Flex.Child>
        </Flex>
      ),
    })),
  }));

  const PlaceholderFoldersView = (
    <>
      {SearchResultCountView}
      <Menu
        mode="inline"
        onSelect={(value) => props?.onSelectCallback?.(value.key)}
        selectedKeys={[props.selectedKeys ?? `${section}-${subSection}`]}
        style={{ overflow: 'auto', ...props.sideBarOverrideStyles }}
        items={items}
      />
    </>
  );

  return (
    <StyledSidebarContainer className={props.viewOnly ? 'view' : ''}>
      {Search}
      {noSearchResult ? EmptyPlaceHolderSections : PlaceholderFoldersView}
    </StyledSidebarContainer>
  );
}

export { PlaceholderSideBar };
