import { TaskStatus } from 'types/activities/task';
import ky from './ky';

const baseUrl = 'tasks';

const STATUS_MAPPING: Record<Exclude<TaskStatus, TaskStatus.DUE_TODAY>, string> = {
  OPEN: 'open',
  CLOSED: 'close',
  DUE: 'due',
  OVERDUE: 'overdue',
};

export async function changeTaskStatus(
  taskId: number,
  status: Exclude<TaskStatus, TaskStatus.DUE_TODAY>
) {
  return ky.put(`${baseUrl}/${taskId}/${STATUS_MAPPING[status]}`);
}
