import { GrowCheckBox, GrowCheckBoxGroup } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { OptionsProps } from '../../types';

interface FilterInputMultiSelectProps<T> {
  label?: string;
  name: string;
  value?: T[];
  options: OptionsProps[];
  onChange?: (value: T[]) => void;
  allowSelectAndUnselectAll?: boolean;
}

const style: React.CSSProperties = {
  maxHeight: 100,
  overflow: 'auto',
};

type MultiSelectCheckboxValueType = string | number;

export function MultiSelectCheckboxFilter<T extends MultiSelectCheckboxValueType>(
  props: FilterInputMultiSelectProps<T>
) {
  const { onChange, name, value, options } = props;

  const optionsList = options.map((option) => ({
    label: option.label,
    value: option.value,
    isChecked: value ? Boolean(value.find((v) => v === option.value)) : false,
  }));

  return (
    <GrowCheckBoxGroup
      value={value}
      onChange={(value) => onChange && onChange(value as T[])}
      name={name}
      style={style}
      design="border-design"
    >
      <Flex direction="column" gap="--space-4">
        {optionsList.map((option) => (
          <GrowCheckBox
            design="border-design"
            style={{ marginLeft: 0, maxWidth: '250px', overflow: 'hidden' }}
            key={option.value}
            value={option.value}
            checked={option.isChecked}
          >
            {option.label}
          </GrowCheckBox>
        ))}
      </Flex>
    </GrowCheckBoxGroup>
  );
}
