import { CellRenderProps, GrowTypography, customerNameFormatter } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import CustomerNameWithDetail from 'components/Common/CustomDetail/CustomerNameWithDetail';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { PageLink } from 'lib/router';
import { IInvoices } from 'store/customer/types';
import { StatementViews } from 'types/entities/invoice';
interface InvoiceRendererProps<T> extends CellRenderProps<T> {
  data: T;
}
export function Customer(props: InvoiceRendererProps<IInvoices>) {
  const { data, currentView } = props;
  const customerNameText = customerNameFormatter({ name: data?.customer_name });
  const isCustomDetailAvailable =
    data?.custom_customer_details && Boolean(data.custom_customer_details.length);
  const CustomerLink = (
    <GrowTypography.Text ellipsis>
      <PageLink
        style={{ color: 'var(--primary-7)' }}
        pathParams={{ id: data.customer_id }}
        appPage="CUSTOMER_DETAILS"
        searchParams={
          {
            [EActivityQueryParams.CURRENT_SECTION]: StatementViews.INVOICES,
            [EActivityQueryParams.CURRENT_VIEW]: currentView as string,
          } as unknown as URLSearchParams
        }
      >
        {customerNameText}
      </PageLink>
    </GrowTypography.Text>
  );

  const CustomerNameWithDetailsPopOver = (
    <CustomerNameWithDetail
      customerName={customerNameText}
      customDetails={data.custom_customer_details ?? []}
      parent={CustomerLink}
      placement="rightTop"
    />
  );

  return (
    <Flex gap="var(--space-8)" align="center" style={{ height: '100%' }}>
      {isCustomDetailAvailable ? CustomerNameWithDetailsPopOver : CustomerLink}
    </Flex>
  );
}
