import { Typography } from 'antd';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { PageLink } from 'lib/router';
import { StatementViews } from 'types/entities/invoice';

interface InovoiceLinkProps {
  invoiceNumber: string;
  invoiceId: number;
}
export function InvoiceLink({ invoiceNumber, invoiceId }: InovoiceLinkProps) {
  return (
    <PageLink
      appPage="INVOICE_DETAILS"
      pathParams={{ id: invoiceId }}
      searchParams={
        {
          [EActivityQueryParams.CURRENT_SECTION]: StatementViews.ACTIVITIES,
          [EActivityQueryParams.CURRENT_ACTIVITY_TAB]: StatementViews.ALL,
        } as unknown as URLSearchParams
      }
    >
      <Typography.Link>{invoiceNumber}</Typography.Link>
    </PageLink>
  );
}
