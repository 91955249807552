import { RcFile, UploadFile } from 'antd/lib/upload';
import { notify } from 'components/BaseComponents/Notifications';
import {
  AcceptedFileTypes,
  getFileExtensionFromName,
  getFileTypeFromExtension,
} from 'components/BaseComponents/UploadFiles/utils';
import {
  maxTotalFileUploadLimit,
  minimumFileSize,
} from 'components/Common/FileUploadDrawer/constants';
import { OnChange, UploadFilesFile, UploadStatus } from 'components/Common/FileUploadDrawer/type';
import { useState } from 'react';
import { FileExtension } from 'types/common/attachments';
import { cleanArrayPredicate } from 'util/predicates';
import useDisableStatus from './useDisableStatus';

export type Info = Parameters<NonNullable<OnChange>>['0'];
export const fileStatusPredicate = <T>(file: UploadFilesFile<T>) => file.status === 'done';
export const getFileObjectPredicate = <T>(file: UploadFilesFile<T>) =>
  file.originFileObj && file.originFileObj;
export const totalFileSizePredicate = <T>(totalFileSize: number, file: UploadFilesFile<T>) =>
  totalFileSize + (file.size === undefined ? 0 : file?.size);

interface useUploadFileListProps<T> {
  key: keyof T;
}

const t = {
  fileSizeMaxLimitReached: 'File(s) exceeding 20MB cannot be attached',
  emptyFileSelected: 'Empty File Found. Please try again adding file with content(s).',
  unSupportedFileSelected: 'Unsupported File Format Found.',
};

function useUploadFileList<T extends Record<string, PropertyKey>>(
  props: Readonly<useUploadFileListProps<T>>
) {
  const [fileList, setFileList] = useState<UploadFilesFile<T>[]>([]);
  const [uploading, setUploading] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const { handleDispatchEvent } = useDisableStatus();

  function getFileType(fileName: string) {
    const fileExtension = getFileExtensionFromName(fileName);
    const fileType = fileExtension ?? getFileTypeFromExtension(fileExtension);
    return fileType;
  }

  function isUnsupportedFile(fileExtension: string) {
    return !AcceptedFileTypes.includes(fileExtension as FileExtension);
  }

  function handleFileChange(info: Info) {
    const allTotalFileSize = info.fileList.reduce(totalFileSizePredicate, 0);
    const currentFileSize = info.file.size as number;
    const currentFileStatus = info.file.status as string;
    const fileType = getFileType(info?.file?.name);

    if (currentFileStatus === 'removed') {
      setTotalFileSize(totalFileSize - currentFileSize);
    }
    if (currentFileSize < minimumFileSize || isUnsupportedFile(fileType)) {
      const updatedFiles = fileList?.filter((draft) => draft.uid !== info.file.uid);
      setFileList(updatedFiles);
    } else if (allTotalFileSize < maxTotalFileUploadLimit) {
      setFileList(info.fileList);
      handleUploading(info.file.status, info.fileList);
    }
  }

  function handleBeforeUpload(file: RcFile, fileList: RcFile[]) {
    const currentSelectedFileSize = fileList.reduce(totalFileSizePredicate, 0);
    const overallSize = totalFileSize + currentSelectedFileSize;
    const fileType = getFileType(file?.name);

    if (isUnsupportedFile(fileType)) {
      notify.error(t.unSupportedFileSelected);
      return false;
    }
    if (file?.size < minimumFileSize) {
      notify.error(t.emptyFileSelected);
      return false;
    }
    if (overallSize >= maxTotalFileUploadLimit) {
      notify.error(t.fileSizeMaxLimitReached);
      return false;
    }
    setTotalFileSize(overallSize);
    return true;
  }

  function getUploadedFileIds() {
    return fileList
      .map((file) => file.response && file.response[props.key])
      .filter(cleanArrayPredicate);
  }
  function getUploadedFiles() {
    return fileList.filter(fileStatusPredicate).map(getFileObjectPredicate);
  }
  const uploadedFiles = getUploadedFiles();
  const uploadedFileIds = getUploadedFileIds();

  function handleFileRemove(file?: RcFile) {
    if (!file) return;
    const updatedFiles = fileList?.filter((draft) => draft.uid !== file.uid);
    setFileList(updatedFiles);
    const updatedFileSize = updatedFiles.reduce(
      (totalFileSize: number, file) => totalFileSize + (file.size === undefined ? 0 : file.size),
      0
    );
    setTotalFileSize(updatedFileSize);
  }

  function handleUploading(status: UploadStatus, fileList: UploadFile<any>[]) {
    if (status === 'uploading') {
      setUploading(true);
    } else {
      setUploading(false);
    }
    const fileStatuses: UploadStatus[] = fileList.map((file) => {
      return file.status;
    });
    if (fileStatuses.includes('uploading')) {
      handleDispatchEvent(true);
    } else {
      handleDispatchEvent(false);
    }
  }

  return {
    fileList,
    handleFileChange,
    uploadedFiles,
    uploadedFileIds,
    handleFileRemove,
    uploading,
    handleBeforeUpload,
  };
}

export default useUploadFileList;
