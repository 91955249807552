export enum ParentContactType {
  PRIMARY_CONTACT_PARENT = 'PRIMARY_CONTACT_PARENT',
  SECONDARY_CONTACT_PARENT = 'SECONDARY_CONTACT_PARENT',
}

export enum ContactType {
  PRIMARY_CONTACT = 'PRIMARY_CONTACT',
  SECONDARY_CONTACT = 'SECONDARY_CONTACT',
  OTHER_CONTACT = 'OTHER_CONTACT',
  PURCHASER = 'PURCHASER',
  PAYER = 'PAYER',
}

export enum PocUserType {
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  COLLECTION_OWNER = 'COLLECTION_OWNER',
  CS_MANAGER = 'CS_MANAGER',
}

export interface Contact {
  id: number;
  email: string;
  phone: string;
  type: ContactType;
  designation: string;
  first_name: string;
  last_name: string;
}
const Contacts = ['PRIMARY_CONTACT', 'SECONDARY_CONTACT'] as const;
export type ContactsType = typeof Contacts[number];
