import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useQuickActionsMenu } from 'components/BaseComponents/RTE/ContextMenu/QuickActionsMenu';
import { LegacyRef } from 'react';
import { AddButtonProps } from './AddButtonCanvas';
import { LinkButton } from './LinkButton';
interface CustomButtonNodeProps extends NodeViewProps {
  buttonStyle: AddButtonProps;
}

export function CustomButtonNodeView(props: CustomButtonNodeProps) {
  const { node, updateAttributes, getPos, editor } = props;
  const { buttonStyle } = node.attrs;

  function onAlignClickCallback(align: React.CSSProperties['textAlign']) {
    const updatedStyle = { ...buttonStyle, align };
    updateAttributes({
      buttonStyle: updatedStyle,
    });
  }
  function onDeleteCallback() {
    const from = getPos();
    const to = from + node.nodeSize;
    editor.commands.deleteRange({ from, to });
  }

  const [QuickAction, triggerCallback, elementRef] = useQuickActionsMenu({
    onAlignClickCallback,
    onDeleteCallback,
  });

  const ButtonPreview = <LinkButton buttonStyle={buttonStyle} />;

  return (
    <NodeViewWrapper>
      <div
        ref={elementRef as LegacyRef<HTMLDivElement>}
        className="custom-button-node-view"
        style={{
          display: 'flex',
          position: 'relative',
          width: '100%',
          justifyContent: buttonStyle.align,
        }}
      >
        <div
          className="button-preview"
          style={{ display: 'inline-block', padding: '8px' }}
          {...triggerCallback}
        >
          {ButtonPreview}
        </div>
        {QuickAction}
      </div>
    </NodeViewWrapper>
  );
}
