import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { stakeHolderListAttributes } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import { useUserCategoryGetQuery } from 'components/Settings/UserCategory/UserCategoryQueries';
import { ContactTypeLabel } from 'constants/relative-contacts';
import { concat, find } from 'lodash';
import { useGetContactTypes } from 'queries/Settings/contact-type';
import { useActivityFeedUserGetQuery, useGetAllUserQuery } from 'queries/users';
import { useMemo } from 'react';
import { ParticipantRelationKeys } from 'store/contacts/contacts';
import { EmailableType } from 'types/activities/email';
import { AccountConfigKey } from 'types/entities/account';
import {
  BaseRecepientsType,
  CustomerContact,
  CustomerParentContact,
} from 'types/entities/collection-strategy/contact-type';
import { ContactType, ParentContactType } from 'types/entities/contact';

export enum ContactGroupLabel {
  CUSTOMER_CONTACT = 'Customer Level',
  INVOICE_CONTACT = 'Invoice Level',
  INTERNAL_CONTACT = 'Internal',
  USERS = 'Users',
}

export type RecipientEntityTypeKeys = 'internal' | 'customer' | 'invoice';

const parentContactTypeOptions = [
  {
    value: ParentContactType.PRIMARY_CONTACT_PARENT,
    label: ContactTypeLabel[ParentContactType.PRIMARY_CONTACT_PARENT],
    participantRelationType: 'PARENT',
  },
  {
    value: ParentContactType.SECONDARY_CONTACT_PARENT,
    label: ContactTypeLabel[ParentContactType.SECONDARY_CONTACT_PARENT],
    participantRelationType: 'PARENT',
  },
];

export function getParentCustomerContactType(
  contact: CustomerContact | CustomerParentContact
): ParentContactType {
  const parentContact = find(parentContactTypeOptions, {
    value: contact.value.concat('_PARENT'),
  }) as (typeof parentContactTypeOptions)[0];

  return parentContact.value;
}

export function getCustomerContactType(contactType: ContactType | ParentContactType): ContactType {
  const parentContact = find(parentContactTypeOptions, {
    value: contactType,
  }) as (typeof parentContactTypeOptions)[0];

  return parentContact.value.replace('_PARENT', '') as ContactType;
}

const parentContactTypes = parentContactTypeOptions.map((option) => option.value);

export function isCustomerParentContactType(value: ParentContactType): value is ParentContactType {
  return !!parentContactTypes.includes(value);
}
//todo - remove after during internal contacts v2 implementation
export function useCustomerContactTypes() {
  const contactTypeOptions = [
    {
      value: ContactType.PRIMARY_CONTACT,
      label: ContactTypeLabel[ContactType.PRIMARY_CONTACT],
      participantRelationType: 'SELF',
    },
    {
      value: ContactType.SECONDARY_CONTACT,
      label: ContactTypeLabel[ContactType.SECONDARY_CONTACT],
      participantRelationType: 'SELF',
    },
    {
      value: ContactType.PAYER,
      label: ContactTypeLabel[ContactType.PAYER],
      participantRelationType: 'SELF',
    },
    {
      value: ContactType.PURCHASER,
      label: ContactTypeLabel[ContactType.PURCHASER],
      participantRelationType: 'SELF',
    },
  ];

  function isCustomerContactType(value: ContactType | ParentContactType): value is ContactType {
    const customerContactTypes = Object.values(ContactType);
    return !!customerContactTypes.includes(value as ContactType);
  }

  return [contactTypeOptions, isCustomerContactType] as const;
}

export function useInternalContactTypes() {
  const { data: userCategories, isLoading } = useUserCategoryGetQuery();
  return { userCategories, isLoading };
}
type ContactIdentifier =
  | 'CUSTOMER_BILLING_CONTACT'
  | 'PARENT_CUSTOMER'
  | 'INVOICE_BILLING_CONTACT'
  | 'CUSTOMER_INTERNAL_CONTACT'
  | 'INVOICE_INTERNAL_CONTACT';

export function generateUniqueID<T extends ContactIdentifier>(
  entity: T,
  id: T extends 'CUSTOMER_BILLING_CONTACT' | 'PARENT_CUSTOMER' | 'INVOICE_BILLING_CONTACT'
    ? number
    : string
) {
  switch (entity) {
    case 'CUSTOMER_BILLING_CONTACT':
      return `customer-billing-level-${id}`;
    case 'PARENT_CUSTOMER':
      return `parent-customer-billing-level-${id}`;
    case 'INVOICE_BILLING_CONTACT':
      return `invoice-billing-level-${id}`;
    case 'CUSTOMER_INTERNAL_CONTACT':
      return `customer-internal-level-${id}`;
    case 'INVOICE_INTERNAL_CONTACT':
      return `invoice-internal-level-${id}`;
  }
}

export function useInternalContactForEntity(entity: 'CUSTOMER' | 'INVOICE') {
  const { userCategories, isLoading } = useInternalContactTypes();

  const internalContact = userCategories?.map((contact, index) => ({
    type: EmailableType.USER,
    email: generateUniqueID(
      entity === 'CUSTOMER' ? 'CUSTOMER_INTERNAL_CONTACT' : 'INVOICE_INTERNAL_CONTACT',
      contact.name
    ),
    value: generateUniqueID(
      entity === 'CUSTOMER' ? 'CUSTOMER_INTERNAL_CONTACT' : 'INVOICE_INTERNAL_CONTACT',
      contact.name
    ),
    id: contact.id,
    label: contact.title,
    title: contact.title,
    user_type: contact.name,
    association_level: entity,
  }));

  return { internalContact, isLoading };
}

export function useGetRecentUsers() {
  const { data: activityFeedUser } = useActivityFeedUserGetQuery();
  const { data: allUsers } = useGetAllUserQuery();
  const recentUser =
    activityFeedUser && !!activityFeedUser?.length ? activityFeedUser : allUsers?.slice(0, 5);

  return (
    recentUser?.map((users) => ({
      label: `${users.first_name} ${users.last_name} (${users.email})`,
      id: users.id,
      email: users.email,
      value: users.id,
      type: BaseRecepientsType.USER,
      participant_relation: undefined,
    })) ?? []
  );
}

export function useRelativeContactTypes() {
  const { data: contactTypes } = useGetContactTypes();
  const {
    internalContact: customerInternalContact,
  }: { internalContact?: stakeHolderListAttributes[] | undefined } =
    useInternalContactForEntity('CUSTOMER');
  const {
    internalContact: invoiceInternalContact,
  }: { internalContact: stakeHolderListAttributes[] | undefined } =
    useInternalContactForEntity('INVOICE');

  const advancedContactEnabled = useConfig(AccountConfigKey.ADVANCED_CONTACTS);
  const isParentChildEnabled = useConfig(AccountConfigKey.PARENT_CHILD_ENABLED);
  const relativeContactOptions = useMemo(() => {
    const relativeContactTypes: Partial<
      Record<RecipientEntityTypeKeys, stakeHolderListAttributes[]>
    > = {};

    if (contactTypes) {
      relativeContactTypes['customer'] = contactTypes?.map((contact) => ({
        type: EmailableType.CONTACT,
        id: contact.id,
        label: contact.display_text,
        title: contact.display_text,
        email: generateUniqueID('CUSTOMER_BILLING_CONTACT', contact.id),
        contact_type_identifier: 'CUSTOMER',
        participant_relation: ParticipantRelationKeys.SELF,
        association_level: 'CUSTOMER',
      }));

      if (customerInternalContact?.length) {
        relativeContactTypes['customer'] = concat(
          relativeContactTypes['customer'],
          customerInternalContact
        );
      }

      if (isParentChildEnabled) {
        const parentContactTypes: (typeof relativeContactTypes)['customer'] = contactTypes?.map(
          (contact) => ({
            type: EmailableType.CONTACT,
            id: contact.id,
            label: `${contact.display_text} (Parent)`,
            email: generateUniqueID('PARENT_CUSTOMER', contact.id),
            contact_type_identifier: 'CUSTOMER',
            title: contact.display_text,
            participant_relation: ParticipantRelationKeys.PARENT,
          })
        );

        relativeContactTypes['customer'] = [
          ...relativeContactTypes['customer'],
          ...parentContactTypes,
        ];
      }

      if (advancedContactEnabled) {
        relativeContactTypes['invoice'] = contactTypes?.map((contact) => ({
          type: EmailableType.CONTACT,
          id: contact.id,
          email: generateUniqueID('INVOICE_BILLING_CONTACT', contact.id),
          label: contact.display_text,
          contact_type_identifier: 'INVOICE',
          title: contact.display_text,
          participant_relation: ParticipantRelationKeys.SELF,
          association_level: 'INVOICE',
        }));

        if (invoiceInternalContact?.length) {
          relativeContactTypes['invoice'] = [
            ...(relativeContactTypes['invoice'] ?? []),
            ...invoiceInternalContact,
          ];
        }
      }
    }

    return relativeContactTypes;
  }, [
    advancedContactEnabled,
    contactTypes,
    customerInternalContact,
    invoiceInternalContact,
    isParentChildEnabled,
  ]);

  return relativeContactOptions;
}
