export const t = {
  invoicePlaceholder: 'Select an Invoice',
  customerPlacehlder: 'Select a Customer',
  emailPreviewTitle: 'Email Preview',
  previewError: {
    error: 'Preview Unsuccessful',
    description: 'Please try again:(',
  },
  groupedInvoicesSelectPlaceholder: 'Select Customer to Preview',
  customerSelectPlaceholder: 'Select Customer to Preview',
};
