import { MinMaxRange } from 'components/Common/FilterComponents/Filters/MinMax';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomFields } from 'services/custom-field';
import { getInvoiceSegments } from 'services/invoice-segment';
import { setCustomFields } from 'store/custom-fields';
import { invoiceSegmentManagementActions } from 'store/invoice-segments';
import { CustomFieldPage } from 'types/entities/custom-field';
import { Range, Validation } from 'types/entities/invoice-segments';
import { NUMBER_ONLY_REGEX, NUMBER_WITH_DECIMAL_REGEX } from 'util/regex';

export function useLoadInvoiceSegments() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchAndSaveInvoiceSegments() {
      try {
        const invoiceSegments = await getInvoiceSegments();
        dispatch(invoiceSegmentManagementActions.setInvoiceSegments(invoiceSegments));
      } catch (e) {
        console.log(e);
      }
    }

    fetchAndSaveInvoiceSegments();
  }, [dispatch]);
}

export function useLoadInvoiceSegmentCustomFieldOptions() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchAndSaveCustomFields() {
      const page = CustomFieldPage.INVOICE_SEGMENT;

      const customerCustomFields = await getCustomFields({
        entity: 'CUSTOMER',
        page,
      });
      const invoiceCustomFields = await getCustomFields({
        entity: 'INVOICE',
        page,
      });

      const customFields = [...customerCustomFields, ...invoiceCustomFields];

      dispatch(setCustomFields({ page, customFields }));
    }

    fetchAndSaveCustomFields();
  }, [dispatch]);
}

type RangeValidationProps = {
  allowDecimal?: boolean;
};
export function useRangeValuesAndValidation(props: RangeValidationProps) {
  const defaultFromValue: Range['from']['value'] = 1;
  const defaultToValue: Range['to']['value'] = 2;

  const regex = props.allowDecimal ? NUMBER_WITH_DECIMAL_REGEX : NUMBER_ONLY_REGEX;
  const validationText = props.allowDecimal
    ? 'Value should be a number'
    : 'Decimal values are not allowed';
  function handleFromValidation(
    value: number | null,
    rangeValues: Range | MinMaxRange,
    customFieldText?: string
  ): Validation {
    if (!value && value !== 0) {
      return {
        validateStatus: 'error',
        errorMsg: 'Value should not be empty',
      };
    } else if (!regex.test(value as unknown as string)) {
      return {
        validateStatus: 'error',
        errorMsg: validationText,
      };
    } else if (!rangeValues.to.value && rangeValues.to.value !== 0) {
      return {
        validateStatus: 'error',
        errorMsg: `${customFieldText ? customFieldText : 'To'} Value should not be empty`,
      };
    } else if (value > rangeValues.to.value || value === rangeValues.to.value) {
      return {
        validateStatus: 'error',
        errorMsg: `Should be lesser than ${customFieldText ? customFieldText : 'To'} value`,
      };
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
  }
  function handleToValidation(
    value: number | null,
    rangeValues: Range | MinMaxRange,
    customFieldText?: string
  ): Validation {
    if (!value && value !== 0) {
      return {
        validateStatus: 'error',
        errorMsg: 'Should not be empty',
      };
    } else if (!regex.test(value as unknown as string)) {
      return {
        validateStatus: 'error',
        errorMsg: validationText,
      };
    } else if (!rangeValues.from.value && rangeValues.from.value !== 0) {
      return {
        validateStatus: 'error',
        errorMsg: `${customFieldText ? customFieldText : 'To'} Value should not be empty`,
      };
    } else if (value < rangeValues.from.value || value === rangeValues.from.value) {
      return {
        validateStatus: 'error',
        errorMsg: `Should be greater than ${customFieldText ? customFieldText : 'From'} value`,
      };
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
  }
  const formValidation = useCallback(handleFromValidation, [regex, validationText]);
  const toValidation = useCallback(handleToValidation, [regex, validationText]);
  return { defaultFromValue, defaultToValue, formValidation, toValidation };
}
