import { FollowupRuleAction } from './rule-action';
import { FollowupRuleEvent, StatusChangeRuleEvent } from './rule-event';
import { FollowupRuleScope } from './rule-scope';

export enum FollowupRuleType {
  DUNNING_RULE = 'DUNNING_RULE',
  PAUSING_RULE = 'PAUSING_RULE',
  STOPPING_RULE = 'STOPPING_RULE',
}

interface DunningRuleData {
  title: string;
  sequence: number;
  event: FollowupRuleEvent;
  action: FollowupRuleAction;
  scopes: FollowupRuleScope[];
}

interface PausingRuleData {
  event: FollowupRuleEvent;
  resume_event: FollowupRuleEvent;
  scopes: FollowupRuleScope[];
}

interface StoppingRuleData {
  event: StatusChangeRuleEvent;
}

interface BaseFollowupRule {
  id: number | string;
}

export interface DunningRule extends BaseFollowupRule {
  type: FollowupRuleType.DUNNING_RULE;
  data: DunningRuleData;
}
export interface PausingRule extends BaseFollowupRule {
  type: FollowupRuleType.PAUSING_RULE;
  data: PausingRuleData;
}
export interface StoppingRule extends BaseFollowupRule {
  type: FollowupRuleType.STOPPING_RULE;
  data: StoppingRuleData;
}
