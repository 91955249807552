import { Descriptions, Divider } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';

import { GrowText } from 'components/BaseComponents/Typography';
import { CommentsBlock } from 'components/CollectionActivities/Comment/CommentsBlock';
import {
  AmountBlock,
  InvoicesBlock,
  LabelBlock,
  LineItemBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { SubscriptionType } from 'components/CollectionActivities/Common/SubscriptionType';
import useGetActivityLink from 'components/CollectionActivities/hooks/useGetActivityLink';
import { ActivityContent, ActivityTitle } from 'components/CollectionActivities/style';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';
import { OPEN_TYPE, useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';

import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';
import { WriteOff } from 'types/activities/write-off';

interface WriteOffDetailsProps extends ActivityDetailsBase {
  data?: WriteOff;
  isLoading: boolean;
}

function WriteOffDetailsBody(props: WriteOffDetailsProps) {
  const { data, isLoading, subscriptionType, customer, activitiesMode } = props;

  const { cardLabel, Icon } = getActivityUnitItems(ActivityType.WRITE_OFF);
  const { entitySearchParams } = useEntitySearchParams();
  const ActivityCopyLink = useGetActivityLink(activitiesMode);

  const Title = (
    <ActivityTitle align="center" gap="var(--space-8)">
      <ActivityIconButton
        icon={Icon}
        size="small"
        css={{ '--activityBg': 'var(--gray-2)' }}
        viewMode
        loading={isLoading}
        hideBackground
      />
      <GrowText>{cardLabel}</GrowText>
      <ActivityCopyLink data={data} customer={customer} type={ActivityType.WRITE_OFF} />
    </ActivityTitle>
  );

  const Header = <Flex justify="space-between">{Title}</Flex>;

  const InvoiceArea = isLoading && !data ? null : <InvoicesBlock invoices={data?.invoices} />;

  const Amount = data && !isLoading ? <AmountBlock amount={data.amount} /> : null;

  const LineItems = data && !isLoading ? <LineItemBlock item={data.invoice_line_items} /> : null;

  const WriteOffAmount =
    data && !isLoading ? <AmountBlock amount={data.amount} size={'var(--fs-20)'} /> : null;

  const WriteOffTitle =
    data && !isLoading ? (
      <GrowText size={'var(--fs-20)'} strong>
        Write off Amount: {WriteOffAmount}
      </GrowText>
    ) : null;

  const Body2 = (
    <>
      {data?.invoice_line_items.length ? (
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item label={<LabelBlock label="Invoice line items" />}>
            {LineItems}
          </Descriptions.Item>
        </ActivityContent>
      ) : null}
    </>
  );

  const Body = (
    <>
      <ActivityContent layout="vertical" colon={false}>
        <Descriptions.Item>{WriteOffTitle}</Descriptions.Item>
      </ActivityContent>
      <ActivityContent layout="vertical" colon={false}>
        <Descriptions.Item label={<LabelBlock label="Invoices" />}>{InvoiceArea}</Descriptions.Item>
      </ActivityContent>
      {Body2}
      <Divider style={{ margin: '16px 0', borderColor: 'var(--gray-5)' }} />
    </>
  );

  const Sub = !isLoading && data ? <SubscriptionType subscriptionType={subscriptionType} /> : null;

  const Content = (
    <>
      <Flex gap="var(--space-12)" direction="column">
        <Flex direction="column" gap="var(--space-8)">
          {Header}
          {Sub}
        </Flex>
        <Flex gap="var(--space-16)" direction="column">
          {Body}
          {Body2}
        </Flex>
      </Flex>
      <Flex>
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item label={<LabelBlock label="Writeoff amount" />}>
            {Amount}
          </Descriptions.Item>
        </ActivityContent>
      </Flex>
    </>
  );

  const Footer =
    data && !isLoading ? (
      <CommentsBlock
        customerId={props.customer?.id}
        activityId={data.id}
        activityType={ActivityType.WRITE_OFF}
        comments={data.comments}
        isOpen={entitySearchParams[EActivityQueryParams.OPEN] === OPEN_TYPE.COMMENTS}
      />
    ) : null;
  return (
    <>
      {Content}
      <Divider style={{ margin: '24px 0', borderColor: 'var(--gray-5)' }} />
      {Footer}
    </>
  );
}

export { WriteOffDetailsBody };
