import { Flex } from 'components/BaseComponents/Layout/Flex';
import { DashboardTab } from '..';
import ModifyTargetButton from '../CollectionPerformance/ModifyTargetButton';

interface ExtraActionsProps {
  currentTab: DashboardTab | undefined;
}
function ExtraActions(props: ExtraActionsProps) {
  const { currentTab } = props;
  return (
    <Flex align="center" gap="var(--space-8)">
      <ModifyTargetButton isHide={currentTab !== DashboardTab.COLLECTORS_PERFORMANCE} />
    </Flex>
  );
}

export default ExtraActions;
