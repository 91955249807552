import { Button, Menu, Modal } from 'antd';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
  padding: var(--space-16);
  margin: 0;

  .ant-dropdown-menu-item-group-list {
    margin: 0;
  }
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected {
    background: none;
    font-weight: var(--fs-semibold);
    color: var(--purple-7);
  }

  .ant-dropdown-menu-item-group-title {
    color: var(--gray-7);
    padding-left: var(--space-4);
    font-size: var(--fs-12);
  }

  li {
    color: var(--purple-10);
    padding-left: var(--space-4);
  }
`;

export const StyledModal = styled(Modal)`
  margin: 0;
  top: 65px;
  left: 74px;
  .ant-modal-header {
    border-bottom: 0;
  }
  .ant-modal-body {
    padding-top: 0;
  }
  .ant-modal-footer {
    padding: var(--space-16) 0;
    margin: 0 var(--space-16);
  }
`;

export const StyledIconButton = styled(Button)`
  &.ant-btn {
    border: 0;
    background: none;
    box-shadow: none;
    &[ant-click-animating-without-extra-node='true']::after,
    .ant-click-animating-node {
      display: none;
      animation: none;
      background: none;
    }
  }
`;

export const OverlayMenuStyles: React.CSSProperties = {
  maxWidth: '540px',
  minWidth: 'none',
};
