import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { remove } from 'lodash';
import { User } from 'types/entities/user';
import { RootState } from '../../reducers/rootReducer';
import { DeleteUserRole, ERoles, Roles, RolesState } from './types';

const initialState: RolesState = {
  defaultRoles: [] as Roles[],
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    getDefaultRolesHandler(state, action: PayloadAction<Roles[]>) {
      state.defaultRoles = action.payload;
    },
    updateRoleHandler(state, action: PayloadAction<Roles>) {
      state.defaultRoles.forEach((role) => {
        if (role.id === action.payload.id) {
          role.display_name = action.payload.name;
          role.description = action.payload.description;
        }
      });
    },
    removeUserFromRoleHandler(state, action: PayloadAction<DeleteUserRole>) {
      const { roleId, userId } = action.payload;

      state.defaultRoles = state.defaultRoles?.map((role) => {
        if (role.id === roleId) {
          const deletedUser = role?.users?.find((user) => user.id === userId);
          state.defaultRoles
            .find((role) => role.name === ERoles.COLLECTION_SPECIALIST)
            ?.users.push(deletedUser as User);

          remove(role.users, deletedUser);
        }
        return role;
      });
    },
  },
});

export const { getDefaultRolesHandler, updateRoleHandler, removeUserFromRoleHandler } =
  rolesSlice.actions;

export const rolesSelector = (state: RootState) => state.roles.defaultRoles;

export default rolesSlice.reducer;
