import {
  isContainsNewlineRegex,
  styleRemoveRegex,
  subjectRegEx,
  tableRegEx,
  tagsPattern,
} from 'lib/RichText/TipTapEditor/utils/adapters';

export function sanitizeSubject(content?: string) {
  const contentHolder = document.createElement('div');
  if (content) {
    contentHolder.innerHTML = content;
    return contentHolder.innerText?.replaceAll(subjectRegEx, '') ?? '';
  }
  return content?.replaceAll(subjectRegEx, '') ?? '';
}
export function cleanedString(content?: string) {
  return content?.replaceAll(tagsPattern, '') ?? '';
}
export function sanitizeBodyAndSubject(content: string) {
  if (tableRegEx.test(content)) {
    const value = content.replace(styleRemoveRegex, '');
    return sanitizeSubject(value);
  }
  return sanitizeSubject(content);
}

export function isContainsNewLine(params: string) {
  return isContainsNewlineRegex.test(params);
}

export function getBodyContent(htmlString: string) {
  /** we're extracting the recent html element from the html string which 
    ignores these tags style|meta|br|hr if any and we return the first match and the rest **/
  const regex = /<div>((?:(?!<\/?(?:style|meta|br|hr)\b)[\s\S])*?)<\/div>/;
  const match = htmlString.match(regex);

  if (match) {
    return { first: match[1], rest: htmlString.replace(regex, '') };
  }
  return;
}
