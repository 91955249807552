import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowText } from 'components/BaseComponents/Typography';
const Icon = <FontAwesomeIcon icon={['far', 'arrow-right']} fontSize={14} />;

interface ClosedDescProps {
  entity: string;
}
function ClosedDesc(props: ClosedDescProps) {
  return (
    <GrowText>
      {`Access this ${props.entity} from ‘Activity’ filter`} &nbsp;{Icon}&nbsp;‘Closed’
    </GrowText>
  );
}

export { ClosedDesc };
