import { Button, Row } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled.div`
  border: none;
  .child-container {
    width: 50px;
  }
  .icon-comment {
    color: var(--blue-5);
  }

  .icon-email {
    color: var(--blue-7);
  }

  .subject {
    font-size: var(--fs-12);
  }

  .to-user {
    color: var(--blue-4);
  }

  .extra-information {
    color: var(--blue-7);
  }

  .icon-item {
    color: var(--blue-5);
  }

  .footer {
    background: var(--gray-2);
  }
  .parent {
    margin-bottom: 1rem;
  }
`;

export const ExpandedViewContainer = styled.div`
  .email-action-banner {
    background: var(--gray-2);

    .action-icon {
      color: var(--purple-5);
    }

    .action-content {
      color: var(--purple-9);
    }
  }

  blockquote {
    margin: 0;
    padding: 0.5rem 1rem;
    border-left: 1px dotted;
  }

  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }

  .header {
    .secondary-actions {
      margin-top: -28px !important;
      float: right;
    }
  }

  .structure-footer {
    margin: 0 !important;
    bottom: -8px !important;
    display: none;
  }
`;

export const EmailContainer = styled.div`
  .title {
    .icon {
      color: var(--blue-5);
    }

    height: 80px;
  }
  .invoice-search-item {
    .error-message {
      display: none;
    }
  }

  .email-body {
    height: calc(100% - 160px);
    overflow: auto;
    overflow-x: hidden;
  }

  .footer {
    height: 80px;
  }
`;

export const SelectReciepientType = styled(Row)`
  .ant-btn-link {
    padding: 2px 6px;
  }
`;

export const EmailActionButton = styled(Button)`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

export const EmailInfoCaret = styled.span`
  border: 1px solid var(--gray-8);
  border-radius: 0.25rem;
  padding: 0 0.25rem;
  margin: 0 0.25rem;

  svg {
    vertical-align: 0.1rem;
  }
`;
export const SDropDownGroupOption = styled.div`
  padding: var(--space-8);

  .group-label {
    color: var(--gray-10);
    font-size: var(--fs-12);
    cursor: default;
    margin-bottom: var(--space-8);
  }

  .option-item {
    padding: 5px 0;
    padding-left: 0.6rem;

    &:hover {
      background: var(--gray-3);
    }

    &.selected {
      color: var(--gray-10);
      font-weight: 600;
      background-color: var(--blue-1);
    }
  }
`;

export const FieldValue = styled.span`
  color: var(--gray-6);
`;
