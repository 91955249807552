import { DunningRule, PausingRule, StoppingRule } from './rule';
import { EmailDeliverySettings } from './setttings';

export enum InvoiceStrategyStatus {
  PAUSED = 'PAUSED',
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
}

export interface EntityCollectionStrategy {
  id: number;
  title: string;
  enabled: boolean;
  invoice_strategy_status?: InvoiceStrategyStatus;
}

export enum FollowupTrackType {
  AGE_TRACK = 'AGE_TRACK',
  PERIODIC = 'PERIODIC',
}

export enum FollowupType {
  INVOICE = 'INVOICE',
  STATEMENT = 'STATEMENT',
}

export interface FollowupTrack {
  track_type: FollowupTrackType;
  dunning_rules: DunningRule[];
  pausing_rules: PausingRule[];
  stopping_rules: StoppingRule[];
}

export interface RecurringAction {
  enabled: boolean;
  days: number;
}
export interface AutoResume {
  enabled: boolean;
  offset: number;
}

export interface InvoiceFollowup {
  enabled: boolean;
  serial_followup: boolean;
  recurring_action: RecurringAction;
  auto_resume: AutoResume;
  tracks: FollowupTrack[];
}

export interface StatementFollowup {
  enabled: boolean;
  pause_and_resume: boolean;
  tracks: FollowupTrack[];
}

export interface FollowupSettings {
  email_delivery: EmailDeliverySettings;
}

export interface BaseCollectionStrategy {
  id: number;
  title: string;
  enabled: boolean;
  invoice_followup: boolean;
  invoice_followup_rules_count: number;
  statement_followup_rules_count: number;
  statement_followup: boolean;
  customer_count: number;
  invoice_count: number;
}

export interface CollectionStrategy {
  id?: number;
  title: string;
  enabled: boolean;
  invoice_followup: InvoiceFollowup;
  statement_followup: StatementFollowup;
  settings: FollowupSettings;
}

export interface CollectionStrategyStats {
  id: number;
  collection_strategy_title: string;
  followup_action_stats: Array<StrategyActionStat>;
}

export interface StrategyActionStat {
  id: number;
  title: string;
  email_sent: number;
  email_viewed: number;
  email_responded: number;
}
