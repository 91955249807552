import { CellRenderProps } from '@sinecycle/growcomponents';
import { Amount, AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { IInvoices } from 'store/customer/types';
import { AccountConfigKey } from 'types/entities/account';

function AmountCell(props: CellRenderProps<IInvoices>) {
  const isMulticurrencyEnabled = useConfig(AccountConfigKey.MULTI_CURRENCY_ENABLED);
  const { data } = props;
  if (!data) return '-';

  return (
    <AmountWithCurrencySplit
      type="multi"
      showTooltip={false}
      amountWithCurrencySplit={data?.balance_due_amount_data}
      context="Draft Invoices"
      hideSplitTotal
      hideMCSplitComp={isMulticurrencyEnabled}
      currencyArea="DETAILS"
    />
  );
}

export default AmountCell;

export function AmountCellCustomerCurrency(props: CellRenderProps<IInvoices>) {
  const { data } = props;
  if (!data) return '-';

  return (
    <AmountWithCurrencySplit
      type="multi"
      showTooltip={false}
      amountWithCurrencySplit={data?.inv_balance_due_amount_customer_currency}
      context="Open Invoices"
      hideSplitTotal
      currencyArea="DETAILS"
    />
  );
}

export function AmountTotalCell(props: CellRenderProps<IInvoices>) {
  const { data } = props;
  if (!data) return '-';
  return (
    <Amount
      amount={data.amount_data.value}
      currency={data.amount_data.currency}
      showTooltip={false}
      context="Open Invoices"
    />
  );
}
export function AmountTotalCustomerCurrency(props: CellRenderProps<IInvoices>) {
  const { data } = props;
  if (!data) return '-';
  return (
    <Amount
      amount={data?.inv_amount_customer_currency.value}
      currency={data?.inv_amount_customer_currency.currency}
      context="Open Invoices"
    />
  );
}
