import { amountFormats, GrowTypography } from '@sinecycle/growcomponents';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';

interface DueStatusProps {
  days: number;
}

type Due_Status_Types = 'near' | 'today' | 'over_due';
type DueStatusConfig = { label: string; color: string };

export type DueStatusRecord = Record<Due_Status_Types, DueStatusConfig>;

const due_status_record: DueStatusRecord = {
  today: {
    label: 'due today',
    color: 'var(--red-4)',
  },
  near: {
    label: 'Due in',
    color: 'var(--orange-6)',
  },
  over_due: {
    label: 'over due',
    color: 'var(--red-7)',
  },
};
function DueStatus(props: DueStatusProps) {
  const { days } = props;
  const locale = useSelector(localeSelector);
  function getDueConfig(): DueStatusConfig | undefined {
    if (days === 0) {
      return {
        ...due_status_record.over_due,
        label: `${due_status_record.today.label}`,
      };
    } else if (days > 0) {
      const dayText = days === 1 ? 'day' : 'days';
      return {
        ...due_status_record.over_due,
        label: `${amountFormats.number(Math.abs(days), { locale })} ${dayText} ${
          due_status_record.over_due.label
        }`,
      };
    } else {
      const dayText = days === -1 ? 'day' : 'days';
      return {
        ...due_status_record.near,
        label: `${due_status_record.near.label} ${
          days ? amountFormats.number(Math.abs(days), { locale }) : '-'
        } ${dayText} `,
      };
    }
  }

  const config = useMemo(getDueConfig, [days, locale]);
  return (
    <GrowTypography.Text style={{ color: config?.color }}>{`${config?.label}`}</GrowTypography.Text>
  );
}

export { DueStatus };
