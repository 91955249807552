import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { ErrorBoundaryWrapper } from 'components/Common/ErrorBoundary';
import { StyledCard } from 'pages/Dashboard/AdminDashboard/SnapShot/Snapshot';
import { useFollowUpStatuesQuery } from 'queries/admin-dashboard';
import ChartSpinner from '../Common/ChartSpinner';
import { DashboardChartProps } from '../Common/types';
import FollowUpStatusChart from './FollowUpStatusChart';

const t = {
  followup_status: 'Receivables by Follow up status',
};

export default function FollowUpStatusCard(props: DashboardChartProps) {
  const { snapshotFilterParams } = props;
  const {
    data: followupStatuses,
    isLoading: loading,
    isSuccess,
  } = useFollowUpStatuesQuery(snapshotFilterParams);

  const Title = (
    <GrowText size={'var(--fs-20)'} color="var(--gray-9)" strong>
      {t.followup_status}
    </GrowText>
  );

  if (loading && !isSuccess) {
    return <ChartSpinner title={Title} height={'250px'} />;
  }

  return (
    <StyledCard style={{ height: '100%' }}>
      <Flex direction="column" align="flex-start" gap="var(--space-8)">
        {Title}
        <ErrorBoundaryWrapper>
          <FollowUpStatusChart followupStatuses={followupStatuses} />
        </ErrorBoundaryWrapper>
      </Flex>
    </StyledCard>
  );
}
