import { Input } from 'antd';
import { useState } from 'react';
interface TextEditorCellProps {
  onChange?: (value?: string) => void;
  value?: string;
  prefixSymbol?: string;
}
export default function TextEditorCell(props: TextEditorCellProps) {
  const [inputValue, setInputValue] = useState(props.value);
  const TextInput = (
    <Input
      onPressEnter={(e) => {
        if (e.key === 'Enter') props?.onChange?.(inputValue);
      }}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      defaultValue={props.value === '-' ? undefined : props.value}
      autoFocus
      onClear={() => {
        props?.onChange?.();
      }}
      allowClear
      prefix={props.prefixSymbol}
      style={{ width: '100%', borderStyle: 'none', boxShadow: 'none' }}
    />
  );
  return <>{TextInput}</>;
}
