import { HeaderRendererProps } from '@sinecycle/growcomponents';
import { MultiSelectCheckboxWrapperWithSearch } from 'components/Common/FilterComponents/FilterWrapper/MultiSelectCheckboxWrapperWithSearch';
import useCustomerList from 'hooks/useCustomerList';
import { IInvoices } from 'store/customer/types';
import { CustomerDropDownDTO } from 'types/entities/customer';

interface CustomerSearchProps extends Partial<HeaderRendererProps<IInvoices>> {
  value?: number[];
  onSubmit: (value: number[]) => void;
}
function CustomerSearch(props: CustomerSearchProps) {
  const { customerSearch, customersList } = useCustomerList({
    selectedIds: props.value,
  });
  const customerFilterConfig = {
    name: 'Customer Name',
    label: 'Customer Name',
    options: customersList,
  };

  return (
    <MultiSelectCheckboxWrapperWithSearch<CustomerDropDownDTO, number[]>
      labelAttribute={'name'}
      valueAttribute="id"
      filterConfig={{ ...customerFilterConfig, contentOnly: true }}
      value={props.value}
      showClear
      onClear={() => {
        props.quickFilter?.onClose?.();
      }}
      onSearch={customerSearch.mutateAsync}
      onSubmit={(value) => {
        props.quickFilter?.onClose?.();
        props.onSubmit?.(value);
      }}
      key="Customer-dropdown"
      onCancel={() => {
        props.quickFilter?.onClose?.();
      }}
    />
  );
}

export { CustomerSearch };
