import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRandomId } from '../../util/random-generators';
import {
  CollectionFollowupPauseResumeRuleResponseState,
  CollectionFollowupStopRuleResponseState,
  DeleteCollectionFollowupRuleResponseState,
  FollowupRuleTriggerCondition,
  GetCollectionFollowupResponseState,
  PauseEventTypeEnum,
  SaveCollectionFollowupRuleResponseState,
  StopEventTypeEnum,
  WeekDay,
} from './types';

export const initialState: GetCollectionFollowupResponseState = {
  status: 0,
  message: '',
  collectionFollowup: {
    title: 'Untitled Collection Strategy',
    id: -1,
    deleted: false,
    customer_count: 0,
    invoice_count: 0,
    enabled: false,
    default: false,
    pausing_rules: [
      {
        id: getRandomId(),
        deleted: false,
        event: {
          id: -1,
          deleted: false,
          eventable_type: FollowupRuleTriggerCondition.GENERIC,
          name: PauseEventTypeEnum.CUSTOMER_RESPONDED,
        },
      },
    ],
    stopping_rules: [
      {
        id: getRandomId(),
        deleted: false,
        event: {
          id: -1,
          deleted: false,
          eventable_type: FollowupRuleTriggerCondition.GENERIC,
          name: StopEventTypeEnum.INVOICE_CLOSED,
        },
      },
    ],
    settings: {
      email_delivery_time: '09:00:00',
      email_delivery_start_dow: WeekDay.MONDAY,
      email_delivery_end_dow: WeekDay.FRIDAY,
      run_on_old_invoices: true,
      run_when_invoice_file_missing: true,
      run_when_email_missing: true,
    },
  },
};

const collectionFollowupSlice = createSlice({
  name: 'collectionFollowup',
  initialState,
  reducers: {
    getCollectionFollowupHandler(state, action: PayloadAction<GetCollectionFollowupResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (action.payload.status === 200 && action.payload.collectionFollowup) {
        state.collectionFollowup = action.payload.collectionFollowup;
      }
    },
    deleteCollectionFollowupRuleHandler(
      state,
      action: PayloadAction<DeleteCollectionFollowupRuleResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (action.payload.status === 200 && state.collectionFollowup)
        state.collectionFollowup.dunning_rules = state.collectionFollowup?.dunning_rules?.map(
          (collectionFollowupRule) => {
            if (collectionFollowupRule.id === action.payload.ruleId)
              return Object.assign({}, collectionFollowupRule, {
                deleted: true,
              });
            else return collectionFollowupRule;
          }
        );
    },
    saveCollectionFollowupRuleHandler(
      state,
      action: PayloadAction<SaveCollectionFollowupRuleResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (
        action.payload.status === 200 &&
        state.collectionFollowup &&
        action.payload.followupRule
      ) {
        state.collectionFollowup.dunning_rules = state.collectionFollowup.dunning_rules || [];
        const actionId = action.payload.followupRule.id;
        const actionIndex = state.collectionFollowup.dunning_rules.findIndex(
          (action) => action.id === actionId
        );
        if (actionIndex >= 0)
          state.collectionFollowup.dunning_rules[actionIndex] = action.payload.followupRule;
        else {
          action.payload.followupRule.id = -1 * new Date().getUTCMilliseconds();
          state.collectionFollowup.dunning_rules = state.collectionFollowup.dunning_rules.concat(
            action.payload.followupRule
          );
        }
      }
    },
    saveCollectionFollowupPauseResumeRuleHandler(
      state,
      action: PayloadAction<CollectionFollowupPauseResumeRuleResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (
        action.payload.status === 200 &&
        state.collectionFollowup &&
        action.payload.pauseResumeRule
      ) {
        state.collectionFollowup.pausing_rules = state.collectionFollowup.pausing_rules || [];
        const actionIndex = state.collectionFollowup.pausing_rules.findIndex(
          (pauseResumeRule) => pauseResumeRule.id === action.payload.pauseResumeRule?.id
        );
        if (actionIndex >= 0) {
          state.collectionFollowup.pausing_rules[actionIndex] = action.payload.pauseResumeRule;
        } else {
          action.payload.pauseResumeRule.id = getRandomId();
          state.collectionFollowup.pausing_rules = state.collectionFollowup.pausing_rules.concat(
            action.payload.pauseResumeRule
          );
        }
      }
    },
    deleteCollectionFollowupPauseResumeRuleHandler(
      state,
      action: PayloadAction<CollectionFollowupPauseResumeRuleResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (
        action.payload.status === 200 &&
        state.collectionFollowup &&
        action.payload.pauseResumeRule
      ) {
        state.collectionFollowup.pausing_rules = state.collectionFollowup.pausing_rules.map(
          (pauseResumeRule) => {
            pauseResumeRule.deleted =
              pauseResumeRule.id === action.payload.pauseResumeRule?.id
                ? true
                : pauseResumeRule.deleted;
            return pauseResumeRule;
          }
        );
      }
    },
    saveCollectionFollowupStopRuleHandler(
      state,
      action: PayloadAction<CollectionFollowupStopRuleResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (action.payload.status === 200 && state.collectionFollowup && action.payload.stopRules)
        state.collectionFollowup.stopping_rules = action.payload.stopRules;
    },
    clearStatusAndMessageHandler(state) {
      state.status = 0;
      state.message = '';
    },
  },
});

export const {
  getCollectionFollowupHandler,
  deleteCollectionFollowupRuleHandler,
  saveCollectionFollowupRuleHandler,
  saveCollectionFollowupPauseResumeRuleHandler,
  deleteCollectionFollowupPauseResumeRuleHandler,
  saveCollectionFollowupStopRuleHandler,
  clearStatusAndMessageHandler,
} = collectionFollowupSlice.actions;

export default collectionFollowupSlice.reducer;
