export function isJson(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}
/**
 *
 * @param obj : object whose unset properties has to be removed
 * @returns : cloned object which is pruned of nullish properties
 */
export function pruneObject<T extends object>(obj: T) {
  const clonedObj = JSON.parse(JSON.stringify(obj));

  Object.keys(clonedObj).forEach(
    (key) => (clonedObj[key] === null || clonedObj[key] === undefined) && delete clonedObj[key]
  );

  return clonedObj as Partial<T>;
}

/**
 *
 * @param options : given array of JSON object
 * @param labelParam : key in JSON object which forms the label attribute of end options list
 * @param valueParam : key in JSON object which forms the value attribute of end options list
 * @returns : an array that constitues objects with lable and value
 *
 */

export type DropDownSelectOptions<T> = {
  label: T[keyof T];
  value: T[keyof T];
  valueobject?: T;
};

export const buildDropdownOption = <T>(
  options: T[],
  labelParam: string,
  valueParam: string
): DropDownSelectOptions<T>[] => {
  const computedptionsList = options.map((option) => {
    type drodownKey = keyof T;

    return {
      label: option[labelParam as drodownKey],
      value: option[valueParam as drodownKey],
      valueobject: option,
    };
  });

  return computedptionsList;
};

export const buildCustomFieldDropdownOptions = <T>(options: T[]) => {
  const optionsList = options.map((option) => {
    return {
      label: option,
      value: option,
      valueobject: options,
    };
  });
  return optionsList;
};
