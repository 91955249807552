import { Button, Col, Row } from 'antd';
import { InsertProps } from 'components/Insert';
import { EmailEditorStyles } from 'lib/RichText';
import { useRichTextEditor } from 'lib/RichText/TipTapEditor/hooks/useRichTextEditor';
import styled from 'styled-components';
import { EmailTemplates } from 'types/entities/email-templates';
import { EditorCanvas } from '../Editor';
import { InsertArea, useEmailPlaceholders } from '../hooks/useEmailPlaceholders';

const t = {
  subject: 'Add subject of the mail',
};

const SubjectEditorContainer = styled(Row)`
  width: 100%;
  border: 1px solid var(--gray-5);
  border-radius: var(--br-2);
  padding: var(--space-4) var(--space-8);
  min-height: 32px;

  .insert-button {
    padding: var(--space-0);
    &:hover,
    :focus {
      background: var(--purple-1);
    }
  }
  &.editor-focussed,
  &:focus,
  &:focus-within {
    border: 1px solid var(--default-default-5, #9254de);
  }
`;

interface RichEmailSubjectEditorProps extends Omit<InsertProps, 'onInsert' | 'cancel'> {
  placeholder?: string;
  onChange: (body: string | null) => void;
  value?: string | null;
  area?: InsertArea;
}

export function RichEmailSubjectEditor(props: RichEmailSubjectEditorProps) {
  const { validFolders, folderType, placeHoldersWrapperOverrides, onChange, value, area } = props;

  const [PlaceholderButtonJSX, isInsertOpen] = useEmailPlaceholders({
    onTemplateSelect,
    validFolders,
    folderType,
    placeHoldersWrapperOverrides,
    onPlaceholderInsert,
    area,
  });

  const { editor, replaceText, insertText } = useRichTextEditor({
    placeholder: t.subject,
    onChange,
    value,
    disableFormatOnPaste: true,
  });

  function onPlaceholderInsert(placeholderString: string) {
    insertText(`<span class="grow-email-placeholder">{{${placeholderString}}}</span>`);
  }

  function onTemplateSelect(selectedTemplate: EmailTemplates) {
    replaceText(selectedTemplate.body);
    props.onTemplateSelect && props.onTemplateSelect(selectedTemplate);
  }

  const RichSubjectEditor = (
    <EmailEditorStyles className="">
      <EditorCanvas editor={editor} />
    </EmailEditorStyles>
  );

  const InsertButton = (
    <Button size="small" className="insert-button" type="text">
      {PlaceholderButtonJSX}
    </Button>
  );

  return (
    <SubjectEditorContainer className={isInsertOpen ? 'editor-focussed' : ''} align="middle" wrap={false}>
      <Col flex={'1 1 auto'}>{RichSubjectEditor}</Col>
      <Col >{InsertButton}</Col>
    </SubjectEditorContainer>
  );
}
