import { Dayjs } from 'dayjs';
import { CustomFieldEntityType } from 'types/entities/custom-field';
import ky from './ky';

const baseUrl = 'custom-fields/v2';
export type CustomFieldTypes = string | number | Dayjs | null;
export interface UpdateCustomFieldData {
  value?: CustomFieldTypes;
  custom_field_id: number;
  entity_type: CustomFieldEntityType;
  entity_id: number;
}

export async function editCustomFieldData(data: UpdateCustomFieldData, custom_field_id?: number) {
  return await ky.put(`${baseUrl}/${custom_field_id}/data`, { json: data });
}
