import { useEffect } from 'react';
import { BaseFilterBlockProps } from '../FilterBlocks/types';
import { FilterDropdown } from '../FilterDropdown';
import { FilterConfig, FilterType } from '../types';
import { useGenerateOptions } from './hooks/useGenerateOptions';
import { FilterConfigWrapperProps } from './type';

interface MultiSelectFilterBlockProps<T> extends BaseFilterBlockProps<T> {
  filterConfig: FilterConfigWrapperProps;
  shouldIncludeUnassigned?: boolean;
  isUnassignedActive?: boolean;
}
type Arraykey = number[] | string[];

export function MultiSelectCheckboxWrapper<T extends Arraykey>(
  props: MultiSelectFilterBlockProps<T>
) {
  const {
    filterConfig,
    onClear,
    onSubmit,
    value,
    showClear = true,
    shouldIncludeUnassigned,
  } = props;

  const [computedOptions, setComputedOptions] = useGenerateOptions(filterConfig.options);

  useEffect(() => {
    if (shouldIncludeUnassigned) {
      setComputedOptions((prevOptions) => {
        prevOptions.unshift({
          label: 'Unassigned',
          value: -1,
        });

        return [...prevOptions];
      });
    }
  }, [setComputedOptions, shouldIncludeUnassigned]);

  const filterConfigo: FilterConfig = {
    ...filterConfig,
    options: computedOptions,
    type: FilterType.MULTI_SELECT_CHECKBOX,
  };

  function handleSubmit(value: T) {
    onSubmit && onSubmit(value);
  }
  function handleClear() {
    onClear && onClear();
    onSubmit && onSubmit([] as unknown as T);
  }

  return (
    <FilterDropdown
      value={value}
      filterConfig={filterConfigo}
      onSubmit={handleSubmit}
      onClear={handleClear}
      showClear={showClear}
      emptyText={'All'}
    />
  );
}
