import { Form } from 'antd';
import { notify } from 'components/BaseComponents/Notifications';
import { ActivityType } from 'types/activities/activity-types';
import useFormSubmit from '../hooks/useFormSubmit';
import { DocumentFormElementProps, DocumentFormModalProps, FormItemNames } from '../types';
import { modifyUploadFiles, parseTransformSchema } from '../Utils';
import DocumentFormItems from './DocumentFormItems';
import { getNotifyErrorMessage, getNotifySuccessMessage } from './notificationMessage';

export default function DocumentForm<DocumentElements extends DocumentFormElementProps>(
  props: Omit<DocumentFormModalProps<DocumentElements>, 'openModal'>
) {
  const { form, responseSchema, onMutateFn, onFormClose, customTransformer } = props;
  const { onFormSubmit } = useFormSubmit<DocumentElements['responseType']>(onMutateFn);

  function onFormFinish(values: Record<FormItemNames, any>) {
    const formValues = parseTransformSchema<DocumentElements['responseType']>({
      data: values,
      type: ActivityType.DOCUMENT,
      responseSchema,
      customTransformer,
    });

    const modifiedData = modifyUploadFiles<DocumentElements['responseSchema']>(
      'file_upload_ids',
      formValues
    );

    onFormSubmit(modifiedData, ActivityType.DOCUMENT)
      .then(() => {
        onFormClose();
        const { title } = getNotifySuccessMessage();
        notify.success(title);
      })
      .catch(() => {
        const { title } = getNotifyErrorMessage();
        notify.error(title);
      });
  }

  return (
    <Form.Provider
      onFormFinish={(name, { values }) => {
        onFormFinish(values as Record<FormItemNames, any>);
      }}
    >
      <Form name={'activityForm'} form={form} layout="vertical" preserve={false}>
        <DocumentFormItems<DocumentElements> {...props} />
      </Form>
    </Form.Provider>
  );
}
