import { Tag } from 'antd';
import styled from 'styled-components';
import { StyledFormItem } from '../Styles/Styles';

export const StyledTag = styled(Tag)`
  display: flex;
  align-items: center;
  padding-block: var(--space-2);
  border: 1px solid var(--gray-4) !important;
  background-color: var(--gray-3);
  margin-right: var(--space-4);
`;

export const ListOptionsContainer = styled.div`
  .ant-select-item {
    display: flex;
    align-items: center;
    gap: var(--space-8);
  }
`;

export const CreateDisputeContaniner = styled.div`
  color: var(--primary-7);
  padding: var(--space-8);
  border-top: 1px solid var(--gray-3);
`;

export const DocumentInvoiceSelect = styled.div`
  padding: var(--space-20);
  background-color: var(--gray-2);
  border-radius: var(--space-4);
`;

export const EllipsisListContainer = styled.div`
  margin-bottom: var(--space-24);
`;

export const ActivityFormItem = styled(StyledFormItem)`
  margin-bottom: var(--space-24);
`;
