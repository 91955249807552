import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import Tag from 'components/BaseComponents/Tags';
import { assign, has } from 'lodash';
import styled from 'styled-components';
import { SourceApp } from 'types/common/source-app';
import { BaseCustomer } from 'types/entities/customer';

const t = {
  title: 'Customer Portal',
};
const StyledTag = styled(Tag)`
  color: var(--geekblue-6);
  background-color: var(--geekblue-1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-8);
  border: 1px solid var(--geekblue-2);
  height: 24px;
  margin: 0;
`;
interface SourceAppCompProps {
  source_app: SourceApp;
  iconOnly?: boolean;
  customer_details: BaseCustomer;
}

const iconOnlyStyle: React.CSSProperties = {
  background: 'transparent',
  border: 0,
  padding: 0,
  margin: 0,
  width: 'auto',
  height: 'auto',
  cursor: 'auto',
};

const UserIcon = <FontAwesomeIcon icon={['far', 'images-user']} color="var(--geekblue-6)" />;

const LinkIcon = (
  <FontAwesomeIcon
    icon={['far', 'arrow-up-right-from-square']}
    color="var(--geekblue-5)"
    size="sm"
  />
);
function SourceAppComp(props: SourceAppCompProps) {
  const { source_app, iconOnly = false, customer_details } = props;
  const result = has(customer_details, 'portal_uri')
    ? assign({}, { icon: LinkIcon, editing: false })
    : undefined;
  if (source_app === 'CUSTOMER_PORTAL') {
    return (
      <Tooltip title={iconOnly ? 'Customer Portal' : ''} arrow={false}>
        {iconOnly ? (
          <StyledTag icon={UserIcon} style={iconOnly ? iconOnlyStyle : undefined}>
            {iconOnly ? null : <TypographyText editable={result}>{t.title}</TypographyText>}
          </StyledTag>
        ) : (
          <a
            href={customer_details?.portal_uri}
            target="_blank"
            rel="noreferrer"
            style={{ cursor: customer_details?.portal_uri ? 'pointer' : 'default' }}
          >
            <StyledTag icon={UserIcon} style={iconOnly ? iconOnlyStyle : undefined}>
              {iconOnly ? null : <TypographyText editable={result}>{t.title}</TypographyText>}
            </StyledTag>
          </a>
        )}
      </Tooltip>
    );
  }

  return null;
}

export { SourceAppComp };
