import { ShowIfActive } from 'components/Common/FilterComponents/Common/ShowIfActive';
import { ReportingTagsFilter } from 'components/Common/FilterComponents/FilterBlocks/CustomerOrInvoice';
import { Filter, FilterGroup } from 'components/Common/FilterComponents/types';
import { IReportingTags } from 'store/reporting-tags/type';
import { CustomDashboardFitlers, useDashboardFilter } from './hooks/useDashboardFilter';

type Props = ReturnType<typeof useDashboardFilter>;
export function ReportingTagsFilters(props: Props) {
  const { filterGroups, updateFilterGroups, filterValues, updateFilterValues } = props;

  const reportingTagFilters: FilterGroup<IReportingTags> | undefined = filterGroups.find(
    (filterGroup) => filterGroup.id === CustomDashboardFitlers.REPORTING_TAGS
  );

  function updateReportingTagValue(reportingTagId: number, value: number[]) {
    updateFilterValues({
      type: 'UPDATE_REPORTING_TAGS',
      payload: { tag_id: reportingTagId, tag_option_ids: value ?? [] },
    });
  }
  function removeReportingTag(reportingTagId: string) {
    updateFilterGroups({
      type: 'UPDATE_FILTER',
      payload: {
        groupId: CustomDashboardFitlers.REPORTING_TAGS,
        filterId: reportingTagId,
        updatedValues: { active: false },
      },
    });
  }
  function getReportingTagValue(reportingTagId: number) {
    const selectedReportingTag = filterValues.reporting_tags.find(
      (reportingTag) => reportingTag.tag_id === reportingTagId
    );

    return selectedReportingTag ? selectedReportingTag.tag_option_ids : [];
  }

  if (!reportingTagFilters) return null;

  return (
    <>
      {reportingTagFilters.filterList.map((reportingTagFilter: Filter<IReportingTags>) => {
        if (!reportingTagFilter.filterObject) return null;

        const reportingTagId = reportingTagFilter.filterObject.id;

        function handleSubmit(newValue: number[]) {
          updateReportingTagValue(reportingTagId, newValue);
        }

        const handleClear = () => removeReportingTag(reportingTagFilter.id);

        return (
          <ShowIfActive
            key={reportingTagFilter.id}
            filterGroupId={CustomDashboardFitlers.REPORTING_TAGS}
            filterId={reportingTagFilter.id}
            filterGroups={filterGroups}
            order={reportingTagFilter.order}
          >
            <ReportingTagsFilter
              reportingTag={reportingTagFilter.filterObject}
              value={getReportingTagValue(reportingTagId)}
              onSubmit={handleSubmit}
              onClear={handleClear}
              showClear
            />
          </ShowIfActive>
        );
      })}
    </>
  );
}
