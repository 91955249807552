export const PageHeaderStyle: React.CSSProperties = {
  background: 'var(--geekblue-1)',
  boxShadow: 'var(--shadow-1)',
  minHeight: '62px',
};
export const PageFilterStyle: React.CSSProperties = {
  boxShadow: 'none',
  backgroundColor: 'initial',
};

export const TabBodyStyle: React.CSSProperties = {
  flexGrow: 1,
  flexBasis: 0,
  overflowY: 'auto',
  padding: 'var(--space-24)',
};
