import { useCallback, useEffect, useState } from 'react';
import { SelectOption } from '../../types';
import { FilterOptions } from '../type';

export function useGenerateOptions(
  options: FilterOptions
): [SelectOption[], React.Dispatch<React.SetStateAction<SelectOption[]>>] {
  const [computedOptions, setComputedOptions] = useState<SelectOption[]>([]);

  const generateOptions = useCallback(async () => {
    let computedOptions: SelectOption[] = [];

    if (typeof options === 'function') {
      computedOptions = await options();
    } else if (Array.isArray(options)) {
      computedOptions = options;
    }

    return computedOptions;
  }, [options]);

  useEffect(() => {
    generateOptions().then((options) => {
      setComputedOptions((prevoption) => {
        return [...prevoption, ...options];
      });
    });
  }, [generateOptions]);

  return [computedOptions, setComputedOptions];
}
