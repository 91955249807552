import { getAllPrivileges } from '../services/privilges';
import { getPrivligesListHandler } from '../store/privilges/privilege';
import { IPrivileges } from '../store/privilges/type';
import { AppThunk } from '../store/store';

export const getAllPrivilgesListThunk = (): AppThunk<
  Promise<{ payload: IPrivileges[]; type: string }>
> => {
  return async (dispatch) => {
    try {
      return dispatch(getPrivligesListHandler(await getAllPrivileges()));
    } catch (err) {
      console.log('Error fetching privileges', err);
      return dispatch(getPrivligesListHandler([] as IPrivileges[]));
    }
  };
};
