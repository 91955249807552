import { HeaderRendererProps, HeaderText, HeaderWrapper } from '@sinecycle/growcomponents';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { IInvoices } from 'store/customer/types';
import { OpenInvoiceListSummary } from 'types/api/invoice';
import { AccountConfigKey } from 'types/entities/account';
interface AmountHeaderProps extends HeaderRendererProps<IInvoices> {
  summary: OpenInvoiceListSummary;
}
function AmountHeader(props: AmountHeaderProps) {
  const { summary: invoiceListSummary } = props;
  const isMulticurrencyEnabled = useConfig(AccountConfigKey.MULTI_CURRENCY_ENABLED);
  const TotalBalanceAmount = invoiceListSummary?.total_balance_amount ? (
    <AmountWithCurrencySplit
      type="multi"
      style={{ color: 'inherit' }}
      amountWithCurrencySplit={invoiceListSummary?.total_balance_amount}
      formatOptions={{ notation: 'compact' }}
      className="text-14 semi-bold"
      showTooltip
      currencyArea="DETAILS"
    />
  ) : null;

  return (
    <HeaderWrapper
      onSort={() => {}}
      styles={{
        gap: 'var(--space-8)',
        justifyContent: isMulticurrencyEnabled ? 'center' : 'flex-end',
      }}
    >
      <HeaderText text={props.displayName} />
      {TotalBalanceAmount}
    </HeaderWrapper>
  );
}

export { AmountHeader };
