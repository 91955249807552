import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { MentionData } from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import { ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';

import { CommentRead } from './CommentRead';
import { CommentWrite } from './CommentWrite';

import { RootState } from '../../../reducers/rootReducer';
import { activeUsers } from '../../../services/users';
import { getUsersHandler } from '../../../store/user/user';

import { UserAvatar } from 'components/HigherOrderComponent/UserAvatar';
import { isJson } from '../../../util/json-utils';
import { UserInfoPopup } from './styles';

export function PopoverContent(mention: MentionData) {
  if (!mention) return null;

  return (
    <UserInfoPopup>
      <UserAvatar name={[mention.first_name[0], mention.last_name[0] ?? '']} />

      <div className="user-info">
        <div className="user-info-part">
          <span className="name">{mention.name}</span>
          <span className="role">
            <b>{mention.role}</b>
          </span>
        </div>
        <div className="user-info-part">
          <span className="email">{mention.email}</span>
          <span className="phone">{mention.phone}</span>
        </div>
      </div>
    </UserInfoPopup>
  );
}

type handleContentChangeFnType = (state: ContentState) => void;

interface Props {
  mode: 'read' | 'write';
  jsonString?: string;
  contentState?: ContentState;
  onChange?: handleContentChangeFnType;
}

export const mentionsDataSelector = createSelector(
  (state: RootState) => {
    return (
      state.users.activeUsers?.map((user) => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        first_name: user.first_name,
        last_name: user.last_name,
        avatar: user.photo_url,
        email: user.email,
        phone: user.phone,
        role: user.designation,
      })) ?? []
    );
  },
  (trimmedUsers) => {
    const mentionsData: Record<string, MentionData> = {};
    trimmedUsers.forEach((user) => (mentionsData[user.id] = user));

    return mentionsData;
  }
);

export default function RichComments(props: Props) {
  const mentions = useSelector(mentionsDataSelector);
  const dispatch = useDispatch();

  const fetchAndStoreUsers = useCallback(
    async function fetchAndStoreUsers() {
      const users = await activeUsers();
      return dispatch(getUsersHandler({ status: 200, users }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!Object.keys(mentions).length) {
      fetchAndStoreUsers();
    }
  }, [mentions, dispatch, fetchAndStoreUsers]);

  return props.mode === 'read' ? (
    props.jsonString && isJson(props.jsonString) ? (
      <CommentRead rawDraftContentStateJson={props.jsonString} mentions={mentions} />
    ) : (
      <div className="ms-5">{props.jsonString}</div>
    )
  ) : props.mode === 'write' ? (
    <CommentWrite
      contentState={props.contentState}
      mentions={Object.values(mentions)}
      onChange={props.onChange as handleContentChangeFnType}
    />
  ) : null;
}
