import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers/rootReducer';
import {
  EmailTemplateFolder,
  EmailTemplateFolderInitial,
  GetEmailTemplateResponseState,
} from './types';

export const initialState: GetEmailTemplateResponseState = {
  status: 0,
  message: '',
  folderId: 0,
  emailTemplate: {
    id: -1,
    deleted: false,
    title: 'Untitled Email Template',
    description: '',
    subject: '',
    body: '',
    attachments: [],
    template_folder_id: 0,
    parent_template_id: null,
  },
  defaultEmailTemplate: {
    id: -1,
    deleted: false,
    title: 'Untitled Email Template',
    description: '',
    subject: '',
    body: '',
    attachments: [],
    template_folder_id: 0,
    parent_template_id: null,
  },
};

const emailTemplatesSlice = createSlice({
  name: 'emailTemplate',
  initialState,
  reducers: {
    getEmailTemplateHandler(state, action: PayloadAction<GetEmailTemplateResponseState>) {
      if (action.payload.emailTemplate?.id !== -1) {
        state.emailTemplate = action.payload.emailTemplate;
      }
    },

    setCurrentFolderId(state, action: PayloadAction<EmailTemplateFolder>) {
      state.folderId = action.payload.folderId;
    },
    resetFolderId(state) {
      state.folderId = state.defaultFolderId;
    },
    setInitialFolderId(state, action: PayloadAction<EmailTemplateFolderInitial>) {
      state.folderId = action.payload.folderId;
      state.defaultFolderId = action.payload.folderId;
      if (state.defaultEmailTemplate) {
        state.defaultEmailTemplate = {
          ...state.defaultEmailTemplate,
          template_folder_id: action.payload.folderId,
        };
      }
    },
  },
});

export const emailTemplateSelector = (id: number) => (state: RootState) => {
  return state.emailTemplate.emailTemplate?.id === id
    ? state.emailTemplate.emailTemplate
    : state.emailTemplate.defaultEmailTemplate;
};

export const folderIdSelector = (state: RootState) => state.emailTemplate.folderId;

export const { getEmailTemplateHandler, setCurrentFolderId, resetFolderId, setInitialFolderId } =
  emailTemplatesSlice.actions;

export default emailTemplatesSlice.reducer;
