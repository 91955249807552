import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Menu, message } from 'antd';
import { logRocket } from 'lib/logrocket';
import { writeToClipboard } from 'util/clipboard';

function copyLogRocketSession() {
  logRocket
    .getSessionURL()
    .then((sessionURL) => {
      if (!sessionURL) {
        throw new Error('no session found');
      }
      writeToClipboard(sessionURL);
    })
    .then(() => message.success('Session URL Copied to clipboard'))
    .catch((e) => {
      message.error('Session URL not copied, check console for more details');
      console.log('Logrocket session URL Not copied to clipboard', e);
    });
}

function startNewLogRocketSession() {
  logRocket.startNewSession();
  message.success('New Log Rocket Session Started. Ended Current One');
}

function LogRocketIcon(props: {}) {
  return <FontAwesomeIcon size="lg" icon={['fas', 'rocket']} color="var(--primary-7)" />;
}

const menu = (
  <Menu>
    <Menu.Item onClick={copyLogRocketSession}>Copy LogRocket Session URL to Clipboard</Menu.Item>
    <Menu.Item onClick={startNewLogRocketSession}>Start New LogRocket Session</Menu.Item>
  </Menu>
);

export function LogRocketButton(props: {}) {

  const items = [
    {
      label: 'Copy LogRocket Session URL to Clipboard',
      onClick: copyLogRocketSession,
      key: 'Copy',
    },
    {
      label: 'Start New LogRocket Session',
      onClick: startNewLogRocketSession,
      key: 'Start New',
    },
  ];
  return (
    <Dropdown menu={{ items }}>
      <Button style={{ margin: '0 var(--space-8)' }} icon={<LogRocketIcon />} />
    </Dropdown>
  );
}
