import { ResultStatusType } from 'antd/lib/result';
import { CSSProperties } from 'react';

export enum ErrorType {
  'API_ERROR' = 'API_ERROR',
}

export interface ErrorBoundaryWrapperProps {
  children?: JSX.Element[] | JSX.Element;
  style?: CSSProperties;
  redirect?: {
    title: string;
    url: string;
  };
}

export type ErrorHandler = {
  type?: ErrorType;
  message?: string;
  serverErrorDesc?: APIError;
};

export interface APIError {
  status: ResultStatusType;
  title?: string;
  subTitle?: string;
}
