import { useQueryClient } from '@tanstack/react-query';
import { useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { useEventBus } from 'util/hooks/useCustomEventEmitter';
import { ActivityEventsPayload } from '../types';

export default function useEmitActivityEvents(isLoading: boolean) {
  const { emitEvent } = useEventBus();
  const queryClient = useQueryClient();
  const { entitySearchParams } = useEntitySearchParams();
  const isFetching = queryClient.isFetching({
    queryKey: [entitySearchParams[EActivityQueryParams.QUERY_KEY]],
  });

  emitEvent<ActivityEventsPayload>({
    event: 'activitiesLoading',
    data: {
      loading: Boolean(isFetching) || isLoading,
      id: entitySearchParams[EActivityQueryParams.ACTIVITY_ID],
      nextPageLoading: Boolean(isFetching),
      enabled: true,
    },
  });
}
