import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { LogRocketButton } from 'components/Common/LogRocket';
import SpotLightSearch from 'components/HigherOrderComponent/SpotlightSearch/spotlight';
import Search from 'components/Search/Search';
import React from 'react';
import { useSelector } from 'react-redux';
import { authenticationSelector } from 'store/authentication/authentication';
import { APP_ENV } from 'util/http-utils';
import { useLayoutContext } from '../LayoutContext';
import { AccountMenu } from './AccountMenu';
import { Breadcrumbs } from './Breadcrumbs';
import ChangeLogWidget from './ChangeLogWidget';
import { StyledLayoutHeader } from './style';

interface HeaderProps {
  logout?: () => void;
  className?: string;
}

function Header(props: HeaderProps) {
  const { user, accountDetails } = useSelector(authenticationSelector);

  const { headerLayout } = useLayoutContext();
  const { middleComponents } = headerLayout;

  const middleComponentsToRender = Array.isArray(middleComponents) ? (
    middleComponents.map((middleComponent, idx) => (
      <React.Fragment key={idx}>{middleComponent}</React.Fragment>
    ))
  ) : (
    <React.Fragment>{middleComponents}</React.Fragment>
  );

  const PageInfo = (
    <Flex direction="column" gap="--space-4">
      <Texto size="12" color="--gray-7">
        {accountDetails?.name}
      </Texto>
      <Breadcrumbs />
    </Flex>
  );

  const SearchAndUserMenu = (
    <Flex gap="var(--space-16)">
      {import.meta.env.VITE_ENV === APP_ENV.staging && <LogRocketButton />}
      <SpotLightSearch />
      <Search />
      <ChangeLogWidget />
      {/* FIX: HERE WE NEED TO EITHER PASS User from GET users/{:id} or pass user id so "AccountMenu" can fetch it itself */}
      {/* @ts-ignore */}
      {user && <AccountMenu user={user} />}
    </Flex>
  );

  return (
    <StyledLayoutHeader>
      <Flex align="center" style={{ width: '100%' }} justify="space-between">
        <Flex.Child className="head-portion">
          <Flex>{PageInfo}</Flex>
        </Flex.Child>

        <Flex.Child className="middle-portion" flex="1 0 0">
          {middleComponentsToRender}
        </Flex.Child>

        <Flex.Child flex="1 0 0" className="tail-portion">
          <Flex justify="flex-end">
            {user?.email && (
              <Flex.Child className="header-right-items">{SearchAndUserMenu}</Flex.Child>
            )}
          </Flex>
        </Flex.Child>
      </Flex>
    </StyledLayoutHeader>
  );
}

export default Header;
