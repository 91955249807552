import { camelCase } from 'lodash';
import React from 'react';

export const JSToCSS = (JS: Record<string, any>) => {
  let cssString = '';
  for (const objectKey in JS) {
    cssString +=
      objectKey.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`) + ': ' + JS[objectKey] + ';\n';
  }

  return cssString;
};

export function cssTextToReactProps(cssText?: string): React.CSSProperties {
  const props: Record<string, any> = {};

  cssText?.split(';').forEach((style: any) => {
    const [key, value] = style.split(':') as string[];
    if (key && value) {
      props[camelCase(key.trim())] = value.trim();
    }
  });

  return props;
}
