import { CustomButtonNodeView } from 'components/BaseComponents/RTE/Toolbar/common/nodes/CustomLinkButton';
import { AddButtonProps } from 'components/BaseComponents/RTE/Toolbar/common/nodes/CustomLinkButton/AddButtonCanvas';
import { buttonCSSProps } from 'components/BaseComponents/RTE/Toolbar/common/nodes/CustomLinkButton/util';
import { getNodeViewRender, Node } from 'lib/RichText';
import { cssTextToReactProps, JSToCSS } from 'util/css-js';

function getDom(buttonStyle?: AddButtonProps) {
  const dom = document.createElement('div');
  if (buttonStyle) {
    const content = document.createElement('div');
    const anchor = document.createElement('a');
    content.className = 'gf-custom-button';
    anchor.href = buttonStyle.linkURL ?? '';
    anchor.innerText = buttonStyle.displayText ?? '';
    anchor.setAttribute('data-button-size', buttonStyle.size ?? '');
    content.style.textAlign = buttonStyle.align ?? '';

    const style: React.CSSProperties = buttonCSSProps(
      buttonStyle.size ?? 'default',
      buttonStyle.background
    );

    anchor.style.cssText = JSToCSS(style);
    anchor.target = '_blank';
    content.append(anchor);

    dom.append(content);
  }

  return {
    dom,
  };
}

export const CustomButtonNode = Node.create({
  name: 'customButton',
  group: 'block',
  content: 'inline*',

  atom: true,

  addAttributes() {
    return {
      buttonStyle: {
        default: null,
        parseHTML: (element) => {
          if (element) {
            const buttonStyle: AddButtonProps = {};
            const reactStyleProps = cssTextToReactProps(element.style.cssText);
            buttonStyle.align = reactStyleProps.textAlign;

            const anchorChild = element.children[0] as HTMLElement;

            if (anchorChild) {
              const anchorReactStyleProps = cssTextToReactProps(anchorChild.style.cssText);
              buttonStyle.background = anchorReactStyleProps.background as string;
              buttonStyle.linkURL = anchorChild.getAttribute('href') ?? '';
              buttonStyle.size = anchorChild.getAttribute(
                'data-button-size'
              ) as AddButtonProps['size'];
              buttonStyle.displayText = anchorChild.innerText;
            }

            return buttonStyle;
          }

          return null;
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'custom-button',
      },
      {
        tag: 'div.gf-custom-button',
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    return getDom(node.attrs.buttonStyle);
  },

  addNodeView() {
    return getNodeViewRender(CustomButtonNodeView);
  },
});
