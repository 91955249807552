import { Form } from 'antd';
import { omit } from 'lodash';
import { CustomFieldFilterValues, CustomFieldOperatorType } from '../custom-field';
import { InvoiceStatus } from '../invoice';
import { InOperator, SegmentFilterOperator } from './operators';

export enum SegmentOperator {
  AND = 'AND',
  ANY = 'OR',
}
export enum InvoiceSegmentFilterType {
  INVOICE_AMOUNT = 'INVOICE_AMOUNT',
  INVOICE_DUE_DAYS = 'INVOICE_DUE_DAYS',
  INVOICE_STATUS = 'INVOICE_STATUS',
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  INVOICE_BALANCE_AMOUNT = 'INVOICE_BALANCE_AMOUNT',
  INVOICE_SUBSIDIARY = 'INVOICE_SUBSIDIARY',
}
export enum InvoiceSegmentFieldDataType {
  CURRENCY = 'CURRENCY',
  INTEGER = 'INTEGER',
  MULTISELECT = 'MULTISELECT',
}

interface BaseInvoiceSegmentFilter {
  id: number | string;
  type: InvoiceSegmentFilterType;
  operator: SegmentFilterOperator<unknown>;
}
export interface InvoiceAmountSegmentFilter extends BaseInvoiceSegmentFilter {
  id: string;
  type: InvoiceSegmentFilterType.INVOICE_AMOUNT;
  operator: SegmentFilterOperator<number>;
}
export interface InvoiceStatusSegmentFilter extends BaseInvoiceSegmentFilter {
  type: InvoiceSegmentFilterType.INVOICE_STATUS;
  operator: SegmentFilterOperator<InvoiceStatus>;
}
export interface InvoiceDueDaysSegmentFilter extends BaseInvoiceSegmentFilter {
  id: string;
  type: InvoiceSegmentFilterType.INVOICE_DUE_DAYS;
  operator: SegmentFilterOperator<number>;
}
export interface InvoiceCustomFieldSegmentFilter
  extends Omit<BaseInvoiceSegmentFilter, 'operator' | 'field_id'> {
  id: number;
  type: InvoiceSegmentFilterType.CUSTOM_FIELD;
  operator: CustomFieldFilterValues<CustomFieldOperatorType>['operator'];
}

export interface InvoiceBalanceAmountFilters extends BaseInvoiceSegmentFilter {
  id: string;
  type: InvoiceSegmentFilterType.INVOICE_BALANCE_AMOUNT;
  operator: SegmentFilterOperator<number>;
}
export interface InvoiceSubsidiarySegmentFilter {
  id: string;
  type: InvoiceSegmentFilterType.INVOICE_SUBSIDIARY;
  operator: InOperator<number>;
}

export type InvoiceSegmentFilter =
  | InvoiceAmountSegmentFilter
  | InvoiceStatusSegmentFilter
  | InvoiceDueDaysSegmentFilter
  | InvoiceCustomFieldSegmentFilter
  | InvoiceSubsidiarySegmentFilter
  | InvoiceBalanceAmountFilters;

export type InvoiceSegmentId = number | 'new-segment';

export interface InvoiceSegment {
  id: InvoiceSegmentId;
  title: string;
  deleted: boolean;
  operator: SegmentOperator;
  filters: InvoiceSegmentFilter[];
}
export type InvoiceSegmentCreatePayload = Omit<InvoiceSegment, 'id'> & { id?: InvoiceSegmentId };

export interface MatchedInvoice {
  invoice_id: number;
}

export const mapInvoiceSegmentFilterPredicate = (filter: InvoiceSegmentFilter) => {
  return filter.type === InvoiceSegmentFilterType.CUSTOM_FIELD ? filter : omit(filter, 'id');
};

export type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];
export type RangeItem = {
  value: number | null;
  validateStatus?: ValidateStatus;
  errorMsg?: string | null;
};
export type Range = {
  from: RangeItem;
  to: RangeItem;
};

export type Validation = Omit<RangeItem, 'value'>;
