import { Filter } from 'components/Common/FilterComponents/types';
import { useEffect, useMemo, useState } from 'react';
import { getCustomFields } from 'services/custom-field';
import { CustomField, CustomFieldEntityType, CustomFieldPage } from 'types/entities/custom-field';

export function useCustomField(entity: CustomFieldEntityType) {
  const [customField, setCustomField] = useState<CustomField[]>([]);

  useEffect(() => {
    getCustomFields({ entity, page: CustomFieldPage.INVOICE_LIST })
      .then((customFields) => {
        setCustomField(customFields);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [entity]);

  const customFields: Filter<CustomField>[] = useMemo(() => {
    return customField.map((customField) => ({
      id: `${entity}-custom-field-${customField.id}`,
      label: customField.display_name,
      active: false,
      removable: true,
      filterObject: customField,
      filterValueKeys: [String(customField.id)],
    }));
  }, [customField, entity]);

  return [customFields];
}
