import { Flex } from 'components/BaseComponents/Layout/Flex';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { LoadingSkeleton } from 'components/CollectionActivities/Common/Details/LoadingSkeleton';

function LoaderDetailsInfo() {
  const Status = <LoadingSkeleton />;
  const EscalatedTo = <LoadingSkeleton />;
  const Owner = <LoadingSkeleton />;

  const CreatedDate = <LoadingSkeleton />;

  const Customer = <LoadingSkeleton />;

  return (
    <Flex direction="column" gap="var(--space-16)">
      <InfoContent content={Status} />
      <InfoContent content={Owner} />
      <InfoContent content={EscalatedTo} />
      <InfoContent content={CreatedDate} />
      <InfoContent content={Customer} />
    </Flex>
  );
}

export { LoaderDetailsInfo };
