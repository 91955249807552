import { Alert, Col, DatePicker, Radio, Row } from 'antd';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { TextArea } from 'components/BaseComponents/Input';
import { GrowText } from 'components/BaseComponents/Typography';
import { EllipsisList } from 'components/Common/EllipsisList';
import dayjs from 'dayjs';
import { concat, map, sumBy } from 'lodash';
import { useCallback, useEffect } from 'react';
import { PTPType } from 'types/activities/promise-to-pay';
import transformData from 'util/transformData';
import InvoiceSelect from '../FormElements/InvoiceSelect';
import { PTPTypeOptions } from '../predicate';
import { ActivityFormItem, EllipsisListContainer } from '../style';
import { InvoiceFieldMapping, PTPFormElementProps, PTPFormModalProps } from '../types';
import P2PTableForm from './P2PTableForm';

export default function PTPFormItems<PTPElements extends PTPFormElementProps>(
  props: Omit<PTPFormModalProps<PTPElements>, 'openModal'>
) {
  const {
    invoices = [],
    form,
    invoiceQueryKey,
    invoiceSearchFn,
    invoiceQueryFn,
    invoiceTransformSchema,
    text,
    customerCurrency,
    bulkAction,
    bulkRecordsLength,
    dateFormat,
  } = props;
  const {
    invoiceLabel,
    invoicePlaceholder,
    dateLabel,
    descriptionLabel,
    descriptionPlaceholder,
    ptpTypeLabel,
    ptpAlertInfo,
    ptpExpected,
    ptpCommitment,
  } = text;

  const ptpSelectedInvoices = useFormWatch('ptpInvoices', form);
  const selectedInvoice: PTPElements['invoiceSelect'][] = concat(
    invoices,
    ptpSelectedInvoices || []
  );
  const selectedInvoiceIds = map(selectedInvoice, (item) => (item as { id: number })?.id);
  const selectedInvoiceNo = map(invoices, (item) => (item as any)?.invoice_no);
  const ptpTypeValue = useFormWatch('ptpType', form);

  const onChangeInvoiceList = useCallback(
    (options: InvoiceFieldMapping[]) => {
      const amountDetails = options?.map((invoice) => ({
        ...invoice,
        amount: invoice?.balanceDueAmount! - (invoice?.ptpAmount ?? 0),
      }));

      form.setFieldValue('ptpTable', amountDetails);
      form.setFieldValue('ptpInvoices', options);
      form.setFieldValue(
        'totalAmount',
        sumBy(
          amountDetails.filter((x) => x.amount > 0),
          'amount'
        )
      );
    },
    [form]
  );

  useEffect(() => {
    if (invoices.length) {
      const optionsListData = map(invoices, (item) => {
        return transformData<PTPElements['invoiceSelect'], InvoiceFieldMapping>({
          originalData: item,
          schemaMapping: invoiceTransformSchema,
        });
      });
      onChangeInvoiceList(optionsListData);
    }
  }, [invoiceTransformSchema, invoices, onChangeInvoiceList]);

  const InvoiceSelectList = (
    <ActivityFormItem
      label={invoiceLabel}
      name={'ptpInvoices'}
      initialValue={selectedInvoiceIds}
      rules={[
        {
          required: true,
          message: `${invoiceLabel} is required`,
        },
      ]}
      required
    >
      <InvoiceSelect<PTPElements['invoiceSelect']>
        placeholder={invoicePlaceholder}
        selectedInvoicesId={selectedInvoiceIds}
        selectedInvoices={invoices}
        onChangeInvoice={(value, option) => onChangeInvoiceList(option)}
        queryFn={invoiceQueryFn}
        searchQueryFn={invoiceSearchFn}
        queryKey={invoiceQueryKey}
        transformSchema={invoiceTransformSchema}
      />
    </ActivityFormItem>
  );

  const PTPDate = (
    <ActivityFormItem
      label={dateLabel}
      name="ptpDate"
      rules={[
        {
          required: true,
          message: `${dateLabel} is required`,
        },
      ]}
      required
      initialValue={dayjs()}
    >
      <DatePicker placeholder={dateLabel} format={dateFormat} style={{ width: '140px' }} />
    </ActivityFormItem>
  );

  const PTPTypeField = (
    <Row align="middle">
      <Col span={14}>
        <ActivityFormItem name="ptpType" label={ptpTypeLabel}>
          <Radio.Group
            options={PTPTypeOptions}
            optionType="button"
            defaultValue={PTPType.CUSTOMER}
          />
        </ActivityFormItem>
      </Col>
      <Col span={10}>
        <GrowText>{ptpTypeValue === PTPType.EXPECTED ? ptpExpected : ptpCommitment}</GrowText>
      </Col>
    </Row>
  );

  const getTableBlock = (
    <ActivityFormItem name="ptpTable">
      <P2PTableForm
        totalAmount={form.getFieldValue('totalAmount')}
        invoiceList={form.getFieldValue('ptpTable')}
        customerCurrency={customerCurrency}
        form={form}
      />
    </ActivityFormItem>
  );

  const AlertInfo = (
    <Alert
      message={ptpAlertInfo}
      type="info"
      style={{
        margin: 'var(--space-12) auto',
      }}
      showIcon
    />
  );

  const PtpDescription = (
    <ActivityFormItem label={descriptionLabel} name="ptpDescription">
      <TextArea placeholder={descriptionPlaceholder} />
    </ActivityFormItem>
  );

  return (
    <>
      {bulkAction ? (
        <EllipsisListContainer>
          <EllipsisList
            label="Added Invoices"
            list={map(selectedInvoiceNo, (item) => String(item))}
            totalRecords={bulkRecordsLength}
            showScrollablePopover
          />
        </EllipsisListContainer>
      ) : (
        InvoiceSelectList
      )}
      {PTPDate}
      {PTPTypeField}
      {getTableBlock}
      {AlertInfo}
      {PtpDescription}
      <ActivityFormItem noStyle name="totalAmount" />
    </>
  );
}
