import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { t } from '../constants';
import { StyledIconButton } from '../style';

interface FilterViewEditProps {
  hide?: boolean;
  onClickCallBack?: React.MouseEventHandler<HTMLElement>;
  isDirty: boolean;
}
function FilterViewEdit(props: Readonly<FilterViewEditProps>) {
  if (props.hide) return null;
  if (!props.isDirty) return null;

  const EditIcon = <FontAwesomeIcon icon={['far', 'pen']} color="var(--primary-7)" />;
  return (
    <Tooltip title={t.edit_filter_view} overlayStyle={{ padding: 0 }}>
      <StyledIconButton icon={EditIcon} onClick={props.onClickCallBack} size="small" />
    </Tooltip>
  );
}

export default FilterViewEdit;
