import { CellRenderProps } from '@sinecycle/growcomponents';
import { LastActivity } from 'components/HigherOrderComponent/KeyActivitesContainer/LastActivity';
import { IInvoices } from 'store/customer/types';

interface LastActivityRendererProps<T> extends CellRenderProps<T> {
  data: T;
}

export const LastActivityRenderer = (props: LastActivityRendererProps<IInvoices>) => {
  const { data } = props;

  if (data.last_activity_type && data.last_activity_date) {
    return (
      <LastActivity
        className='last-activity"'
        lastActivityType={data.last_activity_type}
        lastActivityDate={data.last_activity_date}
        color="var(--gray-8)"
        hideDate
      />
    );
  }

  return <div className="last-activity">{'-'}</div>;
};
