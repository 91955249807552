import styled from 'styled-components';
import { Typography, Col } from 'antd';

const { Text } = Typography;

export const SecondaryText = styled(Text)`
  display: block;
  font-size: var(--fs-12);
  color: var(--gray-8);
`;

export const TotalCollected = styled(Col)`
  h5.ant-typography {
    font-weight: normal;
    line-height: normal;
  }
`;

export const MainCardItem = styled.div`
  .title {
    font-weight: normal;
    line-height: normal;
    font-size: var(--fs-16);
  }
  .value {
    font-weight: var(--fs-semibold);
    color: var(--purple-6);
  }
`;

export const TotalText = styled(Text)`
  color: var(--gray-8);
  display: block;
  line-height: normal;
  font-size: var(--fs-16);
`;
