import styled from 'styled-components';

export const MentionBox = styled.div`
  --bg-color: var(--gray-1);
  --comment-border: 1px solid var(--gray-5);
  display: flex;
  flex-direction: column;
  height: 100%;
  .editor {
    flex-grow: 1;
    border: var(--comment-border, 1px solid var(--gray-9));
    border-radius: 0.25rem;
    padding: 0;
    min-height: var(--read-height, 100px);
    background: var(--gray-1);
  }

  .mention-popup {
    border: 1px solid var(--gray-4);
    position: absolute;
    min-width: 220px;
    max-width: 440px;
    background: var(--gray-1);
    border-radius: var(--br-2);
    box-shadow: var(--shadow-3);
    cursor: pointer;
    padding: var(--space-12);
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: var(--space-4);
    box-sizing: border-box;
    transform: scale(0);
  }
`;

export const UserInfoPopup = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  > .ant-avatar {
    font-size: var(--fs-12);
  }
  .user-info,
  .user-info-part {
    display: flex;
    flex-direction: column;
  }
  .user-info {
    justify-content: space-evenly;
    font-size: var(--fs-12);
  }
  .name {
    color: var(--blue-8);
    font-size: var(--fs-14);
  }
  .email,
  .phone {
    color: var(--gray-9);
  }
`;
