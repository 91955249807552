import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import HideWrapper from 'components/Common/Util/HideWrapper';
import UserInfoAvatar from 'components/HigherOrderComponent/UserInfoAvatar';
import dayjs from 'dayjs';
import {
  ActivitySubcriptionType,
  LatestActivity,
  PerformedActionType,
} from 'types/api/inbox/activity';
import { UserBasic } from 'types/entities/user';
const Icon = <FontAwesomeIcon icon={['far', 'bell']} color="var(--gray-7)" />;
interface LatestActivityMessageProps {
  message: string;
  actor?: UserBasic;
  showAvatar?: boolean;
  createdAt?: string;
}

const UserNameStyle = {
  fontSize: 'var(--fs-14)',
  color: 'var(--gray-7)',
  fontWeight: 'var(--fs-semibold)',
};

const UserInfoStyle = {
  align: 'center',
  userNameTruncateWidth: '150px',
  userName: UserNameStyle,
  gapConfig: { userInfo: '0', avatar: 'var(--space-4)' },
};

function LatestActivityMessage(props: LatestActivityMessageProps) {
  const date = dayjs(props?.createdAt);

  const ActivityMessage = (
    <Flex gap="var(--space-4)" align="center">
      {Icon}
      <GrowText>{props.message}</GrowText>
    </Flex>
  );

  const ActivityProfile = (
    <Flex justify="space-between" align="center">
      <Flex gap="var(--space-4)" align="center">
        <UserInfoAvatar
          name={[props.actor?.first_name ?? '', props.actor?.last_name ?? '']}
          size="small"
          style={UserInfoStyle}
        />
        <GrowText color={'var(--gray-7)'}>{props.message}</GrowText>
      </Flex>
      <GrowText color={'var(--gray-7)'}>{isNaN(date.date()) ? '-' : date.format('h:mm A')}</GrowText>
    </Flex>
  );

  return (
    <>
      <HideWrapper hide={!props?.showAvatar}>{ActivityProfile}</HideWrapper>
      <HideWrapper hide={Boolean(props?.showAvatar)}>{ActivityMessage}</HideWrapper>
    </>
  );
}

interface LatestActivityFooterProps {
  latestActivity: LatestActivity;
}
export function LatestActivityFooter(props: LatestActivityFooterProps) {
  const { latestActivity } = props;

  if (!latestActivity.type) return null;

  const LatestActivityDescription = (() => {
    const type = latestActivity.type;

    switch (type) {
      case PerformedActionType.ASSIGNEE_CHANGED:
        return <LatestActivityMessage message="New assignee" />;
      case PerformedActionType.COMMENT_ADDED:
        return (
          <LatestActivityMessage
            message={latestActivity.showAvatar ? 'added a comment' : 'Comment added'}
            showAvatar={latestActivity.showAvatar}
            actor={latestActivity.actor}
            createdAt={latestActivity.createdDate}
          />
        );
      case PerformedActionType.STATUS_CHANGED:
        return <LatestActivityMessage message="Activity status changed" />;
      case PerformedActionType.TAGGED:
        return <LatestActivityMessage message="You’re tagged" />;
      case PerformedActionType.DATA_UPDATED:
        return <LatestActivityMessage message="Activity updated" />;
      case PerformedActionType.DATE_CHANGED:
        return <LatestActivityMessage message="New due date" />;
      case PerformedActionType.CREATED:
        return (
          <LatestActivityMessage
            message="created this activity"
            showAvatar={latestActivity.showAvatar}
            actor={latestActivity.actor}
            createdAt={latestActivity.createdDate}
          />
        );
    }
  })();

  return <>{LatestActivityDescription}</>;
}

interface DefaultLatestActivityProps {
  type: ActivitySubcriptionType;
}
export function DefaultLatestActivity(props: DefaultLatestActivityProps) {
  const subscriptionType =
    props.type === ActivitySubcriptionType.ASSIGNED ? 'Assigned' : 'Subscribed';

  const TypeText = <Texto color="--primary-7">{subscriptionType}</Texto>;

  return <Texto>You&apos;ve been {TypeText} to this activity</Texto>;
}
