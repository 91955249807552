import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial-state';
import {
  enableEmailDeliverySettigns,
  enableFollowup,
  removeDunningRule,
  removePauseRule,
  removeStopRule,
  resetStrategy,
  saveDunningRule,
  savePauseRule,
  saveStopRule,
  setShowErrors,
  toggleInvoiceFollowup,
  toggleSerialFollowup,
  toggleStatementFollowup,
  updateAutoResume,
  updateEmailDeliveryDays,
  updateEmailDeliveryTime,
  updateRecurringAction,
  updateStatementFollowupPauseRule,
  updateStrategy,
  updateTitle,
} from './reducers';

export const NEW_DUNNING_RULE_ID = 'new-dunning-rule';
export const NEW_PAUSE_RULE_ID = 'new-pause-rule';
export const NEW_DUNNING_RULE_EVENT_ID = 'new-dunning-rule-event';
export const NEW_DUNNING_RULE_ACTION_ID = 'new-dunning-rule-action';

const followupStrategySlice = createSlice({
  name: 'followup-strategy',
  initialState,
  reducers: {
    // ======================== General ======================================= //
    updateStrategy,
    resetStrategy,
    updateTitle,
    enableFollowup,
    setShowErrors,

    // ======================== Invoice Followup ======================================= //
    toggleInvoiceFollowup,
    toggleSerialFollowup,
    updateRecurringAction,
    updateAutoResume,

    // =========================== Statement Followup ==================================== //
    toggleStatementFollowup,
    updateStatementFollowupPauseRule,

    // =========================== Dunning Rules ==================================== //
    saveDunningRule,
    removeDunningRule,

    // =========================== Pause Rules ==================================== //
    savePauseRule,
    removePauseRule,

    // =========================== Stop Rules ==================================== //
    saveStopRule,
    removeStopRule,

    // =========================== Settings ==================================== //
    enableEmailDeliverySettigns,
    updateEmailDeliveryTime,
    updateEmailDeliveryDays,
  },
});

export const followupStrategyActions = followupStrategySlice.actions;

export default followupStrategySlice.reducer;
