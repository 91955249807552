import { useHandleOutsideClickRef } from 'hooks/use-outside-click';
interface ClickOutsideWrapperProps {
  children: React.ReactNode;
  handleClickOutside: () => void;
}
function ClickOutsideWrapper(props: Readonly<ClickOutsideWrapperProps>) {
  const { handleClickOutside } = props;
  const ref = useHandleOutsideClickRef<HTMLDivElement>(handleClickOutside);
  return <div ref={ref}>{props.children}</div>;
}

export default ClickOutsideWrapper;
