import { useQueryClient } from '@tanstack/react-query';
import { Empty } from 'components/BaseComponents/Empty/Empty';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import {
  CreatedBlock,
  CustomerBlock,
  LabelBlock,
  UserInfoBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { SourceAppComp } from 'components/Common/SourceApp/SourceApp';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { handleCreateCustomEventCallBack } from 'components/Inbox/CollectionActivitiesV2/hooks/useActivityUpdation';
import { RaiseADisputeActivity } from 'store/activity-feed/type';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';
import { PerformedActionType } from 'types/api/inbox/activity';
import { DisputeStatusQuickSelect } from '../Common/DisputeStatus';
import { DisputeTypes } from '../Common/DisputeType';
interface DisputeInfoProps extends ActivityDetailsBase {
  data?: RaiseADisputeActivity;
  loading: boolean;
}

function DisputeDetailsInfo(props: DisputeInfoProps) {
  const { data, loading, queryKey, activitiesMode } = props;

  const queryClient = useQueryClient();

  const Source =
    data && !loading ? (
      <HideWrapper hide={data?.source_app !== 'CUSTOMER_PORTAL'}>
        <Flex align="center" gap="var(--space-4)">
          <GrowText color="var(--gray-8)">via</GrowText>
          <SourceAppComp customer_details={data.customer}  source_app="CUSTOMER_PORTAL" />
        </Flex>
      </HideWrapper>
    ) : null;
  const DisputeStatuses =
    data && !loading ? (
      <DisputeStatusQuickSelect
        disputeActivityId={Number(data.id)}
        status={data.status}
        onChangeCallBack={(status) => {
          queryClient.refetchQueries([queryKey]);

          handleCreateCustomEventCallBack('updating_query', {
            id: data.id,
            activity: ActivityType.DISPUTE,
            value: { status, action: PerformedActionType.STATUS_CHANGED },
          });
        }}
        activitiesMode={activitiesMode}
      />
    ) : null;
  const Type =
    data && !loading ? (
      <Empty isEmpty={!data.types.length}>
        <DisputeTypes types={data.types} />
      </Empty>
    ) : null;

  const Owner =
    data && !loading ? (
      <Empty isEmpty={!data.participants.length}>
        <Flex direction="column" gap="var(--space-4)">
          {data.participants.map((user) => {
            return <UserInfoBlock user={user} key={user.id} />;
          })}
        </Flex>
      </Empty>
    ) : null;

  const Customer =
    data && !loading ? <CustomerBlock id={data.customer.id} name={data.customer.name} /> : null;
  const Created =
    data && !loading ? (
      <Flex gap={'var(--space-4)'} direction={'column'}>
        <CreatedBlock createdBy={data.created_by} createdDate={data.created_date} />
        {Source}
      </Flex>
    ) : null;

  return (
    <Flex direction="column" gap="var(--space-16)">
      <InfoContent
        content={DisputeStatuses}
        label={<LabelBlock label="Status" color="var(--gray-7)" />}
      />
      <InfoContent
        content={Type}
        label={<LabelBlock label="Dispute Types" color="var(--gray-7)" />}
      />
      <InfoContent
        content={Owner}
        label={<LabelBlock label="Dispute Owners" color="var(--gray-7)" />}
      />
      <InfoContent content={Created} label={<LabelBlock label="Created" color="var(--gray-7)" />} />
      <InfoContent
        content={Customer}
        label={<LabelBlock label="Customer" color="var(--gray-7)" />}
      />
    </Flex>
  );
}

export { DisputeDetailsInfo };
