// TYPES
type CEITileItem = {
  cei: number;
  cei_change: number;
  cei_since: number;
};

type CEIItem = {
  month: string;
  cei: number;
};

type EmployeePerfomanceAgingItem = {
  key: number;
  aging_collected: number;
  aging_collected_percent: number;
  age: string;
};

type EmployeePerformanceItem = {
  key?: number;
  user: {
    last_name: string;
    id: number;
    first_name: string;
  };
  customer_count: number;
  email_stats: {
    sent: number;
    open: number;
    responded: number;
  };
  receivables: {
    invoice_count: number;
    value: number;
  };
  collection_stats: {
    last_month_amount: number;
    amount: number;
    customer_count: number;
  };
  call_log_stats: {
    call_count: number;
    customer_count: number;
  };
  dispute_stats: {
    resolved_count: number;
    total_count: number;
  };
  due_stats: {
    last_month_amount: number;
    amount: number;
    customer_count: number;
  };
  aging?: Array<EmployeePerfomanceAgingItem>;
};

// VALUES
export const cei_tile: CEITileItem = {
  cei: 52,
  cei_change: -2,
  cei_since: 30,
};

export const cei: CEIItem[] = [
  {
    month: 'Aug 2020',
    cei: Math.round(Math.random() * 100),
  },
  {
    month: 'Sep 2020',
    cei: Math.round(Math.random() * 100),
  },
  {
    month: 'Oct 2020',
    cei: Math.round(Math.random() * 100),
  },
  {
    month: 'Nov 2020',
    cei: Math.round(Math.random() * 100),
  },
  {
    month: 'Dec 2020',
    cei: Math.round(Math.random() * 100),
  },
  {
    month: 'Jan 2021',
    cei: Math.round(Math.random() * 100),
  },
];

export const employee_performance: EmployeePerformanceItem[] = [
  {
    dispute_stats: {
      resolved_count: 7,
      total_count: 18,
    },
    call_log_stats: {
      call_count: 23,
      customer_count: 14,
    },
    collection_stats: {
      last_month_amount: 790000.0,
      amount: 828961.0,
      customer_count: 4,
    },
    due_stats: {
      last_month_amount: 102106.0,
      amount: 92106.0,
      customer_count: 2,
    },
    receivables: {
      invoice_count: 123,
      value: 921068.33,
    },
    user: {
      last_name: 'Freeman',
      id: 3,
      first_name: 'Abdul',
    },
    email_stats: {
      sent: 12,
      open: 2,
      responded: 4,
    },
    customer_count: 13,
  },
  {
    dispute_stats: {
      resolved_count: 4,
      total_count: 8,
    },
    call_log_stats: {
      call_count: 6,
      customer_count: 3,
    },
    collection_stats: {
      last_month_amount: 544332.6,
      amount: 574926.0,
      customer_count: 3,
    },
    due_stats: {
      last_month_amount: 333285.4,
      amount: 324567.76,
      customer_count: 4,
    },
    receivables: {
      invoice_count: 86,
      value: 777618.61,
    },
    user: {
      last_name: 'Taylor',
      id: 4,
      first_name: 'Serena',
    },
    email_stats: {
      sent: 8,
      open: 3,
      responded: 5,
    },
    customer_count: 10,
  },
  {
    dispute_stats: {
      resolved_count: 2,
      total_count: 5,
    },
    call_log_stats: {
      call_count: 9,
      customer_count: 4,
    },
    collection_stats: {
      last_month_amount: 555890,
      amount: 590801,
      customer_count: 5,
    },
    due_stats: {
      last_month_amount: 255080,
      amount: 230267,
      customer_count: 2,
    },
    receivables: {
      invoice_count: 53,
      value: 921068.33,
    },
    user: {
      last_name: 'Scott',
      id: 2,
      first_name: 'Lara',
    },
    email_stats: {
      sent: 6,
      open: 4,
      responded: 2,
    },
    customer_count: 11,
  },
  {
    dispute_stats: {
      resolved_count: 4,
      total_count: 7,
    },
    call_log_stats: {
      call_count: 13,
      customer_count: 4,
    },
    collection_stats: {
      last_month_amount: 456986,
      amount: 438809.5,
      customer_count: 5,
    },
    due_stats: {
      last_month_amount: 345876,
      amount: 438809.3,
      customer_count: 6,
    },
    receivables: {
      invoice_count: 72,
      value: 877618.61,
    },
    user: {
      last_name: 'Logan',
      id: 1,
      first_name: 'Kenny',
    },
    email_stats: {
      sent: 9,
      open: 5,
      responded: 4,
    },
    customer_count: 12,
  },
];
