import { PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { validateInvoiceSegment, validateRuleTitle } from 'store/invoice-segments/validations';
import {
  InvoiceSegment,
  InvoiceSegmentFilter,
  InvoiceSegmentId,
  SegmentOperator,
} from 'types/entities/invoice-segments';
import { replaceInArrayInPlace, replaceOrPushInArray } from 'util/array';
import { defaultInvoiceSegment, initialState, InitialState, NEW_SEGMENT_ID } from './initial-state';
import { identifySegmentFilter, isEqualSegmentFilters } from './utilts';

export type InvoiceSegmentFilterIdentifier = Pick<InvoiceSegmentFilter, 'type' | 'id'>;

export function toggleManagementModal(state: InitialState, action: PayloadAction<boolean>) {
  state.invoiceSegmentManagementOpen = action.payload;

  // reset other data
  state.currentSelectedSegmentId = initialState.currentSelectedSegmentId;
  state.invoiceSegmentForm = initialState.invoiceSegmentForm;
  state.errorsVisible = initialState.errorsVisible;
  state.errors = validateInvoiceSegment(initialState.invoiceSegmentForm, state.invoiceSegments);
}

export function setInvoiceSegments(state: InitialState, action: PayloadAction<InvoiceSegment[]>) {
  state.invoiceSegments = action.payload;
}

export function setCurrentActiveSegment(
  state: InitialState,
  action: PayloadAction<InvoiceSegmentId>
) {
  state.currentSelectedSegmentId = action.payload;
}

export function saveInvoiceSegment(state: InitialState, action: PayloadAction<InvoiceSegment>) {
  const updatedInvoiceSegment = action.payload;
  state.invoiceSegments = replaceOrPushInArray(
    state.invoiceSegments,
    updatedInvoiceSegment,
    (invoiceSegment) => invoiceSegment.id === updatedInvoiceSegment.id
  );
  state.currentSelectedSegmentId = updatedInvoiceSegment.id;
  state.invoiceSegmentForm = updatedInvoiceSegment;
}

export function removeInvoiceSegment(state: InitialState, action: PayloadAction<number>) {
  state.invoiceSegments = state.invoiceSegments.filter(
    (invoiceSegment) => invoiceSegment.id !== action.payload
  );

  if (state.currentSelectedSegmentId !== action.payload) {
    return;
  }
  state.currentSelectedSegmentId = initialState.currentSelectedSegmentId;
  state.invoiceSegmentForm = initialState.invoiceSegmentForm;
  state.errors = validateInvoiceSegment(state.invoiceSegmentForm, state.invoiceSegments);
}

export function cloneInvoiceSegment(state: InitialState, action: PayloadAction<number>) {
  const segmentToClone = state.invoiceSegments.find(
    (invoiceSegment) => invoiceSegment.id === action.payload
  );

  if (segmentToClone) {
    const clonedSegment = cloneDeep(segmentToClone);
    clonedSegment.id = NEW_SEGMENT_ID;
    clonedSegment.title = `${segmentToClone.title} - (cloned)`;

    state.invoiceSegmentForm = clonedSegment;
    state.currentSelectedSegmentId = NEW_SEGMENT_ID;
    state.errors = validateInvoiceSegment(state.invoiceSegmentForm, state.invoiceSegments);
  }
}

// Form Reducers

export function setInvoiceSegmentForm(state: InitialState, action: PayloadAction<InvoiceSegment>) {
  state.invoiceSegmentForm = action.payload;
  state.errors = validateInvoiceSegment(state.invoiceSegmentForm, state.invoiceSegments);
}

export function setInvoiceSegmentFormFromList(
  state: InitialState,
  action: PayloadAction<InvoiceSegmentId>
) {
  const segmentToSet =
    state.invoiceSegments.find((invoiceSegment) => invoiceSegment.id === action.payload) ??
    defaultInvoiceSegment;

  state.invoiceSegmentForm = segmentToSet;
  state.errors = validateInvoiceSegment(state.invoiceSegmentForm, state.invoiceSegments);
}

export function setInvoiceSegmentFormTitle(state: InitialState, action: PayloadAction<string>) {
  state.invoiceSegmentForm.title = action.payload;

  state.errors = {
    ...state.errors,
    ...validateRuleTitle(
      state.invoiceSegmentForm.title,
      state.invoiceSegmentForm.id,
      state.invoiceSegments
    ),
  };
}

export function setInvoiceSegmentFormOperator(
  state: InitialState,
  action: PayloadAction<SegmentOperator>
) {
  state.invoiceSegmentForm.operator = action.payload;
}

export function addInvoiceSegmentFormFilter(
  state: InitialState,
  action: PayloadAction<InvoiceSegmentFilter>
) {
  const filterToAdd = action.payload;
  const existingFilter = state.invoiceSegmentForm.filters.find((filter) =>
    isEqualSegmentFilters(filter, filterToAdd)
  );

  if (!existingFilter) {
    state.invoiceSegmentForm.filters.push(filterToAdd);
  }

  state.errors = { ...state.errors };
}

export function removeInvoiceSegmentFormFilter(
  state: InitialState,
  action: PayloadAction<InvoiceSegmentFilterIdentifier>
) {
  state.invoiceSegmentForm.filters = state.invoiceSegmentForm.filters.filter(
    (filter) => !identifySegmentFilter(filter, action.payload)
  );

  delete state.errors[action.payload.id];
}

export function updateInvoiceSegmentFormFilter(
  state: InitialState,
  action: PayloadAction<InvoiceSegmentFilter>
) {
  const filterToUpdate = action.payload;

  replaceInArrayInPlace(state.invoiceSegmentForm.filters, filterToUpdate, (filter) =>
    isEqualSegmentFilters(filterToUpdate, filter)
  );

  state.errors = { ...state.errors };
}

export function setShowErrors(state: InitialState, action: PayloadAction<boolean>) {
  state.errorsVisible = action.payload;
}

export function setErrors(state: InitialState, action: PayloadAction<InitialState['errors']>) {
  state.errors = { ...state.errors, ...action.payload };
}
