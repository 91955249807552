import { Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { ReactElement } from 'react';
import {
  AmountMaybeWithMultiCurrencySplit,
  CollectionDetails,
  OutstandingAmount,
} from 'types/common/multi-currency';
import { AccountConfigKey } from 'types/entities/account';
import { Amount } from '.';
import { InfoIcon } from '../Icons';

const t = {
  openInvoiceBalances: 'Open Invoice Balances',
  unappliedCredits: 'Unapplied Credits',
  unappliedPayments: 'Unapplied Payments',
  unpostedPayments: 'Unposted Payments',
  appliedPayments: 'Applied Payments',
  totalBalance: 'Total',
  taxes: 'Taxes',
  otherCharges: 'Other Charges',
};

interface AppliedPaymentsCreditsProps {
  appliedPayments: CollectionDetails;
  infoIcon?: React.ReactChild;
}

const popoverStyle: React.CSSProperties = {
  backgroundColor: 'var(--gray-1)',
  padding: 'var(--space-20) var(--space-16)',
};
const dividerStyle: React.CSSProperties = {
  border: '0.5px solid var(--gray-6)',
};

const SHOULD_SHOW_UNPOSTED = false;

function UnappliedPaymentsCreditsPopover({ outstanding, unposted }: UnappliedPaymentsCreditsProps) {
  const {
    outstanding_amount,
    open_invoices_balance_amount,
    un_applied_credit_amount,
    un_applied_payment_amount,
  } = outstanding;

  const shouldEvaluateTotalBalance = useConfig(AccountConfigKey.SHOULD_EVALUATE_BALANCES);

  const totalOutstandingAmount = (
    <>
      <div style={dividerStyle} />
      {outstanding_amount && (
        <AmountSplit amount={outstanding_amount} label={t.totalBalance} bold />
      )}
    </>
  );

  return (
    <Flex gap="--space-12" style={popoverStyle} direction="column">
      {open_invoices_balance_amount && (
        <AmountSplit amount={open_invoices_balance_amount.amount} label={t.openInvoiceBalances} />
      )}
      {un_applied_credit_amount && (
        <AmountSplit
          amount={un_applied_credit_amount.amount}
          label={t.unappliedCredits}
          negative={shouldEvaluateTotalBalance}
        />
      )}
      {un_applied_payment_amount && (
        <AmountSplit
          amount={un_applied_payment_amount.amount}
          label={t.unappliedPayments}
          negative={shouldEvaluateTotalBalance}
        />
      )}
      {shouldEvaluateTotalBalance && totalOutstandingAmount}

      {unposted && SHOULD_SHOW_UNPOSTED && (
        <AmountSplit
          amount={unposted.amount}
          label={`${t.unpostedPayments} (${unposted.paymentCount})`}
        />
      )}
    </Flex>
  );
}
function AppliedPaymentsCreditsPopover({ appliedPayments }: AppliedPaymentsCreditsProps) {
  const {
    total_payment_amount,
    applied_payment_amount,
    un_applied_payment_amount,
    taxes,
    other_charges,
  } = appliedPayments;

  return (
    <Flex gap="--space-12" style={popoverStyle} direction="column">
      {applied_payment_amount && (
        <AmountSplit amount={applied_payment_amount} label={t.appliedPayments} />
      )}
      {un_applied_payment_amount && (
        <AmountSplit amount={un_applied_payment_amount} label={t.unappliedPayments} />
      )}
      {taxes && <AmountSplit amount={taxes} label={t.taxes} />}
      {other_charges && <AmountSplit amount={other_charges} label={t.otherCharges} />}
      <div style={dividerStyle} />
      {total_payment_amount.amount && (
        <AmountSplit amount={total_payment_amount.amount} label={t.totalBalance} bold />
      )}
    </Flex>
  );
}

interface AmountSplitProps {
  label: string;
  amount: AmountMaybeWithMultiCurrencySplit;
  bold?: boolean;
  negative?: boolean;
  count?: number;
}

function AmountSplit({ label, amount, bold, negative, count }: AmountSplitProps) {
  const amountToDispay = {
    ...amount,
  };
  return (
    <Flex justify="space-between" gap="--space-16">
      <Texto style={{ fontWeight: `${bold ? 'var(--fs-semibold)' : 'initial'}` }}>
        {label} {count ? `(${count})` : ''}
      </Texto>
      <Flex gap="--space-12">
        <span>{negative ? `(-)` : null}</span>
        <Amount
          className={`${bold ? 'semi-bold' : ''}`}
          amount={amountToDispay.value}
          currency={amountToDispay.currency}
        />
      </Flex>
    </Flex>
  );
}

interface UnappliedPaymentsCreditsProps {
  outstanding: OutstandingAmount;
  unposted?: {
    amount: AmountMaybeWithMultiCurrencySplit;
    paymentCount: number;
  };
  infoIcon?: ReactElement;
}

export function UnappliedPaymentsCredits({
  outstanding,
  infoIcon,
  unposted,
}: UnappliedPaymentsCreditsProps) {
  return (
    <Popover
      placement="rightTop"
      content={<UnappliedPaymentsCreditsPopover unposted={unposted} outstanding={outstanding} />}
      arrow={false}
      overlayInnerStyle={{ padding: 0 }}
    >
      {infoIcon ?? <InfoIcon size="xs" />}
    </Popover>
  );
}

export function AppliedPaymentsCredits(props: AppliedPaymentsCreditsProps) {
  return (
    <Popover
      placement="rightTop"
      content={<AppliedPaymentsCreditsPopover appliedPayments={props.appliedPayments} />}
      arrow={false}
      overlayInnerStyle={{ padding: 0 }}
    >
      {props.infoIcon ?? <InfoIcon size="sm" />}
    </Popover>
  );
}
