import {
  getFileExtensionFromName,
  getFileTypeFromExtension,
} from 'components/BaseComponents/UploadFiles/utils';
import ItemContent from 'components/Common/FileUploadDrawer/FileUploader/ItemContent';
import { getDocTypeIcon } from 'components/HigherOrderComponent/DocViewer';
import { FileType } from 'types/common/attachments';
import { bytesToSize } from 'util/file-size';
import { removeFileExtension } from 'util/remove-file-extension';
import { AssociatedFile } from '../type';

interface FileItemsProps {
  file: AssociatedFile;
  onChange: (id: number | string, status: boolean) => void;
  fileIcon?: React.ReactNode;
  extra?: React.ReactNode;
}
function FileItems(props: Readonly<FileItemsProps>) {
  const fileExtension = getFileExtensionFromName(props.file.name || props.file.file_name);
  const fileName = removeFileExtension(props.file.name ?? props.file.file_name ?? '');
  const fileSize = bytesToSize(props.file.file_size);
  const fileType = getFileTypeFromExtension(fileExtension);
  const FileIcon = fileExtension
    ? getDocTypeIcon(fileType)
    : props.fileIcon
    ? props.fileIcon
    : getDocTypeIcon(FileType.PDF);

  function handleChange(status: boolean) {
    if (!props.file.id) return;
    props.onChange(props.file.id, status);
  }

  return (
    <ItemContent
      extraComp={props.extra}
      filename={fileName}
      fileSize={fileSize}
      icon={FileIcon}
      hideActions={true}
      filesCount={props.file.files}
      enabled={props.file.checked}
      onChange={handleChange}
      key={String(props.file.checked)}
    />
  );
}

export default FileItems;
