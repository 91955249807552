import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { Popconfirm, Tooltip } from 'antd';
import { PopConfirmContent } from 'components/BaseComponents/ButtonWithConfirmation/PopconfirmContent';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { notify } from 'components/BaseComponents/Notifications';
import { StyledIconButton } from 'components/Common/Styles/Styles';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { useDeleteDocumentType } from 'queries/documents/documents';
import { DocumentTypesList } from 'types/entities/documents';
import useOpen from 'util/hooks/useOpen';
import { DocumentTypesModal } from '../AddDocumentTypes/Modal/DocumentTypesModal';

const iconButtonStyles: React.CSSProperties = {
  padding: 0,
  width: '18px',
  height: '18px',
  lineHeight: '18px',
};

interface DocActionsProps {
  value: DocumentTypesList;
}
function DocActions(props: DocActionsProps) {
  const { value } = props;
  const deleteADocumentType = useDeleteDocumentType();
  const queryClient = useQueryClient();
  const { open, handleOpenChange } = useOpen({ open: false });
  const isDocumentManage = usePrivilegeStatus([ERolesAndPrivileges.MANAGE_DOCUMENT_TYPE]);
  const { open: editOpen, toggleOpen: editToggle, onClose: editClose } = useOpen({ open: false });

  function handleEdit() {
    editToggle();
  }
  function handleDelete() {
    deleteADocumentType
      .mutateAsync({ id: value.id })
      .then(() => {
        queryClient.invalidateQueries(['document-types-list']);
        notify.success('Document Type Deleted Successfully');
      })
      .catch(() => {
        notify.error('Failed To Delete');
      });
  }
  const Content = (
    <PopConfirmContent
      title="Confirm Delete"
      desc={'Do you want to delete this Document Type?'}
      titleStyles={{ color: 'var(--gold-6)', fontWeight: 'var(--fs-semibold)' }}
      descStyles={{ color: 'var(--gray-9)', fontSize: 'var(--fs-12)' }}
    />
  );

  const EditIcon = <FontAwesomeIcon icon={['far', 'pen']} color="var(--primary-7)" fontSize={16} />;
  const DeleteIcon = (
    <FontAwesomeIcon icon={['far', 'trash-alt']} color="var(--primary-7)" fontSize={16} />
  );

  const EditButton = (
    <Tooltip title="Edit">
      <StyledIconButton icon={EditIcon} style={iconButtonStyles} onClick={handleEdit} />
    </Tooltip>
  );

  const DeleteButton = (
    <HideWrapper hide={value.is_default}>
      <Popconfirm
        title={Content}
        icon={false}
        overlayClassName="ant-popover-title-no-padding"
        overlayStyle={{ width: 200 }}
        okText="Yes"
        placement="right"
        onOpenChange={handleOpenChange}
        onConfirm={handleDelete}
      >
        <Tooltip title="Delete">
          <StyledIconButton icon={DeleteIcon} style={iconButtonStyles} />
        </Tooltip>
      </Popconfirm>
    </HideWrapper>
  );
  return (
    <HideWrapper hide={!isDocumentManage}>
      <>
        <Flex
          gap="var(--space-12)"
          justify="flex-end"
          align="center"
          className={open ? '' : 'hide-actions'}
        >
          {EditButton}
          {DeleteButton}
        </Flex>
        {editOpen && (
          <DocumentTypesModal onClose={editClose} open={editOpen} value={value} mode="EDIT" />
        )}
      </>
    </HideWrapper>
  );
}

export { DocActions };
