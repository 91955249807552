import { User } from 'types/entities/user';
import { Rank } from 'types/utils/utils';

export interface Roles {
  id: number;
  name: string;
  description: string;
  users: User[];
  display_name: string;
}

export interface RolesState {
  defaultRoles: Roles[];
}

export interface DeleteUserRole {
  roleId: number;
  userId: number;
}

export enum ERoles {
  ADMIN = 'ADMIN',
  COLLECTION_HEAD = 'COLLECTION_HEAD',
  COLLECTION_SPECIALIST = 'COLLECTION_SPECIALIST',
  COLLABORATOR = 'COLLABORATOR',
  SCOPED_ADMIN = 'SCOPED_ADMIN',
}

export const RolesRank: Rank = {
  ADMIN: 1,
  SCOPED_ADMIN: 2,
  COLLECTION_HEAD: 3,
  SALES_MANAGER: 4,
  COLLECTION_SPECIALIST: 5,
  CUSTOMER_POINT_OF_CONTACT: 6,
};
