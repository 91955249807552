import { FormInstance, UploadFile } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import {
  useGetRecentUsers,
  useInternalContactForEntity,
} from 'components/Common/Contact/ContactType';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import {
  InternalContactRecepientProps,
  getGroupTemplate,
} from 'components/HigherOrderComponent/KeyActivitesContainer/Email/NewEmail/util';
import { stakeHolderListAttributes } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import dayjs from 'dayjs';
import {
  Dictionary,
  assign,
  chain,
  cloneDeep,
  flatMap,
  forEach,
  get,
  isArray,
  isObject,
  isUndefined,
  map,
  mapValues,
  mergeWith,
  omitBy,
  orderBy,
  set,
  some,
  unionBy,
  uniqBy,
} from 'lodash';
import { useMemo } from 'react';
import { AssignedToUsers } from 'store/activity-feed/type';
import { EmailableType } from 'types/activities/email';
import { Attachment } from 'types/common/attachments';
import { BaseRecepientsType } from 'types/entities/collection-strategy/contact-type';
import { DisputeType } from 'types/entities/dispute-type';
import { InvoiceStatus } from 'types/entities/invoice';
import { User, UserBasic } from 'types/entities/user';
import { pruneObject } from 'util/json-utils';
import transformData from 'util/transformData';
import { CLOSED_INVOICE_TYPES } from './predicate';
import {
  CustomActivityProps,
  DisputeTypeMapping,
  FormItemNames,
  InvoiceFieldMapping,
  ParseDateTransformSchemaProps,
  ParseTransformSchemaProps,
} from './types';

interface useComputeInternalRecepientProps {
  entity: ActionableEntity;
}

/**
 * Transforms a list of stakeholder list attributes into grouped options based on a predefined template.
 *
 * This function takes an array of `stakeHolderListAttributes` and:
 * 1. Initializes grouped options using a predefined template obtained from `getGroupTemplate()`.
 * 2. Adds unique options to the 'OTHERS' group within the grouped options, ensuring no duplicates based on 'id'.
 *
 * @param {stakeHolderListAttributes[]} options - The array of stakeholder list attributes to transform into grouped options.
 * @returns {ReturnType<typeof getGroupTemplate>} - The grouped options object structured according to the template.
 */
export function transformToGroupOptions(
  options: stakeHolderListAttributes[]
): ReturnType<typeof getGroupTemplate> {
  const groupedOptions = getGroupTemplate();

  //get unique recipientOptionsList from search
  const uniqueOptions = uniqBy(options, 'id');
  uniqueOptions?.forEach((option) => {
    groupedOptions['OTHERS'].options.push(option);
  });

  return groupedOptions;
}

export type AssignedToGroupedOptions = ReturnType<typeof transformToGroupOptions>;

/**
 * Formats a list of users into a standardized structure suitable for display in a stakeholder list.
 *
 * This function takes an array of user objects (or a single user object) and:
 * 1. Ensures the input is an array.
 * 2. Removes duplicate users based on their email addresses.
 * 3. Filters out users with empty email addresses.
 * 4. Maps the unique and valid users to a standardized format.
 *
 * @param {User[] | User | UserBasic | UserBasic[]} userList - The list of user objects to be formatted.
 * @returns {stakeHolderListAttributes[]} - An array of formatted user objects.
 */

export const getFormatedRecentUsers = (
  userList: User[] | User | UserBasic | UserBasic[]
): stakeHolderListAttributes[] => {
  if (!Array.isArray(userList)) {
    return [];
  }

  const uniqueUserList = uniqBy(userList, 'email').filter((user) => !!user.email.trim());

  return uniqueUserList?.map((user) => {
    return {
      email: user.email,
      type: EmailableType.USER,
      label: `${user.first_name} ${user.last_name} (${user.email})`,
      value: user.id,
      id: user.id,
      key: user.id,
    };
  });
};

/**
 * Transforms a list of assigned users into a standardized format based on the given context.
 *
 * This function maps through the provided `usersList` and transforms each user object
 * into a standardized `AssignedToUsers` format. It sets common properties, handles specific
 * transformations based on user type and context, and prunes any undefined properties from the
 * resulting objects.
 *
 * @param {AssignedToUsers[]} usersList - The list of users to be transformed.
 * @param {string} [context] - An optional context to determine specific transformations (e.g., 'STRATERGY').
 * @returns {AssignedToUsers[]} - The transformed list of users in the `AssignedToUsers` format.
 */
export function InternalAssignedToRecepient(
  usersList: AssignedToUsers[],
  context?: string
): AssignedToUsers[] {
  return usersList.map((item) => {
    const transformedObj = {} as AssignedToUsers;

    //common
    transformedObj['type'] = BaseRecepientsType.USER;

    if (item.user_type) {
      transformedObj['association_level'] = item?.association_level;
      transformedObj['email'] = item.email;
    } else {
      transformedObj['id'] = item.value as number;
    }

    if (context === 'STRATERGY' && item.user_type) {
      transformedObj['value'] = item.user_type;
      transformedObj['type'] = BaseRecepientsType.POC;
    }
    if (context !== 'STRATERGY' && item.user_type) {
      transformedObj['user_type'] = item?.user_type;
    }

    return pruneObject(transformedObj) as AssignedToUsers;
  });
}

/**
 * Computes and returns grouped internal contact recipients based on the provided entity type.
 *
 * This hook fetches internal contacts for the specified entity (`CUSTOMER` by default) and `INVOICE`,
 * along with recent users, and groups them into a structure suitable for selection components.
 * It also tracks the loading state of the internal contact data.
 *
 * @param {useComputeInternalRecepientProps} props - The properties required to compute internal contact recipients.
 * @param {ActionableEntity} [props.entity=ActionableEntity.CUSTOMER] - The type of entity to compute internal contacts for.
 * @returns {InternalContactRecepientProps} - An object containing:
 *   - `computedGroupedOptions` {Record<string, any>} - The grouped internal contact options.
 *   - `isLoading` {boolean} - A flag indicating whether the internal contact data is still loading.
 */
export function useComputeInternalContactRecepient({
  entity = ActionableEntity.CUSTOMER,
}: useComputeInternalRecepientProps): InternalContactRecepientProps {
  const { internalContact: customerInternalContact, isLoading: customerContactLoading } =
    useInternalContactForEntity(ActionableEntity.CUSTOMER);
  const { internalContact: invoiceInternalContact, isLoading: invoiceContactLoading } =
    useInternalContactForEntity(ActionableEntity.INVOICE);
  const recentUsers = useGetRecentUsers();

  const groupedSingleOptions = useMemo(() => {
    const groupedOptions = getGroupTemplate();

    const conditions = [
      {
        path: 'CUSTOMER.options',
        condition: customerInternalContact?.length,
        value: customerInternalContact,
      },
      {
        path: 'INVOICE.options',
        condition: entity !== ActionableEntity.CUSTOMER && !!invoiceInternalContact?.length,
        value: invoiceInternalContact,
      },
      { path: 'OTHERS.options', condition: recentUsers?.length, value: recentUsers },
    ];

    conditions.forEach(({ path, condition, value }) => {
      if (condition) {
        set(groupedOptions, path, value);
      }
    });

    return { groupedOptions, loading: customerContactLoading || invoiceContactLoading };
  }, [
    customerContactLoading,
    customerInternalContact,
    entity,
    invoiceContactLoading,
    invoiceInternalContact,
    recentUsers,
  ]);

  const computedGroupedOptions = useMemo(() => {
    let options = {} as ReturnType<typeof transformToGroupOptions>;
    options = groupedSingleOptions.groupedOptions;
    return options;
  }, [groupedSingleOptions]);

  return { computedGroupedOptions, isLoading: groupedSingleOptions.loading };
}

/**
 * Extracts and categorizes dispute types into active and archived lists.
 *
 * This function filters the provided list of dispute types into two separate lists:
 * one for active dispute types (those not archived) and one for archived dispute types.
 * It also determines whether to show the active list or the archived list based on their lengths.
 *
 * @template T - The type of the dispute types, extending an object with an `archived` boolean property.
 * @param {T[]} disputeTypes - The array of dispute types to be categorized.
 * @returns {Object} - An object containing:
 *   - `activeDisputeTypes` {T[]} - The list of active dispute types.
 *   - `archivedDisputeTypes` {T[]} - The list of archived dispute types.
 *   - `showActiveList` {boolean} - A flag indicating whether to show the active list.
 *   - `showArchivedList` {boolean} - A flag indicating whether to show the archived list.
 */
export function disputeTypeListExtract<T extends { archived: boolean }>(disputeTypes: T[]) {
  const activeDisputeTypes = disputeTypes?.filter((disputeType) => !disputeType.archived);
  const archivedDisputeTypes = disputeTypes?.filter((disputeType) => disputeType.archived);
  const showActiveList = !!activeDisputeTypes?.length;
  const showArchivedList = !showActiveList && !!archivedDisputeTypes?.length;

  return { activeDisputeTypes, archivedDisputeTypes, showActiveList, showArchivedList };
}

export function mapTypesList<T>(disputeTypes: DefaultOptionType[]): Map<number, T> {
  const mapTypeLists = new Map();

  forEach(disputeTypes, (disputeType) =>
    forEach(disputeType.options, (item: DisputeType) => mapTypeLists.set(item.id, item))
  );

  return mapTypeLists;
}

/**
 * Finds and returns options from nested data that match specified selected values.
 *
 * This function flattens the nested `options` arrays within the provided `data` array
 * and filters the options to include only those whose `id` matches any of the `selectedValues`.
 *
 * @param {DefaultOptionType[]} [data] - The array of data containing nested options to search through.
 * @param {number[]} [selectedValues] - The array of selected values to match against the options' `id` properties.
 * @returns {DefaultOptionType[]} - An array of options that match the selected values.
 */
export function findOptions(
  data?: DefaultOptionType[],
  selectedValues?: number[]
): DefaultOptionType[] {
  return flatMap(data, 'options').filter((option: DefaultOptionType) =>
    selectedValues?.includes(option.id)
  );
}

export async function onFormSave(form: FormInstance) {
  form
    .validateFields()
    .then(() => {
      form.submit();
    })
    .catch((err) => {});
}

export function parseTransformSchema<T>(props: ParseTransformSchemaProps<T>) {
  const { data, type, responseSchema, customTransformer } = props;

  const result = transformData<Record<FormItemNames, any>, T, CustomActivityProps>({
    originalData: data,
    schemaMapping: responseSchema as Record<string, any>,
    customTransformers: customTransformer,
    customTransformerProps: { type } as CustomActivityProps,
  });

  return omitUndefined(result);
}

function hasKey(obj: any): obj is Dictionary<any> {
  return typeof obj === 'object' && obj !== null;
}

/**
 * Transforms an input data object by parsing specified date keys and applying custom transformations
 * based on a provided schema.
 *
 * This function deep clones the input data object, formats the specified date keys to 'YYYY-MM-DD',
 * and applies additional transformations based on a provided response schema and custom transformers.
 * It then omits any undefined values from the resulting object.
 *
 * @template T - The type of the input data.
 * @template U - The type of the response schema.
 * @param {ParseDateTransformSchemaProps<T, U>} props - The properties required for date parsing and data transformation.
 * @param {T} props.data - The input data object to be transformed.
 * @param {string} props.type - The type of the transformation.
 * @param {U} props.responseSchema - The schema used for transforming the data.
 * @param {string[]} props.dateKey - The keys in the data object that should be parsed as dates.
 * @param {Function} props.customTransformer - The custom transformer function to be applied to the data.
 * @param {any} props.associatedData - Additional data associated with the transformation.
 * @param {string} [props.parentType] - The parent type for the transformation.
 * @returns {T | undefined} - The transformed data object with parsed dates and custom transformations applied, with undefined values omitted.
 */
export function parseDateTransformSchema<T, U>(props: ParseDateTransformSchemaProps<T, U>) {
  const { data, type, responseSchema, dateKey, customTransformer, associatedData, parentType } =
    props;

  if (!dateKey || !hasKey(data)) return;

  const parsedData = cloneDeep(data);

  dateKey.forEach((item) => {
    if (item in data) {
      const dateValue = data[item];
      parsedData[item] = dayjs(dateValue).format('YYYY-MM-DD');
    }
  });

  const result = transformData<Record<FormItemNames, any>, T, CustomActivityProps>({
    originalData: parsedData,
    schemaMapping: responseSchema as Record<string, any>,
    customTransformers: customTransformer,
    customTransformerProps: { type, associatedData, parentType } as CustomActivityProps,
  });

  return omitUndefined(result);
}

export function isDocumentData(data: any): data is { list: typeof data } {
  return data && Array.isArray(data.list);
}

/**
 * Recursively omits properties with undefined values from an object or array up to a specified depth.
 *
 * This function creates a deep clone of the input object or array and removes all properties
 * with undefined values, traversing nested structures up to the specified depth limit.
 *
 * @template T - The type of the input object or array.
 * @param {T} obj - The object or array from which to omit undefined values.
 * @param {number} [depthLimit=2] - The maximum depth to traverse for omitting undefined values. Defaults to 2.
 * @returns {T} - A new object or array with undefined values omitted up to the specified depth.
 */
function omitUndefined<T>(obj: T, depthLimit = 2) {
  function omitRecursively(value: T, depth: number): T {
    if (depth > depthLimit) {
      return value;
    }

    if (isArray(value)) {
      return value.map((item) => omitRecursively(item as unknown as T, depth + 1)) as unknown as T;
    } else if (isObject(value)) {
      const mappedValues = mapValues(value, (val) =>
        omitRecursively(val as unknown as T, depth + 1)
      );
      const omittedValues = omitBy(mappedValues, (val) => isUndefined(val));
      return omittedValues as T;
    }

    return value;
  }

  return omitRecursively(cloneDeep(obj), 0);
}

export function extractFileUploadIds(filesList: (UploadFile<any> | Attachment)[]) {
  return map(filesList, (item) => {
    if ('response' in item && item.response) {
      return item.response.id;
    } else if ('id' in item) {
      return item.id;
    } else {
      return;
    }
  }).filter((id) => id !== undefined);
}

export function modifyUploadFiles<T>(extractKey: string, data: T) {
  const values = cloneDeep(data);
  const uploadIds: number[] = extractFileUploadIds(get(values, extractKey));
  return assign({}, data, { [extractKey]: uploadIds });
}

export function getInvoiceGroupType(invoiceStatus: InvoiceStatus) {
  return CLOSED_INVOICE_TYPES.includes(invoiceStatus) ? 'CLOSED' : 'OPEN';
}

/**
 * Combines selected invoice data with existing data and orders the result.
 *
 * This function merges the `selectedInvoices` array with the `data` array, ensuring
 * uniqueness based on the `id` property. It then orders the combined array,
 * placing selected invoices at the top.
 *
 * @template T - The type of the invoice data objects.
 * @param {T[]} [data] - The existing array of invoice data.
 * @param {T[]} [selectedInvoices] - The array of selected invoice data.
 * @returns {T[]} - The combined and ordered array of invoice data.
 */
export function updateInvoiceData<T>(data?: T[], selectedInvoices?: T[]) {
  return chain(selectedInvoices)
    .unionBy(data, 'id')
    .orderBy((item) => some(selectedInvoices, { id: (item as { id: number }).id }), ['desc'])
    .value();
}

/**
 * Customizer function for merging two arrays of `InvoiceFieldMapping` objects.
 * It is designed to be used with lodash's `mergeWith` function.
 *
 * If the provided `objValue` is an array, this function merges `objValue` and `srcValue` arrays,
 * ensuring that the objects in the resulting array are unique based on their `value` property.
 *
 * @param {InvoiceFieldMapping[]} objValue - The destination array of `InvoiceFieldMapping` objects.
 * @param {InvoiceFieldMapping[]} srcValue - The source array of `InvoiceFieldMapping` objects.
 * @returns {InvoiceFieldMapping[] | undefined} - The merged array of `InvoiceFieldMapping` objects,
 *                                               or `undefined` if `objValue` is not an array.
 */
export function invoiceSelectCustomizer(
  objValue: InvoiceFieldMapping[],
  srcValue: InvoiceFieldMapping[]
) {
  if (isArray(objValue)) {
    return unionBy(objValue, srcValue, 'value');
  }
}

export function disputeTypeSelectCustomizer(
  objValue: DisputeTypeMapping[],
  srcValue: DisputeTypeMapping[]
) {
  if (isArray(objValue)) {
    return unionBy(objValue, srcValue, 'id');
  }
}

/**
 * Initializes and merges two arrays of select options using a custom merge function.
 * The merged options are then ordered, with selected values being prioritized.
 *
 * @template T - The type of the customizer function.
 * @param {DefaultOptionType[]} options - The initial array of select options.
 * @param {DefaultOptionType[]} optionsList - The array of select options to merge with the initial options.
 * @param {T} customizer - A custom merge function used to merge the options.
 * @param {string} optionsKeys - The key to access the option values for ordering.
 * @param {number[]} [selectedValues] - An optional array of selected values to prioritize in the ordering.
 * @returns {DefaultOptionType[]} - The merged and ordered array of select options.
 */
export function selectOptionsInitializer<T>(
  options: DefaultOptionType[],
  optionsList: DefaultOptionType[],
  customizer: T,
  optionsKeys: string,
  selectedValues?: number[]
) {
  const mergedOptions = mergeWith(cloneDeep(options), optionsList, customizer);

  return map(mergedOptions, (option) => ({
    key: option.key,
    label: option.label,
    options: orderBy(option.options, (opt) => {
      return !selectedValues?.includes(opt[optionsKeys]);
    }),
  }));
}
