import { Invoice, InvoiceLineItem } from 'types/entities/invoice';
import { User } from 'types/entities/user';
import { ActivityBaseWithAttachments } from './activity';
import { StatusTagStyle } from 'components/Common/StatusCell/StatusCell';
import { AssignedToUsers } from 'store/activity-feed/type';

export enum EscalationType {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export type EscalationStatusConfig = Record<
  EscalationType,
  { label: string; style: StatusTagStyle }
>;
export const EscalationTypeMenu = [
  {
    label: 'Open',
    value: EscalationType.OPEN,
    color: 'red',
  },
  {
    label: 'Closed',
    value: EscalationType.CLOSED,
    color: 'green',
  },
];
export interface Escalation extends ActivityBaseWithAttachments {
  id: number;
  description: string;
  status: EscalationType;
  invoices?: Invoice[];
  invoice_line_items?: InvoiceLineItem[];
  assigned_to_list: User[];
  participant_config?: AssignedToUsers[];
}

export const escalationStatusConfig: EscalationStatusConfig = {
  OPEN: {
    label: 'Open',
    style: {
      backgroundColor: 'var(--red-1)',
      textColor: 'var(--red-7)',
    },
  },

  CLOSED: {
    label: 'Closed',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-8)',
    },
  },
};
