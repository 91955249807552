import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  authenticationSelector,
  currentToken,
  updateToken,
} from 'store/authentication/authentication';
import { IS_DISABLED, IS_PROD } from 'util/http-utils';
import { IHeap } from './heap/heap-wrapper';

declare global {
  interface Window {
    FreshworksWidget: any;
    heap: IHeap;
    Headway?: {
      init: (config: any) => void;
    };
    fwSettings: {
      widget_id: number;
    };
  }
}

const WIDGET_TAG_ID = 'fw-widget-script';
const WIDGET_KEY = Number(import.meta.env.VITE_FW_HELP_WIDGET);

export function useHelpWidget() {
  const authState = useSelector(authenticationSelector);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector(currentToken);
  const dispatch = useDispatch();
  useEffect(() => {
    const existingScript = document.getElementById(WIDGET_TAG_ID);
    if (existingScript || !IS_PROD || IS_DISABLED) {
      return;
    }
    setIsLoading(true);
    window.fwSettings = {
      widget_id: WIDGET_KEY,
    };
    // Original code copied from portal
    const script = document.createElement('script');
    script.src = `https://widget.freshworks.com/widgets/${WIDGET_KEY}.js`;
    script.id = WIDGET_TAG_ID;
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      const authenticateCallback = function () {
        fetch(`/api/users/current-user`)
          .then((response) => response.json())
          .then((response) => {
            dispatch(updateToken(response?.help_center_auth_token));
            window.FreshworksWidget('authenticate', {
              token: response?.help_center_auth_token,
            });
          });
      };
      window.FreshworksWidget('authenticate', {
        token: token,
        callback: authenticateCallback,
      });
      setIsLoading(false);
    };
  }, [dispatch, token]);

  const loadWidget = useCallback(
    (callback: () => void) => {
      const existingScript = document.getElementById(WIDGET_TAG_ID);
      if (existingScript && !isLoading) {
        callback();
      }
    },
    [isLoading]
  );

  const openWidget = () => {
    loadWidget(() => {
      if (!authState) {
        return;
      }
      window.FreshworksWidget('open');
      // To prefill user details

      window.FreshworksWidget('identify', 'ticketForm', {
        name: authState.user?.first_name,
        email: authState.user?.email,
      });

      window.FreshworksWidget('disable', 'ticketForm', ['name', 'email']);
    });
  };

  return { openWidget, isLoading };
}

/*
    Related doc: 
    https://developers.freshdesk.com/widget-api
*/
