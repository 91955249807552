import { themeConfig } from '@sinecycle/growcomponents';
import { App as AntdApp, ConfigProvider } from 'antd';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppInit } from 'router/hooks/useAppInit';
import { PageSuspenseFallback } from 'router/PageContainer';
import { AppRoutes } from 'router/Router';

function App() {
  useAppInit();

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          ...themeConfig,
          components: {
            ...themeConfig.components,
          },
        }}
      >
        <Suspense fallback={<PageSuspenseFallback />}>
          <AntdApp>
            <AppRoutes />
          </AntdApp>
        </Suspense>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
