import { Texto } from 'components/BaseComponents/Typography/Texto';

interface FilterViewDescProps {
  viewDesc: string | undefined;
  hide?: boolean;
  toggleOpen: () => void;
}

function FilterViewDesc(props: Readonly<FilterViewDescProps>) {
  if (props.hide || !props.viewDesc) return null;
  return (
    <div className="cursor-pinter" onClick={props.toggleOpen}>
      <Texto as="div" color="var(--gray-9)" size="14" truncate="450px">
        {props.viewDesc}
      </Texto>
    </div>
  );
}

export default FilterViewDesc;
