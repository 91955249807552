import {
  blue,
  cyan,
  geekblue,
  gold,
  grey,
  magenta,
  purple,
  red,
  volcano,
  yellow,
} from '@ant-design/colors';
import { ColorPicker, theme } from 'antd';

interface GrowPickerProps {
  showPicker?: boolean;
  overlay?: React.ReactNode;
  value?: string;
  onChange: (value: string) => void;
  defaultValue?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}
function GrowPicker(props: GrowPickerProps) {
  const { token } = theme.useToken();

  const generatePresets = () => [
    {
      label: 'Presets',
      colors: [
        red[5],
        blue[5],
        geekblue[5],
        volcano[5],
        grey[5],
        magenta[5],
        purple[5],
        yellow[5],
        gold[5],
        cyan[5],
        grey[0],
        grey[7],
        grey[9],
      ],
    },
  ];

  const presets = generatePresets();

  return props.overlay ? (
    <ColorPicker
      defaultValue={props.defaultValue ? props.defaultValue : token.colorPrimary}
      value={props.value}
      presets={presets}
      onChange={(color) => {
        props.onChange(color.toHexString());
      }}
      open={props.open}
      onOpenChange={props.onOpenChange}
    >
      <div>{props.overlay}</div>
    </ColorPicker>
  ) : (
    <ColorPicker
      defaultValue={props.defaultValue ? props.defaultValue : token.colorPrimary}
      value={props.value}
      onChange={(color) => {
        props.onChange(color.toHexString());
      }}
      presets={presets}
      open={props.open}
      onOpenChange={props.onOpenChange}
    />
  );
}

export { GrowPicker };
