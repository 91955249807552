import { AssignedToUsers } from 'store/activity-feed/type';
import { Attachments } from 'store/email/types';
import { RelativeContact } from './contact-type';

export enum FollowupRuleActionType {
  EMAIL = 'EMAIL',
  TASK = 'TASK',
  ESCALATION = 'ESCALATION',
}

export interface FileUploadData {
  uploadId: number;
  fileName: string;
  fileSize: number | undefined;
  fileObjectUrl: string;
}

export interface EmailActionData {
  from: RelativeContact;
  reply_to?: RelativeContact;
  to: RelativeContact[];
  cc: RelativeContact[];
  bcc: RelativeContact[];
  subject: string;
  body: string;

  attachments?: Attachments;
  fileUploads?: FileUploadData[];
  invoice_delivery?: boolean;
}

export interface ValidEmailActionData extends Partial<EmailActionData> {
  from: EmailActionData['from'];
  to: EmailActionData['to'];
  subject: EmailActionData['subject'];
  body: EmailActionData['body'];
}

export interface TaskActionData {
  assign_to: AssignedToUsers[];
  title: string;
  description: string;
  due_date_offset: number;
}

export interface EscalationActionData {
  assign_to: AssignedToUsers[];
  description: string;
}

interface BaseRuleAction {
  id: number | string;
  type: FollowupRuleActionType;
  data: unknown;
}

type PartialActionData<T extends BaseRuleAction> = {
  id: T['id'];
  type: T['type'];
  data: Partial<T['data']>;
};

interface EmailRuleAction extends BaseRuleAction {
  type: FollowupRuleActionType.EMAIL;
  data: EmailActionData;
}

interface TaskRuleAction extends BaseRuleAction {
  type: FollowupRuleActionType.TASK;
  data: TaskActionData;
}

interface EscalationRuleAction extends BaseRuleAction {
  type: FollowupRuleActionType.ESCALATION;
  data: EscalationActionData;
}

export type FollowupRuleAction = EmailRuleAction | TaskRuleAction | EscalationRuleAction;

export type FollowupRuleActionForm =
  | PartialActionData<EmailRuleAction>
  | PartialActionData<TaskRuleAction>
  | PartialActionData<EscalationRuleAction>;
