import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { customerNameFormatter } from '@sinecycle/growcomponents';
import { Divider, List, Skeleton, Tag, Typography } from 'antd';
import { DateText } from 'components/BaseComponents/Date/DateText';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { VIEW_TYPE } from 'components/Common/hooks/useEntitySearchParams';
import InvoiceDetailsList from 'components/HigherOrderComponent/InvoiceList';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { PageLink } from 'lib/router';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { usePendingIssuesQuery } from 'queries/admin-dashboard';
import React from 'react';
import { ELastContactActivity } from 'store/activity-feed/type';
import { LongPendingIssue } from 'types/api/admin-dashboard';
import { StatementViews } from 'types/entities/invoice';
import { dashboardTexts } from '../texts';
import { BodyText, DaysSinceText, ListItemParagraph } from './style';

const { Text, Link: LinkText } = Typography;
const componentTexts = dashboardTexts.pendingIssues;

function PendingIssues({ filterParams }: DashboardComponentProps) {
  const { data: longPendingIssues, isLoading: loading } = usePendingIssuesQuery({
    params: filterParams,
  });

  if (loading) return <Skeleton />;
  if (!longPendingIssues) return null;

  return (
    <>
      <GrowText size={'var(--fs-20)'} color="var(--gray-9)" strong>
        {componentTexts.title}
      </GrowText>
      <Divider style={{ color: 'var(--gray-8)', margin: '16px 0 12px' }} />
      <div style={{ maxHeight: '400px', overflow: 'scroll' }}>
        <List
          itemLayout="horizontal"
          dataSource={longPendingIssues}
          renderItem={(item) => <PendingIssue issue={item} />}
        />
      </div>
    </>
  );
}

function PendingIssue({ issue }: { issue: LongPendingIssue }) {
  let icon: IconProp = ['far', 'comment-times'];
  const customerNameText = customerNameFormatter({
    isUnnamedCustomer: true,
    name: issue.customer.name,
  });
  switch (issue.type) {
    case ELastContactActivity.ESCALATION:
      icon = ['far', 'exclamation-circle'];
      break;
    case ELastContactActivity.CALL_LOG:
      break;
    case ELastContactActivity.EMAIL:
      icon = ['far', 'comment-times'];
      break;
  }

  const customerDetailsPageUrlSearchParams = `${EActivityQueryParams.CURRENT_ACTIVITY_TAB}=${issue.type}&${EActivityQueryParams.ACTIVITY_ID}=${issue.id}&${EActivityQueryParams.CURRENT_SECTION}=${StatementViews.ACTIVITIES}&${EActivityQueryParams.ACTIVITY_TYPE}=${issue.type}&${EActivityQueryParams.VIEW}=${VIEW_TYPE.MODAL}&${EActivityQueryParams.CUSTOMER_ID}=${issue.customer.id}&${EActivityQueryParams.CUSTOMER}=${issue.customer.name}`;
  const IssueTitle = (
    <ListItemParagraph style={{ marginBottom: 0 }}>
      <DaysSinceText style={{ marginRight: 'var(--space-8)' }}>
        {`${Math.round(
          (new Date().getTime() - new Date(issue.created_at).getTime()) / (1000 * 60 * 60 * 24)
        )} days old`}
      </DaysSinceText>
      <Text type="secondary" style={{ marginRight: 'var(--space-4)' }}>
        {componentTexts.since}
      </Text>

      <DateText
        value={issue.created_at}
        textProps={{
          style: { marginRight: 'var(--space-24)', color: 'var(--gray-8)' },
        }}
      />

      <LinkText style={{ display: 'block' }}>{issue.title}</LinkText>
    </ListItemParagraph>
  );

  const CustomerNameLink = (
    <PageLink
      appPage="CUSTOMER_DETAILS"
      pathParams={{ id: issue.customer.id }}
      state={{ addPrimaryPathAsChildPath: true, customer_id: issue.id }}
      searchParams={customerDetailsPageUrlSearchParams}
    >
      <Tag
        color="var(--gray-3)"
        style={{
          padding: 'var(--space-4) var(--space-8)',
          borderRadius: 'var(--br-2)',
        }}
      >
        <GrowText
          style={{ maxWidth: '150px' }}
          ellipsis={{ tooltip: customerNameText }}
          color={'var(--primary-7)'}
        >
          {customerNameText}
        </GrowText>
      </Tag>
    </PageLink>
  );

  const ActorName = (
    <Flex align="center" gap="var(--space-4)">
      <GrowText>{componentTexts.by}</GrowText>
      <GrowText strong>{issue.created_by.first_name}</GrowText>
    </Flex>
  );

  const InvoiceName = (
    <GrowText color={'var(--gray-8)'}>
      <Flex gap={'var(--space-4)'}>
        {componentTexts.invoiceCountLabel}
        <InvoiceDetailsList invoiceList={issue.invoices} openNewPage={true} />
      </Flex>
    </GrowText>
  );

  const Content = (
    <Flex gap="var(--space-12)" align="baseline">
      {CustomerNameLink}
      {ActorName}
      {InvoiceName}
    </Flex>
  );

  const IssueDescription = (
    <Flex direction="column" gap="var(--space-12)">
      {Content}
      <BodyText style={{ marginBottom: 0 }}>{issue.description}</BodyText>
    </Flex>
  );

  return (
    <List.Item
      style={{ marginBottom: 'var(--space-24)', alignItems: 'start' }}
      extra={[
        <PageLink
          key={1}
          appPage="CUSTOMER_DETAILS"
          pathParams={{ id: issue.customer.id }}
          searchParams={customerDetailsPageUrlSearchParams}
          state={{ addPrimaryPathAsChildPath: true }}
        >
          {componentTexts.viewThread}
        </PageLink>,
      ]}
    >
      <List.Item.Meta
        avatar={<FontAwesomeIcon icon={icon} color="var(--red-7)" size="lg" />}
        title={IssueTitle}
        description={IssueDescription}
      />
    </List.Item>
  );
}

export default React.memo(PendingIssues);
