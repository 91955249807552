export class IdGenerator {
  ruleIdCounter: number;
  prefix: string;

  constructor(prefix: string) {
    this.ruleIdCounter = 0;
    this.prefix = prefix;
  }

  getNextId() {
    const nextCount = this.ruleIdCounter + 1;
    this.ruleIdCounter = nextCount;
    return `${this.prefix}-${nextCount}`;
  }
}
