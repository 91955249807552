import { usePageNavigate } from 'lib/router';
import { useCallback, useEffect, useState } from 'react';
import { SpotlightResultItem } from './adapters';
import { EntityDisplay } from './EntityUI';

interface ResultListProps {
  results: SpotlightResultItem[];
  closeSpotlight: () => any;
}

export default function ResultList(props: ResultListProps) {
  const { results, closeSpotlight } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const navigate = usePageNavigate();

  useEffect(() => {
    setSelectedIndex(0);
  }, []);

  // pick the last item if results is less than previously selected
  useEffect(() => {
    if (selectedIndex >= results.length) {
      setSelectedIndex(Math.max(results.length - 1, 0));
    }
  }, [selectedIndex, results]);

  const handleShortCut = useCallback(
    (e) => {
      if (e.key === 'ArrowUp') {
        selectedIndex !== 0 && setSelectedIndex(selectedIndex - 1);
        e.preventDefault();
      } else if (e.key === 'ArrowDown') {
        selectedIndex < results.length - 1 && setSelectedIndex(selectedIndex + 1);
        e.preventDefault();
      } else if (e.key === 'Enter') {
        e.preventDefault();

        const resultItem = results[selectedIndex];
        navigate(resultItem.link);
        closeSpotlight();
      }
    },
    [selectedIndex, closeSpotlight, navigate, results]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleShortCut);
    return () => {
      document.removeEventListener('keydown', handleShortCut);
    };
  }, [handleShortCut]);

  return (
    <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
      {results.map((result, index) => (
        <EntityDisplay
          hoverSelect={() => {
            setSelectedIndex(index);
          }}
          onClick={() => {
            navigate(result.link);
            closeSpotlight();
          }}
          isSelected={index === selectedIndex}
          key={result.type + result.id}
          icon={result.icon}
          title={result.title}
        />
      ))}
    </div>
  );
}
