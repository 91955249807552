import { Skeleton } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { StyledCardWrapper } from '../../style';
import { BaseCurrencySymbolProps } from '../../type';
import { useGetAccountSource } from '../Hooks/CurrencyHooks';
import { CurrencyExchangeTab } from './CurrencyExchangeTab';
const t = {
  title: 'Currency Exchange Configuration',
  description: `Growfin allows you to choose the source of the exchange rate with which the currency conversion has to be done. The dates of exchange rate will be considered as 'Issue date' for Invoice, 'Transaction date' for Payments & Credits.`,
};

export default function CurrencyExchangeConfig(props: BaseCurrencySymbolProps) {
  const { baseCurrency } = props;
  const { data, isLoading } = useGetAccountSource();
  const CardHeader = (
    <GrowText
      style={{ lineHeight: 'var(--lh-16)' }}
      size="var(--fs-20)"
      weight="var(--fs-semibold)"
      color="var( --gray-9)"
    >
      {t.title}
    </GrowText>
  );

  const CardDescription = (
    <GrowText className="text-14" weight="var(--fs-regular)" color="var(--gray-9)">
      {t.description}
    </GrowText>
  );

  return (
    <StyledCardWrapper>
      {isLoading || props.accountDataLoading ? (
        <Skeleton />
      ) : (
        <Flex direction="column" gap="var(--space-16)">
          {CardHeader}
          {CardDescription}
          {data ? (
            <CurrencyExchangeTab accountSourceData={data} baseCurrency={baseCurrency} />
          ) : (
            <></>
          )}
        </Flex>
      )}
    </StyledCardWrapper>
  );
}
