import { CellRenderProps } from '@sinecycle/growcomponents';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { IInvoices } from 'store/customer/types';

function ClosedInvoiceAmount(props: CellRenderProps<IInvoices>) {
  const { data } = props;
  if (!data) return '-';
  return (
    <AmountWithCurrencySplit
      type="single"
      amount={data.amount_data?.value}
      currency={data.amount_data?.currency}
      baseAmount={data.amount_base_currency}
      context="Closed Invoices"
      currencyArea="DETAILS"
    />
  );
}

export default ClosedInvoiceAmount;
