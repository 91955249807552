import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserNotifications, UserNotifications } from 'store/user-notifications';
import { realtime } from '..';
import { RealtimeEvent } from '../events';
import { RealtimeChannel } from '../types';

export function useRegisterGlobalListener() {
  const dispatch = useDispatch();

  const handleUserNotificationChanged = useCallback(
    (eventPayload: UserNotifications) => {
      dispatch(setUserNotifications(eventPayload));
    },
    [dispatch]
  );

  // not using the custom-hook to register because these need to be done AFTER authentication
  const registerGlobalListeners = useCallback(() => {
    realtime.bindHandlerToEvent(
      RealtimeChannel.USER,
      RealtimeEvent.USER_NOTIFICATION_STATUS_UPDATED,
      handleUserNotificationChanged
    );
  }, [handleUserNotificationChanged]);

  useEffect(() => {
    return function deregisterEventListeners() {
      realtime.unbindHandlerFromEvent(
        RealtimeChannel.USER,
        RealtimeEvent.USER_NOTIFICATION_STATUS_UPDATED,
        handleUserNotificationChanged
      );
    };
  }, [handleUserNotificationChanged]);

  return { registerGlobalListeners };
}
