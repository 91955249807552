import { notify } from 'components/BaseComponents/Notifications';
import { HTTPError } from 'ky';
import parseJson from 'util/parse-error';

const GENERIC_ERROR_MESSAGE = 'Something went wrong';

export async function notifyError(error?: HTTPError, customErrorMessage?: string) {
  const err = error && (await parseJson(error));
  if (err?.message) {
    notify.error(err?.message);
  } else if (customErrorMessage) {
    notify.error(customErrorMessage);
  } else {
    notify.error(GENERIC_ERROR_MESSAGE);
  }
}
