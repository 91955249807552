import styled from 'styled-components';

type spacers = '4' | '8' | '12' | '16' | '20' | '24' | '32' | '36' | '48';

type Axis = 'horizontal' | 'vertical';
type SpacerProps = {
  axis?: Axis;
  size: spacers;
};

const sizeToSpaceMapper: Record<spacers, string> = {
  4: '--space-4',
  8: '--space-8',
  12: '--space-12',
  16: '--space-16',
  20: '--space-20',
  24: '--space-24',
  32: '--space-32',
  36: '--space-36',
  48: '--space-48',
};

function getHeight({ axis, size }: SpacerProps) {
  return axis === 'horizontal' ? '1px' : `var(${sizeToSpaceMapper[size]})`;
}

function getWidth({ axis, size }: SpacerProps) {
  return axis === 'vertical' ? '1px' : `var(${sizeToSpaceMapper[size]})`;
}

/**
 * Usage:
 * Produces a 16px × 16px gap
 * <Spacer size={16} />
 *
 * Produces a 32px × 1px gap
 * <Spacer axis="horizontal" size={32} />
 *
 * Produces a 1px x 32px gap
 * <Spacer axis="vertical" size={32} />
 *
 * Use "rem" or "%"
 * <Spacer size="20%" />
 */
export const Spacer = styled.div<SpacerProps>`
  width: ${getWidth};
  min-width: ${getWidth};
  height: ${getHeight};
  min-height: ${getHeight};
`;

Spacer.defaultProps = {
  axis: 'horizontal',
};

export const InlineSpacer = styled(Spacer)<SpacerProps>`
  display: inline-block;
`;
