import { Select, Tag } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowToolTip } from 'components/BaseComponents/Tooltip';
import { GrowText } from 'components/BaseComponents/Typography/GrowText';
import HideWrapper from 'components/Common/Util/HideWrapper';
import styled from 'styled-components';
import { stakeHolderListAttributes } from '../../type';
const StyledTag = styled(Tag)`
  &.ant-tag {
    display: flex;
    align-items: center;
  }
  .anticon-close {
    color: var(--gray-9) !important;
  }
`;
type TagProp = React.ComponentProps<typeof Select>['tagRender'];
type CustomTagProps = Parameters<NonNullable<TagProp>>['0'];

const t = {
  internalContact: 'Internal Contact',
  customerContact: 'Customer Contact',
};

export function optionToRender(option: BaseOptionType) {
  const showEmail = option.email?.includes('@');
  const ContactType = (
    <GrowToolTip title={t.customerContact}>
      <Tag color="var(--geekblue-1)">
        <GrowText
          color="var(--geekblue-9)"
          ellipsis={{ tooltip: option.title }}
          style={{ maxWidth: '200px' }}
        >
          {option.title}
        </GrowText>
      </Tag>
    </GrowToolTip>
  );
  const Title = (
    <GrowToolTip title={t.internalContact}>
      <Tag color="var(--cyan-1)">
        <GrowText
          color="var(--cyan-9)"
          ellipsis={{ tooltip: option.title }}
          style={{ maxWidth: '200px' }}
        >
          {option.title}
        </GrowText>
      </Tag>
    </GrowToolTip>
  );
  const ContactTypeTag = (
    <HideWrapper hide={option.type !== 'CONTACT' || !option.title}>
      {ContactType}
    </HideWrapper>
  );
  const UserTypeTag = (
    <HideWrapper hide={option.type !== 'USER' || !option.title}>{Title}</HideWrapper>
  );
  const ParentTag = (
    <Tag color="var(--orange-1)">
      <GrowText color="var(--orange-8)">Parent</GrowText>
    </Tag>
  );
  const RelationShip = (
    <>
      {Boolean(option.participant_relation) && (
        <HideWrapper hide={option.participant_relation !== 'PARENT'}>{ParentTag}</HideWrapper>
      )}
    </>
  );
  const Optionrender = (
    <>
      {Boolean(showEmail) && (
        <div style={{ width: '100%' }}>
          <GrowText style={{ maxWidth: '300px' }} ellipsis={{ tooltip: option.email }}>
            {option.email}
          </GrowText>
        </div>
      )}

      <Flex style={{ width: '100%' }}>
        {ContactTypeTag}
        {UserTypeTag}
        {RelationShip}
      </Flex>
    </>
  );
  return (
    <Flex justify="space-between" style={{ width: '100%' }}>
      <>{option.label === 'Not Available' ? option.label : Optionrender}</>
    </Flex>
  );
}

export function tagRender(
  props: Readonly<CustomTagProps>,
  selections?: stakeHolderListAttributes[]
) {
  const { value, closable, onClose } = props;

  const result = selections?.find((option: any) => option.email === value);

  const showEmail = result?.email?.includes('@');
  const color = result?.type === 'CONTACT' ? 'var(--geekblue-1)' : 'var(--cyan-1)';
  const toolTipText = result?.type === 'CONTACT' ? t.customerContact : t.internalContact;
  const EmailText = (
    <HideWrapper hide={!showEmail}>
      <GrowText>{result?.email}</GrowText>
    </HideWrapper>
  );
  const TitleText = (
    <HideWrapper hide={!result?.title || Boolean(showEmail)}>
      <GrowText>{result?.title}</GrowText>
    </HideWrapper>
  );
  const RelationShipText = (
    <HideWrapper hide={Boolean(result?.participant_relation !== 'PARENT')}>
      <GrowText>(Parent)</GrowText>
    </HideWrapper>
  );
  const TagDetails = (
    <Flex gap="var(--space-4)" align="center">
      {EmailText}
      {TitleText}
      {RelationShipText}
    </Flex>
  );
  return (
    <GrowToolTip title={toolTipText}>
      <StyledTag closable={closable} onClose={onClose} color={color} style={{ margin: '2px' }}>
        {TagDetails}
      </StyledTag>
    </GrowToolTip>
  );
}
