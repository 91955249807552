import { DisputeList } from 'components/CollectionActivites/Disputes/types';
import { PaymentMode } from 'components/CollectionActivities/types';
import { stakeHolderListAttributes } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import { ActivityType } from 'types/activities/activity-types';
import { ExternalMappingEventType } from 'types/activities/comment';
import { Emailable } from 'types/activities/email';
import { InvoicePaymentStatus, PaymentStatus } from 'types/activities/payment';
import { PTPSecActivity } from 'types/activities/promise-to-pay';
import { TaskStatus } from 'types/activities/task';
import { Attachment } from 'types/common/attachments';
import { SourceApp } from 'types/common/source-app';
import { BaseRecepientsType } from 'types/entities/collection-strategy/contact-type';
import { BaseCustomer } from 'types/entities/customer';
import { Invoice } from 'types/entities/invoice';
import { User } from 'types/entities/user';
import { IInvoices } from '../customer/types';

export enum EmailStakeHolderType {
  FROM = 'FROM',
  TO = 'TO',
  BCC = 'BCC',
  CC = 'CC',
}

export interface IInvoiceLineItems {
  id: number;
  productName: string;
  quantity: number;
  amount: number;
  unitPrice: number;
}

export type ConversationParticipantDateProps = keyof Pick<
  IConversationParticipant,
  'bounced_at' | 'delivered_at' | 'created_at' | 'opened_at'
>;

export interface IConversationParticipant {
  id: number;
  deleted: boolean;
  emailable: Emailable | Emailable[];
  type: EmailStakeHolderType;
  first_name: string;
  last_name: string;
  email: string;
  delivered: boolean;
  delivered_at: string;
  opened: boolean;
  opened_at: string;
  bounced: boolean;
  bounced_at: string;
  created_at: string;
  updated_at: string;
  statuses: any;
}

export interface IConversable {
  conversable_type: string;
  created_at: string;
  deleted: boolean;
  id: number;
  updated_at: string;
}

export interface IEmailActivity {
  id: number;
  deleted: boolean;
  body: string;
  subject: string;
  incoming: boolean;
  replies: IEmailActivity[];
  opened: boolean;
  created_at: string;
  updated_at: string;
  parent_email: any;
  grand_parent_email: any;
  invoice: IInvoices;
  invoices: IInvoices[];
  conversation_participants: IConversationParticipant[];
  reply_recieved: boolean;
  comment_count: number;
  sent_at: string | number;
  attachments?: Attachment[];
}

export interface ICallLogActivity {
  id: number;
  description: string;
  status: string;
  invoices: IInvoices[];
  created_by: User;
  customer: BaseCustomer;
  customer_info: CustomerInfoProps;
  call_date: string;
  updated_date: string;
  attachments?: Attachment[];
  created_date?: string;
  comments: IComment[];
  comment_count: number;
}

export interface IPromiseToPayActivity {
  id: number;
  description: string;
  status: string;
  invoices: IInvoices[] | Invoice[];
  invoice?: Invoice;
  created_by: User;
  pay_date: string;
  customer_info: CustomerInfoProps;
  attachments?: Attachment[];
  slippage_count?: number;
  balance_amount?: number;
  created_date?: string;
  updated_date: string;
  amount: number;
  currency: string;
  customer: BaseCustomer;
  comments?: IComment[];
  comment_count: number;
  activities: PTPSecActivity[];
  source_app: SourceApp;
}

export interface RaiseADisputeActivity extends DisputeList {
  description: string;
  invoice_line_items: IInvoiceLineItems[];
  attachments?: Attachment[];
  created_date: string;
  updated_date: string;
  created_by: User;
  comments: IComment[];
  customer: BaseCustomer;
  customer_info: CustomerInfoProps;
  comment_count: number;
  closed_by: User;
  source_app: SourceApp;
}

export interface IEscalationActivity {
  id: number;
  description: string;
  status: string;
  attachments?: Attachment[];
  invoices?: IInvoices[];
  customer_info: CustomerInfoProps;
  invoice_line_items: IInvoiceLineItems[];
  assigned_to_list: User[];
  participant_config?: AssignedToUsers[];
  created_date?: string;
  updated_date: string;
  created_by: User;
  customer: BaseCustomer;
  comments: IComment[];
  comment_count: number;
}

export type TaskableActivityType =
  | ELastContactActivity.CALL_LOG
  | ELastContactActivity.DISPUTE
  | ELastContactActivity.ESCALATION
  | ELastContactActivity.NOTE
  | ELastContactActivity.PROMISE_TO_PAY
  | ELastContactActivity.WRITE_OFF;

export type TaskableActivity =
  | ICallLogActivity
  | RaiseADisputeActivity
  | IEscalationActivity
  | INotesActivity
  | IPromiseToPayActivity
  | IWriteOffActivity;

export interface AssignedToUsers extends Omit<stakeHolderListAttributes, 'type'> {
  type: BaseRecepientsType;
}

export interface ITaskActivity {
  id: number;
  title: string;
  description: string;
  customer_info: CustomerInfoProps;
  status: TaskStatus;
  invoices: IInvoices[];
  due_date: string;
  attachments?: Attachment[];
  created_date?: string;
  updated_date: string;
  created_by: User;
  comments: IComment[];
  comment_count: number;
  assigned_to_list: User[];
  customer: BaseCustomer;
  participant_config?: AssignedToUsers[];
  associated_activity_type: TaskableActivityType | null;
  associated_activity_id: number | null;
  associated_activity?: TaskableActivity;
  due_days: number;
}

export interface IWriteOffActivity {
  id: number;
  description: string;
  amount: number;
  status: string;
  invoices: IInvoices[];
  customer_info: CustomerInfoProps;
  customer: BaseCustomer;
  invoice_line_items: IInvoiceLineItems[];
  attachments?: Attachment[];
  created_date?: string;
  updated_date: string;
  created_by: User;
  comments: IComment[];
  comment_count: number;
}

export interface INotesActivity {
  id: number;
  private: boolean;
  note: string;
  notes: string;
  customer_info: CustomerInfoProps;
  invoice: IInvoices;
  invoice_line_items: IInvoiceLineItems[];
  attachments?: Attachment[];
  private_users: User[];
  comments: IComment[];
  comment_count: number;
  created_date?: string;
  updated_date: string;
  created_by: User;
  customer: BaseCustomer;
}

export interface IComment {
  id: number;
  tagged_user_list: User[];
  attached_files?: string[];
  private: boolean;
  private_user_list: User[];
  created_date?: string;
  updated_date: string;
  customer_info: CustomerInfoProps;
  created_by: User;
  comment_count?: number;
  customer: BaseCustomer;
  comments: string;
  external_event_type: ExternalMappingEventType;
}

export interface IPaymentActivity {
  id: number;
  amount: number;
  currency: string;
  invoices: IInvoices[];
  mode: PaymentMode;
  notes: string;
  created_at: string;
  created_date: string;
  payment_date: string;
  status: InvoicePaymentStatus;
  reconciled_by?: User;
  created_by?: User;
  customer_info: CustomerInfoProps;
  comments: IComment[];
  customer: BaseCustomer;
  comment_count: string | null | undefined | number;
  reference_no: number | null;
}

export interface ICreditMemoActivity {
  id: number;
  amount: number;
  currency: string;
  invoices: IInvoices[];
  mode: PaymentMode;
  note: string;
  created_date: string;
  credit_memo_date: string;
  status: PaymentStatus;
  reconciled_by?: User;
  created_by?: User;
  comments: IComment[];
  comment_count: string | null | undefined | number;
  reference_no: number | null;
  amount_base_currency: number;
  applied_amount: number;
  applied_amount_base_currency: number;
  unapplied_amount: number;
  unapplied_amount_base_currency: number;
  notes: string;
  reference: number | null;
}
export interface CustomerInfoProps {
  customer_id?: number;
  customer_name?: string;
  is_child_customer?: boolean;
}

export interface IActivityItem<IParent, IChild> {
  parent_type?: ActivityType;
  parent: IParent;
  child_type?: string | null;
  child?: IChild | null;
  customer_info: CustomerInfoProps;
}

export type TypeofActivity =
  | INotesActivity
  | ICallLogActivity
  | IPromiseToPayActivity
  | RaiseADisputeActivity
  | IEscalationActivity
  | ITaskActivity
  | IWriteOffActivity
  | IPaymentActivity
  | IComment;

export type ActivityFeedCollection = IActivityItem<TypeofActivity, TypeofActivity>;

export interface IActivityFeedCollection {
  list: IActivityItem<TypeofActivity, TypeofActivity>[];
  total_records: number;
  current_page: number;
  total_pages: number;
}

export interface EmailActivityFeedCollection {
  list: IActivityItem<IEmailActivity, IEmailActivity>[];
  total_records: number;
  current_page: number;
  total_pages: number;
}

export enum ELastContactActivity {
  AUTOMATED_REMINDER_EMAIL = 'AUTOMATED_REMINDER_EMAIL',
  EMAIL = 'EMAIL', // to make it comaptible with `ActivityType` enum
  CALL_LOG = 'CALL_LOG',
  ESCALATION = 'ESCALATION',
  DISPUTE = 'DISPUTE',
  PROMISE_TO_PAY = 'PROMISE_TO_PAY',
  WRITE_OFF = 'WRITE_OFF',
  NOTE = 'NOTE',
  TASK = 'TASK',
  PAYMENT = 'PAYMENT',
  CREDIT_MEMO = 'CREDIT_MEMO',
}

export enum ELastActivity {
  EMAIL = 'EMAIL',
  CALL_LOG = 'CALL_LOG',
  ESCALATION = 'ESCALATION',
  DISPUTE = 'DISPUTE',
  PROMISE_TO_PAY = 'PROMISE_TO_PAY',
  WRITE_OFF = 'WRITE_OFF',
  NOTE = 'NOTE',
  TASK = 'TASK',
  PAYMENT = 'PAYMENT',
  CREDIT_MEMO = 'CREDIT_MEMO',
  EMAIL_SENT = 'EMAIL_SENT',
  EMAIL_OPENED = 'EMAIL_OPENED',
  VIEWED_ATTACHMENT = 'VIEWED_ATTACHMENT',
  RESPONDED = 'RESPONDED',
  DISPUTE_OPEN = 'DISPUTE_OPEN',
  WAITING_ON_CUSTOMER = 'WAITING_ON_CUSTOMER',
  DISPUTE_CLOSED = 'DISPUTE_CLOSED',
  ESCALATION_OPEN = 'ESCALATION_OPEN',
  ESCALATION_CLOSED = 'ESCALATION_CLOSED',
  PTP_OPEN = 'PTP_OPEN',
  PTP_CLOSED = 'PTP_CLOSED',
}

export interface ILocalStorageActivityThread {
  activityType: ELastContactActivity;
  activityId: string;
}
