import { GenericTabItems } from 'components/BaseComponents/Tabs/Antd/types';
import { AppPageName } from 'router/constants/page-info';

export enum InboxPath {
  EMAIL_CONVERSATION = 'email-conversation',
  ACTIVITIES = 'assigned-activities',
}

export type InboxRouteMatchConfig = Record<
  InboxPath,
  Extract<AppPageName, 'INBOX_EMAIL' | 'INBOX_ACTIVITIES'>
>;

export type InboxTabItems = GenericTabItems<InboxPath>[];
