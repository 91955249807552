import { localStore } from 'util/browser-storage';

interface StorageInitializationProps {
  storageKey?: string;
  id: string;
}
function localStorageInitialization(props: Readonly<StorageInitializationProps>) {
  const { storageKey, id } = props;
  const { getItem: getFromLocalStorage, setItem: setToLocalStorage } = localStore;

  if (!storageKey) return;

  const isValueExist = getFromLocalStorage(storageKey ? storageKey : '');

  if (!isValueExist && id) {
    setToLocalStorage(storageKey ? storageKey : '', id);
  }

  return;
}

export default localStorageInitialization;
