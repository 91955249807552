import ActivitiesModalLoader from 'components/CollectionActivities/Common/ActivitiesModalLoader';
import { DetailsLayout } from 'components/CollectionActivities/Common/Details/DetailsLayout';
import useEmitActivityEvents from 'components/CollectionActivities/hooks/useEmitActivityEvents';
import { ActivityLoader } from 'components/CollectionActivities/style';
import { useCollectionActivityQuery } from 'queries/activites';
import { ActivityDetailsBaseProps } from 'types/activities/activity-types';
import { CreditMemo } from 'types/activities/credit-memo';
import { CreditMemoDetailsBody } from './CreditMemoDetailsBody';
import { CreditMemoDetailsInfo } from './CreditMemoDetailsInfo';

interface CreditMemoDetailsProps extends ActivityDetailsBaseProps {}
function CreditMemoDetails(props: CreditMemoDetailsProps) {
  const { id, type, customer, mode, subscriptionType, isListFetched, activitiesMode } = props;
  const { data, isLoading, error } = useCollectionActivityQuery<CreditMemo>({
    id,
    type,
    isListFetched,
  });
  const isUnauthorized = error ? error?.status === 403 : false;
  useEmitActivityEvents(isLoading);
  if (activitiesMode && isLoading) {
    return (
      <ActivityLoader>
        <ActivitiesModalLoader />
      </ActivityLoader>
    );
  }

  return (
    <DetailsLayout
      mode={mode}
      isUnauthorized={isUnauthorized}
      content={
        <CreditMemoDetailsBody
          data={data}
          isLoading={isLoading}
          customer={customer}
          subscriptionType={subscriptionType}
          activitiesMode={activitiesMode}
        />
      }
      info={<CreditMemoDetailsInfo loading={isLoading} data={data} customer={customer} />}
    />
  );
}

export { CreditMemoDetails };
