import { Descriptions, Divider } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { CommentsBlock } from 'components/CollectionActivities/Comment/CommentsBlock';
import {
  AmountBlock,
  InvoicesBlock,
  LabelBlock,
  multiCurrencyAmount,
  TextBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { SubscriptionType } from 'components/CollectionActivities/Common/SubscriptionType';
import useGetActivityLink from 'components/CollectionActivities/hooks/useGetActivityLink';
import { ActivityContent, ActivityTitle } from 'components/CollectionActivities/style';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';
import { OPEN_TYPE, useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';

import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { startCase, toLower } from 'lodash';
import { useSelector } from 'react-redux';
import { baseCurrencySelector } from 'store/authentication/authentication';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';
import { Payment } from 'types/activities/payment';

interface PaymentDetailsProps extends ActivityDetailsBase {
  data?: Payment;
  isLoading: boolean;
}

function PaymentDetailsBody(props: PaymentDetailsProps) {
  const { data, isLoading, customer, subscriptionType, activitiesMode } = props;
  const { cardLabel, Icon } = getActivityUnitItems(ActivityType.PAYMENT);
  const { entitySearchParams } = useEntitySearchParams();
  const baseCurrency = useSelector(baseCurrencySelector) ?? 'USD';
  const ActivityCopyLink = useGetActivityLink(activitiesMode);
  const Sub = !isLoading && data ? <SubscriptionType subscriptionType={subscriptionType} /> : null;
  const Title = (
    <ActivityTitle align="center" gap="var(--space-8)">
      <ActivityIconButton
        icon={Icon}
        size="small"
        css={{ '--activityBg': 'var(--green-1)' }}
        viewMode
        loading={isLoading}
        hideBackground
      />
      <GrowText>{cardLabel}</GrowText>
      <ActivityCopyLink data={data} customer={customer} type={ActivityType.PAYMENT} />
    </ActivityTitle>
  );

  const Header = <Flex justify="space-between">{Title}</Flex>;

  const InvoiceArea =
    !isLoading && data ? (
      <InvoicesBlock invoices={data.invoices} showTransactionalCurrency />
    ) : null;

  const Amount =
    data && !isLoading ? (
      <AmountBlock
        amount={data.amount ?? 0}
        currency={data.currency}
        amountComp={
          <AmountWithCurrencySplit
            showTooltip
            type="multi"
            amountWithCurrencySplit={multiCurrencyAmount(
              data.amount,
              baseCurrency,
              data.currency,
              data.amount_base_currency
            )}
          />
        }
      />
    ) : null;

  const AppliedAmount =
    data && !isLoading ? (
      <AmountBlock
        amount={data.applied_amount ?? 0}
        currency={data.currency}
        amountComp={
          <AmountWithCurrencySplit
            showTooltip
            type="multi"
            amountWithCurrencySplit={multiCurrencyAmount(
              data.applied_amount!,
              data.applied_amount_data.currency,
              data.currency,
              data.applied_amount_base_currency!
            )}
          />
        }
      />
    ) : null;

  const UnappliedAmount =
    data && !isLoading ? (
      <AmountBlock
        amount={data.unapplied_amount ?? 0}
        currency={data.currency}
        amountComp={
          <AmountWithCurrencySplit
            showTooltip
            type="multi"
            amountWithCurrencySplit={multiCurrencyAmount(
              data.unapplied_amount!,
              data.un_applied_amount_data.currency,
              data.currency,
              data.unapplied_amount_base_currency!
            )}
          />
        }
      />
    ) : null;

  const Note =
    data && !isLoading ? <InfoContent content={<TextBlock value={data?.notes} />} /> : null;

  const PaymentAmount =
    data && !isLoading ? (
      <AmountBlock amount={data.amount ?? 0} currency={data.currency} size={'var(--fs-20)'} />
    ) : null;

  const PaymentTitle =
    data && !isLoading ? (
      <GrowText strong size="var(--fs-20)">
        Payment via {startCase(toLower(data.mode))} - {PaymentAmount}
      </GrowText>
    ) : null;

  const Content = (
    <>
      <Flex gap="var(--space-12)" direction="column">
        <Flex direction="column" gap="var(--space-8)">
          {Header}
          {Sub}
        </Flex>
        <Flex gap="var(--space-16)" direction="column">
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item>{PaymentTitle}</Descriptions.Item>
          </ActivityContent>
          {Note}
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item label={<LabelBlock label="Applied Invoices" />}>
              {InvoiceArea}
            </Descriptions.Item>
          </ActivityContent>
          <Divider style={{ margin: 'var(--space-16) 0', borderColor: 'var(--gray-5)' }} />
        </Flex>
      </Flex>
      <Flex gap="var(--space-16)" direction="column">
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item label={<LabelBlock label="Payment Amount" />}>
            {Amount}
          </Descriptions.Item>
          <Descriptions.Item label={<LabelBlock label="Applied Amount" />}>
            {AppliedAmount}
          </Descriptions.Item>
        </ActivityContent>
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item label={<LabelBlock label="Unapplied Amount" />}>
            {UnappliedAmount}
          </Descriptions.Item>
        </ActivityContent>
      </Flex>
    </>
  );

  const Footer =
    data && !isLoading ? (
      <CommentsBlock
        customerId={props.customer?.id}
        activityId={data.id}
        activityType={ActivityType.PAYMENT}
        comments={data.comments}
        isOpen={entitySearchParams[EActivityQueryParams.OPEN] === OPEN_TYPE.COMMENTS}
      />
    ) : null;
  return (
    <>
      {Content}
      <Divider style={{ margin: 'var(--space-24) 0', borderColor: 'var(--gray-5)' }} />
      {Footer}
    </>
  );
}

export { PaymentDetailsBody };
