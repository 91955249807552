import { Card } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';

export function ReportCardLoader() {
  const LoaderJSX = <Card style={{ width: 320 }} loading></Card>;
  return (
    <Flex gap="var(--space-8)" wrap="wrap">
      {LoaderJSX}
      {LoaderJSX}
      {LoaderJSX}
      {LoaderJSX}
      {LoaderJSX}
      {LoaderJSX}
      {LoaderJSX}
      {LoaderJSX}
    </Flex>
  );
}
