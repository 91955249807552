import { PayloadAction } from '@reduxjs/toolkit';
import { CollectionPlanResponse } from 'types/api/collection-planning';
import { CollectionPeriod, CollectionPeriodProps, CollectionPeriodsFilterPayload } from './types';

export function updateCollectionPeriod(
  state: CollectionPeriodProps,
  action: PayloadAction<CollectionPeriod>
) {
  state.currentCollectionPeriod = action.payload;
}

export function initializeCollectionPeriodFilters(
  state: CollectionPeriodProps,
  action: PayloadAction<CollectionPeriodsFilterPayload>
) {
  state.collectionPeriodFilters[action.payload.key] = action.payload.filter;
}

export function updateCollectionPeriodFilters(
  state: CollectionPeriodProps,
  action: PayloadAction<CollectionPeriodsFilterPayload>
) {
  state.collectionPeriodFilters[action.payload.key] = [
    ...action.payload.filter,
    ...state.collectionPeriodFilters[action.payload.key],
  ];
}

export function updateCollectionPlanInfo(
  state: CollectionPeriodProps,
  action: PayloadAction<CollectionPlanResponse>
) {
  state.collectionPlanInfo = action.payload;
}

export function updateCollectionPlanInfoEnabledStatus(
  state: CollectionPeriodProps,
  action: PayloadAction<boolean>
) {
  if (state.collectionPlanInfo) {
    state.collectionPlanInfo.enabled = action.payload;
  }
}
