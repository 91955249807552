import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reducers/rootReducer';

const invoiceListStateSelector = (state: RootState) => state.invoiceReducerV2;

const closedInvoiceCurrentFilterViewSelector = createSelector(
  invoiceListStateSelector,
  (currentState) => currentState.closedInvoiceCurrentFilterView
);

const closedInvoiceSavedFilterViewsSelector = createSelector(
  invoiceListStateSelector,
  (currentState) => currentState.closedInvoiceSavedFilterviews
);

const closedInvoiceCurrentFilterSelector = createSelector(
  invoiceListStateSelector,
  (currentState) => currentState.closedInvoiceCurrentFilterView?.invoice_filter
);
const closedCurrentPage = (state: RootState) => state.invoiceReducerV2.closedCurrentPage;
export {
  closedCurrentPage,
  closedInvoiceCurrentFilterSelector,
  closedInvoiceCurrentFilterViewSelector,
  closedInvoiceSavedFilterViewsSelector,
};
