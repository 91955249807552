import { customerNameFormatter } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { AssociatedFile } from 'components/Common/AssociatedFiles/type';
import { EllipsisList } from 'components/Common/EllipsisList';
import BulkDocuments from 'components/Documents/Email/BulkDocuments';
import { CustomerSingleLevelDocument } from 'components/Documents/Email/CustomerLevelDocument';
import InvoiceLevelDocuments from 'components/Documents/Email/InvoiceLevelDocuments';
import useAddDocuments from 'components/Documents/Email/useAddDocuments';
import { useLocation } from 'lib/router';
import { isEqual, uniq } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReceivablePlaceHolder } from 'store/email/types';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import { Emailable } from 'types/activities/email';
import { StatementPeriodType } from 'types/customerstatement/customer-statement';
import { BaseCustomer } from 'types/entities/customer';
import { FolderType } from 'types/entities/email-templates';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { cleanArrayPredicate } from 'util/predicates';
import { ControlledFormItem } from '../../GenerateForm';
import { IActivity } from '../type';
import { BulkActionFollowUp } from './BulkActionFollowUp';
import { useComputeRecepient } from './NewEmail/hook/useComputeRecepient';
import ComposeEmail from './NewEmail/new-email';
import { EmailContainer } from './style';
import { EEmailParams, IComposeEmail } from './type';

export enum ActionableEntity {
  INVOICE = 'INVOICE',
  CUSTOMER = 'CUSTOMER',
}

// For temporary fixing of TS Bug
export enum EEmailFor {
  INVOICE = 'INVOICE',
  CUSTOMER = 'CUSTOMER',
}

export enum InvoiceFollowupAction {
  INDIVIDUAL_INVOICE = 'INDIVIDUAL_INVOICE',
  STATEMENT_OF_INVOICES = 'STATEMENT_OF_INVOICES',
}

const mapCustomerIdPredicate = (customer: BaseCustomer) => customer.id;
const mapCustomerNamePredicate = (customer: BaseCustomer) =>
  customerNameFormatter({ name: customer.name });

const mapInvoiceIdsPredicate = (invoice: InvoiceDropdownInterfaceProps | InvoiceBasicDetails) =>
  invoice.id;
const mapInvoiceNoPredicate = (invoice: InvoiceDropdownInterfaceProps | InvoiceBasicDetails) =>
  invoice.invoice_no;
interface IEmail extends IComposeEmail, Omit<IActivity, 'invoiceList'> {
  quickAction?: React.ReactChild;
  emailAction?: boolean;
  entity?: ActionableEntity;
  invoiceList?: InvoiceDropdownInterfaceProps[] | InvoiceBasicDetails[];
  invoiceLineItems?: number[];
  customers?: BaseCustomer[];
  isNewEmail?: boolean;
  to?: Emailable[];
  cc?: Emailable[];
  from?: Emailable;
  setInvoiceSelectedList?: (options: InvoiceDropdownInterfaceProps[]) => void;
  action?: string;
  isFromInvoiceList?: boolean;
  totalRecords?: number;
  totalCustomerRecords?: number;
  selectAll?: boolean;
  onInvoiceFollowupActionSelect?: (followup: InvoiceFollowupAction) => void;
  validFolders?: FolderType[];
  placeHolderStyles?: React.CSSProperties;
  isCustomerLevelInvoice?: boolean;
  drawerClassName?: string;
  extraFilesComp?: React.ReactNode;
  isInbox?: boolean;
}

const followUpsConfig = [
  {
    key: InvoiceFollowupAction.INDIVIDUAL_INVOICE,
    selected: true,
    title: 'Individual Invoices',
    desc: 'Customer receives an email for every invoice that is chosen.',
  },
  {
    key: InvoiceFollowupAction.STATEMENT_OF_INVOICES,
    selected: false,
    title: 'Statement of Invoices',
    desc: 'Customer receives one email for any number of invoices that are chosen.',
  },
];

const t = {
  heading: 'Choose the type of Invoice Followup you want to perform',
  Customers: 'Customers',
  Added_Invoices: 'Added Invoices',
};

const Email = (props: IEmail) => {
  const params = useLocation();
  const isCustomer = params.pathname.includes('customers') && params.pathname.split('/').length > 2;
  const invoiceId = props.invoiceList?.length === 1 ? props.invoiceList[0].id : undefined;
  const customerId = props.customers?.length === 1 ? props.customers[0].id : undefined;

  const [documents, setDocuments] = useState<AssociatedFile[]>();
  const [invoiceFollowupActions, setInvoiceFollowupActions] = useState(followUpsConfig);
  const [activeFollowupAction, setActiveFollowupAction] = useState<
    InvoiceFollowupAction | undefined
  >();

  const { control, setValue, watch, unregister, register } = useFormContext();

  const location = useLocation();
  const selectedInvoiceIds = watch('invoice_ids', []);
  const isGroupableEmail =
    (props.isFromInvoiceList || props.entity === ActionableEntity.INVOICE) &&
    props.invoiceList &&
    props.invoiceList.length > 1;
  const recipientEntity =
    isEqual(props.entity, ActionableEntity.INVOICE) &&
    isEqual(activeFollowupAction, InvoiceFollowupAction.STATEMENT_OF_INVOICES)
      ? ActionableEntity.CUSTOMER
      : props.entity;

  const GroupedOptions = useComputeRecepient({
    entity: recipientEntity,
    customerId: customerId ?? props.customerId,
    invoiceId: invoiceId,
    isRelativeOptions: props.showRelativeOptionsList,
    page: isCustomer ? 'CUSTOMER_DETAILS' : undefined,
    defaultValues: [...(props.cc ?? []), ...(props.to ?? [])] as Emailable[],
  });

  useEffect(() => {
    if (isGroupableEmail) {
      register(EEmailParams.group_by_customer);
    }
  }, [isGroupableEmail, register]);

  useEffect(() => {
    return () => {
      setActiveFollowupAction(undefined);
      for (const param in EEmailParams) {
        unregister(param);
      }
    };
  }, [unregister]);

  useEffect(() => {
    /**
     * @property
     * showRelativeOptionsList
     * @description
     * showRelativeOptionsList is used when trying to perform
     * bulk email actions where different Customer(s) / Invoice(s)
     * have different recpients and to protect overlapping of information
     * across recepients. This would enable to load relative options like
     * [PRIMARY_CONTACT, SECONDARY_CONTACT] during bulk actions.
     *
     * @property
     * ActionableEntity.CUSTOMER
     * @description
     * To Control/gate the actions starting from Customer being the entity
     * for the email
     *
     * * @property
     * ActionableEntity.INVOICE
     * @description
     * To Control/gate the actions starting from Invoice being the entity
     * for the email
     *
     */

    if (!props.showRelativeOptionsList) {
      if (props.entity === ActionableEntity.INVOICE) {
        /**
         * To handle situtation when the user necessarily decides to
         * not to associate emails to invoice(s) even if its from a Invoice
         * Entity page. Useful when trying to create an email from Customer details page
         * where it could be Invoice/Customer entity
         */
        if (props.invoiceList?.length) {
          unregister(EEmailParams.customer_ids);
        } else {
          setValue(EEmailParams.customer_ids, props.customers?.map(mapCustomerIdPredicate));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.customerId, props.customers, props.entity, props.to, setValue, unregister]);

  const ignoredInvoiceGroups = (() => {
    const selectedInvoicesCount = selectedInvoiceIds?.length ?? 0;
    if (selectedInvoicesCount > 1) return ['invoice', 'custom_fields'] as ReceivablePlaceHolder[];
    if (selectedInvoicesCount === 1) return ['multiple_invoices'] as ReceivablePlaceHolder[];
    return ['invoice', 'custom_fields', 'multiple_invoices'] as ReceivablePlaceHolder[];
  })();

  function getScopeTemplateAndPlaceholders():
    | {
        type: StatementPeriodType;
        folder: FolderType[];
      }
    | undefined {
    const page = location.pathname.split('/')[1];

    if (props.entity === ActionableEntity.CUSTOMER) {
      if (
        page === 'aging-report' &&
        Boolean(props.customers?.length) &&
        props.customers?.length === 1
      ) {
        return { type: 'ABSOLUTE', folder: [FolderType.SINGLE_CUSTOMER] };
      }
      if (page === 'aging-report' && Boolean(props.customers?.length)) {
        return { type: 'RELATIVE', folder: [FolderType.SINGLE_CUSTOMER] };
      }
      if (
        page === 'customers' &&
        Boolean(props.customers?.length) &&
        props.customers?.length === 1
      ) {
        return { type: 'ABSOLUTE', folder: [FolderType.SINGLE_CUSTOMER] };
      }

      if (page === 'customers' && Boolean(props.customers?.length)) {
        return { type: 'RELATIVE', folder: [FolderType.SINGLE_CUSTOMER] };
      }
      if (
        (page === 'customer-details' || page === 'customers') &&
        Boolean(props.customers?.length)
      ) {
        return { type: 'ABSOLUTE', folder: [FolderType.SINGLE_CUSTOMER] };
      }
    }

    if (props.entity === ActionableEntity.INVOICE) {
      if (
        [
          'invoice-list',
          'invoices',
          'invoice-details',
          'customer-details',
          'customers',
          'collection-activities',
          'inbox',
        ].includes(page) &&
        Boolean(props.invoiceList?.length)
      ) {
        if (props.invoiceList?.length === 1 && !activeFollowupAction) {
          return {
            type: 'ABSOLUTE',
            folder: [FolderType.SINGLE_INVOICE, FolderType.SINGLE_CUSTOMER],
          };
        }

        if (activeFollowupAction === InvoiceFollowupAction.INDIVIDUAL_INVOICE) {
          return {
            type: 'RELATIVE',
            folder: [FolderType.SINGLE_INVOICE, FolderType.SINGLE_CUSTOMER],
          };
        }

        if (activeFollowupAction === InvoiceFollowupAction.STATEMENT_OF_INVOICES) {
          return {
            type: 'RELATIVE',
            folder: [FolderType.SINGLE_CUSTOMER, FolderType.MULTIPLE_INVOICE],
          };
        }
      }

      if (page === 'aging-report' && Boolean(props.invoiceList?.length)) {
        if (props.invoiceList?.length === 1 && !activeFollowupAction) {
          return {
            type: 'ABSOLUTE',
            folder: [FolderType.SINGLE_INVOICE, FolderType.SINGLE_CUSTOMER],
          };
        }

        if (activeFollowupAction === InvoiceFollowupAction.INDIVIDUAL_INVOICE) {
          return {
            type: 'RELATIVE',
            folder: [FolderType.SINGLE_INVOICE, FolderType.SINGLE_CUSTOMER],
          };
        }

        if (activeFollowupAction === InvoiceFollowupAction.STATEMENT_OF_INVOICES) {
          return {
            type: 'RELATIVE',
            folder: [FolderType.SINGLE_CUSTOMER],
          };
        }
      }
    }

    return;
  }

  function getInvoiceFileName() {
    const pageArray = location.pathname.split('/').filter(cleanArrayPredicate);
    const pageName = pageArray[0];

    switch (props.entity) {
      case ActionableEntity.CUSTOMER:
        if ((pageName === 'customers' || pageName === 'aging-report') && pageArray.length === 1) {
          return 'All Open Invoices';
        }
        if ((pageName === 'customers' || pageName === 'customer-details') && pageArray.length > 1) {
          return 'All Open Invoices';
        }
        return;
      case ActionableEntity.INVOICE:
        if (pageName === 'aging-report') {
          return props.invoiceList && props.invoiceList?.length > 1
            ? activeFollowupAction === InvoiceFollowupAction.INDIVIDUAL_INVOICE
              ? 'Invoice File'
              : 'All Open Invoices '
            : 'Invoice File';
        }
        if ((pageName === 'invoices' || pageName === 'invoice-list') && pageArray.length === 1) {
          return props.invoiceList && props.invoiceList?.length > 1
            ? activeFollowupAction === InvoiceFollowupAction.INDIVIDUAL_INVOICE
              ? 'Invoice File'
              : 'All Selected Invoices '
            : 'Invoice File';
        }
        if ((pageName === 'invoices' || pageName === 'invoice-details') && pageArray.length > 1) {
          return 'Invoice File';
        }
        if ((pageName === 'customers' || pageName === 'customer-details') && pageArray.length > 1) {
          return props.invoiceList && props.invoiceList?.length > 1
            ? activeFollowupAction === InvoiceFollowupAction.INDIVIDUAL_INVOICE
              ? 'Invoice File'
              : 'All Selected invoices'
            : props.invoiceList?.length === 1
            ? 'Invoice File'
            : 'All Open Invoices';
        }

        if (pageName === 'collection-activities') {
          return props.invoiceList && props.invoiceList?.length > 1
            ? 'All Selected invoices'
            : 'All Open Invoices';
        }
        return;

      default:
        break;
    }
  }

  const invoiceFileName = getInvoiceFileName();

  const scopeTemplatesAndPlaceholders = useMemo(getScopeTemplateAndPlaceholders, [
    location.pathname,
    props.customers?.length,
    props.entity,
    props.invoiceList?.length,
    activeFollowupAction,
  ]);

  function getInvoicesCount() {
    if (props.isCustomerLevelInvoice || props.selectAll) {
      return props.totalRecords;
    } else {
      const invoiceIds = props.invoiceList?.map(mapInvoiceIdsPredicate);
      return uniq(invoiceIds).length;
    }
  }

  function getCustomersCount() {
    const customerIds = props.invoiceList?.map((invoice) => invoice.customer_id);

    if (props.isCustomerLevelInvoice) {
      return props.selectAll ? props.totalCustomerRecords : props.customers?.length;
    }
    if (props.isFromInvoiceList) {
      return props.selectAll ? props.totalCustomerRecords : uniq(customerIds).length;
    }

    return uniq(customerIds).length;
  }

  const customerCount = useMemo(getCustomersCount, [
    props.customers?.length,
    props.invoiceList,
    props.isCustomerLevelInvoice,
    props.isFromInvoiceList,
    props.selectAll,
    props.totalCustomerRecords,
  ]);
  const invoiceCount = useMemo(getInvoicesCount, [
    props.invoiceList,
    props.isCustomerLevelInvoice,
    props.selectAll,
    props.totalRecords,
  ]);

  const handleInvoiceFollowupActionSelect = (selectedKey: InvoiceFollowupAction) => {
    const updatedFollowupAction = invoiceFollowupActions.map((followupAction) => {
      if (followupAction.key === selectedKey) {
        return { ...followupAction, selected: true };
      } else {
        return { ...followupAction, selected: false };
      }
    });

    if (selectedKey) {
      setActiveFollowupAction(selectedKey);
      props.onInvoiceFollowupActionSelect && props.onInvoiceFollowupActionSelect(selectedKey);
    }

    if (selectedKey === InvoiceFollowupAction.INDIVIDUAL_INVOICE && isGroupableEmail) {
      setValue(EEmailParams.group_by_customer, false);
    }

    if (selectedKey === InvoiceFollowupAction.STATEMENT_OF_INVOICES && isGroupableEmail) {
      setValue(EEmailParams.group_by_customer, true);
    }

    setInvoiceFollowupActions(updatedFollowupAction);
    setValue(EEmailParams.body, '');
    setValue(EEmailParams.subject, '');
  };

  useEffect(() => {
    if (isGroupableEmail) {
      setValue(EEmailParams.group_by_customer, false);
    }
  }, [isGroupableEmail, setValue]);

  useEffect(() => {
    setActiveFollowupAction(InvoiceFollowupAction.INDIVIDUAL_INVOICE);
  }, []);

  function getComp() {
    if (props.entity === ActionableEntity.CUSTOMER) {
      return props.customers?.length ? (
        props.customers.length > 1 ? (
          <BulkDocuments leveL={ActionableEntity.CUSTOMER} />
        ) : (
          <CustomerSingleLevelDocument customerId={String(props.customers[0].id)} />
        )
      ) : undefined;
    }
    if (props.entity === ActionableEntity.INVOICE) {
      return props.invoiceList?.length ? (
        props.invoiceList.length > 1 ? (
          <BulkDocuments leveL={ActionableEntity.INVOICE} />
        ) : (
          <InvoiceLevelDocuments invoiceId={String(props.invoiceList[0].id)} />
        )
      ) : undefined;
    }
    return;
  }

  function handleDocuments(event: CustomEvent) {
    setDocuments(event.detail);
  }
  useAddDocuments({
    callBack: (event) => {
      handleDocuments(event);
    },
  });

  const comp = getComp();
  const EmailBody = (
    <div className="email-body">
      <ComposeEmail
        {...props}
        showRelativeOptionsList={props.showRelativeOptionsList}
        groupedOptions={GroupedOptions}
        to={props.to}
        cc={props.cc}
        body={``}
        emailFor={recipientEntity}
        ignoredInvoiceGroups={ignoredInvoiceGroups}
        allowAttachInvoice
        allowUpload
        invoiceFollowupAction={isGroupableEmail ? activeFollowupAction : undefined}
        validFolders={props.validFolders ?? scopeTemplatesAndPlaceholders?.folder}
        placeHolderStyles={props.placeHolderStyles}
        periodType={scopeTemplatesAndPlaceholders?.type}
        invoiceFileName={invoiceFileName}
        key={invoiceFileName}
        drawerClassName={props.drawerClassName}
        extraFilesComp={props.extraFilesComp ? props.extraFilesComp : comp}
        documents={documents}
        handleUpdateDocument={(value) => {
          setDocuments(value);
        }}
        isInbox={props?.isInbox}
        page={isCustomer ? 'CUSTOMER_DETAILS' : undefined}
      />
    </div>
  );

  const CustomerEllipsisList = (
    <ControlledFormItem
      defaultValue={props.customers?.map(mapCustomerIdPredicate)}
      fieldName={EEmailParams.customer_ids}
      control={control}
    >
      <EllipsisList
        label={t.Customers}
        list={props.customers?.map(mapCustomerNamePredicate)}
        totalRecords={props.selectAll ? props.totalRecords : props.customers?.length}
      />
    </ControlledFormItem>
  );

  const InvoiceEllipsisList = (
    <ControlledFormItem
      defaultValue={
        props.isCustomerLevelInvoice
          ? props.customers?.map(mapCustomerIdPredicate)
          : props.invoiceList?.map(mapInvoiceIdsPredicate)
      }
      fieldName={
        props.isCustomerLevelInvoice ? EEmailParams.customer_ids : EEmailParams.invoice_ids
      }
      control={control}
      key={props.entity}
    >
      <EllipsisList
        label={t.Added_Invoices}
        list={props.invoiceList?.map(mapInvoiceNoPredicate)}
        totalRecords={
          props.isCustomerLevelInvoice
            ? props.totalRecords
            : props.selectAll
            ? props.totalRecords
            : props.invoiceList?.length
        }
      />
    </ControlledFormItem>
  );

  const BulkActionFollowUpComp = (
    <Flex gap="var(--space-8)" align="center" justify="space-around">
      {invoiceFollowupActions.map((action) => {
        return (
          <BulkActionFollowUp
            key={action.key}
            title={action.title}
            selected={action.selected}
            desc={action.desc}
            onSelectCallBack={handleInvoiceFollowupActionSelect}
            customersCount={customerCount}
            invoiceCount={invoiceCount}
            actionKey={action.key}
          />
        );
      })}
    </Flex>
  );
  return (
    <EmailContainer>
      <Flex direction="column" gap="--space-24" style={{ marginBottom: 'var(--space-24)' }}>
        <div className={`invoice-search-item ${props.isNewEmail ? '' : 'd-none'}`}>
          {props.entity === ActionableEntity.CUSTOMER ? CustomerEllipsisList : InvoiceEllipsisList}
        </div>
        {isGroupableEmail && (
          <Flex direction="column" gap="var(--space-12)">
            <GrowText color="var(--gray-9)">{t.heading}</GrowText>
            {BulkActionFollowUpComp}
          </Flex>
        )}
      </Flex>
      {EmailBody}
    </EmailContainer>
  );
};

export default Email;
