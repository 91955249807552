import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'antd';
import { IconInbox } from 'assets/icons';
import { NAVIGATION_EVENT } from 'events/navigation';
import { dispatchAppEvent } from 'lib/pub-sub';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import {
  hasUnseenEmailSelector,
  hasUnseenSubscribedActivitiesSelector,
} from 'store/user-notifications';
import { SVGImageType } from 'types/common/assets';

type SideBarIcon = React.ReactNode;

export function getSidebarIconComponent(icon: SVGImageType): SideBarIcon {
  return createElement(icon, { fill: 'white', height: 24, width: 24 }, null);
}

export function getSidebarFontAwesomeIconComponent(icon: IconProp) {
  return <FontAwesomeIcon fontSize={24} icon={icon} color="var(--gray-1)" size="lg" />;
}

export function InboxSidebarIcon() {
  const hasUnseenEmail = useSelector(hasUnseenEmailSelector);
  const hasUnseenActivities = useSelector(hasUnseenSubscribedActivitiesSelector);

  const shouldShowRedDot = hasUnseenEmail || hasUnseenActivities;

  return (
    <Badge dot={shouldShowRedDot} style={{ boxShadow: 'none' }}>
      <IconInbox fill="white" width={24} height={24} />
    </Badge>
  );
}

export function dispatchNavigationEvent(to: string) {
  dispatchAppEvent({
    type: NAVIGATION_EVENT.NAVIGATION,
    payload: { to, context: 'SideBar' },
  });
}
