import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { AcceptedFileTypes } from 'components/BaseComponents/UploadFiles/utils';
import styled from 'styled-components';
import {
  AcceptedFiles,
  FileAction,
  OnChange,
  OnPreview,
  OnRemove,
  UploadFilesFile,
  UploadProps,
} from '../type';
import UploadItemRenderer from './UploadItem';

const t = {
  upload_files: 'Upload File',
  desc: 'Click or drop your files here',
  max_size: 'Max file size: 20mb',
  title: {
    upload: 'Upload File',
    uploaded: 'Uploaded Files',
  },
};

const UploadIcon = (
  <FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} color="var(--primary-3)" size="2x" />
);

const StyledDragger = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag {
    height: 160px !important;
    background-color: var(--gray-1);
    border-color: var(--gray-5);
  }
  .ant-upload-list {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    margin-top: var(--space-12);
  }
`;

const acceptInputAttr = AcceptedFileTypes.join();

export interface DefaultFileUploaderProps<T> {
  fileList?: UploadFilesFile<T>[];
  onRemove?: (file: UploadFilesFile<T>) => ReturnType<NonNullable<OnRemove>>;
  hide?: boolean;
  action?: FileAction;
  acceptInputAttr?: AcceptedFiles;
  onPreview?: (file: UploadFilesFile<T>) => ReturnType<NonNullable<OnPreview>>;
  onChange?: OnChange;
  name?: string;
  beforeUpload?: (file: RcFile, FileList: RcFile[]) => boolean;
}
function DefaultFileUploader<T>(props: Readonly<DefaultFileUploaderProps<T>>) {
  if (props.hide) return null;

  const defaultProps: UploadProps = {
    name: props.name,
    multiple: true,
    action: props.action,
    accept: props.acceptInputAttr ?? acceptInputAttr,
    onChange: props.onChange,
    fileList: props.fileList,
    itemRender(__, file, fileList, actions) {
      return <UploadItemRenderer file={file} action={actions} hideActions={false} />;
    },
    onRemove: props.onRemove,
    onPreview: props.onPreview,
    beforeUpload: props.beforeUpload,
  };

  const Header = (
    <Flex direction="column">
      {UploadIcon}
      <Texto size="14" color="var(--gray-9)">
        {t.upload_files}
      </Texto>
    </Flex>
  );
  const Body = (
    <Texto size="12" color="var(--gray-7)">
      {t.desc}
    </Texto>
  );
  const FileSizeInfo = (
    <Texto size="12" color="var(--gray-7)">
      {t.max_size}
    </Texto>
  );
  const UploadDefaultInfo = (
    <Flex direction="column" gap="--space-8">
      {Header}
      {Body}
      {FileSizeInfo}
    </Flex>
  );

  const UploadArea = (
    <Flex justify="center" align="center">
      {UploadDefaultInfo}
    </Flex>
  );

  const DraggerHeader = (
    <Flex align="center" gap="var(--space-4)">
      <Texto weight="semibold">{t.title.uploaded}</Texto>
      <Texto color="var(--gray-7)" size="14">
        {`(${props.fileList?.length})`}
      </Texto>
    </Flex>
  );
  return (
    <Flex direction="column" gap="--space-8">
      {DraggerHeader}
      <StyledDragger {...defaultProps}>{UploadArea}</StyledDragger>
    </Flex>
  );
}

export default DefaultFileUploader;
