import { CollectionPlanResponse } from 'types/api/collection-planning';

import ky from './ky';

const baseUrl = 'collection-plans';

export async function getCollectionPlan() {
  return (await ky.get(baseUrl).json()) as CollectionPlanResponse;
}

export async function saveCollectionPlan(data: CollectionPlanResponse) {
  return await ky.post(baseUrl, { json: data });
}

export async function editCollectionPlan(id: number, data: CollectionPlanResponse) {
  return await ky.put(`${baseUrl}/${id}`, { json: data });
}
