import { DisputeType } from 'types/entities/dispute-type';
import ky from './ky';

const baseURL = 'dispute-types';

export async function getRecentDisputes() {
  return await ky.get(`${baseURL}/recent`).json<DisputeType[]>();
}

export async function getAllDisputeTypes() {
  return (await ky.get(baseURL).json()) as DisputeType[];
}

export async function createDisputeType(data: DisputeType) {
  return (await ky.post(baseURL, { json: data }).json()) as DisputeType;
}

export async function updateDisputeType(data: DisputeType) {
  return (await ky
    .patch(`${baseURL}/${data.id}`, { json: { name: data.name, archived: data.archived } })
    .json()) as DisputeType;
}

export async function deleteDisputeType(data: DisputeType) {
  return await ky.delete(`${baseURL}/${data.id}`);
}
