import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserNotificationStatus } from 'services/user-notification';
import {
  hasUnseenEmailSelector,
  hasUnseenSubscribedActivitiesSelector,
  setUserNotifications,
  UserNotifications,
  userNotificationsSelector,
} from 'store/user-notifications';

export function useInboxNotificationStatus() {
  const userNotifications = useSelector(userNotificationsSelector);
  const hasUnseenEmail = useSelector(hasUnseenEmailSelector);
  const hasUnseenSubscribedActivities = useSelector(hasUnseenSubscribedActivitiesSelector);
  const dispatch = useDispatch();

  const updateEmailSeen = useCallback(() => {
    const updatedStatus = { hasUnseenEmail: false };

    updateUserNotificationStatus({ ...userNotifications, ...updatedStatus })
      .then(() => dispatch(setUserNotifications(updatedStatus)))
      .catch((e) => console.log(e));
  }, [dispatch, userNotifications]);

  const updateNewSubscribedActivitiesSeen = useCallback(() => {
    const updatedStatus: Partial<UserNotifications> = {
      hasUnseenDispute: false,
      hasUnseenEscalation: false,
      hasUnseenTask: false,
      hasUnseenCallLog: false,
      hasUnseenPromiseToPay: false,
      hasUnseenNote: false,
      hasUnseenCreditMemo: false,
      hasUnseenPayment: false,
      hasUnseenWriteOff: false,
    };

    updateUserNotificationStatus({ ...userNotifications, ...updatedStatus })
      .then(() => dispatch(setUserNotifications(updatedStatus)))
      .catch((e) => console.log(e));
  }, [dispatch, userNotifications]);

  return {
    hasUnseenEmail,
    hasUnseenSubscribedActivities,
    updateEmailSeen,
    updateNewSubscribedActivitiesSeen,
  };
}
