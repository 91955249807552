import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowButton, HideWrapper } from '@sinecycle/growcomponents';
import { Divider } from 'antd';
import CallLogButton from 'components/Common/ActivitiesForm/CallLog/CallLogButton';
import DisputeButton from 'components/Common/ActivitiesForm/Dispute/DisputeButton';
import DocumentButton from 'components/Common/ActivitiesForm/Document/DocumentButton';
import EscalationButton from 'components/Common/ActivitiesForm/Escalation/EscalationButton';
import NoteButton from 'components/Common/ActivitiesForm/Note/NoteButton';
import PTPButton from 'components/Common/ActivitiesForm/PromiseToPay/PTPButton';
import TaskButton from 'components/Common/ActivitiesForm/Task/TaskButton';
import WriteOffButton from 'components/Common/ActivitiesForm/WriteOff/WriteOffButton';
import {
  CallLogFormText,
  DisputeFormText,
  DocumentFormText,
  EscalationFormText,
  NoteFormText,
  P2PFormText,
  TaskFormText,
  WriteOffFormText,
} from 'components/Common/ActivitiesForm/text';
import { ActivityButtons as ActivityButtonsConfig } from 'components/Common/ActivitiesForm/types';
import {
  CallLogFormActivityProps,
  CallLogResponseSchema,
  DisputeFormActivityProps,
  DisputeResponseSchema,
  DocumentFormActivityProps,
  DocumentResponseSchema,
  EscalationFormActivityProps,
  EscalationResponseSchema,
  NoteFormActivityProps,
  NoteResponseSchema,
  PTPFormActivityProps,
  PTPResponseSchema,
  TaskFormActivityProps,
  TaskResponseSchema,
} from 'components/Common/hooks/type';
import { omit } from 'lodash';
import { useSelector } from 'react-redux';
import { getRecentDisputes } from 'services/dispute-type';
import { getDocumentTypesList } from 'services/documents';
import { getDisputeType } from 'services/invoice';
import { dateFormatSelector } from 'store/authentication/authentication';
import { ActivityType } from 'types/activities/activity-types';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import MoreActions from '../MoreActions';
import {
  ActivitySchema,
  invoiceTransformSchema,
  ptpInvoiceTransformSchema,
} from './ActivitySchema';
import { ActionableEntity } from './Email';
import { ActivityButtonsProps, MutationFunctionResponseType } from './type';
import { invoiceItemsFn, lineItemsFn } from './utils';

export default function ActivityButtons(props: ActivityButtonsProps) {
  const {
    invoice,
    customerId,
    customerCurrency,
    entityLevelData,
    lineItems,
    displayKeys,
    extra,
    onMutateFn,
    customTransformer,
    bulkAction,
    disableAction,
    bulkRecordsLength,
    customerNames,
    viewChild,
  } = props;

  const lineItemQueries = lineItemsFn(customerId);
  const invoiceItemQueries = invoiceItemsFn<InvoiceBasicDetails>(customerId, viewChild);
  const invoiceQueryWithoutViewChild = invoiceItemsFn<InvoiceBasicDetails>(customerId);
  const dateFormat = useSelector(dateFormatSelector);

  const buttonsMap = {
    [ActivityType.CALL_LOG]: (buttonConfig?: ActivityButtonsConfig) => (
      <CallLogButton<CallLogFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        text={CallLogFormText}
        responseSchema={ActivitySchema.CALL_LOG}
        onMutateFn={onMutateFn as MutationFunctionResponseType<CallLogResponseSchema>}
        invoiceTransformSchema={invoiceTransformSchema}
        taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
        buttonConfig={buttonConfig}
        customTransformer={customTransformer}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.PROMISE_TO_PAY]: (buttonConfig?: ActivityButtonsConfig) => (
      <PTPButton<PTPFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        text={P2PFormText}
        responseSchema={ActivitySchema.PROMISE_TO_PAY}
        customerCurrency={customerCurrency!}
        onMutateFn={onMutateFn as MutationFunctionResponseType<PTPResponseSchema>}
        invoiceTransformSchema={ptpInvoiceTransformSchema}
        taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
        buttonConfig={buttonConfig}
        customTransformer={customTransformer}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.NOTE]: (buttonConfig?: ActivityButtonsConfig) => (
      <NoteButton<NoteFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        text={NoteFormText}
        responseSchema={ActivitySchema.NOTE}
        invoiceTransformSchema={invoiceTransformSchema}
        taskRemainderResponseSchema={omit(ActivitySchema.TASK_REMAINDER, ['invoice_ids'])}
        invoiceLineItems={lineItems}
        onMutateFn={onMutateFn as MutationFunctionResponseType<NoteResponseSchema>}
        customTransformer={customTransformer}
        showInvoicesList={Boolean(invoice)}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        customerNames={customerNames}
        dateFormat={dateFormat}
        {...lineItemQueries}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.DISPUTE]: (buttonConfig?: ActivityButtonsConfig) => (
      <DisputeButton<DisputeFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        disputeTypesearchQueryFn={(searchTerm: string) => getDisputeType(searchTerm)}
        disputeTypeQueryKey={['get-dispute-type', customerId]}
        disputeTypeQueryFn={() => getRecentDisputes()}
        text={DisputeFormText}
        invoiceLineItems={lineItems}
        responseSchema={ActivitySchema.DISPUTE}
        taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
        invoiceTransformSchema={invoiceTransformSchema}
        onMutateFn={onMutateFn as MutationFunctionResponseType<DisputeResponseSchema>}
        customTransformer={customTransformer}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        {...lineItemQueries}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.DOCUMENT]: (buttonConfig?: ActivityButtonsConfig) => (
      <DocumentButton<DocumentFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        documentQueryFn={(entityLevel: ActionableEntity) =>
          getDocumentTypesList({ page: 1, size: 200, filters: { entity_types: [entityLevel] } })
        }
        documentQueryKey={['document-types', entityLevelData, customerId]}
        text={DocumentFormText}
        onMutateFn={onMutateFn as MutationFunctionResponseType<DocumentResponseSchema>}
        responseSchema={ActivitySchema.DOCUMENT}
        invoiceTransformSchema={invoiceTransformSchema}
        entityLevel={entityLevelData!}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        customTransformer={customTransformer}
        {...invoiceQueryWithoutViewChild}
      />
    ),
    [ActivityType.ESCALATION]: (buttonConfig?: ActivityButtonsConfig) => (
      <EscalationButton<EscalationFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        text={EscalationFormText}
        taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
        responseSchema={ActivitySchema.ESCALATION}
        invoiceTransformSchema={invoiceTransformSchema}
        invoiceLineItems={lineItems}
        onMutateFn={onMutateFn as MutationFunctionResponseType<EscalationResponseSchema>}
        customTransformer={customTransformer}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        {...lineItemQueries}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.TASK]: (buttonConfig?: ActivityButtonsConfig) => (
      <TaskButton<TaskFormActivityProps<InvoiceBasicDetails>>
        invoices={invoice}
        text={TaskFormText}
        onMutateFn={onMutateFn as MutationFunctionResponseType<TaskResponseSchema>}
        responseSchema={ActivitySchema.TASK}
        invoiceTransformSchema={invoiceTransformSchema}
        customTransformer={customTransformer}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        {...invoiceItemQueries}
      />
    ),
    [ActivityType.WRITE_OFF]: (buttonConfig?: ActivityButtonsConfig) => (
      <WriteOffButton
        invoices={invoice}
        text={WriteOffFormText}
        onMutateFn={onMutateFn as MutationFunctionResponseType<undefined>}
        responseSchema={{}}
        invoiceTransformSchema={invoiceTransformSchema}
        customTransformer={customTransformer}
        buttonConfig={buttonConfig}
        disabled={disableAction}
        bulkAction={bulkAction}
        bulkRecordsLength={bulkRecordsLength}
        dateFormat={dateFormat}
        customerCurrency={customerCurrency!}
        {...invoiceItemQueries}
      />
    ),
  };

  const activityButtons = displayKeys
    .filter((item) => item.type === 'button' && !item.hide)
    .map((item) => buttonsMap[item.key](item?.buttonConfig));

  const moreActionsButtons = displayKeys
    .filter((item) => item.type === 'list' && !item.hide)
    .map((item) => ({ label: buttonsMap[item.key](item?.buttonConfig) }));

  const MoreActionsButton = (
    <MoreActions direction="down" style={{ padding: 0 }} options={moreActionsButtons} removePadding>
      <GrowButton
        icon={
          <FontAwesomeIcon
            icon={['far', 'plus']}
            color={disableAction ? undefined : 'var(--purple-7)'}
          />
        }
        disabled={disableAction}
      />
    </MoreActions>
  );

  return (
    <>
      {activityButtons}
      {moreActionsButtons.length ? MoreActionsButton : null}
      <HideWrapper hide={!extra}>
        <>
          <Divider
            type="vertical"
            style={{ margin: 0, height: '32px', borderColor: 'var(--gray-5)' }}
          />
          {extra}
        </>
      </HideWrapper>
    </>
  );
}
