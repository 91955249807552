import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Skeleton } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import Tag from 'components/BaseComponents/Tags';
import { GrowText } from 'components/BaseComponents/Typography';
import { StyledCardWrapper } from '../../style';
import { BaseCurrencySymbolProps } from '../../type';

const t = {
  title: 'Base Currency',
  description: {
    content:
      'Base currency is the currency in which all Financial Data will be viewed inside Growfin. You can choose a currency using which you would want to do your A/R Reporting. Customers in your account might be billed in a different currency and Growfin converts the currency to the base currency.',
    info: `
     You will still be able to see the exact value at a customer currency level on hover of the `,
  },
};

const ExchangeIcon = (
  <FontAwesomeIcon
    icon={['far', 'exchange']}
    color="var(--geekblue-7)"
    style={{
      height: 16,
      width: 16,
      boxSizing: 'border-box',
      borderRadius: 4,
      padding: 2,
      background: 'var(--geekblue-1)',
      marginLeft: 8,
    }}
  />
);

export default function BaseCurrency(props: BaseCurrencySymbolProps) {
  const { baseCurrencyName, baseCurrency, baseCurrencySymbol } = props;

  const baseCurrencyText = (
    <GrowText size="var(--fs-16)" weight="var(--fs-semibold)" color="var(--gray-9)">
      {baseCurrencyName} {baseCurrency} - {baseCurrencySymbol}
    </GrowText>
  );

  const CardHeader = (
    <GrowText size="var(--fs-20)" weight="var(--fs-semibold)" color="var(--gray-9)">
      {t.title}
    </GrowText>
  );

  const MoreInfo = (
    <Row>
      <Col span={1}>
        <FontAwesomeIcon icon={['far', 'info-circle']} color="var(--geekblue-6)" fontSize={14} />
      </Col>
      <Col span={23}>
        <GrowText size="var(--fs-14)" color="var(--gray-9)">
          {t.description.info}
          {ExchangeIcon}
        </GrowText>
      </Col>
    </Row>
  );

  const CardBody = (
    <Flex direction="column" gap="var(--space-12)">
      <GrowText size="var(--fs-14)" color="var(--gray-9)">
        {t.description.content}
      </GrowText>
      {MoreInfo}
      <Tag color="var(--purple-1)" style={{ width: 'fit-content', padding: 'var(--space-12)' }}>
        {baseCurrencyText}
      </Tag>
    </Flex>
  );

  return (
    <StyledCardWrapper>
      {props.accountDataLoading ? (
        <Skeleton />
      ) : (
        <Flex direction="column" gap="var(--space-16)">
          <Flex direction="column" gap="var(--space-16)">
            {CardHeader}
            {CardBody}
          </Flex>
        </Flex>
      )}
    </StyledCardWrapper>
  );
}
