import { mergeAttributes } from '@tiptap/core';
import TableCell from '@tiptap/extension-table-cell';
import { TransformPastedHTML } from '../TipTapEditor/extensions/default-paste';
export const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      hasImage: {
        default: false,
        parseHTML: (element: HTMLElement) => {
          const table = element.closest('table');
          return table ? Array.from(table.querySelectorAll('img')).length > 0 : false;
        },
      },
      style: {
        default:
          'border: 1px solid #ced4da!important; box-sizing: border-box; min-width: 1em; padding: 6px 8px; position: relative; vertical-align: top;',
        parseHTML: (element) => {
          return element.getAttribute('style') || '';
        },
        renderHTML: (attributes) => {
          if (typeof attributes.style === 'object') {
            return {
              style: Object.entries(attributes.style)
                .map(([key, value]) => `${key}: ${value}`)
                .join('; '),
            };
          }
          return { style: attributes.style };
        },
      },
    };
  },
  renderHTML({ HTMLAttributes }: { HTMLAttributes: Record<string, any> }) {
    return ['td', mergeAttributes({ ...HTMLAttributes }), 0];
  },
  addProseMirrorPlugins() {
    return [TransformPastedHTML()];
  },
});
