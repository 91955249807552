export function storageFactory(storage: Storage) {
  function toJSONParse(value: string) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return undefined;
    }
  }

  function toJSONStringyfy(value: unknown): string {
    try {
      return JSON.stringify(value);
    } catch (e) {
      return '';
    }
  }

  function isSupported() {
    try {
      const testKey = '__some_random_key_you_are_not_going_to_use__';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  function clear(): void {
    if (isSupported()) {
      storage.clear();
    }
  }

  function getItem<T>(name: string): T | null {
    if (isSupported()) {
      const storageValue = storage.getItem(name);

      if (storageValue) {
        return toJSONParse(storageValue);
      }
    }
    return null;
  }

  function key(index: number): string | null {
    if (isSupported()) {
      return storage.key(index);
    }

    return null;
  }

  function removeItem(name: string): void {
    if (isSupported()) {
      storage.removeItem(name);
    }
  }

  function setItem(name: string, value: unknown): void {
    if (isSupported()) {
      const convertedValue = toJSONStringyfy(value);
      if (convertedValue) {
        storage.setItem(name, convertedValue);
      }
    }
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
  };
}
