import { FilterGroup, StandardFilterGroup } from 'components/Common/FilterComponents/types';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { AccountConfigKey } from 'types/entities/account';
import { FilterKeys } from './reducers/type';

const invoiceFilterList: FilterGroup['filterList'] = [
  {
    id: FilterKeys.INVOICE_STATUS,
    label: 'Invoice Status',
    active: false,
    removable: false,
    filterValueKeys: ['status'],
  },
  {
    id: FilterKeys.DISPUTE_TYPE,
    label: 'Dispute Type',
    active: false,
    removable: true,
    filterValueKeys: ['dispute_type_ids'],
  },
  // {
  //   id: FilterKeys.DUE_DATE,
  //   label: 'Due Date',
  //   active: false,
  //   removable: true,
  //   filterValueKeys: ['due_date_from', 'due_date_to', 'due_date_range'],
  // },
  {
    id: FilterKeys.TOTAL_DUE_AMOUNT,
    label: 'Due Amount',
    active: false,
    removable: true,
    filterValueKeys: ['invoice_amount_min', 'invoice_amount_max'],
  },
  {
    id: FilterKeys.ISSUED_DATE,
    label: 'Issued date Range',
    active: false,
    removable: true,
    filterValueKeys: ['issue_date_from', 'issue_date_to', 'issue_date_range'],
  },
  {
    id: FilterKeys.CLOSED_DATE,
    label: 'Closed date Range',
    active: false,
    removable: true,
    filterValueKeys: ['closed_date_from', 'closed_date_to', 'closed_date_range'],
  },
  {
    id: FilterKeys.BILLING_CONTACT,
    label: 'Billing Contact',
    active: false,
    removable: true,
    filterValueKeys: ['contact_ids'],
  },
];

export const InvoiceFilterGroupList: FilterGroup = {
  id: StandardFilterGroup.INVOICE,
  label: 'Invoice',
  filterList: invoiceFilterList.filter((filter) => filter.removable),
};

const customerFilterList: FilterGroup['filterList'] = [
  {
    id: FilterKeys.COLLECTION_MANAGER_IDS,
    label: 'Collection Manager',
    active: false,
    removable: true,
    filterValueKeys: ['collection_manager_ids'],
  },
  {
    id: FilterKeys.SALES_MANAGER_IDS,
    label: 'AM/CSM Team Lead',
    active: false,
    removable: true,
    filterValueKeys: ['sales_manager_ids'],
  },
  {
    id: FilterKeys.CUSTOMER_DROPDOWN,
    label: 'Customer Name',
    active: false,
    removable: true,
    filterValueKeys: ['customer_ids'],
  },

  /* 
  commenting for future use
  {
    id: FilterKeys.SUBSIDIARY,
    label: 'Subsidiary',
    active: false,
    removable: true,
    filterValueKeys: ['subsidiary_ids', 'is_subsidiary_not_assigned'],
  }, */
];

export function useCustomerFilterList(): FilterGroup {
  const isSubsidiaryEnabled = useConfig(AccountConfigKey.SUBSIDIARY_ENABLED);
  const blackListedColumns: (string | undefined)[] = [];
  const sanitizedFilterList = customerFilterList.filter(
    (column) => !blackListedColumns.includes(column.id)
  );

  if (!isSubsidiaryEnabled) {
    blackListedColumns.push(FilterKeys.SUBSIDIARY);
  }

  return {
    id: StandardFilterGroup.CUSTOMER,
    label: 'Customer',
    filterList: sanitizedFilterList.filter((filter) => filter.removable),
  };
}
