import { Flex } from 'components/BaseComponents/Layout/Flex';
import { EmailTemplates } from 'types/entities/email-templates';
import { BodyPreview } from './Body/BodyPreview';
import { SubjectPreview } from './Subject/SubjectPreview';

export default function EmailTemplatePreview({
  selectedTemplate,
}: {
  selectedTemplate: EmailTemplates | undefined;
}) {
  return (
    <Flex direction="column" gap="var(--space-16)" className="template-preview-body">
      <SubjectPreview selectedTemplate={selectedTemplate} />
      <BodyPreview selectedTemplate={selectedTemplate} />
    </Flex>
  );
}
