import { Divider, Empty, Skeleton } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { PageLink } from 'lib/router';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useFlaggedInvoicesQuery } from 'queries/admin-dashboard';
import { Fragment } from 'react';
import { FlaggedInvoiceItem } from './FlaggedInvoiceItem';

const t = {
  Flagged_Invoices: 'Flagged Invoices',
  View_All: 'View All',
};

export function FlaggedInvoices({ filterParams }: DashboardComponentProps) {
  const { data: flaggedInvoices, isLoading: loading } = useFlaggedInvoicesQuery(filterParams);
  const Title = (
    <Fragment>
      <Flex gap="var(--space-8)" align="baseline" style={{ margin: '0 0 16px 0' }}>
        <GrowText size={'var(--fs-20)'} color="var(--gray-9)" strong>
          {t.Flagged_Invoices}
        </GrowText>
        {Boolean(flaggedInvoices?.length) && (
          <PageLink
            appPage="INVOICE_LIST"
            searchParams={`flagged=true&sort_col=DUE_DATE&sort_by=ASC`}
          >
            {t.View_All}
          </PageLink>
        )}
      </Flex>
      <Divider style={{ borderColor: 'var(--gray-5)', margin: 0 }} />
    </Fragment>
  );

  const FlaggedInvoiceList = flaggedInvoices?.map((flaggedInvoice) => (
    <FlaggedInvoiceItem flaggedInvoice={flaggedInvoice} key={flaggedInvoice.invoice_id} />
  ));

  const FlaggedInvoiceWrapper = (
    <Flex direction="column">
      {Title}
      <div style={{ height: '412px', overflow: 'scroll' }}>
        {!flaggedInvoices?.length ? (
          <Empty style={{ margin: 'var(--space-20) 0' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          FlaggedInvoiceList
        )}
      </div>
    </Flex>
  );

  return loading ? <Skeleton /> : FlaggedInvoiceWrapper;
}
