import { useEffect } from 'react';
import { TFilterViews } from 'store/aging/types';
import { ClosedInvoicesListFilters } from 'store/invoice/type';
import { ClosedInvoiceFilterStrip } from './FilterComponentsContainer';
import { useClosedInvoiceListFilter } from './hooks/useClosedInvoiceListFilter';
import { FilterValues } from './reducers/filter-values-reducer';

interface ClosedInvoiceProps {
  currentFilter: TFilterViews;
  setCurrentFilter: React.Dispatch<React.SetStateAction<TFilterViews>>;
}

export function ClosedInvoiceFilterHeader(props: ClosedInvoiceProps) {
  const { currentFilter, setCurrentFilter } = props;

  /** Filter start*/
  const closedInvoiceListFilterStripProps = useClosedInvoiceListFilter();

  const { initializeFilterValuesAndFilterGroups, filterValues } = closedInvoiceListFilterStripProps;

  const FilterStrip = <ClosedInvoiceFilterStrip {...closedInvoiceListFilterStripProps} />;
  /**Filter end */

  useEffect(() => {
    if (Object.keys(currentFilter).length) {
      initializeFilterValuesAndFilterGroups(currentFilter as unknown as FilterValues);
    }
  }, [currentFilter, initializeFilterValuesAndFilterGroups]);

  useEffect(() => {
    function getFilteredData(invoice_filter: ClosedInvoicesListFilters) {
      setCurrentFilter(invoice_filter);
    }

    getFilteredData(filterValues as unknown as ClosedInvoicesListFilters);
  }, [filterValues, setCurrentFilter]);

  return FilterStrip;
}
