import SkeletonInput from 'antd/es/skeleton/Input';
import { notify } from 'components/BaseComponents/Notifications';
import { UploadFilesComponent } from 'components/BaseComponents/UploadFiles';
import { FileUploadPreview } from 'components/BaseComponents/UploadFiles/FilePreview';
import { isFileSizeValid } from 'util/isFileSizeValid';
import { ActivityFormItem } from '../style';
import { UploadFormFilesProps } from '../types';
export default function UploadFormFiles(props: UploadFormFilesProps) {
  const { formFieldName, value, selectedValues, required, form } = props;
  // const currentValue = form.getFieldValue(formFieldName);
  // function removeUploadedFiles(fileId: number) {
  //   const file = filter(currentValue, (item) => item.id !== fileId);
  //   form.setFieldValue(formFieldName, file);
  // }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <ActivityFormItem
      name={formFieldName}
      preserve={false}
      label="Attachments"
      initialValue={value}
      required={required}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[
        {
          required,
          message: `Attachments is required`,
        },
      ]}
    >
      <UploadFilesComponent
        fileList={selectedValues}
        uploadProps={{
          action: '/api/attachments',
          beforeUpload: (file) => {
            try {
              if (!isFileSizeValid(file.size)) {
                throw new Error('File size exceeds the limit of 20MB');
              }
            } catch {
              notify.error('File size should be less than 20MB');
            }
          },
          maxCount: undefined,
          itemRender: (node, file, list, actions) => {
            if (!isFileSizeValid(file?.size) || file.status === 'error') return null;

            return file.response ? (
              <FileUploadPreview
                fileName={file.fileName}
                file={file.originFileObj}
                onRemove={actions.remove}
              />
            ) : (
              <SkeletonInput style={{ height: '40px' }} />
            );
          },
          onChange(info) {
            if (info.file.status === 'error') {
              notify.error(`${info.file.name} file upload failed.`);
              return;
            }

            props.onChange?.(info);

            form.setFieldValue(formFieldName, info.fileList);
          },
        }}
      />
      {/* <HideWrapper hide={!Boolean(currentValue?.length)}>
          <FilePreviewList files={currentValue} onRemove={removeUploadedFiles} />
        </HideWrapper> */}
    </ActivityFormItem>
  );
}
