import { TaskStatus } from 'types/activities/task';

export enum INVOICE_ACTIVITY_EVENTS {
  ACTIVITY_CARD_QUICK_ACTION = 'Activity Card Collection Action',
  ACTIVITY_CARD_QUICK_ACTION_DELETE = 'Activity Card Collection Action Delete',
  ACTIVITY_CARD_QUICK_ACTION_EDIT = 'Activity Card Collection Action Edit',
  ACTIVITY_CARD_QUICK_ACTION_SAVE = 'Activity Card Collection Action Save',
  ACTIVITY_CARD_QUICK_ACTION_STATUS = 'Activity Card Collection Action Status',
  ACTIVITY_CARD_QUICK_ACTION_ADD_COMMENT = 'Activity Card Collection Action Add Comment',
  ACTIVITY_CHANGE_TASK_STATUS = 'Activity Card Complete Task',
  ACTIVITY_EMAIL_FORWARD = 'Activity Forward Email',
  ACTIVITY_EMAIL_REPLY = 'Activity Reply Email',
  ACTIVITY_EMAIL_SEND = 'Activity Send Email',
}

type INVOICE_ACTIVITY_EVENTS_WITH_PROPS_PAYLOAD =
  | {
      type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_CARD_QUICK_ACTION;
      payload: {
        tab: string;
        actionName?: string;
      };
    }
  | {
      type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_CARD_QUICK_ACTION_DELETE;
      payload: {
        tab: string;
        actionName?: string;
      };
    }
  | {
      type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_CARD_QUICK_ACTION_EDIT;
      payload: {
        tab?: string;
        actionName?: string;
      };
    }
  | {
      type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_CARD_QUICK_ACTION_SAVE;
      payload: {
        tab?: string;
        actionName?: string;
      };
    }
  | {
      type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_CARD_QUICK_ACTION_ADD_COMMENT;
      payload: {
        tab?: string;
        actionName?: string;
      };
    }
  | {
      type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_CARD_QUICK_ACTION_STATUS;
      payload: {
        actionName?: string;
        status: string;
      };
    }
  | {
      type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_CHANGE_TASK_STATUS;
      payload: {
        tab: 'Task';
        status: TaskStatus.CLOSED | TaskStatus.OPEN;
      };
    };

type INVOICE_ACTIVITY_EVENTS_WITH_PROPS = INVOICE_ACTIVITY_EVENTS_WITH_PROPS_PAYLOAD['type'];

type INVOICE_ACTIVITY_EVENTS_WITHOUT_PROPS = Exclude<
  INVOICE_ACTIVITY_EVENTS,
  INVOICE_ACTIVITY_EVENTS_WITH_PROPS
>;

export type INVOICE_ACTIVITY_EVENT_TYPES =
  | INVOICE_ACTIVITY_EVENTS_WITH_PROPS_PAYLOAD
  | { type: INVOICE_ACTIVITY_EVENTS_WITHOUT_PROPS; payload?: undefined };
