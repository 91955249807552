import { Select, Skeleton } from 'antd';
import { stakeHolderListAttributes } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import { values } from 'lodash';
import { useState } from 'react';
import { AssignedToUsers } from 'store/activity-feed/type';
import useGetAssignees from '../hooks/useGetAssignees';
import { AssignOwnerMaxTagListRenderer, ListOptions } from '../predicate';
import { RecepientsProps } from '../types';

const t = {
  defaultPlaceHolder: 'Add users...',
};

export default function AssignOwners(props: RecepientsProps) {
  const { placeholder, value, assigneeOptionType, context, onChange, assigneeUserList } = props;
  const { options, selectedUsers, setSearch, onChangeUser, onSearch, isLoading } = useGetAssignees({
    value,
    assigneeUserList,
    assigneeOptionType,
    context,
    onChange,
  });
  const [selectedValue, setSelectedValue] =
    useState<(string | number | undefined)[]>(selectedUsers);

  function onDropdownVisibleChange(open: boolean) {
    if (!open) {
      setSearch(undefined);
    }
  }

  if (isLoading) {
    return <Skeleton.Input active block />;
  }

  return (
    <Select
      filterOption={false}
      showSearch
      value={selectedValue}
      placeholder={placeholder ?? t.defaultPlaceHolder}
      mode={'multiple'}
      options={values(options)}
      loading={isLoading}
      onSearch={onSearch}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onChange={(value, option) => {
        setSelectedValue(value);
        onChangeUser(option as unknown as AssignedToUsers[]);
      }}
      listHeight={props.listHeight}
      dropdownRender={(options) => {
        return ListOptions(options);
      }}
      suffixIcon={null}
      maxTagPlaceholder={(list) => {
        return <AssignOwnerMaxTagListRenderer<stakeHolderListAttributes> list={list} />;
      }}
      maxTagCount={props.maxTagCount ?? 2}
      style={{ width: '100%' }}
    />
  );
}
