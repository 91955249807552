import { Tabs } from 'antd';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { useCollectionPeriodsQuery } from 'queries/admin-dashboard';
import { useBusinessUnitsQuery, useRegionsQuery } from 'queries/filters';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer, PageSuspenseFallback } from 'router/PageContainer';
import { CollectionPlanEnabledSelector } from 'store/collection-period/selector';
import { CollectorsPerformanceActions } from 'store/collectors-performace';
import { DashboardContainer, StyledTabs } from '../style';
import ExtraActions from './ExtraActions';
import SnapShotDashboard from './SnapShot';

const AnalysisDashboard = lazy(() => import('./Analysis'));
const CollectionPerformanceDashboard = lazy(() => import('./CollectionPerformance'));

const t = {
  collectors_performance: "Collector's Performance",
  Snapshot: 'Snapshot',
  Analysis: 'Analysis',
};

export enum DashboardTab {
  SNAPSHOT = 'SNAPSHOT',
  ANALYSIS = 'ANALYSIS',
  COLLECTORS_PERFORMANCE = 'COLLECTORS_PERFORMANCE',
}

type TabItems = React.ComponentProps<typeof Tabs>['items'];
type Tab = NonNullable<TabItems>[0];
export default function CompanyDashboard() {
  useBusinessUnitsQuery();
  useRegionsQuery();
  const snapShotPrivilege = [
    ERolesAndPrivileges.ADMIN_DASHBOARD_SNAPSHOT,
    ERolesAndPrivileges.TEAM_DASHBOARD_SNAPSHOT,
  ];
  const analysisPrivilege = [
    ERolesAndPrivileges.ADMIN_DASHBOARD_ANALYSIS,
    ERolesAndPrivileges.TEAM_DASHBOARD_ANALYSIS,
  ];

  const hasSnapshotAccess = usePrivilegeStatus(snapShotPrivilege, { shallowCheck: true });
  const hasAnalysisAccess = usePrivilegeStatus(analysisPrivilege, { shallowCheck: true });
  const hasCPAccess = usePrivilegeStatus([ERolesAndPrivileges.VIEW_COLLECTOR_PERFORMANCE], {
    shallowCheck: true,
  });
  const hasCollectionPeriod = useSelector(CollectionPlanEnabledSelector);
  const hasCollectionPerformanceAccess = hasCPAccess && hasCollectionPeriod;
  const [activeTab, setActiveTab] = useState(
    hasSnapshotAccess
      ? DashboardTab.SNAPSHOT
      : hasAnalysisAccess
      ? DashboardTab.ANALYSIS
      : undefined
  );

  const dispatch = useDispatch();

  const formMethods = useForm();
  const { unregister } = formMethods;

  function resetCollectionPeriods() {
    refetch();
    return () => {
      dispatch(CollectorsPerformanceActions.resetCollectionPeriods());
    };
  }

  useEffect(() => {
    return () => unregister();
  }, [dispatch, unregister]);

  const { refetch } = useCollectionPeriodsQuery(hasAnalysisAccess, hasCollectionPeriod);
  useEffect(resetCollectionPeriods, [dispatch, refetch]);

  function handleTabClick(key: string) {
    setActiveTab(key as DashboardTab);
  }

  function handleTabItems() {
    const defaultItems: TabItems = [
      {
        label: t.Snapshot,
        key: DashboardTab.SNAPSHOT,
        children: <SnapShotDashboard />,
      },
      {
        label: t.Analysis,
        key: DashboardTab.ANALYSIS,
        children: (
          <Suspense fallback={<PageSuspenseFallback />}>
            <AnalysisDashboard />
          </Suspense>
        ),
      },
      {
        label: t.collectors_performance,
        key: DashboardTab.COLLECTORS_PERFORMANCE,
        children: (
          <Suspense fallback={<PageSuspenseFallback />}>
            <CollectionPerformanceDashboard />
          </Suspense>
        ),
      },
    ];

    const noAnalysisAndNoCPAccess = !hasAnalysisAccess && !hasCollectionPerformanceAccess;

    const noSnapShotAndNoCPAccess = !hasSnapshotAccess && !hasCollectionPerformanceAccess;

    //filter predicates
    const noCPAndNoAnalysisPredicate = (items: Tab) =>
      items.key !== DashboardTab.COLLECTORS_PERFORMANCE && items.key !== DashboardTab.ANALYSIS;

    const noAnalysisPredicate = (items: Tab) => items.key !== DashboardTab.ANALYSIS;

    const noCPAndNoSnapShotPredicate = (items: Tab) =>
      items.key !== DashboardTab.COLLECTORS_PERFORMANCE && items.key !== DashboardTab.SNAPSHOT;

    const noSnapShotPredicate = (items: Tab) => items.key !== DashboardTab.SNAPSHOT;

    const noCpPredicate = (items: Tab) => items.key !== DashboardTab.COLLECTORS_PERFORMANCE;
    //filter predicates

    //conditional
    if (noAnalysisAndNoCPAccess) return defaultItems?.filter(noCPAndNoAnalysisPredicate);

    if (!hasAnalysisAccess) return defaultItems?.filter(noAnalysisPredicate);

    if (noSnapShotAndNoCPAccess) return defaultItems?.filter(noCPAndNoSnapShotPredicate);

    if (!hasSnapshotAccess) return defaultItems?.filter(noSnapShotPredicate);

    if (!hasCollectionPerformanceAccess) return defaultItems?.filter(noCpPredicate);

    return defaultItems;
    //conditional
  }

  const items = useMemo(handleTabItems, [
    hasAnalysisAccess,
    hasCollectionPerformanceAccess,
    hasSnapshotAccess,
  ]);

  const TabExtraContent = <ExtraActions currentTab={activeTab} />;
  return (
    <PageContainer page={'ADMIN_DASHBOARD'}>
      <DashboardContainer>
        <StyledTabs
          headerheight="46px"
          activeKey={activeTab}
          onTabClick={handleTabClick}
          items={items}
          tabBarExtraContent={TabExtraContent}
        />
      </DashboardContainer>
    </PageContainer>
  );
}
