import { DefaultOptionType } from 'antd/lib/select';
import { assign, map } from 'lodash';
import { useState } from 'react';
import { DisputeType } from 'types/entities/dispute-type';
import transformData from 'util/transformData';
import { generateDisputeTypeOptions } from '../predicate';
import { useFormMutation } from '../services/mutation';
import { DisputeTypeMapping, useGetDisputeTypeSearchProps } from '../types';

export default function useDisputeTypeSearch<T>(props: useGetDisputeTypeSearchProps<T>) {
  const { searchQueryFn, setOptionsMap, selectedDisputes, transformSchema } = props;
  const [searchOptions, setSearchOptions] = useState<DefaultOptionType[]>();
  const { mutateAsync: searchDisputes, isLoading: searchLoading } =
    useFormMutation<T>(searchQueryFn);

  async function searchDisputeTypeList(searchTerm: string) {
    if (!searchTerm) return;

    try {
      const data = await searchDisputes(searchTerm);
      const optionsSelectedList = assign([], data, selectedDisputes);
      const optionsListData = transformSchema
        ? map(optionsSelectedList, (item) => {
            return transformData<T, DisputeTypeMapping>({
              originalData: item,
              schemaMapping: transformSchema,
            });
          })
        : (optionsSelectedList as DisputeTypeMapping[]);
      const optionsMapList = new Map(map(optionsListData, (item: DisputeType) => [item.id, item]));
      const optionsList = generateDisputeTypeOptions(optionsListData);

      setOptionsMap((prev) => {
        const mergedMap = new Map(
          prev ? [...prev.entries(), ...optionsMapList.entries()] : optionsMapList.entries()
        );
        return mergedMap;
      });
      setSearchOptions(optionsList);
    } catch {
      throw new Error();
    }
  }

  return { searchDisputeTypeList, searchLoading, searchOptions };
}
