import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

interface useKeyGeneratorProps<T> {
  items?: T;
}
const KEY = 'gro-key';
function useKeyGenerator<T>(props: Readonly<useKeyGeneratorProps<T>>) {
  const [prevItems] = useState(props.items);
  const [key, setKey] = useState(KEY);

  function handleComparison() {
    const number = Math.round(Math.random() * 10000);
    const newKey = `${KEY}-${number}`;
    const isDirty = isEqual(prevItems, props.items);
    const predicate = (prevKey: string) => (isDirty ? prevKey : newKey);

    setKey(predicate);
  }
  useEffect(handleComparison, [prevItems, props.items]);

  return key;
}

export default useKeyGenerator;
