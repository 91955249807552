import { PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import {
  validateEmailDelivery,
  validateStrategy,
  validateStrategyTitle,
} from 'store/collection-strategy/validations';
import {
  AutoResume,
  CollectionStrategy,
  FollowupTrackType,
  RecurringAction,
} from 'types/entities/collection-strategy';
import { DunningRule, PausingRule, StoppingRule } from 'types/entities/collection-strategy/rule';
import { replaceOrPushInArray } from 'util/array';
import { NEW_DUNNING_RULE_ID, NEW_PAUSE_RULE_ID } from '.';
import { InitialState, initialState } from './initial-state';
import {
  findStoppingRuleIndexByStatus,
  findTrack,
  getMaxSequenceCount,
  getSortedRulesBySequence,
  ruleIdGenerator,
} from './utils';

export type FollowupPropType = 'invoice_followup' | 'statement_followup';

type UpdateStoppingRule = { trackType: FollowupTrackType; rule: StoppingRule };
type UpdatePausingRule = { trackType: FollowupTrackType; rule: PausingRule };
type RemovePausingRule = { trackType: FollowupTrackType; ruleId: PausingRule['id'] };

type RemoveDunningRule = {
  followupType: FollowupPropType;
  trackType: FollowupTrackType;
  ruleId: string | number;
};

type UpdateDunningRule = {
  followupType: FollowupPropType;
  trackType: FollowupTrackType;
  rule: DunningRule;
};

// ======================== General ======================================= //
export function resetStrategy(state: InitialState) {
  return initialState;
}

export function updateStrategy(state: InitialState, action: PayloadAction<CollectionStrategy>) {
  state.strategy = action.payload;
  state.errors = validateStrategy(action.payload);
}

export function updateTitle(state: InitialState, action: PayloadAction<string>) {
  const { strategy } = state;
  strategy.title = action.payload;
  state.errors = { ...state.errors, ...validateStrategyTitle(action.payload) };
}

export function enableFollowup(state: InitialState, action: PayloadAction<boolean>) {
  const { strategy } = state;
  strategy.enabled = action.payload;
}

// ======================== Invoice Followup ======================================= //
export function toggleInvoiceFollowup(state: InitialState, action: PayloadAction<boolean>) {
  const { strategy } = state;
  strategy.invoice_followup.enabled = action.payload;
}

export function toggleSerialFollowup(state: InitialState, action: PayloadAction<boolean>) {
  const { strategy } = state;
  strategy.invoice_followup.serial_followup = action.payload;
}

export function updateRecurringAction(state: InitialState, action: PayloadAction<RecurringAction>) {
  const { strategy } = state;
  strategy.invoice_followup.recurring_action = action.payload;
}
export function updateAutoResume(state: InitialState, action: PayloadAction<AutoResume>) {
  const { strategy } = state;
  strategy.invoice_followup.auto_resume = action.payload;
}

// =========================== Statement Followup ==================================== //
export function toggleStatementFollowup(state: InitialState, action: PayloadAction<boolean>) {
  const { strategy } = state;
  strategy.statement_followup.enabled = action.payload;
}

export function updateStatementFollowupPauseRule(
  state: InitialState,
  action: PayloadAction<boolean>
) {
  const { strategy } = state;
  strategy.statement_followup.pause_and_resume = action.payload;
}

// =========================== Dunning Rules ==================================== //
export function saveDunningRule(state: InitialState, action: PayloadAction<UpdateDunningRule>) {
  const { trackType, followupType, rule: initialRule } = action.payload;
  const { strategy } = state;
  const followup = strategy[followupType];

  // find the track to add the dunning rule to
  let track = findTrack(trackType, followup.tracks);
  // if the track is missing, create one
  if (!track) {
    track = {
      track_type: trackType,
      dunning_rules: [],
      pausing_rules: [],
      stopping_rules: [],
    };
    followup.tracks.push(track);
  }

  // clone the rule, since we need to mutate it for id & sequence
  const rule = cloneDeep(initialRule);

  // get highest sequence count present in the rules list
  const maxSequenceCount = getMaxSequenceCount(track.dunning_rules);
  // update it's sequence count to it's +1
  rule.data.sequence = maxSequenceCount + 1;

  if (rule.id === NEW_DUNNING_RULE_ID) {
    rule.id = ruleIdGenerator.getNextId();
  }

  track.dunning_rules = replaceOrPushInArray(
    track.dunning_rules,
    rule,
    (dunningRule: DunningRule) => dunningRule.id === rule.id
  );

  // sort and update all sequence counts from 1
  let initSequence = 1;
  const sortedRules = getSortedRulesBySequence(track.dunning_rules);
  sortedRules.forEach((rule) => {
    rule.data.sequence = initSequence;
    initSequence++;
  });

  track.dunning_rules = sortedRules;
}

export function removeDunningRule(state: InitialState, action: PayloadAction<RemoveDunningRule>) {
  const { trackType, followupType, ruleId } = action.payload;
  const { strategy } = state;
  const followup = strategy[followupType];

  const track = findTrack(trackType, followup.tracks);
  if (!track) return;

  track.dunning_rules = track.dunning_rules.filter((rule) => rule.id !== ruleId);
}

// =========================== Pause Rules ==================================== //
export function savePauseRule(state: InitialState, action: PayloadAction<UpdatePausingRule>) {
  const { trackType, rule: initialRule } = action.payload;
  const { strategy } = state;

  const track = strategy.invoice_followup.tracks.find((track) => track.track_type === trackType);
  if (!track) return;

  const rule = cloneDeep(initialRule);
  if (rule.id === NEW_PAUSE_RULE_ID) {
    rule.id = ruleIdGenerator.getNextId();
  }

  track.pausing_rules = replaceOrPushInArray(
    track.pausing_rules,
    rule,
    (ruleInArray) => ruleInArray.id === rule.id
  );
}

export function removePauseRule(state: InitialState, action: PayloadAction<RemovePausingRule>) {
  const { trackType, ruleId } = action.payload;
  const { strategy } = state;

  const track = strategy.invoice_followup.tracks.find((track) => track.track_type === trackType);
  if (!track) return;

  track.pausing_rules = track.pausing_rules.filter((rule) => rule.id !== ruleId);
}

// =========================== Stop Rules ==================================== //
export function saveStopRule(state: InitialState, action: PayloadAction<UpdateStoppingRule>) {
  const { trackType, rule } = action.payload;
  const { strategy } = state;

  const track = findTrack(trackType, strategy.invoice_followup.tracks);
  if (!track) return;

  const existingRuleIndex = findStoppingRuleIndexByStatus(
    rule.data.event.data,
    track.stopping_rules
  );

  track.stopping_rules = replaceOrPushInArray(track.stopping_rules, rule, existingRuleIndex);
}

export function removeStopRule(state: InitialState, action: PayloadAction<UpdateStoppingRule>) {
  const { trackType, rule } = action.payload;
  const { strategy } = state;

  const track = findTrack(trackType, strategy.invoice_followup.tracks);
  if (!track) return;

  track.stopping_rules = track.stopping_rules.filter(
    (stoppingRule) =>
      !(
        stoppingRule.data.event.data.entity === rule.data.event.data.entity &&
        stoppingRule.data.event.data.status === rule.data.event.data.status
      )
  );
}

// =========================== Settings ==================================== //
export function enableEmailDeliverySettigns(state: InitialState, action: PayloadAction<boolean>) {
  const { strategy } = state;
  strategy.settings.email_delivery.enabled = action.payload;
  state.errors = { ...state.errors, ...validateEmailDelivery(strategy.settings.email_delivery) };
}

export function updateEmailDeliveryTime(state: InitialState, action: PayloadAction<string>) {
  const { strategy } = state;
  strategy.settings.email_delivery.delivery_time = action.payload; // HH:MM:SS
  state.errors = { ...state.errors, ...validateEmailDelivery(strategy.settings.email_delivery) };
}

export function updateEmailDeliveryDays(state: InitialState, action: PayloadAction<number[]>) {
  const { strategy } = state;
  strategy.settings.email_delivery.delivery_days = action.payload; // HH:MM:SS
  state.errors = { ...state.errors, ...validateEmailDelivery(strategy.settings.email_delivery) };
}

export function setShowErrors(state: InitialState, action: PayloadAction<boolean>) {
  state.showErrors = action.payload;
}
