import * as Yup from 'yup';
const t = {
  enter_comment: 'Please enter a comment',
  min_characters: 'Min 3 characters',
  enter_amount: 'Amount must be entered',
  gt_zero: 'Amount cannot be zero',
  gt_balance: 'It cannot exceed balance amount',
  early_date: 'Date cannot be before Pay date',
};
export function getPaymentSlipSchema(props: { maxAmount: number; minDate: string }) {
  return Yup.object().shape({
    comments: Yup.string().required(t.enter_comment).min(3, t.min_characters),
    amount: Yup.number()
      .typeError(t.enter_amount)
      .moreThan(0, t.gt_zero)
      .max(props.maxAmount, t.gt_balance)
      .required(),
    to_date: Yup.date().min(props.minDate, t.early_date).required(),
  });
}
