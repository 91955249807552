export function getFormattedName(firstName?: string, lastName?: string, length: number = 2) {
  if (firstName) {
    if (lastName === '' || lastName === undefined || lastName === null) {
      return firstName.slice(0, length).toUpperCase();
    }

    return firstName.slice(0, 1).toUpperCase().concat(lastName.slice(0, 1).toUpperCase());
  }

  return 'US';
}

export function getInitials(names: string[]) {
  return names
    .filter((name) => name)
    .reduce(
      (finalString, currentName) => finalString.concat(currentName.charAt(0)).toUpperCase(),
      ''
    )
    .trim();
}

export function getFormattedStringWithoutSpecialChars(value: string) {
  return value.replace(/[^A-Z0-9]/gi, '');
}

export function isMaxCharLen(char: string, maxChar: number = 25) {
  const charLen = char.length;
  return charLen > maxChar;
}
