import { Col, Row } from 'antd';
import { GrowText } from 'components/BaseComponents/Typography';
import { IConversationParticipant } from 'store/activity-feed/type';
import { EmailStatus } from './EmailStatus';

interface RecipientPopoverContentProps {
  label: string;
  recipients: IConversationParticipant[];
}

const recipientMapPredicate = (recipient: IConversationParticipant) => (
  <EmailStatus recipient={recipient} key={recipient.id} />
);
function RecipientPopoverContent(props: RecipientPopoverContentProps) {
  const { label, recipients } = props;
  if (!recipients.length) return null;
  return (
    <Row gutter={[8, 8]}>
      <Col flex={20} style={{ lineHeight: '1.2' }}>
        <GrowText style={{ color: 'var(--gray-7)' }}>{label}</GrowText>
      </Col>
      <Col style={{ color: 'var(--gray-7)', lineHeight: '1.2' }}>:</Col>
      <Col span={22}>{recipients.map(recipientMapPredicate)}</Col>
    </Row>
  );
}

export { RecipientPopoverContent };
