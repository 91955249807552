import { Plugin } from 'prosemirror-state';
export function PlainPastePlugin() {
  return new Plugin({
    props: {
      handlePaste(view, event) {
        event.preventDefault();

        if (!event.clipboardData) return;
        // Get the pasted content as plain text
        const plainText = event.clipboardData?.getData('text/plain');
        // Get the schema from the current editor state
        const schema = view.state.schema;
        const { tr } = view.state;
        // here we're replace the selection with the text node in the schema
        tr.replaceSelectionWith(schema.text(plainText));
        // Dispatch the transaction to update the editor state
        view.dispatch(tr);
        // Indicate that the paste event has been handled
        return true;
      },
    },
  });
}
