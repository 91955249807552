import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface StyledCoverSheetProps {
  changeCursor?: boolean;
  inline?: boolean;
}

const SheetThatCovers = styled.div`
  ${({ changeCursor }: StyledCoverSheetProps) => changeCursor && `cursor: not-allowed;`}
  ${({ inline }: StyledCoverSheetProps) => inline && `display: inline;`}

  .sheet-container {
    ${({ inline }: StyledCoverSheetProps) => inline && `display: inline;`}
    background-color: var(--gray-1);
    opacity: 0.5;
    pointer-events: none;
  }
`;

interface OverlayProps {
  shouldCover: boolean;
  style?: React.CSSProperties;
  changeCursor?: boolean;
  inline?: boolean;
  tooltipText?: string;
  children?: React.ReactNode;
}

// add not-allowed with option
// add tooltip with option
export const Overlay = (props: OverlayProps) => {
  const CoveredComponent = <div className="sheet-container">{props.children}</div>;

  const CoveredComponentWithTooltip = (
    <Tooltip title={props.tooltipText}>{CoveredComponent}</Tooltip>
  );

  const CoveredChildren = props.shouldCover ? (
    <SheetThatCovers
      changeCursor={props.changeCursor}
      inline={props.inline ?? false}
      style={props.style}
    >
      {props.tooltipText ? CoveredComponentWithTooltip : CoveredComponent}
    </SheetThatCovers>
  ) : (
    props.children
  );

  return <>{CoveredChildren}</>;
};

interface MaybeHiddenProps {
  hide?: boolean;
}

// visually hide an element & remove interactivity to it
// Declarative way to write to quick element hide/show logic
export const MaybeHidden = styled.div<MaybeHiddenProps>`
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
`;
