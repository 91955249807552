import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';

type InputProps = React.ComponentProps<typeof Input>;
interface BasicSearchComponentProps {
  placeholder?: string;
  searchCallBack: InputProps['onChange'];
  style?: React.CSSProperties;
  shouldDebounce?: boolean;
  shouldReset: boolean;
  onClearCallBack?: () => void;
  allowClear?: boolean;
  contentOnly?: boolean;
}

function SearchComponent(props: BasicSearchComponentProps) {
  const { placeholder, searchCallBack, style, shouldDebounce, onClearCallBack, allowClear } = props;
  const [value, setValue] = useState<string | undefined>();

  const SearchIcon = <FontAwesomeIcon icon={['far', 'search']} color="var(--gray-9)" />;

  const ClearIcon = (
    <FontAwesomeIcon
      icon={['fal', 'times-circle']}
      color="var(--gray-7)"
      onClick={handleOnClear}
      className="cursor-pointer"
    />
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    function handleSearchCallBack() {
      searchCallBack && searchCallBack(event);
    }

    setValue(event.target.value);
    debounce(handleSearchCallBack, shouldDebounce ? 300 : 0)();
  }

  function handleOnClear() {
    if (value) {
      setValue(undefined);
      onClearCallBack && onClearCallBack();
    }
  }

  function handleReset() {
    if (props.shouldReset) {
      setValue(undefined);
    }
  }

  useEffect(handleReset, [props.shouldReset]);
  return (
    <Input
      prefix={props.contentOnly ? undefined : SearchIcon}
      placeholder={placeholder}
      onChange={handleChange}
      style={style}
      value={value}
      suffix={allowClear && ClearIcon}
    />
  );
}

export default SearchComponent;
