import { QueryKey, useQuery } from '@tanstack/react-query';

interface useGetFormElementQueryProps<T> {
  queryFn: () => Promise<T[]>;
  queryKey: QueryKey;
}

interface useGetFormElementParamsQueryProps<T, U> {
  queryFn: (entityLevel: U) => Promise<T[]>;
  queryKey: QueryKey;
  params: U;
}

export function useGetFormElementQuery<T>(props: useGetFormElementQueryProps<T>) {
  const { queryFn, queryKey } = props;

  return useQuery<T[]>({
    queryKey,
    queryFn: async () => {
      const data = await queryFn();
      return data || [];
    },
  });
}

export function useGetFormElementParamsQuery<T, U>(props: useGetFormElementParamsQueryProps<T, U>) {
  const { queryFn, queryKey, params } = props;

  return useQuery<T[]>({
    queryKey,
    queryFn: async () => {
      const data = await queryFn(params);
      return data || [];
    },
  });
}
