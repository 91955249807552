import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'reducers/rootReducer';

export interface UserNotifications {
  hasUnseenEmail: boolean;
  hasUnseenTask: boolean;
  hasUnseenDispute: boolean;
  hasUnseenEscalation: boolean;
  hasUnseenCallLog: boolean;
  hasUnseenNote: boolean;
  hasUnseenPayment: boolean;
  hasUnseenWriteOff: boolean;
  hasUnseenPromiseToPay: boolean;
  hasUnseenCreditMemo: boolean;
}

export const initialState: { notificationStatus: UserNotifications } = {
  notificationStatus: {
    hasUnseenEmail: false,
    hasUnseenTask: false,
    hasUnseenDispute: false,
    hasUnseenEscalation: false,
    hasUnseenCallLog: false,
    hasUnseenNote: false,
    hasUnseenPayment: false,
    hasUnseenWriteOff: false,
    hasUnseenPromiseToPay: false,
    hasUnseenCreditMemo: false,
  },
};

/* Reducers */
const userNotificationsSlice = createSlice({
  name: 'userNotifications',
  initialState,
  reducers: {
    setUserNotifications(state, action: PayloadAction<Partial<UserNotifications>>) {
      state.notificationStatus = { ...state.notificationStatus, ...action.payload };
    },
  },
});

/* Selectors */
const userNotificationsSliceSelector = (state: RootState) => state.userNotifications;

export const userNotificationsSelector = createSelector(
  userNotificationsSliceSelector,
  (userNotificationsSlice) => userNotificationsSlice.notificationStatus
);

export const hasUnseenEmailSelector = createSelector(
  userNotificationsSliceSelector,
  (userNotifications) => userNotifications.notificationStatus.hasUnseenEmail
);

export const hasUnseenSubscribedActivitiesSelector = createSelector(
  userNotificationsSliceSelector,
  (userNotifications) =>
    userNotifications.notificationStatus.hasUnseenTask ||
    userNotifications.notificationStatus.hasUnseenDispute ||
    userNotifications.notificationStatus.hasUnseenEscalation ||
    userNotifications.notificationStatus.hasUnseenCallLog ||
    userNotifications.notificationStatus.hasUnseenCreditMemo ||
    userNotifications.notificationStatus.hasUnseenNote ||
    userNotifications.notificationStatus.hasUnseenPayment ||
    userNotifications.notificationStatus.hasUnseenPromiseToPay ||
    userNotifications.notificationStatus.hasUnseenWriteOff
);

export const hasUnseenAssignedActivitiesSelector = createSelector(
  userNotificationsSliceSelector,
  (userNotifications) =>
    userNotifications.notificationStatus.hasUnseenTask ||
    userNotifications.notificationStatus.hasUnseenDispute ||
    userNotifications.notificationStatus.hasUnseenEscalation
);

/* Actions (handlers) */
export const { setUserNotifications } = userNotificationsSlice.actions;

export default userNotificationsSlice.reducer;
