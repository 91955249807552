import { ShowIfActive } from 'components/Common/FilterComponents/Common/ShowIfActive';
import { useCustomerContactsFilter } from 'components/Common/FilterComponents/Common/hooks/useCustomerContacts';
import { CustomerContact } from 'components/Common/FilterComponents/FilterBlocks/CustomerFilters';
import { StandardFilterGroup } from 'components/Common/FilterComponents/types';
import { InvoiceListFilterType } from '../hooks/useDraftInvoiceListFilter';

interface ContactsProps extends InvoiceListFilterType {}

export function Contacts(props: ContactsProps) {
  const { filterGroups, filterValues, updateFilterValues } = props;
  const [customerContacts] = useCustomerContactsFilter();

  const CustomerContactsFilter = customerContacts?.map((contact) => {
    const categoryId = contact.filterObject?.id;
    const value = filterValues?.user_association?.find((selectedContact) => {
      return selectedContact.category_id === categoryId;
    });

    const categoryRequest = {
      bussUnit: filterValues.business_unit_ids,
      regionId: filterValues.region_ids,
      subsidariesId: filterValues.subsidiary_ids,
    };

    function onSubmitCallback(value: number[]) {
      updateFilterValues({
        type: 'UPDATE_CUSTOMER_CONTACTS',
        payload: {
          category_id: categoryId as number,
          associated_user_ids: value,
        },
      });
    }

    function onClearCallback() {
      onSubmitCallback([]);
    }

    return (
      <ShowIfActive
        filterGroupId={StandardFilterGroup.CUSTOMER}
        filterId={contact.id}
        filterGroups={filterGroups}
        key={contact.id}
      >
        <CustomerContact
          label={contact.label}
          key={contact.id}
          value={value?.associated_user_ids ?? []}
          category={contact.filterObject}
          onSubmit={onSubmitCallback}
          onClear={onClearCallback}
          categoryRequest={categoryRequest}
        />
      </ShowIfActive>
    );
  });

  return <>{CustomerContactsFilter}</>;
}
