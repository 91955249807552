import { StatusTagStyle } from 'components/Common/StatusCell/StatusCell';
import { Attachment } from 'types/common/attachments';
import { SourceApp } from 'types/common/source-app';
import { Invoice } from 'types/entities/invoice';
import { User } from 'types/entities/user';
import { Commento } from './comment';

export interface Slippage {
  amount: string;
  from_date: string;
  to_date: string;
  id: string;
  comments: string;
  created_by: User;
  created_date: string;
  updated_date: string;
  serial_no: number;
}

export interface PromiseToPay {
  id: number;
  description: string;
  balance_amount?: number;
  status: PromiseToPayStatus;
  invoices: Invoice[];
  invoice?: Invoice;
  created_by: User;
  pay_date: string;
  attachments?: Attachment[];
  created_date: string;
  updated_date: string;
  amount: number;
  activities: Array<PTPSecActivity>;
  comments?: Commento[];
  comment_count: number;
  slippage_count?: number;
  currency: string;
  type: PTPType;
  source_app: SourceApp;
}

export enum PTPType {
  CUSTOMER = 'CUSTOMER',
  EXPECTED = 'EXPECTED',
}

export enum SecondaryActivityTypes {
  PAYMENT_SLIP = 'PAYMENT_SLIPPAGE',
  COMMENT = 'COMMENT',
}

export type PTPSecActivity =
  | { type: SecondaryActivityTypes.COMMENT; data: Commento }
  | { type: SecondaryActivityTypes.PAYMENT_SLIP; data: Slippage };

export enum PromiseToPayStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

type PromiseToPayStatusConfig = Record<
  PromiseToPayStatus,
  { label: string; style: StatusTagStyle }
>;

export const promiseToPayStatusConfig: PromiseToPayStatusConfig = {
  OPEN: {
    label: 'Open',
    style: {
      backgroundColor: 'var(--red-1)',
      textColor: 'var(--red-7)',
    },
  },

  CLOSED: {
    label: 'Closed',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-8)',
    },
  },
};

export const PTPStatusMenu = [
  {
    label: 'Open',
    value: PromiseToPayStatus.OPEN,
  },
  {
    label: 'Closed',
    value: PromiseToPayStatus.CLOSED,
  },
];
