export const bucketColorChart = [
  '#FFF1F0',
  '#FFCCC7',
  '#FFA39E',
  '#FF7875',
  '#FF4D4F',
  '#F5222D',
  '#CF1322',
  '#A8071A',
  '#820014',
  '#5C0011',
  '#000000',
  '#262626',
  '#595959',
  '#8C8C8C',
  '#BFBFBF',
];

export const bucketColor = [
  'red-1',
  'red-2',
  'red-3',
  'red-4',
  'red-5',
  'red-6',
  'red-7',
  'red-8',
  'red-9',
  'red-10',
  'gray-10',
  'gray-9',
  'gray-8',
  'gray-7',
  'gray-6',
] as const;

export const bucketValues = [100, 20, 200, 75, 60, 99, 350, 89, 44, 77, 150, 65, 25, 400, 40];
export const totalBucketAmount = 1794;
