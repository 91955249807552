import { GrowNumberFormat } from '@sinecycle/growcomponents';

import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';

interface NumberFormatProps {
  value: number;
  textProps?: React.ComponentProps<typeof GrowNumberFormat>['textProps'];
  mode?: React.ComponentProps<typeof GrowNumberFormat>['options']['mode'];
}

function NumberFormat(props: NumberFormatProps) {
  const { value, textProps, mode } = props;
  const locale = useSelector(localeSelector);

  return <GrowNumberFormat value={value} options={{ locale, mode }} textProps={textProps} />;
}

export { NumberFormat };
