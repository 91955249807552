import { InputNumber } from 'antd';
import { CSSProperties } from 'styled-components';
interface SingleNumericFieldProps {
  onChange: (value: number | null) => void;
  percision?: number;
  value?: number | null;
  moreInfo?: string;
  prefix?: string;
  style?: CSSProperties;
}

export default function SingleNumericField(props: SingleNumericFieldProps) {
  return (
    <InputNumber
      onChange={props.onChange}
      min={0}
      value={props.value}
      controls={false}
      precision={props.percision ?? 0}
      prefix={props.prefix}
      style={props.style}
    />
  );
}
