import { CollectionStrategy } from 'types/entities/collection-strategy';
import { EmailDeliverySettings } from 'types/entities/collection-strategy/setttings';
import {
  maxLengthErrorMessage,
  minCountErrorMessage,
  minLengthErrorMessage,
  requiredErrorMessage,
} from 'util/validation-messages';

export enum StrategyErrorField {
  title = 'title',
  email_delivery_days = 'email_delivery_days',
}

const t = {
  title: 'Title',
  deliveryDays: 'Delivery Days',
};

export type StrategyErrorFields<T extends StrategyErrorField = StrategyErrorField> = Partial<{
  [key in T]: string;
}>;

export function validateStrategyTitle(title: string): StrategyErrorFields {
  const errorState: StrategyErrorFields = {};

  if (!title.length) {
    errorState[StrategyErrorField.title] = requiredErrorMessage(t.title);
  } else if (title.length > 100) {
    errorState[StrategyErrorField.title] = maxLengthErrorMessage(t.title, 100);
  } else if (title.length < 2) {
    errorState[StrategyErrorField.title] = minLengthErrorMessage(t.title, 2);
  } else {
    errorState[StrategyErrorField.title] = undefined;
  }

  return errorState;
}

export function validateEmailDelivery(emailDelivery: EmailDeliverySettings): StrategyErrorFields {
  const errorState: StrategyErrorFields = {};

  const { enabled, delivery_days } = emailDelivery;

  if (enabled && !delivery_days.length) {
    errorState[StrategyErrorField.email_delivery_days] = minCountErrorMessage(t.deliveryDays, 1);
  } else {
    errorState[StrategyErrorField.email_delivery_days] = undefined;
  }

  return errorState;
}

export function validateStrategy(strategy: CollectionStrategy): StrategyErrorFields {
  return {
    ...validateStrategyTitle(strategy.title),
    ...validateEmailDelivery(strategy.settings.email_delivery),
  };
}
