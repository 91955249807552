import { NodeViewWrapper } from '@tiptap/react';
import { useTranslatePlaceholderToLabel } from 'components/BaseComponents/RTE/hooks/useTemplateConverter';

export function PlaceHolderTagView(props: any) {
  const { node } = props;
  const { getPlaceholderLabel } = useTranslatePlaceholderToLabel();
  const label = getPlaceholderLabel(node?.attrs?.placeholder);

  return (
    <NodeViewWrapper contentEditable={false} as="span">
      <PlaceholderTag label={label} value={node?.attrs?.placeholder} />
    </NodeViewWrapper>
  );
}

export function PlaceholderTag({ label, value }: { label: string; value?: string }) {
  return (
    <span
      data-placeholder={value}
      style={{
        background: 'var(--geekblue-1)',
        color: 'var(--geekblue-6)',
        padding: 'var(--space-2) var(--space-0)',
        borderRadius: 'var(--space-2)',
        margin: 'var(--space-2)',
        width: 'fit-content',
        whiteSpace: 'pre-wrap',
      }}
      className="grow-email-placeholder"
    >
      {` ${label} `}
    </span>
  );
}
