import { GrowModal } from '@sinecycle/growcomponents';
import { Button } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import styled from 'styled-components';

export const StyledEmailTemplatesBody = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  .email-templates-sidebar {
    max-width: 250px;
  }
`;

export const FooterContainer = styled(Flex)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 250px);
  padding: var(--space-12) var(--space-24);
  background: var(--gray-1);
  border-top: 1px solid var(--gray-4);
  .ant-btn-text {
    color: var(--primary-7);
  }
  .cancel-btn {
    margin-right: var(--space-8);
  }
  .insert-btn {
    background: var(--primary-7);
  }
`;

export const TemplatePreviewViewContainer = styled(Flex)`
  --modal-footer-height: 140px;
  padding: var(--space-24) var(--space-32) var(--space-0) var(--space-32);
  width: calc(100% - 255px);

  .template-preview-body {
    overflow: auto;
    height: calc(100% - var(--modal-footer-height));
    padding-bottom: var(--space-16);
    &::-webkit-scrollbar {
      &-thumb {
        background: var(--gray-4);
        border-right: 2px solid var(--gray-1);
      }
    }
  }
`;

export const TemplateButtonStyled = styled(Button)`
  border-radius: var(--br-1);
  .ant-typography,
  svg {
    color: var(--purple-7);
  }
`;

export const StyledEmailTemplatesModal = styled(GrowModal)`
  --templates-search-height: 70px;
  overflow: hidden;
  border-radius: 8px;
  .ant-modal-content {
    padding: var(--space-0);
  }

  .ant-modal-body {
    height: 410px;
    padding: var(--space-0);
  }
  .ant-modal-header {
    background: var(--gray-1);
    border-bottom: 1px solid var(--gray-4);
    padding: var(--space-12) var(--space-20);
    margin: 0;
  }
  .email-templates-sidebar {
    .ant-menu {
      height: calc(100% - var(--templates-search-height));
      overflow: auto;
      overflow-x: hidden;
    }
  }
`;
