import { useQueryClient } from '@tanstack/react-query';
import { Switch, Tooltip } from 'antd';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { useEditDocumentType } from 'queries/documents/documents';
import { DocumentTypesListCompProps } from 'types/entities/documents';

interface DocTypeEnableProps extends DocumentTypesListCompProps<'enabled' | 'is_default'> {
  id: string;
}
const t = {
  title: 'Default File',
  desc: 'This can’t be disabled',
  enable: 'Click to disable',
  disable: 'Click to enable',
};
function DocTypeEnable(props: DocTypeEnableProps) {
  const { enabled, is_default, id } = props;
  const queryClient = useQueryClient();
  const update = useEditDocumentType();

  function handleUpdate(enabled: boolean) {
    update.mutateAsync({ id, enabled }).then(() => {
      queryClient.invalidateQueries(['document-types-list']);
    });
  }

  const Title = (
    <Flex direction="column" gap="var(--space-4)">
      <TypographyText
        style={{ color: 'var(--geekblue-3)', fontSize: 'var(--fs-12)', lineHeight: '14px' }}
      >
        {t.title}
      </TypographyText>
      <TypographyText style={{ color: 'var(--gray-1)' }}>{t.desc}</TypographyText>
    </Flex>
  );
  const NonDefaultTitle = (
    <TypographyText style={{ color: 'var(--gray-1)' }}>
      {enabled ? t.enable : t.disable}
    </TypographyText>
  );
  return (
    <Tooltip title={is_default ? Title : NonDefaultTitle} placement="leftTop">
      <Switch
        size="small"
        disabled={is_default}
        checked={enabled}
        loading={update.isLoading}
        onChange={handleUpdate}
      />
    </Tooltip>
  );
}

export { DocTypeEnable };
