export interface IFilterType {
  AGING: string;
  OPEN_INVOICES: string;
  CLOSED_INVOICES: string;
  DRAFT_INVOICES: string;
}

export enum ELocalStorageVariable {
  OPEN_INVOICE_CUSTOMER_STATEMENTS = 'OPEN_INVOICE_CUSTOMER_STATEMENTS',
  DRAFT_INVOICE_CUSTOMER_STATEMENTS = 'DRAFT_INVOICE_CUSTOMER_STATEMENTS',
  CLOSED_INVOICE_CUSTOMER_STATEMENTS = 'CLOSED_INVOICE_CUSTOMER_STATEMENTS',
}

export type LocalStorageVariableType = {
  OPEN_INVOICE_CUSTOMER_STATEMENTS: {
    sort_by: string;
    sort_col: string;
    status: string[];
  };
  DRAFT_INVOICE_CUSTOMER_STATEMENTS: {
    sort_by: string;
    sort_col: string;
    status: string[];
  };
  CLOSED_INVOICE_CUSTOMER_STATEMENTS: {
    sort_by: string;
    sort_col: string;
    status: string[];
  };
};
