export enum WEEK {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THUS = 'THUS',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}
export enum Period {
  PREVIOUS_MONTH = 'PREVIOUS_MONTH',
  CURRENT_MONTH = 'CURRENT_MONTH',
  NEXT_MONTH = 'NEXT_MONTH',
}

export const PeriodsMonth = [
  {
    id: '1',
    label: '1',
    value: '1',
    day: 'Day 1',
  },
  {
    id: '2',
    label: '2',
    value: '2',
    day: 'Day 2',
  },
  {
    id: '3',
    label: '3',
    value: '3',
    day: 'Day 3',
  },
  {
    id: '4',
    label: '4',
    value: '4',
    day: 'Day 4',
  },
  {
    id: '5',
    label: '5',
    value: '5',
    day: 'Day 5',
  },
  {
    id: '6',
    label: '6',
    value: '6',
    day: 'Day 6',
  },
  {
    id: '7',
    label: '7',
    value: '7',
    day: 'Day 7',
  },
  {
    id: '8',
    label: '8',
    value: '8',
    day: 'Day 8',
  },
  {
    id: '9',
    label: '9',
    value: '9',
    day: 'Day 9',
  },
  {
    id: '10',
    label: '10',
    value: '10',
    day: 'Last Day',
  },
];

export const PeriodsWeek = [
  {
    id: '1',
    label: WEEK.MON,
    value: WEEK.MON,
    day: WEEK.MON,
  },
  {
    id: '2',
    label: WEEK.TUE,
    value: WEEK.TUE,
    day: WEEK.TUE,
  },
  {
    id: '3',
    label: WEEK.WED,
    value: WEEK.WED,
    day: WEEK.WED,
  },
  {
    id: '4',
    label: WEEK.THUS,
    value: WEEK.THUS,
    day: WEEK.THUS,
  },
  {
    id: '5',
    label: WEEK.FRI,
    value: WEEK.FRI,
    day: WEEK.FRI,
  },
  {
    id: '6',
    label: WEEK.SAT,
    value: WEEK.SAT,
    day: WEEK.SAT,
  },
  {
    id: '7',
    label: WEEK.SUN,
    value: WEEK.SUN,
    day: WEEK.SUN,
  },
];

export const PeriodOptions = [
  { key: '1', value: Period.CURRENT_MONTH, label: 'Current Month' },
  { key: '2', value: Period.NEXT_MONTH, label: 'Next Month' },
  { key: '3', value: Period.PREVIOUS_MONTH, label: 'Previous Month' },
];
