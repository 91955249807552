import { Popover } from 'antd';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';

import { convertFromRaw, EditorState, RawDraftContentState } from 'draft-js';

import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { MentionData } from '@draft-js-plugins/mention';

import { GrowText } from '../Typography';
import { PopoverContent } from './index';
import { decodeEditorState } from './serializer';
import { MentionBox } from './styles';

interface Props {
  rawDraftContentStateJson: string;
  mentions: Record<string, MentionData>;
}

export function CommentRead(props: Props) {
  const ref = useRef<Editor>(null);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  useLayoutEffect(() => {
    if (props.rawDraftContentStateJson) {
      setTimeout(() => {
        const rawContentState = decodeEditorState(
            JSON.parse(props.rawDraftContentStateJson),
            props.mentions
          ),
          contentState = convertFromRaw(rawContentState as RawDraftContentState);

        setEditorState(EditorState.createWithContent(contentState));
      });
    }
  }, [props.mentions, props.rawDraftContentStateJson]);

  const plugins = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      supportWhitespace: true,
      mentionPrefix: '@',
      mentionComponent(mentionProps) {
        return (
          <Popover content={PopoverContent(mentionProps.mention)}>
            <GrowText color="var(--primary-7)">{mentionProps.children}</GrowText>
          </Popover>
        );
      },
    });

    return [mentionPlugin];
  }, []);

  return (
    <MentionBox style={{ '--read-height': '0', '--comment-border': 'none' } as React.CSSProperties}>
      <div className="editor">
        <Editor
          editorKey={'editor'}
          editorState={editorState}
          onChange={setEditorState}
          readOnly={true}
          plugins={plugins}
          ref={ref}
        />
      </div>
    </MentionBox>
  );
}
