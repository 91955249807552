import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Empty, Menu } from 'antd';
import { GrowText } from 'components/BaseComponents/Typography';
import { MenuProps } from 'components/FilterViews/type';
import {
  StyledEmptyPlaceHolderSections,
  StyledSearchResultCount,
  StyledSidebarContainer,
} from 'components/Insert/Placeholders/Body/Menu/style';
import React, { useMemo, useState } from 'react';
import { EmailTemplates } from 'types/entities/email-templates';
import { GroupedTemplates } from '..';
import { Flex } from '../../../BaseComponents/Layout/Flex/index';
import { PlaceholderSearch } from '../Search';

interface InsertEmailTemplatesSideBarProps {
  sideBarOverrideStyles?: React.CSSProperties;
  onTemplateSelect: ((selectedTemplate: EmailTemplates) => void) | undefined;
  onTemplateChange: (template: EmailTemplates | undefined) => void;
  selectedKey: string | undefined;
  groupedTemplates: GroupedTemplates[] | undefined;
  togglePreview: (visibility: boolean) => void;
}

const sideBarMenuStyles: React.CSSProperties = {
  padding: 'var(--space-8) var(--space-0)',
};

const t = {
  emptyPlaceholder: 'No results found. Please try a different search term.',
  searchResult: 'Results Found',
};

const menuItemStyles: React.CSSProperties = {
  width: 'calc(100% - var(--space-16))',
  display: 'flex',
  alignItems: 'center',
};

function InsertEmailTemplatesSideBar(props: InsertEmailTemplatesSideBarProps) {
  const [searchKeyword, setSearchKeyword] = useState('');

  function handleClearSearch() {
    setSearchKeyword('');
  }

  const Search = (
    <PlaceholderSearch
      onSearchCallBack={setSearchKeyword}
      onClear={handleClearSearch}
      searchQuery={searchKeyword}
    />
  );

  const filteredTemplateSections = useMemo(() => {
    if (searchKeyword) {
      return props.groupedTemplates?.map((folder) => {
        const templates = folder.templates.filter((template) =>
          template.title.toLowerCase().includes(searchKeyword)
        );
        return { ...folder, templates };
      });
    }
    return props.groupedTemplates;
  }, [props.groupedTemplates, searchKeyword]);

  const templatesCountOnSearch = useMemo(() => {
    let count = 0;
    if (searchKeyword) {
      filteredTemplateSections?.forEach((folder) => {
        count += folder.templates.length;
      });
      count ? props.togglePreview(true) : props.togglePreview(false);
    }
    return count;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTemplateSections, searchKeyword]);

  const ResultCountView = (
    <StyledSearchResultCount>{`${templatesCountOnSearch} ${t.searchResult}`}</StyledSearchResultCount>
  );

  const SearchResultCountView = templatesCountOnSearch ? ResultCountView : null;

  function handleTemplateSelect(selectedTemplateId: string) {
    const [folderId, templateId] = selectedTemplateId.split('-');
    const folder = props?.groupedTemplates?.find((folder) => folder.id === Number(folderId));
    const template = folder?.templates.find((template) => template.id === Number(templateId));
    props.onTemplateChange(template);
  }

  const RightArrowIcon = <FontAwesomeIcon icon={['far', 'chevron-right']} />;

  const items: MenuProps['items'] = filteredTemplateSections?.map((folder) => ({
    key: `${folder.id}`,
    type: 'group',
    label: folder.name,
    children: folder.templates.map((template) => ({
      key: `${template.template_folder_id}-${template.id}`,
      label: (
        <Flex gap="var(--space-2)" justify="space-between" align="center">
          <Flex.Child style={menuItemStyles}>
            <GrowText
              style={{ marginRight: 'var(--space-2)', maxWidth: '100%' }}
              ellipsis={{
                tooltip: { title: template.title, align: { targetOffset: [0, -15] } },
              }}
            >
              {template.title}
            </GrowText>
          </Flex.Child>
          <Flex.Child>{RightArrowIcon}</Flex.Child>
        </Flex>
      ),
    })),
  }));

  const EmailTemplatesFoldersView = (
    <>
      {SearchResultCountView}
      <Menu
        mode="inline"
        onSelect={({ key }) => handleTemplateSelect(key)}
        style={{ ...sideBarMenuStyles, ...props.sideBarOverrideStyles }}
        selectedKeys={[props?.selectedKey ?? '']}
        items={items}
      />
    </>
  );

  const EmptyPlaceHolderSections = (
    <StyledEmptyPlaceHolderSections justify="center" align="center">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t.emptyPlaceholder} />
    </StyledEmptyPlaceHolderSections>
  );

  const MenuSectionView =
    !templatesCountOnSearch && searchKeyword ? EmptyPlaceHolderSections : EmailTemplatesFoldersView;

  return (
    <StyledSidebarContainer className="email-templates-sidebar">
      {Search}
      {MenuSectionView}
    </StyledSidebarContainer>
  );
}

export { InsertEmailTemplatesSideBar };
