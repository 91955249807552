import { Flex } from 'components/BaseComponents/Layout/Flex';
import { EditorCanvas } from 'components/BaseComponents/RTE/Editor';
import { GrowText } from 'components/BaseComponents/Typography';
import { useRichTextEditor } from 'lib/RichText/TipTapEditor/hooks/useRichTextEditor';
import { EmailTemplates } from 'types/entities/email-templates';

interface BodyPreviewProps {
  selectedTemplate?: EmailTemplates;
}
function BodyPreview(props: BodyPreviewProps) {
  const { selectedTemplate } = props;
  const { editor } = useRichTextEditor({
    editable: false,
    value: selectedTemplate?.body,
  });

  const preview = <EditorCanvas editor={editor} />;
  return (
    <Flex direction="column" gap="var(--space-6)">
      <GrowText style={{ color: 'var(--gray-7)' }}>Body</GrowText>
      {preview}
    </Flex>
  );
}

export { BodyPreview };
