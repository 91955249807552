/*
This is Sidebar Page

- This shows list of routes to go to
- Inputs:
  - Links
    - url / sublinks
    - icon
    - label
  - Sublinks
    - icon
    - label

- What it does:
  - It generates links for the given urls
  - It styles currently active route
    - using: let resolved = useResolvedPath(to), let match = useMatch({ path: resolved.pathname, end: true });
*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { matchPath, useLocation } from 'lib/router';
import {
  PopOverLink,
  PopOverTitle,
  StyledGroupedNavLink,
  StyledNavLink,
  expandedIconStyle,
} from './sidebar.styles';
import { dispatchNavigationEvent } from './sidebar.utils';

interface BaseRoute {
  icon: React.ReactNode;
  label: string;
  onOpenChange?: (open: boolean) => void;
  index?: number;
}
export interface AppSubRoute {
  label: string;
  url: string;
}
interface PrimitiveRoute extends BaseRoute {
  url: string;
}
interface GroupedRoute extends BaseRoute {
  subLinks: AppSubRoute[];
}

export function PrimitiveRouteLink({ url, icon, label, onOpenChange, index }: PrimitiveRoute) {
  return (
    <Tooltip
      title={label}
      color="var(--purple-7)"
      placement="right"
      onOpenChange={index === 0 ? onOpenChange : undefined}
    >
      <StyledNavLink
        to={url}
        state={{ fromSidebar: true }}
        onClick={() => dispatchNavigationEvent(label)}
      >
        <span className="icon">{icon}</span>
      </StyledNavLink>
    </Tooltip>
  );
}

export function GroupedRouteLink({
  subLinks: sublinks,
  icon,
  label,
  onOpenChange,
  index,
}: GroupedRoute) {
  const location = useLocation();

  if (!sublinks.length) return null;

  const activeLinkUrl = (() => {
    const activeRoute = sublinks.find((sublink) => matchPath(sublink.url, location.pathname));
    return activeRoute?.url;
  })();

  if (sublinks.length === 1) {
    return (
      <PrimitiveRouteLink
        url={sublinks[0].url}
        icon={icon}
        label={label}
        onOpenChange={onOpenChange}
        index={index}
      />
    );
  }

  // if 1 sublink with privileges -> show just icon
  // if no sublink with privileges -> show nothing

  if (sublinks.length === 1) {
    return (
      <PrimitiveRouteLink
        url={sublinks[0].url}
        icon={icon}
        label={label}
        onOpenChange={onOpenChange}
        index={index}
      />
    );
  }

  const LinkGroup = (
    <StyledGroupedNavLink as="div" className={activeLinkUrl ? 'active' : ''}>
      <span className="icon">{icon}</span>
      <FontAwesomeIcon
        style={expandedIconStyle}
        icon={['far', 'grip-dots-vertical']}
        color="var(--gray-1)"
      />
    </StyledGroupedNavLink>
  );

  const Routes = (
    <Flex direction="column" gap="--space-4" style={{ minWidth: '9vw' }}>
      <PopOverTitle strong>{label}</PopOverTitle>
      {sublinks.map((sublink) => (
        <PopOverLink
          key={sublink.url}
          to={sublink.url}
          state={{ fromSidebar: true }}
          onClick={() => dispatchNavigationEvent(label)}
          className={activeLinkUrl === sublink.url ? 'active' : ''}
        >
          {sublink.label}
        </PopOverLink>
      ))}
    </Flex>
  );

  return (
    <Popover
      trigger={'hover'}
      placement="right"
      content={Routes}
      arrow={false}
      onOpenChange={index === 0 ? onOpenChange : undefined}
    >
      {LinkGroup}
    </Popover>
  );
}
