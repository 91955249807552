import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import CommentComponent from 'components/HigherOrderComponent/KeyActivitesContainer/Comment/comment';
import React, { useCallback, useState } from 'react';
import { ActivityType } from 'types/activities/activity-types';
import { Commento } from 'types/activities/comment';

const t = {
  comment: 'Comment',
};

interface CommentForm {
  style?: React.CSSProperties;
  referenceActivityId: number;
  referenceType: ActivityType;
  customerId: number;
  newCommentCallBack: (commentAdded: Commento) => void;
  isOpen?: boolean;
  onCancel?: () => void;
  wrapperStyle?: React.CSSProperties;
}
export function Form(props: CommentForm) {
  const [showForm, setShowForm] = useState(props.isOpen ?? false);
  const { style, referenceActivityId, newCommentCallBack, customerId, referenceType, onCancel } =
    props;

  const cancelCallBack = useCallback(() => {
    setShowForm(false);
    onCancel?.();
  }, [onCancel]);

  const CommentForm = (
    <div
      style={{
        borderRadius: '6px',
        border: '1px solid var(--gray-4)',
        boxShadow: 'var(--shadow-2)',
      }}
    >
      <CommentComponent
        referenceIds={[referenceActivityId]}
        customerId={customerId}
        referenceType={referenceType}
        updateOnSuccess={(commentAdded: unknown) => {
          newCommentCallBack(commentAdded as Commento);
          setShowForm(false);
        }}
        cancelCallBack={cancelCallBack}
      />
    </div>
  );

  const CommentButton = (
    <Button
      icon={<FontAwesomeIcon icon={['fal', 'comment-alt-lines']} color="var(--primary-7)" />}
      onClick={() => setShowForm((form) => !form)}
      style={{ color: 'var(--primary-7)' }}
    >
      {t.comment}
    </Button>
  );

  return <div style={{ ...style }}>{showForm ? CommentForm : CommentButton}</div>;
}
