import { Outlet, PageNavigate } from 'lib/router';
import { useMemo } from 'react';
import { pagePath } from 'router/constants/page-info';
import { PageContainer } from 'router/PageContainer';
import { useDashboardAccess } from './hooks/useDashboardAccess';

export function Dashboard() {
  return (
    <PageContainer page={'DASHBOARD'}>
      <Outlet />
    </PageContainer>
  );
}

export function DefaultDashboard() {
  const {
    hasAdminDashboardAccess,
    hasCollectorDashboardAccess,
    hasTeam,
    hasTeamDashboardAccess,
    hasDashboardCollaboratorAccess,
  } = useDashboardAccess();

  function handleDefaultDashboardPath() {
    if (hasAdminDashboardAccess) {
      return 'ADMIN_DASHBOARD';
    }
    if (hasCollectorDashboardAccess) {
      return 'COLLECTOR_DASHBOARD';
    }

    if (hasTeam && hasTeamDashboardAccess) {
      return 'TEAM_DASHBOARD';
    }

    if (hasDashboardCollaboratorAccess) {
      return 'COLLABORATOR_DASHBOARD';
    }

    return 'ADMIN_DASHBOARD';
  }

  const path = useMemo(handleDefaultDashboardPath, [
    hasAdminDashboardAccess,
    hasCollectorDashboardAccess,
    hasDashboardCollaboratorAccess,
    hasTeam,
    hasTeamDashboardAccess,
  ]);

  return <PageNavigate to={pagePath(path)} />;
}
