import { useSelector } from 'react-redux';
import { baseCurrencySelector } from 'store/authentication/authentication';
import { getCurrencySymbol } from 'util/number-formatter';

function useGetBaseCurrencySymbol() {
  const baseCurrency = useSelector(baseCurrencySelector) ?? 'USD';
  const currencySymbol = getCurrencySymbol(baseCurrency);
  return { currencySymbol };
}

export default useGetBaseCurrencySymbol;
