import { PlotEvent } from '@ant-design/plots';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ListSkeleton } from 'components/BaseComponents/Skeletons/ListSkeleton';
import { GrowText } from 'components/BaseComponents/Typography';
import ColumnChart from 'components/Common/Charts/Column';
import { ColumnConfigProps } from 'components/Common/Charts/types';
import { bucketColorChart } from 'components/ReceivableSettings/ReceivableDisplay/constant';
import { usePageNavigate } from 'lib/router';
import { sum } from 'lodash';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useOverDueInvoiceByAgeQuery } from 'queries/admin-dashboard';
import React, { useMemo, useState } from 'react';
import { NonUndefined } from 'types/utils/utils';
import { getPercentage } from 'util/number-formatter';
import Tooltip from './Tooltip/tooltip';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';

type ColumChartProps = Parameters<
  NonUndefined<React.ComponentProps<typeof ColumnChart>['onReady']>
>['0'];
function Aging({ filterParams }: DashboardComponentProps) {
  const navigate = usePageNavigate();
  const { data: agingBuckets, isLoading: loading } = useOverDueInvoiceByAgeQuery(filterParams);
  const [splitLabel, setSplitLabel] = useState(false);
  const splitLabelLimit = 6;
  const formattedData = useMemo(processChartData, [agingBuckets]);
  const locale = useSelector(localeSelector);
  const gray9 = '#262626';
  const gray7 = '#8C8C8C';
  const t = {
    title: 'Overdue Invoices by Aging Buckets',
    xAxis: 'Days',
    yAxis: 'Amount (%)',
  };

  function processChartData() {
    const totalAmount = sum(agingBuckets?.map((agingBucket) => agingBucket.amount.amount.value));
    return (agingBuckets ?? []).map((agingBucket, index) => {
      const label =
        index + 1 === agingBuckets?.length
          ? `> ${agingBucket.bucket_from - 1}`
          : `${agingBucket.bucket_from}-${agingBucket.bucket_to}`;
      if (label.length >= splitLabelLimit) setSplitLabel(true);
      return {
        value: agingBucket.amount.amount.value,
        age: label,
        invoices: agingBucket.amount.invoice_count,
        bucket_from: agingBucket.bucket_from,
        bucket_to: agingBucket.bucket_to,
        type: bucketColorChart[index],
        totalAmount,
        currency: agingBucket.amount.amount.currency,
      };
    });
  }
  function handleOnReady(plot: ColumChartProps) {
    plot.on('plot:click', handleColumnClick);
  }

  function handleColumnClick(event: PlotEvent) {
    event.gEvent.preventDefault();
    event.gEvent.stopPropagation();
    if (event.data) {
      const metaActivityData = new URLSearchParams({
        sort_col: 'TOTAL_DUE_AMOUNT',
        sort_by: 'DESC',
        due_days_min: `${event.data.data.bucket_from}`,
        due_days_max: `${event.data.data.bucket_to}`,
        due_days_range: `${event.data.data.age} days`,
      });
      navigate({
        appPage: 'AR_AGING',
        searchParams: metaActivityData,
        state: {
          addPrimaryPathAsChildPath: true,
        },
      });
    }
  }

  const chartData = formattedData;
  const chartConfig: Omit<ColumnConfigProps, 'data'> = {
    className: 'aging-chart',
    renderer: 'canvas',
    columnWidthRatio: 1,
    xField: 'age',
    yField: 'value',
    legend: false,
    seriesField: 'type',
    color: ({ type }) => type,
    onReady: handleOnReady,
    xAxis: {
      tickLine: {
        style: {
          opacity: 0,
        },
      },
      title: {
        text: t.xAxis,
        offset: 55,
        position: 'middle',
        style: {
          fill: gray7,
        },
      },
      label: {
        style: {
          fontSize: 12,
          lineHeight: 15,
          fill: gray9,
        },
        formatter: (value: string) => {
          if (splitLabel && value.includes('-') && formattedData.length > 10) {
            const arr: string[] = value.split('-');
            return ` ${arr[0]}-
${arr[1]}`;
          }
          return value;
        },
      },
    },
    yAxis: {
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
      title: {
        text: t.yAxis,
        offset: 15,
        position: 'middle',
        style: {
          fill: gray7,
        },
      },
      label: {
        style: {
          opacity: 0,
        },
        offsetX: 100,
      },
    },
    height: 200,
    tooltip: {
      customContent: (title, content) => {
        return Boolean(content.length) && <Tooltip content={content} locale={locale} />;
      },
    },
    label: {
      position: 'top',
      offsetY: 10,
      style: {
        fill: gray9,
      },

      formatter: (datum) => getPercentage(datum.value, datum.totalAmount),
    },
    padding: [20, 0, 70, 20],
  };

  return (
    <div id="aging-bar">
      <Flex direction="column" gap="var(--space-8)">
        <GrowText color="var(--gray-9)" size={'var(--fs-20)'} strong>
          {t.title}
        </GrowText>
        {loading ? (
          <ListSkeleton itemsCount={1} style={{ height: '200px' }} />
        ) : (
          <ColumnChart {...chartConfig} data={chartData} />
        )}
      </Flex>
    </div>
  );
}

export default React.memo(Aging);
