import { Tooltip } from 'antd';
import { IconBillingContacts } from 'assets/icons';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { isEmpty, isEqual } from 'lodash';
import { Fragment } from 'react';
import { AccountConfigKey } from 'types/entities/account';
import { Contact } from 'types/entities/contact';

const t = {
  tooltipHeading: 'Invoice Contacts',
  noBillingContact: 'No Billing Contact',
  assigned: 'assigned',
};

interface BillingContactIndicatorProps {
  contacts?: Contact[];
  hideWidth?: boolean;
}

export default function BillingContactIndicator(props: BillingContactIndicatorProps) {
  const { contacts, hideWidth } = props;
  const billingContactCount = contacts?.length;
  const advancedContactEnabled = useConfig(AccountConfigKey.ADVANCED_CONTACTS);

  if (!advancedContactEnabled) {
    return null;
  }

  const TooltipTitleWithBillingContact = (
    <Flex direction="column">
      <Texto color="var(--geekblue-3)" style={{ marginBottom: 'var(--space-4)' }}>
        {t.tooltipHeading} ({billingContactCount})
      </Texto>
      {contacts?.map((contact, contactItemsIndex) => {
        const firstName = contact?.first_name ?? '';
        const lastName = contact?.last_name ?? '';
        const isNameEmpty = isEmpty(firstName) && isEmpty(lastName);
        let displayName = isNameEmpty ? contact?.email : `${firstName} ${lastName}`;
        const contactHasValidName = !isNameEmpty;

        if (contactHasValidName) {
          const contactWithDuplicateName = contacts?.find(
            (item, findContactIndex) =>
              !isEqual(findContactIndex, contactItemsIndex) &&
              isEqual([item?.first_name ?? '', item?.last_name ?? ''], [firstName, lastName])
          );
          displayName += contactWithDuplicateName && contact.email ? `(${contact.email})` : '';
        }

        return (
          <Texto color="var(--gray-1)" key={contact.id}>
            {displayName}
          </Texto>
        );
      })}
    </Flex>
  );

  const NoBillingContactTitle = (
    <Fragment>
      {t.noBillingContact} <br />
      {t.assigned}
    </Fragment>
  );

  const TooltipTitle = billingContactCount
    ? TooltipTitleWithBillingContact
    : NoBillingContactTitle;

  return (
    <Flex.Child>
      <Tooltip title={TooltipTitle}>
        <Flex
          style={{ minWidth: hideWidth ? 'none' : 'var(--space-32)' }}
          gap="var(--space-2)"
          justify="flex-start"
          align="center"
        >
          <IconBillingContacts
            fill={billingContactCount ? 'var(--geekblue-6)' : 'var(--gray-6)'}
            height="16px"
            width="16px"
          />
          <Texto color="var(--geekblue-6)">
            {(billingContactCount ?? 0) > 0 ? billingContactCount : null}
          </Texto>
        </Flex>
      </Tooltip>
    </Flex.Child>
  );
}
