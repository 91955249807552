import { ErrorBoundary } from '@sentry/react';
import { EditorCanvas } from 'components/BaseComponents/RTE/Editor';
import { GrowText } from 'components/BaseComponents/Typography';
import { RichContentEditorWrapper, TIPTAP_EMPTYLINE } from 'lib/RichText';
import { ReactNode, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { Editor } from '../../../../lib/RichText/type';
import Toolbar from '../Toolbar/Toolbar';
// Option 2: Browser-only (lightweight)

const WrapperStyled = styled.div<{ isEditable: boolean }>`
  overflow: auto;
  border: ${(props) => (props.isEditable ? '1px solid var(--gray-5)' : 'none')};
  position: relative;
  border-radius: var(--br-1);

  .insert-button-container {
    .ant-btn {
      height: var(--space-24);
      &:hover {
        background: var(--purple-1);
      }
    }
  }

  &.editor-focussed,
  &:focus,
  &:focus-within {
    border: 1px solid var(--purple-5);
  }
`;

interface RichContentEditorProps {
  editor: Editor | null;
  extras?: ReactNode;
  signature?: string | null;
  viewOnly?: boolean;
  wrapperStyles?: CSSProperties;
  wrapperClassname?: string;
}

export function isEmptyTag(str: string) {
  //Empty tag only represents only exported empty tag and
  // not within the engine
  return TIPTAP_EMPTYLINE.test(str);
}

export function RichContentEditor(props: RichContentEditorProps) {
  const [open, setOpen] = useState(false);
  if (!props.editor) return null;

  function handleVisibleChange(visible: boolean) {
    setOpen(visible);
  }

  const Header = !props?.viewOnly ? (
    <Toolbar
      extras={props?.extras}
      signatureText={props?.signature === null ? undefined : props?.signature}
      editor={props.editor}
      open={open}
      onOpenVisible={handleVisibleChange}
    />
  ) : null;

  const Body = (
    <RichContentEditorWrapper id="rich-content-editor">
      <EditorCanvas editor={props?.editor} />
    </RichContentEditorWrapper>
  );
  return (
    <div>
      <ErrorBoundary fallback={<GrowText>error</GrowText>}>
        <WrapperStyled isEditable={props.editor.isEditable} className={props?.wrapperClassname}>
          {Header}
          {Body}
        </WrapperStyled>
      </ErrorBoundary>
    </div>
  );
}
