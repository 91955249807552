import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { DropdownButton } from 'components/BaseComponents/RTE/Toolbar/common/DropdownWrapper';
import { TextSizes, Texto } from 'components/BaseComponents/Typography/Texto';
import { formattingOptions } from 'lib/RichText';

import { commonButtonProps } from '../../Toolbar';
import { ToolbarOptionProps } from '../../type';
import { GrowTypography } from '@sinecycle/growcomponents';

const t = {
  small: 'Small',
  normal: 'Normal',
  large: 'Large',
  huge: 'Huge',
  label: 'Size',
};

const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
  title: t.label,
};

interface FontSizeList {
  key: string;
  label: string;
  size: Exclude<TextSizes, '30' | '38' | '46' | '56'>;
}

const fontSizeList: FontSizeList[] = [
  {
    key: 'small',
    label: t.small,
    size: '12',
  },
  {
    key: 'normal',
    label: t.normal,
    size: '14',
  },
  {
    key: 'large',
    label: t.large,
    size: '20',
  },
  {
    key: 'huge',
    label: t.huge,
    size: '24',
  },
];

const Icon = <FontAwesomeIcon icon={['far', 'text-size']} />;
interface FontSizeProps extends ToolbarOptionProps {}

export function FontSize({ editor }: FontSizeProps) {
  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    icon: Icon,
  };

  const FontSizeButton = <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />;

  function onFontSizeSelectCallback(size: any) {
    formattingOptions['fontSize'].onClick(editor, size);
  }

  function fontSizeListCallback(font: FontSizeList) {
    return {
      key: font.size,
      label: (
        <div key={font.key}>
          <Space>
            <GrowTypography.Text fs={font.size}>{font.label}</GrowTypography.Text>
          </Space>
        </div>
      ),
    };
  }

  const fontSizeItems = fontSizeList.map(fontSizeListCallback);

  return (
    <DropdownButton
      menu={{
        items: fontSizeItems,
        onClick: (info) => {
          onFontSizeSelectCallback(info.key);
        },
      }}
      placement="top"
    >
      {FontSizeButton}
    </DropdownButton>
  );
}
