import { InputNumber } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { EllipsisList } from 'components/Common/EllipsisList';
import { map } from 'lodash';
import InvoiceSelect from '../FormElements/InvoiceSelect';
import { ActivityFormItem, EllipsisListContainer } from '../style';
import { WriteOffFormModalProps, WriteOffModalFormElementProps } from '../types';

export default function WriteOffFormItems<WriteOffElements extends WriteOffModalFormElementProps>(
  props: Omit<WriteOffFormModalProps<WriteOffElements>, 'openModal'>
) {
  const {
    invoices,
    form,
    invoiceQueryKey,
    invoiceSearchFn,
    invoiceQueryFn,
    invoiceTransformSchema,
    text,
    bulkRecordsLength,
    bulkAction,
    customerCurrency,
  } = props;

  const {
    invoiceLabel,
    invoicePlaceholder,
    descriptionLabel,
    descriptionPlaceholder,
    titleLabel,
    titlePlaceholder,
  } = text;

  const selectedInvoiceIds = map(invoices, (item) => (item as { id: number })?.id);

  const selectedInvoiceNo = map(invoices, (item) => (item as any)?.invoice_no);
  function onChangeInvoiceList(invoiceList: number[]) {
    form.setFieldValue('writeOffInvoices', invoiceList);
  }

  const InvoiceSelectList = (
    <ActivityFormItem
      label={invoiceLabel}
      name="writeOffInvoices"
      initialValue={selectedInvoiceIds}
    >
      <InvoiceSelect<WriteOffElements['invoiceSelect']>
        placeholder={invoicePlaceholder}
        selectedInvoicesId={selectedInvoiceIds}
        selectedInvoices={invoices}
        onChangeInvoice={onChangeInvoiceList}
        queryFn={invoiceQueryFn}
        searchQueryFn={invoiceSearchFn}
        queryKey={invoiceQueryKey}
        transformSchema={invoiceTransformSchema}
      />
    </ActivityFormItem>
  );

  const WriteOffInput = (
    <ActivityFormItem
      label={titleLabel}
      name="writeOffInput"
      rules={[
        {
          required: true,
          message: `${titleLabel} is required`,
        },
      ]}
      required
    >
      <InputNumber
        placeholder={titlePlaceholder}
        prefix={customerCurrency}
        style={{ width: '100%' }}
      />
    </ActivityFormItem>
  );

  const WriteOffDescription = (
    <ActivityFormItem
      label={descriptionLabel}
      name="writeOffDescription"
      rules={[
        {
          required: true,
          message: `${descriptionLabel} is required`,
        },
      ]}
      required
    >
      <TextArea placeholder={descriptionPlaceholder} />
    </ActivityFormItem>
  );

  return (
    <>
      {bulkAction ? (
        <EllipsisListContainer>
          <EllipsisList
            label="Added Invoices"
            list={map(selectedInvoiceNo, (item) => String(item))}
            totalRecords={bulkRecordsLength}
            showScrollablePopover
          />
        </EllipsisListContainer>
      ) : (
        InvoiceSelectList
      )}
      {WriteOffInput}
      {WriteOffDescription}
    </>
  );
}
