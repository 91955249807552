import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSS } from '@stitches/react';
import { Tooltip } from 'antd';
import { GrowBadge } from 'components/BaseComponents/Badge';
import { getKeyActivityConfig } from 'components/HigherOrderComponent/KeyActivitesContainer/key-acivities-config';
import React from 'react';
import { ELastContactActivity } from 'store/activity-feed/type';
import { ActivityType } from 'types/activities/activity-types';
import { ActivityDetail } from 'types/api/inbox/activity';
import useUpdateReadActivity from '../hooks/useUpdateReadActivity';
import { ActivityIconButton } from './style';

const t = {
  markAsRead: 'Mark as Read',
  markAsUnread: 'Mark as Unread',
};

export type ViewMode = React.ComponentProps<typeof ActivityIconButton>['viewMode'];
interface ToggleActivityReadButtonProps {
  activity: ActivityDetail<ActivityType>;
  hideDot?: boolean;
  viewMode?: ViewMode;
  css?: CSS;
}
function ToggleActivityReadButton(props: ToggleActivityReadButtonProps) {
  const { activity, hideDot, viewMode, css } = props;

  const { handleClick } = useUpdateReadActivity(
    activity.user_activity_id,
    activity.read,
    activity.entity_type
  );
  const activityConfig = getKeyActivityConfig(
    activity.entity_type as unknown as ELastContactActivity
  );
  const HideIcon = (
    <Tooltip title={activity.read ? t.markAsUnread : t.markAsRead}>
      <FontAwesomeIcon
        icon={activity.read ? ['far', 'eye-slash'] : ['far', 'eye']}
        fontSize={12}
        color="var(--primary-7)"
        className="hide-icon"
        style={{ margin: 0 }}
      />
    </Tooltip>
  );

  const DefaultIcon = (
    <FontAwesomeIcon
      icon={activityConfig?.icon as IconProp}
      color={activityConfig?.color}
      fontSize={14}
      className="default-icon"
    />
  );
  const Icon = (
    <>
      {DefaultIcon}
      {HideIcon}
    </>
  );

  return (
    <GrowBadge dot={hideDot ? !hideDot : !activity.read} read="read-status">
      <ActivityIconButton
        icon={Icon}
        size="small"
        onClick={handleClick}
        viewMode={viewMode}
        css={css}
        hideBackground
      />
    </GrowBadge>
  );
}

export { ToggleActivityReadButton };
