import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import styled from 'styled-components';

export const PopoverButton = styled(Button)`
  padding: var(--space-8);
  display: flex;
  align-items: center;
  min-width: 100px;

  .show-on-hover {
    opacity: 0;
  }
  &:hover {
    .show-on-hover {
      opacity: 1;
    }
  }
  & > svg + * {
    margin-left: 0;
  }
`;

export const FilterStrip = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-12);
  flex-wrap: wrap;
  background-color: var(--geekblue-1);
  padding: var(--space-12) var(--space-24);
  box-shadow: var(--shadow-1);
`;

export const ClearIcon = styled(FontAwesomeIcon)`
  background: var(--primary-2);
  border-radius: 50%;
  color: var(--primary-7);
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background: var(--primary-4);
    color: var(--primary-9);
  }
`;
