import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { usePageNavigate } from 'lib/router';
import { useSelector } from 'react-redux';
import { cprCurrentFilterSelector } from 'store/collectors-performace/selectors';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  span {
    color: var(--purple-7);
  }
`;
const t = {
  modify_target: 'Modify Target',
};
interface ModifyTargetButtonProps {
  isHide: boolean;
}
function ModifyTargetButton(props: ModifyTargetButtonProps) {
  const { isHide } = props;
  const navigate = usePageNavigate();
  const currentFilter = useSelector(cprCurrentFilterSelector);
  function handleClick() {
    navigate({ appPage: 'COLLECTORS_MANAGEMENT' });
  }
  const Icon = <FontAwesomeIcon icon={['far', 'pen']} color="var(--primary-7)" />;

  const ModifyTarget = (
    <StyledButton color="var(--primary-7)" icon={Icon} onClick={handleClick}>
      {t.modify_target}
    </StyledButton>
  );
  return currentFilter.current && !isHide ? ModifyTarget : null;
}

export default ModifyTargetButton;
