import { StrategyErrorFields } from 'store/collection-strategy/validations';
import { CollectionStrategy, FollowupTrackType } from 'types/entities/collection-strategy';
import { FollowupRuleType, StoppingRule } from 'types/entities/collection-strategy/rule';
import {
  FollowupEventType,
  InvoiceLifecycleStage,
  StatusChangeEventEntityType,
} from 'types/entities/collection-strategy/rule-event';

const defaultStoppingRule: StoppingRule = {
  id: 'stopping-rule-1',
  type: FollowupRuleType.STOPPING_RULE,
  data: {
    event: {
      id: 'stopping-rule-1-event-1',
      type: FollowupEventType.STATUS_CHANGE,
      data: {
        entity: StatusChangeEventEntityType.INVOICE,
        status: InvoiceLifecycleStage.CLOSED,
        offset: 0,
      },
    },
  },
};

export interface InitialState {
  strategy: CollectionStrategy;
  errors: StrategyErrorFields;
  showErrors: boolean;
}

const initialCollectionStrategy: CollectionStrategy = {
  title: 'New Followup Strategy',
  enabled: true,
  invoice_followup: {
    enabled: false,
    serial_followup: false,
    recurring_action: {
      enabled: false,
      days: 0,
    },
    auto_resume: {
      enabled: false,
      offset: 0,
    },
    tracks: [
      {
        track_type: FollowupTrackType.AGE_TRACK,
        dunning_rules: [],
        pausing_rules: [],
        stopping_rules: [defaultStoppingRule],
      },
    ],
  },
  statement_followup: {
    enabled: false,
    pause_and_resume: false,
    tracks: [
      {
        track_type: FollowupTrackType.PERIODIC,
        dunning_rules: [],
        pausing_rules: [],
        stopping_rules: [],
      },
    ],
  },
  settings: {
    email_delivery: {
      enabled: false,
      delivery_days: [],
      delivery_time: '09:00:00',
    },
  },
};

export const initialState: InitialState = {
  strategy: initialCollectionStrategy,
  errors: {},
  showErrors: false,
};
