import { ActionsModal } from 'components/HigherOrderComponent/KeyActionsContainer/ActionsModal';
import { EActions } from 'components/HigherOrderComponent/KeyActionsContainer/types';
import ActivitiesModal from 'components/HigherOrderComponent/KeyActivitesContainer/ActivitiesModal';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ELastContactActivity } from 'store/activity-feed/type';
import { totalCustomerRecordsSelector } from 'store/invoice-v2/open-invoice-selectors';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
interface QuickActionsProps {
  currentFilter: any;
  queryKey?: string[];
}

export function useQuickActions(props: QuickActionsProps) {
  const { currentFilter } = props;
  const [selectAll, setSelectAll] = useState(false);
  const [quickAction, setQuickAction] = useState<ELastContactActivity | undefined>();
  const [invoiceList, setInvoiceList] = useState<InvoiceDropdownInterfaceProps[]>([]);
  const [action, setAction] = useState<EActions | undefined>();
  const [totalRecords, setTotalRecords] = useState<number>();

  const totalCustomerRecords = useSelector(totalCustomerRecordsSelector);

  function resetToIntialState() {
    setQuickAction(undefined);
    setAction(undefined);
    setInvoiceList([]);
  }

  function setQuickActivitiesCallback(
    action: ELastContactActivity,
    invoiceList: InvoiceDropdownInterfaceProps[]
  ) {
    setInvoiceList(invoiceList);
    setQuickAction(action);
    setAction(undefined);
  }

  function setQuickActionsCallback(action: EActions, invoiceList: InvoiceDropdownInterfaceProps[]) {
    setInvoiceList(invoiceList);
    setAction(action);
    setQuickAction(undefined);
  }

  const ActivitiesComponent = (
    <ActivitiesModal
      selectAll={selectAll}
      quickAction={quickAction}
      invoiceList={invoiceList}
      resetToIntialState={resetToIntialState}
      showRelativeOptionsList={invoiceList.length > 1}
      entity={ActionableEntity.INVOICE}
      currentFilterView={{ ...currentFilter }}
      customerId={invoiceList.length === 1 ? invoiceList[0]?.customer_id : undefined}
      totalRecords={totalRecords}
      isFromInvoiceList={true}
      totalCustomerRecords={totalCustomerRecords}
      overrideEmailStyles={{ position: 'fixed' }}
    />
  );

  const ActionsComponent = (
    <ActionsModal
      selectAll={selectAll}
      quickAction={action}
      invoiceList={invoiceList}
      totalRecords={totalRecords}
      currentFilterView={{ ...currentFilter }}
      resetToIntialState={resetToIntialState}
      customerId={invoiceList.length === 1 ? invoiceList[0]?.customer_id : undefined}
      isFromInvoiceList={true}
      queryKey={props.queryKey}
    />
  );

  const QuickActions = (
    <>
      {ActivitiesComponent}
      {ActionsComponent}
    </>
  );

  return {
    selectAll,
    setSelectAll,
    setQuickActivitiesCallback,
    setQuickActionsCallback,
    setTotalRecords,
    QuickActions,
    action
  };
}
