import { InfoCircleOutlined } from '@ant-design/icons';
import CheckBox from 'components/BaseComponents/Checkbox';
import { CheckboxChangeEventType } from 'components/BaseComponents/Checkbox/Antd/Antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { useState } from 'react';
function ApplyHistoric() {
  const t = {
    condition: 'Include historic transactions',
    infoDetail: 'Once saved, the rate will be recalculated for all past invoices as well.',
  };
  const [applyHistory, setApplyHistory] = useState<boolean>(false);

  const handleApplyHistory = (e: CheckboxChangeEventType) => {
    setApplyHistory(e.target.checked);
  };
  return (
    <Flex gap="var(--space-16)" direction="column">
      <CheckBox onChange={handleApplyHistory} checked={applyHistory} disabled>
        {t.condition}
      </CheckBox>
      {applyHistory && (
        <Flex
          gap="var(--space-8)"
          style={{
            borderRadius: 'var(--space-2)',
            background: 'var(--gold-1)',
            padding: 'var(--space-8) var(--space-16)',
          }}
        >
          <InfoCircleOutlined style={{ color: 'var(--gold-6)' }} />
          <GrowText className="text-14">{t.infoDetail}</GrowText>
        </Flex>
      )}
    </Flex>
  );
}
export default ApplyHistoric;
