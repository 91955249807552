import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllEmailTemplatesResponseState } from './types';

const initialState: AllEmailTemplatesResponseState = {
  status: 0,
  message: '',
  emailTemplates: [],
};

const emailTemplatesSlice = createSlice({
  name: 'emailTemplates',
  initialState,
  reducers: {
    getAllEmailTemplatesHandler(state, action: PayloadAction<AllEmailTemplatesResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.emailTemplates = action.payload.emailTemplates ?? [];
    },
  },
});

export const { getAllEmailTemplatesHandler } = emailTemplatesSlice.actions;

export default emailTemplatesSlice.reducer;
