import {
  InvoiceSegmentErrorField,
  InvoiceSegmentErrorFields,
} from 'store/invoice-segments/initial-state';
import { InvoiceSegment } from 'types/entities/invoice-segments';
import {
  duplicateErrorMessage,
  maxLengthErrorMessage,
  minLengthErrorMessage,
  requiredErrorMessage,
} from 'util/validation-messages';

type AllErrorFields = InvoiceSegmentErrorFields<InvoiceSegmentErrorField>;

const t = {
  title: 'Title',
  customFieldValue: 'Custom Field Value',
};

export function validateRuleTitle(
  title: string,
  id: number | string,
  segments: InvoiceSegment[]
): AllErrorFields {
  const errorState: AllErrorFields = {};

  if (!title.length) {
    errorState[InvoiceSegmentErrorField.title] = requiredErrorMessage(t.title);
  } else if (title.length > 100) {
    errorState[InvoiceSegmentErrorField.title] = maxLengthErrorMessage(t.title, 100);
  } else if (title.length < 2) {
    errorState[InvoiceSegmentErrorField.title] = minLengthErrorMessage(t.title, 2);
  } else if (segments.find((segment) => segment.id !== id && segment.title === title)) {
    errorState[InvoiceSegmentErrorField.title] = duplicateErrorMessage(t.title, title);
  } else {
    errorState[InvoiceSegmentErrorField.title] = undefined;
  }

  return errorState;
}

export function validateInvoiceSegment(segment: InvoiceSegment, segments: InvoiceSegment[]) {
  return {
    ...validateRuleTitle(segment.title, segment.id, segments),
  };
}
