import { Slider } from 'antd';
import { SliderMarks } from 'antd/lib/slider';

export interface RangeFilterAdditionalProps {
  step?: number;
  startRange?: number;
  endRange?: number;
  marks?: SliderMarks;
}

interface RangeFilterProps<T> {
  label?: string;
  name: string;
  value?: T;
  onChange?: (value: T) => void;
  additionalProps?: RangeFilterAdditionalProps;
}

function computeMarks(color: string, label: string) {
  return {
    style: { color },
    label,
  };
}

const DEFAULT_MARKS = {
  1: computeMarks('var(--red-6)', '1'),
  2: computeMarks('var(--orange-6)', '2'),
  3: computeMarks('var(--gold-6)', '3'),
  4: computeMarks('var(--green-6)', '4'),
  5: computeMarks('var(--green-8)', '5'),
};

type RangeValueType = [number, number];

export function RangeFilter<T extends RangeValueType>(props: RangeFilterProps<T>) {
  const { value, additionalProps, onChange } = props;
  const { step, startRange, endRange, marks } = additionalProps ?? {};

  return (
    <Slider
      range
      step={step ?? 1}
      value={value ?? [0, 0]}
      min={startRange ?? 1}
      max={endRange ?? 5}
      marks={marks ?? DEFAULT_MARKS}
      onChange={(value) => {
        onChange && onChange(value as T);
      }}
    />
  );
}
