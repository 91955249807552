import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'antd';
import { Flex, FlexBoxChild } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import React from 'react';
import { CustomDashboardLink } from 'types/api/reports';

interface ReportCardProps {
  dashboard?: CustomDashboardLink;
  setSelection?: (dashboardId: string) => void;
}

export function ReportCard({ setSelection, dashboard }: ReportCardProps) {
  const ReportIcon = (
    <FontAwesomeIcon icon={['fal', 'file-chart-line']} size="xl" color="var(--blue-4)" />
  );

  const CardTitle = (
    <Texto size="16" color="var(--primary-7)" weight="semibold">
      {dashboard?.title}
    </Texto>
  );

  const CardDescription = <Texto color="var(--gray-10)">{dashboard?.description}</Texto>;

  function onClickCallback(event: React.MouseEvent<HTMLElement>) {
    if (setSelection && dashboard?.dashboard_id) {
      setSelection(dashboard.dashboard_id);
    }
  }

  return (
    <Card style={{ width: 320 }} className="cursor-pointer" onClick={onClickCallback}>
      <Flex style={{ gap: 'var(--space-8)' }} direction="column">
        <FlexBoxChild>{ReportIcon}</FlexBoxChild>
        <FlexBoxChild>{CardTitle}</FlexBoxChild>
        <FlexBoxChild>{CardDescription}</FlexBoxChild>
      </Flex>
    </Card>
  );
}
