import { StatusCell } from 'components/Common/StatusCell/StatusCell';
import { getDocumentConfig } from 'components/Documents/predicate';
import { DocumentTypesListCompProps } from 'types/entities/documents';
interface DocLevelProps extends DocumentTypesListCompProps<'entity_type'> {}
function DocLevel(props: DocLevelProps) {
  const { entity_type } = props;
  return (
    <StatusCell
      status={{
        mode: 'Tags',
        text: getDocumentConfig(entity_type).label,
        style: getDocumentConfig(entity_type).style,
      }}
    />
  );
}

export { DocLevel };
