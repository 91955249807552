import { Plugin } from 'prosemirror-state';

export function TransformPastedHTML() {
  return new Plugin({
    props: {
      transformPastedHTML(html, view) {
        const dom = new DOMParser().parseFromString(html, 'text/html');
        const style = dom.querySelector('style');
        dom.body.querySelectorAll('*').forEach((element) => {
          element.setAttribute('isPasted', 'true');
        });
        const isFromExcel = Array.from(dom.querySelectorAll('meta')).some((meta) => {
          return (
            meta.getAttribute('name') === 'Generator' &&
            meta.getAttribute('content')?.includes('Excel')
          );
        });
        if (isFromExcel) {
          return dom.body.innerHTML;
        }

        if (style) {
          const cssRules = Array.from(style.sheet?.cssRules || []);
          cssRules.forEach((rule) => {
            if (rule instanceof CSSStyleRule) {
              const elements = dom.querySelectorAll(rule.selectorText);
              elements.forEach((element) => {
                if (element instanceof HTMLElement) {
                  // Merge existing styles with new styles
                  const existingStyles = element.getAttribute('style') || '';
                  element.setAttribute('style', `${existingStyles} ${rule.style.cssText}`);
                }
              });
            }
          });
          style.remove(); // Remove the <style> tag after applying the styles
        }
    

        return dom.body.innerHTML;
      },
    },
  });
}

export function TransformPastedContent() {
  return new Plugin({
    props: {
      transformPastedHTML(html, view) {
        const dom = new DOMParser().parseFromString(html, 'text/html');
        const containsTable = dom.querySelector('table') !== null;

        if (containsTable) {
          return `<table class='ispasted'>${dom.body.innerHTML}</table>`;
        }

        return dom.body.innerHTML;
      },
    },
  });
}
