import { Button as AntdButton, Tooltip, TooltipProps } from 'antd';
import { styled } from 'lib/css-in-js';

interface ButtonProps {
  buttonProps?: React.ComponentProps<typeof AntdButton>;
  toolTipProps?: TooltipProps;
}

const StyledAntButton = styled(AntdButton)``;

export function Button({ buttonProps, toolTipProps }: ButtonProps) {
  return (
    <Tooltip placement="top" {...toolTipProps} mouseEnterDelay={0.5}>
      <StyledAntButton {...buttonProps}>{buttonProps?.children}</StyledAntButton>
    </Tooltip>
  );
}
