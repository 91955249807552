import { PageList } from 'types/common/page-list';
import { ActivityFeedCollection } from '../store/activity-feed/type';
import ky from './ky';
import { CollectionOwnerProps } from './users';

const baseUrl = 'feeds';

export async function getActivityFeed(
  type: string,
  activityType: string | undefined,
  page: number,
  id?: number,
  size?: number
): Promise<PageList<ActivityFeedCollection, unknown>> {
  const url = `${baseUrl}/${type}/${id}`;

  const searchParams = {
    page,
    size: size ?? 10,
    ...(activityType && { type: activityType }),
  };

  return await ky.get(url, { searchParams }).json();
}

export async function getActivityFeedUsers() {
  return (await ky.get(`users/activity-feed-users`).json()) as CollectionOwnerProps[];
}
