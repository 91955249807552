import { Table } from '@tiptap/extension-table';
import { columnResizing, tableEditing, TableView } from 'prosemirror-tables';
import { TransformPastedHTML } from '../TipTapEditor/extensions/default-paste';

export class CustomTableView extends TableView {
  constructor(node: any, cellMinWidth: number) {
    super(node, cellMinWidth);
    this.table.setAttribute('style', node.attrs.style);
  }
}

export const CustomTable = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      style: {
        default: 'border-collapse: collapse; margin: 0 !important; table-layout: fixed;',
        parseHTML: (element) => {
          const colgroup = element.querySelector('colgroup');
          if (colgroup) {
            return `${element.style?.cssText};width:auto!important;`;
          }
          return element.style?.cssText || '';
        },
        renderHTML: (attributes) => ({ style: attributes.style }),
      },
      colgroup: {
        default: '',
        parseHTML: (element) => {
          const colgroup = element.querySelector('colgroup');

          return colgroup ? colgroup.outerHTML : null;
        },
        renderHTML: (attributes) => {
          return {
            colgroup: attributes.colgroup,
          };
        },
      },
      ispasted: {
        default: false,
        parseHTML: (element) => element.getAttribute('ispasted') === 'true',
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      tableEditing({
        allowTableNodeSelection: this.options.allowTableNodeSelection,
      }),
      TransformPastedHTML(),

      ...(this.options.resizable && this.options.HTMLAttributes.ispated !== true
        ? [
            columnResizing({
              handleWidth: this.options.handleWidth,
              cellMinWidth: this.options.cellMinWidth,
              View: CustomTableView,
              lastColumnResizable: this.options.lastColumnResizable,
            }),
          ]
        : []),
    ];
  },
});
