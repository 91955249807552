import { Space } from 'antd';
import { Divider } from 'components/BaseComponents/Layout/Divider';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { CustomFilterSelect } from 'components/Common/FilterComponents/ExtraFiltersSelect';
import { Filter, FilterGroup } from 'components/Common/FilterComponents/types';
import { useRef } from 'react';
import { useInvoiceFilter } from './FilterBlockContainer/Invoice';
import { useClosedInvoiceListFilter } from './hooks/useClosedInvoiceListFilter';

type FilterProps = ReturnType<typeof useClosedInvoiceListFilter>;

export function ClosedInvoiceFilterStrip(props: FilterProps) {
  const order = useRef<number>(0);

  const { filterValues, updateFilterValues, filterGroups, updateFilterGroups } = props;

  function onSelect(filter: Filter, fitlerGroup: FilterGroup) {
    const filterId = filter.id,
      groupId = fitlerGroup.id;

    updateFilterGroups({
      type: 'UPDATE_FILTER',
      payload: { groupId, filterId, updatedValues: { active: true, order: order.current + 1 } },
    });

    order.current = order.current + 1;
  }

  const filterProps = {
    filterValues,
    updateFilterValues,
    filterGroups,
    updateFilterGroups,
  } as FilterProps;

  const {
    DefaultFilterComponents: InvoiceDefaultFilters,
    ExtraFilterComponents: InvoiceExtraFilters,
  } = useInvoiceFilter(filterProps);

  return (
    <Flex align="center" gap="--space-12" wrap="wrap">
      {InvoiceDefaultFilters}
      {InvoiceExtraFilters}
      <Space style={{ order: order.current + 1 }}>
        <Divider size="32" />
        <CustomFilterSelect filterGroups={filterGroups} onSelect={onSelect} />
      </Space>
    </Flex>
  );
}
