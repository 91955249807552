import { BusinessUnit } from 'types/entities/business-unit';

export type AllBusinessUnitsResponseState = {
  status: number;
  message?: string;
  businessUnits?: Array<BusinessUnit>;
};

export type BusinessUnitResponseState = {
  status: number;
  message?: string;
  id?: number;
};

export enum CRUD_ACTIONS {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}
