import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers/rootReducer';
import { AllUsersResponseState } from './types';

const initialState: AllUsersResponseState = {
  status: 0,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsersHandler(state, action: PayloadAction<AllUsersResponseState>) {
      state.users = action.payload.users;
    },
    getActiveUsersHandler(state, action: PayloadAction<AllUsersResponseState>) {
      state.activeUsers = action.payload.users;
    },
  },
});

export const usersSelector = (state: RootState) => state.users.users;

export const activeUsersSelector = (state: RootState) => state.users.activeUsers ?? [];

export const { getUsersHandler, getActiveUsersHandler } = usersSlice.actions;

export default usersSlice.reducer;
