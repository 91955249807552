import { useState } from 'react';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import { BaseCustomer } from 'types/entities/customer';
import { ActionableEntity } from '../Email';
import { groupInvoicesByCustomer, isSingleCustomer } from './utils';
import { InvoiceBasicDetails } from 'types/entities/invoice';

interface Props {
  emailFor: ActionableEntity;
  customerList?: BaseCustomer[];
  invoiceList?: InvoiceDropdownInterfaceProps[] | InvoiceBasicDetails[];
}

function getInitialCustomerAndInvoices({ customerList = [], invoiceList = [], emailFor }: Props) {
  if (emailFor === ActionableEntity.CUSTOMER) {
    return { customerId: customerList[0].id, invoiceIds: [] };
  }

  if (!invoiceList.length) {
    return { customerId: customerList[0].id, invoiceIds: [] };
  }

  if (isSingleCustomer(invoiceList)) {
    return {
      customerId: invoiceList[0].customer_id,
      invoiceIds: invoiceList.map((invoice) => invoice.id),
    };
  }

  const invoicesGroupedByCustomer = groupInvoicesByCustomer(invoiceList);

  const invoiceIds = invoicesGroupedByCustomer[0].invoices.map((invoice) => invoice.invoice_id);
  return { customerId: invoicesGroupedByCustomer[0].id, invoiceIds };
}

export function useEmailPreview(props: Props) {
  const { emailFor, customerList = [], invoiceList = [] } = props;

  const [customer, setCustomer] = useState<number | undefined>(() => {
    const { customerId } = getInitialCustomerAndInvoices({ emailFor, invoiceList, customerList });
    return customerId;
  });
  const [invoices, setInvoices] = useState(() => {
    const { invoiceIds } = getInitialCustomerAndInvoices({ emailFor, invoiceList, customerList });
    return invoiceIds;
  });

  return { customer, invoices, setCustomer, setInvoices };
}
