import { cyan, geekblue, green, magenta, orange, purple, red, yellow } from '@ant-design/colors';
import { Spin } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import EmptyChart, { getIsEmpty } from 'components/Common/Charts/EmptyChart';
import PieChart from 'components/Common/Charts/Pie';
import { PieChartEventData } from 'components/Common/Charts/Pie/type';
import { GenericPlot, PieConfigs } from 'components/Common/Charts/types';
import Legend from 'components/Common/Legend';
import { usePageNavigate } from 'lib/router';
import { sum } from 'lodash';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';
import { InvoiceStatus } from 'types/entities/invoice';
import ToolTip from './ToolTip';
import { ChartRef, InvoiceDisputeData } from './type';

function InvoiceDisputesChart(props: Omit<DashboardComponentProps, 'filterParams'>) {
  const plotRef = useRef<GenericPlot<InvoiceDisputeData> | null>(null);
  const { invoiceDisputes } = props;
  const navigate = usePageNavigate();
  const chartRef = useRef<ChartRef>();
  const locale = useSelector(localeSelector);
  const colorPalette = useMemo(
    () => [
      red[2],
      orange[2],
      yellow[2],
      green[2],
      cyan[2],
      geekblue[2],
      purple[2],
      magenta[2],
      red[3],
      orange[4],
      yellow[4],
      green[4],
      cyan[4],
      geekblue[4],
      purple[4],
      magenta[4],
      red[6],
      orange[6],
      yellow[6],
      green[6],
      cyan[6],
      geekblue[6],
      purple[6],
      magenta[6],
    ],
    []
  );

  function handleInvoiceDisputesData(): InvoiceDisputeData[] {
    if (invoiceDisputes && Boolean(invoiceDisputes.length)) {
      const totalAmount = sum(invoiceDisputes?.map((dispute) => dispute.outstanding.amount.value));
      return invoiceDisputes.map((dispute) => {
        return {
          type: dispute.dispute_type,
          value: dispute.outstanding.amount.value,
          valueObject: dispute,
          totalAmount,
        };
      });
    }
    return [];
  }

  function handleOnReady(plot: GenericPlot<InvoiceDisputeData>) {
    plot.on('plot:click', handlePieClick);
    plotRef.current = plot;
  }

  const invoiceDisputesData: InvoiceDisputeData[] = useMemo(handleInvoiceDisputesData, [
    invoiceDisputes,
  ]);

  function handlePieClick(event: PieChartEventData<InvoiceDisputeData>) {
    event.gEvent.preventDefault();
    event.gEvent.stopPropagation();
    if (event.data) {
      const status = event.data.data.valueObject.dispute_type_id;
      const metaActivityData = new URLSearchParams({
        sort_col: 'DUE_AMOUNT',
        sort_by: 'ASC',
      });
      navigate({
        appPage: 'INVOICE_LIST',
        searchParams: `${metaActivityData}&dispute_type_ids=[${status}]&status=["${InvoiceStatus.DISPUTE}"]`,
        state: {
          addPrimaryPathAsChildPath: true,
        },
      });
    }
  }

  const pieConfig: Omit<PieConfigs<InvoiceDisputeData>, 'data'> = {
    className: 'invoice-disputes-chart',
    style: {
      cursor: 'pointer',
      height: '200px',
      width: '70%',
    },
    appendPadding: [16],
    renderer: 'canvas',
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    width: 100,
    label: false,
    supportCSSTransform: true,
    onReady: handleOnReady,
    color: colorPalette,
    tooltip: {
      customContent: (title, content) => {
        return (
          Boolean(content.length) && <ToolTip title={title} content={content} locale={locale} />
        );
      },
    },
    legend: false,
  };

  const LoadingTemplate = <Spin />;

  const legendPropData = useMemo(
    () =>
      invoiceDisputesData?.map((item, index) => {
        const label = item.type;
        const disable = !item.valueObject.outstanding.invoice_count;
        const color = colorPalette[index % colorPalette.length];

        return {
          label,
          color,
          disable,
          id: item.valueObject.dispute_type_id,
        };
      }),
    [invoiceDisputesData, colorPalette]
  );

  const currentLegends = legendPropData.map((item) => item.id);

  function handleOnClickLegend(type: number) {
    const plot = plotRef.current;
    if (!plot) return;
    const exist = currentLegends.findIndex((t) => t === type);
    if (exist === -1) {
      currentLegends.push(type);
    } else {
      currentLegends.splice(exist, 1);
    }
    plot.chart.filter('type', (value, item) => {
      return currentLegends.includes((item as InvoiceDisputeData).valueObject.dispute_type_id);
    });
    plot.chart.render(true);
  }
  const isEmpty = getIsEmpty(invoiceDisputesData, ['value']);
  return (
    <Flex align="center" gap="var(--space-4)" style={{ width: '100%' }}>
      <EmptyChart isEmpty={isEmpty}>
        <PieChart
          {...pieConfig}
          data={invoiceDisputesData}
          chartRef={chartRef}
          loadingTemplate={LoadingTemplate}
        />
      </EmptyChart>
      <div style={{ width: '200px' }}>
        {legendPropData.length ? (
          <div className="legend-list">
            <Legend
              data={legendPropData}
              handleOnClickLegend={handleOnClickLegend}
              pageItemsSize={7}
            />
          </div>
        ) : null}
      </div>
    </Flex>
  );
}

export default InvoiceDisputesChart;
