import { useDashboardFilterViewsQuery } from 'queries/admin-dashboard';
import { useEffect, useState } from 'react';
import { DashboardTab } from '../..';
import { DashboardFilters } from '../reducers/filterValuesReducer';

function useDashboardFilterView(tab: DashboardTab) {
  const { data: totalSavedViews } = useDashboardFilterViewsQuery(tab);

  const [currentFilter, setCurrentFilter] = useState<Partial<DashboardFilters>>();

  function initializeCurrentFilter() {
    if (totalSavedViews) {
      setCurrentFilter(totalSavedViews[0].dashboard_filter as unknown as DashboardFilters);
    }
  }

  useEffect(initializeCurrentFilter, [totalSavedViews]);

  return {
    totalSavedViews,
    currentFilter,
    setCurrentFilter,
  };
}

export default useDashboardFilterView;
