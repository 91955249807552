import { GroupedOptions } from 'components/BaseComponents/ASelect';
import { uniqBy } from 'lodash';
import { Emailable, EmailableType } from 'types/activities/email';
import { pruneObject } from 'util/json-utils';
import { RECIPIENT_OPT_GROUPS, RECIPIENT_OPT_GROUP_KEYS, stakeHolderListAttributes } from '../type';

const isParent = (user: Emailable) => user.participant_relation === 'PARENT';
/* 
  1.used for default rece calculation
  2.contact search
*/
export const getFormatedEmailData = (
  userList: Emailable[] | Emailable
): stakeHolderListAttributes[] => {
  if (!Array.isArray(userList)) {
    return [];
  }

  const uniqueUserList = uniqBy(userList, 'email').filter(
    (user) => user.email && !!user.email.trim()
  );

  return uniqueUserList?.map((user) => {
    return {
      email: user.email,
      type: user.type ?? EmailableType.CONTACT,
      contact_type: user.type === EmailableType.CONTACT ? user.contact_type : undefined,
      title: user.title,
      label: !isParent(user)
        ? `${user.email}${user.title ? `(${user.title})` : ''}`
        : `${user.email}${user.title ? `(Parent ${user.title})` : ''}`,
      participant_relation: user.participant_relation,
    };
  });
};

/* 
  ideal usecase is to generate a stakholder attribute
  structure and attach contact_type_id

  1. used in onChangecallback
  2. handlestakeholder options
*/
export function computeRecepient(
  usersList: stakeHolderListAttributes[],
  showRelativeOptionsList: boolean
): stakeHolderListAttributes[] {
  return usersList.map((item) => {
    const transformedObj = {} as stakeHolderListAttributes;
    transformedObj['type'] = item.type ?? EmailableType.CONTACT;
    transformedObj['id'] = item.id;
    transformedObj['participant_relation'] = item.participant_relation;
    transformedObj['email'] = item.email;
    transformedObj['label'] = item.email as string;
    transformedObj['title'] = item.title;

    if (showRelativeOptionsList) {
      transformedObj['label'] = item.label;

      if (item.type === 'CONTACT') {
        transformedObj['contact_type_id'] = item?.id;
        transformedObj['contact_type_identifier'] = item.contact_type_identifier;
      } else {
        transformedObj['user_type'] = item.user_type;
        transformedObj['association_level'] = item.association_level;
      }
    }

    return pruneObject(transformedObj) as stakeHolderListAttributes;
  });
}

export function getGroupTemplate(keysToSkip?: Array<`${RECIPIENT_OPT_GROUP_KEYS}`>) {
  const groupedOptions = {} as GroupedOptions<
    stakeHolderListAttributes,
    `${RECIPIENT_OPT_GROUP_KEYS}`
  >;

  RECIPIENT_OPT_GROUPS.filter((item) => !keysToSkip?.includes(item.key)).forEach((group) => {
    const groupObj = {
      key: group.key,
      label: group.label,
      options: [],
    };

    groupedOptions[group?.key] = groupObj;
  });

  return groupedOptions;
}

export function transformToGroupOptions(
  options: stakeHolderListAttributes[]
): ReturnType<typeof getGroupTemplate> {
  const groupedOptions = getGroupTemplate();

  //get unique recipientOptionsList from search
  const uniqueOptions = uniqBy(options, 'email');

  uniqueOptions?.forEach((option) => {
    switch (option.type) {
      case 'CONTACT':
        groupedOptions['CUSTOMER'].options.push(option);
        break;

      case 'USER':
        groupedOptions['INTERNAL_CONTACTS'].options.push(option);
        break;
    }
  });

  return groupedOptions;
}

export type EmailGroupedOptions = ReturnType<typeof transformToGroupOptions>;

export interface InternalContactRecepientProps {
  computedGroupedOptions: ReturnType<typeof transformToGroupOptions>;
  isLoading: boolean;
}
