import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IActivityFeedCollection } from './type';

const initialState: {
  ActivityFeed: IActivityFeedCollection;
} = {
  ActivityFeed: {} as IActivityFeedCollection,
};

const activityFeedSlice = createSlice({
  name: 'activityFeed',
  initialState,
  reducers: {
    updateActivityFeed(state, action: PayloadAction<IActivityFeedCollection>) {
      const list =
        state.ActivityFeed.list && state.ActivityFeed.list.length
          ? state.ActivityFeed.list.concat(action.payload.list)
          : action.payload.list;

      state.ActivityFeed = {
        ...action.payload,
        list,
      };
    },
    updateActivity(state, action: PayloadAction<IActivityFeedCollection>) {},
  },
});

export const { updateActivityFeed, updateActivity } = activityFeedSlice.actions;

export default activityFeedSlice.reducer;
