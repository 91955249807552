import { GridColumnProps } from '@sinecycle/growcomponents';
import { getInvoiceStatusConfig } from 'components/Common/InvoiceStatus/predicate';
import { IInvoices } from 'store/customer/types';
import { Unpacked } from '../../../../../../types/utils/utils';
import { Customer } from './Customer';
import { DueDateCell } from './DueDateCell';
import { FollowUpAction } from './FollowUpAction';
import Invoices from './Invoices';

import { LastActivityRenderer } from './LastActivity';
export const IssueDateColumn: Unpacked<GridColumnProps<IInvoices>> = {
  headerName: 'Issue date',
  cellRenderer: 'dateCell',
  colId: 'INVOICE_DATE',
  field: 'invoice_date',
  cellRendererParams: {
    columnCustom: {
      type: 'DEFAULT',
    },
  },
};

export const InvoiceStatusColumn: Unpacked<GridColumnProps<IInvoices>> = {
  headerName: 'Invoice Status',
  cellRenderer: 'statusCell',
  field: 'status',
  sortable: false,
  cellRendererParams: {
    statusCellProps: {
      getter: getInvoiceStatusConfig,
    },
  },
  colId: 'INVOICE_STATUS',
};

export const InvoiceSubsidiaryColumn: Unpacked<GridColumnProps<IInvoices>> = {
  field: 'subsidiary.display_name',
  sortable: false,
  headerName: 'Subsidiary',
  cellRenderer: 'textCell',
  colId: 'SUBSIDIARY',
};

export const CustomerColumn: Unpacked<GridColumnProps<IInvoices>> = {
  headerName: 'Customer',
  cellRenderer: Customer,
  sortable: false,
  colId: 'CUSTOMER',
  minWidth: 180,
};
export const InvoicesColumn: Unpacked<GridColumnProps<IInvoices>> = {
  headerName: '#Reference',
  cellRenderer: Invoices,
  sortable: false,
  colId: 'REFERENCE',
  initialWidth: 450,
  minWidth: 240,
  headerComponent: 'headerWithCheckBox',
};

export const LastActivityColumn: Unpacked<GridColumnProps<IInvoices>> = {
  headerName: 'Last Activity',
  cellRenderer: LastActivityRenderer,
  sortable: false,
  colId: 'LAST_ACTIVITY',
};
export const DueDate: Unpacked<GridColumnProps<IInvoices>> = {
  headerName: 'Due Date',
  colId: 'DUE_DATE',
  cellRenderer: DueDateCell,
  field: 'due_date',
  cellRendererParams: {
    columnCustom: {
      type: 'DEFAULT',
    },
  },
};

export const DueDays: Unpacked<GridColumnProps<IInvoices>> = {
  headerName: 'Age',
  colId: 'DUE_DAYS',
  cellRenderer: 'numberText',
  valueGetter: (params) => {
    return params.data?.due_days && params.data?.due_days < 0 ? '-' : params.data?.due_days;
  },
};

export const FollowUpActionColumn: Unpacked<GridColumnProps<IInvoices>> = {
  headerName: 'Open Tasks',
  cellRenderer: FollowUpAction,
  sortable: false,
  colId: 'OPEN_TASKS',
};
