import { Emailable } from 'types/activities/email';
import {
  Category,
  CustomerInternalContactProps,
  InvoiceInternalContactProps,
  UpdateInternalCustomerContact,
  UpdateInternalInvoiceContact,
} from 'types/api/internal-contact';
import ky from './ky';

export async function searchGeneralContacts(searchTerm: string, limit?: number) {
  return (await ky
    .get(`suggestions/all-contacts?keyword=${searchTerm}&limit=${limit ?? 10}`)
    .json()) as Emailable[];
}
//customer level

export async function getCustomerUserCategory(id: number) {
  return (await ky.get(`user-category/user/${id}`).json()) as Category[];
}

export async function deleteCustomerUserInternalContact(cutomerId: number, userId: number) {
  return await ky.delete(`customers/${cutomerId}?internal-contact=${userId}`);
}

export async function createCustomerInternalContacts(param: {
  id: number;
  data: UpdateInternalCustomerContact;
}) {
  return (await ky
    .post(`customers/${param.id}/internal-contact`, { json: param.data })
    .json()) as CustomerInternalContactProps[];
}

export async function updateCustomerInternalContact(
  id: number,
  data: UpdateInternalCustomerContact
) {
  return (await ky
    .put(`customers/${id}/internal-contact`, { json: data })
    .json()) as CustomerInternalContactProps[];
}

//invoice level
export async function deleteInvoiceUserInternalContact(entityId: number, id: number) {
  return await ky.delete(`invoices/${entityId}/internal-contact/${id}`);
}

export async function getInvoicelUserCategory(customerId: number, userIid: number) {
  return (await ky
    .get(`user-category/user/${userIid}?customers=${customerId}`)
    .json()) as Category[];
}

export async function createInvoiceInternalContacts(param: {
  id: number;
  data: UpdateInternalInvoiceContact;
}) {
  return (await ky
    .post(`invoices/${param.id}/internal-contact`, { json: param.data })
    .json()) as InvoiceInternalContactProps[];
}

export async function getInvoiceInternalContacts(id: number) {
  const url = `invoices/${id}/internal-contacts`;
  return (await ky.get(url).json()) as InvoiceInternalContactProps[];
}

export async function updateInvoiceInternalContact(id: number, data: UpdateInternalInvoiceContact) {
  return (await ky
    .put(`invoices/${id}/internal-contact`, { json: data })
    .json()) as InvoiceInternalContactProps[];
}
