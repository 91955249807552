import { t } from '../constants';
import { FilterView, MenuProps } from '../type';

interface useMenuItemsProps<T> {
  totalSavedViews: FilterView<T>[];
}
type Items = MenuProps['items'];
type MenuItem = {
  menuItems: Items;
};

const systemDefinedPredicate = <T>(savedView: FilterView<T>) => Boolean(savedView.system_defined);

const nonSystemDefinedPredicate = <T>(savedView: FilterView<T>) =>
  !savedView.system_defined;

const itemsPredicate = <T>(savedViews: FilterView<T>) => {
  return { key: savedViews.id, label: savedViews.name };
};

export default function getMenuItems<T>(props: Readonly<useMenuItemsProps<T>>): MenuItem {
  const { totalSavedViews } = props;
  const systemDefinedFilterViews = totalSavedViews.filter(systemDefinedPredicate);
  const customFilterViews = totalSavedViews.filter(nonSystemDefinedPredicate);

  const systemDefinedItems: Items = systemDefinedFilterViews.map(itemsPredicate);
  const customFilterItems: Items = customFilterViews.map(itemsPredicate);
  const groupedItems: Items = [
    { type: 'group', label: t.default_filter_views, children: systemDefinedItems },
    {
      type: 'divider',
    },
    {
      type: 'group',
      label: t.custom_filter_views,
      children: customFilterItems,
    },
  ];
  const MenuItems = customFilterViews.length ? groupedItems : systemDefinedItems;
  return { menuItems: MenuItems };
}
