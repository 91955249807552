import { cleanedString, sanitizeSubject } from 'components/BaseComponents/RTE/util/sanitizeSubject';
import { ISendEmailParams } from 'store/email/types';
import { ContactType, ParentContactType, PocUserType } from 'types/entities/contact';
import * as yup from 'yup';
import { validationErrors } from './texts';
import { EEmailParams } from './type';

const ignoredEmailTypes = [
  ContactType.PRIMARY_CONTACT.toString(),
  ContactType.SECONDARY_CONTACT.toString(),
  ParentContactType.PRIMARY_CONTACT_PARENT.toString(),
  ParentContactType.SECONDARY_CONTACT_PARENT.toString(),
  PocUserType.ACCOUNT_MANAGER.toString(),
  PocUserType.COLLECTION_OWNER.toString(),
  PocUserType.CS_MANAGER.toString(),
];

export const emailSchema: yup.AnyObjectSchema = yup.object().shape({
  [EEmailParams.to]: yup
    .array()
    .of(
      yup.object().shape(
        {
          email: yup.string().when(['email', 'user_type', 'contact_type'], {
            is: (email: any, userType: any, contactType: any) => {
              if (!email) return false;
              if (userType || contactType) return ignoredEmailTypes.includes(email) ? false : true;
              return true;
            },
            then: yup.string(),
            otherwise: yup.string(),
          }),
        },
        [
          ['email', 'email'],
          ['user_type', 'user_type'],
          ['contact_type', 'contact_type'],
        ]
      )
    )
    .min(1, validationErrors.mandatoryField),
  [EEmailParams.from]: yup.object().shape({
    email: yup.string().required('validationErrors.mandatoryField').email(),
  }),

  [EEmailParams.subject]: yup.string().test({
    message: () => {
      return validationErrors.subject.minCharacter;
    },
    test: (value) => {
      const sanitizedValue = cleanedString(value);

      return sanitizedValue.length < 2 ? false : Boolean(sanitizedValue.length);
    },
  }),
  [EEmailParams.body]: yup.string().test({
    message: () => {
      return validationErrors.mandatoryField;
    },
    test: (value) => {
      const sanitizedValue = cleanedString(value);

      return Boolean(sanitizedValue.length);
    },
  }),
  [EEmailParams.cc]: yup
    .array()
    .of(
      yup.object().shape(
        {
          email: yup.string().when(['email', 'user_type', 'contact_type'], {
            is: (email: any, userType: any, contactType: any) => {
              if (!email) return false;
              if (userType || contactType) return ignoredEmailTypes.includes(email) ? false : true;
              return true;
            },
            then: yup.string(),
            otherwise: yup.string(),
          }),
        },
        [
          ['email', 'email'],
          ['user_type', 'user_type'],
          ['contact_type', 'contact_type'],
        ]
      )
    )
    .ensure(),
  [EEmailParams.bcc]: yup
    .array()
    .of(
      yup.object().shape(
        {
          email: yup.string().when(['email', 'user_type', 'contact_type'], {
            is: (email: any, userType: any, contactType: any) => {
              if (!email) return false;
              if (userType || contactType) return ignoredEmailTypes.includes(email) ? false : true;
              return true;
            },
            then: yup.string(),
            otherwise: yup.string(),
          }),
        },
        [
          ['email', 'email'],
          ['user_type', 'user_type'],
          ['contact_type', 'contact_type'],
        ]
      )
    )
    .ensure(),
});

export function validateEmailPayload(data: ISendEmailParams) {
  const clonedData = JSON.parse(JSON.stringify(data)) as ISendEmailParams;

  clonedData.to.forEach((to) => {
    if (typeof to.id === 'string') delete (to as Partial<typeof to>).id;
  });
  clonedData.cc?.forEach((cc) => {
    if (typeof cc.id === 'string') delete (cc as Partial<typeof cc>).id;
  });
  clonedData.bcc?.forEach((bcc) => {
    if (typeof bcc.id === 'string') delete (bcc as Partial<typeof bcc>).id;
  });

  clonedData.subject = sanitizeSubject(clonedData.subject);

  return clonedData;
}
