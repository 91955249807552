import Currency from 'components/Settings/Currency';
import { PageContainer } from 'router/PageContainer';

export function CurrencyPage() {
  return (
    <PageContainer page="CURRENCY">
      <Currency />
    </PageContainer>
  );
}
