import { Flex, FlexBoxChild } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useBadDebtTileQuery } from 'queries/admin-dashboard';
import { StyledTileCard } from '../styles';
import { dashboardTexts } from '../texts';
import TotalCustomersInvoices from '../TotalCustomersInvoices';

const componentTexts = dashboardTexts.badDebtTile;

function BadDebtTile({ filterParams }: DashboardComponentProps) {
  const { data: totalBadDebt, isLoading: loading } = useBadDebtTileQuery(filterParams);

  const InputLoaderJSX = <InputLoader active={loading} />;

  const customersCount = totalBadDebt?.bad_debt.customer_count;
  const invoicesCount = totalBadDebt?.bad_debt.invoice_count;

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices
      loading={loading}
      customersCount={customersCount}
      invoicesCount={invoicesCount}
    />
  );

  const Header = (
    <Flex direction="column" gap="var(--space-8)">
      <GrowText size={'var(--fs-16)'}>{componentTexts.title}</GrowText>
      {loading ? (
        InputLoaderJSX
      ) : totalBadDebt ? (
        <AmountWithCurrencySplit
          type="multi"
          amountWithCurrencySplit={totalBadDebt.bad_debt.amount}
          className="text-24 semi-bold"
          showTooltip
        />
      ) : null}
    </Flex>
  );

  return (
    <StyledTileCard>
      <Flex gap="var(--space-8)" justify="flex-start">
        {Header}
      </Flex>
      <Flex justify="space-between" align="center" style={{ marginTop: 'var(--space-12)' }}>
        <FlexBoxChild>{TotalCustInvoiceInfo}</FlexBoxChild>
        <FlexBoxChild style={{ paddingRight: 'var(--space-8)' }}>
          <GrowText size={'var(--fs-14)'}>{componentTexts.thisMonth}</GrowText>
        </FlexBoxChild>
      </Flex>
    </StyledTileCard>
  );
}

export default BadDebtTile;
