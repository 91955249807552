import { Form } from 'antd';
import styled from 'styled-components';

export const RangeStyledFormItem = styled(Form.Item)`
  line-height: 1.2;
  &.ant-form-item-label {
    line-height: 1.2;
  }

  &.ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -1px;
  }
  ${(props) => `
    &.${props.className}:not(.label-space):not(.label-with-no-padd){
      .ant-form-item-explain-error{
       font-size:var(--fs-12);
       width:100px;
      }
    }
  `}

  &.label-space {
    .ant-form-item-row {
      gap: var(--space-8);
    }
    .ant-form-item-explain-error {
      width: min-content;
    }
  }

  &.label-with-no-padd {
    width: 100%;
    .ant-form-item-label {
      padding: var(--space-0);
    }
  }
`;
