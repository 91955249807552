import { InvoiceFollowupStatus } from 'store/invoice/type';
import { InvoiceCollectionActivity } from 'types/activities/activity-types';

export const InvoiceCollectionActivitiesDropDownList = [
  {
    label: 'Emails sent',
    value: InvoiceFollowupStatus.EMAIL_SENT,
  },
  {
    label: 'Emails delivered',
    value: InvoiceCollectionActivity.EMAIL_DELIVERED,
  },
  {
    label: 'Emails opened',
    value: InvoiceFollowupStatus.EMAIL_OPENED,
  },
  {
    label: 'Emails not delivered',
    value: InvoiceFollowupStatus.EMAIL_BOUNCED,
  },
  {
    label: 'Emails not sent',
    value: InvoiceCollectionActivity.EMAIL_NOT_SENT,
  },
  {
    label: 'Promise to Pay - Open',
    value: InvoiceFollowupStatus.PTP_OPEN,
  },
  {
    label: 'Promise to Pay - Closed',
    value: InvoiceFollowupStatus.PTP_CLOSED,
  },
  {
    label: 'Escalation - Open',
    value: InvoiceFollowupStatus.ESCALATION_OPEN,
  },
  {
    label: 'Escalation - Closed',
    value: InvoiceFollowupStatus.ESCALATION_CLOSED,
  },
  {
    label: 'Dispute - Open',
    value: InvoiceFollowupStatus.DISPUTE_OPEN,
  },
  {
    label: 'Dispute - Waiting on Customer',
    value: InvoiceFollowupStatus.DISPUTE_WAITING_ON_CUSTOMER,
  },
  {
    label: 'Dispute - Closed',
    value: InvoiceFollowupStatus.DISPUTE_CLOSED,
  },
  {
    label: 'Flagged ',
    value: InvoiceCollectionActivity.INVOICE_FLAGGED,
  },
];
