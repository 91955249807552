import { BulkAction, customerNameFormatter } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { CustomActivityProps, FormItemNames } from 'components/Common/ActivitiesForm/types';
import useFormCustomTransformer from 'components/HigherOrderComponent/KeyActivitesContainer/hooks/useFormCustomTransformer';
import { castArray, head, map } from 'lodash';
import { IInvoices } from 'store/customer/types';
import AddNote from './AddNote';
import CreateEscalation from './CreateEscalation';
import CreateTask from './CreateTask';

function InvoiceStatementsActions(props: BulkAction<IInvoices>) {
  const { selectedRows } = props;

  const { onActivityFormTransform, onActivityAssociatedFormTransform } = useFormCustomTransformer();
  const customerName = map(selectedRows, (item) =>
    customerNameFormatter({ name: item.data.customer_name })
  );
  const customerId = map(selectedRows, (item) => item.data.customer_id);
  const invoices = map(selectedRows, (item) => item.data);

  function customTransformer(data: Record<FormItemNames, any>, customProps?: CustomActivityProps) {
    if (!customProps) return {};

    const { associatedData, parentType } = customProps;
    const invoiceId = map(selectedRows, (item) => item.data.id);

    if (!associatedData && !parentType) {
      return onActivityFormTransform({
        data,
        customProps,
        noteRefid: invoiceId,
        customerId: head(customerId),
        invoices,
      });
    } else if (associatedData && parentType) {
      return onActivityAssociatedFormTransform({
        data,
        customProps,
        customerId: castArray(associatedData?.customer_id),
      });
    }

    return {};
  }

  return (
    <Flex align="center" gap="var(--space-12)">
      <AddNote customerNames={customerName} customTransformer={customTransformer} {...props} />
      <CreateEscalation
        customerId={head(customerId)}
        customTransformer={customTransformer}
        invoices={invoices}
        {...props}
      />
      <CreateTask
        customerId={head(customerId)}
        invoices={invoices}
        customTransformer={customTransformer}
        {...props}
      />
    </Flex>
  );
}

export { InvoiceStatementsActions };
