import { CustomFieldRangeValueType } from 'components/Common/FilterComponents/Filters/CustomFiledRange';
import CustomFieldRangeWrapper from 'components/Common/FilterComponents/FilterWrapper/CustomFieldRangeWrapper';
import {
  CustomField,
  CustomFieldDataType,
  CustomFieldOperatorType,
} from 'types/entities/custom-field';

interface NumberBasedFilterBlockProps {
  customFiledId: number;
  filterId: string;
  onClear: (id: string) => void;
  handleSubmit: (
    value: Partial<CustomFieldRangeValueType<CustomFieldOperatorType>>,
    customFiledId: number
  ) => void;
  value: Partial<CustomFieldRangeValueType<CustomFieldOperatorType>> | undefined;
  customField: CustomField;
  currencySymbol: string | undefined;
  dataType: CustomFieldDataType;
}
function NumberBasedFilterBlock(props: NumberBasedFilterBlockProps) {
  function onClear() {
    props.onClear(props.filterId);
  }
  function handleSubmit(value: Partial<CustomFieldRangeValueType<CustomFieldOperatorType>>) {
    props.handleSubmit(value, props.customFiledId);
  }
  return (
    <CustomFieldRangeWrapper
      value={props.value}
      onClear={onClear}
      onSubmit={handleSubmit}
      filterConfig={{
        name: `custom-field-${props.customField.id}`,
        label: props.customField.display_name,
      }}
      currencySymbol={props.currencySymbol}
      dataType={props.dataType}
    />
  );
}

export default NumberBasedFilterBlock;
