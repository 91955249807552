import { Col, Row } from 'antd';
import { GrowText } from 'components/BaseComponents/Typography';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { StyledChildCard } from 'components/Dashboard/styles';
import { dashboardTexts } from 'components/Dashboard/texts';
import TotalCustomersInvoices from 'components/Dashboard/TotalCustomersInvoices';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import React from 'react';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';

interface Props extends DashboardComponentProps {
  totalOutstanding?: TotalOutstandingResponse;
  loading: boolean;
}

function UnappliedCredits({ totalOutstanding, loading }: Props) {
  const unAppliedCredit = totalOutstanding?.total_outstanding.un_applied_credit_amount;
  const unAppliedCreditCustomersCount = unAppliedCredit?.customer_count;

  const InputLoaderJSX = <InputLoader active={loading} />;

  const UnappliedCreditAmount = unAppliedCredit && (
    <AmountWithCurrencySplit
      type="multi"
      amountWithCurrencySplit={unAppliedCredit.amount}
      className="text-24 semi-bold"
      formatOptions={{ maximumFractionDigits: 0 }}
    />
  );

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices
      loading={loading}
      customersCount={unAppliedCreditCustomersCount}
      showInvoice={false}
    />
  );

  return (
    <StyledChildCard
      style={{ minHeight: 'unset', position: 'relative', height: '100%' }}
      className="current-due-container"
      color="var(--lime-2)"
    >
      <div style={{ marginBottom: 'var(--space-16)' }}>
        <Row className="no-wrap">
          <Col flex="max-content 1 0">{dashboardTexts.unappliedCredits.title}</Col>
        </Row>
        <Row>
          <Col flex="1">
            <GrowText size={'var(--fs-20)'} className="amount">
              {loading ? InputLoaderJSX : UnappliedCreditAmount}
            </GrowText>
          </Col>
        </Row>
      </div>
      <Row>
        <Col flex={'1 0 1'}>{TotalCustInvoiceInfo}</Col>
      </Row>
    </StyledChildCard>
  );
}

export default React.memo(UnappliedCredits);
