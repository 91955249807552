import { disputeMessage } from '../text';

export function getNotifySuccessMessage(bulkAction?: boolean, editMode?: boolean) {
  if (bulkAction) {
    return {
      title: disputeMessage.bulkAction.create.success.title,
      description: disputeMessage.bulkAction.create.success.message,
    };
  } else if (editMode) {
    return {
      title: disputeMessage.update.success.title,
      description: disputeMessage.update.success.message,
    };
  } else {
    return {
      title: disputeMessage.create.success.title,
      description: disputeMessage.create.success.message,
    };
  }
}

export function getNotifyErrorMessage(bulkAction?: boolean, editMode?: boolean) {
  if (bulkAction) {
    return {
      title: disputeMessage.bulkAction.create.error.title,
      description: disputeMessage.bulkAction.create.error.message,
    };
  } else if (editMode) {
    return {
      title: disputeMessage.update.error.title,
      description: disputeMessage.update.error.message,
    };
  } else {
    return {
      title: disputeMessage.create.error.title,
      description: disputeMessage.create.error.message,
    };
  }
}
