import { Contact } from 'types/entities/contact';
import { CustomDetail, CustomDetailConfig, CustomDetailsType } from 'types/entities/custom-detail';
import { cleanArrayPredicate } from 'util/predicates';
import { StyledCustomerTag } from '../HighRiskCustomertags/style';

interface CustomerDetails {
  customDetails: CustomDetail<string | Contact[]>[];
  detailToShow: CustomDetailConfig;
}

function CustomDetails(props: CustomerDetails) {
  const EMPTY_STRING = '';
  function getDetailToShow() {
    const detail = props?.customDetails?.find((detail) =>
      detail.type !== CustomDetailsType.CUSTOM_FIELD
        ? detail.type === props?.detailToShow?.field_type
        : detail.id === props?.detailToShow?.field_id
    );
    if (!detail) return;
    switch (detail.type) {
      case CustomDetailsType.CUSTOM_FIELD:
        if (detail.value) {
          return `${detail.value as string}`;
        }
        break;
      case CustomDetailsType.PRIMARY_CONTACT:
      case CustomDetailsType.SECONDARY_CONTACT:
        const contact = sanitizeContacts(detail.value as Contact[])[0];
        if (contact) {
          return `${contact?.first_name ? contact?.first_name : EMPTY_STRING} ${
            contact.last_name ? contact.last_name : EMPTY_STRING
          }`;
        }
        break;
    }
  }

  function sanitizeContacts(contacts: Contact[]): Partial<Contact[]> {
    return contacts.filter((contact) =>
      cleanArrayPredicate(contact.first_name || contact.last_name)
    );
  }

  const detailStyle: React.CSSProperties = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'default',
  };
  const showDetail = getDetailToShow();
  const mainDetail = (
    <StyledCustomerTag>
      <div style={detailStyle}>{showDetail}</div>
    </StyledCustomerTag>
  );

  const CustomDetails = <>{Boolean(showDetail) && mainDetail}</>;

  return CustomDetails;
}

export default CustomDetails;
