import { ErrorBoundary } from '@sentry/react';
import { amountFormats } from '@sinecycle/growcomponents';
import { Popover, Space, Tooltip } from 'antd';
import { ExtraInfo } from 'components/BaseComponents/GrowTable/ExtraInfo';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { InlineSpacer } from 'components/BaseComponents/Layout/Spacer';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { COMMON_EVENT } from 'events/common';
import { dispatchAppEvent } from 'lib/pub-sub';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { baseCurrencySelector, localeSelector } from 'store/authentication/authentication';
import { formatCurrency } from 'util/number-formatter';
import { ExchangeIcon, FallbackStyledWrapper, MultiCurrencyBox } from './style';
import { AmountProps, AmountWithCurrencySplitProps, MultiCurrencyProps } from './types';
import { canShowCurrencySplitPopup, changeToMultiCurrencyFormat } from './utils';

const texts = {
  title: 'Multi Currency Split',
  invoiceLabel: 'Invoices',
  total: 'Total',
};

function MultiCurrencyData({ multiCurrencySplit, splitTotal, hideSplitTotal }: MultiCurrencyProps) {
  const locale = useSelector(localeSelector);
  const CurrencyList = multiCurrencySplit.map((currencyItem) => {
    const {
      value,
      currency,
      value_in_transformed_currency: value_in_base_currency,
      transformed_currency: base_currency,
      invoice_count,
    } = currencyItem;

    return (
      <div className="multi-currency-item" key={currency}>
        <b className="curreny-name">{currency}</b>
        <div className="currency-amount">
          <b>{formatCurrency(value, { currency: currency, locale })}</b>
          <InlineSpacer size="32" />
          {base_currency !== currency && (
            <span>
              {formatCurrency(value_in_base_currency, { currency: base_currency, locale })}
            </span>
          )}
        </div>
        {invoice_count ? (
          <span className="invoice-count">
            {texts.invoiceLabel} {amountFormats.number(invoice_count, { locale })}
          </span>
        ) : null}
      </div>
    );
  });

  return (
    <MultiCurrencyBox className="multicurrency-box">
      <div className="title">{texts.title}</div>
      <div style={{ maxHeight: '250px', overflow: 'scroll' }}>
        <div className="multi-currency-list">{CurrencyList}</div>
        {!hideSplitTotal && (
          <div className="total">
            <span>{texts.total}</span>
            <InlineSpacer size="32" />
            {splitTotal && (
              <span>
                {formatCurrency(splitTotal.amount, { currency: splitTotal.currency, locale })}
              </span>
            )}
          </div>
        )}
      </div>
    </MultiCurrencyBox>
  );
}

export function Amount(props: AmountProps) {
  const baseCurrency = useSelector(baseCurrencySelector);
  const amount = Number(props.amount);
  const locale = useSelector(localeSelector);

  const StyledWrapper = props.styledComponent ?? FallbackStyledWrapper;
  const formatOptions = props.formatOptions ?? {};
  const options = {
    currency: props.currency ?? baseCurrency ?? 'USD',
    locale,
  };

  let formattedAmount: React.ReactNode = isNaN(amount)
    ? '-'
    : formatCurrency(amount, options, formatOptions);

  if (props.showTooltip) {
    formattedAmount = (
      <Tooltip
        title={formatCurrency(amount, options)}
        onOpenChange={(visible) => {
          if (visible) {
            dispatchAppEvent({
              type: COMMON_EVENT.AMOUNT_HOVER,
              payload: {
                tile: props.context ?? 'unknown tile',
              },
            });
          }
        }}
      >
        <>{formattedAmount}</>
      </Tooltip>
    );
  }

  if (props?.extra?.value) {
    const Title = props.extra.value ? (
      <Flex direction="column" align="flex-start" gap="var(--space-4)">
        {props.extra.value}
      </Flex>
    ) : null;
    formattedAmount = (
      <Popover
        arrow={false}
        placement="right"
        content={Title}
        onOpenChange={(visible) => {
          if (visible) {
            dispatchAppEvent({
              type: COMMON_EVENT.AMOUNT_HOVER,
              payload: {
                tile: props.context ?? 'unknown tile',
              },
            });
          }
        }}
      >
        <div>{isNaN(amount) ? formattedAmount : <ExtraInfo value={formattedAmount} />}</div>
      </Popover>
    );
  }

  if (!props.noTags) {
    formattedAmount = (
      <StyledWrapper style={props.style} className={props.className}>
        {formattedAmount}
      </StyledWrapper>
    );
  }

  return <>{formattedAmount}</>;
}

export function AmountWithCurrencySplit(props: AmountWithCurrencySplitProps) {
  const accBaseCurrency = useSelector(baseCurrencySelector);
  const { hideSplitTotal, hideMCSplitComp, currencyArea = 'CELL' } = props;

  function assignValues() {
    if (props.type === 'single') {
      return changeToMultiCurrencyFormat(
        props.amount,
        props.currency,
        props.baseAmount,
        props.baseCurrency ?? accBaseCurrency ?? 'USD'
      );
    }

    return props.amountWithCurrencySplit;
  }

  const { value, currency, multi_currency_split } = assignValues();

  const showMCSplit = !hideMCSplitComp && canShowCurrencySplitPopup(multi_currency_split, value);

  function getStyles(): React.CSSProperties {
    return currencyArea === 'CELL'
      ? { visibility: showMCSplit && multi_currency_split ? 'visible' : 'hidden' }
      : { display: showMCSplit && multi_currency_split ? 'block' : 'none' };
  }
  const iconStyles = useMemo(getStyles, [currencyArea, multi_currency_split, showMCSplit]);

  return (
    <ErrorBoundary fallback={<Texto>error</Texto>}>
      <Space align={props.align ? props.align : 'end'}>
        <Flex align="center" justify="flex-start" gap="var(--space-8)">
          <Amount
            amount={value}
            currency={currency}
            className={props.className}
            style={props.style}
            styledComponent={props.styledComponent}
            formatOptions={props.formatOptions}
            context={props.context}
            showTooltip={props.showTooltip}
            extra={showMCSplit && multi_currency_split ? undefined : props.amountExtra}
          />
          <>{props.Extra}</>
          <Popover
            arrow={{ pointAtCenter: true }}
            onOpenChange={(visible) => {
              if (visible) {
                dispatchAppEvent({
                  type: COMMON_EVENT.MULTI_CURRENCY_HOVER,
                  payload: {
                    context: props.context ?? 'unknown context',
                  },
                });
              }
            }}
            placement="rightTop"
            content={
              showMCSplit &&
              multi_currency_split && (
                <MultiCurrencyData
                  multiCurrencySplit={multi_currency_split}
                  splitTotal={{ amount: value, currency: currency }}
                  hideSplitTotal={hideSplitTotal}
                />
              )
            }
          >
            <div style={{ ...iconStyles }}>{ExchangeIcon}</div>
          </Popover>
        </Flex>
      </Space>
    </ErrorBoundary>
  );
}
