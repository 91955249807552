import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FocusEventHandler, useEffect, useState } from 'react';
import { searchUsers } from 'services/users';
import { User, UserBasic } from 'types/entities/user';
import { SearchUsersInterface } from '../../../../store/aging/types';
import SelectComponent, { IProps } from '../../../BaseComponents/ASelect';

interface Usersearch {
  optionsList?: User[] | UserBasic[];
  size?: SizeType;
  selectedValues?: number[];
  handleChange: (userList: IProps<SearchUsersInterface>[]) => void;
  isMulti?: boolean;
  placeholder?: string;
  shouldFormat?: string;
  onBlur?: FocusEventHandler<any>;
  includeRelativePath?: boolean;
  disabled?: boolean;
}

const UsersSearchComponent = (props: Usersearch) => {
  const [optionsList, setOptionsList] = useState([] as IProps<User | UserBasic>[]);

  const { includeRelativePath } = props;

  useEffect(() => {
    if (props.optionsList && props.optionsList.length) {
      setOptionsList([...getFormatedUserList(props.optionsList)]);
    }
  }, [props.optionsList]);

  const getFormatedUserList = (users: (User | UserBasic)[]) => {
    return users?.map((user, index) => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
      valueobject: user,
    }));
  };

  const getUsers = (inputValue: string) =>
    new Promise((resolve) => {
      searchUsers(inputValue, includeRelativePath)
        .then((users) => {
          resolve([...optionsList, ...getFormatedUserList(users)]);
        })
        .catch((e) => {
          console.log('Error fetching users', e);
          resolve([...optionsList]);
        });
    });

  return (
    <SelectComponent
      onBlur={props.onBlur}
      className="filter-drop-down"
      mode={'multiple'}
      placeholder={`Search by name`}
      size={props.size}
      onSearchCallBack={getUsers}
      selectedValues={props.selectedValues ?? []}
      optionsList={optionsList}
      onChangeCallBack={(usersList: IProps<SearchUsersInterface>[]) => {
        props.handleChange(usersList);
      }}
      disabled={props.disabled}
    />
  );
};

export default UsersSearchComponent;
