import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutoComplete, Card, Col, Input, Row, Spin, Typography } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { clearSearchResultsThunk, getSearchResultsThunk } from 'controllers/search';
import { COMMON_EVENT } from 'events/common';
import useLoading from 'hooks/use-loader';
import { dispatchAppEvent } from 'lib/pub-sub';
import { debounce } from 'lodash';
import { BaseSelectRef } from 'rc-select';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchSelector } from 'store/search/search';
import { AsyncFetches } from '../../../store/async-fetches/fetches';
import CustomerResults from './CustomerResults';
import InvoiceResults from './InvoiceResults';
import { AutocompleteMenu, KeyboardText, PlaceholderText, SecondaryText, StyledTag } from './style';
import { t } from './texts';
import { SearchResultsProps } from './types';

const { Paragraph } = Typography;

function Search() {
  const searching = useLoading(AsyncFetches.GLOBAL_SEARCH);
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = useState('all');
  const [searchText, setSearchText] = useState('');
  const { results } = useSelector(searchSelector);
  const InputRef = useRef<BaseSelectRef>(null);

  const FilterMenu = (
    <Row style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
      <Col span={24}>
        <StyledTag
          className={`rounded-pill ${searchFilter === 'all' ? 'active' : ''} cursor-pointer`}
          onClick={() => {
            setSearchFilter('all');
            if (searchText.trim()) dispatch(getSearchResultsThunk(searchText, 'all'));
            dispatchAppEvent({
              type: COMMON_EVENT.GLOBAL_SEARCH,
              payload: {
                searchOption: 'all',
              },
            });
          }}
        >
          {t.filterMenu.all}
        </StyledTag>
        <StyledTag
          className={`rounded-pill ${searchFilter === 'customers' ? 'active' : ''} cursor-pointer`}
          onClick={() => {
            setSearchFilter('customers');
            if (searchText.trim()) dispatch(getSearchResultsThunk(searchText, 'customers'));
            dispatchAppEvent({
              type: COMMON_EVENT.GLOBAL_SEARCH,
              payload: {
                searchOption: 'customers',
              },
            });
          }}
        >
          {t.filterMenu.customers}
        </StyledTag>
        <StyledTag
          className={`rounded-pill ${searchFilter === 'invoices' ? 'active' : ''} cursor-pointer`}
          onClick={() => {
            setSearchFilter('invoices');
            if (searchText.trim()) dispatch(getSearchResultsThunk(searchText, 'invoices'));

            dispatchAppEvent({
              type: COMMON_EVENT.GLOBAL_SEARCH,
              payload: {
                searchOption: 'invoices',
              },
            });
          }}
        >
          {t.filterMenu.invoices}
        </StyledTag>
      </Col>
    </Row>
  );

  const SearchContext = (
    <AutocompleteMenu style={{ padding: '0.5rem' }}>
      {FilterMenu}
      <Row style={{ marginBottom: '0.5rem' }}>
        <Col span={24}>
          <FontAwesomeIcon
            icon={['far', 'lightbulb-on']}
            color="var(--orange-5)"
            style={{ marginRight: '0.5rem' }}
          />
          <PlaceholderText>{t.type_to_search}</PlaceholderText>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SecondaryText>{t.customers}</SecondaryText>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Paragraph>{`${t.customer_name}, ${t.customer_id}`}</Paragraph>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SecondaryText>{t.invoices}</SecondaryText>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Paragraph>{`${t.reference_number}, ${t.invoice_title}`}</Paragraph>
        </Col>
      </Row>
    </AutocompleteMenu>
  );

  function SearchResults(props: SearchResultsProps) {
    const { resultText, width } = props;

    const SearchTextComponent = (
      <GrowText strong ellipsis={{ tooltip: searchText }} style={{ maxWidth: width }}>
        {searchText}
      </GrowText>
    );

    return (
      <Flex align="center" gap={'var(--space-4)'}>
        <GrowText>{resultText}</GrowText>
        {"'"}
        {SearchTextComponent}
        {"'"}
      </Flex>
    );
  }

  const NoResults = (
    <AutocompleteMenu style={{ padding: '0.5rem' }}>
      {FilterMenu}
      <Row style={{ marginBottom: 'var(--space-8)' }}>
        <Col span={24}>
          <SearchResults resultText={t.no_results} width={'210px'} />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center text-center">
        <Col span={24}>
          <Card>
            <FontAwesomeIcon
              icon={['far', 'frown']}
              color="var(--orange-5)"
              style={{ marginRight: '0.5rem' }}
            />
            <PlaceholderText>{t.try_another_keyword}</PlaceholderText>
          </Card>
        </Col>
      </Row>
    </AutocompleteMenu>
  );

  const Loader = (
    <AutocompleteMenu style={{ padding: '0.5rem' }}>
      {FilterMenu}
      <Spin
        size="large"
        style={{
          minHeight: '8rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </AutocompleteMenu>
  );

  function dropdownRender(menu: React.ReactElement) {
    if (searching) {
      return Loader;
    }

    if (!searchText || !results) {
      return SearchContext;
    }

    const customerResultsLength = results ? (results.customers ?? []).length : 0;
    const userResultsLength = results ? (results.users ?? []).length : 0;
    const invocieResultsLength = results ? (results.invoices ?? []).length : 0;
    const disputeResultsLength = results ? (results.disputes ?? []).length : 0;
    const totalResults =
      customerResultsLength + userResultsLength + invocieResultsLength + disputeResultsLength;

    if (searchText && results && totalResults === 0) {
      return NoResults;
    }

    if (searchText && results && totalResults > 0)
      return (
        <AutocompleteMenu className="p-2 auto-complete-container px-2">
          <div className="meta-information">
            {FilterMenu}
            <Row>
              <Col span={24}>
                <SearchResults
                  resultText={`Showing ${totalResults} ${
                    totalResults > 1 ? 'Results' : 'Result'
                  } for`}
                  width={'190px'}
                />
              </Col>
            </Row>
          </div>
          <div className="search-cards-list mt-3">
            {(searchFilter === 'all' || searchFilter === 'customers') &&
              (customerResultsLength > 0 || userResultsLength > 0) && (
                <CustomerResults customers={results.customers} users={results.users} />
              )}
            {(searchFilter === 'all' || searchFilter === 'invoices') &&
              invocieResultsLength > 0 && <InvoiceResults invoices={results.invoices} />}
          </div>
        </AutocompleteMenu>
      );

    return menu;
  }

  function handleSearch(value: string) {
    if (value.trim()) {
      dispatch(getSearchResultsThunk(value, searchFilter));
    } else {
      dispatch(clearSearchResultsThunk());
    }
  }

  function handleVisibleChange(open: boolean) {
    if (!open) setSearchText('');
  }

  const debouncedHandleSearch = useCallback(handleSearch, [dispatch, searchFilter]);

  const debouncedSearch = useMemo(() => {
    return debounce(debouncedHandleSearch, 1000);
  }, [debouncedHandleSearch]);
  return (
    <AutoComplete
      onSearch={debouncedSearch}
      options={[{ label: '', options: [] }]}
      style={{ width: '100%' }}
      dropdownRender={dropdownRender}
      dropdownStyle={{ padding: 0, margin: 0 }}
      onChange={(value) => {
        setSearchText(value);
      }}
      ref={InputRef}
      value={searchText}
      onDropdownVisibleChange={handleVisibleChange}
    >
      <Input
        placeholder={t.search}
        suffix={<SearchShortcutInfo />}
        prefix={<FontAwesomeIcon icon={['far', 'search']} />}
        onChange={() => {
          dispatchAppEvent({ type: COMMON_EVENT.GLOBAL_SEARCH });
        }}
      />
    </AutoComplete>
  );
}

function SearchShortcutInfo() {
  const isMac = /mac/i.test((navigator as any).userAgentData?.platform ?? navigator.platform);

  return (
    <div>
      <KeyboardText keyboard>{isMac ? '⌘' : 'Ctrl'}</KeyboardText>
      <KeyboardText keyboard>K</KeyboardText>
    </div>
  );
}

export default Search;
