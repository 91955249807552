import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';

interface FilterInfoProps {
  isDirty: boolean;
  hide?: boolean;
  viewName: string | undefined;
  toggleOpen: () => void;
}

function FilterChangeNotification(props: Pick<FilterInfoProps, 'isDirty' | 'hide'>) {
  if (props.hide) return null;
  if (props.isDirty) return null;

  const AsteriskIcon = (
    <FontAwesomeIcon icon={['fas', 'asterisk']} size="sm" color="var(--red-6)" />
  );
  return <Flex align="flex-start">{AsteriskIcon}</Flex>;
}

function FilterViewName(props: Readonly<FilterInfoProps>) {
  return (
    <Flex align="center" onClick={props.toggleOpen} className="cursor-pointer">
      <Texto as="div" weight="semibold" color="var(--primary-10)" size="16">
        {props.viewName}
      </Texto>
      <Flex.Child alignSelf="flex-start">
        <FilterChangeNotification hide={props.hide} isDirty={props.isDirty} />
      </Flex.Child>
    </Flex>
  );
}

export default FilterViewName;
