import { useParams } from 'lib/router';
import { Navigate } from 'react-router-dom';

const InvoiceDetailsPageFallBack = () => {
  const { id } = useParams();

  return <Navigate to={`/invoices/${id}`} replace />;
};

export default InvoiceDetailsPageFallBack;
