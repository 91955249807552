import { MutationFunction } from '@tanstack/react-query';
import { ActivityKey } from 'queries/activites';
import { useFormSubmitMutation } from '../services/mutation';
import { MutationFunctionProps } from '../types';

export default function useFormSubmit<T>(mutationFn: MutationFunction<T, MutationFunctionProps<T>>) {
  const { mutateAsync, isLoading } = useFormSubmitMutation<T>(mutationFn);

  async function onFormSubmit(props: any, type: ActivityKey, bulkAction?: boolean) {
    try {
      return await mutateAsync({ params: props, type, bulkAction });
    } catch {
      throw new Error();
    }
  }

  return { onFormSubmit, isLoading };
}
