import { Alert, Col, Typography } from 'antd';
import styled from 'styled-components';

const { Text, Title } = Typography;

export const LoginContainer = styled.div`
  background-color: var(--gray-1);
  min-height: 100vh;
`;

export const BannerCol = styled(Col)`
  height: 100vh;
  background: #f5f0ff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: min(30vw, 420px);
`;

export const BannerText = styled(Title)`
  color: #79649e !important;
  margin-top: 50%;
  font-weight: 300 !important;
  margin: 0 4rem;
`;

export const PoweredByText = styled(Text)`
  color: #546e7a;
  margin: 0 4rem;
  margin-bottom: 1rem;
`;

export const StAlert = styled(Alert)`
  position: absolute;
  top: 1rem;
  left: 46%;
  width: 35vw;
`;
