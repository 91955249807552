import { useEffect, useState } from 'react';
import { togglePredicate } from 'util/predicates';

interface useOpenProps {
  open: boolean;
}

interface useOpenReturnType {
  open: boolean;
  toggleOpen: () => void;
  onClose: () => void;
  handleOpenChange: (open: boolean) => void;
}
function useOpen(props: Readonly<useOpenProps>): useOpenReturnType {
  const [open, setOpen] = useState(initializeOpen);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  function initializeOpen() {
    return props.open;
  }
  function toggleOpen() {
    setOpen(togglePredicate);
  }

  function onClose() {
    setOpen(false);
  }
  function handleOpenChange(open: boolean) {
    setOpen(open);
  }
  return { open, toggleOpen, onClose, handleOpenChange };
}

export default useOpen;
