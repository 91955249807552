import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Upload } from 'antd';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { formattingOptions } from 'lib/RichText';
import { commonButtonProps } from '../../Toolbar';
import { ToolbarOptionProps } from '../../type';

const t = {
  label: 'Add Image',
};
const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
  title: t.label,
};

interface InsertImageProps extends ToolbarOptionProps {}
const Icon = <FontAwesomeIcon icon={['far', 'image']} />;

export function InsertImage({ editor }: InsertImageProps) {
  function beforeUpload(file: File) {
    formattingOptions.insertImage.onClick(editor, file);
    return false;
  }

  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    icon: Icon,
  };

  return (
    <Upload beforeUpload={beforeUpload} showUploadList={false}>
      <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />
    </Upload>
  );
}
