import { EditorContent } from 'lib/RichText';
import { Editor } from 'lib/RichText/type';

interface EditorProps {
  editor: Editor | null;
}

export function EditorCanvas({ editor }: EditorProps) {
  return <EditorContent editor={editor} />;
}
