import { ImageLoader } from 'components/BaseComponents/RTE/Toolbar/common/nodes/CustomImageCanvas';
import { getNodeViewRender, Node } from 'lib/RichText';
import { CustomImagePlugin } from 'lib/RichText/TipTapEditor/extensions/Image/drop-down-image-plugin';
import { cssTextToReactProps } from 'util/css-js';

export function CustomImageNode() {
  return Node.create({
    name: 'customImageCanvas',
    group: 'block',
    atom: true,
    draggable: true,
    addAttributes() {
      return {
        imageSrc: {
          default: null,
          parseHTML: (element) => {
            if (element) {
              return element.children[0].getAttribute('src');
            }
          },
        },
        imageStyle: {
          default: {
            display: 'flex',
            width: '250px',
          } as React.CSSProperties,
          parseHTML: (element) => {
            const imageStyle = {
              display: 'flex',
            } as React.CSSProperties;
            if (element) {
              const parentCssText = element.getAttribute('style');

              if (parentCssText) {
                const reactCSSProps = cssTextToReactProps(parentCssText);
                imageStyle.justifyContent = reactCSSProps.textAlign ?? reactCSSProps.justifyContent;
              }

              const imageCssText = element.children[0].getAttribute('style');

              if (imageCssText) {
                const imageCSSProps = cssTextToReactProps(imageCssText);
                imageStyle.width = imageCSSProps.width;
              }
            }

            return imageStyle;
          },
        },
        image: {
          default: null,
        },
      };
    },
    renderHTML({ HTMLAttributes, node }) {
      const css = HTMLAttributes['imageStyle'] as React.CSSProperties;
      const dom = document.createElement('div');

      const image = document.createElement('img');
      image.src = HTMLAttributes.imageSrc;
      image.alt = 'rte-image';
      image.style.cssText = `margin:8px 0;width:${css.width}`;
      dom.classList.add('gf-rte-image-container');
      dom.append(image);
      dom.style.cssText = `text-align:${css.justifyContent}`;

      return {
        dom,
      };
    },
    parseHTML() {
      return [
        {
          tag: 'div.gf-rte-image-container',
        },
      ];
    },
    addNodeView() {
      return getNodeViewRender(ImageLoader);
    },
    addProseMirrorPlugins() {
      return [CustomImagePlugin()];
    },
  });
}
