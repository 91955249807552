import { Popover } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import React, { ReactNode } from 'react';

interface PopoverButtonProps extends React.ComponentProps<typeof Popover> {
  children?: ReactNode;
}

export function PopoverButton(props: PopoverButtonProps) {
  const { children } = props;

  const DropdownChildren = (
    <Flex gap="var(--space-8)" className="cursor-pointer" align="center">
      {children}
    </Flex>
  );

  return React.createElement(
    Popover,
    { ...props, placement: 'bottom', trigger: ['click'] },
    DropdownChildren
  );
}
