export function generateClassName(classNames: Array<string | undefined>) {
  return classNames.reduce(
    (accumulator, currentValue) => (currentValue ? `${accumulator} ${currentValue}` : accumulator),
    ''
  );
}

export function wrapCssProp(value?: string) {
  return value && value.startsWith('--') ? `var(${value})` : value;
}
