import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowTypography } from '@sinecycle/growcomponents';
import { Space } from 'antd';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { formattingOptions } from 'lib/RichText';
import { commonButtonProps } from '../../Toolbar';
import { DropdownButton } from '../../common/DropdownWrapper';
import { ToolbarOptionProps } from '../../type';

const t = {
  label: 'Table',
  addTable: 'Add Table',
  deleteTable: 'Delete Table',
  addColBefore: 'Add Column Before',
  addColAfter: 'Add Column After',
  deleteCol: 'Delete Column',
  addRowBefore: 'Add Row Before',
  addRowAfter: 'Add Row After',
  deleteRow: 'Delete Row',
  mergeCells: 'Merge Cells',
  splitCell: 'Split Cell',
};

const Icon = <FontAwesomeIcon icon={['far', 'table']} />;

const toolTipProps: React.ComponentProps<typeof Button>['toolTipProps'] = {
  title: t.label,
};

interface TableOptions {
  key: keyof typeof formattingOptions.table;
  label: string;
  icon: IconProp;
  color?: 'var(--purple-7)' | 'var(--red-7)';
}

const tableOptions: TableOptions[] = [
  {
    key: 'addTable',
    label: t.addTable,
    icon: ['far', 'plus-circle'],
  },
  {
    key: 'deleteTable',
    label: t.deleteTable,
    icon: ['far', 'minus-circle'],
    color: 'var(--red-7)',
  },
  {
    key: 'addColBefore',
    label: t.addColBefore,
    icon: ['far', 'plus-circle'],
  },
  {
    key: 'addColAfter',
    label: t.addColAfter,
    icon: ['far', 'plus-circle'],
  },
  {
    key: 'deleteCol',
    label: t.deleteCol,
    icon: ['far', 'minus-circle'],
    color: 'var(--red-7)',
  },
  {
    key: 'addRowBefore',
    label: t.addRowBefore,
    icon: ['far', 'plus-circle'],
  },
  {
    key: 'addRowAfter',
    label: t.addRowAfter,
    icon: ['far', 'plus-circle'],
  },
  {
    key: 'deleteRow',
    label: t.deleteRow,
    icon: ['far', 'minus-circle'],
    color: 'var(--red-7)',
  },
  {
    key: 'mergeCell',
    label: t.mergeCells,
    icon: ['far', 'merge'],
  },
  {
    key: 'splitCell',
    label: t.splitCell,
    icon: ['far', 'split'],
  },
];

interface Tableprops extends ToolbarOptionProps {}
export function Table({ editor }: Tableprops) {
  const buttonProps: React.ComponentProps<typeof Button>['buttonProps'] = {
    ...commonButtonProps,
    icon: Icon,
  };

  const TableIcon = <Button buttonProps={buttonProps} toolTipProps={toolTipProps} />;

  function onSelectCallback(key: TableOptions['key']) {
    formattingOptions.table[key].onClick(editor);
  }

  const tableOptionItems = tableOptions.map((option) => {
    return {
      key: option.key,
      label: (
        <Space>
          <FontAwesomeIcon
            icon={option.icon}
            style={{ height: '16px' }}
            color={option.color ?? 'var(--purple-7)'}
          />
          <GrowTypography.Text>{option.label}</GrowTypography.Text>
        </Space>
      ),
    };
  });

  return (
    <DropdownButton
      menu={{
        items: tableOptionItems,
        onClick: (key) => {
          onSelectCallback(key.key as TableOptions['key']);
        },
      }}
      placement="bottom"
    >
      {TableIcon}
    </DropdownButton>
  );
}
