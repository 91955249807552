import { Form } from 'antd';
import { notify } from 'components/BaseComponents/Notifications';
import { ActivityType } from 'types/activities/activity-types';
import useFormSubmit from '../hooks/useFormSubmit';
import { FormItemNames, TaskFormModalProps, TaskModalFormElementProps } from '../types';
import { parseDateTransformSchema } from '../Utils';
import { getNotifyErrorMessage, getNotifySuccessMessage } from './notificationMessage';
import TaskFormItems from './TaskFormItems';

export default function TaskForm<TaskElements extends TaskModalFormElementProps>(
  props: Omit<TaskFormModalProps<TaskElements>, 'openModal'>
) {
  const { form, responseSchema, onMutateFn, onFormClose, customTransformer, bulkAction, editMode } =
    props;
  const { onFormSubmit } = useFormSubmit<TaskElements['responseType']>(onMutateFn);

  function onFormFinish(values: Record<FormItemNames, any>) {
    const formValues = parseDateTransformSchema<
      TaskElements['responseType'],
      TaskElements['responseSchema']
    >({
      data: values,
      type: ActivityType.TASK,
      responseSchema,
      dateKey: ['taskDate'],
      customTransformer,
    });

    onFormSubmit(formValues, ActivityType.TASK)
      .then(() => {
        onFormClose();
        const { title, description } = getNotifySuccessMessage(bulkAction, editMode);
        notify.success(title, {
          description: description,
        });
      })
      .catch(() => {
        const { title, description } = getNotifyErrorMessage(bulkAction, editMode);
        notify.error(title, {
          description: description,
        });
      });
  }

  return (
    <Form.Provider
      onFormFinish={(name, { values }) => {
        onFormFinish(values as Record<FormItemNames, any>);
      }}
    >
      <Form name={'activityForm'} form={form} layout="vertical" preserve={false}>
        <TaskFormItems<TaskElements> {...props} />
      </Form>
    </Form.Provider>
  );
}
