import ActivitiesModalLoader from 'components/CollectionActivities/Common/ActivitiesModalLoader';
import { DetailsLayout } from 'components/CollectionActivities/Common/Details/DetailsLayout';
import useEmitActivityEvents from 'components/CollectionActivities/hooks/useEmitActivityEvents';
import { ActivityLoader } from 'components/CollectionActivities/style';
import { useCollectionActivityQuery } from 'queries/activites';
import { ActivityDetailsBaseProps } from 'types/activities/activity-types';
import { WriteOff } from 'types/activities/write-off';
import { WriteOffDetailsBody } from './WriteOffDetailsBody';
import { WriteOffDetailsInfo } from './WriteOffDetailsInfo';

interface WriteoffDetailsProps extends ActivityDetailsBaseProps {}

function WriteoffDetails(props: WriteoffDetailsProps) {
  const { id, customer, hideActions, type, mode, subscriptionType, isListFetched, activitiesMode } =
    props;
  const { data, isLoading, error } = useCollectionActivityQuery<WriteOff>({
    type,
    id,
    isListFetched,
  });
  const isUnauthorized = error ? error?.status === 403 : false;
  useEmitActivityEvents(isLoading);
  if (activitiesMode && isLoading) {
    return (
      <ActivityLoader>
        <ActivitiesModalLoader />
      </ActivityLoader>
    );
  }

  return (
    <DetailsLayout
      mode={mode}
      isUnauthorized={isUnauthorized}
      content={
        <WriteOffDetailsBody
          data={data}
          isLoading={isLoading}
          customer={customer}
          subscriptionType={subscriptionType}
          activitiesMode={activitiesMode}
        />
      }
      info={
        <WriteOffDetailsInfo
          loading={isLoading}
          data={data}
          customer={customer}
          hideActions={hideActions}
        />
      }
    />
  );
}

export { WriteoffDetails };
