import {
  ActivityAction,
  ActivityType,
  FollowupActivity,
  FollowupStatus,
  InvoiceCollectionActivity,
} from 'types/activities/activity-types';

export const FollowupActionLabels: Record<FollowupActivity, string> = {
  [FollowupActivity.ASSIGNED]: 'Owner/Strategy Assigned',
  [FollowupActivity.NOT_ASSIGNED]: 'Not Assigned',
  [FollowupActivity.FOLLOWED_UP]: 'Followed Up',
  [FollowupActivity.NOT_FOLLOWED_UP]: 'Not Followed Up',
  [FollowupActivity.VIEWED]: 'Email Viewed',
  [FollowupActivity.NOT_VIEWED]: 'Not Viewed',
  [FollowupActivity.RESPONDED]: 'Customer Responded',
  [FollowupActivity.NOT_RESPONDED]: 'Not Responded',
  [FollowupActivity.OPEN_INVOICE_BALANCE]: 'Total Debtors',
};

const ActivityActionLabels: Record<ActivityAction, string> = {
  [ActivityAction.EMAIL_SENT]: 'Email Sent',
  [ActivityAction.EMAIL_OPENED]: 'Email Opened',
  [ActivityAction.EMAIL_BOUNCED]: 'Email Delivery Failed',
  [ActivityAction.RESPONDED]: 'Responded',
  [ActivityAction.VIEWED_ATTACHMENT]: 'Viewed Attachment',
  [ActivityAction.DISPUTE_OPEN]: 'Dispute Open',
  [ActivityAction.DISPUTE_CLOSED]: 'Dispute Closed',
  [ActivityAction.DISPUTE_WAITING_ON_CUSTOMER]: 'Dispute Waiting on Customer',
  [ActivityAction.ESCALATION_OPEN]: 'Escalation Open',
  [ActivityAction.ESCALATION_CLOSED]: 'Escalation Closed',
  [ActivityAction.PTP_OPEN]: 'PTP Open',
  [ActivityAction.PTP_CLOSED]: 'PTP Closed',
  [ActivityAction.CONTACT_MISSING]: 'Contact Missing',
  [ActivityAction.INVALID_EMAIL_ID]: 'Invalid Email ID',
};
const ActivityTypeLabels: Record<Exclude<ActivityType, 'DOCUMENT'>, string> = {
  [ActivityType.EMAIL]: 'Email',
  [ActivityType.CALL_LOG]: 'Call Log',
  [ActivityType.ESCALATION]: 'Escalation',
  [ActivityType.DISPUTE]: 'Dispute',
  [ActivityType.PROMISE_TO_PAY]: 'Promise to Pay',
  [ActivityType.WRITE_OFF]: 'Write Off',
  [ActivityType.NOTE]: 'Note',
  [ActivityType.TASK]: 'Task',
  [ActivityType.PAYMENT]: 'Payment',
  [ActivityType.CREDIT_MEMO]: 'Credit Memo',
};

export const invoiceCollectionActivityLabels: Record<InvoiceCollectionActivity, string> = {
  [InvoiceCollectionActivity.EMAIL_DELIVERED]: 'Email not delivered',
  [InvoiceCollectionActivity.EMAIL_NOT_SENT]: 'Email not sent',
  [InvoiceCollectionActivity.INVOICE_FLAGGED]: 'Invoice Flagged',
};

export const FollowupStatusLabels: Record<FollowupStatus, string> = {
  ...ActivityTypeLabels,
  ...ActivityActionLabels,
  ...FollowupActionLabels,
  ...invoiceCollectionActivityLabels,
};
