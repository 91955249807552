import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers/rootReducer';
import { Credentials, IntegrationAction, Integrations } from './types';

const initialState: Record<Integrations, Partial<Credentials>> = {
  zoho_books: {},
  stripe: {},
  zoho_invoice: {},
  chargebee: {},
  netsuite: {},
  zuora: {},
  invoiced: {},
  salesforce: {},
  quick_books: {},
  xero: {},
  sage: {},
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    addIntegration(state, action: PayloadAction<IntegrationAction>) {
      const { integrationName, credentials } = action.payload;
      state[integrationName] = credentials;
    },
    removeIntegration(state, action: PayloadAction<Integrations>) {
      const integrationName = action.payload;
      state[integrationName] = {};
    },
  },
});

export function getCredentialsSelector(integrationName: Integrations) {
  return createSelector(
    (state: RootState) => state.integrations,
    (integrations) => integrations[integrationName]
  );
}

export const { addIntegration } = integrationsSlice.actions;

export default integrationsSlice.reducer;
