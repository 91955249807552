import { DisputeStatus } from 'components/CollectionActivites/Disputes/types';
import { handleCreateCustomEventCallBack } from 'components/Inbox/CollectionActivitiesV2/hooks/useActivityUpdation';
import { ActivityType } from 'types/activities/activity-types';
import { EscalationType } from 'types/activities/escalation';
import { PromiseToPayStatus } from 'types/activities/promise-to-pay';
import { Task, TaskStatus } from 'types/activities/task';
import { PerformedActionType, SubscribedEntityType } from 'types/api/inbox/activity';
import { DisputeStatusQuickSelect } from '../Dispute/Common/DisputeStatus';
import { EscalationStatusQuickSelect } from '../Escalation/Common/EscalationStatus';
import { PTPStatusQuickSelect } from '../PromiseToPay/Details/Common/PTPStatus';
import { MarkAsCompleteDropDown } from '../Task/Common/MarkAsCompleteDropdown';

export default function useGetActivityActions(
  activity: SubscribedEntityType,
  onStatusUpdate?: (data: EscalationType | DisputeStatus | TaskStatus | PromiseToPayStatus) => void
) {
  switch (activity.entity_type) {
    case ActivityType.ESCALATION: {
      return (
        <EscalationStatusQuickSelect
          status={activity.entity_detail.status}
          escalationActivityId={activity.entity_detail.id}
          onChangeCallBack={(value) => {
            onStatusUpdate?.(value);
            handleCreateCustomEventCallBack('updating_query', {
              id: String(activity.entity_detail.id),
              activity: ActivityType.ESCALATION,
              value: { status: value, action: PerformedActionType.STATUS_CHANGED },
            });
          }}
          size="small"
          activitiesMode
        />
      );
    }
    case ActivityType.DISPUTE: {
      return (
        <DisputeStatusQuickSelect
          disputeActivityId={Number(activity.entity_detail.id)}
          status={activity.entity_detail.status}
          onChangeCallBack={(status) => {
            onStatusUpdate?.(status);
            handleCreateCustomEventCallBack('updating_query', {
              id: activity.entity_detail.id,
              activity: ActivityType.DISPUTE,
              value: { status, action: PerformedActionType.STATUS_CHANGED },
            });
          }}
          size="small"
          activitiesMode
        />
      );
    }
    case ActivityType.TASK: {
      return (
        <MarkAsCompleteDropDown
          taskId={activity.entity_detail.id}
          status={activity.entity_detail.status}
          data={activity as unknown as Task}
          onChangeCallback={(status) => {
            onStatusUpdate?.(status);
            handleCreateCustomEventCallBack('updating_query', {
              id: String(activity.entity_detail.id),
              activity: ActivityType.TASK,
              value: {
                status,
                date: activity.entity_detail.due_date,
                action: PerformedActionType.STATUS_CHANGED,
              },
            });
          }}
          activitiesMode
        />
      );
    }
    case ActivityType.PROMISE_TO_PAY: {
      return (
        <PTPStatusQuickSelect
          ptpActivityId={activity.entity_detail.id}
          status={activity.entity_detail.status}
          onChangeCallBack={(status) => {
            onStatusUpdate?.(status);
            handleCreateCustomEventCallBack('updating_query', {
              id: activity.entity_detail.id,
              activity: ActivityType.PROMISE_TO_PAY,
              value: { status, action: PerformedActionType.STATUS_CHANGED },
            });
          }}
          size="small"
          activitiesMode
        />
      );
    }
    default: {
      return;
    }
  }
}
