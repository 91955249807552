import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { useMemo } from 'react';
import { pagePath, pagePrivileges } from 'router/constants/page-info';
import { AccountConfigKey } from 'types/entities/account';
import { AppSubRoute } from '../sidebar.components';
import { getSidebarFontAwesomeIconComponent } from '../sidebar.utils';
import { PageListItems } from './usePageList';

function useReportsRoute() {
  const hasCustomReportsConfig = useConfig(AccountConfigKey.CUSTOMER_REPORT);
  const hasAdvancedReportsConfig = useConfig(AccountConfigKey.ADVANCED_REPORTS);
  const hasAdvancedReportsPrivilege = usePrivilegeStatus(pagePrivileges('ADVANCED_REPORTS'));
  const hasCustomReportsPrivilege = usePrivilegeStatus(pagePrivileges('REPORTS'));

  function handleReportsSubLinks() {
    const subLinks: AppSubRoute[] = [];

    if (hasCustomReportsConfig && hasCustomReportsPrivilege) {
      subLinks.push({ label: 'Custom Reports', url: pagePath('REPORTS') });
    }

    if (hasAdvancedReportsConfig && hasAdvancedReportsPrivilege) {
      subLinks.push({ label: 'Advanced Reports', url: pagePath('ADVANCED_REPORTS') });
    }
    return subLinks;
  }

  const reportsSubLinks: AppSubRoute[] = useMemo(handleReportsSubLinks, [
    hasAdvancedReportsConfig,
    hasAdvancedReportsPrivilege,
    hasCustomReportsConfig,
    hasCustomReportsPrivilege,
  ]);
  const reportPageList: Array<PageListItems> = [
    {
      key: 'REPORTS',
      subLinks: reportsSubLinks,
      label: 'Reports',
      access: Boolean(reportsSubLinks.length),
      icon: getSidebarFontAwesomeIconComponent(['far', 'file-chart-line']),
    },
  ];
  return { reportPageList };
}

export default useReportsRoute;
