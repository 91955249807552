import { PayloadAction } from '@reduxjs/toolkit';
import { Invoice } from 'types/entities/invoice';
import { PeriodDetails } from './../../../types/api/collectors/collectors-management';
import { ModifyCollectionTarget, ModifyTargetAmountActions } from './types';

export function updatedModifyInvoices(
  state: ModifyCollectionTarget,
  action: PayloadAction<Invoice>
) {
  state.modifyInvoicesData = [...state.modifyInvoicesData, action.payload];
}

export function filterModifyInvoices(state: ModifyCollectionTarget, action: PayloadAction<number>) {
  state.modifyInvoicesData = state.modifyInvoicesData.filter(
    (invoice) => invoice.id !== action.payload
  );
}
export function resetModifyInvoices(state: ModifyCollectionTarget) {
  state.modifyInvoicesData = [];
}

export function updateShowRemovedInvoice(
  state: ModifyCollectionTarget,
  action: PayloadAction<boolean>
) {
  state.showRemovedInvoices = action.payload;
}

export function filterRemovedInvoices(
  state: ModifyCollectionTarget,
  action: PayloadAction<number>
) {
  if (state.periodDetails.removed_invoices) {
    state.periodDetails.removed_invoices = state.periodDetails.removed_invoices.filter(
      (removedInvoice) => removedInvoice.id !== action.payload
    );
  }
}

export function updateRemovedInvoices(
  state: ModifyCollectionTarget,
  action: PayloadAction<Invoice>
) {
  state.periodDetails.removed_invoices =
    state.periodDetails.removed_invoices && Boolean(state.periodDetails.removed_invoices?.length)
      ? [...state.periodDetails.removed_invoices, action.payload]
      : [action.payload];
}

export function updateModifiedAmount(
  state: ModifyCollectionTarget,
  action: PayloadAction<ModifyTargetAmountActions>
) {
  if (action.payload.key === 'NOT_IN_TARGET') {
    if (!state.modifiedTarget) {
      if (state.periodDetails.current_target) {
        const modifiedAmount = state.periodDetails.current_target?.value + action.payload.amount;
        state.modifiedTarget =
          modifiedAmount === state.periodDetails.current_target?.value
            ? undefined
            : {
                value: state.periodDetails.current_target?.value
                  ? modifiedAmount
                  : action.payload.amount,
                currency: 'USD',
              };
      }
    } else {
      const currentAmount = state.modifiedTarget?.value
        ? state.modifiedTarget?.value + action.payload.amount
        : action.payload.amount;

      if (state.modifiedTarget) {
        state.modifiedTarget =
          currentAmount === state.periodDetails.current_target?.value
            ? undefined
            : {
                ...state.modifiedTarget,
                value: currentAmount,
              };
      }
    }
  }

  if (action.payload.key === 'IN_TARGET' && state.periodDetails?.current_target) {
    if (!state.modifiedTarget) {
      state.modifiedTarget = {
        value: state.periodDetails.current_target.value - action.payload.amount,
        currency: 'USD',
      };
    } else {
      const currentAmount = state.modifiedTarget?.value - action.payload.amount;
      if (state.modifiedTarget) {
        state.modifiedTarget =
          currentAmount === state.periodDetails.current_target.value
            ? undefined
            : {
                ...state.modifiedTarget,
                value: currentAmount,
              };
      }
    }
  }
}

export function resetModifiedAmount(state: ModifyCollectionTarget) {
  state.modifiedTarget = undefined;
}

export function setPeriodDetails(
  state: ModifyCollectionTarget,
  action: PayloadAction<PeriodDetails>
) {
  state.periodDetails = action.payload;
  state.removed_invoicesIds = action.payload.removed_invoices?.map((invoice) => invoice.id);
}
