import { Layout, LayoutContent } from 'components/BaseComponents/Layout/AntLayout';
import { ErrorBoundaryWrapper as ErrorBoundary } from 'components/Common/ErrorBoundary';
import { Outlet, useLocation, usePageNavigate } from 'lib/router';
import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PageSuspenseFallback } from 'router/PageContainer';
import { appInitializationStateSelector } from 'store/authentication/authentication';
import styled from 'styled-components';
import { AppLoadder } from '../../AppLoader';
import Header from './Header';
import { LayoutContext, LayoutContextDispatch, useInitLayoutContext } from './LayoutContext';
import { AppSidebar } from './Sidebar.tsx';
import ActivitiesModal from 'components/Common/ActivitiesModal';
import ActivitiesFormEdit from 'components/Common/ActivitiesModal/ActivitiesFormEdit';

const PageContent = styled(LayoutContent)`
  overflow-y: auto;
  background-color: var(--background-1);
  height: calc(100vh - var(--app-header-height));
`;

export default function ProtectedLayout() {
  const [layoutContext, dispatch] = useInitLayoutContext();
  const navigate = usePageNavigate();
  const location = useLocation();

  const { loading, authentication } = useSelector(appInitializationStateSelector);

  useEffect(() => {
    if (authentication !== 'not-authenticated') return;
    if (location.pathname !== '/' || location.search) {
      const searchParams = new URLSearchParams({
        pathname: location.pathname,
        search: location.search,
      });
      navigate({ appPage: 'LOGIN', searchParams });
    } else {
      navigate({ appPage: 'LOGIN' });
    }
  }, [authentication, navigate, location]);

  if (loading || authentication === 'authenticating') {
    return <AppLoadder />;
  }

  return (
    <Layout>
      <LayoutContext.Provider value={layoutContext}>
        <LayoutContextDispatch.Provider value={dispatch}>
          <AppSidebar />
          <Layout>
            <Header />
            <PageContent>
              <ErrorBoundary>
                <Suspense fallback={<PageSuspenseFallback />}>
                  <Outlet />
                  <ActivitiesModal />
                  <ActivitiesFormEdit />
                </Suspense>
              </ErrorBoundary>
            </PageContent>
          </Layout>
        </LayoutContextDispatch.Provider>
      </LayoutContext.Provider>
    </Layout>
  );
}
