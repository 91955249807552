import { Skeleton } from 'antd';
import React from 'react';

interface FilterViewsLoaderProps {
  loading: boolean;
  hide?: boolean;
  children: React.ReactNode;
}
const Loader = <Skeleton.Input />;

function FilterViewsLoader(props: FilterViewsLoaderProps) {
  if (props.hide) return null;
  if (props.loading) return Loader;
  return <>{props.children}</>;
}

export default FilterViewsLoader;
