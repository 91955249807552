import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllInternalContactsResponseState } from './types';

const initialState: AllInternalContactsResponseState = {
  status: 0,
};

const internalContactsSlice = createSlice({
  name: 'internalContacts',
  initialState,
  reducers: {
    getInternalContactsHandler(state, action: PayloadAction<AllInternalContactsResponseState>) {
      state.internal_contacts = action.payload.internal_contacts;
    },
  },
});

export const { getInternalContactsHandler } = internalContactsSlice.actions;

export default internalContactsSlice.reducer;
