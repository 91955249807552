import { localStore, sessionStore } from './browser-storage';

export function clearStorageValues(propertyKey: PropertyKey, deleteAbleKey: string) {
  const keys = ['AGING_FILTERS', 'ALL_CUSTOMERS_FILTERS', 'OPEN_INVOICES', 'PAYMENT_LIST_FILTERS'];
  const { getItem: getFromSessionStorage, setItem: setToSessionStorage } = sessionStore;
  keys.forEach((key: string) => {
    const value = getFromSessionStorage(key) as any;

    if (!value) return;
    for (const keys in value as any) {
      if (
        value[keys][propertyKey] &&
        Array.isArray(value[keys][propertyKey]) &&
        value[keys][propertyKey]
          .map((f: any) => Object.keys(f).includes(deleteAbleKey))
          .includes(true)
      ) {
        delete value[keys];
      }
    }
    setToSessionStorage(key, value);
  });
}

export function clearLocalStorageValues() {
  const keys = [
    'COLUMNS_CLOSED_IN',
    'COLUMNS_CLOSED_IN_C',
    'COLUMNS_DRAFT_IN',
    'COLUMNS_DRAFT_IN_C',
    'COLUMNS_OPEN_IN',
    'COLUMNS_OPEN_IN_C',
  ];
  const { getItem, setItem, removeItem } = localStore;

  const isColumnClear = getItem('COLUMN_CLEARED');

  if (isColumnClear) return;
  keys.forEach((key: string) => {
    removeItem(key);
    setItem('COLUMN_CLEARED', 'true');
  });
}
