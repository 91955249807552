import { Empty } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';

interface EmptyProps {
  isEmpty: boolean;
  children: React.ReactNode;
}
const emptyStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
};

export function getIsEmpty<T extends Object>(data: T[], keys?: (keyof T)[]): boolean {
  if (!data.length) return true;
  return keys
    ? keys
        .flatMap((key) => {
          return data.map((datum) => datum[key]);
        })
        .every((value) => (value as unknown as number) === 0)
    : false;
}

function EmptyChart(props: EmptyProps) {
  const { isEmpty, children } = props;

  const EmptyState = (
    <Flex align="center" style={{ width: '100%', height: '280px' }}>
      <Empty style={emptyStyle} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Flex>
  );
  const Children = <>{children}</>;

  return isEmpty ? EmptyState : Children;
}

export default EmptyChart;
