import { Tabs } from 'antd';
import styled from 'styled-components';

export const ScrollableTabs = styled(Tabs)<{ headerheight?: string }>`
  --tab-header-height: ${({ headerheight }) => headerheight ?? '46px'};
  --tab-content-height: calc(100vh - var(--app-header-height) - var(--tab-header-height));

  .ant-tabs-nav {
    height: var(--tab-header-height);
  }

  .scrollable-tab-content {
    height: var(--tab-content-height);
  }
`;
