import { Popover } from 'antd';
import { HelpIcon, InfoIcon } from '../Icons';
const defaultOverlayStyle: React.CSSProperties = {
  border: '1px solid var(--gray-4)',
  boxShadow: 'var(--shadow-2)',
  borderRadius: 'var(--br-3)',
};

interface InfoPopoverProps {
  content?: React.ComponentProps<typeof Popover>['content'];
  title?: React.ComponentProps<typeof Popover>['title'];
  icon?: 'info' | 'help';
  overlayStyle?: React.ComponentProps<typeof Popover>['overlayStyle'];
}

function PopoverIcons(icon: InfoPopoverProps['icon']) {
  switch (icon) {
    case 'help':
      return <HelpIcon />;

    default:
      return <InfoIcon />;
  }
}

export function InfoPopover(props: InfoPopoverProps) {
  const { content, title, icon, overlayStyle } = props;

  const Icon = PopoverIcons(icon);

  return (
    <Popover
      overlayClassName="tooltip-test"
      content={content}
      title={title}
      color={'var(--gray-1)'}
      overlayInnerStyle={{
        ...defaultOverlayStyle,
        ...overlayStyle,
      }}
      placement="topLeft"
      arrow={{ pointAtCenter: true }}
    >
      {Icon}
    </Popover>
  );
}
