import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { notify } from 'components/BaseComponents/Notifications';
import { GrowText } from 'components/BaseComponents/Typography';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { configureExchangeRate, getAllAccountSources } from 'services/integrations';
import { dateFormatSelector } from 'store/authentication/authentication';
import { exchangeRateSource } from 'types/api/currency-page-settings';
import { CurrencySettingPatchProps } from '../../type';
import { useSelectedCurrencies } from '../CurrencyContextApi/ContextApi';
import { RatesMessage } from './MessageHelper';

const t = {
  customRateSucessMoreDetail: {
    firstHalf: 'Custom currency exchange rate for the period between',
    secondHalf: 'is set successfully',
  },
  errorMsg: 'Currency conversion already set for some/all dates in period selected',
};

export function useGetAccountSource() {
  return useQuery({
    queryKey: ['account-source'],
    retry: false,
    queryFn: () => getAllAccountSources(),
  });
}

export function useCurrencyExchangeConfigQuery(id: number) {
  const { setUnsavedChanges } = useSelectedCurrencies();
  const queryClient = useQueryClient();
  const format = useSelector(dateFormatSelector);
  return useMutation({
    mutationFn: (data: CurrencySettingPatchProps) => configureExchangeRate(data, id),
    retry: false,

    onSuccess(data) {
      setUnsavedChanges(false);
      queryClient.invalidateQueries(['account-source']);
      notify.success(
        <Flex direction="column">
          <GrowText className=".text-16" weight="bold" color="#52C41A">
            {RatesMessage(data.exchange_rate_source)}
          </GrowText>
          {data.exchange_rate_source === exchangeRateSource.STATIC && (
            <GrowText className="text-14">
              {t.customRateSucessMoreDetail.firstHalf}{' '}
              {dayjs(data.exchange_rate_config[0].start_date).format(format)} and{' '}
              {dayjs(data.exchange_rate_config[0].end_date).format(format)}{' '}
              {t.customRateSucessMoreDetail.secondHalf}
            </GrowText>
          )}
        </Flex>
      );
    },
    onError(data) {
      setUnsavedChanges(true);
      notify.error(t.errorMsg);
    },
  });
}
