import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { InvoiceListTabKeys } from 'components/InvoiceListV2';
import { DownloadReportsButton } from 'components/NewReports/DownloadButton';
import { DraftInvoicesReportsCard } from 'components/NewReports/QuickReportsComponents';
import { INVOICE_LIST_EVENT } from 'events/invoice-List';
import { dispatchAppEvent } from 'lib/pub-sub';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { draftInvoiceCurrentFilterSelector } from 'store/invoice-v2/draft-invoice-selector';

interface DownloadReportsProps {
  hide?: boolean;
  loading?: boolean;
}
export function DownloadDraftReports(props: Readonly<DownloadReportsProps>) {
  const hasQuickReportsPrivilege = usePrivilegeStatus([ERolesAndPrivileges.GENERATE_REPORTS]);
  const [downloadReportsVisible, setDownloadReportsVisible] = useState(false);
  const currentFilter = useSelector(draftInvoiceCurrentFilterSelector);
  if (!hasQuickReportsPrivilege || props.hide) {
    return null;
  }

  function dispatchAppEventCallback() {
    dispatchAppEvent({
      type: INVOICE_LIST_EVENT.INVOICE_LIST_DOWNLOAD_QUICK_REPORTS,
      payload: {
        tab: InvoiceListTabKeys.DRAFT_INVOICE,
        status: 'Open',
      },
    });
  }

  function handleDownloadCardVisible() {
    setDownloadReportsVisible(false);
  }

  function handleDownloadButton() {
    setDownloadReportsVisible(!downloadReportsVisible);
  }

  const DraftInvoiceQuickReports = (
    <DraftInvoicesReportsCard
      filters={currentFilter}
      onDownloadFinish={handleDownloadCardVisible}
      handleCloseCallback={handleDownloadCardVisible}
    />
  );

  return (
    <DownloadReportsButton
      style={{ marginRight: 'var(--space-12)' }}
      componentToDisplay={DraftInvoiceQuickReports}
      popoverVisible={downloadReportsVisible}
      onVisibleChange={(visible) => {
        if (visible) {
          dispatchAppEventCallback();
        }
      }}
      onClick={handleDownloadButton}
      loading={props.loading}
    />
  );
}
