import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { useMemo } from 'react';
import { pagePath, pagePrivileges } from 'router/constants/page-info';
import { AppSubRoute } from '../sidebar.components';
import { getSidebarFontAwesomeIconComponent } from '../sidebar.utils';
import { PageListItems } from './usePageList';

function useTransactionsRoute() {
  const hasTransactions = usePrivilegeStatus(
    [...pagePrivileges('INVOICE_LIST'), ...pagePrivileges('PAYMENT_LIST')],
    {
      shallowCheck: true,
    }
  );
  const hasInvoiceAccess = usePrivilegeStatus(pagePrivileges('INVOICE_LIST'), {
    shallowCheck: true,
  });
  const hasPaymentsAccess = usePrivilegeStatus(pagePrivileges('PAYMENT_LIST'), {
    shallowCheck: true,
  });

  function handleTransactionSubLink() {
    const subLinks: AppSubRoute[] = [];

    if (hasInvoiceAccess) subLinks.push({ label: 'Invoices', url: pagePath('INVOICE_LIST') });
    if (hasPaymentsAccess) subLinks.push({ label: 'Payments', url: pagePath('PAYMENT_LIST') });
    return subLinks;
  }
  const transactionSubLinks: AppSubRoute[] = useMemo(handleTransactionSubLink, [
    hasInvoiceAccess,
    hasPaymentsAccess,
  ]);
  const transactionPageList: Array<PageListItems> = [
    {
      key: 'INVOICE_LIST',
      subLinks: transactionSubLinks,
      label: 'Transactions',
      access: hasTransactions,
      icon: getSidebarFontAwesomeIconComponent(['fal', 'money-bill-transfer']),
    },
  ];
  return { transactionPageList };
}

export { useTransactionsRoute };
