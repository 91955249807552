import { HTTPError } from 'ky';
import { deleteRegions, getRegions, postRegions, updateRegions } from '../services/regions';
import { CRUD_ACTIONS } from '../store/business-units/types';
import { getAllRegionsHandler, updateRegionsHandler } from '../store/regions/regions';
import { AllRegionsResponseState, RegionItem } from '../store/regions/types';
import { AppThunk } from '../store/store';

export const getRegionsThunk = (): AppThunk<
  Promise<{ payload: AllRegionsResponseState; type: string }>
> => {
  return async (dispatch) => {
    try {
      const regions = await getRegions();
      return dispatch(getAllRegionsHandler({ status: 200, regions }));
    } catch (err) {
      const error = err as HTTPError;
      return dispatch(getAllRegionsHandler({ status: 500, message: error?.message }));
    }
  };
};

export const updateRegionThunk = (
  type: CRUD_ACTIONS,
  region: RegionItem
): AppThunk<
  Promise<{
    payload: unknown;
    type: string;
  }>
> => {
  return async (dispatch) => {
    let data = {} as RegionItem;
    try {
      switch (type) {
        case 'CREATE':
          data = await postRegions(region);
          break;
        case 'UPDATE':
          data = await updateRegions(region);
          break;
        case 'DELETE':
          await deleteRegions(region.id);
          data = region;
          break;
      }
    } catch (e) {
      console.log('Error saving regions', e);
    }

    return dispatch(
      updateRegionsHandler({
        type,
        Region: data,
      })
    );
  };
};
