import { useCurrentUser } from 'hooks/use-current-user';
import { UserRole } from 'types/entities/user';

export function useDashboardAccess() {
  const currentUser = useCurrentUser() ?? { roles: [], has_team: false };

  const hasAdminDashboardAccess =
    currentUser.roles.includes(UserRole.ADMIN) || currentUser.roles.includes(UserRole.SCOPED_ADMIN);

  const hasTeamDashboardAccess =
    currentUser.roles.includes(UserRole.COLLECTION_HEAD) ||
    currentUser.roles.includes(UserRole.SALES_MANAGER);

  const hasCollectorDashboardAccess =
    currentUser.roles.includes(UserRole.ADMIN) ||
    currentUser.roles.includes(UserRole.COLLECTION_HEAD) ||
    currentUser.roles.includes(UserRole.COLLECTION_SPECIALIST);

  const hasTeam = currentUser.has_team;

  const hasDashboardCollaboratorAccess =
    currentUser.roles.includes(UserRole.CUSTOMER_POINT_OF_CONTACT) ||
    currentUser.roles.includes(UserRole.SALES_MANAGER);

  return {
    hasAdminDashboardAccess,
    hasTeamDashboardAccess,
    hasCollectorDashboardAccess,
    hasTeam,
    hasDashboardCollaboratorAccess,
  };
}
