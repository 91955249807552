import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';

import HideWrapper from 'components/Common/Util/HideWrapper';
import { Style } from 'lib/style/style';
import { ActivitySubcriptionType } from 'types/api/inbox/activity';
import { getSubscriptionTypeInfo } from '../activityCardUtils';

interface SubscriptionTypeProps {
  subscriptionType?: ActivitySubcriptionType;
}

const Icon = (
  <FontAwesomeIcon fontSize={12} icon={['far', 'info-circle']} color="var(--geekblue-6)" />
);
const StyledWrapper = Style(Flex, {
  padding: 'var(--space-8) var(--space-12)',
  border: '1px solid var(--gray-4)',
  background: 'var(--gray-2)',
  borderRadius: 'var(--br-1)',
});

function SubscriptionType(props: SubscriptionTypeProps) {
  const { subscriptionType } = props;

  return (
    <HideWrapper hide={!subscriptionType}>
      <StyledWrapper align="center" gap="var(--space-4)">
        {Icon}
        <GrowText ellipsis>{getSubscriptionTypeInfo(subscriptionType)}</GrowText>
      </StyledWrapper>
    </HideWrapper>
  );
}

export { SubscriptionType };
