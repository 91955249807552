import { Result } from 'antd';
import { every, some } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userPrivilegesSelector } from '../../../store/authentication/authentication';
import { ERolesAndPrivileges } from './privileges';

interface Props {
  privileges: ERolesAndPrivileges[];
  children: React.ReactNode;
  fallback?: React.ReactNode;
  shallowCheck?: boolean;
  showFallBack?: boolean;
}

interface IPrivilegeOptions {
  shallowCheck?: boolean;
}

const FallBack = () => {
  return (
    <Result
      className="h-100 w-100"
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
    />
  );
};

/**
 * @description
 * Custom hook to check if the privilege(s) is valid for the account.
 */

export const usePrivilegeStatus = (
  privileges: ERolesAndPrivileges[],
  options?: IPrivilegeOptions
): boolean => {
  const priviledgesList = useSelector(userPrivilegesSelector),
    hasPrivilege = useMemo(() => {
      const predicateCallBack = (item: ERolesAndPrivileges) => priviledgesList?.includes(item);

      return options?.shallowCheck
        ? some(privileges, predicateCallBack)
        : every(privileges, predicateCallBack);
    }, [privileges, priviledgesList, options]);

  return hasPrivilege;
};

const Privileged = React.memo((props: Props) => {
  const hasPrivilege = usePrivilegeStatus(props.privileges, {
    shallowCheck: props.shallowCheck,
  });

  return (
    <>
      {hasPrivilege
        ? props.children
        : props.showFallBack || props.fallback
        ? props.fallback ?? <FallBack />
        : null}
    </>
  );
});

export { Privileged };
