import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import useOpen from 'util/hooks/useOpen';
import { EscalationFormModalProps, EscalationModalFormElementProps } from '../types';
import EscalationFormModal from './EscalationFormModal';

export default function EscalationButton<
  EscalationElements extends EscalationModalFormElementProps
>(
  props: Omit<
    EscalationFormModalProps<EscalationElements>,
    'openModal' | 'form' | 'remainder' | 'onClose' | 'onFormClose'
  >
) {
  const { buttonConfig, disabled } = props;
  const { open, toggleOpen, onClose } = useOpen({ open: false });
  const iconColor = disabled
    ? undefined
    : buttonConfig?.color
    ? buttonConfig?.color
    : 'var(--primary-7)';
  return (
    <>
      <Button
        icon={
          !buttonConfig?.hideIcon ? (
            <FontAwesomeIcon icon={['far', 'circle-exclamation']} color={iconColor} />
          ) : null
        }
        onClick={toggleOpen}
        disabled={disabled}
        {...buttonConfig}
      >
        {!buttonConfig?.hidetext && (buttonConfig?.text || 'Escalate')}
      </Button>

      <EscalationFormModal<EscalationElements> openModal={open} onClose={onClose} {...props} />
    </>
  );
}
