import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CancelButton, SaveButton } from '@sinecycle/growcomponents';
import { Button, Col, Row, Space, Spin, Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import BulkDocuments from 'components/Documents/Email/BulkDocuments';
import { CustomerSingleLevelDocument } from 'components/Documents/Email/CustomerLevelDocument';
import InvoiceLevelDocuments from 'components/Documents/Email/InvoiceLevelDocuments';
import { COMMON_EVENT } from 'events/common';
import { INVOICE_ACTIVITY_EVENTS } from 'events/invoice-activity';
import { useScrollIntoView } from 'hooks/use-scroll-into-view';
import { dispatchAppEvent } from 'lib/pub-sub';
import { useLocation } from 'lib/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { currentLoggedInUserSelector } from 'store/authentication/authentication';
import { Emailable, EmailableType } from 'types/activities/email';
import { FolderType } from 'types/entities/email-templates';
import useDisableStatus from 'util/hooks/useDisableStatus';
import Email, { ActionableEntity } from '.';
import { UploadFileTypes, saveAttachment } from '../../../../services/attachments';
import { sendEmail } from '../../../../services/email';
import { getKeyActivityData } from '../../../../services/invoice';
import {
  ELastContactActivity,
  EmailStakeHolderType,
  IActivityItem,
  IConversationParticipant,
  IEmailActivity,
} from '../../../../store/activity-feed/type';
import {
  AsyncSaves,
  addToSavingHandler,
  clearSavingHandler,
} from '../../../../store/async-fetches/fetches';
import { CtaWithConfirmation } from '../../../BaseComponents/ButtonWithConfirmation';
import { notify } from '../../../BaseComponents/Notifications';
import { IBaseExpandedActivity } from '../type';
import EmailDisplayTemplate from './email-template';
import EmailSliderStructure from './slider-structure';
import * as Styled from './style';
import { validateEmailPayload } from './validation';

// empty comment for bypassing lint error - remove later
const t = {
  raiseADispute: 'Raise a Dispute',
  send: 'Send',
  deleteConfirm: {
    discardForward: 'Discard Forward',
    discardReply: 'Discard Reply',
    message: 'Are you sure you want to discard the message?',
  },
  emailSucces: {
    message: 'Email Sent',
    description: 'Email was successfully Sent! ',
  },
  emailFailure: {
    message: 'Delivery Unsuccessful',
    description: 'The contents are saved in draft. Please try resending the Email :(',
  },
};

interface IEmailExpandedView extends IBaseExpandedActivity {
  activity: IActivityItem<IEmailActivity, IEmailActivity>;
  closeExpandedView?: () => void;
  handleChange: (data: any, type: 'child' | 'parent') => void;
  customerId: number;
}

const EmailResponseActions = {
  REPLY: {
    name: 'Reply',
    icon: ['fal', 'reply'] as IconProp,
  },
  REPLY_ALL: {
    name: 'Reply All',
    icon: ['fal', 'reply-all'] as IconProp,
  },
  FORWARD: {
    name: 'Forward',
    icon: ['fal', 'share'] as IconProp,
  },
};

enum responseAction {
  REPLY = 'REPLY',
  REPLY_ALL = 'REPLY_ALL',
  FORWARD = 'FORWARD',
}

const CloseIcon = <FontAwesomeIcon icon={['fal', 'times']} color="var(--gray-8)" />;
const EmailExpandedView = (props: IEmailExpandedView) => {
  const [currentResponseMode, setCurrentResponseMode] = useState<responseAction | undefined>();
  const [parentActivity, setParentActivity] = useState({} as IEmailActivity);
  const [childActivity, setChildActivity] = useState([] as IEmailActivity[]);
  const [to, setTo] = useState<Emailable[]>([]);
  const [cc, setCc] = useState<Emailable[]>([]);
  const [from, setFrom] = useState<Emailable>();
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState<string>();
  const { handleSubmit } = useFormContext();
  const cascadedResponse = useRef<string>();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector(currentLoggedInUserSelector);
  const { disabled } = useDisableStatus();
  const closeExpandedView = () => {
    setCurrentResponseMode(undefined);
    cascadedResponse.current = '';
  };

  const scrollRef = useScrollIntoView<HTMLDivElement>();

  const CloseButton = <Button icon={CloseIcon} onClick={props.closeExpandedView} />;
  const uploadActivity = async (fileParams: { fileList?: File[]; type?: UploadFileTypes }) => {
    let uploadedIds;

    if (fileParams.fileList && fileParams.fileList.length && fileParams.type) {
      try {
        dispatch(addToSavingHandler(AsyncSaves.UPLOAD_FILE));
        uploadedIds = await saveAttachment(fileParams.fileList, fileParams.type);
      } catch (e) {
        console.log('Error uploading file', e);
      } finally {
        dispatch(clearSavingHandler(AsyncSaves.UPLOAD_FILE));
      }
    }

    return Promise.resolve(uploadedIds?.map((fileId) => fileId.id));
  };

  const sendEmailCallBack = async (paramData: any) => {
    try {
      if (cascadedResponse.current) {
        paramData = {
          ...paramData,
          body: `<div>${paramData.body}<br/><hr/><br/><em>${cascadedResponse.current}</em></div>`,
        };
      }

      if (paramData?.fileParams?.fileList.length) {
        const fileUploadResponse = await uploadActivity({
          fileList: paramData?.fileParams?.fileList,
          type: paramData?.fileParams?.type,
        });

        if (fileUploadResponse && fileUploadResponse.length) {
          paramData = {
            ...paramData,
            file_upload_ids: fileUploadResponse,
          };
        }
      }

      delete paramData?.fileParams;
      delete paramData?.metaData;

      paramData = {
        ...paramData,
        parent_email_id: props.activity?.parent?.id,
      };

      await sendEmail(validateEmailPayload(paramData));
      dispatchAppEvent({ type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_EMAIL_SEND });

      closeExpandedView();

      notify.success(t.emailSucces.message, { description: t.emailSucces.description });

      return paramData;
    } catch (error: any) {
      notify.error(t.emailFailure.message, {
        description: t.emailFailure.description,
      });
      return Error(error);
    }
  };

  const onInvaildCallBack = (error: any) => {
    return error;
  };

  const cancelCallBack = () => {
    setCurrentResponseMode(undefined);
  };

  const resetItems = () => {
    setTo([]);
    setCc([]);
  };

  useEffect(() => {
    if (!currentResponseMode) {
      setLoading(true);
      getKeyActivityData('email_conversations', props.activity.parent.id)
        .then((data: unknown) => {
          const { replies } = data as IEmailActivity;

          setParentActivity(data as IEmailActivity);
          setChildActivity(replies);
          setLoading(false);
        })
        .catch((e) => console.log('Error fetching key activity: Email', e));
    }
  }, [props.activity.parent.id, currentResponseMode]);

  const generateRecepients = (
    conversationStakeholders: IConversationParticipant[],
    from: Emailable
  ) => {
    const to: Emailable[] = [];
    const cc: Emailable[] = [];
    conversationStakeholders?.forEach((item) => {
      if (item && item.emailable) {
        const recipient = item.emailable as Emailable;
        if (
          currentUser &&
          currentUser.email &&
          currentUser.email !== recipient.email &&
          from &&
          from.email &&
          from.email !== recipient.email
        ) {
          switch (item.type) {
            case EmailStakeHolderType.FROM:
              (currentResponseMode === responseAction.REPLY ||
                currentResponseMode === responseAction.REPLY_ALL) &&
                item.emailable &&
                to.push(recipient);
              break;
            case EmailStakeHolderType.TO:
              currentResponseMode === responseAction.REPLY_ALL &&
                item.emailable &&
                cc.push(recipient);
              break;
            case EmailStakeHolderType.CC:
              currentResponseMode === responseAction.REPLY_ALL &&
                item.emailable &&
                cc.push(recipient);
              break;
          }
        }
      }
    });
    if (
      currentResponseMode === responseAction.REPLY ||
      currentResponseMode === responseAction.REPLY_ALL
    ) {
      setTo(to);
      setCc(cc);
    }
  };

  useEffect(() => {
    if (parentActivity.conversation_participants?.length && currentResponseMode) {
      let conversationStakeHolders =
        parentActivity.conversation_participants as IConversationParticipant[];
      let child;
      cascadedResponse.current = parentActivity.body;
      if (childActivity.length) {
        child = childActivity[childActivity.length - 1];
        conversationStakeHolders = child.conversation_participants;
        cascadedResponse.current = child.body;
      }

      conversationStakeHolders?.forEach((item: IConversationParticipant) => {
        if (item.type === EmailStakeHolderType.TO) {
          const from = {
            id: currentUser?.id,
            email: currentUser?.email,
            type: EmailableType.USER,
          } as Emailable;
          setFrom(from);
          generateRecepients(conversationStakeHolders, from);
        }
      });
    } else {
      resetItems();
    }

    if (parentActivity.subject && currentResponseMode) {
      setSubject(
        `${currentResponseMode === responseAction.FORWARD ? 'Fwd' : 'Re'}: ${
          parentActivity.subject
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentActivity, currentResponseMode, childActivity]);

  const EmailFooterJSX = (
    <Space className="quick-action text-end">
      <CtaWithConfirmation
        title={
          currentResponseMode === responseAction.FORWARD
            ? t.deleteConfirm.discardForward
            : t.deleteConfirm.discardReply
        }
        message={t.deleteConfirm.message}
        onConfirm={cancelCallBack}
      >
        <CancelButton />
      </CtaWithConfirmation>
      <SaveButton
        onClick={handleSubmit(sendEmailCallBack, onInvaildCallBack)}
        disabled={disabled}
        text={t.send}
      />
    </Space>
  );

  const ResponseFooterJSX = (
    <Flex
      style={{ padding: 'var(--space-12)', margin: '0 -21px', background: ' var(--gray-2)' }}
      justify="flex-start"
    >
      <Space>
        <Styled.EmailActionButton
          onClick={() => {
            setCurrentResponseMode(responseAction.REPLY);
            dispatchAppEvent({ type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_EMAIL_REPLY });
          }}
          className="reply-button"
          icon={<FontAwesomeIcon icon={EmailResponseActions.REPLY.icon} />}
        >
          {EmailResponseActions.REPLY.name}
        </Styled.EmailActionButton>
        <Styled.EmailActionButton
          onClick={() => {
            setCurrentResponseMode(responseAction.REPLY_ALL);
            dispatchAppEvent({ type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_EMAIL_REPLY });
          }}
          className="reply-all-button"
          icon={<FontAwesomeIcon icon={EmailResponseActions.REPLY_ALL.icon} />}
        >
          {EmailResponseActions.REPLY_ALL.name}
        </Styled.EmailActionButton>
        <Styled.EmailActionButton
          onClick={() => {
            setCurrentResponseMode(responseAction.FORWARD);
            dispatchAppEvent({ type: INVOICE_ACTIVITY_EVENTS.ACTIVITY_EMAIL_FORWARD });
          }}
          className="forward-button"
          icon={<FontAwesomeIcon icon={EmailResponseActions.FORWARD.icon} />}
        >
          {EmailResponseActions.FORWARD.name}
        </Styled.EmailActionButton>
      </Space>
    </Flex>
  );

  const validFolderTypes = useMemo(() => {
    const page = location.pathname.split('/')[1];

    if (Object.keys(parentActivity).length) {
      if (page === 'customers' || page === 'customer-details') {
        if (!parentActivity.invoices.length) {
          return [FolderType.SINGLE_CUSTOMER];
        }
        if (parentActivity.invoices.length === 1) {
          return [FolderType.SINGLE_CUSTOMER, FolderType.SINGLE_INVOICE];
        }
        if (parentActivity.invoices.length > 1) {
          return [FolderType.SINGLE_CUSTOMER, FolderType.MULTIPLE_INVOICE];
        }
      }

      if (page === 'invoices' || page === 'invoice-details') {
        if (parentActivity.invoices.length === 1) {
          return [FolderType.SINGLE_CUSTOMER, FolderType.SINGLE_INVOICE];
        }
        if (parentActivity.invoices.length > 1) {
          return [FolderType.SINGLE_CUSTOMER, FolderType.MULTIPLE_INVOICE];
        }
      }
    }
  }, [location.pathname, parentActivity]);

  function getExtraFileComp() {
    if (Object.keys(parentActivity).length) {
      if (!parentActivity.invoices.length) {
        return <CustomerSingleLevelDocument customerId={String(props.customerId)} />;
      } else if (parentActivity.invoices.length === 1) {
        return <InvoiceLevelDocuments invoiceId={String(parentActivity.invoices[0].id)} />;
      } else if (parentActivity.invoices.length > 1) {
        return <BulkDocuments leveL={ActionableEntity.INVOICE} />;
      } else {
        return;
      }
    }
  }

  const extraFileComp = getExtraFileComp();

  return (
    <Styled.ExpandedViewContainer className="expanded-view-email h-100">
      <Spin
        spinning={loading}
        style={{
          height: '100%',
        }}
      >
        <EmailSliderStructure
          header={
            <div className="header p-4 border-bottom align-items-center">
              <div className="title text-16 ellipsis w-50">
                <span
                  style={{
                    color: 'var(--blue-5)',
                  }}
                  className="icon me-2"
                >
                  <FontAwesomeIcon icon={['fal', 'envelope']} />
                </span>
                <Tooltip title={parentActivity.subject} placement="bottomLeft">
                  <span className="title-text ellipsis">{`${parentActivity.subject}`}</span>
                </Tooltip>
              </div>
              <div className="secondary-actions" style={{ margin: 0 }}>
                <Flex align="center" gap="--space-8" justify="flex-end">
                  <span
                    className="border rounded btn"
                    onClick={() => {
                      props.setQuickAction &&
                        props.activity.parent.invoice &&
                        props.setQuickAction(ELastContactActivity.DISPUTE, [
                          {
                            id: props.activity.parent.invoice.id,
                            invoice_no: props.activity.parent.invoice.invoice_no,
                          },
                        ]);
                      dispatchAppEvent({
                        type: COMMON_EVENT.COLLECTION_ACTION,
                        payload: {
                          actionName: 'DISPUTE',
                        },
                      });
                    }}
                  >
                    <span
                      style={{
                        color: 'var(--blue-5)',
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'comment-times']} />
                    </span>
                    <span className="ms-2">{t.raiseADispute}</span>
                  </span>
                  {CloseButton}
                </Flex>
              </div>
            </div>
          }
          footer={false}
        >
          <div className="body">
            <Row className="g-0 child p-4">
              <Col span={24} className={``}>
                <EmailDisplayTemplate emailActivity={parentActivity} isParentCard={true} />
              </Col>
            </Row>
            {childActivity?.map((activity, key) => {
              return (
                <Row key={key} className="g-0 child p-4">
                  <Col
                    span={24}
                    className={`email-template`}
                    style={{
                      borderTop: '1px solid var(--gray-4)',
                      paddingTop: '10px',
                    }}
                  >
                    <EmailDisplayTemplate emailActivity={activity} />
                  </Col>
                </Row>
              );
            })}

            <Row className="g-0 child p-4">
              <Col span={24} className={``}>
                {!currentResponseMode && ResponseFooterJSX}
              </Col>
            </Row>

            {currentResponseMode && (
              <div style={{ paddingTop: '2rem' }} ref={scrollRef}>
                <Row>
                  <Col span={24}>
                    <div className="email-action-banner px-4 py-3 d-flex align-items-center">
                      <span className="action-icon">
                        <FontAwesomeIcon icon={EmailResponseActions[currentResponseMode].icon} />
                      </span>
                      <span className="ms-2 action-content fw-bold">{`${EmailResponseActions[currentResponseMode].name} Email`}</span>
                      <span
                        className="ms-auto cursor-pointer"
                        onClick={() => setCurrentResponseMode(undefined)}
                      >
                        <FontAwesomeIcon icon={['fal', 'times']} />
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="p-4 pb-5">
                  <Col span={24}>
                    <Email
                      customerId={props.customerId}
                      to={to as []}
                      cc={cc as []}
                      from={from}
                      subject={subject}
                      validFolders={validFolderTypes}
                      placeHolderStyles={{
                        right: 0,
                        left: 0,
                        backfaceVisibility: 'hidden',
                        minWidth: '700px',
                        overflow: 'scroll',
                      }}
                      extraFilesComp={extraFileComp}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </EmailSliderStructure>
        {currentResponseMode && (
          <Flex
            style={{ padding: 'var(--space-16) var(--space-24)', background: 'var(--gray-2)' }}
            justify="flex-end"
          >
            {EmailFooterJSX}
          </Flex>
        )}
      </Spin>
    </Styled.ExpandedViewContainer>
  );
};

export default EmailExpandedView;
