import ky from './ky';
import { CollectionStatusItem } from 'types/entities/collection-followup';

const baseUrl = 'collection-statuses';

export async function getCollectionStatuses() {
  return (await ky.get(`${baseUrl}`).json()) as CollectionStatusItem[];
}

export async function updateCollectionStatus(collectionStatusItem: CollectionStatusItem) {
  const url = `${baseUrl.concat(collectionStatusItem.id ? `/${collectionStatusItem.id}` : '')}`;

  return (await ky.post(url, { json: collectionStatusItem }).json()) as CollectionStatusItem;
}

export async function removeCollectionStatus(
  collectionStatusId: number,
  newCollectionStatusId?: number
) {
  return await ky.post(`${baseUrl}/${collectionStatusId}/delete`, {
    json: { collection_status_id: newCollectionStatusId },
  });
}
