import React from 'react';
import styled from 'styled-components';
import { Card, Skeleton } from 'antd';

type ListSkeletonProps = {
  itemsCount?: number;
  active?: boolean;
  paragraph?: {
    rows?: number;
  };
  avatar?: boolean;
  style?: React.CSSProperties;
};

const ListSkeletonCard = styled(Card)`
  border-radius: var(--br-1);
  margin-bottom: 1.5rem;

  .ant-card-body {
    padding: var(--space-20);
  }
  .ant-avatar {
    background: var(--gray-2);
    width: var(--space-20);
    height: var(--space-20);
    line-height: var(--space-20);
  }
  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0;
  }
`;

// add not-allowed with option
// add tooltip with option
export const ListSkeleton = ({ itemsCount, style, ...skeletonProps }: ListSkeletonProps) => {
  const count = itemsCount || 3;
  const skeletonList: JSX.Element[] = [];

  for (let i = 0; i < count; i++) {
    skeletonList.push(
      <ListSkeletonCard key={i} bordered={true}>
        <Skeleton {...skeletonProps} />
      </ListSkeletonCard>
    );
  }

  return <div style={style}>{skeletonList}</div>;
};
