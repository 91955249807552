import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sessionStore } from 'util/browser-storage';
import { UI_ELEMENTS } from './type';

const { getItem } = sessionStore;
const isExpand = getItem('side_nav');
const UIElementsInitialState: UI_ELEMENTS = {
  BOOLEAN_ELEMENTS: {
    SIDE_NAV: Boolean(isExpand) ?? false,
  },
};

const UIelementsSlice = createSlice({
  name: 'UIelementsStore',
  initialState: UIElementsInitialState,
  reducers: {
    updateSideNavState(state, action: PayloadAction<boolean>) {
      state.BOOLEAN_ELEMENTS.SIDE_NAV = action.payload;
    },
  },
});

export const { updateSideNavState } = UIelementsSlice.actions;

export default UIelementsSlice.reducer;
