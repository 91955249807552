import { DetailsLayout } from 'components/CollectionActivities/Common/Details/DetailsLayout';

import ActivitiesModalLoader from 'components/CollectionActivities/Common/ActivitiesModalLoader';
import useEmitActivityEvents from 'components/CollectionActivities/hooks/useEmitActivityEvents';
import { ActivityLoader } from 'components/CollectionActivities/style';
import { useCollectionActivityQuery } from 'queries/activites';
import { ActivityDetailsBaseProps } from 'types/activities/activity-types';
import { CallLog } from 'types/activities/callLog';
import { CallLogDetailsBody } from './CallLogDetailsBody';
import { CallLogDetailsInfo } from './CallLogDetailsBodyInfo';

interface CallLogDetailsProps extends ActivityDetailsBaseProps {}
function CallLogDetails(props: CallLogDetailsProps) {
  const {
    id,
    type,
    customer,
    hideActions,
    mode,
    subscriptionType,
    isListFetched,
    onDelete,
    editLoading,
    deleteLoading,
    activitiesMode,
  } = props;
  const { data, isLoading, error } = useCollectionActivityQuery<CallLog>({
    id,
    type,
    isListFetched,
  });

  const isUnauthorized = error ? error?.status === 403 : false;
  useEmitActivityEvents(isLoading);
  if (activitiesMode && isLoading) {
    return (
      <ActivityLoader>
        <ActivitiesModalLoader />
      </ActivityLoader>
    );
  }

  return (
    <DetailsLayout
      mode={mode}
      isUnauthorized={isUnauthorized}
      content={
        <CallLogDetailsBody
          data={data}
          isLoading={isLoading}
          customer={customer}
          hideActions={hideActions}
          subscriptionType={subscriptionType}
          onDelete={onDelete}
          editLoading={editLoading}
          deleteLoading={deleteLoading}
          activitiesMode={activitiesMode}
        />
      }
      info={<CallLogDetailsInfo loading={isLoading} data={data} customer={customer} />}
    />
  );
}

export { CallLogDetails };
