import { InvoiceEmailDeliveryStatus } from 'types/entities/invoice';
import { InvoiceEmailDeliveryStatusConfig } from '../OpenInvoicesStatements/type';

const invoiceDeliveryConfig: InvoiceEmailDeliveryStatusConfig = {
  [InvoiceEmailDeliveryStatus.NOT_DELIVERED]: {
    label: 'Not Delivered',
    iconColor: 'var(--red-6)',
  },
  [InvoiceEmailDeliveryStatus.IN_PROGRESS]: {
    label: 'In Progress',
    iconColor: 'var(--yellow-6)',
  },
  [InvoiceEmailDeliveryStatus.ATTEMPTED]: {
    label: 'Attempted',
    iconColor: 'var(--orange-6)',
  },
  [InvoiceEmailDeliveryStatus.NOT_APPLICABLE]: {
    label: 'Not Applicable',
    iconColor: 'var(--gray-6)',
  },
  [InvoiceEmailDeliveryStatus.DELIVERED]: {
    label: 'Delivered',
    iconColor: 'var(--green-6)',
  },
  [InvoiceEmailDeliveryStatus.SCHEDULED]: {
    label: 'Scheduled',
    iconColor: 'var(--blue-6)',
  },
};

export function getInvoiceEmailDeliveryConfig(status: InvoiceEmailDeliveryStatus) {
  return invoiceDeliveryConfig[status];
}
