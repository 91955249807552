import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { pagePath, pagePrivileges } from 'router/constants/page-info';
import { PageListItems } from './usePageList';
import { InboxSidebarIcon } from '../sidebar.utils';

function useInboxRoute() {
  const hasInbox = usePrivilegeStatus(pagePrivileges('INBOX'), {
    shallowCheck: true,
  });

  const inboxPageList: Array<PageListItems> = [
    {
      key: 'INBOX',
      subLinks: 'primitive',
      label: 'Inbox',
      access: hasInbox,
      icon: <InboxSidebarIcon />,
      url: pagePath('INBOX'),
    },
  ];
  return { inboxPageList };
}

export default useInboxRoute;
