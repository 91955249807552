import { Button, Result } from 'antd';
import { ResultStatusType } from 'antd/lib/result';
import { usePageNavigate } from 'lib/router';
import { useCallback } from 'react';
import { ErrorType } from '.';
import { APIError, ErrorBoundaryWrapperProps, ErrorHandler } from './types';

const t = {
  common: 'Something went wrong...',
  goBack: 'Go Back',
};

type FallbackRenderProps = ErrorBoundaryWrapperProps & {
  error: ErrorHandler;
};

/**
 * @param props
 * @description
 * A Fallbackcomponent which could be shown
 * on event of catching an error
 * @returns React component
 */
export const FallbackRender = (props: FallbackRenderProps) => {
  const { error } = props,
    navigate = usePageNavigate();
  let renderComponent;

  const clickCallBack = useCallback(() => {
    props.redirect?.url &&
      navigate({ TO_PROPS: { to: props.redirect.url }, navigationType: 'original' });
  }, [navigate, props.redirect?.url]);

  const ExtraDefault = props.redirect && (
    <Button type="primary" key="console" onClick={clickCallBack}>
      {props.redirect.title ?? t.goBack}
    </Button>
  );

  const APIError = ({ status, title, subTitle }: APIError) => {
    return (
      <Result
        status={status}
        title={title}
        subTitle={subTitle ?? 'Sorry, Something went wrong.'}
        extra={ExtraDefault}
      />
    );
  };

  switch (error.type) {
    case ErrorType.API_ERROR:
      if (error.serverErrorDesc) {
        const { status, title, subTitle } = error.serverErrorDesc;

        renderComponent = (
          <APIError status={status as ResultStatusType} title={title} subTitle={subTitle} />
        );
      }

      break;

    default:
      renderComponent = (
        <Result
          status="warning"
          title={
            <div>
              <p>{t.common}</p>
            </div>
          }
          extra={ExtraDefault}
        />
      );
  }

  return <>{renderComponent}</>;
};
