import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { notify } from 'components/BaseComponents/Notifications';
import { ClosedDesc } from 'components/CollectionActivities/Common/Details/ClosedDesc';
import useUpdateActivity from 'components/CollectionActivities/hooks/useUpdateActivity';
import { useUpdateKeyActivities, useUpdateTaskStatus } from 'queries/activites';
import React from 'react';
import { Task, TaskStatus } from 'types/activities/task';

const t = {
  markAsComplete: 'Mark as Complete',
  markAsOpen: 'Mark as Open',
};

interface MarkAsCompleteProps {
  status: TaskStatus;
  taskId: number;
  onChangeCallback?: (newStatus: TaskStatus) => void;
  data?: Task;
  activitiesMode?: boolean;
}

const buttonStyle: React.CSSProperties = {
  borderRadius: 'var(--br-1)',
};

const closedStyle: React.CSSProperties = {
  ...buttonStyle,
  background: 'var(--gray-1)',
};

const openStyle: React.CSSProperties = {
  ...buttonStyle,
  background: 'var(--primary-7)',
};

export function MarkAsComplete(props: MarkAsCompleteProps) {
  const { status, taskId, onChangeCallback, data, activitiesMode } = props;
  const { updateActivity } = useUpdateActivity<Task>();
  useUpdateKeyActivities();
  const { mutateAsync: updateTaskStatus, isLoading } = useUpdateTaskStatus();
  const isOpen = status === TaskStatus.OPEN;

  async function handleMarkAsCompleteClick() {
    const newStatus = status === TaskStatus.CLOSED ? TaskStatus.OPEN : TaskStatus.CLOSED;
    try {
      await updateTaskStatus({ id: taskId, status: newStatus });
      notify.success(isOpen ? 'Activity Marked as Closed' : 'Activity Marked as Open', {
        description: !activitiesMode && isOpen ? <ClosedDesc entity="task" /> : null,
      });
      const updated = isOpen ? TaskStatus.CLOSED : TaskStatus.OPEN;
      onChangeCallback?.(updated);
      if (!data) return;
      const updatedValue = { ...data, status: updated };
      updateActivity(data, updatedValue);
      onChangeCallback?.(updated);
    } catch {
      throw new Error();
    }
  }

  const ButtonIcon = isOpen ? <FontAwesomeIcon icon={['far', 'check']} /> : null;

  return (
    <Button
      icon={ButtonIcon}
      style={isOpen ? openStyle : closedStyle}
      onClick={handleMarkAsCompleteClick}
      type={'primary'}
      ghost={status === TaskStatus.CLOSED}
      loading={isLoading}
    >
      {status === TaskStatus.CLOSED ? t.markAsOpen : t.markAsComplete}
    </Button>
  );
}
