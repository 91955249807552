import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AgingBucket } from 'types/entities/aging';
import { RootState } from '../../reducers/rootReducer';

const initialState = {
  buckets: [] as AgingBucket[],
};

const integrationsSlice = createSlice({
  name: 'agingBuckets',
  initialState,
  reducers: {
    addAgingBuckets(state, action: PayloadAction<{ buckets: AgingBucket[] }>) {
      state.buckets = action.payload.buckets;
    },
  },
});

const agingBucketSliceSelector = (state: RootState) => state.agingBuckets;

export const agingBucketsSelector = createSelector(
  agingBucketSliceSelector,
  (agingBucketsSlice) => agingBucketsSlice.buckets
);

export const { addAgingBuckets } = integrationsSlice.actions;

export default integrationsSlice.reducer;
