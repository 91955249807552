import { CollectionStatusItem } from 'types/entities/collection-followup';
import { defaultErrorNotification, notify } from '../components/BaseComponents/Notifications';
import {
  getCollectionStatuses,
  removeCollectionStatus,
  updateCollectionStatus,
} from '../services/collection-status';
import {
  clearStatusAndMessageHandler,
  getAllCollectionStatusHandler,
  removeCollectionStatusHandler,
  updateCollectionStatusHandler,
} from '../store/collection-status/collection-status';
import {
  AllCollectionStatusResponseState,
  CollectionStatusResponseState,
  RemoveCollectionStatusResponseState,
} from '../store/collection-status/types';
import { AppThunk } from '../store/store';

export const getCollectionStatusesThunk = (): AppThunk<
  Promise<{ payload: AllCollectionStatusResponseState; type: string }>
> => {
  return async (dispatch) => {
    dispatch(clearStatusAndMessageHandler());
    try {
      const collectionStatuses = await getCollectionStatuses();
      return dispatch(getAllCollectionStatusHandler({ status: 200, collectionStatuses }));
    } catch (err: any) {
      return dispatch(getAllCollectionStatusHandler({ status: 500, message: err.message }));
    }
  };
};

export const updateCollectionStatusThunk = (
  collectionStatusItem: CollectionStatusItem
): AppThunk<Promise<{ payload: CollectionStatusResponseState; type: string }>> => {
  return async (dispatch) => {
    dispatch(clearStatusAndMessageHandler());
    let isNew = false;
    try {
      const collectionStatusItemClone = JSON.parse(JSON.stringify(collectionStatusItem));

      if (collectionStatusItemClone.id < 0) {
        isNew = true;
        delete collectionStatusItemClone.id;
      }

      const collectionStatus = await updateCollectionStatus(collectionStatusItemClone);
      const message = isNew ? 'New Status Saved!' : 'Changes Saved!';

      notify.success(message);

      return dispatch(updateCollectionStatusHandler({ status: 200, collectionStatus }));
    } catch (err: any) {
      defaultErrorNotification();
      return dispatch(updateCollectionStatusHandler({ status: 500, message: err.message }));
    }
  };
};

export const removeCollectionStatusThunk = (
  collectionStatusId: number,
  newCollectionStatusId?: number
): AppThunk<Promise<{ payload: RemoveCollectionStatusResponseState; type: string }>> => {
  return async (dispatch) => {
    dispatch(clearStatusAndMessageHandler());
    try {
      await removeCollectionStatus(collectionStatusId, newCollectionStatusId);
      return dispatch(removeCollectionStatusHandler({ status: 200, collectionStatusId }));
    } catch (err: any) {
      return dispatch(removeCollectionStatusHandler({ status: 500, message: err.message }));
    }
  };
};
