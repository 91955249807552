import { HTTPError } from 'ky';
import { errorMonitor } from 'lib/error-monitor';

export function logError(error: HTTPError) {
  if (error) {
    const reqUrl = new URL(error.request.url);
    error.name = `APIError - ${error.request.method} - ${reqUrl.pathname}`;

    errorMonitor.logError(error, {
      requestUrl: error.request.url,
      requestMethod: error.request.method,
      responseStatus: error.response?.status,
    });
  }
  return error;
}
