import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { OrderByRank } from 'util/customOrderby';
import { EmailStatus, getEmailStatus } from './EmailStatus';
import { RecipientPopoverContent } from './RecipientPopoverContent';
import { emailDeliveryStatusRank } from './predicate';
import { StyledPopOver } from './style';
import { EmailRecepientProps } from './type';

const t = {
  title: 'Delivery Status ',
  to: 'To',
  cc: 'CC',
  bcc: 'BCC',
};

const overlayStyles: React.CSSProperties = {
  width: '620px',
};

const innerStyle: React.CSSProperties = {
  padding: 'var(--space-16)',
  borderRadius: 'var(--br-1)',
  border: '1px solid var(--gray-4)',
  maxHeight: '400px',
  overflow: 'auto',
};

function EmailRecepientStatus(props: EmailRecepientProps) {
  const { to, cc, bcc } = props;

  const Content = (
    <Flex direction="column" gap="var(--space-12)">
      <GrowText strong>{t.title}</GrowText>
      <RecipientPopoverContent recipients={to} label={t.to} />
      <RecipientPopoverContent recipients={cc} label={t.cc} />
      <RecipientPopoverContent recipients={bcc} label={t.bcc} />
    </Flex>
  );
  function getRecentRecipients() {
    if (!to) return;
    const recentTo = to.map((recipient) => {
      return {
        component: <EmailStatus recipient={recipient} hideDate hideEmail />,
        status: getEmailStatus(recipient),
      };
    });
    return OrderByRank(recentTo, emailDeliveryStatusRank, 'status');
  }

  const recentRecipient = useMemo(getRecentRecipients, [to]);

  function getTotalRecipients() {
    return sum([to ? to.length : 0, cc.length, bcc.length]);
  }
  const totalRecipients = getTotalRecipients();

  if (!to) return null;
  return (
    <StyledPopOver
      content={totalRecipients > 1 ? Content : undefined}
      placement="bottomRight"
      arrow={false}
      overlayStyle={overlayStyles}
      overlayInnerStyle={innerStyle}
      overlayClassName="ant-popover-body-no-padding"
      style={{ boxShadow: 'var(--shadow-2)' }}
    >
      <span className="recentRecipient">
        {!to ? null : recentRecipient?.length ? (
          <Flex gap="var(--space-2)">
            {recentRecipient[0].component}
            {totalRecipients > 1 ? (
              <GrowText color="var(--gray-7)" style={{ fontWeight: 'var(--fs-regular)' }}>
                {`+${totalRecipients}`}
              </GrowText>
            ) : null}
          </Flex>
        ) : null}
      </span>
    </StyledPopOver>
  );
}

export { EmailRecepientStatus };
