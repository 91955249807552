import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
// Draft-js Imports
import { CancelButton, SaveButton } from '@sinecycle/growcomponents';
import { IProps } from 'components/BaseComponents/ASelect';
import { GrowText } from 'components/BaseComponents/Typography';
import { ContentState } from 'draft-js';
import { usePostCommentsMutation } from 'queries/invoices';
import React, { useEffect, useRef, useState } from 'react';
import { SearchUsersInterface } from 'store/aging/types';
import styled from 'styled-components';
import { IComment } from '../../../../store/activity-feed/type';
import CollapseComponent from '../../../BaseComponents/Collapse';
import { notify } from '../../../BaseComponents/Notifications';
import RichComments from '../../../BaseComponents/RichComment';
import UsersSearchComponent from '../AssignedToComponent';

const t = {
  comment: 'Comment',
  markAsPrivate: 'Mark as Private',
  addMembers: 'Add Members',
  typeToStartAdding: 'Type to start adding members...',
  postedComment: 'Comment Posted',
  postCommentDescription: 'Collaborators in comments can also view the whole thread',
  errorPostingComment: 'Comment Unsuccessful',
  tryAgain: 'Please Try Again',
  add: 'Add',
  addingText: 'Adding',
  via: 'via',
};

export interface IParamsForPostComments {
  comments: string;
  customer_id?: number;
  reference_ids: number[];
  reference_type: string;
  private: boolean;
  tagged_user_ids: number[] | undefined;
  attached_files?: string[];
  comments_content?: ContentState;
}

interface ICommentComponent {
  referenceIds: number[];
  referenceType: string;
  customerId?: number;
  updateOnSuccess: (comment: IComment) => void;
  cancelCallBack?: () => void;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

const StyledComment = styled(Flex)`
  background: var(--gray-2);
  max-width: ${(props) => props.style?.maxWidth};
  padding: var(--space-20) var(--space-24);

  .ant-collapse-item {
    background: white;
    border-bottom: none;
  }

  .ant-collapse-header {
    display: flex;
    padding-right: 10px !important;
    align-items: center;
  }

  &.comment-container {
    background: var(--gray-1);
  }

  .sub-title {
    font-size: var(--fs-12);
  }

  .clickable-item {
    color: var(--blue-5);
  }
`;

const InfoIcon = <FontAwesomeIcon icon={['far', 'info-circle']} color="var(--geekblue-6)" />;
// const CommentIcon = <FontAwesomeIcon icon={['far','comment-alt-lines']} />;

const CommentComponent = (props: ICommentComponent) => {
  const [toggleChecked, setToggleChecked] = useState(false);
  const [privateList, setPrivateList] = useState([] as number[]);
  const [commentDescription, setCommentDescription] = useState('');

  const [commentContentState, setCommentContentState] = useState<ContentState | undefined>();
  const paramsForPostComment = useRef({} as IParamsForPostComments);
  const { mutateAsync: postCommentsMutation, isLoading } = usePostCommentsMutation();

  useEffect(() => {
    return () => {
      setToDefault();
    };
  }, []);

  useEffect(() => {
    paramsForPostComment.current = {
      comments: commentDescription,
      comments_content: commentContentState,
      customer_id: props.customerId,
      reference_ids: props.referenceIds,
      reference_type: props.referenceType,
      private: toggleChecked,
      tagged_user_ids: privateList,
    };
  });

  const setToDefault = () => {
    setToggleChecked(false);
    setPrivateList([]);
    setCommentDescription('');
    setCommentContentState(ContentState.createFromText(''));
  };

  function postComments(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    postCommentsMutation({ params: paramsForPostComment.current, key: 'comments' })
      .then((comment) => {
        props.updateOnSuccess(comment as IComment);
        notify.success(t.postedComment, { description: t.postCommentDescription });
        return Promise.resolve(setToDefault());
      })
      .catch(() => {
        notify.error(t.errorPostingComment, { description: t.tryAgain });
      });
  }

  function onCancel(event: React.MouseEvent) {
    const { cancelCallBack } = props;

    event.preventDefault();
    event.stopPropagation();

    setToDefault();
    cancelCallBack && cancelCallBack();
  }

  const Cancel = <CancelButton onClick={onCancel} />;
  const AddButton = (
    <SaveButton
      type="primary"
      onClick={postComments}
      text={t.add}
      loading={isLoading}
      savingText={t.addingText}
    />
  );

  const ActionArea = (
    <Flex
      justify="flex-end"
      style={{
        padding: 'var(--space-20) var(--space-24)',
        width: '100%',
        background: 'var(--gray-2)',
      }}
    >
      {Cancel}
      {AddButton}
    </Flex>
  );

  const Extra = (
    <CollapseComponent
      bordered={false}
      className="private-notes-search"
      expandIcon={({ isActive }) => {
        setToggleChecked(isActive as boolean);
        return <div />;
      }}
    >
      <div>
        <div className="sub-title">{t.addMembers}</div>
        <UsersSearchComponent
          isMulti={true}
          placeholder={t.typeToStartAdding}
          selectedValues={privateList}
          handleChange={(List: IProps<SearchUsersInterface>[]) => {
            setPrivateList([...List.map((item) => item.value)]);
          }}
        />
      </div>
    </CollapseComponent>
  );

  const Info = (
    <Flex align="center" justify="flex-end" gap="--space-4">
      {InfoIcon}
      <GrowText color="var(--gray-8)">Use @ to mention other collaborators</GrowText>
    </Flex>
  );
  const CommentArea = (
    <RichComments
      mode="write"
      contentState={commentContentState}
      onChange={setCommentContentState}
    />
  );
  const Title = (
    <Texto size="16" color="--gray-9" weight="semibold">
      {t.comment}
    </Texto>
  );
  const Header = (
    <StyledComment direction="column" gap="--space-8" style={props.style}>
      {Title}
      {CommentArea}
      {Info}
      {toggleChecked && Extra}
    </StyledComment>
  );
  return (
    <div style={{ ...props.wrapperStyle }}>
      {Header}
      {ActionArea}
    </div>
  );
};

export default CommentComponent;
