import { getKeyActivityConfig } from 'components/HigherOrderComponent/KeyActivitesContainer/key-acivities-config';
import { INBOX_ASSIGNED_EVENT } from 'events/inbox';
import { dispatchAppEvent } from 'lib/pub-sub';
import { ELastContactActivity } from 'store/activity-feed/type';
import { ActivityType } from 'types/activities/activity-types';

export function getActivityUnitItems(activity: ActivityType) {
  const activityConfig = getKeyActivityConfig(activity as unknown as ELastContactActivity);
  const { Icon, cardLabel, color, expandedLabel, iconBg, label } = activityConfig ?? {};

  return { Icon, cardLabel, color, expandedLabel, iconBg, label };
}

export function dispatchAddCommentEvent(context: string) {
  dispatchAppEvent({
    type: INBOX_ASSIGNED_EVENT.ADD_COMMENT,
    payload: {
      context: context,
    },
  });
}
