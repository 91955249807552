import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers/rootReducer';
import {
  AllCollectionStatusResponseState,
  CollectionStatusResponseState,
  RemoveCollectionStatusResponseState,
} from './types';

export const initialState: AllCollectionStatusResponseState = {
  status: 0,
};

const receivableDisplaySlice = createSlice({
  name: 'collectionStatus',
  initialState,
  reducers: {
    getAllCollectionStatusHandler(state, action: PayloadAction<AllCollectionStatusResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.collectionStatuses = action.payload.collectionStatuses;
    },
    updateCollectionStatusHandler(state, action: PayloadAction<CollectionStatusResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (
        action.payload.status >= 200 &&
        action.payload.status < 300 &&
        action.payload.collectionStatus?.id
      ) {
        state.collectionStatuses = state.collectionStatuses ?? [];
        const updatedIndex = state.collectionStatuses.findIndex(
          (cs) => cs.id === action.payload.collectionStatus?.id
        );
        if (updatedIndex >= 0)
          state.collectionStatuses[updatedIndex] = action.payload.collectionStatus;
        else state.collectionStatuses.push(action.payload.collectionStatus);
      }
    },
    removeCollectionStatusHandler(
      state,
      action: PayloadAction<RemoveCollectionStatusResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.collectionStatuses = state.collectionStatuses?.filter((collectionStatus) => {
        return collectionStatus.id !== action.payload.collectionStatusId;
      });
    },
    clearStatusAndMessageHandler(state) {
      state.status = 0;
      state.message = '';
    },
  },
});

export const collectionStatusSelector = (state: RootState) => state.collectionStatus;

export const {
  getAllCollectionStatusHandler,
  updateCollectionStatusHandler,
  removeCollectionStatusHandler,
  clearStatusAndMessageHandler,
} = receivableDisplaySlice.actions;

export default receivableDisplaySlice.reducer;
