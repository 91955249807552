import { createSlice } from '@reduxjs/toolkit';
import {
  initializeCollectionPeriods,
  resetCollectionPeriods,
  resetCurrentPage,
  updateCurrentFilter,
  updateCurrentPage,
} from './reducer';
import { collectorPerformanceProps } from './type';

export const initialState: collectorPerformanceProps = {
  collectionPeriods: [],
  page: {
    size: 10,
    page: 1,
  },
};

const collectorsPerformanceSlice = createSlice({
  name: 'collectorsPerformance',
  initialState,
  reducers: {
    initializeCollectionPeriods,
    updateCurrentFilter,
    resetCollectionPeriods,
    updateCurrentPage,
    resetCurrentPage,
  },
});
export const CollectorsPerformanceActions = collectorsPerformanceSlice.actions;

export default collectorsPerformanceSlice.reducer;
