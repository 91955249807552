import { Switch } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { useEffect } from 'react';
import styled from 'styled-components';

interface Props {
  value?: boolean;
  name?: string;
  label?: string;
  showPrimitive?: boolean;
  onChange?: (checked: boolean) => void;
}

const ToggleSwitch = styled(Switch)`
  border-radius: var(--br-1);
  padding: var(--space-16);
  display: flex;
  align-items: center;

  .ant-switch-handle {
    display: none;
  }
  .ant-switch-inner {
    margin: 0;
  }
`;

export function ToggleFilter(props: Props) {
  const { value, onChange, label, showPrimitive } = props;

  useEffect(() => {
    if (showPrimitive) {
      onChange && onChange(true);
    }
  }, [onChange, showPrimitive]);

  const DisplayOnlyLabel = (
    <Flex
      align="center"
      justify="space-between"
      gap="--space-4"
      style={{ width: '100%', color: 'var(--primary-7)', fontWeight: 'var(--fs-semibold)' }}
    >
      {label}
    </Flex>
  );

  if (showPrimitive) {
    return DisplayOnlyLabel;
  }

  return (
    <ToggleSwitch
      defaultChecked
      checked={value}
      onChange={onChange}
      checkedChildren={label}
      unCheckedChildren={label}
    />
  );
}
