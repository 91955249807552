import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowModal } from '@sinecycle/growcomponents';
import { Input, Spin } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import styled from 'styled-components';
import ResultList from './EntityResultList';
import { useSpotlight } from './useSpotlight';

export default function SpotLightSearch() {
  const { closeSpotlight, searchText, setSearchtext, isOpen, inputRef, results, isLoading } =
    useSpotlight();

  return (
    <TransparentAntModal
      open={isOpen}
      title={null}
      footer={null}
      closeIcon={null}
      type="activities"
      destroyOnClose
      closable={false}
      styles={{
        body: {
          padding: '0px',
        },
      }}
      onCancel={closeSpotlight}
      afterClose={closeSpotlight}
    >
      <StyleContainer>
        <Flex
          style={{
            paddingRight: 'var(--space-8)',
            paddingLeft: 'var(--space-16)',
            borderBottom: '1px solid var(--gray-5)',
          }}
          align="center"
          justify="space-around"
        >
          {isLoading ? (
            <Spin />
          ) : (
            <FontAwesomeIcon
              style={{ fontSize: 'var(--fs-24)', color: 'var(--purple-6)' }}
              icon={['fas', 'search']}
            />
          )}

          <Input
            ref={inputRef}
            value={searchText}
            size="large"
            placeholder="search customers, invoices & pages"
            style={{ fontSize: 'var(--fs-24)', border: '0px', textTransform: 'capitalize' }}
            onChange={({ target: { value } }) => setSearchtext(value)}
          />
        </Flex>
        {searchText && <ResultList closeSpotlight={closeSpotlight} results={results} />}
      </StyleContainer>
    </TransparentAntModal>
  );
}

const TransparentAntModal = styled(GrowModal)`
  .ant-modal-content {
    background: transparent;
    padding: 0;
  }
`;

const StyleContainer = styled.div`
  input:focus {
    box-shadow: none;
  }
  background: white;
  border-radius: var(--space-8);
  border: 2px solid var(--gray-5);

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
