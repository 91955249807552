import { DetailsLayout } from 'components/CollectionActivities/Common/Details/DetailsLayout';

import ActivitiesModalLoader from 'components/CollectionActivities/Common/ActivitiesModalLoader';
import useEmitActivityEvents from 'components/CollectionActivities/hooks/useEmitActivityEvents';
import { ActivityLoader } from 'components/CollectionActivities/style';
import { useCollectionActivityQuery } from 'queries/activites';
import { ActivityDetailsBaseProps } from 'types/activities/activity-types';
import { Payment } from 'types/activities/payment';
import { PaymentDetailsBody } from './PaymentDetailsBody';
import { PaymentDetailsInfo } from './PaymentDetailsInfo';

interface PaymentDetailsProps extends ActivityDetailsBaseProps {}
function PaymentDetails(props: PaymentDetailsProps) {
  const { id, type, customer, mode, subscriptionType, isListFetched, activitiesMode } = props;
  const { data, isLoading, error } = useCollectionActivityQuery<Payment>({
    id,
    type,
    isListFetched,
  });
  const isUnauthorized = error ? error?.status === 403 : false;
  useEmitActivityEvents(isLoading);
  if (activitiesMode && isLoading) {
    return (
      <ActivityLoader>
        <ActivitiesModalLoader />
      </ActivityLoader>
    );
  }

  return (
    <DetailsLayout
      mode={mode}
      isUnauthorized={isUnauthorized}
      content={
        <PaymentDetailsBody
          data={data}
          isLoading={isLoading}
          customer={customer}
          subscriptionType={subscriptionType}
          activitiesMode={activitiesMode}
        />
      }
      info={<PaymentDetailsInfo loading={isLoading} data={data} customer={customer} />}
    />
  );
}

export { PaymentDetails };
