import { useEffect } from 'react';

interface imageResizeProps {
  imgRef: React.RefObject<HTMLImageElement>;
  resizeRef: React.RefObject<HTMLElement>;
  onMouseUpCallback?: (width: number, height: number) => void;
}

const delta = 11;

export function useImageResize({ imgRef, resizeRef, onMouseUpCallback }: imageResizeProps) {
  const imageContainer = imgRef.current;
  const resize = resizeRef.current;
  const rteContainer = document.getElementById('rich-content-editor');

  useEffect(() => {
    function mouseMoveCallback(e: MouseEvent) {
      if (imageContainer && resize && rteContainer) {
        const clientRectImage = imageContainer.getBoundingClientRect();
        const computedWidth = clientRectImage.width + (e.clientX - clientRectImage.right - delta);

        if (computedWidth > 80) {
          resize.style.background = 'var(--purple-6)';
          rteContainer.style.cursor = 'col-resize';
          imageContainer.style.width = `${computedWidth}px`;
        }
      }
    }

    function mouseUpCallback(e: MouseEvent) {
      if (imageContainer && resize && rteContainer) {
        const clientRectImage = imageContainer.getBoundingClientRect();
        resize.style.background = 'var(--gray-6)';
        rteContainer.style.cursor = 'unset';
        onMouseUpCallback && onMouseUpCallback(clientRectImage.width, clientRectImage.height);
        document.removeEventListener('mousemove', mouseMoveCallback);
      }
    }

    function mouseDownCallback(e: MouseEvent) {
      document.addEventListener('mousemove', mouseMoveCallback);
      document.addEventListener('mouseup', mouseUpCallback);
    }

    resize?.addEventListener('mousedown', mouseDownCallback);

    return () => {
      document.removeEventListener('mouseup', mouseUpCallback);
      resize?.removeEventListener('mousedown', mouseDownCallback);
    };
  }, [imageContainer, onMouseUpCallback, resize, rteContainer]);
}
