import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AllCustomerTerritoriesResponseState,
  CustomerTerritoryResponseState,
  CustomerTerritoryUpdateResponseState,
} from './types';

export const initialState: AllCustomerTerritoriesResponseState = {
  status: 0,
  message: '',
  customerTerritories: [],
};

const customerTerritoriesSlice = createSlice({
  name: 'customerTerritories',
  initialState,
  reducers: {
    getAllCustomerTerritoriesHandler(
      state,
      action: PayloadAction<AllCustomerTerritoriesResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.customerTerritories = action.payload.customerTerritories ?? [];
      state.unassignedCustomers = action.payload.unassignedCustomers;
    },
    removeCustomerTerritoryHandler(state, action: PayloadAction<CustomerTerritoryResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (action.payload.status >= 200 && action.payload.status < 300 && action.payload.id)
        state.customerTerritories = (state.customerTerritories ?? []).filter(
          (customerTerritory) => customerTerritory.id !== action.payload.id
        );
    },
    updateCustomerTerritoriesHandler(
      state,
      action: PayloadAction<CustomerTerritoryUpdateResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (action.payload.status >= 200 && action.payload.status < 300 && action.payload.data?.id) {
        state.customerTerritories = state.customerTerritories?.map((customerTerritory) => {
          if (customerTerritory.id === action.payload.data?.id) return action.payload.data;
          return customerTerritory;
        });
      } else if (
        action.payload.status >= 200 &&
        action.payload.status < 300 &&
        action.payload.data
      ) {
        state.customerTerritories = state.customerTerritories || [];
        state.customerTerritories = state.customerTerritories.filter(
          (customerTerritory) => customerTerritory.id !== -1
        );
        state.customerTerritories.push(Object.assign({}, action.payload.data, { id: -1 }));
      }
    },
    clearStatusAndMessageHandler(state) {
      state.status = 0;
      state.message = '';
    },
  },
});

export const {
  getAllCustomerTerritoriesHandler,
  removeCustomerTerritoryHandler,
  clearStatusAndMessageHandler,
  updateCustomerTerritoriesHandler,
} = customerTerritoriesSlice.actions;

export default customerTerritoriesSlice.reducer;
