import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { useIntegrationDashboards } from 'hooks/use-report-query';
import { PageLink } from 'lib/router';
import { ReportCardLoader } from './Loader';
import { ReportCard } from './ReportCard';

const t = {
  advanceReports: 'Advanced Reports',
};

export function AdvancedReports(props: unknown) {
  const { dashboardList, isLoading } = useIntegrationDashboards();
  const Loader = <ReportCardLoader />;

  const Title = (
    <div
      className="advance-reports"
      style={{
        background: 'var(--gray-2)',
        padding: 'var(--space-16) var(--space-24)',
        boxShadow: 'var(--shadow-2)',
      }}
    >
      <Texto size="20" weight="semibold">
        {t.advanceReports}
      </Texto>
    </div>
  );

  const ReportCards = (
    <>
      {dashboardList?.map((dashboard) => {
        return (
          <PageLink
            key={dashboard.dashboard_id}
            pathParams={{ id: dashboard.dashboard_id }}
            appPage="ADVANCED_REPORT"
          >
            <ReportCard dashboard={dashboard} />
          </PageLink>
        );
      })}
    </>
  );

  return (
    <div className="canvas-container">
      {Title}
      <Flex style={{ padding: '40px 180px' }} wrap="wrap" gap="var(--space-24)">
        {isLoading ? Loader : ReportCards}
      </Flex>
    </div>
  );
}
