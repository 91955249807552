import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm, Tooltip } from 'antd';
import { PopConfirmContent } from 'components/BaseComponents/ButtonWithConfirmation/PopconfirmContent';
import { notify } from 'components/BaseComponents/Notifications';
import { deleteSavedFilterViews } from 'services/filter-view';
import { t } from '../constants';
import { StyledIconButton } from '../style';

interface FilterViewDeleteProps {
  hide?: boolean;
  viewId: string | undefined;
  entity: string | undefined;
  resolveCallBack?: () => void;
  settleCallBack?: () => void;
  errorCallBack?: () => void;
}

function FilterViewDelete(props: Readonly<FilterViewDeleteProps>) {
  if (props.hide) return null;

  const DeleteIcon = <FontAwesomeIcon icon={['far', 'trash-alt']} color="var(--primary-7)" />;
  const PopConfirmTitle = (
    <PopConfirmContent
      title={t.warning_title}
      desc={t.warning_desc}
      titleStyles={{ color: 'var(--gold-6)', fontWeight: 'var(--fs-semibold)' }}
      descStyles={{ color: 'var(--gray-9)', width: '250px' }}
    />
  );

  function handleError(e: any) {
    console.log(e);
    notify.error(t.err_message.cant_delete);
  }

  const errorCallBack = props.errorCallBack ? props.errorCallBack : handleError;

  function handleFilterViewDelete() {
    if (!props.viewId) return;
    deleteSavedFilterViews(props.viewId, props.entity ? props.entity : '')
      .then(props.resolveCallBack)
      .then(props.settleCallBack)
      .catch(errorCallBack);
  }

  return (
    <Tooltip title={t.delete_filter_view} overlayStyle={{ padding: 0 }} placement="topRight">
      <Popconfirm
        icon={false}
        title={PopConfirmTitle}
        placement="bottomLeft"
        overlayClassName="ant-popover-title-no-padding "
        onConfirm={handleFilterViewDelete}
      >
        <StyledIconButton icon={DeleteIcon} size="small" />
      </Popconfirm>
    </Tooltip>
  );
}

export default FilterViewDelete;
