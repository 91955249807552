import ky from './ky';

const baseURL = 'attachments/image';

export async function uploadContentImage(attachment: File): Promise<{ hash: string }> {
  const formdata = new FormData();

  formdata.append('file', attachment, attachment.name);

  return await ky.post(`${baseURL}/upload`, { body: formdata }).json();
}

export async function deleteContentImage(hash: string): Promise<void> {
  return await ky.delete(`${baseURL}/${hash}`).json();
}
