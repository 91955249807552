import {
  CustomFitlerGroup,
  Filter,
  FilterGroup,
  StandardFilterGroup,
} from 'components/Common/FilterComponents/types';
import produce from 'immer';
import { intersection } from 'lodash';
import { replaceInArrayInPlace, replaceOrPushInArray } from 'util/array';

type FilterReducerAction =
  | { type: 'UPDATE_INITIAL_GROUP'; payload: IntialGroupUpdate }
  | { type: 'ADD_GROUP'; payload: { filterGroup: FilterGroup } }
  | { type: 'UPDATE_GROUP'; payload: FilterGroup }
  | { type: 'ADD_FILTER'; payload: AddFilterPayload }
  | { type: 'UPDATE_FILTER'; payload: UpdateFilterPayload };

type UpdateFilterPayload = {
  groupId: string;
  filterId: string;
  updatedValues: Partial<Filter>;
};

type AddFilterPayload = {
  groupId: string;
  filter: Filter;
};

interface IntialGroupUpdate {
  standardFieldKeys: string[];
  reportingTagKeys?: string[];
  customFieldKeys?: string[];
  customerContactKeys?: string[];
}

function computeActiveFilters(filterList: Filter<any>[], keys: string[]) {
  return filterList.map((filter) => {
    if (intersection(filter.filterValueKeys, keys).length) {
      filter.active = true;
    } else {
      filter.active = false;
    }

    return filter;
  });
}

export function filterReducer(filterGroups: FilterGroup[], action: FilterReducerAction) {
  function updateInitialGroup(keys: IntialGroupUpdate) {
    return produce(filterGroups, (draft) => {
      draft.forEach((filterGroup) => {
        if (
          filterGroup.id === StandardFilterGroup.CUSTOMER ||
          filterGroup.id === StandardFilterGroup.INVOICE ||
          filterGroup.id === StandardFilterGroup.PAYMENT
        ) {
          const filterKeysList = [...keys.standardFieldKeys];

          if (keys.customFieldKeys && keys.customFieldKeys.length) {
            filterKeysList.push(...keys.customFieldKeys);
          }

          if (keys.customerContactKeys && keys.customerContactKeys.length) {
            filterKeysList.push(...keys.customerContactKeys);
          }

          filterGroup.filterList = computeActiveFilters(filterGroup.filterList, filterKeysList);
        }

        if (filterGroup.id === CustomFitlerGroup.REPORTING_TAGS) {
          filterGroup.filterList = computeActiveFilters(
            filterGroup.filterList,
            keys.reportingTagKeys ?? []
          );
        }
      });
    });
  }

  function addGroup(filterGroup: FilterGroup) {
    const predicate = (filterGroupInArray: FilterGroup) => filterGroupInArray.id === filterGroup.id;

    return produce(filterGroups, (draft) => {
      return replaceOrPushInArray(draft, filterGroup, predicate);
    });
  }

  function updateGroup(filterGroup: FilterGroup) {
    const predicate = (filterGroupInArray: FilterGroup) => filterGroupInArray.id === filterGroup.id;
    const filterGroupForUpdate = filterGroups.find((group) => group.id === filterGroup.id);

    const newFilterGroup = { ...filterGroup };

    if (
      filterGroupForUpdate &&
      filterGroupForUpdate.filterList &&
      Array.isArray(filterGroupForUpdate.filterList)
    ) {
      newFilterGroup.filterList = [...filterGroupForUpdate.filterList, ...filterGroup.filterList];
    }

    return produce(filterGroups, (draft) => {
      return replaceOrPushInArray(draft, newFilterGroup, predicate);
    });
  }

  function addFilter({ groupId, filter }: AddFilterPayload) {
    return produce(filterGroups, (draft) => {
      const filterGroup = draft.find((filterGroup) => filterGroup.id === groupId);

      if (filterGroup) filterGroup.filterList.push(filter);
    });
  }

  function updateFilter({ groupId, filterId, updatedValues }: UpdateFilterPayload) {
    return produce(filterGroups, (draft) => {
      const filterGroup = draft.find((filterGroup) => filterGroup.id === groupId);

      if (!filterGroup?.filterList) return;

      const filterToUpdate = filterGroup.filterList.find((filter) => filter.id === filterId);

      if (!filterToUpdate) return;

      const replacePredicate = (filter: Filter) => filter.id === filterId;
      const updatedFilter: Filter = { ...filterToUpdate, ...updatedValues };

      replaceInArrayInPlace(filterGroup.filterList, updatedFilter, replacePredicate);
    });
  }

  switch (action.type) {
    case 'UPDATE_INITIAL_GROUP':
      return updateInitialGroup(action.payload);
    case 'ADD_GROUP':
      return addGroup(action.payload.filterGroup);
    case 'UPDATE_GROUP':
      return updateGroup(action.payload);
    case 'ADD_FILTER':
      return addFilter(action.payload);
    case 'UPDATE_FILTER':
      return updateFilter(action.payload);
  }
}
