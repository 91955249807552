import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { IProps } from 'components/BaseComponents/ASelect';
import { notify } from 'components/BaseComponents/Notifications';
import { ClosedDesc } from 'components/CollectionActivities/Common/Details/ClosedDesc';
import { StyledPTPStatus } from 'components/CollectionActivities/style';
import { useUpdatePTPStatus } from 'queries/activites';
import { useEffect, useState } from 'react';
import { PTPStatusMenu, PromiseToPayStatus } from 'types/activities/promise-to-pay';

const t = {
  successMessage: 'PTP Status Updated',
  error: {
    title: 'Status not updated',
    description: 'Please try performing the status update again',
  },
};

interface PTPStatusProps {
  status: PromiseToPayStatus;
  ptpActivityId: number;
  onChangeCallBack?: (status: PromiseToPayStatus) => void;
  size?: SizeType;
  activitiesMode?: boolean;
}

export function PTPStatusQuickSelect(props: PTPStatusProps) {
  const [ptpStatus, setPTPStatus] = useState<PromiseToPayStatus | undefined>();
  const { mutateAsync: updatePTPStatus } = useUpdatePTPStatus();
  const {
    status,
    ptpActivityId: disputeActivityId,
    onChangeCallBack,
    size,
    activitiesMode,
  } = props;

  useEffect(() => {
    setPTPStatus(status);
  }, [status]);

  function handleChange(status: IProps<PromiseToPayStatus>) {
    updatePTPStatus({ params: { status: status.value }, id: disputeActivityId }).then(() => {
      notify.success(status.value === 'CLOSED' ? 'Activity Marked as Closed' : t.successMessage, {
        description:
          !activitiesMode && status.value === 'CLOSED' ? <ClosedDesc entity="PTP" /> : null,
      });
      setPTPStatus(status.value);
      onChangeCallBack?.(status.value);
    });
  }
  return (
    <StyledPTPStatus
      status={ptpStatus}
      selectedValues={ptpStatus}
      optionsList={PTPStatusMenu}
      onChangeCallBack={handleChange}
      size={size}
      dropdownStyle={{ minWidth: 'fit-content' }}
    />
  );
}
