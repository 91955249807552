import { GrowCheckBoxGroup } from '@sinecycle/growcomponents';
import { Button, Checkbox, Form, Pagination } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import Modal from 'components/BaseComponents/Modal/Antd';
import styled from 'styled-components';

export const StyledCheckBox = styled(Checkbox)`
  .ant-checkbox .ant-checkbox-inner {
    border: 1px solid var(--gray-5);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: var(--gray-1);
    border: 1px solid var(--purple-7);
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 1.5px solid var(--primary-7);
    border-top: 0;
    border-left: 0;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background: var(--gray-3);
    border: 1px solid var(--gray-5);
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: var(--gray-6);
  }
`;
export const StyledCheckBoxGroup = styled(GrowCheckBoxGroup)`
  &.flex {
    display: flex;
    flex-direction: column;
    gap: var(--space-12);

    .ant-checkbox-wrapper {
      align-items: center;
    }
  }
`;

export const StyledIconButton = styled(Button)`
  border: 0;
  box-shadow: none;
  background-color: transparent;
  width: ${(props) => props.style?.width}!important;
  height: ${(props) => props.style?.height}!important;
  display: ${(props) => props.style?.display}!important;

  &.ant-btn:hover,
  .ant-btn:focus {
    background: transparent;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin: 0;
  line-height: 1.2;
  &.ant-form-item-label {
    line-height: 1.2;
  }

  &.ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    right: -1px;
  }
  ${(props) => `
    &.${props.className}:not(.sp-1):not(.label-space):not(.bulk-strategy-select):not(.label-with-no-padd){
      .ant-form-item-explain-error{
       font-size:var(--fs-12);
       width:100px;
      }
   

    }
  `}

  &.no-height {
    .ant-form-item-control-input {
      min-height: auto;
    }
    .ant-form-item-explain-error {
      width: 100% !important;
    }
  }
  &.sp-1 {
    margin-bottom: var(--space-24);
  }

  &.label-space {
    .ant-form-item-row {
      gap: var(--space-8);
    }
    .ant-form-item-explain-error {
      width: min-content;
    }
  }
  &.bulk-strategy-select {
    .ant-form-item-explain {
      margin-left: var(--space-24);
    }
  }
  &.label-with-no-padd {
    width: 100%;
    .ant-form-item-label {
      padding: var(--space-0);
    }
  }
  .top-align {
    .ant-form-item-control-input {
      align-items: flex-start;
    }
  }
`;

export const StyledPreviewItem = styled(Flex)`
  padding: var(--space-8) var(--space-12);
  background-color: var(--geekblue-1);
  border: 1px solid var(--geekblue-1);
`;

export const StyledPagination = styled(Pagination)`
  padding: var(--space-12);
  background: var(--gray-1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  left: 24px;
  right: 24px;
  bottom: -10px;
  border-top: 1px solid var(--gray-5);
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: var(--br-3);
    overflow: hidden;
  }
  .ant-modal-header {
    background-color: var(--gray-2);
    min-height: 54px;
    border-bottom: 0;
  }
  .ant-modal-footer {
    background-color: var(--gray-2);
    min-height: 54px;
    border-top: 0;
    .comment-cancel {
      border: 0;
      box-shadow: none;
      background: none;
    }
  }
`;

export const DetailsPageContainer = styled.div`
  overflow: auto;
`;

interface StyledLegendPaginationButtonProps {
  right: string;
}

export const StyledLegendPaginationButton = styled(Button)<StyledLegendPaginationButtonProps>`
  margin-top: 'var(--space-12)';
  right: ${(props) => props.right};
`;
