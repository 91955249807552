import {
  BaseRuleEvent,
  CalendarDaysEventData,
  FollowupEventType,
} from 'types/entities/collection-strategy/rule-event';

export interface CalendarDaysRuleEvent extends BaseRuleEvent {
  type: FollowupEventType.CALENDAR;
  data: CalendarDaysEventData;
}
export enum CalendarEventType {
  DAILY = 'DAILY',
  DAY_OF_WEEK = 'DAY_OF_WEEK',
  DAY_OF_MONTH = 'DAY_OF_MONTH',
}
