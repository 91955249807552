import SelectComponent from 'components/BaseComponents/ASelect';
import { notify } from 'components/BaseComponents/Notifications';
import { ClosedDesc } from 'components/CollectionActivities/Common/Details/ClosedDesc';
import useUpdateActivity from 'components/CollectionActivities/hooks/useUpdateActivity';
import { Style } from 'lib/style/style';
import { useUpdateKeyActivities, useUpdateTaskStatus } from 'queries/activites';
import { useEffect, useState } from 'react';
import { Task, TaskStatus, TaskTypeMenu } from 'types/activities/task';

interface MarkAsCompleteDropDownProps {
  status: TaskStatus;
  taskId: number;
  onChangeCallback?: (newStatus: TaskStatus) => void;
  data?: Task;
  activitiesMode?: boolean;
}

const StyledTaskStatus = Style(SelectComponent, {
  '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    border: '1px solid var(--gray-6) !important',
  },

  '.ant-select-arrow': {
    color: 'var(--primary-7)',
  },

  '&:hover': {
    '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      border: '1px solid var(--primary-7) !important',
    },
  },

  variants: {
    status: {
      OPEN: {
        '.ant-select-selection-item': {
          color: 'var(--red-7) !important',
        },
        '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
          background: 'var(--red-1) !important',
        },
      },
      CLOSED: {
        '.ant-select-selection-item': {
          color: 'var(--green-8) !important',
        },

        '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
          background: 'var(--green-1) !important',
        },
      },
    },
  },
});

export function MarkAsCompleteDropDown(props: MarkAsCompleteDropDownProps) {
  const { status, taskId, onChangeCallback, data, activitiesMode } = props;
  const [taskStatus, setTaskStatus] = useState<TaskStatus | undefined>();
  const { updateActivity } = useUpdateActivity<Task>();
  useUpdateKeyActivities();
  const { mutateAsync: updateTaskStatus, isLoading } = useUpdateTaskStatus();
  const isOpen = status === TaskStatus.OPEN;

  useEffect(() => {
    setTaskStatus(status);
  }, [status]);

  async function handleMarkAsCompleteClick() {
    const newStatus = status === TaskStatus.CLOSED ? TaskStatus.OPEN : TaskStatus.CLOSED;
    try {
      await updateTaskStatus({ id: taskId, status: newStatus });
      notify.success(isOpen ? 'Activity Marked as Closed' : 'Activity Marked as Open', {
        description: !activitiesMode && isOpen ? <ClosedDesc entity="task" /> : null,
      });
      setTaskStatus(newStatus);
      const updated = isOpen ? TaskStatus.CLOSED : TaskStatus.OPEN;
      onChangeCallback?.(updated);
      if (!data) return;
      const updatedValue = { ...data, status: updated };
      updateActivity(data, updatedValue);
      onChangeCallback?.(updated);
    } catch {
      throw new Error();
    }
  }

  return (
    <StyledTaskStatus
      status={isOpen ? TaskStatus.OPEN : TaskStatus.CLOSED}
      selectedValues={taskStatus}
      optionsList={TaskTypeMenu}
      size="small"
      onChangeCallBack={handleMarkAsCompleteClick}
      loading={isLoading}
      dropdownStyle={{ minWidth: 'fit-content' }}
    />
  );
}
