import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover } from 'antd';
import useDateFormat from 'components/HigherOrderComponent/KeyActivitesContainer/hooks/useDateFormat';
import React, { useMemo, useState } from 'react';
import { RelativeDateRange } from 'util/relative-date-range';
import { Flex } from '../Layout/Flex';
import { Texto } from '../Typography/Texto';
import { DateRangeSelectInput } from './DateRangeSelectInput';
import { DateRange, DateRangeDropdownOption, DateRangeString } from './types';
import { getDateRange, getDefaultOptions } from './utils';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

const t = {
  selectCustomRange: 'Select Custom Range',
  dateRange: 'Date Range',
  selectDateRange: 'Select Date Range',
};

interface DateRangeSelectProps {
  options?: DateRangeDropdownOption[];
  selected?: DateRange;
  default?: DateRange;
  showClear?: boolean;
  showCustomRangeSelect?: boolean;
  onChange?: (selectedDateRange: DateRangeString) => void;
  onClear?: () => void;
  title?: React.ReactNode;
  format?: string;
  hideLabel?: boolean;
  minWidth?: string;
}

export function DateRangeSelect(props: DateRangeSelectProps) {
  const dateFormat = props.format ?? DEFAULT_DATE_FORMAT;
  const dateRangeOptions = props.options || getDefaultOptions();
  const formatDate = useDateFormat();
  const [value, setValue] = useState<DateRangeString>(() => {
    if (props.selected) {
      return getDateRange(props.selected, dateRangeOptions, dateFormat);
    } else if (props.default) {
      return getDateRange(props.default, dateRangeOptions, dateFormat);
    } else {
      return getDateRange({ type: RelativeDateRange.THIS_WEEK }, dateRangeOptions, dateFormat);
    }
  });

  // // do a onChange callback if default props is set but props is not provided
  // const initRef = useRef(true);
  // if (initRef.current && !props.value) {
  //   props.onChange && props.onChange(value);
  //   initRef.current = false;
  // }

  function setValueAndTriggerOnChange(value: DateRangeString) {
    setValue(value);
    props.onChange && props.onChange(value);
  }

  const selectedRangeText = useMemo(() => {
    const selectedOption = dateRangeOptions.find((option) => option.id === value.type);

    return selectedOption
      ? selectedOption.label
      : `${formatDate(value.from)} - ${formatDate(value.to)}`;
  }, [dateRangeOptions, formatDate, value.from, value.to, value.type]);

  const OptionsList = (
    <DateRangeSelectInput
      value={value}
      onChange={setValueAndTriggerOnChange}
      options={props.options}
      format={props.format}
      showCustomRangeSelect={props.showCustomRangeSelect}
    />
  );

  return (
    <Popover content={OptionsList} title={props.title ?? t.selectDateRange} trigger={['click']}>
      <Button style={{ minWidth: props.minWidth ?? '240px' }}>
        <Flex justify="space-between" align="center" gap="--space-4">
          <span>
            {!props.hideLabel && <Texto weight="semibold">{t.dateRange}</Texto>}
            &nbsp;
            <Texto>{selectedRangeText}</Texto>
          </span>
          <FontAwesomeIcon icon={['far', 'chevron-down']} />
        </Flex>
      </Button>
    </Popover>
  );
}
