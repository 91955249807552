import { Radio as AntRadio } from 'antd';
import { GenericRadioProps, RadioGroupProps } from './type';
function AntRadioComponent<T = any>(props: GenericRadioProps<T>) {
  return <AntRadio {...props} />;
}
function AntRadioGroupComponent(props: RadioGroupProps) {
  return <AntRadio.Group {...props} />;
}

export { AntRadioComponent, AntRadioGroupComponent };
