// Commented Code for Future Reference

// export function clipBoardEnabled() {
//   return Boolean(navigator.clipboard);
// }

// export function readFromClipboard() {
//   return navigator.clipboard.readText();
// }

export function writeToClipboard(newClip: string) {
  return navigator.clipboard.writeText(newClip);
}
