import produce from 'immer';
import isEqual from 'lodash/isEqual';
import {
  customFieldFilterPredicate,
  CustomFieldFilterValues,
  CustomFieldOperatorType,
} from 'types/entities/custom-field';
import { replaceOrPushInArray } from 'util/array';

export interface ReportingTagFilter {
  tag_id: number;
  tag_option_ids: number[];
}

export interface DashboardFilters {
  business_unit_ids: number[];
  region_ids: number[];
  subsidiary_ids: number[];
  reporting_tags: ReportingTagFilter[];
  custom_fields: CustomFieldFilterValues<CustomFieldOperatorType>[];
  my_team?: boolean;
  my_company?: boolean;
  my_direct_reports?: boolean;
  my_indirect_reports?: boolean;
  my_reports?: boolean;
}

export interface DashBoardFilterView {
  id: string;
  name: string;
  system_defined: boolean;
  description?: string;
  dashboard_filter: Partial<DashboardFilters>;
}

export const initialFilterValues: DashboardFilters = {
  business_unit_ids: [],
  region_ids: [],
  subsidiary_ids: [],
  reporting_tags: [],
  custom_fields: [],
};

type FilterValuesAction =
  | { type: 'UPDATE_BUSINESSUNITS'; payload: number[] }
  | { type: 'UPDATE_REGIONS'; payload: number[] }
  | { type: 'UPDATE_SUBSIDARIES'; payload: number[] }
  | { type: 'UPDATE_REPORTING_TAGS'; payload: { tag_id: number; tag_option_ids: number[] } }
  | {
      type: 'UPDATE_CUSTOM_FIELDS';
      payload: CustomFieldFilterValues<CustomFieldOperatorType>;
    };

export function filterValuesReducer(filterValues: DashboardFilters, action: FilterValuesAction) {
  function updateBusinessUnits(businessUnits: number[]) {
    return produce(filterValues, (draft) => {
      if (isEqual(draft.business_unit_ids, businessUnits)) return;
      draft.business_unit_ids = businessUnits;
    });
  }

  function updateRegions(regions: number[]) {
    return produce(filterValues, (draft) => {
      if (isEqual(draft.region_ids, regions)) return;
      draft.region_ids = regions;
    });
  }

  function updateSubsidaries(subsidaries: number[]) {
    return produce(filterValues, (draft) => {
      if (isEqual(draft.subsidiary_ids, subsidaries)) return;
      draft.subsidiary_ids = subsidaries;
    });
  }

  function updateReportingTags(reportingTagValue: { tag_id: number; tag_option_ids: number[] }) {
    const predicate = (reportingTag: ReportingTagFilter) =>
      reportingTag.tag_id === reportingTagValue.tag_id;

    return produce(filterValues, (draft) => {
      const newReportingTagsArray = replaceOrPushInArray(
        draft.reporting_tags,
        reportingTagValue,
        predicate
      ).filter((reportingTag) => reportingTag.tag_option_ids?.length > 0);

      if (isEqual(draft.reporting_tags, newReportingTagsArray)) return;

      draft.reporting_tags = newReportingTagsArray;
    });
  }

  function updateCustomFields(customField: CustomFieldFilterValues<CustomFieldOperatorType>) {
    const predicate = (predicateCustomField: CustomFieldFilterValues<CustomFieldOperatorType>) =>
      customField.id === predicateCustomField.id;

    return produce(filterValues, (draft) => {
      const newCustomFieldsArray = replaceOrPushInArray(
        draft.custom_fields,
        customField,
        predicate
      ).filter(customFieldFilterPredicate);

      if (isEqual(draft.custom_fields, newCustomFieldsArray)) return;

      draft.custom_fields = newCustomFieldsArray;
    });
  }

  switch (action.type) {
    case 'UPDATE_BUSINESSUNITS':
      return updateBusinessUnits(action.payload);
    case 'UPDATE_REGIONS':
      return updateRegions(action.payload);
    case 'UPDATE_SUBSIDARIES':
      return updateSubsidaries(action.payload);
    case 'UPDATE_REPORTING_TAGS':
      return updateReportingTags(action.payload);
    case 'UPDATE_CUSTOM_FIELDS':
      return updateCustomFields(action.payload);
  }
}
