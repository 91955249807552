import { Select } from 'antd';
import { SelectProps } from 'antd/lib';
import styled from 'styled-components';
import { CustomFieldDataType, CustomFieldOption } from 'types/entities/custom-field';

interface SelectEditorCellProps {
  options: SelectProps['options'];
  value?: string;
  handleClear?: () => void;
  isLoading?: boolean;
  onChange?: (value: string) => void;
  dataType: CustomFieldDataType;
  customOptions: CustomFieldOption[];
}
const StyledSelect = styled(Select)`
  width: '100%';
  border: '0px';

  .ant-select-selector {
    background: var(--gray-1) !important;
    box-shadow: none !important;
    border-style: none;
    &:hover,
    &:active,
    &:focus-within {
      box-shadow: none !important;
      border-style: none;
    }
  }
`;
export default function SelectEditorCell(props: SelectEditorCellProps) {
  const booleanOptions = props.customOptions.map((option) => {
    return {
      label: option === '1' || option === 'TRUE' ? 'True' : 'False',
      value: option,
    };
  });

  const SelectCustomField = (
    <StyledSelect
      placeholder={`Select a option...`}
      options={props.dataType === 'BOOLEAN' ? booleanOptions : props.options}
      autoFocus
      style={{ width: '100%' }}
      allowClear
      defaultValue={props.value}
      defaultOpen
      loading={props.isLoading}
      onChange={(value) => {
        props?.onChange?.(value as string);
      }}
    />
  );
  return <>{SelectCustomField}</>;
}
