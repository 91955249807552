import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { DocumentTypesListCompProps } from 'types/entities/documents';

interface DocTitleProps extends DocumentTypesListCompProps<'name'> {}
interface DocDescProps extends DocumentTypesListCompProps<'description'> {}
export function DocumentTitle(props: DocTitleProps) {
  const { name } = props;
  return (
    <TypographyText strong style={{ fontSize: 'var(--fs-16)', maxWidth: '300px' }}>
      {name}
    </TypographyText>
  );
}

export function DocumentDesc(props: DocDescProps) {
  const { description } = props;
  return (
    <TypographyText
      style={{ fontSize: 'var(--fs-14)', maxWidth: '320px' }}
      ellipsis={{
        tooltip: {
          placement: 'leftTop',
          title: description,
        },
      }}
    >
      {description ?? '-'}
    </TypographyText>
  );
}
