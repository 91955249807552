import { BusinessUnit } from 'types/entities/business-unit';
import ky from './ky';

const baseUrl = `business-units`;

export async function getBusinessUnits() {
  return (await ky.get(baseUrl).json()) as BusinessUnit[];
}

export async function postBusinessUnits(data: { name: string }) {
  return (await ky.post(baseUrl, { json: data }).json()) as BusinessUnit;
}

export async function updateBusinessUnits(data: BusinessUnit) {
  return (await ky.put(`${baseUrl}/${data.id}`, { json: data }).json()) as BusinessUnit;
}

export async function deleteBusinessUnits(id: number) {
  return await ky.delete(`${baseUrl}/${id}`);
}
