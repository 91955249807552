import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'reducers/rootReducer';
import { EmailSignature } from 'services/signature';
import { Account, AccountConfig } from 'types/entities/account';
import { CurrentUser, User } from 'types/entities/user';
import { AuthenticationResponseState, AuthenticationState } from './types';

const initialState: AuthenticationState = {
  status: 0,
  message: '',
  appLoading: false,
  appLoadingProgress: 1 / 14,
  authentication: 'authenticating',
  user: undefined,
  accountDetails: undefined,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginHandler(state, action: PayloadAction<AuthenticationResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.user = action.payload.user;
      state.helpToken = action.payload.user?.help_center_auth_token;
      if (action.payload.status === 200) {
        state.authentication = 'authenticated';
      } else {
        state.authentication = 'not-authenticated';
      }
    },
    logoutHandler(state, action: PayloadAction<AuthenticationResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.user = action.payload.user;
      if (action.payload.status === 200) {
        state.authentication = 'not-authenticated';
      }
    },
    forgotPasswordHandler(state, action: PayloadAction<AuthenticationResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    resetPasswordHandler(state, action: PayloadAction<AuthenticationResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    activateAccountHandler(state, action: PayloadAction<AuthenticationResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    clearAuthStateHandler(state) {
      state.status = initialState.status;
      state.message = initialState.message;
    },
    setRedirectPathHandler(state, action: PayloadAction<string>) {
      state.redirectPathOnAuthentication = action.payload;
    },
    updateCurrentUserHandler(state, action: PayloadAction<User>) {
      state.user = {
        ...state.user,
        ...action.payload,
        has_team: state.user?.has_team ?? false,
        privileges: state.user?.privileges ?? [],
        account_id: state.user?.account_id ?? 0,
        roles: state.user?.roles ?? [],
      };
    },
    setAccountGeneralInformationHandler(state, action: PayloadAction<Account>) {
      state.accountDetails = action.payload;
    },
    updateAccountGeneralInformationHandler(state, action: PayloadAction<Partial<Account>>) {
      state.accountDetails = { ...state.accountDetails, ...action.payload } as Account;
    },
    appLoadingHandler(state, action: PayloadAction<boolean>) {
      state.appLoading = action.payload;
    },
    appLoadingProgressHandler(state, action: PayloadAction<number>) {
      state.appLoadingProgress = action.payload;
    },

    updateSignatureHandler(state, action: PayloadAction<EmailSignature | null>) {
      state.user = {
        ...state.user,
        email_signature: action.payload,
      } as CurrentUser;
    },
    updateAccountConfigAction(state, action: PayloadAction<AccountConfig>) {
      state.accountDetails = {
        ...state.accountDetails,
        configs: {
          ...state.accountDetails?.configs,
          ...action.payload,
        },
      } as Account;
    },
    updateToken(state, action: PayloadAction<string>) {
      state.helpToken = action.payload;
    },
    updateLocale(state, action: PayloadAction<string>) {
      if (state.accountDetails) {
        state.accountDetails = { ...state.accountDetails, locale: action.payload };
      }
    },
  },
});

export const baseCurrencySelector = (state: RootState) =>
  state.authentication.accountDetails?.currency;
export const authenticationSelector = (state: RootState) => state.authentication;

export const localeSelector = (state: RootState) =>
  state.authentication.accountDetails?.locale ?? 'en-US';
export const dateFormatSelector = (state: RootState) =>
  state.authentication.accountDetails?.date_format ?? 'MM-DD-YYYY';
export const {
  loginHandler,
  logoutHandler,
  forgotPasswordHandler,
  resetPasswordHandler,
  activateAccountHandler,
  clearAuthStateHandler,
  setRedirectPathHandler,
  updateCurrentUserHandler,
  setAccountGeneralInformationHandler,
  updateAccountGeneralInformationHandler,
  appLoadingHandler,
  appLoadingProgressHandler,
  updateSignatureHandler,
  updateAccountConfigAction,
  updateToken,
  updateLocale,
} = authenticationSlice.actions;

export const accountDetailsSelector = createSelector(
  authenticationSelector,
  (authentication) => authentication.accountDetails
);

export const accountConfigSelector = createSelector(
  authenticationSelector,
  (authentication) => authentication.accountDetails?.configs
);

export const userPrivilegesSelector = createSelector(
  authenticationSelector,
  (authentication) => authentication.user?.privileges
);

export const currentLoggedInUserSelector = createSelector(
  authenticationSelector,
  (authentication: AuthenticationState) => authentication.user
);
export const currentToken = createSelector(
  authenticationSelector,
  (authentication: AuthenticationState) => authentication.helpToken
);
export const appInitializationStateSelector = createSelector(
  (state: RootState) => state.authentication,
  (authentication: AuthenticationState) => ({
    loading: authentication.appLoading,
    progress: authentication.appLoadingProgress,
    authentication: authentication.authentication,
  })
);

// Need to replace usage with useConfig soon
export const strategyV2EnabledSelector = createSelector(
  accountDetailsSelector,
  (accountDetails) => accountDetails?.configs.strategy_v2_enabled
);

export const healthScoreEnabledSelector = createSelector(
  accountDetailsSelector,
  (accountDetails) => accountDetails?.configs.health_score_enabled
);

export default authenticationSlice.reducer;
