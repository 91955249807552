import { omitBy } from 'lodash';
import { agingConfigInterface } from 'pages/ARAging/type';
import { StatementsResponse } from 'store/customer/types';
import { AgingSnapshot } from 'types/api/aging';
import { AgingResponse } from 'types/entities/aging';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import {
  agingFilter,
  NotesForCustomersInterface,
  TagsForCustomersInterface,
} from '../store/aging/types';
import ky from './ky';

const baseUrl = 'aging';

export async function fetchAccountReceivables({ page, size, filters }: agingConfigInterface) {
  //cleanup-empty-array to prevent error in backend
  const data = { ...omitBy(filters, (item) => Array.isArray(item) && item.length === 0) };
  const searchParams = {
    ...(page && { page }),
    ...(size && { size }),
  };

  const response: AgingResponse = await ky
    .post(`${baseUrl}/customers`, { json: data, searchParams })
    .json();
  response.list.forEach((aging) => {
    aging.customer.health_score = aging.customer.health_score ?? aging.customer.risk_score;
  });
  return response;
}

// @unused
// export async function fetchTotalAgingBucketAmount(filters: agingFilter) {
//   //cleanup-empty-array to prevent error in backend
//   const data = { ...omitBy(filters, (item) => Array.isArray(item) && item.length === 0) };

//   return (await ky
//     .post(`${baseUrl}/buckets/total-amount`, { json: data })
//     .json()) as ITotalBucketValue[];
// }

export async function getReceivablesSnapshot(filters?: agingFilter) {
  //cleanup-empty-array to prevent error in backend
  const data = { ...omitBy(filters, (item) => Array.isArray(item) && item.length === 0) };

  return (await ky.post(`${baseUrl}/snapshot`, { json: data }).json()) as AgingSnapshot;
}

// @unused
// export async function getInvoiceOrCollectionStatus(type: string) {
//   return (await ky.get(`/status/${type}`).json()) as InvoiceStatusType[];
// }

type ListOfType = { id: string; name: string }[];
export async function getListOfType(type: string) {
  return (await ky.get(`${type}`).json()) as ListOfType;
}

export async function associateTagsToCustomers(data: TagsForCustomersInterface): Promise<unknown> {
  return await ky.post('tags/customers', { json: data });
}

export async function associateNotesToCustomers(data: NotesForCustomersInterface) {
  return await ky.post('notes', { json: data });
}

export async function getCustomerOpenInvoices(data: {
  ids: number[];
}): Promise<StatementsResponse<InvoiceBasicDetails>> {
  return await ky.post('customers/open-invoices', { json: data }).json();
}

export async function getCustomerOpenInvoicesBulkAction<T>(data: {
  filter: T;
}): Promise<StatementsResponse<InvoiceBasicDetails>> {
  return await ky.post('customers/open-invoices', { json: data }).json();
}
