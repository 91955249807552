import { TypographyParagraph } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { EmailTemplateListCard } from './Card';
import { EmailTemplateListOptionsProps } from './type';

const wrapperStyles: React.CSSProperties = {
  padding: 'var(--space-48) 154px',
  overflow: 'auto',
  overflowX: 'hidden',
};

function EmailTemplateListOptions(props: EmailTemplateListOptionsProps) {
  const { folderName, count, desc, templates } = props;
  return (
    <Flex direction="column" gap="var(--space-24)" style={wrapperStyles}>
      <Flex direction="column" gap="var(--space-12)">
        <GrowText
          size="16px"
          color="var(--gray-9)"
          style={{
            lineHeight: 'var(--lh-16)',
          }}
          weight="var(--fs-semibold)"
        >
          {`${folderName}`}
          <GrowText
            weight="var(--fs-regular)"
            color="var(--gray-7)"
            style={{ marginLeft: 'var(--space-4)' }}
          >
            ({count})
          </GrowText>
        </GrowText>
        <TypographyParagraph
          ellipsis={{
            rows: 2,
            tooltip: desc,
          }}
          style={{
            color: 'var(--gray-9)',
            marginBottom: 'var(--space-0)',
            lineHeight: 'var(--lh-16)',
          }}
        >
          {desc}
        </TypographyParagraph>
      </Flex>
      <EmailTemplateListCard templates={templates} />
    </Flex>
  );
}

export { EmailTemplateListOptions };
