import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import transformData from 'util/transformData';
import { useGetFormElementParamsQuery } from '../services/query';
import { DocumentMappingKeys, useGetDocumentOptionsProps } from '../types';
import { isDocumentData } from '../Utils';

export default function useGetDocumentTypes<T>({
  queryFn,
  queryKey,
  transformSchema,
  entityLevel,
}: useGetDocumentOptionsProps<T>) {
  const [options, setOptions] = useState<DocumentMappingKeys[]>([]);

  const { data, isLoading, isSuccess } = useGetFormElementParamsQuery<T, ActionableEntity>({
    queryFn,
    queryKey,
    params: entityLevel,
  });

  useEffect(() => {
    if (!isLoading && isSuccess && isDocumentData(data)) {
      const optionsListData = transformSchema
        ? map(data.list, (item) => {
            return transformData<T, DocumentMappingKeys>({
              originalData: item,
              schemaMapping: transformSchema,
            });
          })
        : (data.list as DocumentMappingKeys[]);

      setOptions(optionsListData);
    }
  }, [data, isLoading, isSuccess, setOptions, transformSchema]);

  return { isLoading, options };
}
