import { GrowText } from 'components/BaseComponents/Typography';
import { ErrorBoundaryWrapper } from 'components/Common/ErrorBoundary';
import { StyledCard } from 'pages/Dashboard/AdminDashboard/SnapShot/Snapshot';
import { useFollowUpActivitiesQuery } from 'queries/admin-dashboard';
import ChartSpinner from '../Common/ChartSpinner';
import { DashboardChartProps } from '../Common/types';
import FollowUpActivitiesChart from './FollowUpActivitiesChart';

const t = {
  followup_activities: 'Email Followup - Drill down',
};

export default function FollowUpActivitiesCard(props: DashboardChartProps) {
  const { snapshotFilterParams } = props;
  const {
    data: followupActivitiesList,
    isLoading: loading,
    isSuccess,
  } = useFollowUpActivitiesQuery(snapshotFilterParams);

  const Title = (
    <GrowText size={'var(--fs-20)'} color="var(--gray-9)" strong>
      {t.followup_activities}
    </GrowText>
  );

  if (loading && !isSuccess) {
    return <ChartSpinner title={Title} height={'250px'} />;
  }

  return (
    <StyledCard style={{ height: '100%' }}>
      <div style={{ marginBottom: 'var(--space-8)' }}>{Title}</div>
      <ErrorBoundaryWrapper>
        <FollowUpActivitiesChart followupActivitiesList={followupActivitiesList} />
      </ErrorBoundaryWrapper>
    </StyledCard>
  );
}
