import { useEffect, useRef } from 'react';

interface useAddDocumentsProps {
  callBack: (event: CustomEvent) => void;
}

function useAddDocuments(props: useAddDocumentsProps) {
  const savedFunction = useRef<useAddDocumentsProps['callBack']>();

  useEffect(() => {
    savedFunction.current = props.callBack;
  }, [props.callBack]);

  useEffect(() => {
    const eventListener = (event: CustomEvent) => savedFunction?.current?.(event);
    document.addEventListener('add-document', (event) => {
      eventListener(event as any);
    });
    return () => {
      document.removeEventListener('add-document', () => {});
    };
  }, []);
  return;
}

export default useAddDocuments;
