import { GrowTypography } from '@sinecycle/growcomponents';
import { Col, Popover, Row } from 'antd';
import { ExtraInfo } from 'components/BaseComponents/GrowTable/ExtraInfo';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import React from 'react';
import { LastActivityType } from 'types/activities/activity-types';
import { getLastActivityInformation } from '../key-acivities-config';
import { DateText } from 'components/BaseComponents/Date/DateText';

interface LastActivityProps extends React.HTMLProps<HTMLDivElement> {
  lastActivityType: LastActivityType;
  lastActivityDate: string;
  color?: string;
  hideDate?: boolean;
}

const LastActivity = (props: LastActivityProps) => {
  const { lastActivityType, lastActivityDate, color } = props;
  const detailActivity = getLastActivityInformation(lastActivityType);
  if (!detailActivity) {
    return null;
  }

  const { title, Icon, iconBg, detail } = detailActivity;
  const Date = (
    <DateText
      value={lastActivityDate}
      textProps={{ ellipsis: true, style: { color: props.hideDate ? 'var(--gray-8)' : color } }}
      showTime
    />
  );

  const Content = (
    <Flex align="flex-start" direction="column">
      <GrowTypography.Text>{detail ? detail : title}</GrowTypography.Text>
      {Date}
    </Flex>
  );
  return (
    <Flex align="center" gap="var(--space-8)" style={{ height: '100%' }}>
      <Popover
        content={props.hideDate ? Content : undefined}
        placement="right"
        arrow={false}
        autoAdjustOverflow
      >
        <Row wrap={false} align="middle" gutter={[8, 8]}>
          <Col>
            <ActivityIconButton
              icon={Icon}
              size="small"
              css={{ '--activityBg': iconBg }}
              viewMode
            />
          </Col>
          <Col flex="auto" style={{ lineHeight: '1.2' }}>
            <ExtraInfo value={detail ? detail : title} truncate />
          </Col>
        </Row>
      </Popover>
      {props.hideDate ? null : Date}
    </Flex>
  );
};

export { LastActivity };
