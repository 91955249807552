import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { t } from '../constants';
import { StyledIconButton } from '../style';

const CheckIcon = <FontAwesomeIcon icon={['far', 'check']} color="var(--primary-7)" />;
const DoubleCheckIcon = <FontAwesomeIcon icon={['far', 'check-double']} color="var(--primary-7)" />;
interface FilterViewActionProps {
  hide?: boolean;
  isDirty: boolean;
  onClick: () => void;
}

export function FilterViewSaveAction(props: Readonly<FilterViewActionProps>) {
  if (props.hide) return null;
  if (props.isDirty) return null;
  return (
    <Tooltip title={t.save_filter_view} placement="top" overlayStyle={{ padding: 0 }}>
      <StyledIconButton size="small" icon={CheckIcon} onClick={props.onClick} />
    </Tooltip>
  );
}
export function FilterViewSaveAsAction(props: Readonly<FilterViewActionProps>) {
  if (props.hide) return null;
  if (props.isDirty) return null;
  return (
    <Tooltip title={t.save_as_filter_view} placement="top" overlayStyle={{ padding: 0 }}>
      <StyledIconButton size="small" icon={DoubleCheckIcon} onClick={props.onClick} />
    </Tooltip>
  );
}
