import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import styled from 'styled-components';
import { getCustomFormattedDate } from 'util/datetime-formatter';
import useOpen from 'util/hooks/useOpen';

interface DatePickerCompProps {
  onChange: (value?: string) => void;
  value?: string;
}

const StyledDatePicker = styled.div`
  .ant-picker-outlined {
    background: var(--gray-1);
    border-color: var(--gray-1) !important;
    border-radius: 0px;
    box-shadow: none !important;
    border: 0px solid var(--gray-1) !important;
    &:hover,
    &:active,
    &:focus-within {
      border: 0px solid var(--gray-1) !important;
      background: var(--gray-1);
      border-color: var(--gray-1) !important;
      box-shadow: none !important;
    }
  }
`;

export default function DatePickerComp(props: DatePickerCompProps) {
  const format = useSelector(dateFormatSelector);
  const { onClose, open } = useOpen({
    open: true,
  });

  function handleDate(newDate: dayjs.Dayjs) {
    onClose();
    const date = getCustomFormattedDate(newDate, 'YYYY-MM-DD');
    props.onChange(newDate ? date : newDate);
  }

  const popupContainer = () => document.querySelector('.ag-body-viewport') as HTMLElement;

  return (
    <StyledDatePicker style={{ width: '100%' }}>
      <DatePicker
        autoFocus
        allowClear
        getPopupContainer={popupContainer}
        defaultValue={props.value === '-' || !props.value ? dayjs() : dayjs(props.value)}
        style={{ width: '100%' }}
        onChange={handleDate}
        format={format}
        popupAlign={{ overflow: { adjustY: true } }}
        open={open}
      />
    </StyledDatePicker>
  );
}
