import { PaymentMode } from 'components/CollectionActivities/types';

export const PaymentModeLabel: Record<PaymentMode, string> = {
  ACH: 'ACH',
  CREDIT_CARD: 'Credit Card',
  CHECK: 'Check',
  CASH: 'Cash',
  OTHER: 'Other',
  BANK_TRANSFER: 'Bank Transfer',
  BANK_REMITTANCE: 'Bank Remittance',
  WIRE_TRANSFER: 'Wire Transfer',
  PAYPAL: 'Paypal',
  DEBIT_CARD: 'Debit Card',
  OFFLINE_PAYMENT: 'Offline Payment',
  DIRECT_DEBIT: 'Direct Debit',
};
