import { Typography } from 'antd';
import styled from 'styled-components';

const { Text, Paragraph } = Typography;

export const SecondaryText = styled(Text)`
  display: block;
  font-size: var(--fs-12);
  color: rgba(0, 0, 0, 0.45);
  line-height: normal;
  svg {
    color: var(--blue-4);
  }
`;

export const TitleText = styled(Text)`
  color: var(--gray-8);
`;

export const SectionTitle = styled(Text)`
  svg {
    color: var(--blue-4);
  }
`;

export const TooltipParagraph = styled(Paragraph)`
  width: 20rem;
`;

export const SuccessText = styled(Text)`
  color: var(--green-4);
`;

export const DangerText = styled(Text)`
  color: var(--red-4);
`;
