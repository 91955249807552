import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Typography } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { useCustomDetailConfig } from 'components/HigherOrderComponent/Config/config';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { usePageNavigate } from 'lib/router';
import { useBreadCrumbs } from 'router/layout/Protected/LayoutContext';
import { Contact } from 'types/entities/contact';
import { CustomDetail, CustomDetailsType } from 'types/entities/custom-detail';
import { StatementViews } from 'types/entities/invoice';
import { cleanArrayPredicate } from 'util/predicates';
import { Amount } from '../../Common/MultiCurrency';
import { AutocompleteCard, SecondaryText } from './style';
import { CustomerResultsProps } from './types';

const { Text, Link } = Typography;
const t = {
  totalOutstanding: 'Total Outstanding:',
};
function CustomerResults(props: CustomerResultsProps) {
  const navigate = usePageNavigate();
  const customDetailToShow = useCustomDetailConfig();
  const EMPTY_STRING = '';
  const { resetBreadCrumb } = useBreadCrumbs();

  function getFirstDetail(customDetails: CustomDetail<string | Contact[]>[]): string | undefined {
    const detail = customDetails.find((detail) =>
      detail.type !== CustomDetailsType.CUSTOM_FIELD
        ? detail.type === customDetailToShow?.field_type
        : detail.id === customDetailToShow?.field_id
    );
    if (!detail) return;
    switch (detail.type) {
      case CustomDetailsType.CUSTOM_FIELD:
        if (detail.value) {
          return `${detail.value as string}`;
        }
        break;
      case CustomDetailsType.PRIMARY_CONTACT:
      case CustomDetailsType.SECONDARY_CONTACT:
        const contact = sanitizeContacts(detail.value as Contact[])[0];
        if (contact) {
          return `${contact?.first_name ? contact?.first_name : EMPTY_STRING} ${
            contact.last_name ? contact.last_name : EMPTY_STRING
          }`;
        }
        break;
    }
  }

  function sanitizeContacts(contacts: Contact[]): Partial<Contact[]> {
    return contacts.filter((contact) =>
      cleanArrayPredicate(contact.first_name || contact.last_name)
    );
  }

  return (
    <div className="customer-search-resut-card">
      <Row className="my-2">
        <Col span={24}>
          <SecondaryText className="me-2">CUSTOMERS</SecondaryText>
          <Text>{props.customers.length + props.users.length}</Text>
        </Col>
      </Row>
      {props.customers.map((customer, index) => {
        const customerName =
          customer.highlight.name && customer.highlight.name.length > 0
            ? customer.highlight.name[0]
            : customer.content.name;

        const customerId =
          customer.highlight.source_customer_id && customer.highlight.source_customer_id.length > 0
            ? String(customer.highlight.source_customer_id[0])
            : String(customer.content.source_customer_id);

        const showDetail =
          customer.content.custom_details && Boolean(customer.content.custom_details.length)
            ? getFirstDetail(customer.content.custom_details)
            : undefined;

        return (
          <AutocompleteCard
            className="shadow-sm cursor-pointer my-2"
            key={index}
            onClick={() => {
              resetBreadCrumb();
              navigate({
                appPage: 'CUSTOMER_DETAILS',
                pathParams: { id: customer?.content?.id },
                replace: true,
                state: {
                  replaceCurrentPath: true,
                },
                searchParams: {
                  [EActivityQueryParams.CURRENT_SECTION]: StatementViews.INVOICES,
                  [EActivityQueryParams.CURRENT_VIEW]: StatementViews.OPEN_INVOICES,
                } as unknown as URLSearchParams,
              });
            }}
          >
            <Row>
              <Col span={20}>
                {/* <SecondaryText>{customer.content.id}</SecondaryText><br/> */}
                <Link
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 'var(--space-4)',
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: customerName,
                    }}
                  />
                  <span
                    style={{ color: 'var(--gray-7)' }}
                    dangerouslySetInnerHTML={{
                      __html: showDetail ?? customerId ?? '',
                    }}
                  />
                </Link>
                <br />
                <Flex gap="var(--space-4)" align="center">
                  <GrowText>{t.totalOutstanding}</GrowText>
                  <GrowText>
                    <Amount
                      amount={customer.content.outstanding_amount}
                      noTags
                      currency={customer.content.currency}
                    />
                  </GrowText>
                </Flex>
              </Col>
              <Col span={4} className="text-end">
                <FontAwesomeIcon icon={['far', 'briefcase']} opacity={0.5} />
              </Col>
            </Row>
          </AutocompleteCard>
        );
      })}
      {props.users.map((user, index) => {
        const first_name =
          user.highlight.first_name && user.highlight.first_name.length > 0
            ? user.highlight.first_name[0]
            : user.content.first_name;
        const last_name =
          user.highlight.last_name && user.highlight.last_name.length > 0
            ? user.highlight.last_name[0]
            : user.content.last_name;
        const email =
          user.highlight.email && user.highlight.email.length > 0
            ? user.highlight.email[0]
            : user.content.email;
        return (
          <AutocompleteCard className="shadow-sm" key={index}>
            <Row>
              <Col span={20}>
                <SecondaryText>
                  <span dangerouslySetInnerHTML={{ __html: email }} />
                </SecondaryText>
                <br />
                <Link>
                  <span dangerouslySetInnerHTML={{ __html: first_name }} className="me-1" />
                  <span dangerouslySetInnerHTML={{ __html: last_name }} />
                </Link>
                <br />
              </Col>
              <Col span={4} className="text-end">
                <FontAwesomeIcon icon={['far', 'briefcase']} opacity={0.5} />
              </Col>
            </Row>
          </AutocompleteCard>
        );
      })}
    </div>
  );
}

export default CustomerResults;
