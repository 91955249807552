import { Spin } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { documentTypeTargetId } from 'pages/Settings/DocumentTypes/DocumentTypes';
import { useGetDocumentInfiniteTypesQuery } from 'queries/documents/documents';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DocumentTypeFilterKeys } from 'types/entities/documents';
import DocumentTypesListCard from './DocumentTypesListCard';

interface DocumentBodyProps {
  filter: DocumentTypeFilterKeys;
}
function DocumentTypesBody(props: DocumentBodyProps) {
  const { filter } = props;

  const { data, isLoading, hasNextPage, fetchNextPage } = useGetDocumentInfiniteTypesQuery(filter);
  const documentData = data ? data.pages.flatMap((page) => page.list) : [];

  function handleLoadMore() {
    if (hasNextPage) {
      fetchNextPage();
    }
  }

  const InfiniteScrollLoader = (
    <Flex className="loader" justify="center">
      <Spin />
    </Flex>
  );

  return (
    <InfiniteScroll
      dataLength={data?.pages.length ?? 0}
      next={handleLoadMore}
      hasMore={Boolean(hasNextPage)}
      scrollableTarget={documentTypeTargetId}
      loader={InfiniteScrollLoader}
    >
      <DocumentTypesListCard isLoading={isLoading} documentData={documentData} />
    </InfiniteScroll>
  );
}

export { DocumentTypesBody };
