import { getReportingTags } from '../services/reporting-tags';
import { updateReportingTags } from '../store/reporting-tags/reporting-tags';
import { IReportingTags } from '../store/reporting-tags/type';
import { AppThunk } from '../store/store';

export const getReportingTagsCollectionThunk = (): AppThunk<
  Promise<{ payload: IReportingTags[]; type: string }>
> => {
  return async (dispatch) => {
    try {
      return dispatch(updateReportingTags(await getReportingTags()));
    } catch (err) {
      return dispatch(updateReportingTags([] as IReportingTags[]));
    }
  };
};
