import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsMutating } from '@tanstack/react-query';
import { useForm } from 'antd/es/form/Form';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import useOpen from 'util/hooks/useOpen';
import FormModal from '../FormModal/FormModal';
import { activityText } from '../text';
import { DisputeFormModalFormElementProps, DisputeFormModalProps } from '../types';
import { onFormSave } from '../Utils';
import DisputeForm from './DisputeForm';

export default function DisputeFormModal<DisputeElements extends DisputeFormModalFormElementProps>(
  props: Omit<DisputeFormModalProps<DisputeElements>, 'form' | 'remainder' | 'onFormClose'>
) {
  const { openModal, id, onClose, disableRemainder, editMode, disputeNo } = props;
  const [form] = useForm();
  const isFormSaving = useIsMutating({
    mutationKey: ['activity-forms'],
  });

  const {
    open: openRemainder,
    onClose: closeRemainder,
    toggleOpen,
  } = useOpen({
    open: false,
  });
  const { open, onClose: modalClose } = useOpen({
    open: openModal,
  });

  function formSubmit() {
    onFormSave(form);
  }

  function onCancel() {
    modalClose();
    onClose();
    closeRemainder();
  }

  const Header = (
    <Flex gap={'var(--space-8)'} align="center">
      <FontAwesomeIcon color="var(--orange-6)" icon={['far', 'comment-times']} fontSize={18} />
      <GrowText size="var(--fs-20)" strong>
        {editMode ? `${activityText.edit_dispute} (${disputeNo})` : activityText.raise_dispute}
      </GrowText>
    </Flex>
  );

  return (
    <FormModal
      open={open}
      onCancel={onCancel}
      formSubmit={formSubmit}
      isLoading={Boolean(isFormSaving)}
      disableRemainder={disableRemainder}
      setRemainder={toggleOpen}
      headerTitle={Header}
      id={Number(id)}
      enableExtraAction={!editMode}
    >
      <DisputeForm<DisputeElements>
        form={form}
        remainder={openRemainder}
        onFormClose={onCancel}
        {...props}
      />
    </FormModal>
  );
}
