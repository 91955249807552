import {
  getFileExtensionFromName,
  getFileTypeFromExtension,
} from 'components/BaseComponents/UploadFiles/utils';
import { getDocTypeIcon } from 'components/HigherOrderComponent/DocViewer';
import { FileType } from 'types/common/attachments';
import { bytesToSize } from 'util/file-size';
import { removeFileExtension } from 'util/remove-file-extension';
import { Files, UploadActions } from '../../type';
import ItemContent from '../ItemContent';

export interface DefaultUploadItemRendererProps {
  hideActions: boolean;
  extra?: React.ReactNode;
}

type DefaultProps = {
  file: Files;
  action: UploadActions;
} & DefaultUploadItemRendererProps;
function UploadItemRenderer(props: Readonly<DefaultProps>) {
  const fileExtension = getFileExtensionFromName(props.file.name || props.file.fileName);
  const fileName = removeFileExtension(props.file.name ?? props.file.fileName ?? '');
  const fileSize = bytesToSize(props.file.size);
  const fileType = getFileTypeFromExtension(fileExtension);
  const FileIcon = fileExtension ? getDocTypeIcon(fileType) : FileType.PDF;
  const progressPercent = props.file.status === 'done' ? 100 : 0;
  const progressStatus =
    props.file.status === 'error'
      ? 'exception'
      : props.file.status === 'uploading'
      ? 'active'
      : 'normal';
  return (
    <ItemContent
      extraComp={props.extra}
      filename={fileName}
      fileSize={fileSize}
      icon={FileIcon}
      onRemove={props.action.remove}
      onPreview={props.action.preview}
      hideActions={props.hideActions}
      progress={{
        percent: progressPercent,
        status: progressStatus,
      }}
    />
  );
}

export default UploadItemRenderer;
