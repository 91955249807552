import { useEffect, useRef } from 'react';

export function useHandleOutsideClickRef<T extends HTMLElement = HTMLDivElement>(
  onOutsideClick: () => void
) {
  const ref = useRef<T>(null);

  useEffect(() => {
    function handleOutsideClick(e: MouseEvent) {
      if (!ref.current) {
        return;
      }
      if (ref.current.contains(e.target as Node)) {
        return;
      }

      onOutsideClick();
    }

    document.addEventListener('click', handleOutsideClick);

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [onOutsideClick]);

  return ref;
}
