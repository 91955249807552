import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notify } from 'components/BaseComponents/Notifications';
import { ContactTypeItem } from 'components/Settings/ContactTypes/ContactTypeCard';
import { clone, find, get, head, isEqual, remove } from 'lodash';
import {
  createContactType,
  deleteContactType,
  getAllContactTypes,
  updateContactType,
} from 'services/contact-type';

const t = {
  queryKey: 'contact-types-list',
  // Success notification titles
  successCreateTitle: 'Contact Type creation success!',
  updateSuccessTitle: 'Contact Type updated',
  removeSuccessTitle: 'Contact Type removed',
  // Error notification titles
  errorCreateTitle: 'Contact Type creation failed!',
  updateErrorTitle: 'Contact Type updation failed',
  removeErrorTitle: 'Contact Type deletion failed',
  // Notification description
  successCreateDescription: (contactTypeName: string) => `'${contactTypeName}' successfully added.`,
  removeSuccessDescription: (contactTypeName: string) =>
    `'${contactTypeName}' successfully deleted.`,
  updateSuccessDescription: (contactTypeName: string) =>
    `'${contactTypeName}' successfully updated.`,

  duplicateEntry: 'Contact Type already exists!',
};

export function useGetContactTypes() {
  return useQuery({
    queryFn: getAllContactTypes,
    queryKey: [t.queryKey],
  });
}

export function useCreateContactType({ toggleCreateMode }: { toggleCreateMode: () => void }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createContactType,
    onSuccess: (contactTypeAdded) => {
      const contactTypes = clone(queryClient.getQueryState<ContactTypeItem[]>([t.queryKey])?.data);
      contactTypes && queryClient.setQueryData([t.queryKey], [...contactTypes, contactTypeAdded]);
      toggleCreateMode();
      notify.success(t.successCreateTitle, {
        description: t.successCreateDescription(contactTypeAdded?.display_text),
      });
    },
    onError: async (error: any) => {
      const errorMsg = await error?.response?.json();
      const errorText = get(head(errorMsg?.errors), 'message');

      if (error?.response?.status === 409) notify.error(t.duplicateEntry);
      else if (errorText) notify.error(get(errorMsg, 'errors[0].message'));
      else notify.error(t.errorCreateTitle);
    },
  });
}

export function useUpdateContactType({ toggleEditMode }: { toggleEditMode: () => void }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateContactType,
    onSuccess: (contactTypeUpdated) => {
      const contactTypes = queryClient.getQueryState<ContactTypeItem[]>([t.queryKey]);
      const contactTypesUpdated = clone(contactTypes?.data);
      const toUpdateItemIndex = contactTypesUpdated?.findIndex((item) =>
        isEqual(item?.id, contactTypeUpdated.id)
      );
      const contactTypeUpdatedName = contactTypesUpdated
        ? contactTypesUpdated[toUpdateItemIndex ?? 0]?.display_text
        : '';
      if (toUpdateItemIndex && contactTypesUpdated) {
        contactTypesUpdated[toUpdateItemIndex] = contactTypeUpdated;
      }
      contactTypes && queryClient.setQueryData([t.queryKey], contactTypesUpdated);

      notify.success(t.updateSuccessTitle, {
        description: t.updateSuccessDescription(contactTypeUpdatedName),
      });
      toggleEditMode();
    },
    onError: () => notify.error(t.updateErrorTitle),
  });
}

export function useDeleteContactType(contactTypeId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteContactType,
    onSuccess: () => {
      const contactTypes = queryClient.getQueryState<ContactTypeItem[]>([t.queryKey]);
      if (contactTypes?.data) {
        const updatedContactTypes = clone(contactTypes?.data);
        const match: ContactTypeItem | undefined = find(updatedContactTypes, (item) =>
          isEqual(item?.id, contactTypeId)
        );
        remove(updatedContactTypes, match);
        queryClient.setQueryData([t.queryKey], updatedContactTypes);
        notify.success(t.removeSuccessTitle, {
          description: t.removeSuccessDescription(match?.display_text ?? ''),
        });
      }
    },
    onError: () => notify.error(t.removeErrorTitle),
  });
}
