import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  ConversationParticipantDateProps,
  IConversationParticipant,
} from 'store/activity-feed/type';

export enum EmailDeliveryStatusList {
  READ = 'READ',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  NOT_DELIVERED = 'NOT_DELIVERED',
}

export interface EmailRecepientProps {
  to: IConversationParticipant[];
  bcc: IConversationParticipant[];
  cc: IConversationParticipant[];
}

export type EmailDeliveryStatusConfigProps = {
  label: string;
  iconName: IconProp;
  iconColor: string;
  labelColor: string;
  dateProp: ConversationParticipantDateProps;
};

export type EmailDeliveryStatusConfig = Record<
  EmailDeliveryStatusList,
  EmailDeliveryStatusConfigProps
>;
