import { BucketsAndDuePeriod } from 'pages/Settings/ReceivableSettings/type';
import { AgingBucketItem, ReceivableDisplaySettings } from '../store/receivable-display/types';
import ky from './ky';

const baseUrl = 'aging-buckets';

export async function getBucketsAndDuePeriodSettings() {
  return (await ky.get(`${baseUrl}`).json()) as BucketsAndDuePeriod;
}

export async function getReceivableDisplaySettings() {
  return (await ky.get(`${baseUrl}`).json()) as ReceivableDisplaySettings;
}

export async function saveBucketsAndDuePeriodSettings(
  receivableDisplaySettings: BucketsAndDuePeriod
) {
  return (await ky
    .post(`${baseUrl}`, { json: receivableDisplaySettings })
    .json()) as BucketsAndDuePeriod;
}

export async function getAgingPreview(agingBuckets: Array<AgingBucketItem>) {
  type BucketsMap = { [key: string]: Array<number> };
  type AgingItem = { amount: number; count: number; from_days: number; to_days: number };

  const buckets = agingBuckets.reduce((agg, agingBucket, index) => {
    const bucketName = `bucket${index + 1}`;
    agg[bucketName] =
      index !== agingBuckets.length - 1
        ? [Number(agingBucket.from_days), Number(agingBucket.to_days) ?? 1000]
        : [Number(agingBucket.from_days), 9000];
    return agg;
  }, {} as BucketsMap);

  const agingResponse = (await ky
    .post(`${baseUrl}/preview`, { json: buckets })
    .json()) as AgingItem[];

  let maxLastDay = 0,
    maxLastDayIndex = 0;

  const agingItems = agingResponse.map((agingItem, index) => {
    if (agingItem.to_days > maxLastDay) {
      maxLastDay = agingItem.to_days;
      maxLastDayIndex = index;
    }

    return {
      value: agingItem.amount,
      age: `${agingItem.from_days} - ${agingItem.to_days}`,
      invoices: agingItem.count,
    };
  });

  agingItems[maxLastDayIndex].age = `> ${agingResponse[maxLastDayIndex].from_days - 1}`;

  return agingItems;
}
