import { Descriptions, Divider } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ActionButtons } from 'components/CollectionActivities/Common/Details/ActionButons/ActionButtons';
import { LoadingSkeleton } from 'components/CollectionActivities/Common/Details/LoadingSkeleton';
import { ActivityContent } from 'components/CollectionActivities/style';

import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';

function LoaderDetailsBody() {
  const Loader = <LoadingSkeleton />;
  const Title = (
    <Flex align="center" gap="var(--space-8)">
      <ActivityIconButton size="small" viewMode loading />
      {Loader}
    </Flex>
  );

  const Header = (
    <Flex justify="space-between">
      {Title}
      <ActionButtons hide />
    </Flex>
  );

  const InvoiceArea = Loader;
  const LineItem = Loader;

  const Note = Loader;
  const Attachments = Loader;
  const Body = (
    <ActivityContent layout="vertical" colon={false}>
      <Descriptions.Item>{InvoiceArea}</Descriptions.Item>

      <Descriptions.Item>{LineItem}</Descriptions.Item>
    </ActivityContent>
  );
  const Body2 = (
    <ActivityContent layout="vertical" colon={false}>
      <Descriptions.Item>{InvoiceArea}</Descriptions.Item>

      <Descriptions.Item>{LineItem}</Descriptions.Item>
    </ActivityContent>
  );

  const Content = (
    <Flex gap="var(--space-16)" direction="column">
      {Header}
      <>
        {Note}
        {Attachments}
        {Body}
        {Body2}
      </>
    </Flex>
  );

  const Footer = Loader;
  return (
    <>
      {Content}
      <Divider style={{ margin: '24px 0', borderColor: 'var(--gray-5)' }} />
      {Footer}
    </>
  );
}

export { LoaderDetailsBody };
