import { Menu } from 'antd';
import styled from 'styled-components';
export const sideBarMenuStyles: React.CSSProperties = {
  padding: 'var(--space-16) 0',
};

export const folderNameStyles: React.CSSProperties = {
  display: 'flex',
  gap: 'var(--space-4)',
};

export const StyledMenuItem = styled(Menu.Item)`
  &.ant-menu-item {
    height: 60px;
    padding: var(--space-24);
  }

  &.ant-menu-item.ant-menu-item-only-child:hover {
    background: var(--gray-3);
  }
  &.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child .folder-name {
    font-weight: 600 !important;
  }
`;
