import { ContactTypeItem } from 'components/Settings/ContactTypes/ContactTypeCard';
import ky from './ky';

const baseURL = 'contact-type';

export async function getAllContactTypes() {
  return (await ky.get(`${baseURL}`).json()) as ContactTypeItem[];
}

export async function createContactType(data: Pick<ContactTypeItem, 'display_text'>) {
  return (await ky.post(baseURL, { json: data }).json()) as ContactTypeItem;
}

export async function updateContactType(data: Pick<ContactTypeItem, 'display_text' | 'id'>) {
  return (await ky.put(`${baseURL}/${data.id}`, { json: data }).json()) as ContactTypeItem;
}

export async function deleteContactType(item: { id: ContactTypeItem['id'] }) {
  return await ky.delete(`${baseURL}/${item.id}`);
}
