import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPrivileges, privilegeState } from './type';

const initialState: privilegeState = {
  privilges: [],
};

const privilgeSlice = createSlice({
  name: 'privilege',
  initialState,
  reducers: {
    getPrivligesListHandler(state, action: PayloadAction<IPrivileges[]>) {
      state.privilges = action.payload;
    },
  },
});

export const { getPrivligesListHandler } = privilgeSlice.actions;

export default privilgeSlice.reducer;
