import ky from './ky';

const baseUrl = 'invoice-flags';

export interface FlagInvoiceParams {
  id?: number;
  comment?: string;
  invoice_list?: number[];
  customer_id?: number;
}

export async function addFlagToInvoices(params: FlagInvoiceParams) {
  return await ky.post(`${baseUrl}`, { json: params });
}

export async function unflagInvoices(params: FlagInvoiceParams) {
  return await ky.post(`${baseUrl}/unflag`, { json: params });
}
