import dayjs from 'dayjs';
import { formatToDefault as defaultFormat } from './datetime-formatter';

export enum RelativeDateRange {
  THIS_WEEK = 'THIS_WEEK',
  NEXT_WEEK = 'NEXT_WEEK',
  LAST_7_DAYS = 'LAST_7_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  THREE_MONTHS = '3_MONTHS',
  SIX_MONTHS = '6_MONTHS',
  NINE_MONTHS = '9_MONTHS',
  TWELVE_MONTHS = '12_MONTHS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  MORE_THAN_30_DAYS = 'MORE_THAN_30_DAYS',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  LESSER_THAN = 'LESSER_THAN',
  GREATER_THAN = 'GREATER_THAN',
  YESTERDAY = 'YESTERDAY',
}

export function numberToMonthsMapper(monthCount: number) {
  switch (monthCount) {
    case 1:
      return RelativeDateRange.THIS_MONTH;
    case 3:
      return RelativeDateRange.THREE_MONTHS;
    case 6:
      return RelativeDateRange.SIX_MONTHS;
    case 9:
      return RelativeDateRange.NINE_MONTHS;
    case 12:
      return RelativeDateRange.TWELVE_MONTHS;
    default:
      return RelativeDateRange.THIS_MONTH;
  }
}

interface Options {
  customDays?: number;
  format?: string;
}

export const getFormattedDateForRelativeOptions = (
  type: RelativeDateRange,
  { customDays, format }: Options = {}
): [string, string] => {
  const formatToDefault = format ?? defaultFormat;

  let from = dayjs().format(formatToDefault);
  let to = dayjs().format(formatToDefault);
  let start_day,
    end_day = 0;

  switch (type) {
    case RelativeDateRange.THIS_WEEK:
      from = dayjs().startOf('isoWeek').format(formatToDefault);
      to = dayjs().endOf('isoWeek').format(formatToDefault);
      break;

    case RelativeDateRange.NEXT_WEEK:
      from = dayjs().add(1, 'weeks').startOf('isoWeek').format(formatToDefault);
      to = dayjs().add(1, 'weeks').endOf('isoWeek').format(formatToDefault);
      break;

    case RelativeDateRange.LAST_7_DAYS:
      from = dayjs().subtract(7, 'd').format(formatToDefault);
      to = dayjs().format(formatToDefault);
      break;

    case RelativeDateRange.THIS_MONTH:
      from = dayjs().startOf('month').format(formatToDefault);
      to = dayjs().endOf('month').format(formatToDefault);
      break;

    case RelativeDateRange.LAST_MONTH:
      from = dayjs().startOf('month').subtract(1, 'months').format(formatToDefault);
      to = dayjs().endOf('month').subtract(1, 'months').format(formatToDefault);
      break;

    case RelativeDateRange.THREE_MONTHS:
      from = dayjs().startOf('month').subtract(3, 'months').format(formatToDefault);
      to = dayjs().format(formatToDefault);
      break;

    case RelativeDateRange.SIX_MONTHS:
      from = dayjs().startOf('month').subtract(6, 'months').format(formatToDefault);
      to = dayjs().format(formatToDefault);
      break;

    case RelativeDateRange.NINE_MONTHS:
      from = dayjs().startOf('month').subtract(9, 'months').format(formatToDefault);
      to = dayjs().format(formatToDefault);
      break;

    case RelativeDateRange.TWELVE_MONTHS:
      from = dayjs().startOf('month').subtract(12, 'months').format(formatToDefault);
      to = dayjs().format(formatToDefault);
      break;

    case RelativeDateRange.LAST_30_DAYS:
      from = dayjs().subtract(30, 'd').format(formatToDefault);
      to = dayjs().format(formatToDefault);
      break;

    case RelativeDateRange.MORE_THAN_30_DAYS:
      from = dayjs().subtract(1000, 'd').format(formatToDefault);
      to = dayjs().subtract(30, 'd').format(formatToDefault);
      break;

    case RelativeDateRange.TODAY:
      from = dayjs().format(formatToDefault);
      to = dayjs().format(formatToDefault);
      break;

    case RelativeDateRange.TOMORROW:
      from = dayjs().add(1, 'd').format(formatToDefault);
      to = dayjs().add(1, 'd').format(formatToDefault);
      break;

    case RelativeDateRange.LESSER_THAN:
      start_day = 0;
      end_day = customDays as number;

      return [String(start_day), String(end_day)];

    case RelativeDateRange.GREATER_THAN: {
      start_day = customDays as number;
      end_day = 1000;

      return [String(start_day), String(end_day)];
    }

    default:
      break;
  }

  return [from, to];
};
