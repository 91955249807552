import { GrowDrawer } from '@sinecycle/growcomponents';
import { Col, Row } from 'antd';
import React from 'react';
import DefaultFileUploader, { DefaultFileUploaderProps } from './FileUploader';
import DefaultFileUploadHeader from './Header';
import { DrawerProps } from './type';

interface DefaultFileUploadDrawerProps<Q> extends DrawerProps {
  drawerHeader?: React.ReactNode;
  uploadComponent?: React.ReactNode;
  extra?: React.ReactNode;
  onClose?: () => void;
  overrideStyles?: React.CSSProperties;
  headerTitle?: string | React.ReactNode;
  value?: Q;
}
export enum UploadDrawerPosition {
  UPLOAD_POSITION = 'uploadPosition',
  WORKFLOW_UPLOAD_POSITION = 'workflowUploadPosition',
}
interface DefaultFileUploadDrawerProps<Q> extends DefaultFileUploaderProps<Q> {
  hideUpload?: boolean;
  positionSpacing?: UploadDrawerPosition;
}

export type FileUploadDrawerProps<Q, T = void> = T extends void
  ? DefaultFileUploadDrawerProps<Q>
  : T & DefaultFileUploadDrawerProps<Q>;

function FileUploadDrawer<Q, T = void>(props: Readonly<FileUploadDrawerProps<Q, T>>) {
  const Header = props.drawerHeader ? (
    props.drawerHeader
  ) : (
    <DefaultFileUploadHeader onClose={props.onClose} title={props.headerTitle} />
  );

  return (
    <GrowDrawer
      title={Header}
      open={props.open}
      mask={props.mask}
      width={props.width}
      closable={false}
      onClose={props.onClose}
      design="growfin_design_default"
      styles={{
        body: {
          backgroundColor: 'var(--gray-2)',
        },
      }}
      zIndex={props.zIndex}
      forceRender={props.forceRender}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <DefaultFileUploader<Q>
            fileList={props.fileList}
            action={props.action}
            hide={props.hideUpload}
            onChange={props.onChange}
            onPreview={props.onPreview}
            onRemove={props.onRemove}
            beforeUpload={props.beforeUpload}
          />
        </Col>
        <Col span={24}>{props.extra}</Col>
      </Row>
    </GrowDrawer>
  );
}

export default FileUploadDrawer;
