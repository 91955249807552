import { GrowCheckBox } from '@sinecycle/growcomponents';
import { Tooltip } from 'antd';
import { size } from 'lodash';
import { LineItemsCheckboxProps } from '../types';

const t = {
  refer_line_items: 'Refer line Items',
  select_invoice: 'Please select an invoice first',
};

export default function LineItemsCheckbox(props: Omit<LineItemsCheckboxProps, 'form'>) {
  const { checked, disabled, onChange, invoiceIdsList } = props;

  return (
    <Tooltip title={size(invoiceIdsList) < 1 ? t.select_invoice : null}>
      <GrowCheckBox
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        design="border-design"
      >
        {t.refer_line_items}
      </GrowCheckBox>
    </Tooltip>
  );
}
