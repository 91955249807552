import { Select } from 'antd';
import { Style } from 'lib/style/style';

export const StyledSelect = Style(Select, {
  '.ant-select-item:not(.ant-select-item-option-selected)': {
    '.ant-select-item-option-content': {
      color: 'var(--primary-10)',
    },
  },
  '.ant-select-item-option-selected': {
    background: 'transparent',
    fontWeight: 'var(--fs-regular)',
  },

  variants: {
    displayIn: {
      Bold: {
        '.ant-select-arrow': {
          color: 'var(--primary-7)',
        },
        '.ant-select-selection-item': {
          color: 'var(--primary-7)',
          fontSize: 'var(--fs-14)',
          fontWeight: 'var(--fs-semibold)',
        },
        '.ant-select-item-option-selected': {
          color: 'var(--purple-7)',
          backgroundColor: 'transparent',
        },
      },
      Regular: {
        '.ant-select-item-option-selected': {
          backgroundColor: 'var(--purple-1)',
        },
      },
    },
  },
});

export const CollectionStrategySelectDropDown = Style(Select, {
  '.ant-select-selection-placeholder': {
    display: 'flex',
    alignItems: 'center',
  },
  '.ant-select-selection-item': {
    maxWidth: '80%',
  },
  '&:hover .ant-select-arrow:not(:last-child)': {
    opacity: '1 !important',
  },
  '&:hover .select-arrow-down': {
    opacity: '0 !important',
  },
});
