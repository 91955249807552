import { Switch, SwitchProps } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { StyledToggleSwitchComponent } from './style';

interface ISwitch extends SwitchProps {
  label?: string;
}

const ToggleSwitchComponent = (props: ISwitch) => {
  const handleOnChange: SwitchChangeEventHandler = (checked, event) => {
    if (props.onChange) {
      return props.onChange(checked, event);
    }
  };

  return (
    <StyledToggleSwitchComponent className={`switch-component ${props.className}`}>
      <Switch
        checked={props.checked as boolean}
        disabled={props.disabled}
        onChange={handleOnChange}
        size={props.size ? props.size : 'small'}
      />
      <span className="title ms-2">{props.label}</span>
    </StyledToggleSwitchComponent>
  );
};

export default ToggleSwitchComponent;
