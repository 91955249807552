import { useQueryClient } from '@tanstack/react-query';
import { notify } from 'components/BaseComponents/Notifications';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { castArray } from 'lodash';
import {
  ActivityKey,
  ActivityKeyMap,
  useDeleteActivitiesData,
  useDeleteDisputeActivity,
} from 'queries/activites';
import { ActivityType } from 'types/activities/activity-types';
import { useEventBus } from 'util/hooks/useCustomEventEmitter';
import { ActivityNotificationMessage } from '../ActivitiesForm/predicate';
import useActivityUtils from './useActivityUtils';
import { useEntitySearchParams } from './useEntitySearchParams';

export type DeleteActivityId = { id?: string; type?: ActivityType };

export function useOnDeleteActivity() {
  const queryClient = useQueryClient();
  const { emitEvent } = useEventBus();
  const { mutateAsync: deleteActivity, isLoading: deleteLoading } = useDeleteActivitiesData();
  const { mutateAsync: deleteDisputeActivity, isLoading: deleteDisputeLoading } =
    useDeleteDisputeActivity();
  const { entitySearchParams } = useEntitySearchParams();
  const associatedActivity = entitySearchParams[EActivityQueryParams.ASSOCIATED_ACTIVITY];
  const activityType: ActivityKey = associatedActivity
    ? entitySearchParams[EActivityQueryParams.ASSOCIATED_ACTIVITY_TYPE]
    : entitySearchParams[EActivityQueryParams.ACTIVITY_TYPE];
  const notification = ActivityNotificationMessage[activityType];

  const { removeActivityQueryParams } = useActivityUtils();

  async function deleteActivityListData(id?: string, _?: ActivityType) {
    try {
      if (
        entitySearchParams[EActivityQueryParams.ACTIVITY_TYPE] === ActivityType.DISPUTE ||
        entitySearchParams[EActivityQueryParams.ASSOCIATED_ACTIVITY_TYPE] === ActivityType.DISPUTE
      ) {
        await deleteDisputeActivity(castArray(String(id)));
      } else {
        const activityValue = ActivityKeyMap[activityType];
        await deleteActivity({
          currentActivity: activityValue,
          id: Number(id),
        });
      }

      notify.success(notification.delete?.success.title, {
        description: notification.delete?.success.message,
      });
      await queryClient.invalidateQueries([entitySearchParams[EActivityQueryParams.QUERY_KEY]]);

      emitEvent<DeleteActivityId>({
        event: 'deletedActivityId',
        data: {
          id: String(entitySearchParams[EActivityQueryParams.ACTIVITY_ID]),
          type: entitySearchParams[EActivityQueryParams.ACTIVITY_TYPE],
          enabled: !entitySearchParams[EActivityQueryParams.QUERY_KEY],
        },
      });
      removeActivityQueryParams();
    } catch (err) {
      notify.success(notification.delete?.error.title, {
        description: notification.delete?.error.message,
      });
      throw new Error();
    }
  }

  return { isLoading: deleteLoading || deleteDisputeLoading, deleteActivityListData };
}
