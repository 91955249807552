import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/BaseComponents/Buttons/Button';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { useHandleOutsideClickRef } from 'hooks/use-outside-click';
import { styled } from 'lib/css-in-js';
import React, { HTMLAttributes, useMemo, useState } from 'react';

interface AlignItem {
  key: React.CSSProperties['textAlign'];
  label: React.ReactNode;
}

const alignItems: AlignItem[] = [
  {
    key: 'left',
    label: <FontAwesomeIcon icon={['far', 'align-left']} style={{ height: '16px' }} />,
  },
  {
    key: 'center',
    label: <FontAwesomeIcon icon={['far', 'align-center']} style={{ height: '16px' }} />,
  },
  {
    key: 'right',
    label: <FontAwesomeIcon icon={['far', 'align-right']} style={{ height: '16px' }} />,
  },
];

const quickActionCSS: React.CSSProperties = {
  position: 'absolute',
  boxShadow: 'var(--shadow-3)',
  borderRadius: 'var(--br-1)',
  border: '1px solid var(--gray-4)',
  padding: 'var(--space-4)',
  background: 'white',
  bottom: '-40px',
  zIndex: '1',
  width: 'max-content',
};

const StyledContainer = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
`;

interface QuickActionsMenuProps {
  onDeleteCallback?: () => void;
  onAlignClickCallback?: (key: React.CSSProperties['textAlign']) => void;
  children?: React.ReactChild;
  trigger?: 'click' | 'hover';
}

export function QuickActionsMenu(props: QuickActionsMenuProps) {
  const [openQuickAction, setOpenQuickAction] = useState(true);

  function handleOutsideClick() {
    setOpenQuickAction(false);
  }
  const elementRef = useHandleOutsideClickRef<HTMLDivElement>(handleOutsideClick);

  function onDeleteCallback() {
    props.onDeleteCallback && props.onDeleteCallback();
  }

  function onAlignClickCallback(key: React.CSSProperties['textAlign']) {
    props.onAlignClickCallback && props.onAlignClickCallback(key);
  }

  const DeleteButton = (
    <Button
      buttonProps={{
        type: 'text',
        icon: <FontAwesomeIcon icon={['far', 'trash-alt']} style={{ height: '16px' }} />,
        onClick: () => onDeleteCallback(),
      }}
    />
  );

  const AlignButtons = alignItems.map((item) => {
    return (
      <React.Fragment key={item.key}>
        <Button
          buttonProps={{
            onClick: () => onAlignClickCallback(item.key),
            type: 'text',
            icon: <>{item.label}</>,
          }}
        />
      </React.Fragment>
    );
  });

  const ImageQuickAction = (
    <Flex
      gap="var(--space-8)"
      style={{
        display: !openQuickAction ? 'none' : 'inherit',
        ...quickActionCSS,
      }}
    >
      {AlignButtons}
      {DeleteButton}
    </Flex>
  );

  const triggerCallback: HTMLAttributes<HTMLElement> = useMemo(() => {
    const callbacks: HTMLAttributes<HTMLElement> = {};

    if (props.trigger === 'click') {
      callbacks['onClick'] = (event: React.MouseEvent<HTMLElement>) => {
        setOpenQuickAction(true);
      };
    } else {
      callbacks['onMouseEnter'] = (event: React.MouseEvent<HTMLElement>) => {
        setOpenQuickAction(true);
      };

      callbacks['onMouseLeave'] = (event: React.MouseEvent<HTMLElement>) => {
        setOpenQuickAction(false);
      };
    }

    return callbacks;
  }, [props.trigger]);

  return (
    <StyledContainer ref={elementRef} className="gf-rte-node-action" {...triggerCallback}>
      {props.children}
      {ImageQuickAction}
    </StyledContainer>
  );
}

export function useQuickActionsMenu(props: QuickActionsMenuProps) {
  const [openQuickAction, setOpenQuickAction] = useState(false);

  function handleOutsideClick() {
    setOpenQuickAction(false);
  }
  const elementRef = useHandleOutsideClickRef<HTMLDivElement>(handleOutsideClick);

  function onDeleteCallback() {
    props.onDeleteCallback && props.onDeleteCallback();
  }

  function onAlignClickCallback(key: React.CSSProperties['textAlign']) {
    props.onAlignClickCallback && props.onAlignClickCallback(key);
  }

  const DeleteButton = (
    <Button
      buttonProps={{
        type: 'text',
        icon: <FontAwesomeIcon icon={['far', 'trash-alt']} style={{ height: '16px' }} />,
        onClick: () => onDeleteCallback(),
      }}
    />
  );

  const AlignButtons = alignItems.map((item) => {
    return (
      <React.Fragment key={item.key}>
        <Button
          buttonProps={{
            onClick: () => onAlignClickCallback(item.key),
            type: 'text',
            icon: <>{item.label}</>,
          }}
        />
      </React.Fragment>
    );
  });

  const QuickAction = (
    <div
      style={
        {
          display: !openQuickAction ? 'none' : 'flex',
          gap: 'var(--space-8)',
          ...quickActionCSS,
        } as React.CSSProperties
      }
      onMouseEnter={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      {AlignButtons}
      {DeleteButton}
    </div>
  );

  const triggerCallback: HTMLAttributes<HTMLElement> = useMemo(() => {
    const callbacks: HTMLAttributes<HTMLElement> = {};

    if (props.trigger === 'click') {
      callbacks['onClick'] = (event: React.MouseEvent<HTMLElement>) => {
        setOpenQuickAction(true);
      };
    } else {
      callbacks['onMouseEnter'] = (event: React.MouseEvent<HTMLElement>) => {
        setOpenQuickAction(true);
      };
    }

    return callbacks;
  }, [props.trigger]);

  return [QuickAction, triggerCallback, elementRef];
}
