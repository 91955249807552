import { InvoiceListTabKeys } from 'components/InvoiceListV2';
import { CUSTOMER_DETAILS_EVENT } from 'events/customer-details';
import { INVOICE_LIST_EVENT } from 'events/invoice-List';
import { dispatchAppEvent } from 'lib/pub-sub';

export const handleDispatchEventPaginationOpen = (fromInvoiceList?: boolean) => {
  const eventToDispatch = fromInvoiceList
    ? INVOICE_LIST_EVENT.INVOICE_LIST_PAGINATION
    : CUSTOMER_DETAILS_EVENT.PAGINATION;

  dispatchAppEvent({
    type: eventToDispatch,
    payload: {
      tab: InvoiceListTabKeys.OPEN_INVOICE,
    },
  });
};

export const handleDispatchEventViewPdfOpen = (fromInvoiceList?: boolean) => {
  const eventToDispatch = fromInvoiceList
    ? INVOICE_LIST_EVENT.INVOICE_LIST_VIEW_PDF
    : CUSTOMER_DETAILS_EVENT.VIEW_PDF;

  dispatchAppEvent({
    type: eventToDispatch,
    payload: {
      tab: InvoiceListTabKeys.OPEN_INVOICE,
    },
  });
};

export const handleDispatchEventDownloadPdfOpen = (fromInvoiceList?: boolean) => {
  const eventToDispatch = fromInvoiceList
    ? INVOICE_LIST_EVENT.INVOICE_LIST_DOWNLOAD_PDF
    : CUSTOMER_DETAILS_EVENT.DOWNLOAD_PDF;
  dispatchAppEvent({
    type: eventToDispatch,
    payload: {
      tab: InvoiceListTabKeys.OPEN_INVOICE,
    },
  });
};

export const handleDispatchEventSortingOpen = (field: string, fromInvoiceList?: boolean) => {
  const eventToDispatch = fromInvoiceList
    ? INVOICE_LIST_EVENT.INVOICE_LIST_SORTING
    : CUSTOMER_DETAILS_EVENT.SORTING;
  dispatchAppEvent({
    type: eventToDispatch,
    payload: {
      column: field,
      tab: InvoiceListTabKeys.OPEN_INVOICE,
    },
  });
};
