import { CollectionStrategyQuickAction } from 'components/HigherOrderComponent/KeyActionsContainer/CollectionStrategyQuickAction';
import { InvoiceStrategyStatus } from 'types/entities/collection-strategy';
import FlagInvoices from '../FlagInvoices';
import { UnFlagInvoice } from '../UnflagInvoices';
import { EActions } from '../types';
import { IActionsModal } from './types';

export const ActionsModal = (props: IActionsModal) => {
  if (props.quickAction === EActions.FLAG_INVOICE) {
    return <FlagInvoices {...props} />;
  }

  if (props.quickAction === EActions.UNFLAG_INVOICE) {
    return <UnFlagInvoice {...props} />;
  }

  if (props.quickAction === EActions.PAUSE_STRATEGY) {
    return (
      <CollectionStrategyQuickAction {...props} strategyStatus={InvoiceStrategyStatus.PAUSED} />
    );
  }

  if (props.quickAction === EActions.RESUME_STRATEGY) {
    return (
      <CollectionStrategyQuickAction {...props} strategyStatus={InvoiceStrategyStatus.RUNNING} />
    );
  }

  return null;
};
