import { GrowTypography } from '@sinecycle/growcomponents';
import { Col, Row } from 'antd';
import { DateText } from 'components/BaseComponents/Date/DateText';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import ActivityCardActions from 'components/CollectionActivities/Common/Card/ActivityCardActions';
import ActivityCardHeader from 'components/CollectionActivities/Common/Card/ActivityCardHeader';
import { LatestActivityFooter } from 'components/CollectionActivities/Common/LatestActivity';
import { InboxActivityCardProps } from 'components/CollectionActivities/types';
import { Amount } from 'components/Common/MultiCurrency';
import { StatusCell } from 'components/Common/StatusCell/StatusCell';
import { getKeyActivityConfig } from 'components/HigherOrderComponent/KeyActivitesContainer/key-acivities-config';
import { CustomerAndInvoice } from 'components/Inbox/CollectionActivitiesV2/Activities/CustomerAndInvoice';
import { ToggleActivityReadButton } from 'components/Inbox/CollectionActivitiesV2/Activities/ToggleActivityReadButton';
import { head } from 'lodash';
import { ELastContactActivity } from 'store/activity-feed/type';
import { ActivityType } from 'types/activities/activity-types';
import { promiseToPayStatusConfig } from 'types/activities/promise-to-pay';

interface PTPCardProps extends InboxActivityCardProps<ActivityType.PROMISE_TO_PAY> {}

const activityConfig = getKeyActivityConfig(ELastContactActivity.PROMISE_TO_PAY);

function CardHeader(props: PTPCardProps) {
  const { promise_to_pay, viewMode, activitiesMode, activityBg, onStatusUpdate } = props;
  const MarkAsRead = (
    <ToggleActivityReadButton
      activity={promise_to_pay}
      viewMode={viewMode}
      hideDot={activitiesMode}
      css={activityBg}
    />
  );
  const Status = (
    <StatusCell
      status={{
        mode: 'Tags',
        text: promiseToPayStatusConfig[promise_to_pay.entity_detail.status].label,
        style: promiseToPayStatusConfig[promise_to_pay.entity_detail.status].style,
      }}
    />
  );
  const Title = (
    <GrowText color="var(--gray-8)" strong={!promise_to_pay.read}>
      {activityConfig?.cardLabel}
    </GrowText>
  );
  const Heading = (
    <Flex gap="var(--space-4)" align="center">
      {MarkAsRead}
      {Title}
    </Flex>
  );
  return (
    <ActivityCardHeader activitiesMode={activitiesMode}>
      {Heading}
      <ActivityCardActions
        activity={promise_to_pay}
        onStatusUpdate={onStatusUpdate}
        extraActions={Status}
      />
    </ActivityCardHeader>
  );
}

function CardBody({ promise_to_pay, activitiesMode }: PTPCardProps) {
  const PtpAmount = (
    <Amount
      amount={promise_to_pay.ptp_amount || head(promise_to_pay.invoices)?.amount}
      currency={promise_to_pay.currency || head(promise_to_pay.invoices)?.currency}
    />
  );

  const ActivityModeBody = (
    <GrowTypography.Paragraph ellipsis strong={!promise_to_pay.read}>
      Date: {<DateText value={promise_to_pay?.ptp_date ?? ''} />}&nbsp;
      <GrowTypography.Text>Amount: {PtpAmount}</GrowTypography.Text>
    </GrowTypography.Paragraph>
  );

  const Details = (
    <CustomerAndInvoice
      customer={promise_to_pay.customer}
      invoices={promise_to_pay.invoices}
      activitiesMode={activitiesMode}
    />
  );

  return (
    <Row gutter={[0, 6]}>
      <Col span={24}> {ActivityModeBody}</Col>
      <Col span={24}> {Details}</Col>
    </Row>
  );
}

function CardFooter({ promise_to_pay }: PTPCardProps) {
  const { latest_activity } = promise_to_pay;

  return <LatestActivityFooter latestActivity={latest_activity} />;
}

const PromiseToPayCard = () => <></>;

PromiseToPayCard.Header = CardHeader;
PromiseToPayCard.Body = CardBody;
PromiseToPayCard.Footer = CardFooter;

export { PromiseToPayCard };
