import { Form } from 'antd';
import { FormItemNames, WriteOffFormModalProps, WriteOffModalFormElementProps } from '../types';
import WriteOffFormItems from './WriteOffFormItems';

export default function WriteOffForm<WriteOffElements extends WriteOffModalFormElementProps>(
  props: Omit<WriteOffFormModalProps<WriteOffElements>, 'openModal'>
) {
  const { form } = props;

  function onFormFinish(values: Record<FormItemNames, any>) {
    return values;
  }

  return (
    <Form.Provider
      onFormFinish={(name, { values }) => {
        onFormFinish(values as Record<FormItemNames, any>);
      }}
    >
      <Form name={'activityForm'} form={form} layout="vertical" preserve={false}>
        <WriteOffFormItems<WriteOffElements> {...props} />
      </Form>
    </Form.Provider>
  );
}
