import { GrowTree } from '@sinecycle/growcomponents';
import { Tree } from 'antd';
import { Style } from 'lib/style/style';

export const StyledTree = Style(GrowTree, {
  '.ant-tree-list-holder': {
    '.ant-tree-treenode': {
      lineHeight: '1.2',
      '.ant-tree-switcher': {
        display: 'none',
      },
    },

    '.ant-tree-indent-unit': {
      width: '16px',
    },

    '.ant-tree-treenode-leaf-last': {
      paddingBottom: 'var(--space-16)',
    },
  },
  '&.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected': {
    backgroundColor: 'var(--transparent)',
  },
});
