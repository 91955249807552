import { useQueryClient } from '@tanstack/react-query';

function useUpdateActivity<T>() {
  const queryClient = useQueryClient();

  function updateActivity(value: T, updatedValue: T) {
    const currentData = queryClient.getQueriesData<T>(['collection_activity']);
    if (!currentData.length) return;
    const [queryKey] = currentData[0];
    queryClient.setQueryData(queryKey, {
      ...value,
      ...updatedValue,
    });
  }

  return { updateActivity };
}

export default useUpdateActivity;
