import { DefaultOptionType } from 'antd/lib/select';
import { map } from 'lodash';
import { useState } from 'react';
import transformData from 'util/transformData';
import { generateInvoiceOptions } from '../predicate';
import { useFormMutation } from '../services/mutation';
import { InvoiceFieldMapping, useGetInvoiceSearchProps } from '../types';
import { updateInvoiceData } from '../Utils';

export default function useGetInvoiceSearch<U>(props: useGetInvoiceSearchProps<U>) {
  const { searchQueryFn, transformSchema, openInvoices, selectedInvoices, modifyOptionsMap } =
    props;
  const [searchOptions, setSearchOptions] = useState<DefaultOptionType[]>();
  const { mutateAsync: searchInvoices, isLoading: searchLoading } =
    useFormMutation<U>(searchQueryFn);

  async function searchInvoiceList(searchTerm: string) {
    if (!searchTerm) return;

    try {
      const data = await searchInvoices(searchTerm);
      const optionsSelectedList = updateInvoiceData(data, selectedInvoices);
      const optionsData = map(optionsSelectedList, (item) => {
        return transformData<U, InvoiceFieldMapping>({
          originalData: item,
          schemaMapping: transformSchema,
        });
      });
      const optionsMapList = new Map(map(optionsData, (item) => [item?.id, item]));
      const optionsList = generateInvoiceOptions(optionsData, openInvoices);
      modifyOptionsMap(optionsMapList);
      setSearchOptions(optionsList);
    } catch {
      throw new Error();
    }
  }

  return { searchInvoiceList, searchLoading, searchOptions };
}
