import { Card, Col, Row } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ErrorBoundaryWrapper } from 'components/Common/ErrorBoundary';
import HideWrapper from 'components/Common/Util/HideWrapper';
import Aging from 'components/Dashboard/Aging';
import BadDebtTile from 'components/Dashboard/BadDebtTile';
import CEITile from 'components/Dashboard/CEITile';
import CollectionStatusCard from 'components/Dashboard/CollectionStatues';
import DSOTile from 'components/Dashboard/DSOTile';
import Delinquencies from 'components/Dashboard/Delinquencies';
import { FlaggedInvoices } from 'components/Dashboard/FlaggedInvoices';
import FollowUpActivitiesCard from 'components/Dashboard/FollowupActivities';
import FollowUpStatusCard from 'components/Dashboard/FollowupStatuses';
import InvoiceDisputesCard from 'components/Dashboard/InvoiceDisputes';
import InvoiceStatusCard from 'components/Dashboard/InvoiceStatus';
import PendingIssues from 'components/Dashboard/PendingIssues';
import TotalBalanceTile from 'components/Dashboard/TotalBalanceTile';
import TotalCollectedTile from 'components/Dashboard/TotalCollectedTile';
import TotalOutstandingTile from 'components/Dashboard/TotalOutstandingTile';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { useTotalBalanceTileQuery, useTotalOutstandingQuery } from 'queries/admin-dashboard';
import { cei_tile } from 'services/dashboard-datasets';
import styled from 'styled-components';
import { AccountConfigKey } from 'types/entities/account';
import { IS_DEMO } from 'util/http-utils';
import { DashboardPostFilterParams } from '../../types';

const ceiTile = cei_tile;

interface SnapShotProps {
  snapshotFilterParams: DashboardPostFilterParams;
}

interface StyledCardProps {
  disabledPadding?: boolean;
}

export const StyledCard = styled(Card)<StyledCardProps>`
  border: 0;
  box-shadow: var(--shadow-2);
  border-radius: var(--br-3);

  .ant-card-body {
    padding: ${(props) => (props.disabledPadding ? 0 : 'var(--space-24)')};
  }
`;

export function SnapshotView(props: SnapShotProps) {
  const { snapshotFilterParams } = props;
  const showBadDebt = useConfig(AccountConfigKey.SHOW_BAD_DEBT_TILE);

  const { data: totalBalance, isLoading: totalBalanceLoading } =
    useTotalBalanceTileQuery(snapshotFilterParams);
  const { data: totalOutstandingAmount, isLoading: totalOutstandingLoading } =
    useTotalOutstandingQuery(snapshotFilterParams);

  const TotalCollectionsBadDebtDso = (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ErrorBoundaryWrapper>
          <TotalCollectedTile filterParams={snapshotFilterParams} />
        </ErrorBoundaryWrapper>
      </Col>
      <HideWrapper hide={!showBadDebt}>
        <Col span={IS_DEMO ? 12 : 24}>
          <ErrorBoundaryWrapper>
            <BadDebtTile filterParams={snapshotFilterParams} />
          </ErrorBoundaryWrapper>
        </Col>
      </HideWrapper>
      {IS_DEMO && ceiTile && (
        <Col span={!showBadDebt ? 24 : 12}>
          <CEITile />
        </Col>
      )}
      <Col span={24}>
        <ErrorBoundaryWrapper>
          <DSOTile filterParams={snapshotFilterParams} />
        </ErrorBoundaryWrapper>
      </Col>
    </Row>
  );

  const TotalCollectionsBalanceOutStanding = (
    <>
      <Col flex="1 1 33%">
        <ErrorBoundaryWrapper>
          <TotalBalanceTile totalOutstanding={totalBalance} loading={totalBalanceLoading} />
        </ErrorBoundaryWrapper>
      </Col>
      <Col flex="1 1 33%">
        <ErrorBoundaryWrapper>
          <TotalOutstandingTile
            totalOutstanding={totalOutstandingAmount}
            loading={totalOutstandingLoading}
            filterParams={snapshotFilterParams}
          />
        </ErrorBoundaryWrapper>
      </Col>
      <Col flex="1 1 33%">{TotalCollectionsBadDebtDso}</Col>
    </>
  );

  const AgingFollowupChart = (
    <>
      <Col span={16}>
        <StyledCard style={{ height: '100%' }}>
          <ErrorBoundaryWrapper>
            <Aging filterParams={snapshotFilterParams} />
          </ErrorBoundaryWrapper>
        </StyledCard>
      </Col>
      <Col span={8}>
        <InvoiceStatusCard snapshotFilterParams={snapshotFilterParams} />
      </Col>
    </>
  );

  const CollectionsAndInvoiceCharts = (
    <>
      <Col span={12}>
        <CollectionStatusCard snapshotFilterParams={snapshotFilterParams} />
      </Col>
      <Col span={12}>
        <InvoiceDisputesCard snapshotFilterParams={snapshotFilterParams} />
      </Col>
    </>
  );

  const FollowupActivities = (
    <>
      <Col span={12}>
        <FollowUpStatusCard snapshotFilterParams={snapshotFilterParams} />
      </Col>
      <Col span={12}>
        <FollowUpActivitiesCard snapshotFilterParams={snapshotFilterParams} />
      </Col>
    </>
  );

  const CollectionsTable = (
    <>
      <Col span={12}>
        <StyledCard>
          <ErrorBoundaryWrapper>
            <PendingIssues filterParams={snapshotFilterParams} />
          </ErrorBoundaryWrapper>
        </StyledCard>
      </Col>
      <Col span={12}>
        <StyledCard>
          <ErrorBoundaryWrapper>
            <FlaggedInvoices filterParams={snapshotFilterParams} />
          </ErrorBoundaryWrapper>
        </StyledCard>
      </Col>
    </>
  );

  const DelinquenciesTable = (
    <Col span={24}>
      <StyledCard>
        <ErrorBoundaryWrapper>
          <Delinquencies filterParams={snapshotFilterParams} />
        </ErrorBoundaryWrapper>
      </StyledCard>
    </Col>
  );

  return (
    <Flex direction="column" gap="var(--space-16)">
      <Row gutter={[16, 16]}>{TotalCollectionsBalanceOutStanding}</Row>
      <Row gutter={[16, 16]}>{AgingFollowupChart}</Row>
      <Row gutter={[16, 16]}>{CollectionsAndInvoiceCharts}</Row>
      <Row gutter={[16, 16]}>{FollowupActivities}</Row>
      <Row gutter={[16, 16]}>{CollectionsTable}</Row>
      <Row gutter={[16, 16]}>{DelinquenciesTable}</Row>
    </Flex>
  );
}
