import React, { FocusEventHandler } from 'react';
import { Input } from 'antd';

interface IMentionsComponent {
  value?: string;
  onChange?: (comment: string) => void;
  placeholder?: string;
  onBlur?: FocusEventHandler<any>;
  autoSize?:
    | boolean
    | {
        minRows: number;
        maxRows: number;
      };
}

const TextArea = (props: IMentionsComponent) => {
  const { TextArea } = Input;

  const handleChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  return (
    <TextArea
      onBlur={props.onBlur}
      autoSize={
        props.autoSize ?? {
          minRows: 4,
          maxRows: 5,
        }
      }
      style={{ width: '100%' }}
      placeholder={props.placeholder ?? 'Start typing...'}
      defaultValue={props.value}
      onChange={handleChange}
    />
  );
};

export default TextArea;
