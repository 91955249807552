import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reducers/rootReducer';

const invoiceListStateSelector = (state: RootState) => state.invoiceReducerV2;

const openInvoiceCurrentFilterViewSelector = createSelector(
  invoiceListStateSelector,
  (currentState) => currentState.openInvoiceCurrentFilterView
);

const openInvoiceSavedFilterViewsSelector = createSelector(
  invoiceListStateSelector,
  (currentState) => currentState.openInvoiceSavedFilterviews
);

const openInvoiceCurrentFilterSelector = createSelector(
  invoiceListStateSelector,
  (currentState) => currentState.openInvoiceCurrentFilterView?.invoice_filter
);

const openInvoiceQuerParams = createSelector(
  invoiceListStateSelector,
  (currentState) => currentState.openInvoiceFilterQueryParams
);
const totalCustomerRecordsSelector = createSelector(
  invoiceListStateSelector,
  (currentState) => currentState.totalCustomerCount
);
const openCurrentPage = (state: RootState) => state.invoiceReducerV2.openCurrentPage;

export {
  openInvoiceCurrentFilterSelector,
  openInvoiceCurrentFilterViewSelector,
  openInvoiceQuerParams,
  openInvoiceSavedFilterViewsSelector,
  totalCustomerRecordsSelector,
  openCurrentPage,
};
