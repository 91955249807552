import { ColGroupProps, ColProps, SortOrder, getSort } from '@sinecycle/growcomponents';
import { head } from 'lodash';

export function getPersistedColumnPredicate<T>(
  colConfig: ColProps<T>,
  col?: string,
  by?: SortOrder
): ColProps<T> {
  if (colConfig.colId === col) {
    return { ...colConfig, sort: head(getSort(by)) as ColProps<T>['sort'] };
  }

  return { ...colConfig, sort: null };
}

export function getPersistedColumnGroupPredicate<T>(
  colConfig: ColProps<T> & ColGroupProps<T>,
  col?: string,
  by?: SortOrder,
  customColId?: number
): ColProps<T> & ColGroupProps<T> {
  if (!colConfig.colId && colConfig.groupId === col && colConfig?.children?.length) {
    const modifiedChildren = colConfig.children.map((childeCol: ColProps<T>) => {
      return childeCol.sortable
        ? { ...childeCol, sort: head(getSort(by)) as ColProps<T>['sort'] }
        : childeCol;
    });

    return { ...colConfig, children: modifiedChildren };
  }
  const customFieldCol = `${col}_${customColId}`;

  return colConfig.colId === col || colConfig.colId === customFieldCol
    ? { ...colConfig, sort: head(getSort(by)) as ColProps<T>['sort'] }
    : colConfig.children
    ? {
        ...colConfig,
        children: colConfig.children.map((childeCol: ColProps<T>) => {
          return childeCol.sortable && childeCol.colId === col
            ? { ...childeCol, sort: head(getSort(by)) as ColProps<T>['sort'] }
            : { ...childeCol, sort: null };
        }),
      }
    : { ...colConfig, sort: null };
}

export function getPersistedChildColumnGroupPredicate<T>(
  colConfig: ColProps<T> & ColGroupProps<T>,
  col?: string,
  by?: SortOrder
): ColProps<T> & ColGroupProps<T> {
  if (colConfig.children) {
    const modifiedChildren = colConfig.children.map((column: ColProps<T>) => {
      return column.colId === col
        ? {
            ...column,
            sort: head(getSort(by)) as ColProps<T>['sort'],
          }
        : { ...column, sort: null };
    });

    return {
      ...colConfig,
      children: modifiedChildren,
    };
  }

  if (colConfig.colId === col) {
    return {
      ...colConfig,
      sort: head(getSort(by)) as ColProps<T>['sort'],
    };
  }

  return { ...colConfig, sort: null };
}
