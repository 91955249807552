import {
  CallLogFormPayloadSchema,
  DisputeFormPayloadSchema,
  DocumentFormPayloadSchema,
  EscalationFormPayloadSchema,
  NoteFormPayloadSchema,
  PTPFormPayloadSchema,
  TaskFormPayloadSchema,
  TaskRemainderFormPayloadSchema,
} from 'components/Common/ActivitiesForm/predicate';
import { assign } from 'lodash';
import { ActivityType } from 'types/activities/activity-types';

export const ActivitySchema = {
  [ActivityType.DISPUTE]: DisputeFormPayloadSchema,
  [ActivityType.CALL_LOG]: assign({}, CallLogFormPayloadSchema, { customer_ids: 'customer_ids' }),
  [ActivityType.PROMISE_TO_PAY]: assign({}, PTPFormPayloadSchema, { type: 'type' }),
  [ActivityType.NOTE]: assign({}, NoteFormPayloadSchema, {
    ref_id_list: 'ref_id_list',
    type: 'type',
  }),
  [ActivityType.DOCUMENT]: DocumentFormPayloadSchema,
  [ActivityType.ESCALATION]: assign({}, EscalationFormPayloadSchema, {
    customer_id: 'customer_id',
  }),
  [ActivityType.TASK]: assign({}, TaskFormPayloadSchema, {
    customer_ids: 'customer_ids',
  }),
  TASK_REMAINDER: assign({}, TaskRemainderFormPayloadSchema, {
    associated_activity_id: 'associated_activity_id',
    associated_activity_type: 'associated_activity_type',
    customer_ids: 'customer_ids',
    description: 'description',
    invoice_ids: 'invoice_ids',
  }),
};

export const invoiceTransformSchema = {
  id: 'id',
  invoiceNo: 'invoice_no',
  invoiceAmount: ['invoice_amount', 'amount_base_currency'],
  invoiceStatus: 'invoice_status',
  customerName: ['customer_name', 'customer.name'],
};

export const ptpInvoiceTransformSchema = {
  id: 'id',
  invoiceNo: 'invoice_no',
  invoiceAmount: ['invoice_amount', 'amount_base_currency'],
  invoiceStatus: 'invoice_status',
  invoiceCurrency: 'currency',
  ptpAmount: 'promise_to_pay_amount',
  balanceDueAmount: 'balance_due_amount',
  customerName: 'customer_name',
};
