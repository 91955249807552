import produce from 'immer';
import isEqual from 'lodash/isEqual';
import {
  CustomFieldFilterValues,
  CustomFieldOperatorType,
  customFieldFilterPredicate,
} from 'types/entities/custom-field';
import { ArrayValues, PrimitiveValues } from 'types/filter-view/closed-invoice';
import { CustomerContact } from 'types/page-filter';
import { replaceOrPushInArray } from 'util/array';

export type ArrayKeys = keyof ArrayValues;
type ArrayValue<T extends ArrayKeys> = ArrayValues[T];

export type PrimitiveKeys = keyof PrimitiveValues;
type PrimitiveValue<T extends PrimitiveKeys> = PrimitiveValues[T];

export interface FilterValues extends ArrayValues, PrimitiveValues {}

export const initialFilterValues: FilterValues = {};

export type updateArrayPayload<T extends ArrayKeys> = {
  key: T;
  value: ArrayValue<T>;
};

export type updatePrimitivePayload<T extends PrimitiveKeys> = {
  key: T;
  value: PrimitiveValue<T>;
};

type FilterValuesAction =
  | {
      type: 'UPDATE_INITIAL_VALUE';
      payload: FilterValues;
    }
  | {
      type: 'UPDATE_ARRAY';
      payload: updateArrayPayload<ArrayKeys>;
    }
  | {
      type: 'UPDATE_PRIMITIVES';
      payload: Array<updatePrimitivePayload<PrimitiveKeys>>;
    }
  | {
      type: 'UPDATE_CUSTOM_FIELDS';
      payload: CustomFieldFilterValues<CustomFieldOperatorType>;
    }
  | {
      type: 'UPDATE_CUSTOMER_CONTACTS';
      payload: CustomerContact;
    };

export function filterValuesReducer(filterValues: FilterValues, action: FilterValuesAction) {
  function updateInitialValue(updatedInitialValue: FilterValues) {
    return produce(filterValues, (draft) => {
      return updatedInitialValue;
    });
  }

  function updateArray<T extends ArrayKeys>({ key, value }: updateArrayPayload<T>) {
    return produce(filterValues, (draft) => {
      if (isEqual(draft[key], value)) return;

      if (value && value.length) {
        draft[key] = value;
      }

      if (value && !value.length) {
        delete draft[key];
      }
    });
  }

  function updatePrimitives<T extends PrimitiveKeys>(
    updatedValues: Array<updatePrimitivePayload<T>>
  ) {
    return produce(filterValues, (draft) => {
      updatedValues.forEach(({ key, value }) => {
        if (isEqual(draft[key], value)) return;

        if (typeof value === 'undefined' || !value) {
          /**
           * removing the value from the filter values
           * to ensure clean data
           */

          delete draft[key];
        }

        draft[key] = value;
      });
    });
  }
  function updateCustomFields(customField: CustomFieldFilterValues<CustomFieldOperatorType>) {
    const predicate = (predicateCustomField: CustomFieldFilterValues<CustomFieldOperatorType>) =>
      customField.id === predicateCustomField.id;

    return produce(filterValues, (draft) => {
      const newCustomFieldsArray = replaceOrPushInArray(
        draft.custom_fields ?? [],
        customField,
        predicate
      ).filter(customFieldFilterPredicate);

      if (isEqual(draft.custom_fields, newCustomFieldsArray)) return;

      draft.custom_fields = newCustomFieldsArray;
    });
  }

  function updateCustomerContacts(customerContact: CustomerContact) {
    const predicate = (contact: CustomerContact) =>
      contact?.category_id === customerContact?.category_id;

    return produce(filterValues, (draft) => {
      const newCustomerContactArray = replaceOrPushInArray(
        draft.user_association ?? [],
        customerContact,
        predicate
      ).filter((contact) => !!contact.associated_user_ids?.length);

      if (isEqual(draft.user_association, newCustomerContactArray)) return;

      draft.user_association = newCustomerContactArray;
    });
  }

  switch (action.type) {
    case 'UPDATE_INITIAL_VALUE':
      return updateInitialValue(action.payload);
    case 'UPDATE_ARRAY':
      return updateArray(action.payload);
    case 'UPDATE_PRIMITIVES':
      return updatePrimitives(action.payload);
    case 'UPDATE_CUSTOMER_CONTACTS':
      return updateCustomerContacts(action.payload);
    case 'UPDATE_CUSTOM_FIELDS':
      return updateCustomFields(action.payload);
  }
}
