import { ReactNode } from 'react';
import styled from 'styled-components';

const EmailStructure = styled.div<{ isEmail?: boolean }>`
  .structure-header {
    position: absolute;
    width: 100%;
    z-index: 2;
    margin: ${(props) =>
      props.isEmail
        ? 'calc(-1 * var(--space-24)) calc(-1 * var(--space-24)) var(--space-16)'
        : 'initial'};
  }

  .structure-body {
    height: calc(100% - 80px);
    overflow: auto;
    overflow-x: 'hidden';
    padding: calc((var(--space-32) + var(--space-16))) 0;
  }

  .structure-footer {
    background: var(--gray-2);
    bottom: 0;
    position: absolute;
    width: 100%;
    left: 0;
    padding: var(--space-12) var(--space-20);

    // due to => .DraftEditor-editorContainer { z-index: 1 }
    z-index: 2;

    & > div {
      flex: 1;
      align-self: center;
    }
  }
`;

interface IEmailSliderStructure {
  [x: string]: ReactNode;
  isEmail?: boolean;
}

const EmailSliderStructure = (props: IEmailSliderStructure) => {
  return (
    <EmailStructure isEmail={props.isEmail}>
      <div className="structure-header">{props.header}</div>
      <div className="structure-body">{props.children}</div>
      <div className="structure-footer">{props.footer}</div>
    </EmailStructure>
  );
};

export default EmailSliderStructure;
