import { Empty } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import React from 'react';
import { useSelector } from 'react-redux';
import { ReceivablePlaceHolder } from 'store/email/types';
import { folderSelector } from 'store/insert';
import { EmailTemplates, FolderType } from 'types/entities/email-templates';
import { Placeholders } from './Placeholders';
import { PlaceholderComponentWrapper, StyledEmptyAntComponent, overrideStyles } from './style';

export interface InsertProps {
  onInsert: (value: string) => void;
  cancel: () => void;
  folderType?: FolderType;
  onTemplateSelect?: (selectedTemplate: EmailTemplates) => void;
  validFolders?: FolderType[];
  placeHoldersWrapperOverrides?: React.CSSProperties;
  className?: string;
  show?: boolean;
  emailFor?: ActionableEntity;
  ignoredInvoiceGroups?: ReceivablePlaceHolder[];
}

function Insert(props: InsertProps) {
  const folders = useSelector(folderSelector);

  const EmptyComponent = <StyledEmptyAntComponent image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  const InsertPlaceholdersComponent = (
    <Placeholders
      onInsert={props.onInsert}
      cancel={props.cancel}
      sideBarOverrideStyles={overrideStyles}
      style={{ boxShadow: 'unset' }}
      validFolders={props.validFolders}
      folderType={props.folderType}
    />
  );

  const PlaceholderComponent = folders.length
    ? InsertPlaceholdersComponent
    : EmptyComponent;

  return (
    <PlaceholderComponentWrapper>
      <Flex>{PlaceholderComponent}</Flex>
    </PlaceholderComponentWrapper>
  );
}

export { Insert };
