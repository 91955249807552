import { createSlice } from '@reduxjs/toolkit';
import {
  filterModifyInvoices,
  filterRemovedInvoices,
  resetModifiedAmount,
  resetModifyInvoices,
  setPeriodDetails,
  updatedModifyInvoices,
  updateModifiedAmount,
  updateRemovedInvoices,
  updateShowRemovedInvoice,
} from './reducers';
import { ModifyCollectionTarget } from './types';

export const initialState: ModifyCollectionTarget = {
  periodDetails: {
    current_target: {
      value: 0,
      currency: 'USD',
    },
    removed_invoices: [],
    targeted_inv_count: 0,
    removed_inv_count: 0,
  },
  modifyInvoicesData: [],
  removedInvoices: [],
  showRemovedInvoices: false,
  removed_invoicesIds: [],
};

const ModifyTargetSlice = createSlice({
  name: 'modifyCollectionTarget',
  initialState,
  reducers: {
    updatedModifyInvoices,
    filterModifyInvoices,
    resetModifyInvoices,
    updateShowRemovedInvoice,
    filterRemovedInvoices,
    updateModifiedAmount,
    resetModifiedAmount,
    setPeriodDetails,
    updateRemovedInvoices,
  },
});

export const modifyTargetActions = ModifyTargetSlice.actions;

export default ModifyTargetSlice.reducer;
