import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initial-state';
import {
  addInvoiceSegmentFormFilter,
  cloneInvoiceSegment,
  removeInvoiceSegment,
  removeInvoiceSegmentFormFilter,
  saveInvoiceSegment,
  setCurrentActiveSegment,
  setErrors,
  setInvoiceSegmentFormFromList,
  setInvoiceSegmentFormOperator,
  setInvoiceSegmentFormTitle,
  setInvoiceSegments,
  setShowErrors,
  toggleManagementModal,
  updateInvoiceSegmentFormFilter,
} from './reducers';

const invoiceSegmentManagementSlice = createSlice({
  name: 'invoice-segment-management',
  initialState: initialState,
  reducers: {
    toggleManagementModal,
    setCurrentActiveSegment,
    saveInvoiceSegment,
    removeInvoiceSegment,
    setInvoiceSegmentFormFromList,
    setInvoiceSegmentFormTitle,
    setInvoiceSegmentFormOperator,
    addInvoiceSegmentFormFilter,
    removeInvoiceSegmentFormFilter,
    updateInvoiceSegmentFormFilter,
    setInvoiceSegments,
    cloneInvoiceSegment,
    setShowErrors,
    setErrors,
  },
});

export const invoiceSegmentManagementActions = invoiceSegmentManagementSlice.actions;
export default invoiceSegmentManagementSlice.reducer;
