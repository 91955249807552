import { GrowCurrencyText, GrowNumberFormat, GrowTypography } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { ChartTooltip } from 'components/Common/Charts/types';
import { getPercentage } from 'util/number-formatter';
import { AgingBucketData } from '../type';

interface AgingBucketTooltipContent extends ChartTooltip {
  data: AgingBucketData;
}

interface TooltipProps {
  content: AgingBucketTooltipContent[];
  locale?: string;
}

function Tooltip(props: TooltipProps) {
  const t = {
    Invoices: 'Invoices',
    Days: 'days',
  };
  const percentage = getPercentage(props.content[0].data.value, props.content[0].data.totalAmount);

  const invoices = (
    <Flex direction="column" gap="var(--space-2)">
      <GrowTypography.Text fs="12" style={{ color: 'var(--gray-7)' }}>
        {t.Invoices}
      </GrowTypography.Text>
      <GrowNumberFormat
        value={props.content[0].data.invoices}
        options={{ locale: props.locale }}
        textProps={{ fs: '12' }}
      />
    </Flex>
  );

  const amount = (
    <GrowCurrencyText
      amount={props.content[0].data.value}
      options={{ currency: props.content[0].data.currency, locale: props.locale }}
    />
  );

  const tooltipHeader = (
    <Flex direction="column" gap="var(--space-4)">
      <Flex align="center" gap="var(--space-4)">
        <div style={{ background: props.content[0].data.type, width: 10.5, height: 10.5 }} />
        <Texto size="12" color="var(--gray-7)">
          {`${props.content[0].data.age} ${t.Days}`}
        </Texto>
      </Flex>
      <Texto size="12" color="var(--gray-9)">
        {percentage}
      </Texto>
    </Flex>
  );

  const tooltipBody = (
    <Flex direction="column" gap="var(--space-12)">
      {amount}
      {invoices}
    </Flex>
  );

  return (
    <Flex direction="column" gap="var(--space-8)" className="custom-tooltip">
      {tooltipHeader}
      {tooltipBody}
    </Flex>
  );
}

export default Tooltip;
