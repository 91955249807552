import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { amountFormats } from '@sinecycle/growcomponents';
import { Card, Popconfirm } from 'antd';
import { PopConfirmContent } from 'components/BaseComponents/ButtonWithConfirmation/PopconfirmContent';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';
import styled from 'styled-components';
import { InvoiceFollowupAction } from '.';

const t = {
  warning_desc: 'Contents of the Email would be reset, do you still want to switch?',
  warning_title: 'Warning',
  OkText: 'Confirm',
  CancelText: 'Cancel',
};

const StyledCard = styled(Card)`
  cursor: pointer;
  width: 100%;
  border-radius: var(--br-3);

  &.selected {
    box-shadow: var(--shadow-2);
    border-color: var(--purple-6);
    pointer-events: none;
  }

  &:hover {
    box-shadow: var(--shadow-1);
  }
`;
interface BulkActionFollowUpProps {
  invoiceCount?: number;
  customersCount?: number;
  title: string;
  selected: boolean;
  desc: string;
  actionKey: InvoiceFollowupAction;
  onSelectCallBack: (key: InvoiceFollowupAction) => void;
}

const titleStyles: React.CSSProperties = {
  fontSize: 'var(--fs-16)',
  fontWeight: 'var(--fs-semibold)',
};
const descStyles: React.CSSProperties = {
  fontSize: 'var(--fs-14)',
  color: 'var(--gray-9)',
  maxWidth: '250px',
  lineHeight: 'normal',
};

const okButtonStyles: React.CSSProperties = {
  background: 'var(--purple-7)',
  borderRadius: 'var(--br-1)',
};

const ConfirmHeaderTitle = (
  <PopConfirmContent
    title={t.warning_title}
    desc={t.warning_desc}
    titleStyles={{ color: 'var(--gold-6)', fontWeight: 'var(--fs-semibold)' }}
    descStyles={{ color: 'var(--gray-9)' }}
  />
);
function BulkActionFollowUp(props: BulkActionFollowUpProps) {
  const ref = useRef<HTMLDivElement>(null);
  const locale = useSelector(localeSelector);
  const CheckIconComp = (
    <div>
      {props.selected ? (
        <FontAwesomeIcon icon={['fas', 'check-circle']} color="var(--purple-7)" size="lg" />
      ) : (
        <FontAwesomeIcon icon={['fal', 'check-circle']} color="var(--gray-6)" size="lg" />
      )}
    </div>
  );

  const FollowupInformation = (
    <div
      style={{
        fontSize: 'var(--fs-14)',
        marginTop: 'var(--space-8)',
        color: props.selected ? 'var(--purple-7)' : 'var(--gray-7)',
      }}
    >{`${
      props.actionKey === InvoiceFollowupAction.INDIVIDUAL_INVOICE
        ? amountFormats.number(props.invoiceCount, { locale })
        : amountFormats.number(props.customersCount, { locale })
    } emails for ${amountFormats.number(props.customersCount, { locale })} customers`}</div>
  );

  const TitleAndDesc = (
    <>
      <div
        style={{
          ...titleStyles,
          color: props.selected ? 'var(--purple-7)' : 'var(--purple-10)',
        }}
      >
        {props.title}
      </div>
      <div style={{ ...descStyles }}>{props.desc}</div>
    </>
  );

  const handleConfirm = () => {
    props.onSelectCallBack(props.actionKey);
  };
  return (
    <Popconfirm
      title={ConfirmHeaderTitle}
      cancelText={t.CancelText}
      okText={t.OkText}
      disabled={props.selected}
      onConfirm={handleConfirm}
      placement="bottom"
      overlayStyle={{ width: '300px' }}
      icon={false}
      overlayClassName="ant-popover-title-no-padding"
      okButtonProps={{ style: okButtonStyles }}
      getPopupContainer={() => ref.current as HTMLElement}
      showArrow
    >
      <StyledCard className={props.selected ? 'selected' : 'not-selected'} ref={ref}>
        <Flex justify="space-between" align="flex-start">
          <Flex direction="column" gap="var(--space-4)">
            {TitleAndDesc}
            {FollowupInformation}
          </Flex>
          {CheckIconComp}
        </Flex>
      </StyledCard>
    </Popconfirm>
  );
}

export { BulkActionFollowUp };
