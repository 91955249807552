import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';

const BackIcon = (
  <FontAwesomeIcon icon={['far', 'arrow-left']} color="var(--primary-7)" fontSize={12} />
);

export default function useActivityBackButton() {
  const { entitySearchParams, removeEntitySearchParams } = useEntitySearchParams();

  function ActivityBackButton() {
    return (
      <HideWrapper hide={!entitySearchParams[EActivityQueryParams.ASSOCIATED_ACTIVITY]}>
        <ActivityIconButton
          icon={BackIcon}
          size="small"
          onClick={() =>
            removeEntitySearchParams([
              EActivityQueryParams.ASSOCIATED_ACTIVITY,
              EActivityQueryParams.ASSOCIATED_ACTIVITY_TYPE,
              EActivityQueryParams.ASSOCIATED_ACTIVITY_ID,
            ])
          }
          hideBackground
        />
      </HideWrapper>
    );
  }

  return ActivityBackButton;
}
