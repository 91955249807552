export interface IHeap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

const HeapWrapper = {
  identify(identity: string) {
    window.heap.identify(identity);
  },
  addUserProperties(properties: Object) {
    window.heap.addUserProperties(properties);
  },
  clearEventProperties() {
    window.heap.clearEventProperties();
  },
};

export default HeapWrapper;
