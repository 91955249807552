import React, { useEffect, useRef } from 'react';

interface Options<T> {
  ref?: React.MutableRefObject<T>;
  alignToTop?: boolean;
}
export function useScrollIntoView<T extends HTMLElement>(options?: Options<T>) {
  const { ref: passedRef, alignToTop } = options ?? {};
  const newRef = useRef<T>(null);
  const isScrolled = useRef(false);

  const refToUse = passedRef ?? newRef;
  const optionsToUse: boolean = alignToTop ?? true;

  useEffect(() => {
    if (refToUse.current) {
      if (!isScrolled.current) {
        refToUse.current.scrollIntoView(optionsToUse);
        isScrolled.current = true;
      }
    } else {
      isScrolled.current = false;
    }
  });

  return refToUse;
}
