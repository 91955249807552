import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'reducers/rootReducer';
import {
  ActivityStatus,
  ActivitySubcriptionType,
  InboxActivitiesFilter,
  InboxActivityType,
  SubscribedEntityType,
} from 'types/api/inbox/activity';
import { replaceInArray } from 'util/array';

interface InboxItems {
  collectionActivities: SubscribedEntityType[];
  totalUnreadAssignedActivities: number;
  totalUnreadSubscribedActivities: number;
  activitiesFilter: InboxActivitiesFilter;
}

export const inboxInitialState: InboxItems = {
  collectionActivities: [],
  totalUnreadAssignedActivities: 0,
  totalUnreadSubscribedActivities: 0,
  activitiesFilter: {
    assignmentTypes: [],
    assignmentStatus: ActivityStatus.ALL,
  },
};

export enum UpdateUnreadCount {
  INCREMENT,
  DECREMENT,
}

type UpdateCountPayload = {
  type: UpdateUnreadCount;
  activitySubscriptionType?: ActivitySubcriptionType;
};

type UpdateActivityPayload = {
  id: number;
  updatedValue: Partial<SubscribedEntityType>;
};

const inboxSlice = createSlice({
  name: 'inboxActivities',
  initialState: inboxInitialState,
  reducers: {
    setCollectionActivites(state, action: PayloadAction<SubscribedEntityType[]>) {
      state.collectionActivities = action.payload;
    },
    setTotalUnreadAssignedActivitiesCount(state, action: PayloadAction<number>) {
      state.totalUnreadAssignedActivities = action.payload;
    },
    setTotalUnreadSubscribedActivitiesCount(state, action: PayloadAction<number>) {
      state.totalUnreadSubscribedActivities = action.payload;
    },
    updateUnreadCollectionActivitiesCount(state, action: PayloadAction<UpdateCountPayload>) {
      const { type, activitySubscriptionType } = action.payload;

      if (activitySubscriptionType === ActivitySubcriptionType.ASSIGNED) {
        if (type === UpdateUnreadCount.INCREMENT) {
          state.totalUnreadAssignedActivities += 1;
        } else if (type === UpdateUnreadCount.DECREMENT) {
          state.totalUnreadAssignedActivities =
            state.totalUnreadAssignedActivities > 0 ? state.totalUnreadAssignedActivities - 1 : 0;
        }
      } else {
        if (type === UpdateUnreadCount.INCREMENT) {
          state.totalUnreadSubscribedActivities += 1;
        } else if (type === UpdateUnreadCount.DECREMENT) {
          state.totalUnreadSubscribedActivities = Math.max(
            state.totalUnreadSubscribedActivities - 1,
            0
          );
        }
      }
    },
    updateCollectionActivity(state, action: PayloadAction<UpdateActivityPayload>) {
      const { id, updatedValue } = action.payload;

      const predicate = (assignedActivity: SubscribedEntityType) =>
        assignedActivity.user_activity_id === id;

      const activityToUpdate = state.collectionActivities.find(predicate);

      if (!activityToUpdate) return;

      const finalUpdatedValue = {
        ...activityToUpdate,
        ...updatedValue,
      } as SubscribedEntityType;

      state.collectionActivities = replaceInArray<SubscribedEntityType>(
        state.collectionActivities,
        finalUpdatedValue,
        predicate
      );
    },
    updateActivityFilter(state, action: PayloadAction<InboxActivitiesFilter>) {
      state.activitiesFilter = action.payload;
    },
    updateAssignmentTypes(state, action: PayloadAction<InboxActivityType[]>) {
      state.activitiesFilter.assignmentTypes = action.payload;
    },
    updateAssignmentStatus(state, action: PayloadAction<ActivityStatus>) {
      state.activitiesFilter.assignmentStatus = action.payload;
    },
  },
});

const inboxActivitiesSelector = (state: RootState) => state.inboxActivities;

const inboxAssignedActivitiesUnreadCountSelector = createSelector(
  inboxActivitiesSelector,
  (inboxActivities) => inboxActivities.totalUnreadAssignedActivities
);

const inboxSubscribedActivitiesUnreadCountSelector = createSelector(
  inboxActivitiesSelector,
  (inboxActivities) => inboxActivities.totalUnreadSubscribedActivities
);

export const collectionActivitiesSelector = createSelector(
  inboxActivitiesSelector,
  (inboxActivities) => inboxActivities.collectionActivities
);

export const assignedActivitiesSelector = createSelector(
  collectionActivitiesSelector,
  (collectionActivities: SubscribedEntityType[]) =>
    collectionActivities.filter(
      (collectionActivity) =>
        collectionActivity.subscription_type === ActivitySubcriptionType.ASSIGNED
    )
);

export const subscribedActivitiesSelector = createSelector(
  collectionActivitiesSelector,
  (collectionActivities: SubscribedEntityType[]) =>
    collectionActivities.filter(
      (collectionActivity) =>
        collectionActivity.subscription_type !== ActivitySubcriptionType.ASSIGNED
    )
);

export const unreadAssingedActivitiesCountSelector = createSelector(
  inboxAssignedActivitiesUnreadCountSelector,
  (unreadCount: number) => unreadCount
);

export const unreadSubscribdeActivitiesCountSelector = createSelector(
  inboxSubscribedActivitiesUnreadCountSelector,
  (unreadCount: number) => unreadCount
);

export const activitiesFiltersSelector = createSelector(
  inboxActivitiesSelector,
  (inboxActivities) => inboxActivities.activitiesFilter
);
export const assignmentTypesSelector = createSelector(
  activitiesFiltersSelector,
  (activitiesFilters) => activitiesFilters.assignmentTypes
);
export const assignmentStatusSelector = createSelector(
  activitiesFiltersSelector,
  (activitiesFilters) => activitiesFilters.assignmentStatus
);

export const {
  setCollectionActivites,
  setTotalUnreadSubscribedActivitiesCount,
  setTotalUnreadAssignedActivitiesCount,
  updateUnreadCollectionActivitiesCount,
  updateCollectionActivity,
  updateAssignmentTypes,
  updateAssignmentStatus,
  updateActivityFilter,
} = inboxSlice.actions;

export default inboxSlice.reducer;
