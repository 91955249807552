import { BaseFilterBlockProps } from '../FilterBlocks/types';
import { FilterDropdown } from '../FilterDropdown';
import { FilterConfig, FilterType } from '../types';
import { useGenerateOptions } from './hooks/useGenerateOptions';
import { FilterConfigWrapperProps } from './type';

interface SingleSelectFilterBlockProps<T> extends BaseFilterBlockProps<T> {
  filterConfig: FilterConfigWrapperProps;
}
type Arraykey = string;
const t = { empty_text: 'All' };

export function SingleSelectWrapper<T extends Arraykey>(props: SingleSelectFilterBlockProps<T>) {
  const { filterConfig, onClear, onSubmit, value, showClear = true } = props;

  const [computedOptions] = useGenerateOptions(filterConfig.options);

  const filterConfigo: FilterConfig = {
    ...filterConfig,
    options: computedOptions,
    type: FilterType.RADIO,
  };

  function handleSubmit(value: T) {
    onSubmit && onSubmit(value);
  }
  function handleClear() {
    onClear && onClear();
    onSubmit && onSubmit([] as unknown as T);
  }

  return (
    <FilterDropdown
      value={value}
      filterConfig={filterConfigo}
      onSubmit={handleSubmit}
      onClear={handleClear}
      showClear={showClear}
      emptyText={t.empty_text}
    />
  );
}
