export enum AR_AGING_EVENT {
  SHOW_STATS = 'AR Aging: Show Stats',
  HIDE_STATS = 'AR Aging: Hide Stats',
  PAGINATION = 'AR Aging Pagination',
  BULK_ACTION = 'AR Aging Bulk Action',
  INDIVIDUAL_ACTION = 'AR Aging Individual Action',
  SORTING = 'AR Aging Sorting',
  DOWNLOAD = 'AR Aging Download',
  CLICK_DOWNLOAD = 'AR Aging Download Click',
}

type AR_AGING_EVENTS_WITH_PROPS_PAYLOAD =
  | { type: AR_AGING_EVENT.PAGINATION; payload: { pageNumber: number; totalPages: number } }
  | { type: AR_AGING_EVENT.BULK_ACTION; payload: { option: string } }
  | { type: AR_AGING_EVENT.INDIVIDUAL_ACTION; payload: { option: string } }
  | { type: AR_AGING_EVENT.SORTING; payload: { column: string } };

type AR_AGING_EVENTS_WITH_PROPS = AR_AGING_EVENTS_WITH_PROPS_PAYLOAD['type'];

type AR_AGING_EVENTS_WITHOUT_PROPS = Exclude<AR_AGING_EVENT, AR_AGING_EVENTS_WITH_PROPS>;

export type AR_AGING_EVENT_TYPES =
  | AR_AGING_EVENTS_WITH_PROPS_PAYLOAD
  | { type: AR_AGING_EVENTS_WITHOUT_PROPS; payload?: undefined };
