import ActivitiesModalLoader from 'components/CollectionActivities/Common/ActivitiesModalLoader';
import { DetailsLayout } from 'components/CollectionActivities/Common/Details/DetailsLayout';
import useEmitActivityEvents from 'components/CollectionActivities/hooks/useEmitActivityEvents';
import { ActivityLoader } from 'components/CollectionActivities/style';
import { useCollectionActivityQuery } from 'queries/activites';
import { RaiseADisputeActivity } from 'store/activity-feed/type';
import { ActivityDetailsBaseProps } from 'types/activities/activity-types';
import { DisputeDetailsBody } from './DisputeDetailsBody';
import { DisputeDetailsInfo } from './DisputeDetailsInfo';
interface DisputeDetailsProps extends ActivityDetailsBaseProps {}
function DisputeDetails(props: DisputeDetailsProps) {
  const {
    id,
    customer,
    type,
    hideActions,
    queryKey,
    mode,
    subscriptionType,
    isListFetched,
    onDelete,
    editLoading,
    deleteLoading,
    activitiesMode,
  } = props;
  const { data, isLoading, error } = useCollectionActivityQuery<RaiseADisputeActivity>({
    id,
    type,
    isListFetched,
  });
  const isUnauthorized = error ? error?.status === 403 : false;
  useEmitActivityEvents(isLoading);
  if (activitiesMode && isLoading) {
    return (
      <ActivityLoader>
        <ActivitiesModalLoader />
      </ActivityLoader>
    );
  }

  return (
    <DetailsLayout
      mode={mode}
      isUnauthorized={isUnauthorized}
      content={
        <DisputeDetailsBody
          data={data}
          isLoading={isLoading}
          customer={customer}
          hideActions={hideActions}
          subscriptionType={subscriptionType}
          onDelete={onDelete}
          editLoading={editLoading}
          deleteLoading={deleteLoading}
          activitiesMode={activitiesMode}
        />
      }
      info={
        <DisputeDetailsInfo
          loading={isLoading}
          data={data}
          queryKey={queryKey}
          customer={customer}
          activitiesMode={activitiesMode}
        />
      }
    />
  );
}

export { DisputeDetails };
