import { Col, Row } from 'antd';
import React from 'react';

import { dashboardTexts } from '../texts';

import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';

import { GrowText } from 'components/BaseComponents/Typography';
import { TotalOutstandingResponse } from 'types/api/admin-dashboard';
import { StyledChildCard } from '../styles';
import TotalCustomersInvoices from '../TotalCustomersInvoices';

interface CurrentDueTileProps {
  loading: boolean;
  totalOutstanding?: TotalOutstandingResponse;
}
function CurrentDueTile({ loading, totalOutstanding }: CurrentDueTileProps) {
  const InputLoaderJSX = <InputLoader active={loading} />;

  const currentDue = totalOutstanding?.current_due;
  const dueAmt = currentDue?.amount;
  const invoicesCount = currentDue?.invoice_count;

  const TotalCustInvoiceInfo = (
    <TotalCustomersInvoices loading={loading} invoicesCount={invoicesCount} showCustomer={false} />
  );

  const DueAmountJSX = dueAmt && (
    <AmountWithCurrencySplit
      type="multi"
      amountWithCurrencySplit={dueAmt}
      className="text-24 semi-bold"
      showTooltip
      formatOptions={{ maximumFractionDigits: 0 }}
    />
  );

  const LabelJSX = (
    <div className="title">
      <GrowText color={'var(--gray-9)'}>{dashboardTexts.currentDue.title}</GrowText>
    </div>
  );

  return (
    <StyledChildCard
      className="current-due-container"
      color="var(--gold-2)"
      style={{ height: 'fit-content' }}
    >
      <div style={{ marginBottom: 'var(--space-12)' }}>
        <Row className="no-wrap" gutter={[16, 16]} wrap>
          <Col span={24}>{LabelJSX}</Col>
        </Row>
        <Row>
          <Col span={24}>
            <GrowText size={'var(--fs-20)'} className="amount">
              {loading ? InputLoaderJSX : DueAmountJSX}
            </GrowText>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>{TotalCustInvoiceInfo}</Col>
      </Row>
    </StyledChildCard>
  );
}

export default React.memo(CurrentDueTile);
