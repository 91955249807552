import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Placeholder } from 'components/Insert/Placeholders/type';
import { capitalize } from 'lodash';
import { RootState } from 'reducers/rootReducer';
import {
  EmailTemplateFoldersProps,
  FolderType,
  RemoveEmailTemplateProps,
} from 'types/entities/email-templates';

const initialState: EmailTemplateFoldersProps = {
  folders: [],
  templates: [],
  placeholders: {
    SINGLE_CUSTOMER: [
      {
        placeholder: '',
        label: '',
        description: '',
        section: '',
        subSection: '',
      },
    ],
    SINGLE_INVOICE: [
      {
        placeholder: '',
        label: '',
        description: '',
        section: '',
        subSection: '',
      },
    ],
    MULTIPLE_INVOICE: [
      {
        placeholder: '',
        label: '',
        description: '',
        section: '',
        subSection: '',
      },
    ],
    OTHERS: [
      {
        placeholder: '',
        label: '',
        description: '',
        section: '',
        subSection: '',
      },
    ],
  },
  custom_fields: [],
};

const insertSlice = createSlice({
  name: 'insert',
  initialState,
  reducers: {
    setInserts(state, action: PayloadAction<EmailTemplateFoldersProps>) {
      state.folders = action.payload.folders ?? [];
      state.templates = action.payload.templates ?? [];
      state.placeholders = action.payload.placeholders ?? {};
      state.custom_fields = action.payload.custom_fields ?? [];
    },
    removeEmailTemplateInsert(state, action: PayloadAction<RemoveEmailTemplateProps>) {
      state.templates = state.templates.map((template) => {
        if (template.id === action.payload.id) {
          return { ...template, deleted: true };
        }
        return template;
      });
      state.folders = state.folders.map((folder) => {
        if (folder.id === action.payload.folderId) {
          return { ...folder, templateCount: folder.templateCount - 1 };
        }
        return folder;
      });
    },
  },
});

/* Selectors */

export const viewPlaceholdersSelector = (state: RootState) =>
  Object.values(state.insert.placeholders).flat();

export const scopedPlaceholdersSelector = (state: RootState) => {
  const customFieldPlaceholders = state.insert.custom_fields.map((customFiled) => {
    return {
      label: customFiled.display_name,
      description: customFiled.display_name,
      placeholder: `${customFiled.placeholder_name}`,
      section: 'Custom Fields',
      subSection: capitalize(customFiled.entity_type),
    };
  });
  return [...Object.values(state.insert.placeholders), ...customFieldPlaceholders]
    .flat()
    .reduce((acc, crr) => {
      acc[crr.placeholder] = crr;
      return acc;
    }, {} as Record<string, Placeholder>);
};

export const insertPlaceholderSelector = (folderKey: FolderType) => (state: RootState) =>
  state.insert.placeholders[folderKey];
export const insertPlaceholdersSelector = (state: RootState) => state.insert.placeholders;

export const folderSelector = (state: RootState) => state.insert.folders;

export const insertOtherPlaceholdersSelector = (state: RootState) =>
  state.insert.placeholders.OTHERS;

export const insertCustomFieldSelector = (state: RootState) =>
  state.insert.custom_fields.map((customFiled) => {
    return {
      label: customFiled.display_name,
      description: customFiled.display_name,
      placeholder: `${customFiled.placeholder_name}`,
      section: 'Custom Fields',
      subSection: capitalize(customFiled.entity_type),
    };
  });

export const customFieldSelector = (state: RootState) => state.insert.custom_fields;

export const insertEmailTemplateSelector = (id: number) => (state: RootState) =>
  state.insert.templates.filter((template) => template.template_folder_id === id);

export const emailTemplatesSelector = (state: RootState) => state.insert.templates;

export const { setInserts, removeEmailTemplateInsert } = insertSlice.actions;

export default insertSlice.reducer;
