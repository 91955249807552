import { getReceivableDisplaySettings } from '../services/receivable-display';
import {
  clearStatusAndMessageHandler,
  getReceivableDisplaySettingsHandler,
} from '../store/receivable-display/receivable-display';
import { ReceivableDisplayResponseState } from '../store/receivable-display/types';
import { AppThunk } from '../store/store';

export const getReceivableDisplayThunk = (): AppThunk<
  Promise<{ payload: ReceivableDisplayResponseState; type: string }>
> => {
  return async (dispatch) => {
    dispatch(clearStatusAndMessageHandler());
    try {
      const data = await getReceivableDisplaySettings();
      return dispatch(getReceivableDisplaySettingsHandler({ status: 200, data }));
    } catch (err: any) {
      return dispatch(getReceivableDisplaySettingsHandler({ status: 500, message: err.message }));
    }
  };
};
