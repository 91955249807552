import { Descriptions, Divider } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { CommentsBlock } from 'components/CollectionActivities/Comment/CommentsBlock';
import { ActionButtons } from 'components/CollectionActivities/Common/Details/ActionButons/ActionButtons';
import {
  AttachmentsBlock,
  InvoicesBlock,
  LabelBlock,
  LineItemBlock,
  TextBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { ViewInvoicesButton } from 'components/CollectionActivities/Common/Details/HeaderButtons';
import { ActivityContent, ActivityTitle } from 'components/CollectionActivities/style';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';

import { GrowText } from 'components/BaseComponents/Typography';
import { OPEN_TYPE, useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { getActivityBg } from 'components/HigherOrderComponent/KeyActivitesContainer/ActivitiesList/Predicate';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';
import { Task } from 'types/activities/task';
import { AssociatedActivity } from '../Common/Details/AssociatedActivity';
import { SubscriptionType } from '../Common/SubscriptionType';
import useGetActivityLink from '../hooks/useGetActivityLink';

interface TaskDetailsProps extends ActivityDetailsBase {
  data?: Task;
  isLoading: boolean;
}

const t = {
  deleteTitle: 'Delete Task',
  deleteDescription: 'Are you sure you want to Delete the Task entry?',
};

function TaskDetailsBody(props: TaskDetailsProps) {
  const {
    hideActions = false,
    data,
    isLoading,
    queryKey,
    customer,
    subscriptionType,
    onDelete,
    editLoading,
    deleteLoading,
    activitiesMode,
  } = props;
  const { cardLabel, Icon } = getActivityUnitItems(ActivityType.TASK);
  const { entitySearchParams } = useEntitySearchParams();
  const ActivityCopyLink = useGetActivityLink(activitiesMode);

  const Title = (
    <ActivityTitle align="center" gap="var(--space-8)">
      <ActivityIconButton
        icon={Icon}
        size="small"
        css={{ '--activityBg': 'var(--magenta-1)' }}
        viewMode
        loading={isLoading}
        hideBackground
      />
      <GrowText>{cardLabel}</GrowText>
      <ActivityCopyLink data={data} customer={customer} type={ActivityType.TASK} />
    </ActivityTitle>
  );

  const Header = (
    <Flex justify="space-between">
      {Title}
      <ActionButtons
        hide={hideActions}
        onDelete={onDelete}
        id={data?.id}
        editLoading={editLoading}
        deleteLoading={deleteLoading}
        title={t.deleteTitle}
        description={t.deleteDescription}
        type={ActivityType.TASK}
      />
    </Flex>
  );

  const InvoiceArea =
    !isLoading && data ? <InvoicesBlock invoices={data.invoices} isBaseCurrency /> : null;
  const LineItem = !isLoading && data ? <LineItemBlock item={data.invoice_line_items} /> : null;

  const Note =
    !isLoading && data ? (
      <Flex direction="column" gap="var(--space-8)">
        <TextBlock value={data.title} strong size="var(--fs-20)" />
        <TextBlock value={data.description} />
      </Flex>
    ) : null;
  const Attachments =
    !isLoading && data?.attachments?.length ? (
      <ActivityContent layout="vertical" colon={false}>
        <Descriptions.Item label={<LabelBlock label="Attachments" />}>
          <AttachmentsBlock attachments={data.attachments ?? []} hideTitle />
        </Descriptions.Item>
      </ActivityContent>
    ) : null;

  const Actions = data?.invoices ? (
    <div style={{ marginTop: 'var(--space-8)' }}>
      <ViewInvoicesButton invoices={data?.invoices} text="Invoice" />
    </div>
  ) : null;
  const Associated = (
    <>
      {data?.associated_activity_id &&
      data.associated_activity_type &&
      Boolean(data.associated_activity) ? (
        <Flex style={{ marginTop: 'var(--space-8)' }} gap="var(--space-8)" direction="column">
          <LabelBlock label="Associated Activity" strong color="var(--gray-8)" />
          <AssociatedActivity
            id={data.associated_activity_id}
            type={data.associated_activity_type}
            queryKey={queryKey}
            customer={customer}
            subscriptionType={subscriptionType}
            css={getActivityBg(data.associated_activity_type)}
            isModal={activitiesMode}
          />
        </Flex>
      ) : null}
    </>
  );

  const Sub = !isLoading && data ? <SubscriptionType subscriptionType={subscriptionType} /> : null;

  const Content = (
    <Flex gap="var(--space-12)" direction="column">
      <Flex direction="column" gap="var(--space-8)">
        {Header}
        {Sub}
      </Flex>
      <Flex gap="var(--space-16)" direction="column">
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item>{Note}</Descriptions.Item>
        </ActivityContent>
        {Attachments}
        {Actions}
        <HideWrapper hide={!data?.invoices?.length}>
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item label={<LabelBlock label="Invoices" />}>
              {InvoiceArea}
            </Descriptions.Item>
          </ActivityContent>
        </HideWrapper>
        <HideWrapper hide={!data?.invoice_line_items?.length}>
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item label={<LabelBlock label="Invoice line items" />}>
              {LineItem}
            </Descriptions.Item>
          </ActivityContent>
        </HideWrapper>
        {Associated}
      </Flex>
    </Flex>
  );

  const Footer =
    data && !isLoading ? (
      <CommentsBlock
        customerId={props.customer?.id}
        activityId={Number(data.id)}
        activityType={ActivityType.TASK}
        comments={data.comments}
        isOpen={entitySearchParams[EActivityQueryParams.OPEN] === OPEN_TYPE.COMMENTS}
      />
    ) : null;

  return (
    <>
      {Content}
      <Divider style={{ margin: '24px 0', borderColor: 'var(--gray-5)' }} />
      {Footer}
    </>
  );
}

export { TaskDetailsBody };
