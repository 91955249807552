import { Texto } from 'components/BaseComponents/Typography/Texto';
import { ReactElement } from 'react';

interface EntityDisplayProps {
  title: string;
  isSelected: boolean;
  onClick?: () => void;
  icon: ReactElement;
  hoverSelect: () => any;
}

export function EntityDisplay(props: EntityDisplayProps) {
  return (
    <div
      onMouseEnter={() => {
        props.hoverSelect();
      }}
      onClick={props.onClick}
      style={{
        padding: 'var(--space-8)',
        background: props.isSelected ? 'var(--purple-6)' : '',
        color: props.isSelected ? 'var(--gray-1)' : '',
      }}
    >
      <Texto size="14" color={props.isSelected ? '--gray-1' : '--gray-8'}>
        <span style={{ margin: 'var(--space-8)', fontSize: 'var(--fs-16)' }}>{props.icon}</span>
        {props.title}
      </Texto>
    </div>
  );
}
