import { InvoiceStatus } from 'types/entities/invoice';

export const InvoiceStatusLabel: Record<InvoiceStatus, string> = {
  [InvoiceStatus.PARTIAL_PAYMENT]: 'Partially Paid',
  [InvoiceStatus.REOPEN]: 'Re-opened',
  [InvoiceStatus.DISPUTE]: 'In Dispute',
  [InvoiceStatus.CLOSED]: 'Closed',
  [InvoiceStatus.WRITE_OFF]: 'Written Off',
  [InvoiceStatus.DRAFT]: 'Draft',
  [InvoiceStatus.PENDING]: 'Pending',
  [InvoiceStatus.FULL_PAYMENT]: 'Fully Paid',
  [InvoiceStatus.VOID]: 'Void',
};
export const InvoiceStatusLabelColor: Record<InvoiceStatus, string> = {
  [InvoiceStatus.PARTIAL_PAYMENT]: '#B7EB8F',
  [InvoiceStatus.REOPEN]: '#FFF566',
  [InvoiceStatus.DISPUTE]: '#FFA39E',
  [InvoiceStatus.CLOSED]: '#CF1322',
  [InvoiceStatus.WRITE_OFF]: '#FF7875',
  [InvoiceStatus.DRAFT]: ' #5CDBD3',
  [InvoiceStatus.PENDING]: '#FFE58F',
  [InvoiceStatus.FULL_PAYMENT]: '#52C41A',
  [InvoiceStatus.VOID]: '#F0F0F0',
};

export const ClosedInvoicestatusList = [
  {
    label: InvoiceStatusLabel.CLOSED,
    value: InvoiceStatus.CLOSED,
    color: 'var(--red-5)',
  },
  {
    label: InvoiceStatusLabel.WRITE_OFF,
    value: InvoiceStatus.WRITE_OFF,
    color: 'var(--red-2)',
  },
  {
    label: InvoiceStatusLabel.VOID,
    value: InvoiceStatus.VOID,
    color: 'var(--green-5)',
  },
];
