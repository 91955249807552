import { CumulioDashboardComponent } from '@cumul.io/react-cumulio-dashboard';
import { useCustomReportToken } from 'hooks/use-report-query';
import { useParams } from 'lib/router';
import { getCustomEventHandler } from './customEventHandlers';
const CUMUL_APP_SERVER = 'https://app.us.cumul.io/';

export function AdvancedReport(props: unknown) {
  const tokenMetaInfo = useCustomReportToken();
  const { id: dashboardId } = useParams();

  return (
    <CumulioDashboardComponent
      authKey={tokenMetaInfo.customReportToken?.sso_id}
      authToken={tokenMetaInfo.customReportToken?.sso_token}
      dashboardId={dashboardId}
      appServer={CUMUL_APP_SERVER}
      customEvent={getCustomEventHandler}
    />
  );
}
