import { useCallback, useEffect, useState } from 'react';
import { SelectOption } from '../../types';
import { FilterOptions } from '../type';

export function useGenerateOptionsWithSearch<T>(
  options: FilterOptions,
  shouldIncludeUnassigned?: boolean
): [
  computedOptions: SelectOption[],
  setComputedOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
  handleResetToDefaultOptions: () => void,
  updateComputedOptions: (value: T) => void,
  updateOptionsWithSelectedValue: (value: T, options: SelectOption[]) => void
] {
  const [computedOptions, setComputedOptions] = useState<SelectOption[]>([]);
  const [defaultOptions, setDefaultOptions] = useState<SelectOption[]>([]);

  function handleResetToDefaultOptions() {
    setComputedOptions(defaultOptions);
  }

  function updateComputedOptions(value: T) {
    if (Array.isArray(value)) {
      const selectedOptions = computedOptions.filter((option) => value.includes(option.value));
      const nonSelectedOptions = defaultOptions.filter((option) => !value.includes(option.value));
      setComputedOptions([...selectedOptions, ...nonSelectedOptions]);
      return;
    }
    const selectedOption = computedOptions.find((option) => option.value === value);
    if (selectedOption) {
      setComputedOptions([selectedOption, ...defaultOptions]);
    }
  }

  function updateOptionsWithSelectedValue(value: T, options: SelectOption[]) {
    if (Array.isArray(value)) {
      const selectedOptions = computedOptions.filter((option) => value.includes(option.value));
      const nonSelectedOptions = options.filter((option) => !value.includes(option.value));
      setComputedOptions([...nonSelectedOptions, ...selectedOptions]);
    }
  }
  const generateOptions = useCallback(async () => {
    if (typeof options === 'function') {
      return await options();
    } else if (Array.isArray(options)) {
      return options;
    }

    return [];
  }, [options]);

  useEffect(() => {
    generateOptions().then((options) => {
      const optionsWithUnassigned = [{ label: 'Unassigned', value: -1 }, ...options];
      if (shouldIncludeUnassigned) {
        setComputedOptions(optionsWithUnassigned);
        setDefaultOptions(optionsWithUnassigned);
      } else {
        setComputedOptions(options);
        setDefaultOptions(options);
      }
    });
  }, [generateOptions, shouldIncludeUnassigned]);

  return [
    computedOptions,
    setComputedOptions,
    handleResetToDefaultOptions,
    updateComputedOptions,
    updateOptionsWithSelectedValue,
  ];
}
