export type Credentials = {
  client_id?: string;
  client_secret?: string;
  org_id?: string;
};

export type CredentialsPostBody = Credentials;

export enum Integrations {
  ZOHO_BOOKS = 'zoho_books',
  STRIPE = 'stripe',
  ZOHO_INVOICE = 'zoho_invoice',
  CHARGEBEE = 'chargebee',
  NETSUITE = 'netsuite',
  ZUORA = 'zuora',
  INVOICED = 'invoiced',
  SALESFORCE = 'salesforce',
  QUICK_BOOKS = 'quick_books',
  XERO = 'xero',
  SAGE_INTACCT = 'sage',
}

export type IntegrationAction = {
  integrationName: Integrations;
  credentials: Credentials;
};

export type BaseAccountSource = {
  accountSourceId: number;
  accountId: number;
  clientId: string;
  clientSecret: string;
  orgId: string;
  integrationSource: string;
  id: number;
  enabled: boolean;
};

export type AccountSource<T extends Integrations> = T extends Integrations.NETSUITE
  ? {
      consumerKey: string;
      consumerSecret: string;
      tokenId: string;
      tokenSecret: string;
      nsAccountId: string;
      id: number;
      enabled: boolean;
    }
  : T extends Integrations.QUICK_BOOKS
  ? BaseAccountSource & {
      realmId?: string;
      accessToken?: string;
      refreshToken?: string;
    }
  : BaseAccountSource;
