import TextStyle from '@tiptap/extension-text-style';

export const CustomTextStyle = TextStyle.extend({
  addAttributes() {
    return {
      style: {
        default: null,
        parseHTML: (element) => {
          return {
            style: element.getAttribute('style'),
          };
        },
        renderHTML: ({ style }) => style,
      },
    };
  },
});
