import { Col, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const SecondaryText = styled(Text)`
  display: block;
  font-size: var(--fs-12);
  color: var(--gray-8);
`;

export const TotalBalance = styled(Col)`
  h5.ant-typography {
    font-weight: normal;
    line-height: normal;
  }
`;

export const TotalText = styled(Text)`
  color: var(--gray-8);
  display: block;
  line-height: normal;
  font-size: var(--fs-16);
`;
