export function replaceInArray<T>(
  array: Array<T>,
  replacedValue: T,
  indexOrPredicate: number | ((item: T) => boolean)
) {
  let index: number;

  if (typeof indexOrPredicate === 'function') {
    index = array.findIndex(indexOrPredicate);
  } else {
    index = indexOrPredicate;
  }

  if (!(index >= 0) || index >= array.length) {
    return array;
  }

  const newArray = [...array];

  newArray.splice(index, 1, replacedValue);

  return newArray;
}

export function replaceInArrayInPlace<T>(
  array: Array<T>,
  replacedValue: T,
  indexOrPredicate: number | ((item: T) => boolean)
) {
  let index: number;

  if (typeof indexOrPredicate === 'function') {
    index = array.findIndex(indexOrPredicate);
  } else {
    index = indexOrPredicate;
  }

  if (!(index >= 0) || index >= array.length) {
    return array;
  }

  array.splice(index, 1, replacedValue);
}

export function replaceOrPushInArray<T>(
  array: Array<T>,
  replacedValue: T,
  indexOrPredicate: number | ((item: T) => boolean)
) {
  let index: number;

  if (typeof indexOrPredicate === 'function') {
    index = array.findIndex(indexOrPredicate);
  } else {
    index = indexOrPredicate;
  }

  if (index >= array.length) {
    return array;
  }

  const newArray = [...array];

  if (index < 0) {
    newArray.push(replacedValue);
  } else {
    newArray.splice(index, 1, replacedValue);
  }

  return newArray;
}

export function getEntriesFromMap<K, V>(map: Map<K, V>): [K, V][] {
  const list: [K, V][] = [];

  for (const [key, value] of map.entries()) {
    list.push([key, value]);
  }

  return list;
}
