import { Spin } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import EmptyChart, { getIsEmpty } from 'components/Common/Charts/EmptyChart';
import PieChart from 'components/Common/Charts/Pie';
import { PieChartEventData } from 'components/Common/Charts/Pie/type';
import { GenericPlot, PieConfigs } from 'components/Common/Charts/types';
import Legend from 'components/Common/Legend/index';
import {
  InvoiceStatusLabel,
  InvoiceStatusLabelColor,
} from 'constants/collection-activities/invoice-statuses';
import { usePageNavigate } from 'lib/router';
import { sum } from 'lodash';
import { DashboardComponentProps } from 'pages/Dashboard/types';
import { useMemo, useRef } from 'react';
import { InvoiceStatus } from 'types/entities/invoice';
import ToolTip from './ToolTip';
import { ChartRef, InvoiceStatusData } from './type';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';

function InvoiceStatusChart(props: Omit<DashboardComponentProps, 'filterParams'>) {
  const { invoiceStatus } = props;
  const plotRef = useRef<GenericPlot<InvoiceStatusData> | null>(null);

  const navigate = usePageNavigate();
  const chartRef = useRef<ChartRef>();
  const locale = useSelector(localeSelector);
  function handleInvoiceStatusData(): InvoiceStatusData[] {
    if (invoiceStatus && Boolean(invoiceStatus.length)) {
      const totalAmount = sum(invoiceStatus?.map((status) => status.outstanding.amount.value));
      return invoiceStatus.map((status) => {
        return {
          type: InvoiceStatusLabel[status.status],
          value: status.outstanding.amount.value,
          valueObject: status,
          totalAmount,
        };
      });
    }
    return [];
  }
  function handleInvoiceStatusColors() {
    if (invoiceStatus && Boolean(invoiceStatus.length)) {
      return invoiceStatus.map((status) => {
        return InvoiceStatusLabelColor[status.status];
      });
    }
    return [];
  }

  function handleOnReady(plot: GenericPlot<InvoiceStatusData>) {
    plot.on('plot:click', handlePieClick);
    plotRef.current = plot;
  }

  const invoiceStatusData: InvoiceStatusData[] = useMemo(handleInvoiceStatusData, [invoiceStatus]);

  function handlePieClick(event: PieChartEventData<InvoiceStatusData>) {
    event.gEvent.preventDefault();
    event.gEvent.stopPropagation();
    if (event.data) {
      const status = event.data.data.valueObject.status;
      const metaActivityData = new URLSearchParams({
        sort_col: 'TOTAL_DUE_AMOUNT',
        sort_by: 'DESC',
      });
      navigate({
        appPage: 'AR_AGING',
        searchParams: `${metaActivityData}&status=["${status}"]`,
        state: {
          addPrimaryPathAsChildPath: true,
        },
      });
    }
  }

  const invoiceStatusColor = useMemo(handleInvoiceStatusColors, [invoiceStatus]);

  const pieConfig: Omit<PieConfigs<InvoiceStatusData>, 'data'> = {
    className: 'invoice-status-chart',
    appendPadding: [16],
    renderer: 'canvas',
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: false,
    width: 100,
    color: invoiceStatusColor,
    onReady: handleOnReady,
    supportCSSTransform: true,
    tooltip: {
      customContent(title, content) {
        return (
          Boolean(content.length) && <ToolTip title={title} content={content} locale={locale} />
        );
      },
    },
    legend: false,
    style: {
      cursor: 'pointer',
      width: '70%',
      height: '200px',
    },
  };

  const LoadingTemplate = <Spin />;

  const legendPropData = useMemo(
    () =>
      invoiceStatusData?.map((item) => {
        const label = item.type;
        const color = InvoiceStatusLabelColor[item.valueObject.status];
        const disable = !item.valueObject.outstanding.invoice_count;

        return { label, color, disable, id: item.valueObject.status };
      }),
    [invoiceStatusData]
  );
  const currentLegends = legendPropData.map((item) => item.id);

  function handleOnClickLegend(type: InvoiceStatus) {
    const plot = plotRef.current;
    if (!plot) return;
    const exist = currentLegends.findIndex((t) => t === type);
    if (exist === -1) {
      currentLegends.push(type);
    } else {
      currentLegends.splice(exist, 1);
    }
    plot.chart.filter('type', (value, item) => {
      return currentLegends.includes((item as InvoiceStatusData).valueObject.status);
    });
    plot.chart.render(true);
  }

  const isEmpty = getIsEmpty(invoiceStatusData, ['value']);

  return (
    <Flex
      style={{
        width: '100%',
        marginTop: 'var(--space-8)',
      }}
      gap="var(--space-4)"
      align="center"
    >
      <EmptyChart isEmpty={isEmpty}>
        <PieChart
          {...pieConfig}
          data={invoiceStatusData}
          chartRef={chartRef}
          loadingTemplate={LoadingTemplate}
        />
      </EmptyChart>
      <div style={{ width: '120px' }}>
        {legendPropData.length ? (
          <div className="legend-list">
            <Legend
              data={legendPropData}
              handleOnClickLegend={handleOnClickLegend}
              textWidth={'80px'}
            />
          </div>
        ) : null}
      </div>
    </Flex>
  );
}

export default InvoiceStatusChart;
