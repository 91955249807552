import { cleanArrayPredicate } from 'util/predicates';
import { GenericSelectOption } from '../../types';

export function treeValueToString<T, R extends PropertyKey = 'children'>(
  defaultValue: T[],
  option: GenericSelectOption<T, R>[],
  key: R
) {
  /* predicates*/
  const valueArrayPredicate = (value: T) =>
    option.find((option) => option.value === (value as T))?.label;
  const optionPredicate = (option: GenericSelectOption<T, R>) => option.value;
  const childPredicate = (childOption: GenericSelectOption<T, R>) =>
    childOption.valueObj?.[key] as unknown as GenericSelectOption<T, R>;
  /* predicates*/

  const valueArray = defaultValue.map(valueArrayPredicate).filter(cleanArrayPredicate);
  const parent = option.map(optionPredicate);
  const child = option.flatMap(childPredicate).filter(cleanArrayPredicate).map(optionPredicate);

  return defaultValue.length === [...parent, ...child].length ? 'All' : valueArray.join(', ');
}
