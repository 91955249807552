import { Button } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { AllAccountSourceProps } from 'types/api/currency-page-settings';
import useOpen from 'util/hooks/useOpen';
import PastPeriodDrawer from './PastPeriodDrawer';

interface PastPeriodDrawerProps {
  pastPeriodData: AllAccountSourceProps;
  openDrawer?: boolean;
}

const t = {
  choosePeriod: 'Choose a past period',
  pastPeriodText: 'View Past Periods',
  drawerTitle: 'Past Periods',
  exchangeRate: 'Exchange rate for each currency',
};

function PastPeriodForm(props: PastPeriodDrawerProps) {
  const { pastPeriodData } = props;
  const { open, toggleOpen } = useOpen({ open: false });
  const format = useSelector(dateFormatSelector);
  const pastPeriodOption = useMemo(() => {
    return pastPeriodData.exchange_rate_config
      ? pastPeriodData.exchange_rate_config
          .filter((item) => item.is_past_period === true)
          .map((item) => ({
            label: `${dayjs(item.start_date).format(format)} - ${dayjs(item.end_date).format(
              format
            )}`,
            value: `${item.start_date} - ${item.end_date}`,
          }))
      : [];
  }, [format, pastPeriodData.exchange_rate_config]);

  const handleDrawerToggle = () => {
    toggleOpen();
  };

  const linkOpenCondition = pastPeriodOption.length === 0;

  const PastPeriodButton = !linkOpenCondition && (
    <Button
      onClick={handleDrawerToggle}
      type="link"
      style={{
        color: 'var(--purple-7)',
        padding: '0',
      }}
    >
      {t.pastPeriodText}
    </Button>
  );

  return (
    <Flex align="flex-end">
      {PastPeriodButton}
      <PastPeriodDrawer
        pastPeriodData={pastPeriodData}
        open={open}
        handleDrawerToggle={handleDrawerToggle}
      />
    </Flex>
  );
}

export default PastPeriodForm;
