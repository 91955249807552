import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CancelButton } from '@sinecycle/growcomponents';
import { Button, Divider, Popconfirm } from 'antd';
import { TypographyParagraph } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { sortTemplates } from 'components/Settings/Productivity/EmailTemplates/Body';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { emailTemplatesSelector, folderSelector } from 'store/insert';
import { EmailTemplates, FolderType, Folders } from 'types/entities/email-templates';
import useOpen from 'util/hooks/useOpen';
import { FooterContainer, StyledEmailTemplatesBody, TemplatePreviewViewContainer } from '../style';
import { InsertEmailTemplatesSideBar } from './Menu';
import EmailTemplatePreview from './Preview';

const t = {
  insert: 'Insert',
  subject: 'Subject',
  body: 'Body',
  replace: 'Replace',
  confirmInsert: 'Adding this template will replace the current email draft.',
  confirmTitle: 'Replace Current Email Draft?',
  confirmDescription: 'Adding this template will replace the current email draft.',
};

export interface GroupedTemplates extends Folders {
  templates: EmailTemplates[];
}

interface InsertEmailTemplatesBodyProps {
  onTemplateInsert?: (selectedTemplate: EmailTemplates) => void;
  optionsOverrideStyles?: React.CSSProperties;
  sideBarOverrideStyles?: React.CSSProperties;
  validFolders?: FolderType[];
  onCancel: () => void;
  confirmBeforeInsert: () => boolean;
}

function InsertEmailTemplatesBody(props: InsertEmailTemplatesBodyProps) {
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplates>();
  const [showPreview, setShowPreview] = useState(true);
  const folders = useSelector(folderSelector);
  const { open: openConfirmDelete, toggleOpen } = useOpen({ open: false });
  const emailTemplates = useSelector(emailTemplatesSelector);

  const findMatchingFolder = useCallback(
    (folderName: FolderType) => {
      return folders?.find((folder) => folder.email_template_type === folderName);
    },
    [folders]
  );

  const foldersWithTemplates = useMemo(() => {
    const grouped = props.validFolders?.reduce((templateGroup, folderName) => {
      const folderMatch = findMatchingFolder(folderName);
      if (folderMatch) {
        const matchingTemplates = emailTemplates.filter(
          (template) => template.template_folder_id === folderMatch.id
        );
        templateGroup.push({ ...folderMatch, templates: sortTemplates(matchingTemplates) });
      }
      return templateGroup;
    }, [] as GroupedTemplates[]);

    return grouped;
  }, [emailTemplates, findMatchingFolder, props.validFolders]);

  const headerDescriptionTooltipProps = {
    title: selectedTemplate?.description,
    align: { targetOffset: [0, -30] },
  };
  const headerTitleTooltipProps = {
    title: selectedTemplate?.title,
    align: { targetOffset: [0, -15] },
  };

  const popConfirmButtonStyles = {
    background: 'var(--orange-5)',
    border: 'none',
    color: 'var(--gray-9)',
  };

  function insertTemplate() {
    selectedTemplate && props?.onTemplateInsert?.(selectedTemplate);
    props.onCancel();
  }

  function togglePreview(visibility: boolean) {
    setShowPreview(visibility);
  }

  function handleBeforeInsertTemplate() {
    const askConfirmation = props.confirmBeforeInsert();
    if (askConfirmation) {
      toggleOpen();
    } else insertTemplate();
  }

  function handleTemplateChange(template: EmailTemplates | undefined) {
    setSelectedTemplate(template);
  }

  const selectedKey = useMemo(() => {
    if (selectedTemplate) {
      return `${selectedTemplate?.template_folder_id}-${selectedTemplate?.id}`;
    }
    const defaultOpenTemplate = foldersWithTemplates?.[0]?.templates?.[0];
    if (defaultOpenTemplate) {
      setSelectedTemplate(defaultOpenTemplate);
      return `${defaultOpenTemplate?.template_folder_id}-${defaultOpenTemplate?.id}`;
    }
  }, [foldersWithTemplates, selectedTemplate]);

  const PopConfirmContent = (
    <Flex gap="var(--space-8)" direction="column">
      <Flex align="flex-start" gap="var(--space-4)">
        <FontAwesomeIcon color="var(--orange-7)" size="lg" icon={['far', 'exclamation-circle']} />
        <GrowText strong>{t.confirmTitle}</GrowText>
      </Flex>
      <GrowText style={{ maxWidth: '200px', color: 'var(--color-7)' }}>
        {t.confirmDescription}
      </GrowText>
    </Flex>
  );

  const popConfirmButtonProps = {
    okButtonProps: {
      onClick: () => {
        insertTemplate();
        toggleOpen();
      },
      style: popConfirmButtonStyles,
    },
    cancelButtonProps: { style: { color: 'var(--primary-7)' }, onClick: toggleOpen },
  };

  const InsertButton = (
    <Button
      disabled={!selectedTemplate}
      onClick={handleBeforeInsertTemplate}
      className="insert-btn"
      type="primary"
    >
      {t.insert}
    </Button>
  );

  const Cancel = <CancelButton className="cancel-btn" onClick={props.onCancel} />;

  const InsertWithConfirm = (
    <Popconfirm
      title={PopConfirmContent}
      open={openConfirmDelete}
      className="remove-pop-over"
      overlayClassName="ant-popover-title-no-padding"
      icon={false}
      okText={t.replace}
      getPopupContainer={(node) => {
        return node.parentElement as HTMLElement;
      }}
      overlayInnerStyle={{ borderRadius: 'var(--br-1)', border: '1px solid var(--gray-4)' }}
      {...popConfirmButtonProps}
    >
      {InsertButton}
    </Popconfirm>
  );

  const ModalFooter = (
    <FooterContainer justify="flex-end">
      {Cancel}
      {InsertWithConfirm}
    </FooterContainer>
  );

  const TemplatePreviewHeader = selectedTemplate?.title ? (
    <>
      <Flex direction="column">
        <GrowText
          style={{ maxWidth: '100%' }}
          weight="var(--fs-semibold)"
          ellipsis={{ tooltip: headerTitleTooltipProps }}
        >
          {selectedTemplate?.title}
        </GrowText>
        <TypographyParagraph
          ellipsis={{
            tooltip: headerDescriptionTooltipProps,
            rows: 2,
          }}
          style={{ color: 'var(--gray-8)', margin: 'var(--space-0)' }}
        >
          {selectedTemplate?.description.length ? selectedTemplate?.description : '-'}
        </TypographyParagraph>
      </Flex>
      <Divider style={{ margin: 'var(--space-16) 0', borderColor: 'var(--gray-4)' }} />
    </>
  ) : null;

  const TemplatePreviewView = showPreview ? (
    <TemplatePreviewViewContainer direction="column">
      {TemplatePreviewHeader}
      <EmailTemplatePreview selectedTemplate={selectedTemplate} />
    </TemplatePreviewViewContainer>
  ) : null;

  const Sidebar = (
    <InsertEmailTemplatesSideBar
      groupedTemplates={foldersWithTemplates}
      onTemplateSelect={props.onTemplateInsert}
      onTemplateChange={handleTemplateChange}
      selectedKey={selectedKey}
      sideBarOverrideStyles={props.sideBarOverrideStyles}
      togglePreview={togglePreview}
    />
  );

  return (
    <StyledEmailTemplatesBody>
      {Sidebar}
      {TemplatePreviewView}
      {ModalFooter}
    </StyledEmailTemplatesBody>
  );
}

export { InsertEmailTemplatesBody };
