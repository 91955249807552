import { Form } from 'antd';
import { notify } from 'components/BaseComponents/Notifications';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { ActivityType } from 'types/activities/activity-types';
import TaskRemainder from '../TaskRemainder';
import { parseDateTransformSchema } from '../Utils';
import useFormSubmit from '../hooks/useFormSubmit';
import useOnFormRemainderSubmit from '../hooks/useSubmitFormRemainder';
import { FormItemNames, PTPFormElementProps, PTPFormModalProps } from '../types';
import PTPFormItems from './PTPFormItems';
import { getNotifyErrorMessage, getNotifySuccessMessage } from './notificationMessage';

export default function PTPForm<PTPElements extends PTPFormElementProps>(
  props: Omit<PTPFormModalProps<PTPElements>, 'openModal'>
) {
  const {
    form,
    text,
    invoices,
    remainder,
    responseSchema,
    taskRemainderResponseSchema,
    onMutateFn,
    onFormClose,
    customTransformer,
    bulkAction,
    editMode,
    dateFormat,
  } = props;

  const assigneeOptionType = invoices?.length
    ? ActionableEntity.INVOICE
    : ActionableEntity.CUSTOMER;

  const { onFormSubmit } = useFormSubmit<PTPElements['responseType']>(onMutateFn);

  const onFormRemainderSubmit = useOnFormRemainderSubmit<
    PTPElements['responseType'],
    PTPElements['taskRemainderResponseSchema']
  >({
    taskRemainderResponseSchema,
    customTransformer,
    onMutateFn,
    parentType: ActivityType.PROMISE_TO_PAY,
    onClose: onFormClose,
  });

  function onFormFinish(values: Record<FormItemNames, any>) {
    const formValues = parseDateTransformSchema<
      PTPElements['responseType'],
      PTPElements['responseSchema']
    >({
      data: values,
      type: ActivityType.PROMISE_TO_PAY,
      responseSchema,
      dateKey: ['ptpDate'],
      customTransformer,
    });

    onFormSubmit(formValues, ActivityType.PROMISE_TO_PAY)
      .then((data) => {
        if (!remainder) {
          onFormClose();
        }
        onFormRemainderSubmit<PTPElements['responseType']>({ values, data, remainder });
        const { title, description } = getNotifySuccessMessage(bulkAction, editMode);
        notify.success(title, {
          description: description,
        });
      })
      .catch(() => {
        const { title, description } = getNotifyErrorMessage(bulkAction, editMode);
        notify.error(title, {
          description: description,
        });
      });
  }

  return (
    <Form.Provider
      onFormFinish={(name, { values }) => {
        onFormFinish(values as Record<FormItemNames, any>);
      }}
    >
      <Form name={'activityForm'} form={form} layout="vertical" preserve={false}>
        <PTPFormItems<PTPElements> {...props} />
      </Form>
      <Form name={'taskRemainderForm'} form={form} layout="vertical" preserve={false}>
        <div className={`${remainder ? 'active-transition' : 'hide-transition'}`}>
          <TaskRemainder
            form={form}
            assigneeOptionType={assigneeOptionType}
            text={text}
            remainder={remainder}
            dateFormat={dateFormat}
          />
        </div>
      </Form>
    </Form.Provider>
  );
}
