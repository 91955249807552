interface HideWrapperProps {
  hide: boolean;
  children: JSX.Element;
}

function HideWrapper(props: HideWrapperProps) {
  if (props.hide) return null;
  return props.children;
}

export default HideWrapper;
