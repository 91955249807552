import { Subsidiary } from 'types/entities/subsidiary';
import ky from './ky';

const baseUrl = 'subsidiaries';

export async function getSubsidiaries() {
  return (await ky.get(`${baseUrl}`).json()) as Subsidiary[];
}

export async function getSubsidiariesForSettings() {
  return (await ky.get(`${baseUrl}/settings`).json()) as Subsidiary[];
}

export type saveSubsidiaryParams = { id: number; name: string; file?: File; default?: boolean };

export async function saveSubsidiary(props: saveSubsidiaryParams) {
  const { id, name, file } = props;

  const formdata = new FormData();
  formdata.append('name', name);
  file && formdata.append('file', file, file.name);
  formdata.append('default', `${!file}`);

  return (await ky
    .put(`${baseUrl}/settings/${id}`, {
      body: formdata,
    })
    .json()) as Subsidiary;
}
