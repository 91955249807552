import { mergeAttributes } from '@tiptap/core';
import TableRow from '@tiptap/extension-table-row';
import { TransformPastedHTML } from '../TipTapEditor/extensions/default-paste';

export const CustomTableRow = TableRow.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      style: {
        default: 'border: 1px solid #ced4da',
        parseHTML: (element) => element.getAttribute('style') || '',
        renderHTML: (attributes) => {
          if (typeof attributes.style === 'object') {
            // Convert the style object to a CSS string
            return {
              style: Object.entries(attributes.style)
                .map(([key, value]) => `${key}: ${value}`)
                .join('; '),
            };
          }
          return { style: attributes.style };
        },
      },
    };
  },
  renderHTML(props) {
    return ['tr', mergeAttributes(props.HTMLAttributes), 0];
  },
  addProseMirrorPlugins() {
    return [TransformPastedHTML()];
  },
});
