import { createSlice } from '@reduxjs/toolkit';
import {
  initializeCollectionPeriodFilters,
  updateCollectionPeriod,
  updateCollectionPeriodFilters,
  updateCollectionPlanInfo,
  updateCollectionPlanInfoEnabledStatus,
} from './reducers';
import { CollectionPeriodProps } from './types';

export const initialState: CollectionPeriodProps = {
  collectionPeriodFilters: {
    'ar-aging': [],
    'invoice-list': [],
  },
};

const CollectionPeriodSlice = createSlice({
  name: 'collectionPeriod',
  initialState,
  reducers: {
    updateCollectionPeriod,
    initializeCollectionPeriodFilters,
    updateCollectionPeriodFilters,
    updateCollectionPlanInfo,
    updateCollectionPlanInfoEnabledStatus,
  },
});

export const CollectionPeriodActions = CollectionPeriodSlice.actions;

export default CollectionPeriodSlice.reducer;
