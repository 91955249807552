import ky from './ky';

export type AttachmentIdResponse = {
  id: number;
  file_path: string;
  file_name: string;
  content_type?: string;
  file_size?: number;
};
type AttachmentLinkResponse = { url: string };

export enum UploadFileTypes {
  CALL_LOG = 'CALL_LOG',
  ESCALATION = 'ESCALATION',
  WRITE_OFF = 'WRITE_OFF',
  DISPUTE = 'DISPUTE',
  PROMISE_TO_PAY = 'PROMISE_TO_PAY',
  EMAIL = 'EMAIL',
  FOLLOWUP_ACTION_EMAIL = 'FOLLOWUP_ACTION_EMAIL',
}

const baseUrl = 'attachments';

export async function saveAttachment(attachments: File[], type: UploadFileTypes) {
  return Promise.all(
    attachments.map(async (attachment) => {
      return saveSingleAttachment(attachment, type);
    })
  );
}

export async function saveSingleAttachment(
  attachment: File,
  type: UploadFileTypes
): Promise<AttachmentIdResponse> {
  const formdata = new FormData();

  formdata.append('file', attachment, attachment.name);
  formdata.append('type', type);

  return await ky.post(`${baseUrl}`, { body: formdata }).json();
}

export async function getAttachmentUrl(attachmentId: number) {
  return (
    (await ky.get(`${baseUrl}/${attachmentId}/expiry-link?`).json()) as AttachmentLinkResponse
  ).url;
}

export async function uploadContentImage(attachment: File): Promise<string> {
  const formdata = new FormData();

  formdata.append('file', attachment, attachment.name);

  return await ky.post(`${baseUrl}`, { body: formdata }).json();
}
