import { currencyList } from 'constants/currency';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import {
  AllAccountSourceProps,
  ExchangeRate,
  ExchangeRateData,
} from 'types/api/currency-page-settings';
import { SelectedCurrenciesWithValueProps } from '../../../CurrencyContextApi/ContextApi';

export function handleDisableDate(currentDate: dayjs.Dayjs) {
  if (currentDate && dayjs(currentDate).isValid()) {
    return currentDate.isBefore(dayjs().startOf('day'));
  }
  return false;
}

export function getCurrencyInfo(code: string) {
  const currency = currencyList.find((currencyCode) => currencyCode.code === code);

  return currency
    ? {
        name: currency.name,
        symbol: currency.symbol,
        code: currency.code,
      }
    : {
        name: code,
        symbol: '',
        code,
      };
}

export const useGetInitialAvailableCurrencies = (exchangeRateData: AllAccountSourceProps) => {
  const exchangeRates = exchangeRateData?.exchange_rate_config || [];
  const currentExchangeRate = exchangeRates.find((rate: ExchangeRate) => !rate.is_past_period);
  const endDate = currentExchangeRate && dayjs(currentExchangeRate.end_date);
  const startDate = currentExchangeRate && dayjs(currentExchangeRate.start_date);

  const responseSelectCurrencyWithValue: SelectedCurrenciesWithValueProps[] = useMemo(() => {
    const localResponse: SelectedCurrenciesWithValueProps[] = [];

    exchangeRateData.exchange_rate_config &&
      exchangeRateData?.exchange_rate_config.forEach((entry) => {
        if (!entry.is_past_period && entry.exchange_rate_data) {
          entry.exchange_rate_data.forEach((exchangeRateData: ExchangeRateData) => {
            localResponse.push({
              source: exchangeRateData.source,
              destination: exchangeRateData.destination,
              exchangeRate: exchangeRateData.exchange_rate,
            });
          });
        }
      });
    return localResponse;
  }, [exchangeRateData]);

  return {
    startDate,
    responseSelectCurrencyWithValue,
    endDate,
  };
};
