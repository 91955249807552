import { Result } from 'antd';
import { GrowCol } from 'components/BaseComponents/GrowGrid';
import { GrowText } from 'components/BaseComponents/Typography';
import {
  ActivityCardDetails,
  ActivityCardInfo,
  DetailsLayoutContainer,
} from 'components/CollectionActivities/style';
import React from 'react';

export type LayoutMode = React.ComponentProps<typeof DetailsLayoutContainer>['mode'];
interface DetailsLayoutProps {
  content: React.ReactNode;
  info: React.ReactNode;
  mode?: LayoutMode;
  isUnauthorized?: boolean;
}

function DetailsLayout(props: DetailsLayoutProps) {
  const { content, info, mode, isUnauthorized } = props;

  const SubTitle = (
    <GrowText color="var(--gray-7)">Sorry, you are not authorized to access this detail.</GrowText>
  );

  const ResultComp = <Result status="403" subTitle={SubTitle} />;

  return (
    <DetailsLayoutContainer wrap={false} mode={mode}>
      <GrowCol flex="auto">
        <ActivityCardDetails mode={mode}>
          {isUnauthorized ? ResultComp : content}
        </ActivityCardDetails>
      </GrowCol>
      <GrowCol flex="248px">
        <ActivityCardInfo mode={mode}>{info}</ActivityCardInfo>
      </GrowCol>
    </DetailsLayoutContainer>
  );
}

export { DetailsLayout };
