import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetCollectionFollowupResponseState } from '../collection-followup/types';
import { AllCollectionFollowupsResponseState, CollectionFollowupResponseState } from './types';

const initialState: AllCollectionFollowupsResponseState = {
  status: 0,
  message: '',
  collectionFollowups: [],
};

const collectionFollowupsSlice = createSlice({
  name: 'collectionFollowups',
  initialState,
  reducers: {
    allCollectionFollowupsHandler(
      state,
      action: PayloadAction<AllCollectionFollowupsResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.collectionFollowups = action.payload.collectionFollowups ?? [];
    },
    removeCollectionFollowupHandler(state, action: PayloadAction<CollectionFollowupResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (action.payload.status >= 200 && action.payload.status < 300 && action.payload.id)
        state.collectionFollowups = state.collectionFollowups?.map((collectionFollowup) => {
          if (collectionFollowup.id === action.payload.id)
            return Object.assign({}, collectionFollowup, { deleted: true });
          else return collectionFollowup;
        });
    },
    enableCollectionFollowupHandler(state, action: PayloadAction<CollectionFollowupResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (action.payload.status >= 200 && action.payload.status < 300 && action.payload.id)
        state.collectionFollowups = state.collectionFollowups?.map((collectionFollowup) => {
          if (collectionFollowup.id === action.payload.id)
            return Object.assign({}, collectionFollowup, { enabled: true });
          else return collectionFollowup;
        });
    },
    disableCollectionFollowupHandler(
      state,
      action: PayloadAction<CollectionFollowupResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (action.payload.status >= 200 && action.payload.status < 300 && action.payload.id)
        state.collectionFollowups = state.collectionFollowups?.map((collectionFollowup) => {
          if (collectionFollowup.id === action.payload.id)
            return Object.assign({}, collectionFollowup, { enabled: false });
          else return collectionFollowup;
        });
    },
    cloneCollectionFollowupHandler(
      state,
      action: PayloadAction<GetCollectionFollowupResponseState>
    ) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      if (
        action.payload.status >= 200 &&
        action.payload.status < 300 &&
        action.payload.collectionFollowup
      ) {
        state.collectionFollowups = (state.collectionFollowups ?? []).concat([
          action.payload.collectionFollowup,
        ]);
      }
    },
    clearStatusAndMessageHandler(state) {
      state.status = 0;
      state.message = '';
    },
  },
});

export const {
  allCollectionFollowupsHandler,
  removeCollectionFollowupHandler,
  enableCollectionFollowupHandler,
  disableCollectionFollowupHandler,
  cloneCollectionFollowupHandler,
  clearStatusAndMessageHandler,
} = collectionFollowupsSlice.actions;

export default collectionFollowupsSlice.reducer;
