import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { InputLoader } from 'components/Common/FilterComponents/Common/SkeletonLoader';
import { NumberFormat } from 'components/Common/NumberFormat/NumberFormat';
import { ReactNode } from 'react';

interface TotatalCustomersInvoicesProps {
  customerLabel?: ReactNode;
  invoiceLabel?: ReactNode;
  customersCount?: number;
  invoicesCount?: number;
  showCustomer?: boolean;
  showInvoice?: boolean;
  loading?: boolean;
}

const t = {
  total_customers: 'Total Customers',
  total_invoices: 'Total Invoices',
};

function TotalCustomersInvoices(props: TotatalCustomersInvoicesProps) {
  const {
    customersCount = 0,
    invoicesCount = 0,
    showCustomer = true,
    showInvoice = true,
    loading,
  } = props;

  const InputLoaderJSX = (
    <InputLoader active={loading} style={{ minWidth: 'unset', width: '20px' }} />
  );

  return (
    <Flex className="total-count-container" gap="var(--space-16)">
      {showCustomer && (
        <div className="total-customers">
          <Tooltip title={t.total_customers}>
            <Row align="middle" gutter={[4, 4]} wrap={false}>
              <Col>
                <FontAwesomeIcon icon={['far', 'briefcase']} color={'var(--primary-4)'} />
              </Col>
              <Col>
                <span>{loading ? InputLoaderJSX : <NumberFormat value={customersCount} />}</span>
              </Col>
            </Row>
          </Tooltip>
        </div>
      )}
      {showInvoice && (
        <div className="total-invoices">
          <Tooltip title={t.total_invoices}>
            <Row align="middle" gutter={[4, 4]} wrap={false}>
              <Col>
                <FontAwesomeIcon icon={['far', 'file-invoice']} color={'var(--geekblue-4)'} />
              </Col>
              <Col>
                <span>{loading ? InputLoaderJSX : <NumberFormat value={invoicesCount} />}</span>
              </Col>
            </Row>
          </Tooltip>
        </div>
      )}
    </Flex>
  );
}

export default TotalCustomersInvoices;
