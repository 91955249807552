import { IconSideBarAutomation } from 'assets/icons';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { useCurrentUser } from 'hooks/use-current-user';
import { useSelector } from 'react-redux';
import { pagePath, pagePrivileges } from 'router/constants/page-info';
import { strategyV2EnabledSelector } from 'store/authentication/authentication';
import { UserRole } from 'types/entities/user';
import { AppSubRoute } from '../sidebar.components';
import { PageListItems } from './usePageList';

function useAutomations() {
  const strategyV2Enabled = useSelector(strategyV2EnabledSelector);
  const currentUser = useCurrentUser() ?? { roles: [] };
  const hasWorkFlowAccess =
    currentUser.roles.includes(UserRole.ADMIN) || currentUser.roles.includes(UserRole.SCOPED_ADMIN);

  const hasStrategy = usePrivilegeStatus(
    strategyV2Enabled
      ? pagePrivileges('COLLECTION_STRATEGIES')
      : pagePrivileges('COLLECTION_FOLLOWUPS'),
    {
      shallowCheck: true,
    }
  );
  function handleCollectionsSubLink() {
    const subLinks: AppSubRoute[] = [];
    if (hasStrategy) {
      subLinks.push({
        label: 'Collection Strategy',
        url: strategyV2Enabled
          ? pagePath('COLLECTION_STRATEGIES')
          : pagePath('COLLECTION_FOLLOWUPS'),
      });
    }
    if (hasWorkFlowAccess) {
      subLinks.push({
        label: 'Workflow Automation',
        url: pagePath('WORKFLOW_AUTOMATIONS'),
      });
    }
    return subLinks;
  }
  const automationsSubLinks: AppSubRoute[] = handleCollectionsSubLink();
  const automationPage: Array<PageListItems> = [
    {
      key: 'WORKFLOW_AUTOMATIONS',
      subLinks: automationsSubLinks,
      label: 'Automations',
      access: Boolean(automationsSubLinks.length),
      icon: <IconSideBarAutomation fill="var(--gray-1)" fontSize={24} />,
    },
  ];
  return { automationPage };
}

export default useAutomations;
