import { exchangeRateSource } from 'types/api/currency-page-settings';

const t = {
  rateMSGText: {
    growfin: 'Growfin rate set',
    integration: 'ERP rate set',
    static: 'Current rate set',
  },
};

export function RatesMessage(rates: string) {
  switch (rates) {
    case exchangeRateSource.GROWFIN:
      return t.rateMSGText.growfin;
    case exchangeRateSource.ERP:
      return t.rateMSGText.integration;
    default:
      return t.rateMSGText.static;
  }
}
