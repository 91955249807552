export const t = {
  filterMenu: {
    all: 'All',
    customers: 'Customers',
    invoices: 'Invoices',
  },
  type_to_search: 'Type to search...',
  customers: 'Customers',
  customer_name: 'Customer Name',
  customer_id: 'Customer ID',
  invoices: 'Invoices',
  invoice_title: 'Invoice Title',
  reference_number: 'Reference Number',
  try_another_keyword: 'Try some other keyword...',
  search: 'Search',
  no_results: 'Showing 0 Results for',
};
