import { ColumnTitle } from '@sinecycle/growcomponents';
import { Flex } from '../Layout/Flex';

function ColumnFooter() {
  return (
    <Flex align="center" gap="var(--space-4)">
      <ColumnTitle hasSymbol />
      <ColumnTitle style={{ color: 'var(--gray-7)' }}>Custom Fields</ColumnTitle>
    </Flex>
  );
}

export { ColumnFooter };
