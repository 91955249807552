import { Col, DatePicker, Row } from 'antd';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { TextArea } from 'components/BaseComponents/Input';
import { EllipsisList } from 'components/Common/EllipsisList';
import dayjs from 'dayjs';
import { map } from 'lodash';
import InvoiceSelect from '../FormElements/InvoiceSelect';
import UploadFormFiles from '../FormElements/UploadFormFiles';
import { ActivityFormItem, EllipsisListContainer } from '../style';
import { CallLogFormModalProps, CallLogModalFormElementProps } from '../types';

export default function CallLogFormItems<CallLogElements extends CallLogModalFormElementProps>(
  props: Omit<CallLogFormModalProps<CallLogElements>, 'openModal'>
) {
  const {
    invoices,
    form,
    invoiceQueryKey,
    invoiceSearchFn,
    invoiceQueryFn,
    invoiceTransformSchema,
    attachments,
    callDetails,
    callDate,
    text,
    bulkRecordsLength,
    bulkAction,
    dateFormat,
  } = props;

  const {
    invoiceLabel,
    invoicePlaceholder,
    descriptionLabel,
    descriptionPlaceholder,
    dateLabel,
    datePlaceholder,
  } = text;

  const fileList: File[] | undefined = useFormWatch('callLogFileParams', form);
  const selectedInvoiceIds = map(invoices, (item) => (item as { id: number })?.id);
  const selectedInvoiceNo = map(invoices, (item) => (item as any)?.invoice_no);
  function onChangeInvoiceList(invoiceList: number[]) {
    form.setFieldValue('callLogInvoices', invoiceList);
  }

  const InvoiceSelectList = (
    <ActivityFormItem label={invoiceLabel} name="callLogInvoices" initialValue={selectedInvoiceIds}>
      <InvoiceSelect<CallLogElements['invoiceSelect']>
        placeholder={invoicePlaceholder}
        selectedInvoicesId={selectedInvoiceIds}
        selectedInvoices={invoices}
        onChangeInvoice={onChangeInvoiceList}
        queryFn={invoiceQueryFn}
        searchQueryFn={invoiceSearchFn}
        queryKey={invoiceQueryKey}
        transformSchema={invoiceTransformSchema}
      />
    </ActivityFormItem>
  );

  const CallLogDescription = (
    <ActivityFormItem
      label={descriptionLabel}
      name="callLogDescription"
      initialValue={callDetails}
      rules={[
        {
          required: true,
          message: `${descriptionLabel} is required`,
        },
      ]}
      required
    >
      <TextArea placeholder={descriptionPlaceholder} />
    </ActivityFormItem>
  );

  const CallLogDate = (
    <ActivityFormItem
      label={dateLabel}
      name="callLogDate"
      rules={[
        {
          required: true,
          message: `${dateLabel} is required`,
        },
      ]}
      required
      initialValue={callDate ? dayjs(callDate) : ''}
    >
      <DatePicker
        placeholder={datePlaceholder}
        size="middle"
        format={dateFormat}
        style={{ width: '100%', height: '32px' }}
      />
    </ActivityFormItem>
  );

  const UploadCallLogFile = (
    <UploadFormFiles
      form={form}
      formFieldName="callLogFileParams"
      value={attachments}
      selectedValues={fileList}
    />
  );

  return (
    <>
      {bulkAction ? (
        <EllipsisListContainer>
          <EllipsisList
            label="Added Invoices"
            list={map(selectedInvoiceNo, (item) => String(item))}
            totalRecords={bulkRecordsLength}
            showScrollablePopover
          />
        </EllipsisListContainer>
      ) : (
        InvoiceSelectList
      )}
      {CallLogDescription}
      <Row gutter={16}>
        <Col span={12}>{CallLogDate}</Col>
        <Col span={12}>{UploadCallLogFile}</Col>
      </Row>
    </>
  );
}
