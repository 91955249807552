import { pickBy } from 'lodash';

export function getSanitizedObject<T extends object>(object: T) {
  return pickBy(object, (value) => {
    if (Array.isArray(value) && !(value as any[]).length) {
      return false;
    }
    return value != null;
  });
}
