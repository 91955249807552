import { AmountMaybeWithMultiCurrencySplit, MultiCurrencySplit } from 'types/common/multi-currency';

export function canShowCurrencySplitPopup(
  multi_currency_split: MultiCurrencySplit[] | undefined,
  value: number
) {
  return (
    multi_currency_split &&
    multi_currency_split.length > 0 &&
    !(
      multi_currency_split?.length === 1 &&
      multi_currency_split[0].transformed_currency === multi_currency_split[0].currency
    ) &&
    !!value
  );
}

export function changeToMultiCurrencyFormat(
  amount: number,
  currency: string,
  baseAmount: number,
  baseCurrency: string,
  invoice_count?: number
): AmountMaybeWithMultiCurrencySplit {
  return {
    value: baseAmount,
    currency: baseCurrency,
    multi_currency_split: [
      {
        value: amount,
        currency: currency,
        value_in_transformed_currency: baseAmount,
        transformed_currency: baseCurrency,
        invoice_count: invoice_count,
      },
    ],
  };
}
