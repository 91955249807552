import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getSanitizedObject } from 'util/remove-null-and-undefined';
import { FilterView, UpdatedFilterValues } from '../type';

interface FilterComparisonProps<T> {
  currentFilterView?: FilterView<T>;
  totalSavedViews: FilterView<T>[];
  updatedFilterValue: UpdatedFilterValues<T> | undefined;
  filterType: string;
}

function useFilterComparison<T>(props: Readonly<FilterComparisonProps<T>>) {
  const { currentFilterView, totalSavedViews, updatedFilterValue, filterType } = props;
  const [isDirty, setIsDirty] = useState<boolean>(true);
  function filterComparisonMemo() {
    if (!currentFilterView || !Object.keys(currentFilterView).length) return true;

    const findPredicate = (filterView: FilterView<T>) => filterView.id === currentFilterView.id;
    const nonPersisted = totalSavedViews.find(findPredicate) as any;

    if (!nonPersisted || !nonPersisted[String(filterType)] || !updatedFilterValue) return true;

    const filter = nonPersisted[String(filterType)];
    const nonEmptyCurrentFilter = getSanitizedObject(filter);
    const nonEmptyUpdatedFilter = getSanitizedObject(updatedFilterValue);

    return isEqual(nonEmptyCurrentFilter, nonEmptyUpdatedFilter);
  }
  const isFilterDirty = useMemo(filterComparisonMemo, [
    currentFilterView,
    filterType,
    totalSavedViews,
    updatedFilterValue,
  ]);

  function filterComparison() {
    if (isFilterDirty !== undefined) {
      setIsDirty(isFilterDirty);
    }
  }

  useEffect(filterComparison, [isFilterDirty]);

  return { isDirty, setIsDirty };
}

export default useFilterComparison;
