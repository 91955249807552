import { assign, map } from 'lodash';
import { useEffect, useState } from 'react';
import transformData from 'util/transformData';
import { useGetFormElementQuery } from '../services/query';
import { LineitemsMapping, useGetLineItemOptionsProps } from '../types';

export default function useGetLineItemOptions<T>({
  queryFn,
  queryKey,
  transformSchema,
  selectedLineItems,
}: useGetLineItemOptionsProps<T>) {
  const [options, setOptions] = useState<LineitemsMapping[]>([]);
  const [optionsMap, setOptionsMap] = useState<Map<number, LineitemsMapping>>();

  const { data, isLoading, isSuccess } = useGetFormElementQuery<T>({
    queryFn,
    queryKey,
  });

  useEffect(() => {
    if (!isLoading && isSuccess) {
      const optionsSelectedList = assign([], data, selectedLineItems);
      const optionsListData = transformSchema
        ? map(optionsSelectedList, (item) => {
            return transformData<T, LineitemsMapping>({
              originalData: item,
              schemaMapping: transformSchema,
            });
          })
        : (optionsSelectedList as LineitemsMapping[]);
      const optionsMapList = new Map(map(optionsListData, (item) => [item.id, item]));

      setOptionsMap(optionsMapList);
      setOptions(optionsListData);
    }
  }, [data, isLoading, isSuccess, selectedLineItems, setOptionsMap, transformSchema]);

  return { isLoading, options, optionsMap };
}
