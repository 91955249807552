export const activityText = {
  call_date_label: 'Call Date',
  call_date_placeholder: 'Select Date',
  call_description_label: 'Call Details',
  call_descirption_placeholder: 'Describe the call...',
  add_invoices: 'Add Invoices',
  search_invoice: 'Search Invoice ID',
  notes_description_label: 'Notes',
  notes_description_placeholder: 'Enter the notes here...',
  dispute_type_label: 'Dispute Type',
  dispute_type_placeholder: 'Search disputes by name or Add Dispute',
  dispute_title_label: 'Title',
  dispute_title_placeholder: 'Enter a title',
  dispute_description_label: 'Description',
  dispute_description_placeholder: 'Start describing the dispute...',
  dispute_assignto_label: 'Dispute Owner',
  dispute_assignto_placeholder: '+ Add members',
  document_type_label: 'Document Type',
  document_type_placeholder: 'Select Document Type',
  document_details_label: 'Document Details',
  document_details_placeholder: 'Start Describing the Document...',
  attachments_required: 'Attachments is required',
  escalation_assignto_label: 'Escalation Owner',
  escalation_assignto_placeholder: 'Select Escalation Owner',
  escalation_description_label: 'Notes',
  escalation_description_placeholder: 'Enter the notes here...',
  task_title_label: 'Title of the Task',
  task_title_placeholder: 'Enter the title of the task',
  task_description_label: 'Description',
  task_description_placeholder: 'Describe about the task...',
  task_date_label: 'Due Date',
  task_date_placeholder: 'Select date',
  task_assignto_label: 'Task Owner',
  task_assignto_placeholder: 'Add users...',
  line_item_placeholder: 'Add Line Items',
  document_level: 'Level',
  task_reminder_label: 'Task Title',
  task_reminder_placeholder: 'Task Title',
  task_reminder_date_label: 'Due Date',
  task_reminder_date_placeholder: 'Select Date',
  task_reminder_assignto_label: 'Task Owner',
  task_reminder_assignto_placeholder: 'Add users',
  table_row_headers: ['Invoice ID', 'Invoice Balance', 'Existing PTP Balance', 'PTP Amount'],
  total_amount: 'Total Payment Amount',
  remove_invoice: 'Remove Invoice',
  error_help: [
    'If you have received a payment for existing PTPs, please close them ',
    'If current PTP amount is part of an existing PTP, delete existing PTP ',
    'If you want to revise PTP date of an existing PTP, please record payment slippage',
  ],
  error_type_empty: 'Amount should be entered',
  error_type_less_than_zero: 'PTP Amount should be greater than 0',
  error_type_greater: 'PTP Amount cannot be greater than Available Balance.',
  remainder_info_text: 'Reminders can be viewed under Task Management',
  ptp_alert_info: 'Revised payment dates for existing PTPs can be set by recording a payment slip',
  ptp_type: 'PTP Type',
  ptp_note: 'Promissory Note',
  ptp_note_placeholder: 'Start typing Promissory Note',
  ptp_date: 'PTP Date',
  ptp_expected_internal: 'This is an internal expectation for the customer’s payment',
  ptp_customer_commitment: 'This is a customer commitment to make the payment',
  raise_dispute: 'Raise a Dispute',
  edit_dispute: 'Edit dispute',
  create_call_log: 'Call Log',
  edit_call_log: 'Edit Call Log',
  create_ptp: 'Set Promise to Pay',
  create_notes: 'Add Notes',
  edit_notes: 'Edit Note',
  create_document: 'Add Document(s)',
  create_escalation: 'Add Escalation',
  edit_escalation: 'Edit Escalation',
  create_task: 'Add Task',
  edit_task: 'Edit Task',
  set_reminder: 'Set Reminder',
  write_off_amount_title: 'Writeoff Amount',
  enter_writeoff_amount_placeholder: 'Enter Write Off amount',
  write_off_description_title: 'Description',
  write_off_description_placeholder: 'Start describing the write-off...',
};

export const callLogMessage = {
  create: {
    success: { title: 'Call Logged', message: 'Call Log created successfully!' },
    error: {
      title: 'Missed the Call Log :(',
      message: 'Contents of the Log are available. Please try saving the them again',
    },
  },
  update: {
    success: {
      title: 'Call Log changes Saved',
      message: 'These changes will be notified to collaborators present in the comments',
    },
    error: { title: 'Save Unsuccessful', message: 'Please try saving the details again' },
  },
  delete: {
    success: {
      title: 'Call Log Deleted',
      message: 'This will be notified to comment collaborators in the thread.',
    },
    error: { title: 'Call Log not deleted', message: 'Please try deleting again' },
  },
  bulkAction: {
    create: {
      success: { title: 'Calls Logged', message: 'Call Logs created successfully!' },
      error: {
        title: 'Missed the Call Logs',
        message: 'Contents of the Log are available. Please try saving the them again',
      },
    },
  },
};

export const ptpMessage = {
  create: {
    success: { title: 'Cash Incoming :)', message: 'PTP is successfully created' },
    error: {
      title: 'Broken Promise',
      message: 'PTP was not captured successfully. Please save it again',
    },
  },
  update: {
    success: {
      title: 'Cash Incoming :)',
      message: 'These changes will be notified to collaborators present in the comments',
    },
    error: {
      title: 'Broken Promise',
      message: 'PTP was not captured successfully. Please submit it again',
    },
  },
  delete: {
    success: { title: 'PTP Deleted' },
    error: { title: 'PTP not Deleted', message: 'Please try deleting again' },
  },
  bulkAction: {
    create: {
      success: { title: 'Cash Incoming :)', message: 'PTPs are successfully created' },
      error: {
        title: 'Broken Promise',
        message: 'PTPs were not captured successfully. Please save it again.',
      },
    },
  },
};

export const noteMessage = {
  create: {
    success: { title: 'Noted!', message: 'Note is saved successfully!' },
    error: {
      title: 'Ran out of pages :(',
      message: 'Your Note was not captured. Please try saving them again',
    },
  },
  update: {
    success: {
      title: 'Noted!',
      message: 'These changes will be notified to collaborators present in the comments',
    },
    error: {
      title: 'Ran out of pages :(',
      message: 'Note was not captured. Please try saving them again',
    },
  },
  delete: {
    success: { title: 'Note Deleted' },
    error: { title: 'Note not deleted', message: 'Please try deleting again' },
  },
  bulkAction: {
    create: {
      success: { title: 'Noted!', message: 'Private Notes saved successfully!' },
      error: {
        title: 'Ran out of pages :(',
        message: 'Notes were not captured. Please try saving them again',
      },
    },
  },
};

export const disputeMessage = {
  create: {
    success: { title: 'Dispute Created', message: 'The Dispute is saved successfully' },
    error: {
      title: 'Undisputed :|',
      message: 'The Dispute was not captured. Please try creating it again.',
    },
  },
  update: {
    success: {
      title: 'Dispute changes saved',
      message: 'These changes will be notified to collaborators present in the comments',
    },
    error: {
      title: 'Undisputed :|',
      message: 'The Dispute was not captured. Please try creating it again.',
    },
  },
  delete: {
    success: { title: 'Dispute Deleted' },
    error: { title: 'Dispute not deleted', message: 'Please try deleting again' },
  },
  bulkAction: {
    create: {
      success: { title: 'Disputes Created', message: 'Disputes were saved successfully' },
      error: {
        title: 'Undisputed :|',
        message: 'The Disputes were not captured. Please try creating it again.',
      },
    },
  },
};

export const escalationMessage = {
  create: {
    success: { title: 'Escalation successful', message: 'Escalated to the chosen assignees' },
    error: {
      title: 'Escalation failed',
      message: "Escalation wasn't raised yet. Please try again.",
    },
  },
  update: {
    success: {
      title: 'Escalation successful',
      message: 'These changes will be notified to collaborators present in the comments',
    },
    error: {
      title: 'Escalation failed',
      message: "Escalation wasn't raised yet. Please try again.",
    },
  },
  delete: {
    success: { title: 'Escalation Deleted' },
    error: { title: 'Escalation not Deleted', message: 'Please try deleting again' },
  },
  bulkAction: {
    create: {
      success: {
        title: 'Escalations successful',
        message: 'Escalated to the chosen assignees',
      },
      error: {
        title: 'Escalations failed',
        message: `Escalation wasn't raised yet. Please try again.`,
      },
    },
  },
};

export const taskMessages = {
  create: {
    success: { title: 'Task Created', message: 'Chase the task and finish it!' },
    error: { title: 'Tsk Tsk :(', message: 'Task could not be created. Please retry.' },
  },
  update: {
    success: {
      title: 'Task update successful',
      message: 'These changes will be notified to collaborators present in the comments',
    },
    error: {
      title: 'Task update failed',
      message: "Task wasn't update. Please try again.",
    },
  },
  bulkAction: {
    create: {
      success: { title: 'Tasks Created', message: 'Chase those tasks and finish them!' },
      error: { title: 'Tsk Tsk :(', message: `Tasks could not be created. Please retry.` },
    },
  },
};

export const documentMessages = {
  create: {
    success: { title: 'Document Created Successfully' },
    error: { title: 'Document Creation Failed :(' },
  },
};

export const DisputeFormText = {
  invoiceLabel: activityText.add_invoices,
  invoicePlaceholder: activityText.search_invoice,
  disputeTypeLabel: activityText.dispute_type_label,
  disputeTypePlaceholder: activityText.dispute_type_placeholder,
  titleLabel: activityText.dispute_title_label,
  titlePlaceholder: activityText.dispute_title_placeholder,
  descriptionLabel: activityText.dispute_description_label,
  descriptionPlaceholder: activityText.dispute_description_placeholder,
  assignedOwnersLabel: activityText.dispute_assignto_label,
  assignOwnersPlaceholder: activityText.dispute_assignto_placeholder,
  lineItemsPlaceholder: activityText.line_item_placeholder,
  taskReminderLabel: activityText.task_reminder_label,
  taskReminderPlaceholder: activityText.task_reminder_placeholder,
  taskReminderDateLabel: activityText.task_reminder_date_label,
  taskReminderDatePlaceholder: activityText.task_reminder_date_placeholder,
  taskReminderAssignToLabel: activityText.task_reminder_assignto_label,
  taskReminderAssignToPlaceholder: activityText.task_reminder_assignto_placeholder,
};

export const CallLogFormText = {
  invoiceLabel: activityText.add_invoices,
  invoicePlaceholder: activityText.search_invoice,
  dateLabel: activityText.call_date_label,
  datePlaceholder: activityText.call_date_placeholder,
  descriptionLabel: activityText.call_description_label,
  descriptionPlaceholder: activityText.call_descirption_placeholder,
  taskReminderLabel: activityText.task_reminder_label,
  taskReminderPlaceholder: activityText.task_reminder_placeholder,
  taskReminderDateLabel: activityText.task_reminder_date_label,
  taskReminderDatePlaceholder: activityText.task_reminder_date_placeholder,
  taskReminderAssignToLabel: activityText.task_reminder_assignto_label,
  taskReminderAssignToPlaceholder: activityText.task_reminder_assignto_placeholder,
};

export const NoteFormText = {
  invoiceLabel: activityText.add_invoices,
  invoicePlaceholder: activityText.search_invoice,
  lineItemsPlaceholder: activityText.line_item_placeholder,
  descriptionLabel: activityText.notes_description_label,
  descriptionPlaceholder: activityText.notes_description_placeholder,
  taskReminderLabel: activityText.task_reminder_label,
  taskReminderPlaceholder: activityText.task_reminder_placeholder,
  taskReminderDateLabel: activityText.task_reminder_date_label,
  taskReminderDatePlaceholder: activityText.task_reminder_date_placeholder,
  taskReminderAssignToLabel: activityText.task_reminder_assignto_label,
  taskReminderAssignToPlaceholder: activityText.task_reminder_assignto_placeholder,
};

export const EscalationFormText = {
  invoiceLabel: activityText.add_invoices,
  invoicePlaceholder: activityText.search_invoice,
  descriptionLabel: activityText.escalation_description_label,
  descriptionPlaceholder: activityText.escalation_description_placeholder,
  assignedOwnersLabel: activityText.escalation_assignto_label,
  assignOwnersPlaceholder: activityText.escalation_assignto_placeholder,
  lineItemsPlaceholder: activityText.line_item_placeholder,
  taskReminderLabel: activityText.task_reminder_label,
  taskReminderPlaceholder: activityText.task_reminder_placeholder,
  taskReminderDateLabel: activityText.task_reminder_date_label,
  taskReminderDatePlaceholder: activityText.task_reminder_date_placeholder,
  taskReminderAssignToLabel: activityText.task_reminder_assignto_label,
  taskReminderAssignToPlaceholder: activityText.task_reminder_assignto_placeholder,
};

export const TaskFormText = {
  invoiceLabel: activityText.add_invoices,
  invoicePlaceholder: activityText.search_invoice,
  titleLabel: activityText.task_title_label,
  titlePlaceholder: activityText.task_title_placeholder,
  descriptionLabel: activityText.task_description_label,
  descriptionPlaceholder: activityText.task_description_placeholder,
  assignedOwnersLabel: activityText.task_assignto_label,
  assignOwnersPlaceholder: activityText.task_assignto_placeholder,
  dateLabel: activityText.task_date_label,
  datePlaceholder: activityText.task_date_placeholder,
};

export const WriteOffFormText = {
  invoiceLabel: activityText.add_invoices,
  invoicePlaceholder: activityText.search_invoice,
  titleLabel: activityText.write_off_amount_title,
  titlePlaceholder: activityText.enter_writeoff_amount_placeholder,
  descriptionLabel: activityText.write_off_description_title,
  descriptionPlaceholder: activityText.write_off_description_placeholder,
};

export const DocumentFormText = {
  invoiceLabel: activityText.add_invoices,
  invoicePlaceholder: activityText.search_invoice,
  documentTypeLabel: activityText.document_type_label,
  documentTypePlaceholder: activityText.document_type_placeholder,
  descriptionLabel: activityText.document_details_label,
  descriptionPlaceholder: activityText.document_details_placeholder,
  documentLevelLabel: activityText.document_level,
};

export const P2PFormText = {
  invoiceLabel: activityText.add_invoices,
  invoicePlaceholder: activityText.search_invoice,
  ptpTypeLabel: activityText.ptp_type,
  descriptionLabel: activityText.ptp_note,
  descriptionPlaceholder: activityText.ptp_note_placeholder,
  dateLabel: activityText.ptp_date,
  ptpAlertInfo: activityText.ptp_alert_info,
  ptpExpected: activityText.ptp_expected_internal,
  ptpCommitment: activityText.ptp_customer_commitment,
  taskReminderLabel: activityText.task_reminder_label,
  taskReminderPlaceholder: activityText.task_reminder_placeholder,
  taskReminderDateLabel: activityText.task_reminder_date_label,
  taskReminderDatePlaceholder: activityText.task_reminder_date_placeholder,
  taskReminderAssignToLabel: activityText.task_reminder_assignto_label,
  taskReminderAssignToPlaceholder: activityText.task_reminder_assignto_placeholder,
};
