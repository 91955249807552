import { Select, Skeleton } from 'antd';
import { useState } from 'react';
import useGetLineItemOptions from '../hooks/useGetLineItemOptions';
import { customTagRender, ListOptions, MaxTagListRenderer } from '../predicate';
import { LineitemsMapping, LineItemsSelectProps } from '../types';

export default function LineItemsSelect<T>(props: LineItemsSelectProps<T>) {
  const {
    placeholder,
    value,
    onChangeLineItems,
    queryFn,
    queryKey,
    transformSchema,
    selectedLineItems,
  } = props;

  const [selectedValues, setSelectedValues] = useState<number[] | undefined>(value);
  const { options, isLoading, optionsMap } = useGetLineItemOptions<T>({
    queryFn,
    queryKey,
    transformSchema,
    selectedLineItems,
  });

  if (isLoading) {
    return <Skeleton.Input active block />;
  }

  return (
    <Select
      placeholder={placeholder}
      value={selectedValues}
      mode={'multiple'}
      listHeight={250}
      style={{ width: '100%' }}
      suffixIcon={null}
      filterOption
      showSearch
      fieldNames={{ label: 'productName', value: 'id' }}
      options={options}
      loading={isLoading}
      optionFilterProp="productName"
      dropdownRender={(options) => {
        return ListOptions(options, isLoading);
      }}
      tagRender={(props) => {
        return customTagRender<T, LineitemsMapping>(props, 'productName', optionsMap);
      }}
      maxTagPlaceholder={(list) => {
        return (
          <MaxTagListRenderer<LineitemsMapping>
            list={list}
            optionsMap={optionsMap}
            extractKey={'productName'}
          />
        );
      }}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      maxTagCount="responsive"
      onChange={(value: number[]) => {
        setSelectedValues(value);
        onChangeLineItems(value);
      }}
    />
  );
}
