import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuickOperations } from '@sinecycle/growcomponents';
import { EActions } from 'components/HigherOrderComponent/KeyActionsContainer/types';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { ELastContactActivity } from 'store/activity-feed/type';
import { IInvoices } from 'store/customer/types';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import { useComputedCustomerList } from './useComputedCustomerList';
import { useComputedInvoiceList } from './useComputedInvoiceList';

interface useActionsProps {
  selectAll: boolean;
  isFromInvoiceList: boolean;
  setAction: (key: EActions, invoiceList: InvoiceDropdownInterfaceProps[]) => void;
  setQuickAction: (key: ELastContactActivity, invoiceList: InvoiceDropdownInterfaceProps[]) => void;
}
function useActions(props: useActionsProps) {
  const hasPrivilegeToSetInvoiceFlag = usePrivilegeStatus([
    ERolesAndPrivileges.SET_CUSTOMER_DETAIL_FLAG_INVOICE,
    ERolesAndPrivileges.SET_INVOICE_LIST_FLAG_INVOICE,
  ]);
  const { computedQuickActivitiesInvoiceList } = useComputedInvoiceList({
    hasPrivilegeToSetInvoiceFlag,
  });
  const { computedBulkActionOptions } = useComputedCustomerList({
    selectAll: props.selectAll,
    hasPrivilegeToSetInvoiceFlag,
    isFromInvoiceList: props.isFromInvoiceList,
  });

  const invoiceQuickActions: QuickOperations<IInvoices>['items'] =
    computedQuickActivitiesInvoiceList.map((action) => {
      return {
        label: action.label,
        icon: <FontAwesomeIcon icon={action.icon as IconProp} />,
        key: action.key,
      };
    });
  const customerQuickActions: QuickOperations<IInvoices>['items'] =
    computedQuickActivitiesInvoiceList.map((action) => {
      return {
        label: action.label,
        icon: <FontAwesomeIcon icon={action.icon as IconProp} />,
        key: action.key,
      };
    });
  return {
    computedQuickActivitiesInvoiceList,
    computedBulkActionOptions,
    invoiceQuickActions,
    customerQuickActions,
  };
}

export default useActions;
