import { ActivityIdsState } from 'components/Common/ActivitiesModal/type';
import { useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { findIndex } from 'lodash';
import { useState } from 'react';

export default function useUpdateActivityUrlParams() {
  const { entitySearchParams, setEntitySearchParams, removeEntitySearchParams } =
    useEntitySearchParams();
  const [activityKeysData, setActivityKeysData] = useState<ActivityIdsState | undefined>();

  const totalPages = entitySearchParams[EActivityQueryParams.TOTAL_PAGES];
  const activityPage = entitySearchParams[EActivityQueryParams.ACTIVITY_PAGE];
  const activityId = entitySearchParams[EActivityQueryParams.ACTIVITY_ID];
  const activityType = entitySearchParams[EActivityQueryParams.ACTIVITY_TYPE];

  const index = findIndex(
    activityKeysData?.id,
    (id) => id === Number(entitySearchParams[EActivityQueryParams.ACTIVITY_ID])
  );

  const previousId = activityKeysData?.id[index - 1];
  const nextId = activityKeysData?.id[index + 1];
  const isFetchNextActivity = activityKeysData && index === activityKeysData.id.length - 1;
  const isLastActivity =
    activityPage === totalPages &&
    activityKeysData?.id[activityKeysData?.id.length - 1] === activityId;

  function resetAssociatedActivity() {
    removeEntitySearchParams([
      EActivityQueryParams.ASSOCIATED_ACTIVITY,
      EActivityQueryParams.ASSOCIATED_ACTIVITY_TYPE,
      EActivityQueryParams.ASSOCIATED_ACTIVITY_ID,
    ]);
  }

  function updateActivityUrlToNextActivity() {
    if (!activityKeysData) return;
    if (index !== activityKeysData.id.length - 1) {
      const activityIndex = index + 1;

      setEntitySearchParams({
        [EActivityQueryParams.ACTIVITY_ID]: activityKeysData.id[activityIndex],
        [EActivityQueryParams.ACTIVITY_TYPE]: activityKeysData?.types
          ? activityKeysData.types[activityIndex]
          : activityType,
        [EActivityQueryParams.CUSTOMER]: activityKeysData?.customerName
          ? activityKeysData?.customerName[activityIndex]
          : undefined,
        [EActivityQueryParams.CUSTOMER_ID]: activityKeysData?.customerId
          ? activityKeysData?.customerId[activityIndex]
          : undefined,
      });
      resetAssociatedActivity();
    }
  }

  function updateActivityUrlParamsToNextPage(activityKeys?: ActivityIdsState) {
    if (activityKeys && activityKeysData && index === activityKeysData.id.length - 1 && index + 1) {
      const activityIndex = index + 1;

      setEntitySearchParams({
        [EActivityQueryParams.ACTIVITY_ID]: activityKeys.id[activityIndex],
        [EActivityQueryParams.ACTIVITY_TYPE]:
          activityKeys?.types && activityKeys.types[activityIndex] !== undefined
            ? activityKeys.types[activityIndex]
            : activityType,
        [EActivityQueryParams.CUSTOMER]: activityKeysData?.customerName
          ? activityKeysData?.customerName[activityIndex]
          : undefined,
        [EActivityQueryParams.CUSTOMER_ID]: activityKeysData?.customerId
          ? activityKeysData?.customerId[activityIndex]
          : undefined,
      });
      resetAssociatedActivity();
    }
  }

  function updateActivityUrlToPreviousActivity() {
    if (!activityKeysData) return;
    const activityIndex = index - 1;

    setEntitySearchParams({
      [EActivityQueryParams.ACTIVITY_ID]: activityKeysData.id[activityIndex],
      [EActivityQueryParams.ACTIVITY_TYPE]: activityKeysData?.types
        ? activityKeysData.types[activityIndex]
        : activityType,
      [EActivityQueryParams.CUSTOMER]: activityKeysData?.customerName
        ? activityKeysData?.customerName[activityIndex]
        : undefined,
      [EActivityQueryParams.CUSTOMER_ID]: activityKeysData?.customerId
        ? activityKeysData?.customerId[activityIndex]
        : undefined,
    });
    resetAssociatedActivity();
  }

  return {
    updateActivityUrlToNextActivity,
    setActivityKeysData,
    isFetchNextActivity,
    updateActivityUrlParamsToNextPage,
    updateActivityUrlToPreviousActivity,
    isFirstActivity: index,
    previousId,
    nextId,
    isLastActivity,
  };
}
