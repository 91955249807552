import { CalendarDaysRuleEvent, CalendarEventType } from 'components/Common/CalenderEvent/type';
import {
  NEW_DUNNING_RULE_ACTION_ID,
  NEW_DUNNING_RULE_EVENT_ID,
  NEW_DUNNING_RULE_ID,
} from 'store/collection-strategy';
import { RuleErrorField, validateDunningRule } from 'store/collection-strategy-rule/validations';
import { FollowupType } from 'types/entities/collection-strategy';
import {
  BaseRecepientsType,
  RelativeContact,
} from 'types/entities/collection-strategy/contact-type';
import { FollowupRuleType } from 'types/entities/collection-strategy/rule';
import {
  EmailActionData,
  EscalationActionData,
  FollowupRuleActionForm,
  FollowupRuleActionType,
  TaskActionData,
} from 'types/entities/collection-strategy/rule-action';
import {
  FollowupEventType,
  FollowupRuleEventForm,
  InvoiceAgeRuleEvent,
  InvoiceDaysType,
} from 'types/entities/collection-strategy/rule-event';
import { FollowupRuleScope } from 'types/entities/collection-strategy/rule-scope';
import { PocUserType } from 'types/entities/contact';

export type RuleErrors<T extends RuleErrorField = RuleErrorField> = Partial<{
  [key in T]: string;
}>;

export interface DunningRuleForm {
  id: string | number;
  type: FollowupRuleType.DUNNING_RULE;
  data: {
    title: string;
    sequence?: number;
    scopes: FollowupRuleScope[];
    event: FollowupRuleEventForm;
    action: FollowupRuleActionForm;
  };
}

export interface InitialState {
  rule: DunningRuleForm;
  followupType: FollowupType;
  errors: RuleErrors<RuleErrorField>;
  showErrors: boolean;
  formVisible: boolean;
}

const defaultAssignTo: RelativeContact = {
  type: BaseRecepientsType.POC,
  value: PocUserType.ACCOUNT_MANAGER,
  association_level: 'CUSTOMER',
};

export const defaultTaskValues: Partial<TaskActionData> = {
  due_date_offset: 0,
};
export const defaultEmailValues: Partial<EmailActionData> = {
  from: defaultAssignTo,

  subject: '',
  body: '',
  attachments: [],
  fileUploads: [],
};
export const defaultEscalationValues: Partial<EscalationActionData> = {};

const statementInitialEvent: CalendarDaysRuleEvent = {
  id: NEW_DUNNING_RULE_EVENT_ID,
  type: FollowupEventType.CALENDAR,
  data: {
    type: CalendarEventType.DAY_OF_WEEK,
    days: [],
  },
};

const invoiceInitialEvent: InvoiceAgeRuleEvent = {
  id: NEW_DUNNING_RULE_EVENT_ID,
  type: FollowupEventType.INVOICE_AGE,
  data: {
    days: 0,
    type: InvoiceDaysType.DUE_DATE,
  },
};

const invoiceDunningRule: DunningRuleForm = {
  id: NEW_DUNNING_RULE_ID,
  type: FollowupRuleType.DUNNING_RULE,
  data: {
    title: 'New Dunning Rule',
    sequence: -1,
    scopes: [],
    event: invoiceInitialEvent,
    action: {
      id: NEW_DUNNING_RULE_ACTION_ID,
      type: FollowupRuleActionType.EMAIL,
      data: {},
    },
  },
};

const statementDunningRule: DunningRuleForm = {
  id: NEW_DUNNING_RULE_ID,
  type: FollowupRuleType.DUNNING_RULE,
  data: {
    title: 'New Dunning Rule',
    sequence: -1,
    scopes: [],
    event: statementInitialEvent,
    action: {
      id: NEW_DUNNING_RULE_ACTION_ID,
      type: FollowupRuleActionType.EMAIL,
      data: {},
    },
  },
};

const baseRuleProps = {
  errors: {},
  showErrors: false,
  formVisible: false,
};

export const invoiceInitialState: InitialState = {
  ...baseRuleProps,
  rule: invoiceDunningRule,
  followupType: FollowupType.INVOICE,
  errors: validateDunningRule(invoiceDunningRule),
};

export const statementInitialState: InitialState = {
  ...baseRuleProps,
  rule: statementDunningRule,
  followupType: FollowupType.STATEMENT,
  errors: validateDunningRule(statementDunningRule),
};
