import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { getBusinessUnits } from 'services/business-units';
import { getRegions } from 'services/regions';
import { getAllBusinessUnitsHandler } from 'store/business-units/business-units';
import { getAllRegionsHandler } from 'store/regions/regions';
const HALF_DAY = 12 * 60 * 60 * 1000;
export function useBusinessUnitsQuery() {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['bu-units'],
    queryFn: () => getBusinessUnits(),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
    onSuccess: (businessUnits) => {
      dispatch(getAllBusinessUnitsHandler({ status: 200, businessUnits }));
    },
    onError: (err) => {
      console.log(err);
      dispatch(getAllBusinessUnitsHandler({ status: 500, message: 'something went wrong' }));
    },
  });
}

export function useRegionsQuery() {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ['regions'],
    queryFn: () => getRegions(),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
    onSuccess: (regions) => {
      dispatch(getAllRegionsHandler({ status: 200, regions }));
    },
    onError: (err) => {
      console.log(err);
      dispatch(getAllRegionsHandler({ status: 500, message: 'something went wrong' }));
    },
  });
}
