import { CustomDashboardLink, CustomReportToken } from 'types/api/reports';
import ky from './ky';

const baseUrl = 'cumul';

export async function getCustomReportToken() {
  return (await ky.get(`${baseUrl}/token`).json()) as CustomReportToken;
}

export async function getCustomReports() {
  return (await ky.get(`${baseUrl}/dashboard`).json()) as CustomDashboardLink[];
}
