import {
  InvoiceSegments,
  SegmentDate,
} from 'components/Settings/CollectionPlanConfiguration/CPBody/PeriodConditions/Filters/types';
import { Period } from 'components/Settings/CollectionPlanConfiguration/CPBody/PeriodPlanning/PeriodSelection/config';
import { InvoiceStatus } from 'types/entities/invoice';
import {
  CustomFieldFilterValues,
  CustomFieldOperatorType,
} from './../../types/entities/custom-field/index';

export enum PlanMode {
  MANUAL = 'MANUAL',
  AUTOMATED = 'AUTO',
}

export enum PeriodType {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type SegmentDateFilters = Array<
  Extract<
    InvoiceSegments,
    InvoiceSegments.DUE_DATE | InvoiceSegments.ISSUE_DATE | InvoiceSegments.PTP_DATE
  >
>;

export type DefaultDateFilterValues = Record<SegmentDateFilters[number], SegmentDate>;

export const validDateFields: SegmentDateFilters = [
  InvoiceSegments.DUE_DATE,
  InvoiceSegments.ISSUE_DATE,
  InvoiceSegments.PTP_DATE,
];

type ConditionValidations = Record<string, boolean>;

export const defaultDateFilterValues: DefaultDateFilterValues = {
  [InvoiceSegments.ISSUE_DATE]: { day: '20', period: Period.CURRENT_MONTH },
  [InvoiceSegments.DUE_DATE]: { day: 'First Day', period: Period.NEXT_MONTH },
  [InvoiceSegments.PTP_DATE]: { day: 'First Day', period: Period.NEXT_MONTH },
};

export interface SegmentConditions {
  [InvoiceSegments.INVOICE_STATUS]?: InvoiceStatus[];
  [InvoiceSegments.COLLECTION_STATUS]?: number[];
  [InvoiceSegments.ISSUE_DATE]?: SegmentDate;
  [InvoiceSegments.DUE_DATE]?: SegmentDate;
  [InvoiceSegments.PTP_DATE]?: SegmentDate;
  [InvoiceSegments.CUSTOM_FIELD]?: CustomFieldFilterValues<CustomFieldOperatorType>[];
}
export interface SegmentConditionsValidation {
  [InvoiceSegments.INVOICE_STATUS]: boolean;
  [InvoiceSegments.COLLECTION_STATUS]: boolean;
  [InvoiceSegments.ISSUE_DATE]: boolean;
  [InvoiceSegments.DUE_DATE]: boolean;
  [InvoiceSegments.PTP_DATE]: boolean;
}

export type UpdateInvoiceFilterConditionsValues =
  | {
      key: InvoiceSegments.INVOICE_STATUS;
      options?: InvoiceStatus[];
    }
  | {
      key: InvoiceSegments.COLLECTION_STATUS;
      options?: number[];
    }
  | {
      key: InvoiceSegments.ISSUE_DATE;
      options?: SegmentDate;
    }
  | {
      key: InvoiceSegments.PTP_DATE;
      options?: SegmentDate;
    }
  | {
      key: InvoiceSegments.DUE_DATE;
      options?: SegmentDate;
    }
  | {
      key: InvoiceSegments.CUSTOM_FIELD;
      payload: CustomFieldFilterValues<CustomFieldOperatorType>;
    };

export interface CollectionPlanProps {
  lastActivity?: string;
  planStatus?: boolean;
  currentCondition: SegmentConditions;
  planMode: PlanMode;
  persistedCondition?: SegmentConditions;
  periodType: PeriodType;
  planDayMonth: string;
  planId?: number;
  planDayWeek?: string;
}
export type PlanDay = PeriodType.MONTH | PeriodType.WEEK;

export interface PlanDayActions {
  type: PlanDay;
  value?: string;
}

export interface CollectionPlan {
  collectionPlan: CollectionPlanProps;
  conditionsValidation: ConditionValidations;
}
