import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer } from 'types/entities/customer';
import { User } from 'types/entities/user';
import { RootState } from '../../reducers/rootReducer';
import { IContacts, ICustomerInternalContact, ITasks } from './types';

const initialState: {
  status: number;
  message: string;
  customerDetail: Customer;
  internalContact: ICustomerInternalContact[];
  contacts: IContacts[];
  tasks: ITasks[];
} = {
  status: 0,
  message: '',
  customerDetail: {} as Customer,
  internalContact: [
    {
      title: '',
      user: {} as User,
    },
  ] as ICustomerInternalContact[],
  contacts: [] as IContacts[],
  tasks: [] as ITasks[],
};

const customerDetailsSlice = createSlice({
  name: 'customerDetails',
  initialState,
  reducers: {
    updateCustomerDetailsHandler(state, action: PayloadAction<Customer>) {
      state.customerDetail = action.payload;
    },
    updateInternalContactsForCustomerHandler(
      state,
      action: PayloadAction<ICustomerInternalContact[]>
    ) {
      state.internalContact = action.payload;
    },
    updateContactsForCustomerHandler(state, action: PayloadAction<IContacts[]>) {
      state.contacts = action.payload;
    },
  },
});

export const customerEntity = (state: RootState) => state.customerDetail;

export const customerDetailsSelector = createSelector(
  customerEntity,
  (customer) => customer.customerDetail
);

export const {
  updateCustomerDetailsHandler,
  updateInternalContactsForCustomerHandler,
  updateContactsForCustomerHandler,
} = customerDetailsSlice.actions;

export default customerDetailsSlice.reducer;
