import { useCallback, useEffect } from 'react';

type EventType<T> = {
  event: string;
  data?: T & { enabled: boolean };
};

type EventCallback<T> = (data: T) => void;

/**
 * Creates an event bus hook that allows components to emit custom events.
 *
 * @template T - The type of data that can be passed along with the emitted event.
 * @returns {Object} An object with an `emitEvent` function that can be used to emit custom events.
 */
export const useEventBus = () => {
  const emitEvent = <T>({ event, data }: EventType<T>) => {
    if (data && data.enabled) {
      const customEvent = new CustomEvent(event, { detail: data });
      document.dispatchEvent(customEvent);
    }
  };

  return { emitEvent };
};

/**
 * Creates an event listener hook that allows components to listen for custom events.
 *
 * @template T - The type of data that can be passed along with the emitted event.
 * @param {string} event - The name of the event to listen for.
 * @param {EventCallback<T>} callback - The callback function to be called when the event is triggered.
 * @return {void}
 */
export const useEventListener = <T>(event: string, callback: EventCallback<T>) => {
  const memoizedCallback = useCallback(callback, [callback]);

  useEffect(() => {
    const eventListener = (event: Event) => {
      const customEvent = event as CustomEvent<T>;
      if (customEvent && customEvent.detail) {
        memoizedCallback(customEvent.detail);
      }
    };

    document.addEventListener(event, eventListener);

    return () => {
      document.removeEventListener(event, eventListener);
    };
  }, [event, memoizedCallback]);
};
