import { Card } from 'antd';
import styled from 'styled-components';

export const StyledItemCard = styled(Card)`
  margin-bottom: var(--space-8);
  width: 720px;
  border-radius: 4px;
  border: 1px solid var(--gray-5);

  .ant-list-item-meta {
    max-width: 400px;
  }
  .ant-list-item {
    justify-content: normal;
  }

  .ant-card-body {
    padding: var(--space-16) var(--space-20);
  }

  .ant-list-item-meta-avatar {
    margin-right: var(--space-12);
  }
  .ant-list-item-meta-title {
    line-height: normal;
  }
  .ant-list-item-action {
    margin-left: auto;
    li {
      .hide-actions {
        visibility: hidden;
      }
    }
  }
  &:hover {
    .ant-list-item-action {
      li {
        .hide-actions {
          visibility: visible;
        }
      }
    }
    box-shadow: var(--shadow-2);
    border: 1px solid var(--primary-4);
  }
`;
