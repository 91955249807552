import { InvoiceEmailDeliveryStatus } from './../../../../../types/entities/invoice';
export type InvoiceEmailDeliveryStatusConfig = {
  [key in InvoiceEmailDeliveryStatus]: {
    label: string;
    iconColor: string;
  };
};
export const eActionList = [
  'TASK',
  'CALL_LOG',
  'ESCALATION',
  'PROMISE_TO_PAY',
  'NOTE',
  'DISPUTE',
  'DOCUMENT',
  'DOWNLOAD_PDF',
  'ESCALATE',
  'EMAIL',
];
