import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { IProps } from 'components/BaseComponents/ASelect';
import { notify } from 'components/BaseComponents/Notifications';
import { DisputeStatus } from 'components/CollectionActivites/Disputes/types';
import { ClosedDesc } from 'components/CollectionActivities/Common/Details/ClosedDesc';
import { StyledDisputeStatus } from 'components/CollectionActivities/style';
import { useUpdateDisputeStatus } from 'queries/activites';
import { useEffect, useState } from 'react';
import { DisputeTypeMenu } from 'types/activities/dispute';

const t = {
  successMessage: 'Dispute Status Updated',
};

interface DisputeStatusProps {
  status: DisputeStatus;
  disputeActivityId: number;
  onChangeCallBack?: (status: DisputeStatus) => void;
  size?: SizeType;
  activitiesMode?: boolean;
}

export function DisputeStatusQuickSelect(props: DisputeStatusProps) {
  const [disputeStatus, setDisputeStatus] = useState<DisputeStatus | undefined>();
  const { mutateAsync: updateDisputeStatus } = useUpdateDisputeStatus();
  const { status, disputeActivityId, onChangeCallBack, size, activitiesMode } = props;

  useEffect(() => {
    setDisputeStatus(status);
  }, [status]);

  async function handleChange(status: IProps<DisputeStatus>) {
    const statusValue = status.value === 'CLOSED';
    try {
      await updateDisputeStatus({ params: { status: status.value }, id: disputeActivityId });
      notify.success(statusValue ? 'Activity Marked as Closed' : t.successMessage, {
        description: !activitiesMode && statusValue ? <ClosedDesc entity="dispute" /> : null,
      });
      setDisputeStatus(status.value);
      onChangeCallBack?.(status.value);
    } catch {
      throw new Error();
    }
  }

  return (
    <StyledDisputeStatus
      status={disputeStatus}
      selectedValues={disputeStatus}
      optionsList={DisputeTypeMenu}
      onChangeCallBack={handleChange}
      size={size}
      dropdownStyle={{ minWidth: 'fit-content' }}
    />
  );
}
